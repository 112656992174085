import { IContact } from 'models/addressbookModel'
import I18n from 'i18n'
import * as React from 'react'
import Button from 'components/button'
import Modal from 'components/newModal'

export interface IContactListViewProps {
  contacts: Array<IContact>
  activeContactId: number | undefined
  onContactSelected: (contact: IContact) => void
  onAddButton: (filter: string) => void
  onCleanupClick: () => void
}

interface State {
  filter: string
  openModal?: boolean
  closeModal?: boolean
}

export class ContactListView extends React.Component<IContactListViewProps, State> {
  constructor(props: IContactListViewProps) {
    super(props);
    this.state = {
      filter: ''
    }
  }
  render() {
    return (
      <form>
        <div className="button-group button-group--vertical">
          <div className="button-group__item-filter">
            <div className="button-group__item-lens">
              <i className="icon-lens" />
            </div>
            <input
              type="text"
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                this.onKeyUp(e)
              }
              defaultValue={this.state.filter}
              className="button-group__item-filter__input text-input text-input--borderless"
              placeholder={I18n.t(
                "webapp.settings.contacts.groups.search_or_add_contact"
              )}
            />
          </div>
          {this.contactList()}
          {this.addButton()}
          {this.cleanupButton()}
          {this.cleanupModal()}
        </div>
      </form>
    );
  }

  handleKeyPress(e: any) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.cleanupContacts();
    }
  }

  private cleanupModal() {
    const prefix =
      'webapp.settings.contacts.general.cleanup_contacts_modal.';
    const props = {
      title: I18n.t(prefix + 'title'),
      cancelLabel: I18n.t(prefix + 'cancel'),
      confirmationLabel: I18n.t(prefix + 'confirm'),
      onConfirm: () => this.cleanupContacts(),
      modalId: 'cleanupContacts',
      open: this.state.openModal,
      close: this.state.closeModal,
      onClose: () => this.hideModal(),
      enableConfirmButton: true,
      onKeyDown: (e: any) => this.handleKeyPress(e),
    }
    const body = I18n.t(prefix + 'body');
    return (
      <Modal {...props}>{body}</Modal>
    )
  }

  private cleanupContacts() {
    this.hideModal();
    this.props.onCleanupClick();
  }

  private showModal() {
    this.setState({openModal: true, closeModal: false});
  }

  private hideModal() {
    this.setState({openModal: false, closeModal: true});
  }

  private cleanupButton() {
    const label = I18n.t('webapp.settings.contacts.general.cleanup_contacts');
    const props = {
      className: 'button--negative mtm',
      onClick: () => { this.showModal() }
    }
    return (
      <Button {...props}>{label}</Button>
    )
  }


  private onKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    let enteredText = e.currentTarget.value
    if (enteredText !== null) {
      this.setState((prevState, props) => {
        return {
          filter: enteredText
        }
      })
    }
  }

  private contactList() : JSX.Element[] {
    return this.props.contacts.map((contact) => {
      let classes = ['button-group__item', 'button--pointing']
      let activeId = this.props.activeContactId
      if (activeId && contact.id === activeId) {
        classes.push('is-active')
      }
      if (!this.matches(contact, this.state.filter)) {
        classes.push('is-hidden')
      }
      let iconClass = contact.isUserContact() ? 'icon-user' : 'icon-envelope'
      let text;
      if (contact.get('name')) {
        text = [
          contact.get('name'),
          ' ',
          <span className="text--grey">{contact.get('email')}</span>
        ]
      } else {
        text = contact.get('email')
      }
      return (
        <a onClick={() => this.props.onContactSelected(contact)}
          className={classes.join(' ')}
          key={contact.id}>
          <i className={iconClass}></i>&nbsp;
          {text}
        </a>
      )
    })
  }

  private addButton() : JSX.Element | undefined {
    let text = I18n.t('webapp.settings.contacts.general.add_contact')
    if (this.state.filter !== '') {
      text = I18n.t('webapp.settings.contacts.general.add_contact_with_search', {
        search_string: this.state.filter
      })
    }
    return (
      <a onClick={() => this.props.onAddButton(this.state.filter)} className="button-group__item">
        <strong><i className="icon-plus"></i>&nbsp;
          {text}
        </strong></a>
    )
  }

  private matches(contact: IContact, filter: string) : boolean {
    let text = this.text(contact).toLowerCase()
    return text.indexOf(filter.toLowerCase()) >= 0
  }

  private text(contact: IContact): string {
    return contact.getName() + contact.get('email')
  }
}
