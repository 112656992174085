import SceneController from 'controllers/sceneController';
import PersonsDetailController from 'controllers/settings/persons/personsDetailController';
import PersonsPlaceholderController from 'controllers/settings/persons/personsPlaceholderController';
import * as _ from 'underscore'
import OverviewController from 'controllers/settings/entityOverviewController';

class PersonsSceneController extends SceneController {
  show() {
    this.controller = new OverviewController({
      headerLocaleKey: 'webapp.settings.persons.header',
      entityType: 'person_entity',
      prefixSearchErrorLocaleKey: 'webapp.settings.persons.searchErrorNotification',
      knowledgebaseErrorLocaleKey: 'webapp.settings.persons.personLoadErrorNotification'
    });
    this.showInLeftColumn(this.controller.view);
    this._showPlaceholder();
    this._registerListeners();
  }

  _showPlaceholder() {
    this._closeDetailView();
    this._showDetailController(PersonsPlaceholderController, {});
  }

  _showDetailController(Controller, param) {
    this.detailController = new Controller(param);
    this.detailView = this.detailController.view;
    this.showInRightColumn(this.detailView);  
  }

  _closeDetailView() {
    if (!_.isUndefined(this.detailView)) {
      this.detailView.close();
      delete this.detailView;
      this.detailController.close();
      delete this.detailController;
      this.focusRight(); 
    }
  }

  _registerListeners() {
    this.listenTo(this.controller, 'entity:clicked', this._showPersonDetails);
    this.listenTo(this.controller, 'entity:closed', this._showPlaceholder);
  }

  _showPersonDetails(data) {
    this._showDetailController(
      PersonsDetailController,
      { person: data }
    );

  }
}

export default PersonsSceneController;
