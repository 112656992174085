import Marionette from 'backbone.marionette'
import EditAgentResultView from 'views/editAgentResultView'
import _ from 'underscore'
import I18n from 'i18n'
import Notificator from 'libraries/notificator'
import vent from 'libraries/vent'
import doubleSingleNewLines from 'libraries/doubleSingleNewLines'
import RichTextEditorController from 'controllers/components/richTextEditorController'

class EditAgentResultController extends Marionette.Controller {
  initialize(options) {
    _.bindAll(this, '_whenDataArrives', '_whenWeDontGetData', '_handleSuccessfulSave', '_handleFailedSave')
    this.agentResult = options.agentResult
    this.view = new EditAgentResultView({ model: this.agentResult })

    this._initRichTextEditor()

    this._registerListeners()
  }

  _renderHandler() {
    this.view.showTitleTextPreloader();

    this._showRichTextEditor();
    this.view.useNewTextFormField();
    this.view.useNewTitleFormInput();

    this.agentResult.getFullText(this._whenDataArrives, this._whenWeDontGetData)
  }

  _whenDataArrives(clipFullText, clipRichText) {
    this.listenTo(this.view, 'click:saveAgentResult', () => {
      this._handleAgentResultSave()
    })
    if (!_.isUndefined(clipRichText) && _.isString(clipRichText)) {
      this.richTextEditorController.setRichText(clipRichText)
    } else {
      this.richTextEditorController.setPlainText(clipFullText)
    }
    this.view.showTitleAndTextInputs()
    this.view.enableSaving()
  }

  _handleAgentResultSave() {
    let title = this.view.getTitle()
    let rawText, richText
    rawText = this.richTextEditorController.getPlainText()
    richText = this.richTextEditorController.getRichText()
    let text = doubleSingleNewLines(rawText || '')
    let attachments = this.view.getAttachments()
    let sourceData = this.view.getSourceData();
    let sendingAttachments = false
    sendingAttachments = true

    if(title === "") {
      this.view.showTitleValidationErrorMessage()
    } else {
      this.view.hideTitleValidationErrorMessage()
      this.view.disableSaving()
      this.agentResult.saveTitleFullTextAndAttachments(
        title,
        text,
        richText,
        attachments,
        sendingAttachments,
        sourceData,
        this._handleSuccessfulSave,
        this._handleFailedSave
      )
    }
  }

  _handleSuccessfulSave() {
    this.trigger('edit-form:finished')
    vent.trigger('agentResult:loaded', this.agentResult)
  }

  _handleFailedSave() {
    Notificator.showErrorNotification(I18n.t("webapp.notifications.error.not_saved"))
    this.trigger('edit-form:finished')
  }

  _whenWeDontGetData() {
    Notificator.showErrorNotification(I18n.t("webapp.notifications.error.information_not_loaded"))
    this.trigger('edit-form:finished')
  }

  onClose() {
    this.view.close()
  }

  _initRichTextEditor() {
    this.richTextEditorController = new RichTextEditorController({
      placeholderText: I18n.t("webapp.agents.results.edit_result.full_text")
    })
  }

  _showRichTextEditor() {
    this.view.richTextEditor.show(
      this.richTextEditorController.view
    )
    this.view.hideTextInput()
  }

  _focusOnText() {
    this.richTextEditorController.view.focusOnText();
  }

  _registerListeners() {
    this.listenTo(this.view, 'render', this._renderHandler);
    this.listenTo(this.view, 'focusOnText', this._focusOnText);
    this.listenTo(this.view, 'click:cancelEditAgentResult', () => {
      this.trigger('edit-form:finished')
    })
  }
}

export default EditAgentResultController
