import OptionsProvider, { GlobalSearchOption } from './optionsProvider';
import EntityPrefixSearcher from '../../libraries/entityPrefixSearcher'



type Types = 'topic'
export default class EntitiesOptionsProvider extends OptionsProvider {
  private types: Types[]

  constructor(name: string, types: Types[]) {
    super(name)
    this.types = types
  }

  results(term: string): Promise<GlobalSearchOption[]> {
    const searcher = new EntityPrefixSearcher()
    const searchType = this.types[0] // FIXME: support multiple types
    return searcher.search(term, searchType).then(() => {
      let topics = searcher.results()
      return topics.map((topic) => {
        let name = topic.parseName()
        if (topic.get('parent')) {
          let parentName = topic.get('parent').parseName().trim()
          name = `${name} (${parentName})`
        }
        return {
          id: topic.id,
          type: topic.get('type'),
          text: name,
          priority: 7
        }
      })
    })
  }
}
