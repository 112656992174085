import AbstractPatternController from 'controllers/newsroom/abstractPatternController';
import schedulingOptionsTemplate
    from 'text-loader!templates/newsroom/readerRefreshPatternOptions.html';

class ReaderRefreshPatternController extends AbstractPatternController {
    _setupSubviews() {
        this._setupSchedulingView();
        this._setupWeekdaySelection();
        this._setupTimeSelectorView();
        this._setupHolidayExclusionView();
        this._setupDateExclusionsView();
    }

    scheduleTimeLabelKey() {
        return 'webapp.reader_admin.edit.weekday_schedule.time';
    }
}

ReaderRefreshPatternController.prototype.titleKey =
  'webapp.reader_admin.edit.token_refresh_pattern';
ReaderRefreshPatternController.prototype.schedulingOptionsTemplate =
  schedulingOptionsTemplate;
ReaderRefreshPatternController.prototype.patternAttribute =
  'token_refresh_pattern';
ReaderRefreshPatternController.prototype.scheduleAttribute =
  'token_refresh_schedule';

export default ReaderRefreshPatternController;

