import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/rssFeedWidget.html'

WidgetView.RssFeedWidget = WidgetView.extend({
  template: template,
  onInitialize: function () {
    this.enableAutoRefresh();
  },
  prepareSerializedData: function () {
    return {
      articles: this.model.get('articles'),
      message: this.model.get('message'),
      feed_invalid: this.model.get('feed_invalid')
    };
  }
});

export default WidgetView.RssFeedWidget;

