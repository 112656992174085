import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/socialMediaMonitorListItem.html'
import I18n from 'i18n';

var SocialMediaMonitorListItemView = Marionette.ItemView.extend({
  template: template,
  triggers: {
    'click': 'social_media_monitor:clicked'
  },
  tagName: "a",
  className: function () {
    var className = "button-group__item button--pointing social-media-monitor";
    return className + " " + "social-media-monitor-" + this.model.get('id');
  },
  initialize: function () {
    this.listenTo(this.model, 'change', this.render);
  },
  serializeData: function () {
    const authorMissing =
      I18n.t('webapp.agents.edit.smm.author_missing_placeholder');
    return {
      screen_name: this.model.get('name') || authorMissing
    };
  },
  activate: function () {
    this.$el.addClass('is-active');
  },
  deactivate: function () {
    this.$el.removeClass('is-active');
  }
});

export default SocialMediaMonitorListItemView;

