import _ from 'underscore'
import Report from 'models/report'
import ReportWizard from 'models/reporting/reportWizard'
import TopicSelection from 'models/reporting/wizard/topicSelection'
import TimespanSelection from 'models/reporting/wizard/timespanSelection'
import EditTitleSummary from 'models/reporting/wizard/editTitleSummary'
import FilterSortSettings from 'models/reporting/wizard/filterSortSettings'
import ContentSettings from 'models/reporting/wizard/contentSettings'
import session from 'models/sessionInstance'
import DateFormatter from '../../libraries/dateFormatter'
import I18n from 'i18n'

var MedienspiegelWizard = ReportWizard.extend({
  getReport: function () {
    return new Report({
      title: I18n.t('webapp.reporting.medienspiegel.headline_with_date', {date: DateFormatter.formatDate(new Date())}),
      type: "Medienspiegel"
    });
  },

  initializeStepModels: function () {
    var features = session.get('features');
    var filters = [
      function (step) {
        if ((step instanceof FilterSortSettings) && !features.reports_grouping_sorting) {
          return false;
        } else {
          return true;
        }
      },
      function (step) {
        if ((step instanceof ContentSettings) && !ContentSettings.hasContentSettings()) {
          return false;
        } else {
          return true;
        }
      }
    ];
    return _.filter([
      new TopicSelection({report: this.report}),
      new TimespanSelection({report: this.report}),
      new EditTitleSummary({report: this.report}),
      new FilterSortSettings({report: this.report}),
      new ContentSettings({report: this.report})
    ], function (step) {
      return _.all(filters, function (f) { return f(step); });
    });
  }
});

export default MedienspiegelWizard;

