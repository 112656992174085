import * as React from 'react';
import I18n from 'i18n';
import TimeSelector from 'components/timeSelector';
import WeekdaySelectionButtonGroup from 'components/weekdaySelectionButtonGroup';
import * as _ from 'underscore';
import * as moment from 'moment';
import DateFormatter from 'libraries/dateFormatter';


class ReaderScheduleTrigger extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const schedule = props.schedule;
        const selectedTriggers = props.selectedTriggers;
        this.state = { isOpen: _.contains(selectedTriggers, schedule) };
    }

    render() {
        let listItemClasses = ['collapsible__item'];
        let headerIconClass = 'icon-triangle-up';
        if (this.state.isOpen) {
            listItemClasses.push('is-open');
            headerIconClass = 'icon-triangle-down';
        }
        const buttonGroupProps = {
            values: this.props.values,
            onWeekdaySelection: this.props.onWeekdaySelection,
            object: this.props.schedule
        };
        return (
            <li className={listItemClasses.join(" ")}>
              <div className="collapsible__header" onClick={ this.onHeaderClick.bind(this) }>
                {I18n.t('webapp.reader_admin.edit.notification_configuration.trigger.by_schedule.title')}
                <div className="collapsible__header__action"><i className={headerIconClass}></i></div>
              </div>
              <div className="collapsible__body">
                <div className="collapsible__body__content">
                  <p>{ I18n.t('webapp.reader_admin.edit.notification_configuration.trigger.by_schedule.description') }</p>
                  <div className="flow-container flow-container--padding-s mbm">
                    <div className="flow-container__cell">
                      <WeekdaySelectionButtonGroup {...buttonGroupProps} />
                    </div>
                  </div>

                  <div className="flow-container__cell">
                    <TimeSelector {...this.timeselectorProps()} />
                  </div>
                  <div className="collapsible__body__action">
                    <a href="#" onClick={this.onDestroy.bind(this)}><i className="icon-trash"></i> { I18n.t("webapp.reader_admin.edit.notification_configuration.trigger.remove") }</a></div>

                </div>
              </div>
            </li>
        );
    }

    onHeaderClick() {
        if (!this.state.isOpen) {
            this.props.onTriggerViewOpened(this.props.schedule);
        } else {
            this.props.onTriggerViewClosed(this.props.schedule);
        }
        this.setState({ isOpen: !this.state.isOpen });
    }

    onDestroy(e: any) {
        this.props.onTriggerDestroy(this.props.schedule);
        e.preventDefault();
    }

    timeselectorProps() {
        let formattedTime = DateFormatter.format(
            moment.utc(this.props.schedule.get('time'), "YYYY/MM/DD HH:mm:ss Z"),
            "YYYY/MM/DD HH:mm:ss Z"
        )
        return {
            object: this.props.schedule,
            selectedOption: formattedTime,
            onOptionChange: this.props.onTimeChange,
            options: this.generateOptions()
        };
    }

    generateOptions() {
      const startOfDay = moment.utc(
          '2000/01/01 00:00:00 +00:00', 'YYYY/MM/DD HH:mm:ss Z'
      ).startOf('day');
      const endOfDay = moment.utc(
          '2000/01/01 00:00:00 +00:00', 'YYYY/MM/DD HH:mm:ss Z'
      ).endOf('day');
      const duration: any = 60000 * 15 // 15 minutes
      let current = moment.utc(startOfDay);
      let options: any = [];
      while (current.isBefore(endOfDay)) {
        options = options.concat({
            text: DateFormatter.formatTime(current),
            value: DateFormatter.format(current, "YYYY/MM/DD HH:mm:ss Z")
        });
        current = moment(current).add(duration);
      }
      return options;
    }
}

export default ReaderScheduleTrigger;
