// some polyfills
import 'promise-polyfill/src/polyfill'
import 'whatwg-fetch'

// actual imports
import _ from 'underscore'
import Marionette from 'backbone.marionette'
import session from 'models/sessionInstance'
import sessionPromise from 'models/sessionPromise'
import History from 'libraries/history'
import vent from 'libraries/vent'
import EventLogger from 'libraries/eventLogger'
import Logger from 'libraries/logger'
import ApplicationController from 'controllers/applicationController'
import moment from 'moment'
import I18n from 'i18n'
import QueryString from 'libraries/queryString'
import localePromise from 'models/localePromise'
import Analytics from 'libraries/analytics'
import pusherWrapper from 'models/pusherWrapper'
import Visibility from 'libraries/visibility'
import concurrentSelectionManager from 'models/concurrentSelectionManager'
import 'handlebars'
import 'libraries/handlebarsHelpers'
import 'libraries/marionetteHandlebars'
import 'moment.customizations'
import 'libraries/setupDatepickerLocales'

var App = new Marionette.Application();

// Undefine the event aggregator instances. Use
// libraries/vent instead, you get the same functionality
// without having to depend on the application object.
App.vent = undefined;
App.commands = undefined;
App.reqres = undefined;

// logging
if (_.has(QueryString, 'debug')) {
  Logger.level = Logger.LEVELS.DEBUG;
  Logger.debug('Debug mode is on.');
  window._APP = App
  window._vent = vent
  window._session = session
  window._I18n = I18n
} else {
  Logger.level = Logger.LEVELS.SILENCE;
}

EventLogger.logEventsOn(vent, 'vent');

// add listener to trigg events when the user is away / is back
Visibility.addListener();

App.setLocaleFromSession = function() {
  var localeOverride = QueryString.locale;
  var locale = localeOverride || session.get('locale');
  Logger.debug('Session locale: ' + locale);
  I18n.locale = locale;
  var language = locale.split('-')[0];
  session.set('language', language);
  moment.locale(language);
};

App.subscribeToPusherChannel = function() {
  var channelName = session.get('channel_name');
  pusherWrapper.setChannel(channelName);
  pusherWrapper.startListening();
};

App.subscribeToEndSessionEvent = function() {
  var webSessionId = session.get('web_session_id');
  this.listenTo(pusherWrapper, 'end-session', function (data) {
    if (webSessionId === data.id && !data.impersonated) {
      window.setTimeout(function () {
        window.location.reload();
      }, 2000);
    }
  });
};

App.loadSession = function() {
  return sessionPromise.fetch().then(function() {
    App.setLocaleFromSession();
    localePromise.resolve();

    App.subscribeToPusherChannel();
    App.subscribeToEndSessionEvent();

    concurrentSelectionManager.init();

    App.applyModuleOverride();

    App.applicationController.brandLayout();
    App.applicationController.renderLayout();
    App.setupAnalytics();
  });
};

App.setupAnalytics = function() {
  if (session.get('analytics')) {
    Analytics.setUserProperties({
      br_user_id: session.get('user').id.toString(),
      br_client_id: session.get('client_id').toString(),
      br_app_name: 'blueReport WebApp',
      br_app_version: session.get('application_version'),
    });
  } else {
    Analytics.disableReporting();
  }
};

App.applyModuleOverride = function() {
  if (QueryString.modules) {
    var override = QueryString.modules.split(',');
    session.set('modules', override);
  }
};

App.on('initialize:before', function() {
});

App.on('start', function() {
  Logger.debug('I18n locale on [app start]: ' + I18n.locale);
  Logger.debug(I18n);

  App.Shortcuts.start();

  if (session.hasModule('reports')) {
    App.Reporting.start({
      delay: session.get('reporting_module_loading_offset')
    });
  }

  if (session.hasModule('dashboard')) {
    if(session.hasFeature('new_dashboards')) {
      App.NewDashboard.start();
    } else if (
      session.get('tableau_snippet')
    ) {
      App.TableauDashboard.start();
    } else {
      App.Dashboard.start();
    }
  }

  if (session.hasModule('agents')) {
    Logger.debug('I18n locale on [agents start]: ' + I18n.locale);
    Logger.debug(I18n);
    if(session.hasFeature('show_new_agents_module')) {
      App.NewAgents.start();
    } else {
      App.Agents.start();
    }
  }

  if (session.hasModule('research') && !session.hasFeature('access_to_new_research')) {
    App.Research.start();
  }

  if (session.hasModule('settings')) {
    App.Settings.start();
  }

  var canAccessExecReader =
        session.hasFeature('executive_reader') ||
        session.hasModule('newsroom');

  if (canAccessExecReader) {
    App.Newsroom.start();
  }

  App.Styleguide.start();

  History.startWhenReady();
});

App.createApplicationController = function() {
  this.applicationController = new ApplicationController();
};

App.createApplicationController();

export default App;
