/*
 * Add some format customizations to moment
 */
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/fr-ch'

moment.updateLocale('de', {
  longDateFormat: {
    LT: "H:mm",
    L : "DD.MM.YYYY",
    LL : "D. MMMM YYYY",
    LLL : "D. MMMM YYYY LT",
    LLLL : "dddd, D. MMMM YYYY LT"
  }
});

export default moment;
