import Widget from 'models/dashboard/widget'
import KeySourcesWidget from 'models/dashboard/widget/keySourcesWidget'
import _ from 'underscore'

Widget.KeyCommentSourcesWidget = KeySourcesWidget.extend({
  setCommentAndArticleVolumeClassPerSource: function () {
    this.setArticleVolumeClassPerSource();
    this.setCommentVolumeClassPerSource();
  },
  setCommentVolumeClassPerSource: function () {
    var widget = this;

    _.each(widget.get("sources"), function (source) {
      source.commentVolumeClass = widget.getCommentVolumeClass(source.comments);
    });
  },
  getCommentVolumeClass: function (comments) {
    var tresholds = this.get("tresholds");

    if (_.isUndefined(tresholds)) {
      return 'none';
    } else {
      var lowTreshold    = tresholds[0];
      var mediumTreshold = tresholds[1];

      if (comments > 0 && comments < lowTreshold) {
        return 'low';
      } else if (comments >= lowTreshold && comments < mediumTreshold) {
        return 'medium';
      } else if (comments >= mediumTreshold) {
        return 'high';
      } else {
        return 'none';
      }
    }
  }
});

export default Widget.KeyCommentSourcesWidget;
