import _ from 'underscore';
import BaseObject from 'libraries/baseObject';
import unorm from 'unorm';

export default class OptionsManager extends BaseObject {
    initialize() {
        this.providers = {};
    }

    dataForTerm(options) {
        const term = unorm.nfc(options.term);
        const callback = options.callback;
        const page = options.page;
        const pageSize = 50;
        const enabledProviders = _.sortBy(this.enabledProviders(), 'priority');
        const promises = _.reduce(enabledProviders, (memo, provider) => {
            return memo.concat(provider.results(term));
        }, []);

        const resultsForPromises = {};
        const generateResults = () => {
            let results = _.map(enabledProviders, (provider, index) => {
                return resultsForPromises[index];
            });
            results = _.sortBy(_.flatten(_.compact(results)), 'priority');
            return {
                results: results.slice((page - 1) * pageSize, page * pageSize),
                more: results.length >= page * pageSize
            };
        };

        _.each(promises, (promise, index) => {
            promise.then((results) => {
                if (!_.isUndefined(results)) {
                    resultsForPromises[index] = results;
                    callback(generateResults());
                }
            });
        });
    }

    enabledProviders() {
        return _.pluck(_.filter(this.providers,
                                (provider) => provider.enabled),
                       'provider');
    }

    registerProvider(provider, enabled)  {
        if (!_.isBoolean(enabled)) {
            enabled = true;
        }
        this.providers[provider.name] = {
            provider: provider,
            enabled: enabled
        };
    }

    enableProvider(name) {
        this.providers[name].enabled = true;
    }

    disableProvider(name) {
        this.providers[name].enabled = false;
    }
}
