import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/newsroom/editReaderCategoryTopics.html';
import select2OptionsTemplate
    from 'text-loader!templates/newsroom/editReaderCategoryTopicOptions.html';
import select2CloseOnBlur from 'libraries/select2CloseOnBlur';
import agents from 'collections/agentsInstance';
import tags from 'collections/tagsInstance';
import manualTags from 'collections/manualTagsInstance';
import _ from 'underscore';
import $ from 'jquery';
import unorm from 'unorm';
import I18n from 'i18n';

class EditReaderCategoryTopicsView extends Marionette.ItemView {
    initialize() {
        this.listenTo(this.model, 'change:id', this.render);
        this.listenTo(this.model, 'change:topic_ids', this.render);
        this.triggerSelectionChanges = true;
        this._filterValidTopics();
    }

    onRender() {
        let category = this.model;
        this._prepareSelect2ForTopics();

        if (category.get('id')) {
            this.ui.topicSelect.prop('disabled', false);
        } else {
            this.ui.topicSelect.prop('disabled', true);
        }
    }

    isFilteredByTags() {
        return this.model.get('topic_type') === 'Tag';
    }

    isFilteredByAgents() {
        return this.model.get('topic_type') === 'Agent';
    }

    afterSelectionChanged() {
        const oldValue = this.triggerSelectionChanges;
        this.triggerSelectionChanges = false;
        this.render();
        this.triggerSelectionChanges = oldValue;
    }

    _formatResult(item) {
        if (!item.id) {
            return item.text;
        }

        let iconClass = $(item.element).data('icon-class');

        return '<i class="' + iconClass + '"></i>' + item.text;

    }

    _prepareSelect2ForTopics() {
        let topicSelect = this.ui.topicSelect;
        let select2Options = {
            containerCssClass: 'custom-select2',
            width: '100%',
            formatResult: this._formatResult,
            formatSelection: this._formatResult,
            matcher: function (term, text) {
                let normalizedTerm = unorm.nfc(term).toUpperCase();
                let normalizedText = unorm.nfc(text).toUpperCase();
                return normalizedText.indexOf(normalizedTerm)>=0;
            }
        };
        let optionData = this._initializeSelect2Options();
        let optionHtml = Marionette.Renderer.render(
            select2OptionsTemplate, optionData
        );
        this.triggerSelectionChanges = false;
        select2CloseOnBlur(topicSelect);
        topicSelect.select2(select2Options);
        topicSelect.html(optionHtml);
        topicSelect.trigger('change');
        this.triggerSelectionChanges = true;
    }

    _initializeSelect2Options() {
        let agentOptions = this._agentSelect2Options();
        let manualTagOptions = this._manualTagSelect2Options();
        let smartTagOptions = this._smartTagSelect2Options();
        let optGroups  = [];

        if (this.isFilteredByAgents()) {
            optGroups.push({ label: 'Agents', options: agentOptions });

        } else if (this.isFilteredByTags()) {
            optGroups.push({ label: 'Tags', options: manualTagOptions });
            optGroups.push({ label: 'Smarttags', options: smartTagOptions });

        } else {
            optGroups.push({ label: 'Agents', options: agentOptions });
            optGroups.push({ label: 'Tags', options: manualTagOptions });
            optGroups.push({ label: 'Smarttags', options: smartTagOptions });
        }

        return { optGroups: optGroups };
    }

    _agentSelect2Options() {
        let topicIds = this.model.get('topic_ids');
        let selectableAgents = this.model.readerConfig().get('selectable_agents');

      return _.chain(agents.models).map((agent) => {
            let agentId = agent.get('id');
            let selected = false;
            let shared = agent.get('shared');

          if (_.contains(topicIds,  agentId) && this.isFilteredByAgents()) {
            selected = true;
          }

          let name = shared ? agent.get('name') : `${agent.get('name')} ${I18n.t("webapp.agents.private_agent_name_suffix")}`;
          let disabled = selectableAgents.includes(agentId) ? false : true;

          return {
            id: agentId,
            name: name,
            type: 'Agent',
            iconClass: 'icon-searchagent',
            selected: selected,
            disabled: disabled,
          };
        })
        .value();
    }

    _manualTagSelect2Options() {
        let topicIds = this.model.get('topic_ids');
        return _.map(manualTags.models, (tag) => {
            let tagId = tag.get('id');
            let selected = false;

            if (_.contains(topicIds, tagId) && this.isFilteredByTags()) {
                selected = true;
            }

            return {
                id: tagId,
                name: tag.get('name'),
                type: 'Tag',
                iconClass: 'icon-tag',
                selected: selected,
            };
        });
    }

    _smartTagSelect2Options() {
        let topicIds = this.model.get('topic_ids');
        const smartTags = tags.filter(
            (tag) => { return !tag.isManual(); }
        );
        return _.map(smartTags, (tag) => {
            let tagId = tag.get('id');
            let selected = false;

            if (_.contains(topicIds, tagId) && this.isFilteredByTags()) {
                selected = true;
            }

            return {
                id: tagId,
                name: tag.visibleName(),
                type: 'Tag',
                iconClass: 'icon-smarttag',
                selected: selected,
            };
        });
    }

    _selectionChanged() {
        let selection = this.ui.topicSelect.val();
        let selectionType = this._getSelectedType();

        if (this.triggerSelectionChanges) {
            this.trigger('topic:selection-changed', selection, selectionType);
        }
    }

    _getSelectedType() {
        let selectedData = this.ui.topicSelect.select2('data');
        if (selectedData.length > 0) {
            return selectedData[0].element[0]
                .attributes['data-option-type'].value;
        } else {
            return undefined;
        }
    }

    _filterValidTopics() {
        let topicType = this.model.get('topic_type');
        if (topicType === 'Agent') {
            let topicIds  = this.model.get('topic_ids');
            let ids = _.pluck(agents.models, 'id');
            let validIds = _.map(topicIds, (topicId) => {
                if (_.contains(ids, topicId)) { return topicId; }
            });
            this.model.set({ topic_ids: _.compact(validIds) });
        }
    }
}

EditReaderCategoryTopicsView.prototype.template = template;
EditReaderCategoryTopicsView.prototype.ui = {
    topicSelect: '[data-topics-select]'
};
EditReaderCategoryTopicsView.prototype.events = {
    'change select': '_selectionChanged'
};

export default EditReaderCategoryTopicsView;
