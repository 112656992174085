function doubleSingleNewLines(text: string): string {
  let textWithDoubleNewLines =
    text.replace(/((?:\n)+|(?:\r\n)+)/g, function(m) {
      return (m === '\n' || m === '\r\n') ? '\n\n' : m
    })

  return textWithDoubleNewLines
}

export default doubleSingleNewLines
