import Marionette from 'backbone.marionette'
import $ from 'jquery'
import _ from 'underscore'
import agentResultListTemplate from 'text-loader!templates/agentResultList.html'
import AgentResultListItemView from 'views/agents/agentResultListItemView'
import vent from 'libraries/vent'

var AgentResultListView = Marionette.CompositeView.extend({
  template: agentResultListTemplate,
  itemView: AgentResultListItemView,
  itemViewContainer: "[data-agent-result-list]",
  ui: {
    preloader: '[data-preloader]',
    showMoreButton: '[data-show-more]',
    noResultsMessage: '[no-results-message]'
  },
  appendHtml: function (collectionView, itemView, index) {
    if (index <= 0) {
      collectionView.$(this.itemViewContainer).prepend(itemView.el);
    } else if (index > 0) {
      collectionView.$(this.itemViewContainer).append(itemView.el);
    }
  },
  triggers: {
    'click [data-show-more]': 'clicked-show-more'
  },
  onRender: function () {
    this.updateElementsVisibility();
  },
  updateElementsVisibility: function () {
    if (!_.isString(this.ui.showMoreButton)) {
      if (this._showShowMoreButton) {
        this.ui.showMoreButton.show();
      } else {
        this.ui.showMoreButton.hide();
      }
    }
    if (!_.isString(this.ui.preloader)) {
      if (this._showPreloader) {
        this.ui.preloader.show();
      } else {
        this.ui.preloader.hide();
      }
    }
    if (!_.isString(this.ui.noResultsMessage)) {
      if (this._showDefaultMessage) {
        this.ui.noResultsMessage.show();
      } else {
        this.ui.noResultsMessage.hide();
      }
    }
  },
  scrollingAgentResults: function () {
    if (this.isLoadMoreVisible()) {
      this.trigger('scrolled-to-end-of-list');
    }
  },
  isLoadMoreVisible: function () {
    var showMoreButton = $(this.ui.showMoreButton);
    if (showMoreButton.is(":visible")) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var buttonTop = showMoreButton.offset().top;
      var buttonBottom = buttonTop + showMoreButton.height();

      return ((buttonBottom <= docViewBottom) &&
              (buttonTop - 100 >= docViewTop));
    } else {
      return false;
    }
  },
  initialize: function (options) {
    this.collection = options.results;
    this._resultsLoaded = false;
    this.listenTo(vent, 'layout:scroll-left',
                  this.scrollingAgentResults);
    this._showPreloader = true;
    this._showShowMoreButton = false;
    this._showDefaultMessage = false;
  },
  fetchFinished: function () {
    this._resultsLoaded = true;

    this._showPreloader = false;
    this._showShowMoreButton = true;

    this.updateElementsVisibility();
  },
  allItemsLoaded: function () {
    this._showShowMoreButton = false;
    this.updateElementsVisibility();
  },
  serializeData: function () {
    return {
      resultsLoaded: this._resultsLoaded
    };
  },
  showNoResultsMessage: function () {
    this._showDefaultMessage = true;
    this._showPreloader = false;
    this.updateElementsVisibility();
  },
  showPreloader: function () {
    if (!_.isString(this.ui.preloader)) {
      this._showPreloader = true;
      this._showShowMoreButton = false;
      this.updateElementsVisibility();
    }
  }
});

export default AgentResultListView;

