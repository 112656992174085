import Backbone from 'backbone';
import TimelineDay from 'models/timeline/timelineDay';

class TimelineDays extends Backbone.Collection {
    initialize(timelineMonth) {
        this.timelineMonth = timelineMonth;
    }

    comparator(day) {
        return -day.get('date').unix();
    }

    findSameDay(day) {
        return this.find(function(d) {
            return d.isSame(day);
        });
    }
}

TimelineDays.prototype.model = TimelineDay;

export default TimelineDays;
