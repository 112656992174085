import Backbone from 'backbone';
import _ from 'underscore';

class Summary extends Backbone.Model {
  initialize(options) {
    this.readerConfigurationId = options.readerConfigurationId;
    this.archiveDateId = options.archiveDateId;
  }

  parse(response) {
    if (!_.isEmpty(response)) {
      this.summaries = response;
    }
    return {};
  }

  isPresent() {
    for (const language in this.summaries) {
      const title = this.summaries[language].title;
      if (!_.isUndefined(title) && !_.isNull(title) && title !== '') {
        return true;
      }
    }
  }

  getDefaultTitle() {
    for (const language in this.summaries) {
      const title = this.summaries[language].title;
      if (!_.isUndefined(title) && !_.isNull(title) && title !== '') {
        return title;
      }
    }
  }
    
    getDefaultLanguage() {
        for (const language in this.summaries) {
            const title = this.summaries[language].title;
            if (!_.isUndefined(title) && !_.isNull(title) && title !== '') {
              return language;
            }
        }
        return 'de';
    }

  getTitle(language) {
    return this.summaries && this.summaries[language] && this.summaries[language].title;
  }

  getText(language) {
    return this.summaries && this.summaries[language] && this.summaries[language].text;
  }
    
  setTitle(language, title) {
    if (this.summaries !== undefined) {
      this.summaries[language].title = title;
    } else {
      this.summaries = {[language]: {title}}
    }
  }

  setText(language, text) {
    if (this.summaries !== undefined) {
      this.summaries[language].text = text;
    } else {
      this.summaries = {[language]: {text}}
    }
  }

  saveSummary(language) {
      let attribute = {};
      attribute[language] = this.summaries[language];
      const attr = this.attributes;
      this.attributes = attribute;
      const save = this.save({}, { type: 'PUT' });
      this.attributes = attr;
      return save;
  }
    
    deleteSummary(language) {
      this.set('id', 1)
      const url = `${this.url()}/${language}`
      const destroyPromise = this.destroy({url: url})
      this.setTitle(language, '');
      this.setText(language, '');
      return destroyPromise;
  }

  url() {
    return (
      '/api/v3/reader_configurations/' +
      this.readerConfigurationId +
      '/archive_dates/' +
      this.archiveDateId +
      '/summary'
    );
  }
}

export default Summary;
