/* jshint module: true */
import {Observable} from 'rxjs';
import pusher from 'models/pusherWrapper';
import users from 'collections/usersInstance';
import _ from 'underscore';

class SelectionObserver {
    constructor(collection) {
        this.collection = collection;
    }

    start() {
        this.addSource = Observable.fromEvent(this.collection, 'add')
            .map(result => ['add', result]);
        this.removeSource = Observable.fromEvent(this.collection, 'remove')
            .map(result => ['remove', result]);
        this.addAndRemoveSource = Observable.merge(this.addSource, this.removeSource)
            .bufferTime(500);

        this.selectedAndDeselected = this.addAndRemoveSource.subscribe((event) => {
            let grouped = _.extend(
                    { add: [], remove: [] },
                    _.groupBy(event, (e => e[0]))
            );
            this._sendAgentResultSelection(grouped);
        });
    }

    stop() {
        this._sendDeselectAll();
        this.selectedAndDeselected.unsubscribe();
    }

    _sendAgentResultSelection(group) {
        let added = group.add.map(r => r[1].id);
        let removed = group.remove.map(r => r[1].id);
        let final_selection = _.difference(added, removed);
        let final_deselection = _.difference(removed, added);

        if (!_.isEmpty(final_selection) || !_.isEmpty(final_deselection)) {
            let data = {
                user_id: users.currentUser.id,
                session_id: this._sessionId(),
                removed: final_deselection,
                added: final_selection
            };
            pusher.triggerClientEvent('client-agent-result-selection', data);
        }
    }

    _sendDeselectAll() {
        let remove = this.collection.map(r =>  ["remove", r]);
        let selection = { add: [], remove };
        this._sendAgentResultSelection(selection);
    }

    _sessionId() {
        return pusher.channel.members.me.id;
    }
}

export default SelectionObserver;
