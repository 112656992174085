import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/keySourcesWidget.html'
import _ from 'underscore'

WidgetView.KeySourcesWidget = WidgetView.extend({
  template: template,
  onInitialize: function () {
    this.enableAutoRefresh();
  },
  prepareSerializedData: function () {
    var widget = this.model;

    this.truncateSourceNames();
    widget.setArticleVolumeClassPerSource();

    var values = {
      sources: widget.get('sources')
    };
    return values;
  },
  truncateSourceNames: function () {
    var widgetView = this;
    var widget = this.model;

    _.each(widget.get('sources'), function (source) {
      source.name = widgetView._truncateLabel(source.name, 29);
    });
  }
});

export default WidgetView.KeySourcesWidget;

