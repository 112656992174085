import ModuleController from 'controllers/moduleController'
import _ from 'underscore'
import I18n from 'i18n'
import vent from 'libraries/vent'
import when from 'when'
import session from 'models/sessionInstance'
import deepMerge from 'libraries/deepMerge'
import Shortcutter from 'libraries/shortcutter'
import Tags from 'collections/tags'
import tags from 'collections/tagsInstance'
import sources from 'collections/sourcesInstance'
import agents from 'collections/agentsInstance'
import agentsInstancePromise from 'models/agentsInstancePromise'
import usersInstancePromise from 'models/usersInstancePromise'
import ApogeeLayout from 'views/apogeeLayout'
import TagsOptionsProvider from 'models/globalSearch/tagsOptionsProvider'
import KeywordOptionsProvider from 'models/globalSearch/keywordOptionsProvider'
import SourcesOptionsProvider from 'models/globalSearch/sourcesOptionsProvider'
import EntitiesOptionsProvider from 'models/globalSearch/entitiesOptionsProvider'
import ResearchOptionsProvider from "models/globalSearch/researchOptionsProvider";
import AgentsSidebarController from 'controllers/agents/agentsSidebarController'
import AgentSettingsLayoutController from 'controllers/agents/agentSettingsLayoutController'
import TrashBinLayoutController from 'controllers/agents/trashBinLayoutController'
import AgentsTimelineLayoutController from 'controllers/agents/agentsTimelineLayoutController'
import AgentsListController from 'controllers/agents/agentsListController'
import NewResearchController from 'controllers/agents/newResearchController'
import CloseSubObjects from 'libraries/closeSubObjects'
import SourceCategoriesInstancePromise from "models/sourceCategoriesInstancePromise";

var AgentsController = ModuleController.extend({
  initialize: function() {
    _.bindAll(this,
              'loaded',
              'showLeftHandContainer',
              'showRightHandContainer',
              '_showAgents',
              '_showFilteredTimeline',
              'showNewResearch');
    this._dependenciesPromise().done(this.loaded);
  },
  loaded: function() {
    this.view = new ApogeeLayout();
    this.view.render();

    this.sidebar = new AgentsSidebarController();

    this.showViews();
    this.registerEventListeners();
    this._registerCommandHandlers();
    this._registerGlobalSearchProviders();
    this.showDefaultAgentSelection();
    this._bindShortcuts();
    this.trigger('loaded');
  },
  showViews: function() {
    this.view.showSidebar(this.sidebar.view);
  },
  registerEventListeners: function() {
    this.listenTo(this.sidebar, 'trash-bin-list', this.showTrashBin);
    this.listenTo(this.sidebar, 'edit-agent', this.showEditAgent);
    this.listenTo(this.sidebar, 'add-result', this.showAddResultForm);
    this.listenTo(this.sidebar, 'agent-list', this.showAgentsList);
    this.listenTo(this.sidebar, 'new-research', this.showNewResearch)

    this.listenTo(this.sidebar, 'change:selection', this.showAgentsTimeline);
    this.listenTo(this.sidebar, 'created:agent', this._handleAgentCreation);

    this.listenTo(vent, 'globalSearch:query:changed', this.globalSearchUpdated);

    this.listenTo(vent, 'layout:focus-left', this._handleDetailClosed);
  },
  _registerCommandHandlers: function() {
    vent.commands.setHandler('agents:showAgents', this._showAgents);
    vent.commands.setHandler('agents:showFilteredTimeline', this._showFilteredTimeline);
    vent.commands.setHandler('new-research:query', this.showNewResearch);
  },
  _bindShortcuts: function() {
    var self = this;
    Shortcutter.bindInModule('agents', 'r', function() {
      self._recreateController();
    });
    Shortcutter.bindInModule('agents', 'n', function() {
      self.showAddResultForm();
    });
  },
  _unbindShortcuts: function() {
    Shortcutter.unbindInModule('agents', ['r', 'n']);
  },
  _currentControllerIdentifier: null,
  _isDifferentController: function(identifier) {
    return this._currentControllerIdentifier !== identifier;
  },
  _isLeavingNewResearch: function(identifier) {
    return this._currentControllerIdentifier === 'newResearch' && identifier !== 'newResearch';
  },
  _showController: function(identifier, Class, parameters) {
    this._closeController();
    if (this._isDifferentController(identifier)) {
      this._storeFilters();
      this._restoreFiltersFor(identifier);
    }
    if (this._isLeavingNewResearch(identifier)) {
      this._enableGlobalSearchproviders();
    }

    this._currentControllerIdentifier = identifier;
    this.controller = new Class(
      _.extend(
        parameters,
        { filter: this._cloneFilter() })
    );
    this._registerSubcontrollerEventHandlers();
  },
  _storedFilters: {},
  _storeFilters: function() {
    var f = this._cloneFilter();
    this._storedFilters[this._currentControllerIdentifier] = f;
  },
  _restoreFiltersFor: function(identifier) {
    var newFilter = this._storedFilters[identifier];
    if (_.isObject(newFilter)) {
      this.filter = this._clone(newFilter);
    } else {
      this.filter = this._defaultFilter();
    }

    this._restoreGlobalSearchFilters();
  },
  _defaultFilter: function() {
    return {
      ratings: [null, -10, 0, 10],
      mediaTypes: ['online', 'social_media', 'print', 'radio_tv'],
      resultImportance: ['normal', 'important']
    };
  },
  _restoreGlobalSearchFilters: function() {
    var options = this._filterToGlobalSearchOptions();
    vent.commands.execute('globalSearch:setSelection', options, false);
  },
  _filterToGlobalSearchOptions: function() {
    var options = [];
    var query = this.filter.query;
    if (_.isString(query) && !_.isEmpty(query)) {
      options.push({
        text: query,
        type: 'keyword',
        id: 'keyword: ' + query
      });
    }

    var tagIds = this.filter.tagIds;
    if (_.isArray(tagIds)) {
      var selectedTags = _.map(tagIds, function(id) {
        return tags.get(id);
      });
      options = options.concat(Tags.toGlobalSearchSelect2(selectedTags));
    }

    var sourceIds = this.filter.sourceIds;
    if (_.isArray(sourceIds)) {
      var selectedSources = _.map(sourceIds, function(id) {
        return sources.get(id);
      });
      options = options.concat(sources.toGlobalSearchSelect2(selectedSources));
    }

    return options;
  },
  _clearGlobalSearchFilters: function() {
    vent.commands.execute('globalSearch:setSelection', [], false);
  },
  showAgentsTimeline: function() {
    var agents = this.sidebar.getSelection();
    this._showController(
      'timeline',
      AgentsTimelineLayoutController,
      {
        showLeftHandContainer: this.showLeftHandContainer,
        showRightHandContainer: this.showRightHandContainer,
        agents: agents,
        initialJumpDate: this.initialJumpDate,
        filter: this.filter
      });
    this.initialJumpDate = undefined
  },
  showTrashBin: function() {
    this.sidebar.setSelection([], { silent: true });
    this._showController(
      'trashbin',
      TrashBinLayoutController,
      {
        showLeftHandContainer: this.showLeftHandContainer,
        showRightHandContainer: this.showRightHandContainer
      });
  },
  showEditAgent: function(agent) {
    if (!agent) {
      agent = this.sidebar.getSelection()[0];
    }
    this.sidebar.setSelection([agent], { silent: true });
    this._showController('settings', AgentSettingsLayoutController, {
      agent: agent,
      showLeftHandContainer: this.showLeftHandContainer,
      showRightHandContainer: this.showRightHandContainer
    });
  },
  showAddResultForm: function() {
    if (this._currentControllerIdentifier !== 'timeline') {
      this.showAgentsTimeline();
    }

    this.controller.showClipCreation();
  },
  showAgentsList: function() {
    this._showController(
      'agentList',
      AgentsListController,
      {
        agents: agents,
        showLeftHandContainer: this.showLeftHandContainer,
        showRightHandContainer: this.showRightHandContainer
      });
  },
  _registerSubcontrollerEventHandlers: function() {
    if (!_.isUndefined(this.controller)) {
      this.listenTo(this.controller,
                    'deleted:agent',
                    this._handleAgentDeletion);
      this.listenTo(this.controller,
                    'changed:filter',
                    this._updatedFilter);
      this.listenTo(this.controller,
                    'create-agent',
                    this.sidebar.createNewAgent);
    }
  },
  _updatedFilter: function(filter) {
    this.filter = this._clone(filter);
  },
  _handleAgentDeletion: function() {
    this._updateAgentsCount(-1);
    this.sidebar.updateShowAgents();
    this.showDefaultAgentSelection();
  },
  _handleAgentCreation: function() {
    this._updateAgentsCount(1);
  },
  _updateAgentsCount: function(value) {
    var counts = session.get('counts');
    counts.agents += value;
    session.set('counts', counts);
  },
  showDefaultAgentSelection: function() {
    this.sidebar.makeDefaultSelection();
  },
  showNewResearch: function(query) {
    vent.commands.execute('navigation:activate', 'agents');
    this.sidebar.setSelection([], { silent: true });
    this.sidebar.activateResearch();
    this._disableGlobalSearchProviders();
    this._showController(
      'newResearch',
      NewResearchController,
      {
        showLeftHandContainer: this.showLeftHandContainer,
        showRightHandContainer: this.showRightHandContainer,
        query
      }
    );
  },
  globalSearchUpdated: function(filter) {
    this._mergeFilter(filter);
    this._recreateController();
  },
  _recreateController: function() {
    var id = this._currentControllerIdentifier;
    if (id === 'timeline') {
      this.showAgentsTimeline();
    } else if (id === 'settings') {
      this.showEditAgent();
    } else if (id === 'trashbin') {
      this.showTrashBin();
    } else if (id === 'addResultFrom') {
      this.showAddResultForm();
    } else {
      throw 'invalid controller identifier "' + id + '"';
    }
  },
  onClose: function() {
    this._unbindShortcuts();
    CloseSubObjects.closeAll(
      [this.view,
       this.controller]
    );
  },
  showLeftHandContainer: function(view) {
    this.view.showLeftContainer(view);
  },
  showRightHandContainer: function(view) {
    this.view.showRightContainer(view);
  },
  _mergeFilter: function(filter) {
    this.filter = _.extend(this._cloneFilter(), filter);
  },
  _clone: function(object) {
    return deepMerge({}, object);
  },
  _handleDetailClosed: function() {
    if (!_.isUndefined(this.controller)) {
      this.controller.handleRightColumnClosed();
    }
  },
  _registerGlobalSearchProviders: function() {
    var tagsProvider = new TagsOptionsProvider('tags');
    var sourcesProvider = new SourcesOptionsProvider('sources');
    var topicsProvider = new EntitiesOptionsProvider('entities', ['topic']);
    var keywordProvider = new KeywordOptionsProvider('keyword');

    vent.commands.execute('globalSearch:registerOptionsProvider', tagsProvider, false);
    vent.commands.execute('globalSearch:registerOptionsProvider', sourcesProvider, false);
    vent.commands.execute('globalSearch:registerOptionsProvider', keywordProvider, false);

    if (session.hasFeature('agent_timeline_with_elasticsearch')) {
      vent.commands.execute('globalSearch:registerOptionsProvider', topicsProvider, false);
    }

    if (session.hasFeature('access_to_new_research')) {
      var researchProvider = new ResearchOptionsProvider('research');
      vent.commands.execute('globalSearch:registerOptionsProvider', researchProvider, true);
    }

  },
  _disableGlobalSearchProviders: function() {
    vent.commands.execute('globalSearch:setPlaceholder');
    vent.commands.execute('globalSearch:disableOptionsProvider', 'tags');
    vent.commands.execute('globalSearch:disableOptionsProvider', 'sources');
    vent.commands.execute('globalSearch:disableOptionsProvider', 'keyword');
    if (session.hasFeature('agent_timeline_with_elasticsearch')) {
      vent.commands.execute('globalSearch:disableOptionsProvider', 'entities');
    }
  },
  _enableGlobalSearchproviders: function() {
    var placeholder = I18n.t('webapp.global_search.agents_placeholder');
    vent.commands.execute('globalSearch:setPlaceholder', placeholder);
    vent.commands.execute('globalSearch:enableOptionsProvider', 'tags');
    vent.commands.execute('globalSearch:enableOptionsProvider', 'sources');
    vent.commands.execute('globalSearch:enableOptionsProvider', 'keyword');
    if (session.hasFeature('agent_timeline_with_elasticsearch')) {
      vent.commands.execute('globalSearch:enableOptionsProvider', 'entities');
    }
  },
  _showAgents: function(agentIds) {
    let filters = agentIds.map((id) => {
      return {
        type: 'agent',
        id
      }
    })
    this._showFilteredTimeline(filters)
  },
  /*
    type MediaType = 'online' | 'social_media' | 'print' | 'radio_tv'
    type Filter = SourceFilter | AgentFilter | TagFilter | MediaTypeFilter | RatingFilter | ImportanceFilter | TopicFilter
    interface MediaTypeFilter {
      type: 'mediaType'
      id: MediaType
    }
    interface RatingFilter {
      type: 'rating'
      id: number | null
    }
    interface ImportanceFilter {
      type: 'importance'
      id: 'normal' | 'important'
    }
    interface SourceFilter {
      type: 'source'
      id: number
      name: string
    }
    interface AgentFilter {
      type: 'agent'
      id: number
      name?: string
    }
    interface TagFilter {
      type: 'tag'
      id: number
      name?: string
    }
    interface TopicFilter {
      type: 'topic'
      id: number
      name: string
    }
    interface Select2Option {
      type: 'source'
      id: number
      text: string
    }
   */
  _filtersArrayToFilterObject: function(filters) { // (filters: Array<Filter>) => {old filter object}
    let valuesByType = (type) => {
      return filters.filter((f) => f.type === type).map((f) => f.id)
    }

    let result = {}
    let mapping = {
      tag_ids: 'tag',
      mediaTypes: 'mediaType',
      ratings: 'rating',
      resultImportance: 'importance',
      sourceIds: 'source',
      topicIds: 'topic'
    }
    for (const key in mapping) {
      let type = mapping[key]
      let values = valuesByType(type)
      if (values.length > 0) {
        result[key] = values
      }
    }
    return result
  },
  _filtersArrayToSelect2Options: function(filters) { // (filters: Array<Filter>) => Array<Select2Option>
    let result = []
    filters.forEach((filter) => {
      if (filter.type === 'source') {
        let {id, name} = filter
        result.push({
          id,
          text: name,
          type: 'source'
        })
      } else if (filter.type === 'tag') {
        let tag = tags.get(filter.id)
        result.push(...Tags.toGlobalSearchSelect2([tag]))
      } else if (filter.type === 'topic') {
        let topic = {
          type: 'topic',
          id: filter.id,
          text: filter.name
        };
        result.push(topic)
      }
    })
    return result
  },
  _filtersArrayToSidebarSelection: function(filters) { // (filters: Array<Filter>) => Array<Agent>
    let agentIds = filters.filter((f) => f.type === 'agent').map((f) => f.id)
    if (agentIds.length > 0) {
      return agentIds.map((id) => agents.get(id))
    } else {
      return agents.models // FIXME: does this work with subscriptions?
    }
  },
  _showFilteredTimeline: function(filters, initialJumpDate) { // filters: Array<Filter>
    vent.commands.execute('navigation:activate', 'agents');

    if (initialJumpDate) {
      this.initialJumpDate = initialJumpDate
    }

    this.filter = this._defaultFilters;
    this._mergeFilter(this._filtersArrayToFilterObject(filters))
    let options = this._filtersArrayToSelect2Options(filters)
    let agentSelection = this._filtersArrayToSidebarSelection(filters)

    vent.commands.execute('globalSearch:setSelection', options);
    this.sidebar.setSelection(agentSelection, {
      notifyOnMissingSubscription: true
    });
  },
  _cloneFilter: function() {
    return deepMerge({}, this.filter);
  },
  _closeController: function() {
    if (!_.isUndefined(this.controller)) {
      this.controller.close();
    }
  },
  _defaultFilters: {
    mediaTypes: ['online', 'social_media', 'print', 'radio_tv'],
    ratings: [10, 0, -10, null],
    resultImportance: ['normal', 'important']
  },
  _dependenciesPromise: function() {
    if (session.hasFeature('access_to_new_research')) {
        return when.join(
          usersInstancePromise,
          agentsInstancePromise,
          SourceCategoriesInstancePromise.fetch()
        );
    } else {
        return when.join(
          usersInstancePromise,
          agentsInstancePromise
        );
    }
  }
});

export default AgentsController;
