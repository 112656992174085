import Marionette from 'backbone.marionette';
import * as React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper';
import I18n from 'i18n';
import moment from 'moment';
import DateFormatter from 'libraries/dateFormatter';
import users from 'collections/usersInstance'
import EditableTitleView from 'views/components/editableReactTitleView';
import template from 'text-loader!templates/agents/settings/editAgent.html';
import 'jquery';
import 'underscore';

class EditAgentView extends Marionette.Layout {
  initialize(options) {
    this.model = options.agent;
    this.user = options.user;
  }

  showEditableTitle(props) {
    const editableTitleView = new ReactWrapper({
      getComponent: () => {
        return <EditableTitleView {...props}/>;
      }
    });
    this.editableTitle.show(editableTitleView);
  }

  showTopics(view) {
    this.topics.show(view);
  }

  showSocialMediaMonitors(view) {
    this.socialMediaMonitors.show(view);
  }

  showSourceSelection(view) {
    this.sourceSelection.show(view);
  }

  showEditAgentSettings(view) {
    this.settings.show(view);
  }

  showEditAgentSubscriptions(view) {
    this.subscriptions.show(view);
  }

  showEditAgentUnsubscribeButton(view) {
    this.unsubscribeButton.show(view);
  }

  hideUnSubscribeButton() {
    if (this.unsubscribeButton) {
      this.unsubscribeButton.close();
    }
  }

  showDeletion(view) {
    this.deletion.show(view);
    this.listenTo(view, 'delete:agent', () => {
      this.trigger('delete:agent');
    });
  }

  serializeData() {
    let date = this.fetchCreationDate();
    let fullName = this.fetchCreatorName();
    let meta_information_key;
    if (this.model.get("shared")) {
      meta_information_key = "webapp.agents.edit.settings.meta_information_public" ;
    }
    else {
      meta_information_key = "webapp.agents.edit.settings.meta_information_private";
    }
    let agent_meta_information = I18n.t(meta_information_key,
    { date: date,
      fullname: fullName
    });
    return {
      "read_only": this.options.readOnly,
      "agent_name": this.model.get("name"),
      "agent_meta_information": agent_meta_information,
      "feed_url": this.model.get("feed_url"),
      "topics": this.model.get("topics"),
      "social_media_monitors": this.model.get("social_media_monitors")
    };
  }

  fetchCreationDate() {
    let rawDate;
    if (this.model.get("created_at") > 0) {
      rawDate = moment(this.model.get("created_at") * 1000);
    } 
    else {
      rawDate = new Date();
    };
    return DateFormatter.formatDate(rawDate);
  }

  fetchCreatorName() {
    let fullName;
    if (this.model.get("user_id") && users.get(this.model.get('user_id'))) {
      fullName = users.get(this.model.get('user_id')).get("full_name");
    }
    else if (this.model.get("user_id")) {
      fullName = I18n.t("webapp.agents.edit.settings.deleted_user");
    }
    else {
      fullName = users.currentUser.get('full_name');
    };
    return fullName;
  }

  handleAgentDelClick(event) {
    event.preventDefault();
    this.trigger('showModal');
  }
}
EditAgentView.prototype.template = template;
EditAgentView.prototype.regions = {
  topics: '[data-edit-agent-topics]',
  socialMediaMonitors: '[data-edit-agent-social-media-monitors]',
  sourceSelection: '[data-edit-agent-source-selection]',
  settings: '[data-edit-agent-settings]',
  deletion: '[data-edit-agent-deletion]',
  editableTitle: '[data-editable-title-container]',
  subscriptions: '[data-edit-agent-subscriptions]',
  unsubscribeButton: '[data-edit-unsubscribe-button]'
};
EditAgentView.prototype.events = {
  'click [data-delete]': 'handleAgentDelClick'
};
EditAgentView.prototype.ui = {
  agentDeleteBtn: '[data-delete]',
};

export default EditAgentView;
