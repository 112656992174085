import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/google_plus.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import agentResultReadingTime from 'libraries/agentResultReadingTime'

var GooglePlusView = Marionette.Layout.extend({
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    return this.metadata();
  },
  metadata: function () {
    var agentResult = this.agentResult;
    var data = agentResult.get('clip_metadata');

    var metadata = {};
    var reach = {
      circled_by_count: data.circled_by_count
    };
    conditionalSetToMetadata(metadata, 'reach', reach);

    var engagement = {
      reply_count: data.reply_count,
      share_count: data.share_count,
      plus_one_count: data.plus_one_count
    };
    conditionalSetToMetadata(metadata, 'engagement', engagement);

    var information = {
      reading_time: agentResultReadingTime(this.agentResult)
    };
    conditionalSetToMetadata(metadata, 'information', information);

    return metadata;
  }
});

export default GooglePlusView;

