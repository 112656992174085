import * as React from "react";
import { PlaceholderView, Props } from 'components/placeholderView'
import I18n from "i18n";
import ReactController from 'controllers/reactController'

class OrganizationsPlaceholderController extends ReactController<Props> {
  getComponent(props: Props): JSX.Element {
    return (
      <PlaceholderView {...props} />
    )
  }
  defaultProps(): Props {
    return {
      label: I18n.t('webapp.settings.organizations.placeholder')
    }
  }
}

export default OrganizationsPlaceholderController