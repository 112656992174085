import Widget from 'models/dashboard/widget'

Widget.TagChartWidget = Widget.extend({
  clipCount: function () {
    return this._countClipsInChartData(this.get('chart_data'));
  },
  tagIds: function () {
    return [this.get('tag_id')];
  }
});

export default Widget.TagChartWidget;

