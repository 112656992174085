import Marionette from 'backbone.marionette';
import ReaderSidebarView from 'views/layoutView';
import NewsroomSidebar from 'components/newsroomSidebar'
import readerConfigurations from 'collections/newsroom/configurationsInstance';
import session from 'models/sessionInstance';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import * as _ from 'underscore';
import vent from 'libraries/vent'

class ReaderSidebarController extends Marionette.Controller {
  initialize() {
    this.collection = readerConfigurations;
    this.view = new ReaderSidebarView();
    this._registerListeners();
  }

  renderView(additionalOpts?: object) {
    const sidebarView = new ReactWrapper({
      getComponent: () => (<NewsroomSidebar {...this.buildProps(additionalOpts)} />)
    });
    this.view.showView(sidebarView);
  }

  buildProps(additionalProps?: object): AgentsSidebarComponentProps {
    const readerLinkClick = (config: ReaderConfiguration) =>
      this._handleReaderLinkClick(config)
    const readerEditLinkClick = (config: ReaderConfiguration) =>
      this._handleReaderEditLinkClick(config)
    const readerCategoryClick = (category: ReaderCategory) =>
      this._handleReaderCategoryClick(category)
    const addReaderClick = (templateName) => this._handleAddReaderClick(templateName)
    const summaryClick = (config: ReaderConfiguration) =>
      this._handleSummaryClick(config)
    const base = {
      useNewReaderManagement: session.hasFeature('use_new_reader_management'),
      allowedToAddReaderConfig: this._allowedToAddReaderConfig(),
      readerConfigurations: readerConfigurations.models,
      onReaderLinkClick: readerLinkClick,
      onReaderEditLinkClick: readerEditLinkClick,
      onCategoryClick: readerCategoryClick,
      onAddReaderClick: addReaderClick,
      onSummaryClick: summaryClick,
      contractVersion: session.contractVersion(),
      isAdmin: session.isGroupAdmin()
    }

    const full = _.extend(base, additionalProps)
    return full
  }

  _handleAddReaderClick(templateName) {
      if (this._allowedToAddReaderConfig()) {
        this.trigger('add-reader:clicked', templateName);
      }
  }

  _handleReaderCategoryClick(category: ReaderCategory) {
    this.selection = category
    this.trigger('category:clicked', category);
  }

  _handleSummaryClick(config: ReaderConfiguration) {
    this.selection = config
    this.trigger('summary-link:clicked', config);
  }

  _handleReaderEditLinkClick(config: ReaderConfiguration) {
    this.selection = config
    this.trigger('edit-link:clicked', config);
  }

  _handleReaderLinkClick(config: ReaderConfiguration) {
    let readerLink = config.get('newest_reader_link');
    if (readerLink) {
      window.open(readerLink, '_blank');
    }
  }

  _registerListeners() {
    this.listenTo(this.view, 'render', () => this.renderView());
    this.listenTo(this.collection, 'add',
                  this.renderView);
    this.listenTo(this.collection, 'remove',
                  this.renderView);
    this.listenTo(this.collection, 'change',
      () => this.renderView());
    this.listenTo(vent, 'refreshReaderSidebar', () => {
      this.renderView()
    });
  }

  selectFirst() {
    if (readerConfigurations.length > 0) {
      const reader = readerConfigurations.first()
      if (reader.categories.length > 0) {
        var category = readerConfigurations.first().categories.first();
        this.selection = category;
        this.trigger('category:clicked', category);
      } else {
        this.selection = reader;
        this.trigger('summary-link:clicked', reader);
      }
      this.renderView();
    }
  }

  deselectAll() {
    this.selection = null
  }

  _allowedToAddReaderConfig() {
    return !this._isReaderQuotaReached() &&
      (session.hasFeature('use_new_reader_management') ||
       session.isGroupAdmin())
  }

  _isReaderQuotaReached() {
    let limit = session.get('features').executive_reader;
    return limit >= 0 && this.collection.length >= limit;
  }
}

export default ReaderSidebarController;
