import Marionette from "backbone.marionette";
import SourceExclusionItemView from "views/sourceExclusionItemView";
import template from "text-loader!templates/sourceExclusionCollection.html";
import ReactWrapper from 'views/marionetteReactWrapper';
import SourceSearch from "components/sourceSearch";
import * as React from 'react';

class SourceExclusionsCollectionView extends Marionette.CompositeView {
  initialize() {
    this.listenTo(this, 'render', () => {
      this.searchForm = new Marionette.Region({ el: this.ui.searchForm });
    });
  }

  appendHtml(collectionView, itemView) {
    this.ui.searchForm.before(itemView.el);
  }

  showSearchForm(viewProps) {
    const sourceSearchView = new ReactWrapper({
      getComponent: () => {
        return <SourceSearch {...viewProps} />;
      }
    });
    this.searchForm.show(sourceSearchView);
  }

  displaySearchForm() { this.$(this.ui.searchForm).show(); }
  hideSearchForm() { this.$(this.ui.searchForm).hide(); }
}

SourceExclusionsCollectionView.prototype.template = template;
SourceExclusionsCollectionView.prototype.tagName = "ul";
SourceExclusionsCollectionView.prototype.className = "input-group input-group--compact";
SourceExclusionsCollectionView.prototype.itemView = SourceExclusionItemView;
SourceExclusionsCollectionView.prototype.ui = {
    searchForm: '[data-search-form]'
};

export default SourceExclusionsCollectionView;
