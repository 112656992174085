import * as React from 'react';
import I18n from 'i18n';
import DeleteButtonView
    from 'views/newsroom/readerEditNotificationConfigurationDeleteButtonView';
import EditableTitleView from 'views/components/editableReactTitleView';
import Dropdown from 'components/dropdown';
import {ReaderNotificationConfigurationSenderButtonsView, AvailableButtons}
    from 'views/newsroom/readerNotificationConfigurationSenderButtonsView';
import { ReaderNotificationConfigurationSenderFormView }
    from 'views/newsroom/readerNotificationConfigurationSenderFormView';
import ReaderNotificationTriggersView from 'views/newsroom/readerNotificationTriggersView';
import ReaderNotificationTriggerNotesView from 'views/newsroom/readerNotificationTriggerNotesView';
import HolidayExclusionView from 'components/holidayExclusionView';
import DateExclusionView from 'components/dateExclusionView';
import Notificator from 'libraries/notificator';
import users from 'collections/usersInstance'

class ReaderEditNotificationConfigurationView extends React.Component<any, any> {
    render() {
        const configurationTemplateLabel =
            I18n.t('webapp.reader_admin.edit.notification_configuration.template_label');
        const exceptionsHeader =
            I18n.t('webapp.reader_admin.edit.notification_configuration.exceptions');
        const holidayExceptionsLabel =
            I18n.t('webapp.reader_admin.edit.weekday_schedule.exceptions_holidays');
        const dateExceptionsLabel =
            I18n.t('webapp.reader_admin.edit.weekday_schedule.exceptions_individual');
        const previewButtonLabel =
            I18n.t('webapp.reader_admin.edit.notification_configuration.preview.button_label');
        const previewButtonText =
          previewButtonLabel + ' ' + this.currentUserEmail();

        return (
            <div className="lightened-container lifted-container ptm phl pbl">
              <div className="link-bar mbm">
                {this.renderDeleteButton()}
              </div>

              <EditableTitleView {...this.props.editableTitleViewProps} />

              <div className="row mtl">
                <div className="col1of2">
                  <form className="form-item--inline mbs">
                    <label htmlFor="absendername" className="form-item__label">
                      {configurationTemplateLabel}
                    </label>
                    <div className="form-item__input">
                      <div data-template-dropdown></div>
                      <Dropdown {...this.props.dropdownProps} />
                    </div>
                  </form>
                </div>

                <div className="col2of2">
                    <ReaderNotificationConfigurationSenderButtonsView {...this.props.senderButtonGroupProps}/>
                </div>
              </div>

              <div>
                <ReaderNotificationConfigurationSenderFormView {...this.props.senderFormProps}/>
              </div>

              <hr className="separator mvl" />

              <div>
                <ReaderNotificationTriggersView {...this.props.triggersProps}/>
              </div>
              <ReaderNotificationTriggerNotesView {...this.props.triggerNotesProps}/>

              <hr className="separator mvl" />

              <h3 className="heading-xlarge mbs">{exceptionsHeader}</h3>
              <form className="form-item mbm">
                <label htmlFor="absendername" className="form-item__label">
                  {holidayExceptionsLabel}
                </label>
                <div className="form-item__input">
                   <HolidayExclusionView {...this.props.holidayExclusionsProps}/>
                </div>
              </form>

              <form className="form-item mbm">
                <label htmlFor="absendername" className="form-item__label">
                  {dateExceptionsLabel}
                </label>
                <div className="form-item__input">
                  <DateExclusionView {...this.props.dateExclusionsProps}/>
                </div>
              </form>
              <div className="ptm">
                <a id="topic_save" className="button button--primary button--block" onClick={this.sendNotificationClicked.bind(this)}>{previewButtonText}</a>
              </div>
            </div>
        );
    }

    sendNotificationClicked() {
        const params = {
          notification_configuration_id: this.props.notificationConfigurationId,
          email: this.currentUserEmail()
        }
        const url =
        '/api/v3/notifications/send_notification_preview_to_user';
        $.post(url, params)
            .done(() => this.notificationSuccess())
            .fail(() => this.notificationFailure());
    }

    notificationSuccess() {
        const key =
        'webapp.reader_admin.edit.notification_configuration.preview.sent';
        const msg = I18n.t(key, { email: this.currentUserEmail() });
        Notificator.showNotification(msg);
    }

    notificationFailure() {
        const key =
          'webapp.reader_admin.edit.notification_configuration.preview.error'
        Notificator.showNotification(I18n.t(key));
    }

    currentUserEmail() {
        return users.currentUser.get('email');
    }

    renderDeleteButton() {
        if (this.props.displayDeleteButton) {
            const deleteButtonProps = { onDeleteClick: this.props.onDeleteClick };
            return <DeleteButtonView {...deleteButtonProps} />;
        }
    }
}

export default ReaderEditNotificationConfigurationView;
