import template from 'text-loader!templates/newsroom/readerResultHeaderActions.html';
import AgentResultHeaderActionsView from 'views/agents/agentResultHeaderActionsView';
import handleTooltips from 'models/handleTooltips';
import SendEmailModal from '../../components/SendEmailModal';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import session from 'models/sessionInstance';
import SearchableDropdown from 'components/SearchableDropdown';
import AddToReaderClient from 'components/addToReaderClient';
import ShowClipIdHeader from 'components/ShowClipIdHeader';

class ReaderResultHeaderActionsView extends AgentResultHeaderActionsView {
    initialize() {
        handleTooltips(this);
    }
    serializeData() {
        let presenter = this.options.presenter;
        const clipDownloadEnabled = session.hasFeature('clip_download_enabled');
        return {
            canShareArticleViaEmail: session.shareSelectedArticlesViaEmail(),
            canBeAddedToReader: session.hasFeature('use_new_reader_management'),
            isOrderableOnlineClip: this.model.isOrderableOnlineClip(),
            isOrderable: this.model.isOrderable(),
            isOrdered: this.model.isOrdered(),
            isCuttable: this.model.isCuttable(),
            isEditable: this._isReaderResultEditable(),
            isClipEditable: this._isClipEditable(),
            downloadable: presenter.canDownloadResult(),
            clipDownloadEnabled: clipDownloadEnabled,
        }
    }

    onRender() {
        this.delegateEvents();
        if (session.shareSelectedArticlesViaEmail()) {
            this.showArticleViaEmailModal()
        }
        if (session.hasFeature('use_new_reader_management')) {
            const props = {
              onCategorySelect: (confId, catId) => {
                  new AddToReaderClient(confId, catId).fromAgentResults(
                      [this.model.get('id')]
                  );
              }
            };
            let component = <SearchableDropdown {...props} />
              let componentWrapper = new ReactWrapper({
                  tagName: 'span',
                  getComponent: () => component
              })
              this.searchableDropdown.show(componentWrapper);
        }
        if (session.isImpersonated() || session.can('internal_access')) {
            this.showClipId();
        }
    }

    showArticleViaEmailModal() {
        let component = <SendEmailModal emailAgentResultIds={[this.model.get("id")]} emailClipIds={[]}/>
            let componentWrapper = new ReactWrapper({
                getComponent: () => component
            })
        this.articleViaEmail.show(componentWrapper);
    }

    _isReaderResultEditable() {
        return session.hasFeature('agent_result_editing_possible') &&
          this.model.isEditable()
    }

    _isClipEditable() {
        return session.can('edit_clips_globally')
    }
    showClipId() {
        let component = <ShowClipIdHeader clipId={this.model.get("clip_id")} agentResultId={this.model.get("id")}/>
            let componentWrapper = new ReactWrapper({
            getComponent: () => component
            })
        this.clipId.show(componentWrapper);
    }
}
ReaderResultHeaderActionsView.prototype.template = template;
ReaderResultHeaderActionsView.prototype.events = {
    'click [data-clip-order]': '_handleOrderClick',
};
ReaderResultHeaderActionsView.prototype.triggers = {
    'click #clip-download': 'clicked:download',
    'click [data-reader-result-delete]': 'click:deleteReaderResult',
    'click [data-reader-result-and-tags-delete]': 'click:deleteReaderResultAndTags',
    'click [data-result-translate]': 'click:translate',
    'click [data-result-cut]': 'click:cut',
    'click [data-result-edit]': 'click:editAgentResult',
    'click [data-clip-edit]': 'click:editClip',
};
ReaderResultHeaderActionsView.prototype.regions = {
    articleViaEmail: '[data-article-email]',
    searchableDropdown: '[data-add-to-reader]',
    orderButton: '[data-clip-order]',
    orderModal: '[data-order-modal]',
    noQuotaModal: '[data-noquota-modal]',
    clipId: '[data-show-clip-id]'
};

export default ReaderResultHeaderActionsView;
