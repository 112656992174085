import Backbone from 'backbone';
import _ from 'underscore';
import RestorableModel from "libraries/restorableModel";

class Category extends RestorableModel {
    parse(response) {
        if (response.category) {
            return response.category;
        } else if (response.object) {
            return response.object;
        } else {
            return response;
        }
    }

    toJSON() {
        let json = Backbone.Model.prototype.toJSON.call(this);
        let readerCategoryJson = {
            reader_configuration_id: json.reader_configuration_id,
            languages: json.languages,
            media_types: json.media_types,
            name: json.name,
            position: json.position,
            only_important_results: json.only_important_results,
            topic_ids: json.topic_ids,
            topic_type: json.topic_type
        };
        return _.extend(json, { reader_category: readerCategoryJson });
    }

    urlRoot() {
        return '/api/v3/reader_configurations/' +
               this.get('reader_configuration_id') +
               '/categories/';
    }

    readerConfig() {
        return this.collection.readerConfig;
    }
}

export default Category;
