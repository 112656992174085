import * as React from "react";
import I18n from "i18n";
import * as _ from "underscore";
import ReaderNotificationTriggers from "collections/newsroom/readerNotificationTriggers";
import ReaderNotificationTrigger from "models/newsroom/readerNotificationTrigger";

interface IReaderNotificationTriggerNotes {
  triggers: ReaderNotificationTriggers,
  editorialReader: boolean;
}

class ReaderNotificationTriggerNotesView extends React.Component<IReaderNotificationTriggerNotes, {}> {
  render() {
    const triggers: ReaderNotificationTrigger[] = this.props.triggers.models;
    const triggerTypes: string[] = _.map(this.props.triggers.models, (trigger) => {
      return trigger.get("trigger_type");
    });
    if (this.props.editorialReader && _.contains(triggerTypes, "new_results")) {
      return <p>{I18n.t(`${this.triggerI18nPath()}.new_results.note`)}</p>;
    } else {
      return null;
    }
  }

  private triggerI18nPath(): string {
    return "webapp.reader_admin.edit.notification_configuration.trigger";
  }
}

export default ReaderNotificationTriggerNotesView;
