import SceneController from 'controllers/sceneController'
import _ from 'underscore'
import UserListController from 'controllers/settings/userListController'
import UserPlaceholderView from 'views/settings/userPlaceholderView'
import UserSettingsController from 'controllers/settings/userSettingsController'
import OtherUserSettingsController from 'controllers/settings/otherUserSettingsController'
import NewUserController from 'controllers/settings/newUserController'
import users from 'collections/usersInstance'
import agents from 'collections/agentsInstance'
import User from 'models/user'
import session from 'models/sessionInstance'

var UsersSceneController = SceneController.extend({
  show: function () {
    if (session.isGroupAdmin()) {
      this._adminMode();
    } else {
      this._defaultMode();
    }
  },
  _defaultMode: function () {
    this._showPlaceholder();
    this._showDefaultUserSettings();
  },
  _showDefaultUserSettings: function () {
    if (!_.isUndefined(this.controller)) {
      this.controller.close();
      delete this.controller;
    }
    this.controller = new UserSettingsController({
      user: users.currentUser
    });
    this.showInLeftColumn(this.controller.view);
    this.listenTo(
      this.controller,
      'user-settings:cancelled',
      this._showDefaultUserSettings
    );
  },
  _adminMode: function () {
    this.listController = new UserListController();
    this.showInLeftColumn(this.listController.view);
    this._registerListListeners();
    // for some reason, users.currentUser is not the same
    // instance as the one in the collection
    var currentUser = users.get(users.currentUser.id);
    this.listController.selectUser(currentUser);
    this._showOwnUserSettings();
  },
  _registerListListeners: function () {
    this.listenTo(this.listController, 'user:selected', this._userSelected);
    this.listenTo(this.listController, 'user:deselected', this._closeUserSettings);
    this.listenTo(this.listController, 'user:addClicked', this._addUser);
  },
  _userSelected: function (user) {
    this._showUserSettings(user);
  },
  _addUser: function () {
    this._showUserSettingsController(new NewUserController({
      user: new User()
    }));
  },
  _showUserSettings: function (user) {
    this._closeUserSettings();
    if (user.id === users.currentUser.id) {
      this._showOwnUserSettings();
    } else {
      this._showOtherUserSettings(user);
    }
  },
  _showOwnUserSettings: function () {
    this._showUserSettingsController(new UserSettingsController({
      user: users.currentUser
    }));
  },
  _showOtherUserSettings: function (user) {
    this._showUserSettingsController(new OtherUserSettingsController({
      user: user
    }));
  },
  _showUserSettingsController: function (controller) {
    this.userSettingsController = controller;
    this.showInRightColumn(controller.view);
    this._registerSettingsListeners();
  },
  _registerSettingsListeners: function () {
    var c = this.userSettingsController;
    this.listenTo(c, 'user:saved', this._closeUserSettings);
    this.listenTo(c, 'user:cancelled', this._closeUserSettings);
    this.listenTo(c, 'user:deleted', this._deleteUser);
  },
  _deleteUser: function (user) {
    if (session.hasFeature("new_search_agent_management")) {
      var privateAgents = _.filter(user.agents.models, (agent) => {
        return agent.get('shared') === false;
      })
      agents.remove(privateAgents);
    } else {
      agents.remove(user.agents.models);
    }
    this._closeUserSettings();
  },
  _closeUserSettings: function () {
    if (!_.isUndefined(this.userSettingsController)) {
      this.userSettingsController.close();
      delete this.userSettingsController;
      this._showPlaceholder();
      this.listController.deselectAll();
    }
  },
  _showPlaceholder: function () {
    var placeholder = new UserPlaceholderView();
    this.showInRightColumn(placeholder);
    this.focusRight();
  }
});

export default UsersSceneController;

