import session from 'models/sessionInstance';

const UPDATE: 'app/user/UPDATE' = 'app/user/UPDATE';

export interface User {
  id: number;
}

const initialState = {
  id: session.get('user') ? session.get('user').id : -1,
};

//Reducer
export default function user(
  state: User = initialState,
  action: UpdateAction
): User {
  switch (action.type) {
    case UPDATE:
      return { ...state, id: action.payload };
    default:
      return state;
  }
}

// Action Creators
export interface UpdateAction {
  type: typeof UPDATE;
  payload: number;
}
export function update(payload: number): UpdateAction {
  return { type: UPDATE, payload };
}
