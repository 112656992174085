import Marionette from 'backbone.marionette'
import _ from 'underscore'
import template from 'text-loader!templates/agents/agentResultActions.html'
import AgentResultTagFormatter from 'libraries/agentResultTagFormatter'
import AgentResults from 'collections/agentResults'

var AgentResultActionsView = Marionette.Layout.extend({
  template: template,
  regions: {
    mark_important: '[data-mark-important-partial]',
    ratings: '[data-ratings-partial]',
    comments: '[data-comments-partial]',
    tags:    '[data-tags-partial]',
    agentEditWarning: '[data-agent-edit-warning]'
  },
  triggers: {
    'change #tags': 'changed:tags'
  },
  initialize: function () {
    _.bindAll(this, 'showAgentEditWarning');
  },
  showMarkImportant: function (view) {
    this.mark_important.show(view);
  },
  showRatings: function (view) {
    this.ratings.show(view);
  },
  showTags: function (view) {
    this.tags.show(view);
  },
  showComments: function (view) {
    this.comments.show(view);
  },
  showAgentEditWarning: function (view) {
    this.agentEditWarning.show(view);
  },
  serializeData: function () {
    var agentResult = this.model;
    var agentResultTagFormatter = new AgentResultTagFormatter(
      new AgentResults(agentResult)
    );

    return {
      tags: agentResultTagFormatter.stringTags()
    };
  }
});

export default AgentResultActionsView;

