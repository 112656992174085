import * as React from "react";

// TODO: replace with components/detailViewContainer
export const EntityDetailViewContainer = (props: any) => {
  return (
    <div className="lightened-container lifted-container pts phl pbl">
      {props.children}
    </div>
  )
}

export default EntityDetailViewContainer
