import _ from 'underscore'
import BaseObject from 'libraries/baseObject'

var AgentResultTagFormatter = BaseObject.extend({
  initialize: function (agentResults) {
    this.agentResults = agentResults;
  },
  intersectedResultTags: function () {
    var tags =  _.map(this.agentResults.models, function (agentResult) {
      return agentResult.get('tags');
    });
    var tagNames = _.map(tags, function (tag) {
      if (tag && tag.pluck) { return tag.pluck('name') }
    });
    var commonTags = _.intersection(...tagNames);
    return _.flatten(commonTags);
  }
});

var AgentResultTagFormatterPublicInterface = BaseObject.extend({
  initialize: function (agentResults) {
    this.formatter = new AgentResultTagFormatter(agentResults);
    this.separator = '^';
  },
  stringTags: function () {
    return this.formatter.intersectedResultTags().join(this.separator);
  },
  initSelectionArray: function (element) {
    return _.map(element.val().split(this.separator), function(name) {
      return { id: name, text: name};
    });
  },
});

export default AgentResultTagFormatterPublicInterface;
