import * as React from 'react'
import * as ReactDOM from 'react-dom'
import I18n from 'i18n'
import * as _ from 'underscore'

interface Attachment {
  url: string
  caption?: string
  name?: string
  extension?: string
  size?: number
}

interface Props {
  attachments: Attachment[]
}

export default class ClipAttachmentList extends React.Component<Props, {}> {
  render() {
    return (
      <div className="clip-detail__footer">
        <div className="meta__title">{I18n.t('webapp.components.ClipAttachmentList.title')}</div>
        <ul className="file-list">
          {this.attachments()}
        </ul>
      </div>
    )
  }

  private attachments(): JSX.Element[] {
    return this.props.attachments.map((a) => <ClipAttachment attachment={a} key={a.url}/>)
  }
}

interface AttachmentProps {
  attachment: Attachment
}

class ClipAttachment extends React.Component<AttachmentProps, {}> {
  render() {
    return (
        <li>
          <a href={this.url()} title={this.fullName()} target="_blank" className="file-list__link">
            <span className="file-list__name">
              <i className="icon-download"></i>
              {' '}
              {this.name()}
            </span>
            {' '}
            <span className="file-list__meta">
              {this.fileInfo()}
            </span>
          </a>
        </li>
      )
  }

  private attachment(): Attachment {
    return this.props.attachment
  }

  private url(): string {
    return this.attachment().url
  }

  private fullName(): string | undefined {
    let a = this.attachment()
    if (a.caption) {
      return a.caption
    } else if (a.name) {
      return a.name
    }
  }

  private name(): string | JSX.Element {
    let fullName = this.fullName()
    if (fullName) {
      return fullName
    } else {
      return <i>{I18n.t('webapp.components.ClipAttachmentList.unnamed')}</i>
    }
  }

  private fileInfo(): string {
    let info = _.compact([this.extension(), this.size()])
    if (info.length > 0) {
      return `(${info.join(', ')})`
    } else {
      return ''
    }
  }

  private extension(): string | undefined {
    let a = this.attachment()
    if (a.extension) {
      return a.extension.toLowerCase()
    }
  }

  private size(): string | undefined {
    let a = this.attachment()
    if (a.size) {
      return I18n.toHumanSize(a.size, {
        format: I18n.t('number.human.storage_units.format')
      })
    }
  }
}
