import EditAgentResultController from 'controllers/editAgentResultController'
import customReaderBrandingStyleTagView from 'views/components/customReaderBrandingStyleTagView'
import readerConfigurationsInstance from 'collections/newsroom/configurationsInstance'
import EditAgentResultView from 'views/editAgentResultView'
import _ from 'underscore'


class EditReaderResultController extends EditAgentResultController {
  initialize(options) {
      _.bindAll(this, '_whenDataArrives', '_whenWeDontGetData', '_handleSuccessfulSave', '_handleFailedSave')
      this.readerResult = options.readerResult;
      this.readerConfig = readerConfigurationsInstance.get(this.readerResult.collection.readerConfigurationId);
      this.brandingStyleView = new customReaderBrandingStyleTagView({configuration: this.readerConfig});
      this.agentResult = options.agentResult
      this.view = new EditAgentResultView({ model: this.agentResult })

      this._initRichTextEditor();

      this._registerListeners()
  }

  _renderHandler() {
    this.view.showTitleTextPreloader();

    this._showRichTextEditor();
    this.view.useNewTextFormField();
    this.view.useNewTitleFormInput();
    this.view.includeReaderBranding(this.brandingStyleView);

    this.agentResult.getFullText(this._whenDataArrives, this._whenWeDontGetData)
  }
}

export default EditReaderResultController
