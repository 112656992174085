import viewTemplate from 'text-loader!templates/reporting/wizard/clipTopicSelection.html'
import StepView from 'views/reporting/wizard/stepView'

var View = StepView.extend({
  template: viewTemplate,
  className: "wizard-step rounded clearfix",
  initialize: function () {
    StepView.prototype.initialize.call(this);
  },
  onRender: function () {
    this.model.checkForCompleteness();
  }
});

export default View;

