import 'highcharts_legacy'

var formatNumber = function (number, withSign) {
  var sign = '';
  if (withSign && number > 0) {
    sign += '+';
  }
  return sign + Highcharts.numberFormat(number, 0, ',', '&#x202f;');
};

export default formatNumber;

