import Backbone from 'backbone';
import ReaderNotificationSchedule from 'models/newsroom/readerNotificationSchedule';

class ReaderNotificationSchedules extends Backbone.Collection {
    initialize(models, options) {
        this.notificationConfiguration = options.notificationConfiguration;
    }

    url() {
        const notificationConfigId = this.notificationConfiguration.get('id');
        return `api/v3/notification_configurations/${notificationConfigId}` +
                '/notification_schedules';
    }
}

ReaderNotificationSchedules.prototype.model = ReaderNotificationSchedule;

export default ReaderNotificationSchedules;
