import Marionette from "backbone.marionette";
import template from "text-loader!templates/agents/metadata/sopranoParlamentChView.html";
import agentResultReadingTime from "libraries/agentResultReadingTime";
import DateFormatter from 'libraries/dateFormatter'

class SopranoArgusView extends Marionette.ItemView {
  initialize() {
    this.agentResult = this.options.agentResult;
  }

  serializeData() {
    const languageCodes = this.agentResult.get('languages');
    const author = this.agentResult.get('clip_metadata').author;
    let lastUpdate = this.agentResult.get('clip_metadata').last_update;
    let publishTime = this.agentResult.get('publish_time');
    if (lastUpdate) {
      lastUpdate = DateFormatter.formatDate(new Date(lastUpdate))
    }
    if (publishTime) {
      publishTime = DateFormatter.formatDate(new Date(publishTime * 1000))
    }

    return {
      languages: languageCodes,
      readingTime: agentResultReadingTime(this.agentResult),
      author: author,
      lastUpdate: lastUpdate === publishTime ? null : lastUpdate,
      publishTime: publishTime,
    };
  }
}

SopranoArgusView.prototype.template = template;

export default SopranoArgusView;
