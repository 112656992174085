import WidgetView from 'views/dashboard/widgetView'
import TopSourcesWidgetView from 'views/dashboard/widgetView/topSourcesWidgetView'
import template from 'text-loader!templates/dashboard/widget/tagTopSourcesWidget.html'

WidgetView.TagTopSourcesWidget = TopSourcesWidgetView.extend({
  template: template
});

export default WidgetView.TagTopSourcesWidget;

