import { createRoot } from 'react-dom/client';
import Marionette from 'backbone.marionette';

export default class MarionetteReactWrapper extends Marionette.ItemView {
  initialize() {
    if (this.options.tagName) {
      this.tagName = this.options.tagName;
    }
    if (this.options.className) {
      this.className = this.options.className;
    }
    this.root = createRoot(this.el);
  }

  render() {
    this.root.render(this.options.getComponent());
  }

  destroyReactComponent() {
    this.root.unmount();
  }
}
