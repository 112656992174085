import * as Backbone from 'backbone';

class ReaderNotificationTrigger extends Backbone.Model {
    public urlRoot: () => string

    constructor(params: any) {
        super(params)
        this.urlRoot = () => {
        return '/api/v3/notification_configurations/' +
               this.get('reader_notification_configuration_id') +
              '/notification_triggers/';
        }
    }

    toJSON() {
        const fullJson = Backbone.Model.prototype.toJSON.call(this);
        return {
            notification_trigger: fullJson
        };
    }
}

export default ReaderNotificationTrigger;
