/* eslint no-useless-escape: "off", no-use-before-define: "off", no-invalid-this: "off" */
import Marionette from 'backbone.marionette'
import _ from 'underscore'
import $ from 'jquery'
import template from 'text-loader!templates/dashboard/createAWidgetWindow.html'
import agents from 'collections/agentsInstance'
import tagsInstance from 'collections/tagsInstance'
import manualTagsInstance from 'collections/manualTagsInstance'
import usersInstance from 'collections/usersInstance'
import session from 'models/sessionInstance'
import I18n from 'i18n'
import localePromise from 'models/localePromise'
import select2CloseOnBlur from 'libraries/select2CloseOnBlur'
import unorm from 'unorm'

var jQuery = $;

var CreateAWidgetWindowView = Marionette.ItemView.extend({
  template: template,
  id: 'widget-menu-overlay-container',
  initialize: function (options) {
    this.agents = [];
    this.populateAgents();
    this.widgets = options.widgets;
    this.on('cancel', this.closeCreateAWidgetWindow);
    this.on('item:rendered', this.refreshSelect2Select);
    _.bindAll(this, 'closeCreateAWidgetWindow', '_keyupHandler');
    this.bindEscapeHandler();
  },
  triggers: {
    "click a[data-action='cancel']": 'cancel',
    "click #widget-menu-background": 'cancel'
  },
  refreshSelect2Select: function () {
    // Seriously, why cannot I execute trigger here so
    // that the placeholder text becomes visible. This works
    // when executed from the console!
    //
    //   this.$("#select2-select").trigger('change');
    //
    // Hackety-hack-hack.
    this.$(".select2-input").css('width', '275px');
  },
  onRender: function () {
    this.initializeCaWW();
  },
  initializeCaWW: function () {
    var container = this.$("#wizard-caww");
    var self = this;
    // localized widget titles, need to wait for locale
    localePromise.done(function () {
      var widgets = initWidgets();
      if (session.isSwissCustomer()) {
        var swissWidgets = initSwissWidgets();
        widgets.push(...swissWidgets);
      }
      widgets.push(initRssFeedWidget());
      var options = self.agents.concat(self.allTags());

      self.widgetChooser = new CreateAWidgetWindowView.DynamicTypeWidgetChooser(widgets, options);
      self.widgetChooserView = new CreateAWidgetWindowView.DynamicTypeWidgetChooserView(self, container, self.widgetChooser);
      self.widgetChooserView.render();
    });
  },
  closeCreateAWidgetWindow: function () {
    this.$('#select2-select').select2('close');
    this.close();
    this.unbindEscapeHandler();
  },
  create: function (widget, input) {
    var active_container = this.$("#wizard-" + this.$("#create-widget-wizard").attr("data-active-pane"));
    active_container.addClass('loading-indicator');
    var data = widget.toJSON(input).dashboard_widget;
    var widgetInstance = this.widgets.model(data);
    var self = this;
    widgetInstance.save({}, {
      success: function () {
        self.widgets.add(widgetInstance);
        self.close();
      },
      error: function (_, data) {
        self.handleError(data);
        active_container.removeClass('loading-indicator');
      }
    });
  },
  handleError: function (data) {
    this.widgetChooserView.showError(data);
  },
  populateAgents: function () {
    var createAwidgetWindowView = this;

    _.chain(agents.models)
      .filter(function (agent) {
        return agent.get('shared') ||
          (agent.get('user_id') === usersInstance.currentUser.id);
      })
      .each(function (agent) {
        var name = agent.get('name');
        var optGroupLabel = agentOptGroupName(agent);
        var optGroupClass = generateOptGroupClassForAgent(agent);
        var optGroup = new CreateAWidgetWindowView.DynamicOptionGroup(optGroupLabel, optGroupClass, CreateAWidgetWindowView.Agent);
        createAwidgetWindowView.agents.push(new CreateAWidgetWindowView.Agent(agent.id, optGroup, name));
      });
  },
  allTags: function () {
    var tags = [];

    var tagsOptGroup = new CreateAWidgetWindowView.DynamicOptionGroup(
      I18n.t("webapp.dashboard.creation.tags"), "tags", CreateAWidgetWindowView.Tag);
    var smarttagsOptGroup = new CreateAWidgetWindowView.DynamicOptionGroup(
      I18n.t("webapp.dashboard.creation.smarttags"), "smarttags", CreateAWidgetWindowView.Tag);
    manualTagsInstance.each(function (tag) {
      tags.push(new CreateAWidgetWindowView.Tag(tag.id,
                                                tagsOptGroup, tag.visibleName()));
    });
    var smarttags = tagsInstance.filter(function (tag) {
      return !tag.isManual();
    });
    _.each(smarttags, function (tag) {
      tags.push(new CreateAWidgetWindowView.Tag(tag.id,
                                                smarttagsOptGroup, tag.visibleName()));
    });

    return tags;
  },
  _keyupHandler: function (event) {
    if (event.which === 27) {
      this.closeCreateAWidgetWindow();
    }
  },
  bindEscapeHandler: function () {
    $(document).bind('keyup', this._keyupHandler);
  },
  unbindEscapeHandler: function () {
    $(document).unbind('keyup', this._keyupHandler);
  }
});

CreateAWidgetWindowView.Widget = function (type) {
  this.type = type;
};

CreateAWidgetWindowView.Widget.simpleAcceptableInput = function (numberOfArguments, typeOfArguments) {
  return function (input) {
    if (input.length !== numberOfArguments) { return false; }
    for (var i = 0; i < numberOfArguments; i++) { if (!(input[i] instanceof typeOfArguments)) { return false; }}
    return true;
  };
};

CreateAWidgetWindowView.Agent = function (id, optGroup, name) {
  this.id = id;
  this.optGroup = optGroup;
  this.name = name;
  this.kind = "Agent";
};

function generateOptGroupLabelForAgent(owner_name) {
  var trimmed_name = jQuery.trim(owner_name);
  if (owner_name === "mine") {
    return I18n.t("webapp.dashboard.creation.my_agents");
  } else {
    return I18n.t("webapp.dashboard.creation.users_agents", {username: trimmed_name});
  }
}

function generateOptGroupClassForAgent(agent_el) {
  return "user-" + agent_el.get('user_id') + "-agents";
}

function agentOptGroupName(agent_el) {
  var agentUserId = agent_el.get('user_id');
  var user = usersInstance.get(agentUserId);
  if (_.isUndefined(user)) {
    return generateOptGroupLabelForAgent("gelöschter Nutzer", false);
  } else {
    if (agentUserId === usersInstance.currentUser.id) {
      return generateOptGroupLabelForAgent("mine");
    } else {
      var fullName = user.get('full_name');
      return generateOptGroupLabelForAgent(fullName);
    }
  }
}

CreateAWidgetWindowView.Tag = function (id, optGroup, name) {
  this.id = id;
  this.optGroup = optGroup;
  this.name = name;
  this.kind = "Tag";
};

function sanitizeTextForHTML(text) {
  return text.replace(/"/g, "'");
}

CreateAWidgetWindowView.Feed = function (optGroup, feedUri) {
  this.id = feedUri;
  this.optGroup = optGroup;
  this.name = feedUri;
  this.feedUri = feedUri;
  this.kind = "Feed";
};

function createFeedObject(term) {
  var optGroup = new CreateAWidgetWindowView.DynamicOptionGroup("Feeds",
                                                                "feeds", CreateAWidgetWindowView.Feed);
  return (new CreateAWidgetWindowView.Feed(optGroup, term));
}

CreateAWidgetWindowView.DynamicOptionGroup = function (label, cssClass, type) {
  this.label = label;
  this.cssClass = cssClass;
  this.type = type;
  this.asHTML = '<optgroup label="' + sanitizeTextForHTML(label) + '" class="' + cssClass + '"></optgroup>';
};

// The default behavior of the sort function of arrays.
CreateAWidgetWindowView.DynamicOptionGroup.defaultSortFunction = function (a, b) {
  if (a < b) {
    return -1;
  } else if (a === b) {
    return 0;
  } else {
    return 1;
  }
};

// This function is intended to be used as argument to the sort
// function on an array of DynamicOptionGroups
CreateAWidgetWindowView.DynamicOptionGroup.sortFunction = function (a, b) {
  var typeOrder = [CreateAWidgetWindowView.Agent, CreateAWidgetWindowView.Tag];

  if (_.indexOf(typeOrder, a.type) < _.indexOf(typeOrder, b.type)) {
    return -1;
  } else if (_.indexOf(typeOrder, a.type) > _.indexOf(typeOrder, b.type)) {
    return 1;
  } else {
    var regex = new RegExp("^" + I18n.t("webapp.dashboard.creation.my_agents"));
    var regex2 = new RegExp("^" + I18n.t("webapp.dashboard.creation.smarttags"));
    if (a.label.match(regex2)) { return 1; }
    if (b.label.match(regex2)) { return -1; }
    if (a.label.match(regex)) {
      if (b.label.match(regex)) {
        return CreateAWidgetWindowView.DynamicOptionGroup.defaultSortFunction(a.label, b.label);
      } else {
        return -1;
      }
    } else if (b.label.match(regex)) {
      return 1;
    } else {
      return CreateAWidgetWindowView.DynamicOptionGroup.defaultSortFunction(a.label, b.label);
    }
  }
};

// BaseWidgetChooser


CreateAWidgetWindowView.BaseWidgetChooser = function () {
};

// BaseWidgetChooserView

CreateAWidgetWindowView.BaseWidgetChooserView = function (caww, container, chooser) {
  this.caww = caww;
  this.container = container;
  this.chooser = chooser;
};

CreateAWidgetWindowView.BaseWidgetChooserView.prototype.updateOkButtonState = function () {
  var button = this.container.find('.ok-button');
  if (this.chooser.isCreatable(this.creationParameters())) {
    button.addClass('ok-button-active');
  } else {
    button.removeClass('ok-button-active');
  }

};

CreateAWidgetWindowView.BaseWidgetChooserView.prototype.registerCreateCallback = function () {
  var self = this;
  var button = this.container.find('.ok-button');
  var callback = function () {
    if (button.hasClass('ok-button-active')) {
      self.chooser.create(self.creationParameters(), self.caww);
    }
    return false;
  };
  button.unbind();
  button.click(callback);
};

CreateAWidgetWindowView.BaseWidgetChooserView.prototype.showError = function () {
  this.caww.widgets.trigger('widget-creation-failed');
};


// TypeWidgetChooser


CreateAWidgetWindowView.TypeWidgetChooser = function (widgets) {
  CreateAWidgetWindowView.BaseWidgetChooser.call(this);
  this.widgets = widgets;
};
CreateAWidgetWindowView.TypeWidgetChooser.prototype = new CreateAWidgetWindowView.BaseWidgetChooser();
CreateAWidgetWindowView.TypeWidgetChooser.prototype.constructor = CreateAWidgetWindowView.TypeWidgetChooser;

CreateAWidgetWindowView.TypeWidgetChooser.prototype.allTypes = function () {
  var i;
  var allTypes = [];
  for (i = 0; i < this.widgets.length; i++) {
    allTypes = uniqueAppend(allTypes, this.widgets[i].type);
  }
  return allTypes;
};

CreateAWidgetWindowView.TypeWidgetChooser.prototype.findMatchingWidget = function (selected_type) {
  var widgetMatcher = function (widget) {
    return widget.type === selected_type;
  };
  return _.find(this.widgets, widgetMatcher);
};

CreateAWidgetWindowView.TypeWidgetChooser.prototype.isCreatable = function (selected_type) {
  return this.findMatchingWidget(selected_type) !== undefined;
};

CreateAWidgetWindowView.TypeWidgetChooser.prototype.create = function (type, caww) {
  var widget = this.findMatchingWidget(type);
  caww.create(widget, []);
};


// TypeWidgetChooserView


CreateAWidgetWindowView.TypeWidgetChooserView = function (caww, container, chooser) {
  CreateAWidgetWindowView.BaseWidgetChooserView.call(this, caww, container, chooser);
};
CreateAWidgetWindowView.TypeWidgetChooserView.prototype = new CreateAWidgetWindowView.BaseWidgetChooserView();
CreateAWidgetWindowView.TypeWidgetChooserView.prototype.constructor = CreateAWidgetWindowView.TypeWidgetChooserView;

CreateAWidgetWindowView.TypeWidgetChooserView.prototype.render = function () {
  this.initializeChooserTypes();
  this.registerCreateCallback();
};

CreateAWidgetWindowView.TypeWidgetChooserView.prototype.initializeChooserTypes = function () {
  var self = this;
  this.typeContainer().empty();
  var types = this.chooser.allTypes();
  _.each(types, function (type) {
    self.typeContainer().append('<div class="type-icon ' + type.name + '"></div>');
    self.typeContainer().disableSelection();
    var createdElement = self.typeContainer().children().last();
    createdElement.data('type', type);
  });

  this.typeContainer().find('.type-icon').each(function (index, element) {
    element = jQuery(element);
    element.click(function () {
      if (element.hasClass('selectable')) {
        if (element.hasClass('selected')) {
          element.removeClass('selected');
        } else {
          element.siblings().removeClass('selected');
          element.addClass('selected');
        }
        self.updateOkButtonState();
      }
    });
    var typeCaption = self.container.find('.type-caption');
    element.hover(
      function () {
        typeCaption.text(element.data('type').description);
      },
      function () {
        typeCaption.empty();
      });
  });

  this.updateSelectableTypes();
};

CreateAWidgetWindowView.TypeWidgetChooserView.prototype.updateSelectableTypes = function () {
  var self = this;
  this.typeContainer().find('.type-icon').each(function (index, element) {
    element = jQuery(element);
    var type = element.data('type');
    if (self.isTypeSelectable(type)) {
      element.addClass('selectable');
    } else {
      element.removeClass('selectable');
      element.removeClass('selected');
    }
  });
  this.updateOkButtonState();
};

CreateAWidgetWindowView.TypeWidgetChooserView.prototype.isTypeSelectable = function () {
  return true;
};

CreateAWidgetWindowView.TypeWidgetChooserView.prototype.typeContainer = function () {
  return this.container.find('.type-container');
};

CreateAWidgetWindowView.TypeWidgetChooserView.prototype.creationParameters = function () {
  var type = this.typeContainer().find('.type-icon.selected').data('type');
  return type;
};


// DynamicTypeWidgetChooser


CreateAWidgetWindowView.DynamicTypeWidgetChooser = function (widgets, options) {
  CreateAWidgetWindowView.TypeWidgetChooser.call(this, widgets); // call super
  this.options = options;
};
CreateAWidgetWindowView.DynamicTypeWidgetChooser.prototype = new CreateAWidgetWindowView.TypeWidgetChooser();
CreateAWidgetWindowView.DynamicTypeWidgetChooser.prototype.constructor = CreateAWidgetWindowView.DynamicTypeWidgetChooser;

CreateAWidgetWindowView.DynamicTypeWidgetChooser.prototype.isTypeSelectable = function (selection, type) {
  var i = 0;
  for (i = 0; i < this.widgets.length; i++) {
    var widget = this.widgets[i];
    if (widget.type === type && widget.acceptableInput(selection)) {
      return true;
    }
  }
  return false;
};

CreateAWidgetWindowView.DynamicTypeWidgetChooser.prototype.availableRemainingOptions = function (selection) {
  var active_options = [];
  var i;
  var j;
  for (i = 0; i < this.options.length; i++) {
    for (j = 0; j < this.widgets.length; j++) {
      var option = this.options[i];
      if (_.indexOf(selection, option) >= 0) { break; }
      var test_selection = selection.concat([option]);
      if (this.widgets[j].acceptableInput(test_selection)) {
        active_options.push(option);
        break;
      }
    }
  }
  return active_options;
};

CreateAWidgetWindowView.DynamicTypeWidgetChooser.prototype.findMatchingWidget = function (parameters) {
  var type = parameters[0];
  var selection = parameters[1];
  var widgetMatcher = function (widget) {
    return widget.type === type && widget.acceptableInput(selection);
  };
  return _.find(this.widgets, widgetMatcher);
};

CreateAWidgetWindowView.DynamicTypeWidgetChooser.prototype.isCreatable = function (parameters) {
  return this.findMatchingWidget(parameters) !== undefined;
};

CreateAWidgetWindowView.DynamicTypeWidgetChooser.prototype.create = function (parameters, caww) {
  var widget = this.findMatchingWidget(parameters);
  var selection = parameters[1];
  caww.create(widget, selection);
};

// DynamicTypeChooserView

CreateAWidgetWindowView.DynamicTypeWidgetChooserView = function (caww, container, chooser) {
  CreateAWidgetWindowView.TypeWidgetChooserView.call(this, caww, container, chooser);
  this.select2InputElement = this.container.find('#select2-select');
};
CreateAWidgetWindowView.DynamicTypeWidgetChooserView.prototype = new CreateAWidgetWindowView.TypeWidgetChooserView();
CreateAWidgetWindowView.DynamicTypeWidgetChooserView.prototype.constructor = CreateAWidgetWindowView.DynamicTypeWidgetChooserView;

CreateAWidgetWindowView.DynamicTypeWidgetChooserView.prototype.render = function () {
  var chooserView = this;

  this.select2InputElement.select2({
    containerCssClass: "custom-select2",
    placeholder: I18n.t("webapp.dashboard.creation.default_input"),
    multiple: true,
    formatNoMatches: function (term) {
      var httpRegExp = /^http/i;

      if (httpRegExp.test(term)) {
        return term;
      } else if (chooserView.currentSelectionKind === "Agent") {
        return "Keine passenden Agenten gefunden.";
      } else if (chooserView.currentSelectionKind === "Tag") {
        return "Keine passenden Tags gefunden.";
      } else if (chooserView.currentSelectionKind === "Feed") {
        return "Keine weitere Eingabe möglich.";
      } else {
        return "Keine passenden Agenten oder Tags";
      }
    },
    // Selected item
    formatSelection: function (object, container) {
      jQuery(container).data('option-object', object['option-object']);
      return object.text;
    },
    createSearchChoice: function (term, data) {
      if ($(data).filter(function () {
        return this.text.localeCompare(term) === 0;
      }).length === 0) {
        if (chooserView.currentSelectionKind === 'Feed' ||
            _.isUndefined(chooserView.currentSelectionKind)) {
          return {
            id: term,
            text: term,
            'option-object': createFeedObject(term),
            kind: "Feed"
          };
        }
      }
    },
    query: function (options) {
      var currentSelection = chooserView.getCurrentSelection();
      var resultOptions = chooserView.select2ChooserOptions();
      var queryData = {more: false, text: 'text', results: []};

      _.each(resultOptions.results, function (optionGroup) {
        var queriedChildren = [];
        _.each(optionGroup.children, function (option) {
          var alreadySelected = _.find(currentSelection, function (selectedOption) {
            return selectedOption.id === option.id;
          });
          if (!alreadySelected && unorm.nfc(option.text).toUpperCase().indexOf(unorm.nfc(options.term).toUpperCase()) >= 0) {
            queriedChildren.push(option);
          }
        });
        if (!_.isEmpty(queriedChildren)) {
          queryData.results.push({
            text: optionGroup.text,
            kind: optionGroup.kind,
            children: queriedChildren
          });
        }
      });

      options.callback(queryData);
    }
  });
  select2CloseOnBlur(this.select2InputElement);

  this.select2InputElement.on("open", function () {
    var currentSelection = chooserView.getCurrentSelection()[0];
    if (_.isUndefined(currentSelection)) {
      chooserView.currentSelectionKind = undefined;
    } else {
      chooserView.currentSelectionKind = currentSelection.kind;
    }
  });

  this.select2InputElement.on("change", function () {
    chooserView.updateSelectableTypes();
  });

  // need to call super after setting up the options
  CreateAWidgetWindowView.TypeWidgetChooserView.prototype.render.call(this);
};

CreateAWidgetWindowView.DynamicTypeWidgetChooserView.prototype.getCurrentSelection = function () {
  var selectedOptions = this.select2InputElement.select2('data');
  var selection = _.pluck(selectedOptions, 'option-object');
  return selection;
};

CreateAWidgetWindowView.sortChooserOptions = function (options) {
  var getSortingKey = function (option) {
    return option.name.toLowerCase();
  };
  return _.sortBy(options, getSortingKey);
};

// NS objects can only have string keys, I need object keys, so this
// groupBy returns an array of [key, value] arrays and tests for
// equality by calling `_.isEqual'.
var groupBy = function (list, iterator) {
  var hashTable = [];
  hashTable.lookupKeyValue = function (key) {
    return _.find(this, function (element) { return _.isEqual(element[0], key); });
  };
  hashTable.get = function (key) {
    var entry = this.lookupKeyValue(key);
    if (_.isArray(entry)) {
      return entry[1];
    }
  };
  hashTable.insert = function (key, value) {
    this.push([key, value]);
  };
  hashTable.set = function (key, value) {
    if (_.isObject(this.get(key))) {
      var entry = this.lookupKeyValue(key);
      entry[1] = value;
    } else {
      this.insert(key, value);
    }
  };
  hashTable.keys = function () {
    return _.map(this, function (keyAndValue) { return keyAndValue[0]; });
  };
  _.each(list, function (element) {
    var group = iterator(element);
    if (_.isObject(hashTable.get(group))) {
      var value = hashTable.get(group);
      value.push(element);
    } else {
      hashTable.insert(group, [element]);
    }
  });
  return hashTable;
};

CreateAWidgetWindowView.sortChooserOptionGroups = function (groupedOptions) {
  return groupedOptions.sort(function (a, b) {
    var optGroupA = a[0];
    var optGroupB = b[0];
    return CreateAWidgetWindowView.DynamicOptionGroup.sortFunction(optGroupA, optGroupB);
  });
};
CreateAWidgetWindowView.groupChooserOptions = function (options) {
  var grouped = groupBy(options, function (option) {
    return option.optGroup;
  });
  _.each(grouped.keys(), function (key) {
    var optGroupOptions = grouped.get(key);
    grouped.set(key, CreateAWidgetWindowView.sortChooserOptions(optGroupOptions));
  });
  return CreateAWidgetWindowView.sortChooserOptionGroups(grouped);
};

CreateAWidgetWindowView.DynamicTypeWidgetChooserView.prototype.select2ChooserOptions = function () {
  var selection = this.getCurrentSelection();
  var stillSelectableOptions = this.chooser.availableRemainingOptions(selection);
  var options = selection.concat(stillSelectableOptions);
  var groupedOptions = CreateAWidgetWindowView.groupChooserOptions(options);
  var results = [];

  _.each(groupedOptions, function (groupAndOptions) {
    var optGroup = groupAndOptions[0];
    var optGroupOptions = groupAndOptions[1];
    var options = _.map(optGroupOptions, function (optGroupOption) {
      return {
        id: optGroupOption.id,
        text: optGroupOption.name,
        kind: optGroupOption.kind,
        'option-object': optGroupOption
      };
    });
    results.push({
      text: sanitizeTextForHTML(optGroup.label),
      kind: options[0].kind,
      children: options
    });
  });

  return {more: false, text: 'text', results: results};
};

CreateAWidgetWindowView.DynamicTypeWidgetChooserView.prototype.isTypeSelectable = function (type) {
  return this.chooser.isTypeSelectable(this.getCurrentSelection(), type);
};

CreateAWidgetWindowView.DynamicTypeWidgetChooserView.prototype.creationParameters = function () {
  var selection = this.getCurrentSelection();
  var type = this.typeContainer().find('.type-icon.selected').data('type');
  return [type, selection];
};

// Widget types

CreateAWidgetWindowView.WidgetType = function (name, description) {
  this.name = name;
  this.description = description;
};

function initWidgets() {
  var lineChartType = new CreateAWidgetWindowView.WidgetType('line-chart', I18n.t("webapp.dashboard.creation.time_series"));
  var topSourcesType = new CreateAWidgetWindowView.WidgetType('top-sources', I18n.t("webapp.dashboard.creation.top_sources"));
  var tagCloudType = new CreateAWidgetWindowView.WidgetType('tag-cloud', I18n.t("webapp.dashboard.creation.tag_cloud"));
  var pieType = new CreateAWidgetWindowView.WidgetType('pie-chart', I18n.t("webapp.dashboard.creation.media_distribution"));
  var agentTagRatingType = new CreateAWidgetWindowView.WidgetType('rating-info', I18n.t("webapp.dashboard.creation.ratings"));
  var tickerType = new CreateAWidgetWindowView.WidgetType('clip-ticker', I18n.t("webapp.dashboard.creation.news_ticker"));
  var trendingTopicsType = new CreateAWidgetWindowView.WidgetType('top-tags', I18n.t("webapp.dashboard.creation.top_tags"));

  var agentChartWidget = new CreateAWidgetWindowView.Widget(lineChartType);
  agentChartWidget.acceptableInput = CreateAWidgetWindowView.Widget.simpleAcceptableInput(1, CreateAWidgetWindowView.Agent);
  agentChartWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'AgentChartWidget',
        agent_id: selection[0].id
      }
    };
  };

  var tagChartWidget = new CreateAWidgetWindowView.Widget(lineChartType);
  tagChartWidget.acceptableInput = CreateAWidgetWindowView.Widget.simpleAcceptableInput(1, CreateAWidgetWindowView.Tag);
  tagChartWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'TagChartWidget',
        tag_id: selection[0].id
      }
    };
  };

  var multiAgentChartWidget = new CreateAWidgetWindowView.Widget(lineChartType);
  multiAgentChartWidget.acceptableInput = CreateAWidgetWindowView.Widget.simpleAcceptableInput(2, CreateAWidgetWindowView.Agent);
  multiAgentChartWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'MultiAgentChartWidget',
        agent_ids: [selection[0].id, selection[1].id]
      }
    };
  };

  var multiTagChartWidget = new CreateAWidgetWindowView.Widget(lineChartType);
  multiTagChartWidget.acceptableInput = CreateAWidgetWindowView.Widget.simpleAcceptableInput(2, CreateAWidgetWindowView.Tag);
  multiTagChartWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'MultiTagChartWidget',
        tag_ids: [selection[0].id, selection[1].id]
      }
    };
  };

  var agentTopSourcesWidget = new CreateAWidgetWindowView.Widget(topSourcesType);
  agentTopSourcesWidget.acceptableInput = function (input) {
    if (input.length < 1) { return false; }
    for (var i = 0; i < input.length; i++) { if (!(input[i] instanceof CreateAWidgetWindowView.Agent)) { return false; }}
    return true;
  };
  agentTopSourcesWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'AgentTopSourcesWidget',
        agent_ids: jQuery.map(selection, function (agent) { return agent.id; })
      }
    };
  };

  var tagTopSourcesWidget = new CreateAWidgetWindowView.Widget(topSourcesType);
  tagTopSourcesWidget.acceptableInput = function (input) {
    if (input.length < 1) { return false; }
    for (var i = 0; i < input.length; i++) { if (!(input[i] instanceof CreateAWidgetWindowView.Tag)) { return false; }}
    return true;
  };
  tagTopSourcesWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'TagTopSourcesWidget',
        tag_ids: jQuery.map(selection, function (tag) { return tag.id; })
      }
    };
  };

  var tagCloudWidget = new CreateAWidgetWindowView.Widget(tagCloudType);
  tagCloudWidget.acceptableInput = CreateAWidgetWindowView.Widget.simpleAcceptableInput(0);
  tagCloudWidget.toJSON = function () {
    return {
      dashboard_widget: {
        type: 'TagCloudWidget'
      }
    };
  };

  var agentSourceDistributionPieWidget = new CreateAWidgetWindowView.Widget(pieType);
  agentSourceDistributionPieWidget.acceptableInput = function (input) {
    if (input.length < 1 || input.length > 3) { return false; }
    for (var i = 0; i < input.length; i++) { if (!(input[i] instanceof CreateAWidgetWindowView.Agent)) { return false; }}
    return true;
  };
  agentSourceDistributionPieWidget.toJSON = function (input) {
    var agent_ids = jQuery.map(input, function (agent) { return agent.id; });
    return {
      dashboard_widget: {
        type: 'AgentSourceDistributionPieWidget',
        agent_ids: agent_ids
      }
    };
  };

  var tagSourceDistributionPieWidget = new CreateAWidgetWindowView.Widget(pieType);
  tagSourceDistributionPieWidget.acceptableInput = function (input) {
    if (input.length < 1) { return false; }
    for (var i = 0; i < input.length; i++) { if (!(input[i] instanceof CreateAWidgetWindowView.Tag)) { return false; }}
    return true;
  };
  tagSourceDistributionPieWidget.toJSON = function (input) {
    var tag_ids = jQuery.map(input, function (tag) { return tag.id; });
    return {
      dashboard_widget: {
        type: 'TagSourceDistributionPieWidget',
        tag_ids: tag_ids
      }
    };
  };

  var agentTagRatingWidget = new CreateAWidgetWindowView.Widget(agentTagRatingType);
  agentTagRatingWidget.acceptableInput = function (input) {
    if (input.length < 1) { return false; }
    if (agentsGivenP(input) || tagsGivenP(input)) {
      return true;
    }
    return false;
  };
  agentTagRatingWidget.toJSON = function (input) {
    var ids = jQuery.map(input, function (object) { return object.id; });
    if (tagsGivenP(input)) {
      return {
        dashboard_widget: {
          type: 'TagRatingWidget',
          tag_ids: ids
        }
      };
    }
    else if (agentsGivenP(input)) {
      return {
        dashboard_widget: {
          type: 'AgentRatingWidget',
          agent_ids: ids
        }
      };
    }
  };

  var tickerWidget = new CreateAWidgetWindowView.Widget(tickerType);
  tickerWidget.acceptableInput = function (input) {
    if (input.length < 1) { return false; }
    return agentsGivenP(input);
  };
  tickerWidget.toJSON = function (input) {
    var ids = _.pluck(input, 'id');
    return {
      dashboard_widget: {
        type: 'TickerWidget',
        agent_ids: ids
      }
    };
  };

  var trendingTopicsWidget = new CreateAWidgetWindowView.Widget(trendingTopicsType);
  trendingTopicsWidget.acceptableInput = CreateAWidgetWindowView.Widget.simpleAcceptableInput(0);
  trendingTopicsWidget.toJSON = function () {
    return {
      dashboard_widget: {
        type: 'TrendingTopicsWidget'
      }
    };
  };

  var widgets = [
    // 1. Chart Widgets
    agentChartWidget, multiAgentChartWidget,
    tagChartWidget, multiTagChartWidget,
    // 2. Distribution Widget
    agentSourceDistributionPieWidget, tagSourceDistributionPieWidget,
    // 3. TopSources Widgets
    agentTopSourcesWidget, tagTopSourcesWidget,
    // 4. Rating Widgets
    agentTagRatingWidget,
    // 5. Misc Widgets
    tagCloudWidget, trendingTopicsWidget, tickerWidget
  ];

  return widgets;
}

function initSwissWidgets() {
  var adValueType = new CreateAWidgetWindowView.WidgetType('ad-value', I18n.t("webapp.dashboard.creation.ad_value"));

  var printAdValueKPIWidget = new CreateAWidgetWindowView.Widget(adValueType);
  printAdValueKPIWidget.acceptableInput = function (input) {
    if (input.length < 1) { return false; }
    for (var i = 0; i < input.length; i++) { if (!(input[i] instanceof CreateAWidgetWindowView.Agent)) { return false; }}
    return true;
  };
  printAdValueKPIWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'AgentAdValueWidget',
        agent_ids: jQuery.map(selection, function (agent) { return agent.id; })
      }
    };
  };

  var printTagAdValueKPIWidget = new CreateAWidgetWindowView.Widget(adValueType);
  printTagAdValueKPIWidget.acceptableInput = function (input) {
    if (input.length < 1) { return false; }
    for (var i = 0; i < input.length; i++) { if (!(input[i] instanceof CreateAWidgetWindowView.Tag)) { return false; }}
    return true;
  };
  printTagAdValueKPIWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'TagAdValueWidget',
        tag_ids: jQuery.map(selection, function (tag) { return tag.id; })
      }
    };
  };

  var geoChartType = new CreateAWidgetWindowView.WidgetType('geo-chart', I18n.t("webapp.dashboard.creation.map"));
  var geoChartSwitzerlandWidget = new CreateAWidgetWindowView.Widget(geoChartType);
  geoChartSwitzerlandWidget.acceptableInput = function (input) {
    if (input.length < 1) { return false; }
    return agentsGivenP(input);
  };
  geoChartSwitzerlandWidget.toJSON = function (input) {
    var ids = _.pluck(input, 'id');
    return {
      dashboard_widget: {
        type: 'GeochartSwitzerlandWidget',
        agent_ids: ids
      }
    };
  };


  return [
    // Swiss Widgets
    printAdValueKPIWidget, printTagAdValueKPIWidget,
    geoChartSwitzerlandWidget
  ];
}

function initRssFeedWidget() {
  var rssFeedWidgetType = new CreateAWidgetWindowView.WidgetType('rss-feed', I18n.t("webapp.dashboard.creation.rss_feed"));
  var rssFeedWidget = new CreateAWidgetWindowView.Widget(rssFeedWidgetType);
  rssFeedWidget.acceptableInput = function (input) {
    if (input.length !== 1) { return false; }

    var feedInput = input[0];
    if (!(feedInput instanceof CreateAWidgetWindowView.Feed)) { return false; }
    return validateURL(feedInput.feedUri);
  };
  rssFeedWidget.toJSON = function (selection) {
    return {
      dashboard_widget: {
        type: 'RssFeedWidget',
        feed_id: selection[0].feedUri
      }
    };
  };

  return rssFeedWidget;
}


function tagsGivenP(array) {
  // Who expects IE 8.0 to support array.reduce ?!?
  return _.reduce(array, function (predicate_satisfied, element) {
    return predicate_satisfied && element instanceof CreateAWidgetWindowView.Tag;
  }, true);
}
function agentsGivenP(array) {
  // Who expects IE 8.0 to support array.reduce ?!?
  return _.reduce(array, function (predicate_satisfied, element) {
    return predicate_satisfied && element instanceof CreateAWidgetWindowView.Agent;
  }, true);

}

function uniqueAppend(array, element) {
  if (_.indexOf(array, element) < 0) {
    array.push(element);
  }
  return array;
}

function validateURL(url) {
  //from http://stackoverflow.com/questions/2723140/validating-url-with-jquery-without-the-validate-plugin
  var urlRegex = /^([a-z]([a-z]|\d|\+|-|\.)*):(\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?((\[(|(v[\da-f]{1,}\.(([a-z]|\d|-|\.|_|~)|[!\$&'\(\)\*\+,;=]|:)+))\])|((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=])*)(:\d*)?)(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*|(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)|((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)|((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)){0})(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  return urlRegex.test(url);
}

export default CreateAWidgetWindowView;

