import TimelineController from 'controllers/timelineController'
import ResearchResultListController from 'controllers/research/researchResultListController'
import Clips from 'collections/clips'
import ClipsApi from 'collections/researchClipApiCollection'
import session from 'models/sessionInstance';

var ResearchTimelineController = TimelineController.extend({
  initialize: function (options) {
    TimelineController.prototype.initialize.apply(this, [options]);
  },
  ListItemCollection: Clips,
  ListItemApiCollection: ClipsApi,
  ListController: ResearchResultListController,
  listOptionKeys: ['topicBuildParameters', 'sourceCategoryIds'],
  disableMultipleSelection: function () {
    return !session.hasFeature('access_to_new_research')
  },
  moduleName: 'research',
  getTime: function (clip) {
    return new Date(clip.get('publish_time') * 1000);
  },
  _handleSelectionRemoval: function (listItem) {
    var dayId = this.listItemDay[listItem.id].cid;
    var listController = this.listControllers[dayId];
    listController.markAsDeselected(listItem);
    this.notifyDeselect(listItem);
  }
});
export default ResearchTimelineController;
