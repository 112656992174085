import * as React from "react";

interface Props {
  children: any
}

const SidebarSubtree = (props: Props) => {
  return (
    <ul className="nav-subtree">
      {props.children}
    </ul>
  )
}

export default SidebarSubtree