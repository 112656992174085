import Marionette from 'backbone.marionette'
import ShortcutsHelpView from 'views/shortcutsHelpView'
import Shortcutter from 'libraries/shortcutter'
import _ from 'underscore'
import $ from 'jquery'

var ShortcutsHelpController = Marionette.Controller.extend({
  initialize: function () {
    _.bindAll(this,
              '_triggerHelpModal');

    this.view = new ShortcutsHelpView();
    this.view.render();
    $('#layout').append(this.view.el);

    var self = this;
    Shortcutter.whenReady.then(function () {
      Shortcutter.bind('?', self._triggerHelpModal);
    });
  },
  _triggerHelpModal: function () {
    if (this.view.isOpen()) {
      this.view.closeModal();
    } else {
      this.view.openModal();
    }

    return false;
  }
});

export default ShortcutsHelpController;
