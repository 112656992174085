import Backbone from 'backbone'
import session from 'models/sessionInstance'
import template from 'text-loader!templates/reporting/sidebar.html'
import 'backbone.marionette'

var View = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'ul',
  triggers: {
    'click #reporting-new-medienspiegel': 'clicked:new-medienspiegel',
    'click #reporting-new-excel-export': 'clicked:new-excel-export',
    'click #reporting-new-newsletter': 'clicked:new-newsletter',
    'click #reporting-list': 'clicked:list'
  },
  activateEntry: function (entry) {
    this.$('li.active').removeClass('active');
    this.$('#reporting-' + entry).parent().addClass('active');
  },
  serializeData: function () {
    return {features: session.get('features')};
  }
});

export default View;

