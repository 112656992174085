import {
  IAddressbookModelProps,
  IAddressbookContact,
  IAddressbookGroup,
  AddressbookType
} from 'models/addressbookModel'
import ContactView from 'views/contactGroup/contactView'
import ContactGroupView from 'views/contactGroup/contactGroupView'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'

interface IContactListViewProps {
  editContactId: number | null
  highlightContactId: number | null
  contacts: Array<IAddressbookModelProps>
  visibilityMap: Map<string, boolean>
  contactsText: string
  onAdd: (contact: IAddressbookContact, query: string) => void
  onEdit: (contact: IAddressbookContact, query: string) => void
  onRemove: (contact: IAddressbookContact, query: string) => void
  onDestroy: (contact: IAddressbookContact, query: string) => void
  onListItemClick: (contact: IAddressbookContact, query: string) => void
  onCancel: (query: string) => void
  onUpdate: (contact: IAddressbookContact, name: string, email: string, query: string) => void
  onGroupAdd: (group: IAddressbookGroup, query: string) => void
  onGroupRemove: (group: IAddressbookGroup, query: string) => void
  searchInput: string
  addable: boolean
  editable: boolean
  removable: boolean
  destroyable: boolean
  useAudienceCount: boolean
}
class ContactsListView extends React.Component<IContactListViewProps, {}> {
  render() {
    let count = this.visibleContactsCount()
    if (this.props.useAudienceCount) {
      count = this.contactCount()
    }
    return (
      <li className="contact-list__item contact-list__item-group-title">
        <div className="contact-list__item-field">
          <strong>{this.props.contactsText} ({count})</strong>
        </div>
        <ul data-contact-group-contact-list>
          { this.contactItems() }
        </ul>
      </li>
    )
  }

  private visibleContactsCount(): number {
    const values = Array.from(this.props.visibilityMap.values())
    return values.reduce((count, value) => {
      return value ? count + 1 : count
    }, 0)
  }

  private contactCount(): number {
    const values = _.map(this.props.contacts, (contact) => {
      if (contact.type === AddressbookType.Contact) {
        return 1
      } else {
        let group = contact.data as IAddressbookGroup
        return group.contacts.length
      }
    })
    return values.reduce((a, b) => { return a + b }, 0)
  }

  private contactItems() {
    return _.map(this.props.contacts, (contact) => {
      if (contact.type === AddressbookType.Contact) {
        return this.renderContact(contact)
      } else {
        return this.renderContactGroup(contact)
      }
    })
  }

  private renderContact(contact: IAddressbookModelProps) {
    const visible = this.props.visibilityMap.get(contact.id) || false
    return (
      <ContactView key={contact.id}
                   onAdd={this.props.onAdd}
                   onEdit={this.props.onEdit}
                   onRemove={this.props.onRemove}
                   onDestroy={this.props.onDestroy}
                   onListItemClick={this.props.onListItemClick}
                   onCancel={this.props.onCancel}
                   onUpdate={this.props.onUpdate}
                   onGroupAdd={this.props.onGroupAdd}
                   onGroupRemove={this.props.onGroupRemove}
                   removable={this.props.removable}
                   destroyable={this.props.destroyable}
                   addable={this.props.addable}
                   editable={this.props.editable}
                   editContactId={this.props.editContactId}
                   highlightContactId={this.props.highlightContactId}
                   visible={visible}
                   searchInput={this.props.searchInput}
                   useButtonStyle={false}
                   groupAddable={false}
                   groupRemovable={false}
                   contact={contact}
                   groupMember={false}/>
    )
  }

  private renderContactGroup(group: IAddressbookModelProps) {
    const visible = this.props.visibilityMap.get(group.id) || false
    return (
      <ContactGroupView key={group.id}
                        onAdd={this.props.onAdd}
                        onEdit={this.props.onEdit}
                        onRemove={this.props.onRemove}
                        onDestroy={this.props.onDestroy}
                        onListItemClick={this.props.onListItemClick}
                        onCancel={this.props.onCancel}
                        onUpdate={this.props.onUpdate}
                        onGroupAdd={this.props.onGroupAdd}
                        onGroupRemove={this.props.onGroupRemove}
                        removable={this.props.removable}
                        destroyable={this.props.destroyable}
                        addable={this.props.addable}
                        editable={this.props.editable}
                        editContactId={this.props.editContactId}
                        highlightContactId={this.props.highlightContactId}
                        visible={visible}
                        searchInput={this.props.searchInput}
                        useButtonStyle={false}
                        groupAddable={false}
                        groupRemovable={true}
                        contact={group}
                        groupMember={false}/>
    )
  }
}

export default ContactsListView
