import { EntityType } from "../../../models/entity";

const fulltextFilter = (terms: string[], id: number) => {
  return {
    type: 'fulltext',
    exclude: false,
    id: id,
    selection: terms,
    active: true,
    showErrors: false
  }
}

const defaultFilter = () => {
  return [
    [
      fulltextFilter([], 1)
    ]
  ]
}

export const buildFilters = (query: string) => {
  if (!query) {
    return defaultFilter()
  } else {
    let filters = [[fulltextFilter([query], 1)]]
    return filters
  }
}

export function buildEntityFilters(entity: {entity_type: EntityType, id: number}, id: number = 1) {
  return [
    [
      {
        type: entity.entity_type,
        exclude: false,
        id,
        selection: [entity.id]
      }
    ]
  ];
}

export function buildTopicFilters(topic: {id: number}, id: number = 1) {
  return [
    [
      {
        type: "topic",
        exclude: false,
        id,
        selection: [topic.id]
      }
    ]
  ]
}