import $ from 'jquery'
import _ from 'underscore'
import LayoutContentResizer from 'modules/layoutContentResizer'

function DashboardFullscreen(layout) {
  _.bindAll(this, 'keyupHandler');
  this.layout = layout;
}

DashboardFullscreen.prototype.keyupHandler = function (event) {
  var self = this;
  if (event.which === 27) {
    self.exitCallback();
  }
};

DashboardFullscreen.prototype.isFullscreenApiSupported = function () {
  var doc = document.documentElement;
  return doc.requestFullscreen || doc.mozRequestFullScreen || doc.webkitRequestFullScreen;
};

DashboardFullscreen.prototype.start = function (exitCallback, options) {
  if (_.isUndefined(options)) {
    options = {};
  }
  if (options.forceCompatibility) {
    this.forceCompatibility = true;
  }
  if (this.isFullscreenApiSupported() && !this.forceCompatibility) {
    this.startAPI(exitCallback);
  } else {
    this.startCompatibility(exitCallback);
  }
};


DashboardFullscreen.prototype.exit = function () {
  if (this.isFullscreenApiSupported() && !this.forceCompatibility) {
    this.exitAPI();
  } else {
    this.exitCompatibility();
  }
  this.forceCompatibility = false;
};

// fullscreen API

DashboardFullscreen.prototype.dashboardElement = function () {
  return this.layout.el;
};

DashboardFullscreen.prototype.startAPI = function (exitCallback) {
  var el = this.dashboardElement();
  if (el.requestFullscreen) {
    el.requestFullscreen();
  }
  else if (el.mozRequestFullScreen) {
    el.mozRequestFullScreen();
  }
  else if (el.webkitRequestFullScreen) {
    el.webkitRequestFullScreen();
  }
  this.listenForFullscreenChange(exitCallback);
};

DashboardFullscreen.prototype.listenForFullscreenChange = function (callback) {
  var self = this;
  var f = function () {
    if (self.isFullScreen()) {
      self.listenForFullscreenChange(callback);
    } else {
      callback();
    }
  };
  $(document).one('fullscreenchange', f);
  $(document).one('mozfullscreenchange', f);
  $(document).one('webkitfullscreenchange', f);
};

DashboardFullscreen.prototype.exitAPI = function () {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  }
  else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  }
  else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  }
};

DashboardFullscreen.prototype.isFullScreen = function () {
  return document.fullscreen || document.mozFullScreen || document.webkitIsFullScreen;
};


// compatibility mode

var elementsToHideInFullscreen =  function () {
  return $('.header, .layout-toolbar');
};

DashboardFullscreen.prototype.startCompatibility = function (exitCallback) {
  elementsToHideInFullscreen().hide();

  $('#container-content').css('top', 0);
  $('#container-content').css('height', $(window).height());
  this.exitCallback = exitCallback;
  $(document).bind('keyup', this.keyupHandler);
  
};

DashboardFullscreen.prototype.exitCompatibility = function () {
  elementsToHideInFullscreen().show();
  $('#container-content').css('top', '');
  $('#container-content').css('height', '');
  this.exitCallback = undefined;
  $(document).unbind('keyup', this.keyupHandler);
  LayoutContentResizer.enableContentResizing();
};

export default DashboardFullscreen;

