import Marionette from 'backbone.marionette';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import vent from 'libraries/vent';
import * as _ from 'underscore';
import I18n from 'i18n';
import { AgentsPermissions } from "components/agents/permissions";
import Agent from "models/agent";
import AgentsListView from "views/agents/agentsListView";
import AgentsListLandingPageView from "views/agents/agentsListLandingPageView";
import usersInstance from 'collections/usersInstance';
import Notificator from 'libraries/notificator';


export default class AgentsListController extends Marionette.Controller {
  initialize(options: {showLeftHandContainer: (view: any) => any, showRightHandContainer: (view: any) => any, agents: any}) {
    this.agents = options.agents;
    this.showLeftHandContainer = options.showLeftHandContainer;
    this.showRightHandContainer = options.showRightHandContainer;
    this.subscriptionRequested = [];
    this.buildView()
    this.view = new ReactWrapper({getComponent: () => { return this.component } });
    this.showAgentsListLandingPageView();
    this.showLeftHandContainer(this.view);
  }

  buildView() {
    const props = {
      onCreateAgent: () => this.createAgent(),
      onSubscribeAgent: (agent_id: number) => this.subscribeAgent(agent_id),
      agents: this.preparedAgentsList(this.agents),
      users: usersInstance
    };
    this.component = <AgentsListView {...props} />;
  }

  updateView() {
    this.buildView();
    this.view.render();
  }

  showAgentsListLandingPageView() {
      this.showRightHandContainer(new ReactWrapper({getComponent: () => { return <AgentsListLandingPageView/> } }));
      vent.trigger('layout:focus-left');
  }

  createAgent() {
    this.trigger('create-agent');
  }

  subscribeAgent(agent_id: number) {
    if (_.contains(this.subscriptionRequested, agent_id)) { return }
    this.subscriptionRequested.push(agent_id)
    const self = this;

    const url = '/api/v3/agents/' + agent_id + '/subscribed_users';
    const data = {
      id: usersInstance.currentUser.id
    };

    $.post(url, data, function (data) {
      self.agents.get(agent_id).set({
        subscribed_user_ids: data.user_ids,
        subscribed: true
      });
      self.agents.trigger('add:subscribed')
      self.updateView();
      self.handleRequestSuccess();
      self.removeAgentFromRequests(agent_id);
    }).fail(() => {
      self.handleRequestError();
      self.removeAgentFromRequests(agent_id);
    })
  }

  handleRequestError() {
    const key =
      'webapp.agents.edit.agent_subscriptions.request_error'
    Notificator.showNotification(I18n.t(key));
  }

  handleRequestSuccess() {
    const key =
      'webapp.agents.edit.agent_subscriptions.request_success'
    Notificator.showNotification(I18n.t(key));
  }

  preparedAgentsList(agents: any) {
    const availableAgents = _.filter(agents.models, (agent: Agent) => {
      return AgentsPermissions.canSubscribeAgent(agent)
    });
    const sorted = _.sortBy(
      availableAgents, (agent: Agent) => agent.get('name').toLowerCase()
    );
    const sortedByActiveSearch = _.sortBy(
      sorted, (agent: Agent) => !agent.get("search_active")
    );
    return _.map(sortedByActiveSearch,
      (agent: Agent) => {
        return {
          id: agent.get('id'),
          name: agent.get('name'),
          shared: agent.get('shared'),
          searchActive: agent.get("search_active"),
          userId: agent.get("user_id")
        };
      });
  }

  removeAgentFromRequests(agent_id: number) {
    this.subscriptionRequested = _.filter(this.subscriptionRequested, (id: number) => {
      return id != agent_id
    })
  }

  handleRightColumnClosed() {}
}
