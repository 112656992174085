import Marionette from 'backbone.marionette'
import CommentView from 'views/agents/commentView'
import template from 'text-loader!templates/agents/commentList.html'
import $ from 'jquery'

var CommentListView = Marionette.CompositeView.extend({
  ui: {
    commentInput: '[data-comment-input]'
  },
  template: template,
  itemView: CommentView,
  className: "comment-input",
  events: {
    'keypress @ui.commentInput': '_handleCommentInput',
    'blur @ui.commentInput': '_createComment'
  },
  initialize: function (options) {
    this.collection = options.collection;
    this.on('itemview:update:comment', (view) => {
      var updatedText = $(view.ui.commentInput).val();
      view.leaveEditMode();
      this.trigger('update:comment', view.model, updatedText);
    });
    this.on('itemview:delete:comment', (event) => {
      this.trigger('delete:comment', event.model);
    });
    this.on('itemview:edit:comment', function (event) {
      event.enterEditMode();
    });
    this.on('itemview:render', this._releaseLock);
  },
  focusOnCommentInput: function () {
    this.ui.commentInput.focus();
  },
  _handleCommentInput: function (event) {
    if (event.which === 13) {
      event.preventDefault();
      this._createComment();
    }
  },
  _createComment: function () {
    if (!this._isLocked()) {
      var inputField = this.$(this.ui.commentInput);
      if (inputField.val()) {
        this._lock();
        this.trigger('create:comment', inputField.val());
        inputField.val("");
        if (event.which === 13) {
          inputField.blur();
        }
      }
    }
  },
  _isLocked: function () {
    return this.createLock === true;
  },
  _lock: function () {
    this.createLock = true;
  },
  _releaseLock: function () {
    this.createLock = false;
  }
});

export default CommentListView;

