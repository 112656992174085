import $ from 'jquery'

var contentResizingDisabled;

function resizeContent() {
  if (!contentResizingDisabled) {
    var content = $('[data-computed-height]');
    var newHeight = $('body').height() - $('#container-header').height() - $('#container-navigation').height() - $('[data-container=header]').height() - $('[data-container=navigation]').closest('.layout').height();
    content.css('height', newHeight);
  }
}

export default {
  resizeContent: function () {
    resizeContent();
  },
  disableContentResizing: function () {
    contentResizingDisabled = true;
  },
  enableContentResizing: function () {
    contentResizingDisabled = false;
    resizeContent();
  }
};

