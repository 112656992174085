import SourceExclusionSearchPropFactory from "components/sourceExclusionSearchPropFactory";
import SourceSearch from "collections/sourceSearch";
import Source from "models/source";
import I18n from "i18n";

class SourceSearchPropFactory extends SourceExclusionSearchPropFactory {
  query: any;

  generate() {
    return {
        queryCallback: this.queryCallback,
        selectionCallback: this.selectedCallback,
        multiselect: false,
        minimumInputLength: 2,
        select2Id: "sourceSearchOnCreate"
    };
  }

  protected selectedCallback(id: any) {
    let source = this.seenSources.get(id);
    if (source) {
      this.onSelection(source);
    } else {
      this.onSelection(new Source({ id: 0, name: this.query.term.trim() }));
    }
  }

  protected queryCallback(query: any) {
    const createNewSource =
      I18n.t("webapp.agents.add_result.create_new_source", { term: query.term.trim() });
    let sourceSearch = new SourceSearch(
      [], { query: query.term.trim(), include_private: true, page: this.page }
    );
    let promise = sourceSearch.fetch();
    this.query = query;
    promise.done(() => {
      let newSource = { id: 0, text: createNewSource, term: query.term.trim() };
      this.registerSources(sourceSearch);
      let results = this.generateSelect2Results(sourceSearch);
      results.results.push(newSource);
      query.callback(results);
    }).fail(() => query.callback({ results: [] }));
  }
}

export default SourceSearchPropFactory;
