import * as moment from 'moment'
import I18n from 'i18n'

var formats = {
  date: 'l',
  dateTime: 'l LT',
  time: 'LT',
  datePicker: 'DD.MM.YYYY',
  month: 'MMM',
  weekNumber: 'w',
};

export type AnyDate = moment.Moment | Date

function isToday (date: moment.Moment) {
  return date.isSame(moment(), 'day');
}

function isYesterday (date: moment.Moment) {
  var yesterday = moment().subtract(1, 'day');
  return date.isSame(yesterday, 'day');
}

function isDayBeforeYesterday (date: moment.Moment) {
  var dayBeforeYesterday = moment().subtract(2, 'day');
  return date.isSame(dayBeforeYesterday, 'day');
}

var DateFormatter = {
  format: function (date: AnyDate, format: string) {
    date = moment(date);
    return date.format(format);
  },
  formatForDatePicker: function (date: AnyDate) {
    date = moment(date);
    return date.format(formats.datePicker);
  },
  formatDate: function (date: AnyDate) {
    date = moment(date);
    return date.format(formats.date);
  },
  formatMonth: function (date: AnyDate) {
    date = moment(date);
    return date.format(formats.month);
  },
  formatWeekNumber: function (date: AnyDate) {
    date = moment(date);
    return `${I18n.t("webapp.date.week_format.kw")} ${date.format(formats.weekNumber)}`;
  },
  formatRelativeDate: function (date: AnyDate) {
    date = moment(date);
    if (isToday(date)) {
      return I18n.t('webapp.date.today');
    } else if (isYesterday(date)) {
      return I18n.t('webapp.date.yesterday');
    } else if (isDayBeforeYesterday(date)) {
      return I18n.t('webapp.date.day_before_yesterday');
    } else {
      return date.format(formats.date);
    }
  },
  formatRelativeArchiveDate: function (date: AnyDate, isLatestIssue: boolean) {
    date = moment(date);
    var now = moment();
    var key;
    if (date.isAfter(now)) {
      key = 'webapp.reader_admin.archive_dates.upcoming_issue';
      return I18n.t(key);
    } else {
      if (isLatestIssue) {
        key = 'webapp.reader_admin.archive_dates.latest_issue';
        return I18n.t(key);
      } else {
        key = 'webapp.reader_admin.archive_dates.issue_of';
        return I18n.t(key, { date: date.format(formats.date) });
      }
    }
  },
  formatTime: function (time: AnyDate) {
    time = moment(time);
    return time.format(formats.time);
  },
  formatDateTime: function (dateTime: AnyDate) {
    dateTime = moment(dateTime);
    return dateTime.format(formats.dateTime);
  },
  formatRelativeDateTime: function (dateTime: AnyDate) {
    dateTime = moment(dateTime);
    if (isToday(dateTime)) {
      if (dateTime.isAfter(moment().subtract(1, 'hour'))) {
        return dateTime.fromNow();
      } else {
        let today = I18n.t('webapp.date.today')
        return dateTime.format(`[${today},] ${formats.time}`)
      }
    } else if (isYesterday(dateTime)) {
      var yesterday = I18n.t('webapp.date.yesterday');
      return dateTime.format('[' + yesterday + ',] ' + formats.time);
    } else if (isDayBeforeYesterday(dateTime)) {
      var dayBeforeYesterday = I18n.t('webapp.date.day_before_yesterday');
      return dateTime.format('[' + dayBeforeYesterday + ',] ' + formats.time);
    } else {
      return dateTime.format(formats.dateTime);
    }
  },
  formatRelativeListDateTime: function (dateTime: AnyDate) {
    dateTime = moment(dateTime)
    if (isToday(dateTime) || isYesterday(dateTime) || isDayBeforeYesterday) {
      return DateFormatter.formatRelativeDateTime(dateTime);
    } else {
      return DateFormatter.formatDate(dateTime);
    }
  },
  formatTimestamp: function (dateTime: AnyDate) {
    dateTime = moment(dateTime);
    return dateTime.unix();
  }
};

export default DateFormatter;

