import Backbone from 'backbone'
import when from 'when'
import 'jquery'

export default class Usage extends Backbone.Model {
    load() {
        return when(this.fetch())
    }

    isMinimumUsageReached(unitType) {
        let unitUsage = this.get(unitType)
        if (!unitUsage.unit_usage_incurs_cost) {
            return false;
        }
        return unitUsage.usage >= unitUsage.minimum
    }

    unitPrice(unitType) {
        return this.get(unitType).unit_price
    }
    currentUnitUsage(unitType) {
        return this.get(unitType).usage
    }
}

Usage.prototype.url = '/api/v3/usage'
