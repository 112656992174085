import Config from 'libraries/configuration'
import Backbone from 'backbone'
import moment from 'moment'
import $ from 'jquery'
import _ from 'underscore'

var ResearchQueryBuilder = Backbone.Model.extend({
  baseUrl: Config.apiUrl + "research/search",
  defaultLimit: 25,
  toUrl: function () {
    var parameters = {limit: this.get('limit') || this.defaultLimit};
    if (!_.isUndefined(this.get('date'))) {
      parameters.since = moment(this.get('date')).startOf('day').unix();
      parameters.before = moment(this.get('date')).endOf('day').unix();
    } else {
      if (!_.isUndefined(this.get('since'))) {
        parameters.since = this.get('since');
      }
      if (!_.isUndefined(this.get('before'))) {
        parameters.before = this.get('before');
      }
    }
    var params = this.get('topicBuildParameters');
    var terms = params.terms;
    if (terms) {
      parameters.terms = _.pluck(terms, 'name');
    }
    var contexts = params.contexts;
    if (contexts) {
      parameters.contexts = _.map(contexts, function (context) {
        return _.pluck(context.literals, 'name');
      });
    }
    var exclusions = params.exclusions;
    if (exclusions) {
      parameters.exclusions = _.pluck(exclusions, 'name');
    }
    var sourceCategoryIds = this.get('sourceCategoryIds');
    if (sourceCategoryIds) {
      parameters.source_category_ids = sourceCategoryIds;
    }
    var sourceIdsToExclude = this.get('sourceIdsToExclude');
    if (sourceIdsToExclude) {
      parameters.source_ids_to_exclude = sourceIdsToExclude;
    }
    var sourceGroupIds = this.get('sourceGroupIds');
    if (sourceGroupIds) {
      parameters.source_group_ids = sourceGroupIds;
    }
    if (this.get('beforeId')) {
      parameters.before_id = this.get('beforeId');
    }
    if (this.get('sinceId')) {
      parameters.since_id = this.get('sinceId');
    }
    parameters.use_sphinx = true;
    return this.baseUrl + '?' + $.param(parameters);
  }
});

export default ResearchQueryBuilder;
