import _ from 'underscore'

function compactObject(obj) {
  _.each(obj, function(value, key) {
    if(!value){
      delete obj[key];
    }
  });
  return obj;
}

export default compactObject;
