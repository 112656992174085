// Actions
const UPDATE: 'app/showDashBoard/UPDATE' = 'app/showDashBoard/UPDATE';

const initialState = false;

type Action = UpdateAction ;

// Reducer
export default function (state = initialState, action: Action) : boolean {
  switch (action.type) {
      case UPDATE:
          return action.payload;
    default:
          return state;
  }
}

export interface UpdateAction { type: typeof UPDATE; payload: boolean; }
export function update(payload: boolean ): UpdateAction {
  return { type: UPDATE, payload};
}