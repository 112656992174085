import _ from 'underscore';
import $ from 'jquery';
import Deferred from 'libraries/deferred';
import OptionsProvider from 'models/globalSearch/optionsProvider';
import SourceSearch from 'collections/sourceSearch';
import LRUCache from 'lru';
import validateAgentResultQuery from 'models/validateAgentResultQuery'

function isMoreThanThreeCharsLong(term) {
    return !_.isEmpty($.trim(term)) && (term.length > 2);
}

const queryCache = new LRUCache(100);

function generateSelect2Results(sourceSearch, term) {
    const results = sourceSearch.map(function (source) {
        let prio = 6;
        if (source.get('name').toLowerCase() === term.toLowerCase()) {
            prio = 2;
        }
        return {
            id: source.id,
            type: "source",
            text: source.get('name'),
            priority: prio
        };
    });
    return results;
}

function findSourcesByTerm(term) {
    const cachedResult = queryCache.get(term);
    if (cachedResult) {
        return cachedResult;
    } else {
        const sourceSearch = new SourceSearch([], {
            query: term
        });
        const apiPromise = sourceSearch.fetch();
        const optionsPromise = apiPromise.then(function () {
            return generateSelect2Results(sourceSearch, term);
        });
        queryCache.put(term, optionsPromise);
        return optionsPromise;
    }
}

export default class GlobalSearchSourcesOptionsProvider extends OptionsProvider{
    results(term) {
        if (isMoreThanThreeCharsLong(term)) {
            term = term.replace(/:/, '');
            return validateAgentResultQuery(term).then(function (valid) {
              if (valid) {
                return findSourcesByTerm(term);
              }
            });
        } else {
            const deferred = Deferred.build();
            deferred.resolve();
            return deferred.promise();
        }
    }
}
