import _ from 'underscore'
import Backbone from 'backbone'

var TimespanSelection = Backbone.Model.extend({
  identifier: 'timespanSelection',
  initialize: function () {
    this.on('change:start_date', this.updateReport);
    this.on('change:end_date', this.updateReport);
    this.on('change', this.checkForCompleteness);
    var report = this.get('report');
    this.set({
      start_date: report.get('start_date'),
      end_date: report.get('end_date')
    });
    this.on('change:active', () => {
      this.trigger('updateClipApproximation');
    });
  },
  updateReport: function () {
    var report = this.get('report');
    report.set({
      start_date: this.get('start_date'),
      end_date: this.get('end_date')
    });
  },

  data: function () {
    return {
      start_date: this.get('start_date'),
      end_date: this.get('end_date')
    };
  },

  checkForCompleteness: function () {
    var start_date = this.get('start_date');
    var end_date = this.get('end_date');
    var complete = !_.isUndefined(start_date) && !_.isUndefined(end_date);
    this.set({complete: complete});
  }
});

export default TimespanSelection;

