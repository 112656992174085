import * as Backbone from 'backbone'

export interface IAddressbookContact {
  id: number
  is_user_contact: boolean
  contact_group_ids: Array<number>
  email: string
  name: string | null
}

export interface IAddressbookGroup {
  id: number
  name: string
  contacts: Array<IAddressbookModelProps>
}

export interface IAddressbookModelProps {
  id: string
  type: AddressbookType
  data: IAddressbookContact | IAddressbookGroup
}

export function isContact(obj: IContact | IContactGroup): obj is IContact {
  return obj.get('user_group_ids') || obj.get('email')
}

export interface IContactJSON {
  contact: {
    name: string | undefined
    email: string
    user_group_id: number
    contact_group_ids: Array<number>
  }
}

export interface IContact extends Backbone.Model {
  id: number
  getName(): string;
  updateIncurCost(params: any): any
  isUserContact(): boolean
  toString(): string
  parse(response: any): any
  toJSON(): IContactJSON
  get(attribute: string): any
}

export interface IContactGroupJSON {
  contact_group: {
    name: string
  }
}

export interface IContactGroup {
  defaults(): { string: Array<any> }
  toString(): string
  addContactIncurCost(contact: IContact): any
  addContact(contact: IContact): any
  removeContact(contact: IContact): any
  fetchDomainsOnce(): any
  parse(response: any): any
  toJSON(): IContactGroupJSON
  get(attribute: 'id'): number
  get(attribute: string): any
}

export enum AddressbookType {
  Contact, ContactGroup
}

export interface IContactCollection {
  models: Array<IContact>
}

export default class AddressbookModel {
  id: string
  type: AddressbookType
  data: IContact | IContactGroup

  constructor(id: string, type: AddressbookType, contactOrGroup: IContact | IContactGroup) {
    this.id = id
    this.type = type
    this.data = contactOrGroup
  }

  toReactProps(): IAddressbookModelProps {
    if (!isContact(this.data)) {
      let contactGroup = this.data
      let name = contactGroup.toJSON().contact_group.name
      let props: IAddressbookGroup = {
        name: name,
        contacts: contactGroup.get('contacts'),
        id: contactGroup.get('id')
      }
      return {
        id: this.id,
        type: AddressbookType.ContactGroup,
        data: props
      }
    } else {
      let contact = this.data
      let contactData = contact.toJSON().contact
      let name: string | undefined | null = contactData.name
      if (name === undefined) {
        name = null
      }
      let props: IAddressbookContact = {
        id: contact.get('id'),
        is_user_contact: contact.isUserContact(),
        contact_group_ids: contactData.contact_group_ids,
        email: contactData.email,
        name: name
      }
      return {
        id: this.id,
        type: AddressbookType.Contact,
        data: props
      }
    }
  }
}
