import Marionette from 'backbone.marionette';
import { LawDetailView, Props } from 'components/lawDetailView'
import { IEsApiEntity as Entity } from 'models/esResult'
import * as _ from 'underscore'
import * as React from "react";
import ReactWrapper from 'views/marionetteReactWrapper';
import LayoutView from 'views/layoutView'
import { IKbApiEntity } from 'models/kbApiEntity'

class LawsDetailController extends Marionette.Controller {
  initialize(opts: { law?: IKbApiEntity }) {
    if (opts.law) {
      this.law = opts.law
    }
    this.view = new LayoutView()
    this.listenTo(this.view, 'render', () => this.renderView())
  }

  private renderView(additionalOpts?: object) {
    const props = this.buildProps(additionalOpts)
    const sidebarView = new ReactWrapper({
      getComponent: () => (<LawDetailView {...props} />)
    });
    this.view.showView(sidebarView);
  }

 private buildProps(additionalProps?: object): object {
    let props: Props = {}
    if (this.law && this.law.data) {
      props['law'] = this.law
    }
    return _.extend(props, additionalProps)
  }
}

export default LawsDetailController;
