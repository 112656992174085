import Backbone from 'backbone'
import _ from 'underscore'
import Widget from 'models/dashboard/widget'
import $ from 'jquery'
import Config from 'libraries/configuration'
import 'models/dashboard/widget/agentChartWidget'
import 'models/dashboard/widget/agentAdValueWidget'
import 'models/dashboard/widget/tagAdValueWidget'
import 'models/dashboard/widget/multiAgentChartWidget'
import 'models/dashboard/widget/socialMediaTrackerChartWidget'
import 'models/dashboard/widget/swissLogoWidget'
import 'models/dashboard/widget/swissFacebookWallWidget'
import 'models/dashboard/widget/agentSourceDistributionPieWidget'
import 'models/dashboard/widget/trendingTopicsWidget'
import 'models/dashboard/widget/agentTopSourcesWidget'
import 'models/dashboard/widget/tagTopSourcesWidget'
import 'models/dashboard/widget/geochartSwitzerlandWidget'
import 'models/dashboard/widget/timeWidget'
import 'models/dashboard/widget/tickerWidget'
import 'models/dashboard/widget/welcomeWidget'
import 'models/dashboard/widget/agentRatingWidget'
import 'models/dashboard/widget/tagRatingWidget'
import 'models/dashboard/widget/rssFeedWidget'
import 'models/dashboard/widget/tagCloudWidget'
import 'models/dashboard/widget/keySourcesWidget'
import 'models/dashboard/widget/keyCommentSourcesWidget'
import 'models/dashboard/widget/trendingTopicsOnAgentClipsWidget'
import 'models/dashboard/widget/tagChartWidget'
import 'models/dashboard/widget/multiTagChartWidget'
import 'models/dashboard/widget/tagSourceDistributionPieWidget'
var Widgets = Backbone.Collection.extend({
  url: function () { return Config.apiUrl + "dashboard_widgets"; },
  model: function (attributes, options) {
    var className = attributes.type;
    var Klass = Widget[className];
    if (_.isUndefined(Klass)) {
      // fall back to using Widget if the type is not known
      Klass = Widget;
    }
    return new Klass(attributes, options);
  },
  parse: function (response) {
    return response.collection;
  },

  savePositions: function (positions) {
    var collection = this;
    var positionUrl = this.url() + "/positions";
    $.ajax({
      type: 'PUT',
      url: positionUrl,
      contentType: 'application/json',
      data: JSON.stringify({ dashboard_widgets: positions }),
      success: function (response) {
        _.each(response.collection, function (updatedWidget) {
          var widget = collection.get(updatedWidget.id);
          widget.set({
            'position_x': updatedWidget.position_x,
            'position_y': updatedWidget.position_y
          });
        });
      }
    });
  }
});

export default Widgets;
