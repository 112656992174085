import Tags from 'collections/tags';
import manualTags from 'collections/manualTagsInstance';

let tags = new Tags();

tags.on('add', function(tag) {
    'use strict';
    if (tag.isManual()) { manualTags.add(tag); }
});

tags.on('remove', function(tag) {
    'use strict';
    if (tag.isManual()) { manualTags.remove(tag); }
});

export default tags;
