import Marionette from 'backbone.marionette'
import moment from 'moment'
import _ from 'underscore'
import TimeSelectorView from 'views/settings/timeSelectorView'

var TimeSelectorController = Marionette.Controller.extend({
  initialize: function (options) {
    var duration = options.duration;
    var currentValue = options.value;
    this._options = TimeSelectorController.generateOptions(duration);
    this._currentValue = currentValue;
    this._checkValue(this._currentValue);
    this.view = new TimeSelectorView(this._options);
    this._updateViewValue();
    this._registerListeners();
  },
  _checkValue: function (value) {
    if (!this._optionExists(value)) {
      throw 'value ' + value.format() + ' is not a valid option';
    }
  },
  _optionExists: function (value) {
    return !_.isUndefined(this._findOption(value));
  },
  _findOption: function (value) {
    return _.find(this._options, function (t) {
      return t.isSame(value);
    });
  },
  _updateViewValue: function () {
    this.view.setValue(this._currentValue);
  },
  _triggerChange: function () {
    this.trigger('change', this._currentValue);
  },
  updateValue: function (newValue) {
    this._currentValue = newValue;
    this._updateViewValue();
    this._triggerChange();
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'next', this._next);
    this.listenTo(this.view, 'previous', this._previous);
    this.listenTo(this.view, 'selected', this._selected);
  },
  _next: function () {
    this.updateValue(this._nextOption());
  },
  _previous: function () {
    this.updateValue(this._previousOption());
  },
  _selected: function (selectedOption) {
    this._checkValue(selectedOption);
    if (!selectedOption.isSame(this._currentValue)) {
      this.updateValue(selectedOption);
    }
  },
  _nextOption: function () {
    var index = this._indexOfCurrentValue();
    return this._optionAtIndex(index + 1);
  },
  _previousOption: function () {
    var index = this._indexOfCurrentValue();
    return this._optionAtIndex(index - 1);
  },
  _indexOfCurrentValue: function () {
    var option = this._findOption(this._currentValue);
    return _.indexOf(this._options, option);
  },
  _optionAtIndex: function (index) {
    // loop around
    var length = this._options.length;
    index = (index + length) % length;
    return this._options[index];
  }
});

TimeSelectorController.generateOptions = function (duration) {
  var startOfDay = moment().startOf('day');
  var endOfDay = moment().endOf('day');

  var options = [];
  var current = moment(startOfDay);
  while (current.isBefore(endOfDay)) {
    options = options.concat(current);
    current = moment(current).add(duration);
  }
  return options;
};

export default TimeSelectorController;

