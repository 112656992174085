import jQuery from 'jquery'

// jQuery’s deep merging function is very hidden, as you have to
// pass true as the first argument to jQuery.extend (otherwise it
// will do a shallow merge).
// 
// This function has a more understandable name and makes deep
// merging available without making the jQuery object itself
// available.
function deepMerge(...args) {
  return jQuery.extend(...[true].concat(args));
}

export default deepMerge;
