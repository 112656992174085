import Marionette from 'backbone.marionette'
import AgentResultRatingController from 'controllers/agents/agentResultRatingController'
import AgentResultMarkImportantController from 'controllers/agents/agentResultMarkImportantController'
import AgentResultTagController from 'controllers/agents/agentResultTagController'
import AgentResultCommentController from 'controllers/agents/agentResultCommentController'
import SelectedAgentResults from 'models/selectedAgentResults'
import concurrentSelectionManager from 'models/concurrentSelectionManager'
import AgentResultActionsView from 'views/agents/agentResultActionsView'
import AgentEditWarningView from 'views/agents/agentEditWarningView'
import CloseSubObjects from 'libraries/closeSubObjects'

var AgentResultActionsController = Marionette.Controller.extend({
  initialize: function (options) {
    this.agentResult = options.agentResult;
    this.view = new AgentResultActionsView({model: this.agentResult});
    this._registerListeners();
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'render', this.showRatings);
    this.listenTo(this.view, 'render', this.showTags);
    this.listenTo(this.view, 'render', this.showMarkImportant);
    this.listenTo(this.view, 'render', this.showComments);
    // Display warning if someone else has the view already open
    this.listenTo(this.view, 'render', this.showEditWarning);
    // Display warning if someone else is opening this result view
    this.listenTo(concurrentSelectionManager, 'change', this.showEditWarning);
  },
  onClose: function () {
    CloseSubObjects.closeAll(
      [this.ratingController,
       this.markAsImportantController,
       this.tagController,
       this.commentController]
    );
  },
  showComments: function () {
    this.commentController =
      new AgentResultCommentController(this.agentResult);
    this.view.showComments(this.commentController.view);
  },
  showRatings: function () {
    var agentResults = new SelectedAgentResults([this.agentResult]);
    this.ratingController =
      new AgentResultRatingController(agentResults);
    this.view.showRatings(this.ratingController.view);
  },
  showMarkImportant: function () {
    var agentResults = new SelectedAgentResults([this.agentResult]);
    var opts = {
      selectedAgentResults: agentResults
    };
    this.markAsImportantController =
      new AgentResultMarkImportantController(opts);
    this.view.showMarkImportant(this.markAsImportantController.view);
  },
  showTags: function () {
    var agentResults = new SelectedAgentResults([this.agentResult]);
    this.tagController = new AgentResultTagController(
      agentResults.collection
    );
    this.view.showTags(this.tagController.view);
  },
  showEditWarning: function () {
    var concurrentSelection = concurrentSelectionManager
          .getSelectionForId(this.agentResult.id);
    var editWarningView = new AgentEditWarningView({
      model: concurrentSelection
    });
    if (!this.view.isClosed) {
      this.view.showAgentEditWarning(editWarningView);
    }
  }
});

export default AgentResultActionsController;
