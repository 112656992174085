import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/editReaderCategoryLanguageSelection.html';
import $ from 'jquery';
import _ from 'underscore';

class EditReaderCategoryLanguageSelectionView extends Marionette.ItemView {
    initialize() {
        this.listenTo(this.model, 'change', this.render);
    }

    onRender() {
        let langs = this.model.get('languages');
        if (_.isEmpty(langs)) {
            $(this.ui.list).hide();
            $(this.ui.allLanguages).addClass('is-active');
            $(this.ui.onlySelected).removeClass('is-active');
        } else {
            this._handleOnlySelectedClick();
            _.each(langs, (lang) => {
                if (lang === "de") {
                    $(this.ui.german).addClass('is-active');
                }
                if (lang === "en") {
                    $(this.ui.english).addClass('is-active');
                }
                if (lang === "fr") {
                    $(this.ui.french).addClass('is-active');
                }
                if (lang === "it") {
                    $(this.ui.italian).addClass('is-active');
                }
            });
        }
    }


    selectedLanguages() {
        if ($(this.ui.allLanguages).hasClass('is-active')) {
            return [];
        } else {
            return this.languages();
        }
    }

    languages() {
        var out = [];

        if ($(this.ui.german).hasClass('is-active')) {
            out = out.concat("de");
        }
        if ($(this.ui.english).hasClass('is-active')) {
            out = out.concat("en");
        }
        if ($(this.ui.italian).hasClass('is-active')) {
            out = out.concat("it");
        }
        if ($(this.ui.french).hasClass('is-active')) {
            out = out.concat("fr");
        }

        return out;
    }

    _handleAllLanguagesClick() {
        $(this.ui.list).hide();
        $(this.ui.allLanguages).addClass('is-active');
        $(this.ui.onlySelected).removeClass('is-active');
        this.trigger('select:languages:all');
    }

    _handleOnlySelectedClick() {
        $(this.ui.list).show();
        $(this.ui.allLanguages).removeClass('is-active');
        $(this.ui.onlySelected).addClass('is-active');
    }

    _handleGerman() {
        $(this.ui.german).toggleClass('is-active');
        this._triggerModelChanged();
    }

    _handleEnglish() {
        $(this.ui.english).toggleClass('is-active');
        this._triggerModelChanged();
    }

    _handleFrench() {
        $(this.ui.french).toggleClass('is-active');
        this._triggerModelChanged();
    }

    _handleItalian() {
        $(this.ui.italian).toggleClass('is-active');
        this._triggerModelChanged();
    }

    _triggerModelChanged() {
        let self = this;
        let languages = self.selectedLanguages();

        if (!_.isUndefined(this.timer)) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            self.trigger('select:languages:only-selected',
                         languages);
        }, 2000);
    }
}

EditReaderCategoryLanguageSelectionView.prototype.template = template;
EditReaderCategoryLanguageSelectionView.prototype.ui = {
    allLanguages: '[data-all-languages]',
    onlySelected: '[data-only-selected]',
    list: '[data-language-list]',
    german: '[data-german]',
    english: '[data-english]',
    french: '[data-french]',
    italian: '[data-italian]',
};
EditReaderCategoryLanguageSelectionView.prototype.events = {
    'click @ui.allLanguages': '_handleAllLanguagesClick',
    'click @ui.onlySelected': '_handleOnlySelectedClick',
    'click @ui.german': '_handleGerman',
    'click @ui.english': '_handleEnglish',
    'click @ui.french': '_handleFrench',
    'click @ui.italian': '_handleItalian',
};

export default EditReaderCategoryLanguageSelectionView;
