import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/settings/editAgentSocialMediaMonitors.html'
import handleTooltips from 'models/handleTooltips'

var EditAgentSocialMediaMonitorView = Marionette.Layout.extend({
  template: template,
  regions: {
    socialMediaMonitorList: '[data-social-media-monitor-list]'
  },
  initialize: function () {
    handleTooltips(this);
  },
  showSocialMediaMonitorList: function (view) {
    this.socialMediaMonitorList.show(view);
  }
});

export default EditAgentSocialMediaMonitorView;

