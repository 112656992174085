import * as _ from "underscore";

// TODO: Needs a specs but the spec setup of webapp is broken
// Removes incomplete tags like:
// - "Oktober 19904, <span class='clip-detail__highlig"
// Would result to "Oktober 19904, "
// and closes tags like:
// - "Oktober 19904, <span class='clip-detail__highlight>"
// Would result to "Oktober 19904, <span class='clip-detail__highlight></span>"
function removeOrCloseOpenTags(text: string): string {
    let doc = document.createElement("div");
    doc.innerHTML = text;
    return _.unescape(doc.innerHTML);
}

function truncate(text: string, length: number): string {
  if (!_.isString(text)) {
    return "";
  } else if (text.length <= length) {
    return text;
  } else {
    return removeOrCloseOpenTags(text.substring(0, length)) + "…";
  }
}

export default truncate;
