import * as React from "react";
import I18n from "i18n";
import * as jQuery from "jquery";
import * as _ from "underscore";
import User from 'models/user'
import Agent from 'models/agent'
import truncate from 'libraries/truncate'
import users from 'collections/usersInstance'

interface Props {
  users: User[]
  subscribedUserIds: number[]
  onUnSubscribeClick: (user: User) => void
  onSubscribeClick: (user: User) => void
}

interface State {
  filter: string
}

class AgentSubscriptions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      filter: ''
    }
  }
  render() {
    return (
      <div className="mtm mbm">
        <ul className="contact-list">
          {this.searchInput()}
          <li className="contact-list__item contact-list__item-group-title">
            {this.subscribedUsersHeader()}
            <ul>
              {this.subscribedUserList()}
            </ul>
          </li>
          <li className="contact-list__item contact-list__item-group-title">
            {this.unsubscribedUsersHeader()}
            <ul>
              {this.unsubscribedUserList()}
            </ul>
          </li>
        </ul>
      </div>
    )
  }

  subscribedUserList() {
    const markup = _.map(
      this.subscribedUsers(),
      (user: User) => this.subscribedUser(user)
    )
    return markup
  }

  unsubscribedUserList() {
    const markup = _.map(
      this.unSubscribedUsers(),
      (user: User) => this.unSubscribedUser(user)
    )
    return markup
  }

  subscribedUsers() {
    return _.filter(
      this.props.users,
      (user: User) =>
        _.contains(this.props.subscribedUserIds, user.get('id')) &&
        this.matches(user, this.state.filter)
    )
  }

  userName(user: User) {
    return user.get('full_name')
  }

  unSubscribedUsers() {
    return _.filter(
      this.props.users,
      (user: User) =>
        !_.contains(this.props.subscribedUserIds, user.get('id')) &&
        this.matches(user, this.state.filter)
    )
  }

  searchInput() {
    const key = 'webapp.agents.edit.agent_subscriptions.search_placeholder'
    return (
      <li className="contact-list__item contact-list__item-filter">
        <div className="contact-list__item-lens">
          <i className="icon-lens"></i>
        </div>
        <form>
          <input className="contact-list__filter text-input" data-contacts-search="true" placeholder={I18n.t(key)} type="text" onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => this.onKeyUp(e)} defaultValue={this.state.filter} />
        </form>
      </li>
    )
  }

  onKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    const enteredText = e.currentTarget.value
    if (enteredText !== null) {
      this.setState((prevState, props) => {
        return {
          filter: enteredText
        }
      })
    }
  }

  matches(user: User, filter: string): boolean {
    const text = this.userName(user).toLowerCase()
    return text.indexOf(filter.toLowerCase()) >= 0
  }

  subscribedUsersHeader() {
    const key =
      'webapp.agents.edit.agent_subscriptions.subscribed_users'
    return (
      <div className="contact-list__item-field">
        <strong>{I18n.t(key)}</strong>
      </div>
    )
  }

  unsubscribedUsersHeader() {
    const key =
      'webapp.agents.edit.agent_subscriptions.unsubscribed_users'
    return (
      <div>
        <div className="contact-list__item-field">
          <span><strong>{I18n.t(key)}</strong></span>
        </div>
      </div>
    )
  }

  subscribedUser(user: User) {
    const name = this.userName(user)
    const key = 'subscribed-users-' + user.get('id')

    return (
      <li className="contact-list__item" data-filterable-text={name} key={key}>
        <div className="contact-list__item-field">
          <div className="contact-list__item-actions">
            <i className="icon-delete" onClick={() => this.props.onUnSubscribeClick(user)}></i>
          </div>
          <span data-contact-name-field="true"> {name} </span>
        </div>
      </li>
    )
  }

  unSubscribedUser(user: User) {
    const name = this.userName(user)
    const key = 'unsubscribed-users-' + user.get('id')

    return (
      <li className="contact-list__item" data-filterable-text={name} key={key}>
        <div className="contact-list__item-field">
          <div className="contact-list__item-actions">
            <i className="icon-plus" onClick={() => this.props.onSubscribeClick(user)}></i>
          </div>
          <span><span data-contact-name-field="true"> {name} </span></span>
        </div>
      </li>
    )
  }
}

export default AgentSubscriptions