import Marionette from "backbone.marionette";
import _ from "underscore";
import I18n from 'i18n';
import template from "text-loader!templates/agents/settings/editAgentSourceSelection.html";
import handleTooltips from "models/handleTooltips";
import * as React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper';
import AgentTimeframeButton from "views/agents/agentTimeframeButton";

class EditAgentSourceSelectionView extends Marionette.Layout {
  initialize() {
    _.bindAll(this, "handleClickStandardSources");
    handleTooltips(this);
  }

  deselectSourceSelections() {
    this.ui.standardSources.removeClass("is-selected");
    this.ui.standardSources.removeClass("is-active");
  }

  handleClickStandardSources() {
    this.ui.standardSources.addClass("is-selected");
    this.ui.standardSources.addClass("is-active");
    this.trigger("show:standard:sources");
  }

  showTimeframeSettings(props) {
    const timeframeButton = new ReactWrapper({
        getComponent: () => {
            return <AgentTimeframeButton {...props}/>;
        }
    });
    this.timeframeSettings.show(timeframeButton);
  }

  //the function below is to count the categories
  //which is not being used now, as it has the problem
  //for rtv where the subcategories and the subsubcategories
  //of rtv are counted as subcategories.
  btnText() {
    let sourceCategoryIdsLength = this.model.get('source_category_ids') && this.model.get('source_category_ids').length;
    let sourceGroupIdsLength = this.model.get('source_group_ids') && this.model.get('source_group_ids').length;
    let totalSelectedCategories = sourceCategoryIdsLength + sourceGroupIdsLength;
    let msg = I18n.t('webapp.agents.edit.settings.standard_categories');
    let noCategoryMsg = I18n.t('webapp.agents.edit.settings.no_category_selected');
    if (totalSelectedCategories > 0) {
      return {
        text: `${totalSelectedCategories} ${msg}`
      }
    } else {
      return {
        text: noCategoryMsg
      }
    }
  }
  //the function btnText is not being used now,
  //thus we can comment this part out.
  // serializeData() {
  //   return this.btnText();
  // }
}

EditAgentSourceSelectionView.prototype.template = template;
EditAgentSourceSelectionView.prototype.ui = {
  standardSources: "#standard-sources",
};
EditAgentSourceSelectionView.prototype.regions = {
  timeframeSettings: "[data-agent-timeframe-settings]",
};
EditAgentSourceSelectionView.prototype.events = {
  "click #standard-sources": "handleClickStandardSources"
};

export default EditAgentSourceSelectionView;
