import Backbone from 'backbone';
import $ from 'jquery';
import when from 'when';
import fetchOncePromise from 'libraries/fetchOncePromise';
import Domains from 'collections/whitelistDomains';

class ContactGroup extends Backbone.Model {
    defaults() {
        return { contact_ids: [] };
    }

    toString() {
        return this.get('name');
    }

    addContactIncurCost(contact) {
        const email = contact.get('email');
        return when($.ajax({
            type: 'POST',
            data: { email: email },
            url: `/api/v3/contact_groups/${this.id}/add_contact_incur_cost`
        }));
    }

    addContact(contact) {
        let id = contact.get('id');
        return when($.ajax({
            type: 'PUT',
            url: `/api/v3/contact_groups/${this.id}/contacts/${id}`
        })).tap(() => {
            contact.get('contact_group_ids').push(this.id);
        });
    }

    removeContact(contact) {
        let id = contact.get('id');
        return when($.ajax({
            type: 'DELETE',
            url: `/api/v3/contact_groups/${this.id}/contacts/${id}`
        })).tap(() => {
            let ids = contact.get('contact_group_ids');
            let index = ids.indexOf(this.id);
            if (index > -1) {
                ids.splice(index, 1);
            }
        });
    }

    fetchDomainsOnce() {
        if (this.fetchDomainsPromise === undefined) {
            this.domains = new Domains([], { contactGroup: this });
            this.fetchDomainsPromise = fetchOncePromise(this.domains);
        }
        this.fetchDomainsPromise.fetch().done((domains) => {
            this.set({ domains: domains });
        });
        return this.fetchDomainsPromise;
    }

    parse(response) {
        return response.object || response;
    }

    toJSON() {
        return {
            "contact_group": {
                "name": this.get('name')
            }
        };
    }
}

ContactGroup.prototype.type = 'contact_group';

export default ContactGroup;
