import * as React from "react";

interface SidebarActionsMenuItemProps {
  onClick: () => void
  label: string
}

const SidebarActionsMenuItem = (props: SidebarActionsMenuItemProps) => {
  return (
    <li className="dropdown__item" role="menuitem">
      <a className="dropdown__link" data-target="upload-pmg-result" onClick={props.onClick}>
        {props.label}
      </a>
    </li>
  )
}

export default SidebarActionsMenuItem