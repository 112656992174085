import WidgetView from 'views/dashboard/widgetView'
import SourceDistributionPieWidgetView from 'views/dashboard/widgetView/sourceDistributionPieWidgetView'
import template from 'text-loader!templates/dashboard/widget/agentSourceDistributionPieWidget.html'

WidgetView.AgentSourceDistributionPieWidget = SourceDistributionPieWidgetView.extend({
  template: template,
  onInitialize: function () {
    SourceDistributionPieWidgetView.prototype.onInitialize.call(this);
    this.setClickHandler();
  },
  _showFilteredResultsByType: function (type) {
    this.model.showAgentTag([{ type: 'mediaType', id: type }]);
  }

});

export default WidgetView.AgentSourceDistributionPieWidget;

