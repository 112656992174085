import ClipCreationFormView from 'views/clipCreationFormView';
import template from 'text-loader!templates/pmgUploadForm.html';

class PmgUploadFormView extends ClipCreationFormView {
    initialize() {
        this.buttons = [];
    }

    onRender() {
        this.hideUploadInProgress();
        this.hideValidationMessages();
        this.setupPMGDropZone();
        const el = this.$('[data-pmg-view]');
        setTimeout(() => { el.focus() }, 0);
    }

    hideValidationMessages() {
        this.$('[data-file-error]').hide();
    }
    hideUpload() {
        this.$('[data-pmg-article-upload]').hide();
    }

    showUpload() {
        this.$('[data-pmg-article-upload]').show();
    }

    pmgFilePickerChanged() {
        this.file = this.ui.pmgFileUpload[0].files[0];
        this.trigger('change:file');
    }

    _handleKeyboardInput(e) {
        if (e.keyCode === 13) {
            const pmgPicker = this.$('#pmg-file-upload');
            pmgPicker.click();
        }
    }
}

PmgUploadFormView.prototype.template = template;
PmgUploadFormView.prototype.events = {
    'change #pmg-file-upload': 'pmgFilePickerChanged',
    'keyup [data-pmg-view]': '_handleKeyboardInput'
};

export default PmgUploadFormView;
