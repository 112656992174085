import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'
import * as jQuery from 'jquery'
import I18n from 'i18n'

export interface ISelect2Props {
  options: Select2Option[]
  configuration: Select2Configuration
  selection: Select2Id[]
  onSelectionChange: (selection: Select2Id[]) => void
  allowArbitraryInput: boolean
  readonly: boolean
}

interface ISelect2State {
  currentSelection: Select2Id[]
}

export interface Select2CallbackOptions {
  term: string;
  page: number;
  callback: (results: any) => void;
}

export type Select2Id = number | string

export interface Select2Option extends Select2Keyword {
  searchableText?: string
}

export interface Select2Configuration {
  allowMultiSelect?: boolean;
  fetchOptionsCallback?: (options: Select2CallbackOptions) => any;
  fetchOptionsToSelect2?: (results: any) => any;
  knownFetchOptions: () => Select2Option[];
  minimumInputLength?: number;
}

export interface Select2Keyword {
  text: string
  id: Select2Id
}

export interface Select2Results extends Select2Keyword {
  parentTopicText?: string
  parentTopicId?: number
}

interface Select2PagedResults {
  results: Select2Keyword[]
  more: boolean
}

interface Select2QueryCallback {
  (options: Select2PagedResults): void
}

export class Select2WithAndSeparator extends React.Component<ISelect2Props, ISelect2State> {
  private select2Container: any

  constructor(props: ISelect2Props) {
    super(props)
    this.state = this.initialState(props)
  }

  private initialState(props: ISelect2Props) {
    return {
      currentSelection: props.selection.slice()
    }
  }

  render() {
    const placeHolder = I18n.t("webapp.agents.edit.filters.select2_placeholder");

    if (this.props.readonly) {
      return (
        <form>
          <input type="text"
                 className="js-select2-input-search--separated"
                 data-placeholder={placeHolder}
                 disabled
                 ref={el => this.select2Container = el} />
        </form>
      );
    } else {
      return (
        <form>
          <input type="text"
                 className="js-select2-input-search--separated"
                 data-placeholder={placeHolder}
                 ref={el => this.select2Container = el } />
        </form>
      );
    }
  }

  rootNode(): JQuery {
    return jQuery(ReactDOM.findDOMNode(this)!)
  }

  select2Element(): any {
    return jQuery(this.select2Container)
  }

  componentDidMount() {
    this.setupSelect2()
  }

  componentWillReceiveProps(nextProps: ISelect2Props) {
    if (nextProps.selection !== this.state.currentSelection) {
      this.setState((prevState: ISelect2State) => {
        return {
          currentSelection: nextProps.selection
        }
      })
    }
  }

  componentDidUpdate(prevProps: ISelect2Props, prevState: ISelect2State) {
    if (!_.isEqual(prevProps.options, this.props.options)) {
      this.destroySelect2()
      this.setupSelect2()
    } else {
      this.setSelect2Selection()
    }
  }

  componentWillUnmount() {
    this.closeSelect2()
    this.destroySelect2()
    this.bodyClickHandler = undefined
  }

  private destroySelect2() {
    (this.select2Element() as any).select2('destroy')
    if (this.bodyClickHandler) {
      jQuery('body').off('click', this.bodyClickHandler)
      this.bodyClickHandler = undefined
    }
  }

  private closeSelect2() {
    (this.select2Element() as any).select2('close')
  }

  private setupSelect2() {
    let doQuery = this.queryCallback.bind(this);
    if (!_.isUndefined(this.props.configuration.fetchOptionsCallback)) {
      doQuery = _.debounce(this.queryCallback.bind(this), 1000);
    }

    (this.select2Element() as any).select2({
      containerCssClass: "custom-select2 custom-select2--separated",
      width: "100%",
      formatResult: (keyword: Select2Keyword) => keyword.text,
      formatSelection: (keyword: Select2Keyword) => keyword.text,
      formatNoMatches: I18n.t('webapp.agents.edit.filters.select2_noMatches'),
      formatLoadMore: I18n.t('webapp.agents.edit.filters.select2_loadMore'),
      query: (options: any) => { doQuery(options); },
      createSearchChoice: (term: string) => this.createSearchChoice(term),
      multiple: this.allowMultiSelect(),
      minimumInputLength: this.minimumInputLength()
    }).on('change', () => {
      this.showOrSeparators()
      let newSelection = this.getSelect2Selection()
      this.props.onSelectionChange(newSelection)
    }).on("select2-open", () => {
      const input = jQuery("input.select2-input.select2-focused");
      input.blur();
      input.focus();
    });
    this.setSelect2Selection()
    this.bodyClickHandler = () => this.closeSelect2()
    jQuery('body').on('click', this.bodyClickHandler)
  }

  private minimumInputLength() {
    let config = this.props.configuration;
    if (_.isUndefined(config.minimumInputLength)) {
      return 0;
    } else {
      return config.minimumInputLength;
    }
  }

  private allowMultiSelect() {
    let config = this.props.configuration;
    if (_.isUndefined(config.allowMultiSelect)) {
      return true;
    } else {
      return config.allowMultiSelect;
    }
  }

  private bodyClickHandler: any

  private queryCallback(options: any): void {
    const term: string = options.term;
    const callback: Select2QueryCallback = options.callback;
    const page = options.page;
    const pageSize = 50;

    let config = this.props.configuration;
    let fetchOptions = config.fetchOptionsCallback;
    let toSelect2 = config.fetchOptionsToSelect2;

    if (fetchOptions !== undefined) {
      fetchOptions(options).done((apiResults: any) => {
        if (toSelect2 !== undefined) {
          let forSelect2 = toSelect2(apiResults);
          let results = this.mapAllResults(forSelect2);
          let groupedByParentTopic = this.groupByParentTopic(results)
          callback(this.paginate(groupedByParentTopic, page, pageSize))
        }
      }).fail(() => {
        callback(this.paginate([], page, pageSize));
      });
    } else {
      let results = this.generateAllResults(term, this.options());
      let groupedByParentTopic = this.groupByParentTopic(results)
      callback(this.paginate(groupedByParentTopic, page, pageSize))
    }
  }

  private filterAllResults(term: string, options: Select2Option[]): Select2Option[] {
    return options.filter((option: Select2Option) => {
      let text = option.searchableText || option.text;
      return text.toLowerCase().indexOf(term.toLowerCase()) > -1;
    })
  }

  private mapAllResults(options: Select2Option[]): Select2Results[] {
    return options.map((option: Select2Results) => {
      let {id, text, parentTopicId, parentTopicText} = option
      return { id, text, parentTopicId, parentTopicText }
    });
  }

  private generateAllResults(term: string, options: Select2Option[]): Select2Results[] {
    return this.mapAllResults(this.filterAllResults(term, options));
  }

  private groupByParentTopic(results: Select2Results[]): any {
    let groupedTopics = _.groupBy(results, 'parentTopicId')
    let pairs = _.pairs(groupedTopics)
    let result = pairs.map((pair) => {
      return { text: pair[1][0].parentTopicText,
               children: this.getParentTopicChildren(pair[1])
      }
    })
    return result
  }

  private getParentTopicChildren(children_array: Select2Results[]): Select2Keyword[] {
    let children = children_array.map((child) => { return { id: child.id, text: child.text } })
    return children
  }

  private paginate(results: Select2Keyword[], page: number, pageSize: number): Select2PagedResults {
    return {
      results: results.slice((page - 1) * pageSize, page * pageSize),
      more: results.length >= page * pageSize
    }
  }

  private createSearchChoice(term: string) {
    if (this.props.allowArbitraryInput) {
      let text = term.replace(/[,"@]/g, '')
                     .replace(/^-|-$/, '')
                     .replace(/(\s)-(\w)/g, '$1$2')
                     .replace(/(\w)-(\s)/g, '$1$2')
                     .replace(/[«»‹›„‚“‟‘‛”"❛❜❟❝❞❮❯⹂〝〞〟＂]/g, '')
                     .trim()
      return {
        text: text,
        id: text
      }
    } else {
      return
    }
  }

  showOrSeparators() {
    let rootEl = this.rootNode()
    rootEl.find('.multiple-selection-or').remove();
    rootEl.find('.custom-select2--separated .select2-search-choice + .select2-search-choice').before(`<li class='multiple-selection-or'>${I18n.t('webapp.agents.edit.filters.multiple_selection_or')}</li>`)
}

  private getSelect2Selection() {
    return (this.select2Element() as any).select2('val')
  }

  private setSelect2Selection() {
    if (this.state.currentSelection !== this.getSelect2Selection()) {
      let config = this.props.configuration;
      if (this.props.allowArbitraryInput) {
        let data = this.state.currentSelection.map((t) => {
          return {id: t, text: t}
        }) as any
        (this.select2Element() as any).select2('data', data)
      } else if (config.knownFetchOptions !== undefined) {
        let data = this.state.currentSelection.map((t) => {
          const id = t;
          const option = _.find(config.knownFetchOptions(), (o) => {
            return o.id.toString() === id.toString();
          });
          if (!option) {
            throw `Option with id ${id} does not exist.`;
          }
          return { id: id, text: option.text };
        }) as any
        (this.select2Element() as any).select2('data', data)
      } else if (!_.isEmpty(this.options())) {
        let data = this.state.currentSelection.map((t) => {
          const id = t
          const options = this.options()
          const index = this.options
          const option = _.find(this.options(), (o) => o.id.toString() === id.toString())
          if (!option) {
            throw `Option with id ${id} does not exist.`
          }
          let text = option.text
          return { id, text }
        }) as any
        (this.select2Element() as any).select2('data', data)
      }
      this.showOrSeparators()
    }
  }

  private options(): Select2Option[] {
    return this.props.options
  }
}
