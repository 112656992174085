import * as React from "react";
import I18n from "i18n";

interface IPointingButtonProps {
  onClick: () => void;
  buttonText: string;
  isActive: boolean;
}

export default class PointingButton extends React.Component<IPointingButtonProps> {
  render() {
    let baseButtonClasses = [
      "mbl", "button", "button--block", "button--pointing", "align-left"
    ];
    if (this.props.isActive) { baseButtonClasses.push("is-active"); }

    return (
      <a className={baseButtonClasses.join(" ")} onClick={ this.props.onClick }>
        {this.props.buttonText}
      </a>
    );
  }
}
