import ResearchQueryBuilder from "models/research/researchQueryBuilder";
import $ from "jquery";
import _ from "underscore";
import moment from 'moment'

class NewResearchQueryBuilder extends ResearchQueryBuilder {
  toUrl() {
    let parameters = { limit: this.get('limit') || this.defaultLimit };
    let searchQuery = this.get('search_query');
    // Date is used for expanding the dates in timeline
    if (!_.isUndefined(this.get('date'))) {
      parameters.since = moment(this.get('date')).startOf('day').valueOf();
      // When before is explicitly set, we are doing partial fetch for a day
      // Fetch results since start of day and before last seen result
      if (!_.isUndefined(this.get('before'))) {
        parameters.before = this.get('before');
      // When before is not set, we fetch the whole day
      } else {
        parameters.before = moment(this.get('date')).endOf('day').valueOf();
      }
    } else {
      // Since and before are used for initial timeline load
      if (!_.isUndefined(this.get('since'))) {
        parameters.since = this.get('since');
      }
      if (!_.isUndefined(this.get('before'))) {
        parameters.before = this.get('before');
      }
    }
    parameters.source_category_ids = this.get('source_category_ids');
    parameters.search_query = searchQuery;
    if (this.get('aggregateTimelineStart')) {
        parameters.aggregate_timeline_start = true;
    }
    parameters.search_query = JSON.stringify(parameters.search_query, null, 2);
    return this.baseUrl + '?' + $.param(parameters);
  }
}


export default NewResearchQueryBuilder;
