import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/instagram.html'

var InstagramView = Marionette.Layout.extend({
  regions: {
  },
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    return {
      metadata: this.metadata()
    };
  },
  metadata: function () {
    var agentResult = this.agentResult;
    var data = {
      engagement: {
        likes_count: this.agentResult.get('clip_metadata').like_count,
        comments_count: this.agentResult.get('clip_metadata').comment_count
      }
    };
    if (agentResult.isInstagramClip() && agentResult.get('author') !== null) {
      var author = agentResult.get('author');
      data.reach = {
        followers_count: author.properties.follower_count
      };
    }
    return data;
  }
});

export default InstagramView;

