import * as React from 'react';
import Modal from './newModal';
import I18n from 'i18n';
import Notificator from '../libraries/notificator';
import { BASE_PATH } from './dashboard/DashboardAppConstants';

enum EmailStatus {
  VALID,
  INVALID,
  DOMAIN_INVALID
}

interface State {
  openModal: boolean;
  emailAddresses: string[];
  emailSubject: string;
  emailAdditionalText: string;
  emailStatus: EmailStatus;
}

interface Props {
  emailClipIds?: number[];
  emailAgentResultIds?: number[];
}

const EMAIL_REGEX = /\S+@\S+\.\S+/;

export default class SendEmailModal extends React.Component<Props, State> {
  state = {
    openModal: false,
    emailAddresses: [],
    emailSubject: '',
    emailAdditionalText: '',
    emailStatus: EmailStatus.VALID
  };

  render() {
    const prefix = 'webapp.result_lists.result_details.article_via_email.';
    const props = {
      title: I18n.t(`${prefix}title`),
      confirmationButtonBlue: true,
      cancelLabel: I18n.t(`${prefix}cancel`),
      confirmationLabel: I18n.t(`${prefix}send_email`),
      onConfirm: this.sendEmail,
      modalId: 'emailArticle',
      open: this.state.openModal,
      close: !this.state.openModal,
      onClose: this.hideModal,
      enableConfirmButton:
        this.state.emailAddresses.length > 0 &&
        this.state.emailSubject.length > 0
    };
    return (
      <>
        {this.link()}
        <Modal {...props}>
          <div>
            <form>
              <div
                className={`form-item ${this.state.emailStatus !== EmailStatus.VALID &&
                  'is-erroneous'}`}>
                <label htmlFor="email" className="form-item__label">
                  {`${I18n.t(
                    'webapp.result_lists.result_details.article_via_email.email_to'
                  )}* :`}
                </label>
                <div className="form-item__input">
                  <input
                    type="email"
                    required
                    placeholder={I18n.t("webapp.result_lists.result_details.article_via_email.email_placeholder")}
                    className="text-input text-input--block"
                    onChange={ev =>
                      this.setState({
                        emailAddresses: ev.target.value.split(/,/)
                      })
                    }
                    value={this.state.emailAddresses.join(',')}
                    onBlur={() =>
                      this.state.emailAddresses.forEach(i =>
                        EMAIL_REGEX.test(i)
                          ? this.setState({ emailStatus: EmailStatus.VALID })
                          : this.setState({ emailStatus: EmailStatus.INVALID })
                      )
                    }
                  />
                </div>
                {this.state.emailStatus !== EmailStatus.VALID ? (
                  <div className="form-item__feedback">
                    <i className="icon-info-2" />
                    {I18n.t("webapp.result_lists.result_details.article_via_email.input_error")}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <label htmlFor="text" className="form-item__label">
                {`${I18n.t(
                  'webapp.result_lists.result_details.article_via_email.subject'
                )}* :`}
              </label>
              <div className="form-item__input">
                <input
                  type="text"
                  required
                  className="text-input text-input--block"
                  value={this.state.emailSubject}
                  onChange={ev =>
                    this.setState({ emailSubject: ev.target.value })
                  }
                />
              </div>
              <p className="align-right">{`*${I18n.t(
                'webapp.result_lists.result_details.article_via_email.mandatory_field'
              )}`}</p>
              <label htmlFor="text" className="form-item__label">
                {`${I18n.t(
                  'webapp.result_lists.result_details.article_via_email.message'
                )} :`}
              </label>
              <div className="form-item__input">
                <textarea
                  className="textarea textarea--xlarge"
                  value={this.state.emailAdditionalText}
                  onChange={ev =>
                    this.setState({ emailAdditionalText: ev.target.value })
                  }
                />
              </div>
            </form>
          </div>
        </Modal>
      </>
    );
  }

  sendEmail = () => {
    fetch(`${BASE_PATH}/share_selected_articles_via_email`, {
      method: 'POST',
      body: JSON.stringify({
        clip_ids: this.props.emailClipIds,
        agent_result_ids: this.props.emailAgentResultIds,
        addresses: this.state.emailAddresses,
        subject: this.state.emailSubject,
        additional_text: this.state.emailAdditionalText
      }),
      credentials: 'same-origin',
      headers:{
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 400) {
          this.setState({emailStatus: EmailStatus.DOMAIN_INVALID})
        } else if (res.status >= 300) {
          throw new Error('Request not successful');        
        }
        return res;
      })
      .then(res => {
        if (res.ok) {
          Notificator.showNotification(
            I18n.t(
              'webapp.result_lists.result_details.article_via_email.success_message'
              )
              );
              this.setState({
                openModal: false,
                emailAddresses: [],
                emailSubject: '',
                emailAdditionalText: '',
                emailStatus: EmailStatus.VALID
              });
            }
      })
      .catch(() => {
        Notificator.showNotification(
          I18n.t(
            'webapp.result_lists.result_details.article_via_email.error_message'
          )
        );
      });
  };

  link() {
    return (
      <a onClick={this.showModal} className="link-bar__left-item">
        <i className="icon-envelope" />{" " + I18n.t('webapp.result_lists.result_details.article_via_email.link')}
      </a>
    );
  }

  private showModal = () => {
    this.setState({ openModal: true });
  };

  private hideModal = () => {
    this.setState({ openModal: false });
  };
}
