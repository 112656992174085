import _ from 'underscore'
import Backbone from 'backbone'
import agents from 'collections/agentsInstance'
import tags from 'collections/tagsInstance'
import manualTags from 'collections/manualTagsInstance'
import session from 'models/sessionInstance'
import I18n from 'i18n'

var TopicSelection = Backbone.Model.extend({
  identifier: 'topicSelection',
  initialize: function (opts) {
    this.on('change:selection', this.updateOptions);
    this.on('change:selection', this.updateReport);
    this.on('change:selection', this.checkForCompleteness);
    // initialize topics if report model given in opts
    if (opts.report) {
      var prefix = '';
      if (opts.report.get('topic_type') === 'agent') {
        prefix = 'a';
      } else if (opts.report.get('topic_type') === 'tag') {
        prefix = 't';
      } else if (opts.report.get('topic_type') === 'clip') {
        prefix = 'c';
      }

      var selection = _.map(opts.report.get('topic_ids'), function (topic_id) {
        return prefix + topic_id;
      });
      this.set({selection: selection});
    } else {
      this.set({selection: []});
    }
  },
  selection: function () {
    return this.get('selection');
  },
  checkForCompleteness: function () {
    var complete = !_.isEmpty(this.selection());
    this.set({complete: complete});
  },
  selectedOptionsCount: function () {
    var selection = this.selection();
    return selection ? selection.length : 0;
  },
  updateReport: function () {
    var selection = this.selection();
    var report = this.get('report');
    if (_.isEmpty(selection)) {
      report.set({topic_ids: []});
    } else {
      var topic_ids = this.extractTopicIds(selection);
      report.set({
        topic_type: this.topicType(),
        topic_ids: topic_ids
      });
    }
  },
  extractTopicIds: function (selection) {
    return _.map(selection, function (id) {
      return parseInt(id.substr(1), 10);
    });
  },
  topicType: function () {
    var selection = this.selection();
    if (!_.isEmpty(selection)) {
      if (_.first(selection)[0] === 'a') {
        return 'agent';
      } else if (_.first(selection)[0] === 't') {
        return 'tag';
      } else {
        return 'clip';
      }
    }
  },
  updateOptions: function () {
    this.set({options: this.availableOptions()});
  },
  availableOptions:  function () {
    var topicSelection = this;
    var selection = this.selection();
    var options = [];
    if (this.selectedOptionsCount() < session.get('features').reports_agent_tag_limit) {
      if (_.isEmpty(selection)) {
        options = options.concat(this.topicOptGroups(function (options) {
          return topicSelection.markSelectedOptions(options);
        }, 'agent'));
        options = options.concat(this.topicOptGroups(function (options) {
          return topicSelection.markSelectedOptions(options);
        }, 'tag'));
      } else {
        options = options.concat(this.topicOptGroups(function (options) {
          return topicSelection.markSelectedOptions(options);
        }));
      }
      return options;
    } else {
      options = options.concat(this.topicOptGroups(function (options) {
        return topicSelection.selectedOptions(options);
      }));

      return options;
    }
  },
  topicOptGroups: function (filter, topicType) {
    var topicMap = {
      agent: {label: I18n.t('webapp.reporting.wizard.topic_groups.agents'), options: this.agents},
      manualTag: {label: I18n.t('webapp.reporting.wizard.topic_groups.tags'), options: this.manualTags},
      smartTag: {label: I18n.t('webapp.reporting.wizard.topic_groups.smarttags'), options: this.smartTags}
    };
    if (!topicType) {
      topicType = this.topicType();
    }
    var topics;
    if (topicType === 'agent') {
      topics = [topicMap.agent];
    } else {
      topics = [topicMap.manualTag, topicMap.smartTag];
    }

    return _.map(topics, function (topic) {
      return {
        label: topic.label,
        options: filter(topic.options())
      };
    });
  },
  agents: function () {
    if (session.get('features').reports_select_by_agent) {
      if (_.isUndefined(this.cachedAgentOptions)) {
        this.cachedAgentOptions = agents.map(function (agent) {
          return {
            id: 'a' + agent.get('id'),
            name: agent.get('name')
          };
        });
      }
      return this.cachedAgentOptions;
    } else {
      return [];
    }
  },
  manualTags: function () {
    if (session.get('features').reports_select_by_tag) {
      if (_.isUndefined(this.cachedManualTagOptions)) {
        this.cachedManualTagOptions = manualTags.map(function (tag) {
          return {
            id: 't' + tag.get('id'),
            name: tag.get('name')
          };
        });
      }
      return this.cachedManualTagOptions;
    } else {
      return [];
    }
  },
  smartTags: function () {
    if (session.get('features').reports_select_by_tag) {
      if (_.isUndefined(this.cachedSmartTagOptions)) {
        var smartTags = tags.filter(function (tag) {
          return !tag.isManual();
        });
        this.cachedSmartTagOptions = smartTags.map(function (tag) {
          return {
            id: 't' + tag.get('id'),
            name: tag.visibleName()
          };
        });
      }
      return this.cachedSmartTagOptions;
    } else {
      return [];
    }
  },
  markSelectedOptions: function (array) {
    return _.map(array, (option) => {
      var selected = _.include(this.selection(), option.id);
      return {
        id: option.id,
        name: option.name,
        selected: selected
      };
    }, this);
  },
  selectedOptions: function (array) {
    var selection = this.selection();
    var rawOpts = _.filter(array, function (option) {
      return _.include(selection, option.id);
    });
    return _.map(rawOpts, function (option) {
      return {
        id: option.id,
        name: option.name,
        selected: true
      };
    });
  },
  data: function () {
    var selection = this.selection();
    var topic_ids = this.extractTopicIds(selection);
    return {
      topic_type: this.topicType(),
      topic_ids: topic_ids
    };
  },
  label: function () {
    var select_by_agent = session.get('features').reports_select_by_agent;
    var select_by_tag = session.get('features').reports_select_by_tag;

    if (select_by_agent && !select_by_tag) {
      return I18n.t('webapp.reporting.wizard.content.agent');
    } else if (!select_by_agent && select_by_tag) {
      return I18n.t('webapp.reporting.wizard.content.tag');
    } else {
      return I18n.t('webapp.reporting.wizard.content.agents_or_tags');
    }
  }
});

export default TopicSelection;

