import * as React from "react";
import * as moment from "moment";
import I18n from "i18n";
import DateFormatter from "libraries/dateFormatter";
// import { Badge } from 'newsroom-component-library';
//the commented functions in the file are to run bR without 'newsroom-component-library' dependency
import * as _ from "underscore";

interface ResultSourceHeaderProps {
  paywall?: string | null;
  sourceName: string;
  sourceReach?: number | null;
  sourceReachLabel: string | null;
  sourceFavicon: string;
  articlePublishTime: moment.Moment | Date;
  editedBadge: boolean;
}

export default class ResultSourceHeaderView extends
  React.Component<ResultSourceHeaderProps, {}> {
  render() {
    const timestamp = DateFormatter.formatTimestamp(this.props.articlePublishTime);
    const relativeDateTime = DateFormatter.formatRelativeDateTime(this.props.articlePublishTime);
    return (
      <div className="clip-detail">
        <span className="clip-detail__timestamp">
          <span data-date-auto-update=""
                data-date-format="dateTime"
                data-timestamp={timestamp}>
            { relativeDateTime }
          </span>
        </span>
        {/* {this.editedBadge()} */}
        <div className="source">
          <div className="source__agent">
            <img src={this.props.sourceFavicon} alt="" className="source__agent-icon" />
            <div className="dropdown">
              <span className="source__name">
                { this.props.sourceName }
              </span>
              { this.renderPaywallBadge() }
            </div>
          </div>
          { this.renderSourceReach() }
        </div>
      </div>
    );
  }

  private renderPaywallBadge() {
    if (_.isString(this.props.paywall)) {
      return <span className="badge--orange badge--compact">Paywall</span>;
    }
  }

  // private editedBadge () {
  //   if (this.props.editedBadge) {
  //     return (
  //       <Badge 
  //         translationKey={'timeline.editLabel'}
  //         blue={true}
  //         compact={true}
  //       />
  //       );
  //     }
  //   }

  private renderSourceReach() {
    if (this.props.sourceReach) {
      return (
        <div className="source__reach">
          <span className="source__reach-label">
            { I18n.toNumber(this.props.sourceReach, { precision: 0 }) }
          </span>
          <span className="source__reach-value"> { this.props.sourceReachLabel }</span>
        </div>
      );
    }
  }
}
