import * as React from "react";
import * as Highcharts from "highcharts";
import "highcharts_modules";
import "highcharts_theme";

export default class HighchartsReact extends React.PureComponent<any> {
  container: React.RefObject<any> = React.createRef();
  chart: any;

  componentDidMount() {
    const props = this.props;
    const highcharts = Highcharts;
    // Create chart
    this.chart = highcharts.chart(
      this.container.current,
      props.options,
      props.callback ? props.callback : undefined
    );
  }

  componentDidUpdate() {
    if (this.props.allowChartUpdate !== false) {
      this.chart.update(
        this.props.options,
        ...(this.props.updateArgs || [true, true])
      );
    }
  }

  componentWillUnmount() {
    // Destroy chart
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  render() {
    // Create container for the chart
    return React.createElement("div", { ref: this.container });
  }
}
