import Clips from 'collections/clips';
import ResearchQueryBuilder from 'models/research/researchQueryBuilder';
import _ from 'underscore';

class ResearchClipApiCollection extends Clips {
    initialize(models, options) {
        this.queryBuilder = new ResearchQueryBuilder(options);
    }
    url() {
        return this.queryBuilder.toUrl();
    }
    prependResults(opts) {
        let firstResult = this.first();
        if (firstResult) {
            this.queryBuilder.set("sinceId", firstResult.get("id"));
        } else {
            this.queryBuilder.set("since", 1230764400);
        }
        opts = _.extend({remove: false, at: 0}, opts);
        let deferred = this.fetch(opts);
        let self = this;
        deferred.done(function () { self.trigger('results:loaded'); });
        return deferred;
    }
    appendResults(opts) {
        let lastResult = this.last();
        if (lastResult) {
            this.queryBuilder.set("beforeId", lastResult.get("id"));
        }
        opts = _.extend({remove: false}, opts);
        let deferred = this.fetch(opts);
        let self = this;
        deferred.done(function () { self.trigger('results:loaded'); });
        return deferred;
    }
    parse(response) {
        let numberOfResults = response.collection.length;
        this.totalEntries = response.total_entries;
        this.moreObjectsAvailable = this.totalEntries > numberOfResults;
        return response.collection;
    }
    allItemsLoaded() {
        return !this.moreObjectsAvailable;
    }
}

export default ResearchClipApiCollection;
