import * as React from "react";
import "froala";
import "froala.lists";
import "froala.paragraph_format";
import "froala.languages.en";
import "froala.languages.de";
import "froala.languages.fr";
import EditableDetailTitleView from "../../views/components/editableDetailTitleView";
import I18n from "i18n";
import EditorConfig from "./EditorConfig";
import ClipEditFooterView, { Selections } from "./ClipEditFooterView";
import { State as GlobalClipViewState, convertToRichText } from "./GlobalClipView";

interface Props {
  clipViewState: GlobalClipViewState;
  onSave(): void;
  onCancel(): void;
  onTitleEdit(title: string): void;
  onBodyEdit(text: string): void;
  onFooterEdit(selections: Selections): void;
}

export default function GlobalClipEditView({
  clipViewState,
  onSave,
  onCancel,
  onBodyEdit,
  onFooterEdit,
  onTitleEdit
}: Props) {

  const {
    object: {
      clip: {
        title,
        full_text,
        meta: { rich_text }
      },
      entities,
      topics
    }
  } = clipViewState.data;

  let richText = "";
  if (clipViewState.editedObject) {
    richText =
      clipViewState.editedObject.clip.full_text !== undefined
        ? convertToRichText(clipViewState.editedObject.clip.full_text)
        : rich_text
        ? rich_text
        : convertToRichText(full_text);
  }
  return (
    <>
      <div className="clip-detail">
        <h2 className="heading-xlarge mvl">
          {I18n.t("webapp.agents.results.edit_result.heading")}
        </h2>
        <div className="form-item mbs">
          <EditableDetailTitleView
            originalTitle={title}
            title={title}
            displayError={false}
            onEdit={onTitleEdit}
            onEnter={() => {}}
          />
        </div>
        <EditorConfig text={richText} onEdit={onBodyEdit} />
        <div className="mtm" />
        <hr className="separator mvm" />
      </div>
      <ClipEditFooterView
        entities={entities}
        topics={topics}
        onSelectionChange={onFooterEdit}
      />
      <hr className="separator mvm" />
      <div className="col1of2">
        <div className="action-bar mbs prs">
          <div className="action-bar__action">
            <a
              data-cancel
              className="button button--block"
              onClick={() => onCancel()}>
              {I18n.t("webapp.agents.results.edit_result.cancel")}
            </a>
          </div>
        </div>
      </div>
      <div className="col1of2">
        <div className="action-bar mbs pls">
          <div className="action-bar__action">
            <a
              className={`button button--primary button--block ${
                clipViewState.isSaveReqOngoing ? "is-disabled" : ""
              }`}
              onClick={onSave}>
              {I18n.t("webapp.agents.results.edit_result.save")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
