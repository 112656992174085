import Marionette from 'backbone.marionette'
import $ from 'jquery'
import template from 'text-loader!templates/dashboard/toolbar.html'
import session from 'models/sessionInstance'

var DashboardToolbar = Marionette.ItemView.extend({
  template: template,
  tagName: 'ul',
  className: 'legacy',
  id: 'dashboard-controls',
  triggers: {
    'click .fullscreen': 'fullscreen',
    'click .edit-dashboard-widgets': 'edit-mode',
    'click .add-dashboard-widget': 'add-widget'
  },
  onRender: function () {
    $(this.el).disableSelection();
  },
  enterEditMode: function () {
    this.$(".add-dashboard-widget").show();
    this.$(".edit-dashboard-widgets").parent().addClass("clicked");
  },
  exitEditMode: function () {
    this.$(".add-dashboard-widget").hide();
    this.$(".edit-dashboard-widgets").parent().removeClass("clicked");
  },
  serializeData: function () {
    return {
      full_screen_allowed: !!session.get('features').dashboard_fullscreen
    };
  }
});

export default DashboardToolbar;

