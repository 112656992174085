import Marionette from 'backbone.marionette';
import { MultiClipDetailView, Props } from 'components/multiClipDetailView'
import * as _ from 'underscore'
import * as React from "react";
import ReactWrapper from 'views/marionetteReactWrapper';
import ReactController from 'controllers/reactController'
import { IEsApiResultContent as EsResult } from 'models/esResult'

class MultiClipDetailController extends ReactController<Props> {
  initialize(results: EsResult[]) {
    super.initialize({})
    this.results = results
  }

  defaultProps(): Props {
    let props: Props = {
      results: this.results
    }
    return props
  }

  getComponent(props: Props) {
    return (<MultiClipDetailView {...props} />)
  }
}

export default MultiClipDetailController;
