import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/readerConfigurationHolidayExclusion.html';
import select2Template from 'text-loader!templates/select2OptGroupTemplate.html';
import select2CloseOnBlur from 'libraries/select2CloseOnBlur';
import I18n from 'i18n';
import _ from 'underscore';

class ReaderConfigurationHolidayExclusionView extends Marionette.ItemView {
    initialize(options) {
        this.excludedHolidays = options.excludedHolidays;
        this.triggerSelectionChanges = true;
    }

    onRender() {
        this._prepareSelect2();
    }

    _prepareSelect2() {
        let holidayExclusionSelect = this.ui.holidayExclusionSelect;
        let select2Options = {
            containerCssClass: 'custom-select2',
            width: '100%'
        };
        let optionData = this._initializeSelect2Options();
        let optionHtml = Marionette.Renderer.render(
            select2Template, optionData
        );
        this.triggerSelectionChanges = false;
        select2CloseOnBlur(holidayExclusionSelect);
        holidayExclusionSelect.select2(select2Options);
        holidayExclusionSelect.html(optionHtml);
        holidayExclusionSelect.trigger('change');
        this.triggerSelectionChanges = true;
    }

    _initializeSelect2Options() {
        let optGroups = [
            {
                label: I18n.t('webapp.reader_admin.edit.weekday_schedule.holidays_switzerland'),
                options: this._swissHolidays()
            },
            {
                label: I18n.t('webapp.reader_admin.edit.weekday_schedule.holidays_germany'),
                options: this._germanHolidays()
            }
        ];
        return { optGroups: optGroups };
    }

    _selectionChanged() {
        if (this.triggerSelectionChanges) {
            let selection = this.ui.holidayExclusionSelect.val();
            this.trigger('holiday-exclusion:selection-changed', selection);
        }
    }

    _isRegionExcluded(region) {
        return _.contains(this.excludedHolidays, region);
    }

    _swissHolidays() {
        return [
            {
                id: 'ch',
                selected: this._isRegionExcluded('ch'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.national_switzerland')
            },
            {
                id: 'ch_ag',
                selected: this._isRegionExcluded('ch_ag'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ag')
            },
            {
                id: 'ch_ai',
                selected: this._isRegionExcluded('ch_ai'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ai')
            },
            {
                id: 'ch_ar',
                selected: this._isRegionExcluded('ch_ar'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ar')
            },
            {
                id: 'ch_be',
                selected: this._isRegionExcluded('ch_be'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.be')
            },
            {
                id: 'ch_bl',
                selected: this._isRegionExcluded('ch_bl'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.bl')
            },
            {
                id: 'ch_bs',
                selected: this._isRegionExcluded('ch_bs'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.bs')
            },
            {
                id: 'ch_fr',
                selected: this._isRegionExcluded('ch_fr'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.fr')
            },
            {
                id: 'ch_ge',
                selected: this._isRegionExcluded('ch_ge'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ge')
            },
            {
                id: 'ch_gl',
                selected: this._isRegionExcluded('ch_gl'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.gl')
            },
            {
                id: 'ch_gr',
                selected: this._isRegionExcluded('ch_gr'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.gr')
            },
            {
                id: 'ch_ju',
                selected: this._isRegionExcluded('ch_ju'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ju')
            },
            {
                id: 'ch_lu',
                selected: this._isRegionExcluded('ch_lu'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.lu')
            },
            {
                id: 'ch_ne',
                selected: this._isRegionExcluded('ch_ne'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ne')
            },
            {
                id: 'ch_nw',
                selected: this._isRegionExcluded('ch_nw'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.nw')
            },
            {
                id: 'ch_ow',
                selected: this._isRegionExcluded('ch_ow'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ow')
            },
            {
                id: 'ch_sg',
                selected: this._isRegionExcluded('ch_sg'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.sg')
            },
            {
                id: 'ch_sh',
                selected: this._isRegionExcluded('ch_sh'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.sh')
            },
            {
                id: 'ch_so',
                selected: this._isRegionExcluded('ch_so'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.so')
            },
            {
                id: 'ch_sz',
                selected: this._isRegionExcluded('ch_sz'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.sz')
            },
            {
                id: 'ch_tg',
                selected: this._isRegionExcluded('ch_tg'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.tg')
            },
            {
                id: 'ch_ti',
                selected: this._isRegionExcluded('ch_ti'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ti')
            },
            {
                id: 'ch_ur',
                selected: this._isRegionExcluded('ch_ur'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ur')
            },
            {
                id: 'ch_vd',
                selected: this._isRegionExcluded('ch_vd'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.vd')
            },
            {
                id: 'ch_vs',
                selected: this._isRegionExcluded('ch_vs'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.vs')
            },
            {
                id: 'ch_zg',
                selected: this._isRegionExcluded('ch_zg'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.zg')
            },
            {
                id: 'ch_zh',
                selected: this._isRegionExcluded('ch_zh'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.zh')
            }
        ];
    }

    _germanHolidays() {
        return [
            {
                id: 'de',
                selected: this._isRegionExcluded('de'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.national_germany')
            },
            {
                id: 'de_bb',
                selected: this._isRegionExcluded('de_bb'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.bb')
            },
            {
                id: 'de_be',
                selected: this._isRegionExcluded('de_be'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.be')
            },
            {
                id: 'de_bw',
                selected: this._isRegionExcluded('de_bw'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.bw')
            },
            {
                id: 'de_by',
                selected: this._isRegionExcluded('de_by'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.by')
            },
            {
                id: 'de_hb',
                selected: this._isRegionExcluded('de_hb'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.hb')
            },
            {
                id: 'de_he',
                selected: this._isRegionExcluded('de_he'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.he')
            },
            {
                id: 'de_hh',
                selected: this._isRegionExcluded('de_hh'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.hh')
            },
            {
                id: 'de_mv',
                selected: this._isRegionExcluded('de_mv'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.mv')
            },
            {
                id: 'de_ni',
                selected: this._isRegionExcluded('de_ni'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.ni')
            },
            {
                id: 'de_nw',
                selected: this._isRegionExcluded('de_nw'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.nw')
            },
            {
                id: 'de_rp',
                selected: this._isRegionExcluded('de_rp'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.rp')
            },
            {
                id: 'de_sh',
                selected: this._isRegionExcluded('de_sh'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.sh')
            },
            {
                id: 'de_sl',
                selected: this._isRegionExcluded('de_sl'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.sl')
            },
            {
                id: 'de_sn',
                selected: this._isRegionExcluded('de_sn'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.sn')
            },
            {
                id: 'de_st',
                selected: this._isRegionExcluded('de_st'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.st')
            },
            {
                id: 'de_th',
                selected: this._isRegionExcluded('de_th'),
                name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.th')
            }
        ];
    }
}
ReaderConfigurationHolidayExclusionView.prototype.template = template;
ReaderConfigurationHolidayExclusionView.prototype.ui = {
    holidayExclusionSelect: '[data-holiday-exclusion-select]'
};
ReaderConfigurationHolidayExclusionView.prototype.events = {
    'change select': '_selectionChanged'
};

export default ReaderConfigurationHolidayExclusionView;
