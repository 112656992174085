import Config from 'libraries/configuration';
import Backbone from 'backbone';
import moment from 'moment';
import $ from 'jquery';
import _ from 'underscore';

class AgentResultsQueryBuilder extends Backbone.Model {
    initialize(options) {
        this.set("agentIds", options.agentIds.join(';'));
        if (options.updatedAtAfter) {
            this.set("updatedAtAfter", options.updatedAtAfter);
        }
        if (options.createdAtDate) {
            let dayBeginning = moment(options.createdAtDate).startOf('day');
            this.set('createdAtAfter', dayBeginning.unix());
            this.set('createdAtBefore', dayBeginning.add(1, 'day').unix());
        }

        this.set("limit", options.limit || 25);
        this.set("hidden", options.hidden || false);

        if (options.filter) {
            // handle null value in filter.ratings
            if (options.filter.ratings) {
                let lst = _.filter(options.filter.ratings, function (el) {
                    return el !== null;
                });
                // we removed a null
                if (lst.length < options.filter.ratings.length) {
                    lst.push('null');
                }
                this.set('ratings', lst);
            }

            if (options.filter.mediaTypes) {
                this.set('mediaTypes', options.filter.mediaTypes);
            }

            if (options.filter.tagIds) {
                this.set('tagIds', options.filter.tagIds);
            }

            if (options.filter.sourceIds) {
                this.set('sourceIds', options.filter.sourceIds);
            }

            if (options.filter.topicIds) {
                this.set('topicIds', options.filter.topicIds);
            }

            if (options.filter.query) {
                this.set('query', options.filter.query);
            }

            if (options.filter.resultImportance) {
                this.set('resultImportance', options.filter.resultImportance);
            }
        }

        this.baseUrl = Config.apiUrl + "agents/" + this.get("agentIds") + "/agent_results";
    }
    toUrl() {
        let beforeId = this.get("beforeId");
        let sinceId  = this.get("sinceId");
        let updatedSinceId = this.get('updatedSinceId');
        let parameters = "?limit=" + this.get("limit") + "&hidden=" + this.get("hidden");

        if (this.get('createdAtAfter')) {
            parameters += "&since=" + this.get("createdAtAfter");
        }

        if (this.get('updatedAtAfter')) {
            parameters += "&updated_since=" + this.get("updatedAtAfter");
        }

        if (sinceId && !this.get('updatedAtAfter')) {
            parameters += "&since_id=" + sinceId;
        }

        if (updatedSinceId) {
            parameters += "&updated_since_id=" + updatedSinceId;
        }

        if (this.get('createdAtBefore')) {
            parameters += "&before=" + this.get("createdAtBefore");
        }

        if (beforeId) {
            parameters += "&before_id=" + beforeId;
        }

        if (this.get('ratings')) {
            parameters += "&" +
                decodeURIComponent($.param({ratings: this.get('ratings')}));
        }

        if (this.get('mediaTypes')) {
            parameters += "&" +
                decodeURIComponent($.param({media_types: this.get('mediaTypes')}));
        }

        if (this.get('tagIds')) {
            parameters += "&" +
                decodeURIComponent($.param({tag_ids: this.get('tagIds')}));
        }

        if (this.get('sourceIds')) {
            parameters += "&" +
                decodeURIComponent($.param({source_ids: this.get('sourceIds')}));
        }

        if (this.get('topicIds')) {
            parameters += "&" +
                decodeURIComponent($.param({topic_ids: this.get('topicIds')}));
        }

        if (this.get('resultImportance')) {
            parameters += "&" +
                decodeURIComponent(
                    $.param({result_importance: this.get('resultImportance')}));
        }

        if (this.get('query')) {
            parameters += "&" +
                $.param({query: this.get('query')});
        }

        return this.baseUrl + parameters;
    }
}

export default AgentResultsQueryBuilder;
