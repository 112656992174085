import Marionette from 'backbone.marionette'
import when from 'when'
import ClipApiCollection from 'collections/researchClipApiCollection'
import ResearchResultListView from 'views/research/researchResultListView'

var ResearchResultListController = Marionette.Controller.extend({
  initialize: function (options) {
    this.listItemViews = {};
    this.appendTracker = options.appendTracker;

    this.clips = new ClipApiCollection([], {
      //search options
      topicBuildParameters: options.topicBuildParameters,
      sourceCategoryIds: options.sourceCategoryIds,
      date: options.createdAtDate
    });

    this.view = new ResearchResultListView({
      results: this.clips
    });

    this._registerListeners();

    this.view.render();

    var self = this;
    var initialResultsShown = function () {
      self.trigger('initial-results-shown');
    };
    if (options.results) {
      this.clips.add(options.results);
      this.clips.moreObjectsAvailable = !options.noMoreResults;
      this._appendItemsFinished();
      when().then(initialResultsShown);
    } else {
      this.appendItems().then(initialResultsShown);
    }
  },
  markAsSelected: function (result) {
    var view = this.listItemViews[result.id];
    if (view) { view.markAsSelected(); }
  },
  markAsDeselected: function (result) {
    var view = this.listItemViews[result.id];
    if (view) { view.markAsDeselected(); }
  },
  shownItems: function () {
    return this._resultsArray();
  },
  getDistances: function (result) {
    var view = this.listItemViews[result.id];
    return view.getDistanceFromTopAndBottom();
  },
  canLoadMoreResults: function () {
    return !this.clips.allItemsLoaded();
  },
  _resultsArray: function () {
    return this.clips.slice(0, this.clips.length);
  },
  appendItems: function () {
    var self = this;
    this.appendTracker.recordAppend();
    this.view.showPreloader();
    return when(this.clips.appendResults()).then(function () {
      self._appendItemsFinished();
    });
  },
  _handleScrolledToEndOfList: function () {
    if (this.appendTracker.doAutomaticAppendNow()) {
      this.appendItems();
    }
  },
  _appendItemsFinished: function () {
    this._notifyViewFetchFinished();
    this.trigger('newItemsLoaded');
    this._handleNoResults();
    this._handleAllItemsLoaded();
  },
  _notifyViewFetchFinished: function () {
    this.view.fetchFinished();
  },
  _handleNoResults: function () {
    if (this.clips.isEmpty()) {
      this.view.showNoResultsMessage();
    }
  },
  _handleAllItemsLoaded: function () {
    if (this.clips.allItemsLoaded()) {
      var resultSize = this.clips.size();
      this.view.allItemsLoaded();
      this.trigger('allItemsLoaded', resultSize);
    }
  },
  _registerListeners: function () {
    this.listenTo(
      this.view, 'itemview:result:clicked', (itemView) => {
        this.trigger('result:clicked', itemView.model);
      }
    );
    this.listenTo(
      this.view,
      "itemview:result:ctrlClicked", (itemView) => {
        this.trigger("result:ctrlClicked", itemView.model);
      }
    );
    this.listenTo(
      this.view,
      "itemview:result:shiftClicked", (itemView) => {
        this.trigger("result:shiftClicked", itemView.model);
      }
    );
    this.listenTo(this.clips, 'results:loaded', this._appendItemsFinished);
    this.listenTo(this.view, "clicked-show-more", this.appendItems);
    this.listenTo(this.view, "scrolled-to-end-of-list",
                  this._handleScrolledToEndOfList);
    this.listenTo(this.view, 'itemview:render', (itemView) => {
      this._registerItemView(itemView);
      this.trigger('result:add', itemView.model);
    });
  },
  _registerItemView: function (itemView) {
    var model = itemView.model;
    this.listItemViews[model.id] = itemView;
  }
});

export default ResearchResultListController;

