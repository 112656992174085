import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/newsroomSummaryTimelineItem.html';
import Summary from 'models/newsroom/summary';
import _ from 'underscore';
import readerConfigurations from 'collections/newsroom/configurationsInstance';
import handleTooltips from 'models/handleTooltips';
import DateFormatter from 'libraries/dateFormatter';
import I18n from 'i18n';
import usersInstance from 'collections/usersInstance';
import moment from 'moment';

class NewsroomSummaryTimelineItemView extends Marionette.ItemView {
    initialize() {
        this.archiveDateCollapsed = true;
        this.summaryModel = new Summary({
            readerConfigurationId: this.options.readerConfigurationId,
            archiveDateId: this.model.get('id'),
        });
        this.listenTo(this.summaryModel, 'sync', this.reload);
        this.configuration =
            readerConfigurations.findWhere({
                id: this.options.readerConfigurationId
            });
        _.bindAll(this, 'hidePreloader');
        handleTooltips(this);
    }

    onRender() {
        this.collapseArchiveDate();
        this.initBell();
    }

    initBell() {
        let notifications = this.model.get('archive_date_notifications');
        let id = this.model.get('id');
        let latestArchiveDateId = this.model.collection.latestArchiveDateId();
        let isLatestArchiveDate = id === latestArchiveDateId;
        let isNotificationAlreadySent = !_.isEmpty(notifications);
        this._bellDisabled = true;

        if (this.model.isUpcomingIssue()) {
            this.ui.bell.hide();
        } else {
            if (isLatestArchiveDate && isNotificationAlreadySent) {
                this._bellDisabled = false;
                this.ui.bell.addClass('send--notification');
                this.showTooltipWithNotificationAt();
            } else if (isLatestArchiveDate && !isNotificationAlreadySent) {
                this._bellDisabled = false;
                this.ui.bell.addClass('send--notification');
                this.showTooltipNotSentYet();
            } else if (isNotificationAlreadySent) {
                this.ui.bell.addClass('notification--disabled--sent');
                this.showTooltipWithNotificationAt();
            } else {
                this.ui.bell.addClass('notification--disabled--not--sent');
                this.showTooltipNotSent();
            }
        }
    }

    disableBell() {
        this._bellDisabled = true;
        this.ui.bell.removeClass('send--notification');
        this.ui.bell.addClass('notification--disabled--sent');
    }

    showAddSummaryButton() {
        this.ui.title.hide();
        this.ui.addButton.removeClass('hidden');
        this.ui.archiveDateBody.show();
        this.archiveDateCollapsed = false;
    }

    showSummaryTitle() {
        this.ui.addButton.addClass('hidden');
        this.ui.title.html(this.summaryModel.getDefaultTitle());
        this.ui.title.show();
        this.ui.archiveDateBody.show();
        this.archiveDateCollapsed = false;
    }

    reload() {
        if (this.summaryModel.isPresent()) {
            this.showSummaryTitle();
        } else {
            this.showAddSummaryButton();
        }
    }

    showPreloader() {
        this.ui.addButton.addClass('hidden');
        this.ui.title.hide();
        this.ui.preloader.show();
        this.ui.archiveDateBody.show();
    }

    hidePreloader() {
        this.ui.preloader.hide();
    }

    collapseArchiveDate() {
        this.ui.archiveDateBody.hide();
        this.ui.title.hide();
        this.ui.addButton.addClass('hidden');
        this.ui.preloader.hide();
        this.archiveDateCollapsed = true;
    }

    highlight() {
        this.ui.archiveDateBody.addClass('is-active');
    }

    clearHighlight() {
        this.ui.archiveDateBody.removeClass('is-active');
        this.ui.addButton.removeClass('is-disabled');
    }

    showTooltipWithNotificationAt() {
        this._showTooltip(this._tooltipNotificationContent());
    }

    _tooltipNotificationContent() {
        let notifications = this._sortedNotifications();
        let tool_tip = "";
        _.each(notifications, (note) => {
            let date = DateFormatter.formatDateTime(note['sent_at']);
            let manual = note['automatic'] === false;
            if ( manual && note['user_id'] && usersInstance.get(note['user_id'])) {
                let key = 'webapp.reader_admin.notifications.tooltip_sent.manual';
                let name = usersInstance.get(note['user_id']).get('full_name');
                tool_tip += I18n.t(key, {date: date, name: name});
            } else {
                let key = 'webapp.reader_admin.notifications.tooltip_sent.automatic';
                tool_tip += I18n.t(key, {date: date});
            }
        });
        return tool_tip
    }

    _sortedNotifications() {
        return _.sortBy(this.model.get('archive_date_notifications'), (note) => {
            return -moment(note['sent_at'])
        })
    }

    showTooltipNotSent() {
        let key = 'webapp.reader_admin.notifications.tooltip_not_sent';
        this._showTooltip(I18n.t(key));
    }

    showTooltipNotSentYet() {
        let key = 'webapp.reader_admin.notifications.tooltip_not_sent_yet';
        this._showTooltip(I18n.t(key));
    }

    updateTooltipWithNotification() {
        if (this.ui.bellContent.html()) {
            let notification = this._tooltipNotificationContent()
            this.ui.bellContent.html("<span class='tooltip__text'>" + notification + "</span>");
        } else {
            this.ui.showTooltipWithNotificationAt();
        }
    }

    _showTooltip(notification) {
        this.ui.bellTooltip.addClass('tooltip');
        this.ui.bellToggle.addClass('tooltip__toggle tooltip__toggle--html');
        this.ui.bellContent.addClass('tooltip__content tooltip__content--html')
                           .append("<span class='tooltip__text'>" + notification + "</span>");
        this.trigger('update-tooltips');
    }

    _handleArchiveDateClick() {
        this.trigger('toggleArchiveDate');
    }

    _handleAddSummaryButtonClick() {
        this.trigger('addSummary');
        this.trigger('clearAllHighlights');
        this.ui.addButton.addClass('is-disabled');
        this.highlight();
    }

    _handleSummaryTitleClick() {
        this.trigger('editSummary');
        this.trigger('clearAllHighlights');
        this.highlight();
    }

    _handleBellClick() {
        if (!this._bellDisabled) {
            this.trigger('bellClicked', this.model.get('id'));
        }
        return false;
    }

    serializeData() {
        return {
            archiveDate: this.model.formattedDate()
        };
    }
}

NewsroomSummaryTimelineItemView.prototype.template = template;

NewsroomSummaryTimelineItemView.prototype.ui = {
    preloader: '[data-preloader]',
    addButton: '[data-add-summary-button]',
    archiveDate: '[data-archive-date]',
    archiveDateBody: '[data-archive-date-body]',
    title: '[data-title]',
    bell: '[data-bell]',
    bellTooltip: '[data-bell-tooltip]',
    bellToggle: '[data-bell-toggle]',
    bellContent: '[data-bell-content]'
};

NewsroomSummaryTimelineItemView.prototype.events = {
    'click @ui.archiveDate': '_handleArchiveDateClick',
    'click @ui.addButton': '_handleAddSummaryButtonClick',
    'click @ui.title': '_handleSummaryTitleClick',
    'click @ui.bell': '_handleBellClick',
};

export default NewsroomSummaryTimelineItemView;
