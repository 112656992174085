import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/tickerWidget.html'
import session from 'models/sessionInstance'
import _ from 'underscore'
import $ from 'jquery'
import AgentTagWidgetView from 'views/dashboard/agentTagWidgetView'

WidgetView.TickerWidget = AgentTagWidgetView.extend({
  template: template,
  prepareWidgetBody: function () {
    var clips = this.model.get('clips');
    if (clips.length === 0) {
      $(this.el).find('.ticker-widget').addClass('no-clip');
    } else {
      $(this.el).find('.ticker-widget').removeClass('no-clip');
      this.initializeTicker();
    }
  },
  initializeTicker: function () {
    var index;
    var clips = this.model.get('clips');
    var indicators = $(this.el).find('.indicator li');
    var clipElements = $(this.el).find('.hidden div');
    var headlineElement = $(this.el).find('.headline');
    var sourceElement = $(this.el).find('.ticker__source');
    var timeElement = $(this.el).find('.time');
    var clipElement = $(this.el).find('.clip-data');
    var self = this;

    for (var i = 0; i < indicators.length; i++) {
      if (clips.length > i) {
        $(indicators[i]).addClass('clip');
      }
    }

    var showClip
    var nextClip = function () {
      index++;
      if (index >= clips.length) {
        index = 0;
      }
      showClip();
    };

    var hideClip = function () {
      clipElement.fadeOut(self.fadingTime, nextClip);
    };

    var wait = function () {
      window.setTimeout(hideClip, self.timeToWait);
    };

    showClip = function () {
      var headline = $(clipElements[index * 3]).text();
      var source = $(clipElements[index * 3 + 1]).text();
      var time = $(clipElements[index * 3 + 2]).text();
      indicators.removeClass('current');
      $(indicators[index]).addClass('current');
      headlineElement.text(headline);
      sourceElement.text(source);
      timeElement.text(time);
      clipElement.fadeIn(self.fadingTime, wait);
    };

    var noop = function () { return; };
    var stop = function () {
      showClip = noop;
      wait = noop;
      hideClip = noop;
      nextClip = noop;
    };

    if (this.stopAnimation) {
      this.stopAnimation();
    }
    this.stopAnimation = stop;
    index = 0;
    showClip();
  },
  timeToWait: 10000,
  fadingTime: 1000,
  prepareSerializedData: function () {
    var theme = session.get('dashboard_theme') || 'default';
    var maxLengths = this.maxLengths()[theme];
    var clips = this.model.get('clips');
    _.each(clips, (clip) => {
      clip.title = this._truncateLabel(clip.title, maxLengths.title);
      clip.source = this._truncateLabel(clip.source, maxLengths.source);
    }, this);

    return { clipsData: clips };
  },
  maxLengths: function () {
    return {
      'default': {
        title: this.model.get('configuration').truncation_characters_title,
        source: this.model.get('configuration').truncation_characters_source
      },
      'swiss': {title: 50, source: 80}
    };
  }
});


export default WidgetView.TickerWidget;

