import Marionette from 'backbone.marionette';
import ReaderTokenUrlView from 'views/newsroom/readerTokenUrlView';
import template from 'text-loader!templates/newsroom/readerEditView.html';
import moment from 'moment';
import _ from 'underscore';
import I18n from 'i18n';

class ReaderEditView extends Marionette.Layout {
    initialize() {
        this.readerTokenUrlView = new ReaderTokenUrlView({model: this.model});
    }

    serializeData() {
        let config         = this.model;
        let serializedData = config.toJSON();
        let nextRefresh    = config.get('next_token_refresh');
        let nextUpdateDate;
        let nextToken      = this._getNextTokenText();
        let nextUpdateTime;

        if (nextRefresh) {
            nextUpdateDate = moment.unix(nextRefresh).format('DD.MM.YYYY');
            nextUpdateTime = moment.unix(nextRefresh).format('HH:mm');
        }

        return _.extend(
            serializedData,
            {
                nextUpdateDate: nextUpdateDate,
                nextToken: nextToken,
                nextUpdateTime: nextUpdateTime
            }
        );
    }

    _getNextTokenText() {
        let nextToken = this._formattedNextTokenDate();
        let buttonText = I18n.t('webapp.reader_admin.edit.next_token', { time: nextToken });
        return buttonText;
    }

    _formattedNextTokenDate() {
        let nextTokenRefresh = this.model.get(
            'next_token_refresh'
        );
        let nextTokenDate = moment.unix(nextTokenRefresh).format('dd L LT');
        return nextTokenDate;
    }

    _showReaderTokenUrl(view) {
        this.readerTokenUrl.show(view);
    }

    onRender() {
        this._showReaderTokenUrl(this.readerTokenUrlView);
    }

    _registerListeners() {
        this.listenTo(this.model, 'change:next_token_refresh', this._nextTokenDateHasChanged);
    }

    _nextTokenDateHasChanged() {
        let nextToken = this._getNextTokenText();
        this.ui.nextTokenDateSpan.html(nextToken);
    }

    showSendTokenModal(view) {
        this.sendTokenModalContainer.show(view);
    }

}
ReaderEditView.prototype.regions = {
    sendTokenModalContainer: '[data-send-token-modal-container]',
    readerTokenUrl: '[data-reader-token-url]'
};

ReaderEditView.prototype.template = template;
ReaderEditView.prototype.ui = {
    sendToken: '[data-send-token-btn]',
    nextTokenDateSpan: '[data-next-token-date]'
};

ReaderEditView.prototype.triggers = {
    'click @ui.sendToken': 'sendTokenModal'
};

export default ReaderEditView;
