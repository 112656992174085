import Deferred from 'libraries/deferred'
import $ from 'jquery'

export default {
  blurOnEscape: function (keyUpEvent) {
    if (keyUpEvent.keyCode === 27) {
      Deferred.async(function () {
        $(':focus').blur();
      });
    }
  }
};

