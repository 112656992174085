import Marionette from 'backbone.marionette';
import _ from 'underscore';
import template from 'text-loader!templates/agents/agentResultDetailView.html';
import I18n from 'i18n';
import highlight from 'libraries/highlight';
import highlightRichText from 'libraries/highlightRichText';
import convertNewlinesToParagraphs from 'libraries/convertNewlinesToParagraphs';
import $ from 'jquery';
import blueimp from 'blueimp';
import truncate from 'libraries/truncate'
import session from "models/sessionInstance";
import browserCheck from 'libraries/browserCheck';

class AgentResultDetailView extends Marionette.Layout {
    showActions(view) {
        this.actions.show(view);
    }

    showHeaderActions(view) {
        this.headerActions.show(view);
    }

    showEditLabel(view) {
        this.editLabel.show(view);
    }

    showAgentResultDetailAttachments(view) {
        this.attachments.show(view)
    }

    showAgentResultDetailClipLinks(view) {
        this.clipLinks.show(view)
    }

    showAgentResultDetailClipTopics(view) {
        this.clipTopics.show(view)
    }

    showAgentResultDetailFooter(view) {
        this.detailFooter.show(view);
    }

    showEditFormView(view) {
        this._removeGallery();
        this.ui.agentResultDetailContainer.hide();
        this.editForm.show(view);
        this.ui.editFormContainer.show();
    }

    showAgentResultDetailView() {
        this.ui.editFormContainer.hide();
        this.ui.agentResultDetailContainer.show();
    }

    onClose() {
        this._removeGallery();
    }

    galleryId() {
        return 'blueimp-gallery-carousel-agents';
    }

    _removeGallery() {
        if (this.gallery) {
            this.gallery.destroyEventListeners();
            this.gallery.close();
            this.gallery = undefined;
        }
    }

    serializeData() {
        let match;
        let reach;
        let agentResult       = this.model;
        let additionalSources = agentResult.additionalSources();
        let primarySource     = agentResult.primarySource();
        let url               = primarySource.clip_url;
        let languageDetected  = this._languageDetected();
        let additionalSourcesCount = {
            count: additionalSources.length
        };

        if (primarySource && primarySource.source_metadata) {
            reach = primarySource.source_metadata.reach;
        }

        let additionalSourcesLabel = I18n.t('webapp.agents.results.result_details.sources',
                                            additionalSourcesCount)
        if (agentResult.isRtvClip()) {
          additionalSourcesLabel = I18n.t('webapp.agents.results.result_details.broadcasts',
                                          additionalSourcesCount)
        }

        let clipUrl = primarySource.clip_url

        let highlightedText = this._convertAndHighlightArticleText();
        const limit = session.getTextLengthLimit();
        let articleText = truncate(highlightedText, limit);
        let showFullArticleLink = false;
        if (browserCheck.isIE11() && highlightedText.length > limit && clipUrl) {
          showFullArticleLink = true
        }
        if (!clipUrl && this.documentAttachmentByName('layout_branded.pdf')) {
            clipUrl = this.documentAttachmentByName('layout_branded.pdf').url;
        }
        if (!clipUrl && this.documentAttachments().length > 0) {
            clipUrl = this.documentAttachments()[0].url
        }

        let serializedData = {
            showFullArticleLink: showFullArticleLink,
            title:          agentResult.get('title'),
            youtubeContentRemoved: agentResult.youtubeContentRemoved(),
            teaser:         articleText,
            reach:          reach,
            primary_source: primarySource,
            published_at:   agentResult.timelineTime(),
            query_terms:    agentResult.get('query_terms'),
            clip_url:       clipUrl,
            mentions:       agentResult.get('mentions'),
            language_detected:       languageDetected,
            has_additional_sources:  additionalSources.length > 0,
            additional_source_count: additionalSources.length,
            additional_sources:      additionalSources,
            additional_sources_label: additionalSourcesLabel,
            isMMO: agentResult.isMMOClip(),
            isRtv: agentResult.isRtvClip(),
            galleryId: this.galleryId(),
            paywall: agentResult.isPaywalled()
        };

        if (agentResult.agent) {
            serializedData.color = agentResult.agent.get('colors')['default'];
            serializedData.agent_name = agentResult.agent.get('name');
            serializedData.hideAgentName = this.options.hideAgentName;
        }

        if (agentResult.isYoutubeClip()) {
            match = url.match(/[?&]v=([\w-]+)/);
            if (match) {
                serializedData.hasYoutubeVideo = true;
                serializedData.videoId = match[1];
            }
        }

        if (agentResult.avProcessingDone()) {
            match = url.match(/\/(\d+)(\/\w+)?\/?([#?].*)?$/);
            if (match) {
                serializedData.hasVimeoVideo = true;
                serializedData.videoId = match[1];
            }
        }

        if (agentResult.isInstagramClip()) {
            let metadata = agentResult.get('clip_metadata');

            if (!_.isUndefined(metadata.video_url)) {
                serializedData.hasInstagramVideo = true;
                serializedData.imageUrl = metadata.image_url;
                serializedData.videoUrl = metadata.video_url;
            }
        }

        if (this.showGallery()) {
            serializedData.showGallery = true;
        } else if (this.instagramImageUrl()) {
            serializedData.showInstagramImage = true;
            serializedData.imageUrl = this.instagramImageUrl();
        }

        return serializedData;
    }

    showGallery() {
        return !(_.isEmpty(this.images()));
    }

    // undefined or string
    instagramImageUrl() {
        if (this.model.isInstagramClip()) {
            let metadata = this.model.get('clip_metadata');
            if (!_.isUndefined(metadata.image_url) &&
                _.isUndefined(metadata.video_url)) {
                return metadata.image_url;
            }
        }
    }

    onRender() {
        if (this.showGallery()) {
            setTimeout(this.initImageGallery.bind(this));
        }
    }

    images() {
        const attachments = _.filter(this.model.clipAttachments(), (attachment) => {
            return attachment.file_type === 'image';
        });
        if (attachments.length > 0) {
            return attachments;
        } else if (this.instagramImageUrl()) {
            return [
                {
                    caption: undefined,
                    url: this.instagramImageUrl(),
                    thumbnail: this.instagramImageUrl()
                }
            ];
        }
    }

    documentAttachments() {
        return _.filter(this.model.clipAttachments(), (attachment) => {
            return attachment.file_type == 'document'
        })
    }

    documentAttachmentByName(name) {
        return _.find(this.documentAttachments(), (attachment) => {
            return attachment.file_name == name
        })
    }

    initImageGallery() {
        const data = this.images().map(function (img) {
            let caption, title;

            if (img.caption) {
              caption = highlight(img.caption);
              title = caption.replace(/<\/?[^>]+(>|$)/g, '');
            }

            return {
                title: title,
                caption: caption,
                href: img.url,
                thumbnail: img.url
            };
        });


        let firstHighlightedCaption = _.find(data, function (d) {
            return /clip-detail__highlight/.test(d.caption);
        });
        let startIndex = 0;
        if (firstHighlightedCaption) {
            startIndex = _.indexOf(data, firstHighlightedCaption);
        }

        const multipleImages = this.images().length > 1
        const options = {
            container: `#${this.galleryId()}`,
            index: startIndex,
            carousel: true,
            startSlideshow: false,
            enableKeyboardNavigation: multipleImages
        };

        if (multipleImages) {
            $(`#${this.galleryId()}`).removeClass('single');
        } else {
          options.emulateTouchEvents = false;
        }

        this.gallery = blueimp(data, options);
        this.gallery.onclick = () => { this.gallery.next(); };
    }

    /**
     *  Based on the view model, converts the new lines to paragarphs and
     *  highlights the article text content.
     *
     *  @returns {String} Highlighted article text.
     */
    _convertAndHighlightArticleText() {
      if (this.model.richText()) {
        return this.decorateRichText(
          highlightRichText(this.model.richText())
        );
      } else if (this.model.articleText()) {
          let articleText = this.model.articleText();
          let convertedText = convertNewlinesToParagraphs(
              articleText, this._languageDetected()
          );
          return highlight(convertedText);
      } else {
        return "";
      }
    }

    /**
     * Add styleguide classes to rich text tags
     */
    decorateRichText(richText) {
        const assignments = [
            ['h1', 'heading-humongous'],
            ['h2', 'heading-xlarge'],
            ['h3', 'heading-large'],
            ['h4', 'heading-medium'],
            ['ul', 'list-unordered'],
            ['ul > li', 'list-unordered__item'],
            ['ol', 'list-ordered'],
            ['ol > li', 'list-ordered__item']
        ];

        let $richTextHTML = $('<div>' + richText + '</div>');

        _.each(assignments, function (assignment) {
            $richTextHTML.find(assignment[0]).addClass(assignment[1]);
        });

        let links = $richTextHTML.find("a"),
            hostname =  window.location.hostname;

        links.each(function(_idx, el) {
            const href = $(el).attr('href');
            let linkHost;
            if(href) {
                try {
                    linkHost = new URL(href).host;
                } catch(_) { /* invalid url */ }
                if(linkHost !== hostname) {
                    $(el).attr({target: "_blank", rel: "noopener"});
                }
            }
        })

        return $richTextHTML.html();
    }


    _languageDetected() {
        let languages = this.model.get('languages');

        if (!_.isEmpty(languages)) {
            return _.first(languages);
        } else {
            return false;
        }
    }

    _conditionalRender() {
        if (this._triggerRenderFromModelUpdate()) {
            this.render();
        }
    }

    _triggerRenderFromModelUpdate() {
        let result = this.model;
        return result.hasChanged('title') || result.hasChanged('teaser');
    }
}

AgentResultDetailView.prototype.regions = {
    headerActions: '[data-header-actions]',
    actions: '[data-agent-result-actions]',
    attachments: '[data-agent-result-detail-attachments]',
    clipLinks: '[data-agent-result-clip-links]',
    clipTopics: '[data-agent-result-clip-topics]',
    detailFooter: '[data-agent-result-detail-footer]',
    editForm: '[data-edit-form]',
    editLabel: '[data-edit-label]'
};

AgentResultDetailView.prototype.modelEvents = {
    change: '_conditionalRender'
};

AgentResultDetailView.prototype.ui = {
    agentResultDetailContainer: '[data-detail-view]',
    editFormContainer: '[data-edit-form]'
};

AgentResultDetailView.prototype.template = template;

export default AgentResultDetailView;
