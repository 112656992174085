import session from "models/sessionInstance";
import users from "collections/usersInstance";
import Agent from "models/agent";
import RestorableModel from "libraries/restorableModel";
import * as _ from "underscore";

const hasAdministrativeRightsToAgent = (agent: Agent) => {
  if (session.hasFeature("new_search_agent_management")) {
    return session.isGroupAdmin();
  } else {
    return agent.isOwnAgent();
  }
};

export const AgentsPermissions = {
  canChangeAgentVisibility: (agent: Agent) => {
    return hasAdministrativeRightsToAgent(agent);
  },

  canChangeAgentTimeframe: (agent: Agent) => {
    if (session.hasFeature("new_search_agent_management")) {
      return session.isGroupAdmin() || agent.isOwnAgent();
    } else {
      return false;
    }
  },

  canPrivatizeAgent: (agent: Agent) => {
    return hasAdministrativeRightsToAgent(agent);
  },

  canSubscribeAgent: (agent: Agent) => {
    if (agent.get('subscribed')) { return false; }
    if (agent.get('shared')) {
      return true;
    } else {
      return agent.isOwnAgent();
    }
  },

  canUpdateSharedValue: (model: Agent | RestorableModel) => {
    if (session.hasFeature("new_search_agent_management")) {
      let value = model.get("shared");
      let possibleValues = model.get("possible_values_for_shared_attribute");
      if (possibleValues && possibleValues.length > 1) {
        return true;
      } else if (possibleValues && possibleValues.length === 1) {
        return possibleValues[0] !== value;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
};
