/* eslint no-invalid-this: "off" */
import Marionette from 'backbone.marionette'
import _ from 'underscore'
import template from 'text-loader!templates/navigation.html'

var clickCallbackFor = function (module) {
  return function () {
    this.trigger('module:clicked', module);
  };
};

var NavigationView = Marionette.ItemView.extend({
  template: template,
  ui: {
    dashboard: '[data-module=dashboard]',
    agents: '[data-module=agents]',
    newsroom: '[data-module=newsroom]',
    reports: '[data-module=reports]',
    settings: '[data-module=settings]'
  },
  events: {
    'click [data-module=dashboard]': clickCallbackFor('dashboard'),
    'click [data-module=agents]': clickCallbackFor('agents'),
    'click [data-module=newsroom]': clickCallbackFor('newsroom'),
    'click [data-module=reports]': clickCallbackFor('reports'),
    'click [data-module=settings]': clickCallbackFor('settings')
  },
  initialize: function () {
    _.bindAll(this,
              'showModule',
              'hideModule',
              'enableModule',
              'disableModule',
              'activateModule',
              'deactivateModule');
  },
  showModule: function (module) {
    this._elForModule(module).show();
  },
  hideModule: function (module) {
    this._elForModule(module).hide();
  },
  enableModule: function (module) {
    this._elForModule(module).removeClass('is-disabled');
  },
  disableModule: function (module) {
    this._elForModule(module).addClass('is-disabled');
  },
  activateModule: function (module) {
    this._elForModule(module).addClass('is-active');
  },
  deactivateModule: function (module) {
    this._elForModule(module).removeClass('is-active');
  },
  _elForModule: function (module) {
    var el = this.ui[module];
    if (_.isUndefined(el)) {
      el = this.$(); // null object that supports all jQuery methods
    }
    return el;
  }
});

export default NavigationView;

