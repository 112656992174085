import SceneController from 'controllers/sceneController';
import ContactsOverviewController
    from 'controllers/settings/contacts/contactsOverviewController';
import ContactDetailController
    from 'controllers/settings/contacts/contactDetailController';
import UserContactDetailController
    from 'controllers/settings/contacts/userContactDetailController';
import ContactGroupDetailsController
    from 'controllers/settings/contacts/contactGroupDetailsController';
import Contact from 'models/contact';
import ContactGroup from 'models/contactGroup';
import contacts from 'collections/contactsInstance';
import contactGroupsInstancePromise
    from 'models/contactGroupsInstancePromise';
import _ from 'underscore';
import when from 'when';
import I18n from 'i18n';
import PlaceholderView from 'views/settings/contacts/contactsPlaceholderView';
import Notificator from 'libraries/notificator'
import $ from 'jquery'

class ContactsSceneController extends SceneController {
    show() {
        this._showContactsOverview();
        this._showInitialView();
    }

    _showInitialView() {
        function firstOrReject(collection) {
            if (!collection.isEmpty()) {
                return when.resolve(collection.first());
            } else {
                return when.reject("collection empty");
            }
        }
        var self = this;
        contactGroupsInstancePromise
            .then(firstOrReject)
            .then(function (contactGroup) {
                self._showContactGroupDetails(contactGroup);
            }).otherwise(function () {
                self._showPlaceholder();
            });
    }

    _showContactsOverview() {
        this.controller = new ContactsOverviewController();
        this.showInLeftColumn(this.controller.view);
        this._registerListeners();
    }

    _showPlaceholder() {
        var placeholder = new PlaceholderView();
        this.showInRightColumn(placeholder);
    }

    _showContactDetails(contact) {
        var Controller = ContactDetailController;
        if (contact.get('is_user_contact')) {
            Controller = UserContactDetailController;
        }
        this._showDetailController(
            Controller,
            { contact: contact }
        );
    }

    _showContactGroupDetails(contactGroup) {
        this.controller.selectContactGroup(contactGroup);
        this._showDetailController(
            ContactGroupDetailsController,
            contactGroup
        );
    }

    _showDetailController(Controller, param) {
        this.detailController = new Controller(param);
        this.detailView = this.detailController.view;
        this.showInRightColumn(this.detailView);
        this.focusRight();
        this.listenTo(this.detailController, 'deleted', this._closeDetailView);
    }

    _handleNewContactGroup() {
        var contactGroup = new ContactGroup({
            name: I18n.t('webapp.settings.contacts.groups.new_group')
        });
        contactGroupsInstancePromise.done(function (contactGroups) {
            contactGroups.add(contactGroup);
            contactGroup.save();
        });
        this._showContactGroupDetails(contactGroup);
    }

    _handleNewContact(inputValue) {
        var self = this;
        var contact = new Contact();
        if (inputValue !== undefined) {
            if (/@/.test(inputValue)) {
              contact.set({ email: inputValue });
            } else {
              contact.set({ name: inputValue });
            }
        }
        var saved = when.promise(function (resolve, reject) {
            contact.once('change:id', resolve);
            contact.once('destroy', reject);
        });
        saved.then(function (c) {
            contacts.add(c);
            self.controller.renderContactList(
                { highlightContactId: c.get('id') }
            );
        }).orElse();
        this._showContactDetails(contact);
    }

    _closeDetailView() {
        if (!_.isUndefined(this.detailView)) {
            this.detailView.close();
            delete this.detailView;
            this.detailController.close();
            delete this.detailController;
            this.controller.deselectAll();
        }
        this.controller.renderContactList();
        this._showPlaceholder();
    }

    _registerListeners() {
        this.listenTo(this.controller, 'contact-group:clicked', this._showContactGroupDetails);
        this.listenTo(this.controller, 'contact:clicked', this._showContactDetails);
        this.listenTo(this.controller, 'contact-group:add', this._handleNewContactGroup);
        this.listenTo(this.controller, 'contact:add', this._handleNewContact);
        this.listenTo(this.controller, 'contacts:cleanup', this._handleContactCleanup);
        this.listenTo(this.controller, 'contact:close', this._closeDetailView);
    }

    _handleContactCleanup() {
        this._closeDetailView();
        const self = this;
        $.ajax({
            url: '/api/v3/contacts/cleanup',
            type: 'POST',
            dataType: 'json',
            error: function () {
                self.handleRequestError();
            }
        }).always(() => self.controller.refreshContactList())
    }

    handleRequestError() {
        const key =
          'webapp.settings.contacts.general.cleanup_contacts_modal.cleanup_error'
        Notificator.showNotification(I18n.t(key));
    }
}

export default ContactsSceneController;
