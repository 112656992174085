/*** IMPORTS FROM imports-loader ***/
var $ = require("jquery");

//
// I just copied this file from the IXT repo and made the following
// changes:
//
//   - namespaced this plugin under DropdownMultiselect,
//     the original was Dropdown (adjusted css class accordingly: dropdown-multiselect),
//     so that this doesn't interfere with the normal dropdown functionality.
//     not sure if it would, but better safe than sorry...
//
//   - renamed the class for an open dropdown from "open" -> "is-open",
//     as that's the css class IXT uses for multiselect dropdowns
//
//   - changed the selector '.dropdown form' -> '.multiselect__body form',
//     this has the effect, that the dropdown won't close when clicking
//     inside of it (i.e. checkboxes)
//
//

/* ============================================================
 * bootstrap-dropdown.js v2.3.2
 * http://twitter.github.com/bootstrap/javascript.html#dropdowns
 * ============================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ============================================================ */


!function ($) {

  "use strict"; // jshint ;_;


 /* DROPDOWN CLASS DEFINITION
  * ========================= */

  var toggle = '[data-toggle=dropdown-multiselect]'
    , DropdownMultiselect = function (element) {
        var $el = $(element).on('click.dropdown-multiselect.data-api', this.toggle)
        $('html').on('click.dropdown-multiselect.data-api', function () {
          $el.parent().removeClass('is-open')
        })
      }

  DropdownMultiselect.prototype = {

    constructor: DropdownMultiselect

  , toggle: function (e) {
      var $this = $(this)
        , $parent
        , isActive

      if ($this.is('.disabled, :disabled')) return

      $parent = getParent($this)

      isActive = $parent.hasClass('is-open')

      clearMenus()

      if (!isActive) {
        if ('ontouchstart' in document.documentElement) {
          // if mobile we we use a backdrop because click events don't delegate
          $('<div class="dropdown-multiselect-backdrop"/>').insertBefore($(this)).on('click', clearMenus)
        }
        $parent.toggleClass('is-open')
      }

      $this.focus()

      return false
    }

  , keydown: function (e) {
      var $this
        , $items
        , $active
        , $parent
        , isActive
        , index

      if (!/(38|40|27)/.test(e.keyCode)) return

      $this = $(this)

      e.preventDefault()
      e.stopPropagation()

      if ($this.is('.disabled, :disabled')) return

      $parent = getParent($this)

      isActive = $parent.hasClass('is-open')

      if (!isActive || (isActive && e.keyCode == 27)) {
        if (e.which == 27) $parent.find(toggle).focus()
        return $this.click()
      }

      $items = $('[role=menu] li:not(.divider):visible a', $parent)

      if (!$items.length) return

      index = $items.index($items.filter(':focus'))

      if (e.keyCode == 38 && index > 0) index--                                        // up
      if (e.keyCode == 40 && index < $items.length - 1) index++                        // down
      if (!~index) index = 0

      $items
        .eq(index)
        .focus()
    }

  }

  function clearMenus() {
    $('.dropdown-multiselect-backdrop').remove()
    $(toggle).each(function () {
      getParent($(this)).removeClass('is-open')
    })
  }

  function getParent($this) {
    var selector = $this.attr('data-target')
      , $parent

    if (!selector) {
      selector = $this.attr('href')
      selector = selector && /#/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, '') //strip for ie7
    }

    $parent = selector && $(selector)

    if (!$parent || !$parent.length) $parent = $this.parent()

    return $parent
  }


  /* DROPDOWN PLUGIN DEFINITION
   * ========================== */

  var old = $.fn.dropdownMultiselect

  $.fn.dropdownMultiselect = function (option) {
    return this.each(function () {
      var $this = $(this)
        , data = $this.data('dropdown-multiselect')
      if (!data) $this.data('dropdown-multiselect', (data = new DropdownMultiselect(this)))
      if (typeof option == 'string') data[option].call($this)
    })
  }

  $.fn.dropdownMultiselect.Constructor = DropdownMultiselect


 /* DROPDOWN NO CONFLICT
  * ==================== */

  $.fn.dropdownMultiselect.noConflict = function () {
    $.fn.dropdownMultiselect = old
    return this
  }


  /* APPLY TO STANDARD DROPDOWN ELEMENTS
   * =================================== */

  $(document)
    .on('click.dropdown-multiselect.data-api', clearMenus)
    .on('click.dropdown-multiselect.data-api', '.multiselect__body form', function (e) { e.stopPropagation() })
    .on('click.dropdown-multiselect.data-api'  , toggle, DropdownMultiselect.prototype.toggle)
    .on('keydown.dropdown-multiselect.data-api', toggle + ', [role=menu]' , DropdownMultiselect.prototype.keydown)

}(window.jQuery);

