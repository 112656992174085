import _ from 'underscore'
import Backbone from 'backbone'

var FilterSortSettings = Backbone.Model.extend({
  identifier: 'filterSortSettings',
  initialize: function () {
    var report = this.get('report');
    this.set({
      clip_types_to_keep: report.get('clip_types_to_keep'),
      group_by: report.get('group_by'),
      order_by: report.get('order_by'),
      topic_type: report.get('topic_type')
    });

    this.on('change:clip_types_to_keep', () => {
      report.set({clip_types_to_keep: this.get('clip_types_to_keep')});
      this.checkForCompleteness();
    });
    this.on('change:group_by', () => {
      var value = this.get('group_by');
      report.set({group_by: value});
      this.checkForCompleteness();
    });
    this.on('change:order_by', () => {
      report.set({order_by: this.get('order_by')});
      this.checkForCompleteness();
    });
    var self = this;
    report.on('change:topic_type', () => {
      self.set({topic_type: report.get('topic_type')});
    });
    this.checkForCompleteness();
  },

  data: function () {
    var clip_types_to_keep_array;

    if (this.get('clip_types_to_keep')) {
      clip_types_to_keep_array = this.get('clip_types_to_keep');
    } else {
      clip_types_to_keep_array = [];
    }
    return {
      clip_types_to_keep: clip_types_to_keep_array,
      group_by: this.get('group_by'),
      order_by: this.get('order_by'),
      topic_type: this.get('topic_type')
    };
  },

  canGroupByTopic: function () {
    return (this.get('report').get('topic_type') !== "clip");
  },

  checkForCompleteness: function () {
    var order_by = this.get('order_by');
    var group_by = this.get('group_by');
    var clip_types_to_keep = this.get('clip_types_to_keep');
    var complete = order_by && group_by && !_.isEmpty(clip_types_to_keep);
    this.set({complete: complete});
  }
});

export default FilterSortSettings;

