import { IContactProps } from 'views/contactGroup/contactView'
import AddressbookModel,
  {
    IAddressbookModelProps,
    IAddressbookGroup,
    IAddressbookContact,
    IContact,
    IContactGroup,
    AddressbookType,
    isContact
  }
  from 'models/addressbookModel'
import * as Backbone from 'backbone'
import * as _ from 'underscore'

export default class ContactReactAdapter {
  models: Array<AddressbookModel>

  constructor(contactsOrGroups: Array<IContact|IContactGroup>) {
    this.models = _.map(contactsOrGroups, (model) => {
      if (isContact(model)) {
        return new AddressbookModel(
          `c${model.get('id')}`, AddressbookType.Contact, model
        )
      } else {
        return new AddressbookModel(
          `g${model.get('id')}`, AddressbookType.ContactGroup, model
        )
      }
    })
  }

  toReactProps(): Array<IAddressbookModelProps> {
    return _.map(this.models, (model: AddressbookModel) => {
        return model.toReactProps()
    })
  }

  filter(filter: string) {
    return ContactReactAdapter.filter(this.toReactProps(), filter)
  }

  excludingContactGroup(contactGroupId: number) {
    return ContactReactAdapter.excludingContactGroup(this.toReactProps(), contactGroupId)
  }

  static excludingContactGroup(contacts: Array<IAddressbookModelProps>, contactGroupId: number) {
    return _.filter(contacts, (contact) => {
      if (contact.type === AddressbookType.Contact) {
        const model = <IAddressbookContact> contact.data
        const groupIds = model.contact_group_ids
        return !_.contains(groupIds, contactGroupId)
      } else {
        return false
      }
    })
  }

  static filter(contacts: Array<IAddressbookModelProps>, filter: string) {
    return contacts.reduce((agg: Map<string, boolean>, model: IAddressbookModelProps) => {
      let matches: boolean
      if (filter.length === 0) {
        matches = true
      } else {
        if (model.type === AddressbookType.ContactGroup) {
          const contactGroup = <IAddressbookGroup> model.data
          let text = contactGroup.name || ''
          _.each(contactGroup.contacts, (model: IAddressbookModelProps) => {
            const contact = <IAddressbookContact> model.data
            const contactName = contact.name || ''
            const contactText = ` ${name} ${contact.email} `
            text += contactText
          })
          matches = text.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        } else {
          const contact = <IAddressbookContact> model.data
          const name = contact.name || ''
          const filterableText = name + ' ' + contact.email
          matches = filterableText.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        }
      }
      return agg.set(model.id, matches)
    }, new Map<string, boolean>())
  }
}
