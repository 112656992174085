function checkLiteralSyntax(text: string): boolean {
  function checkEvenQuoteCount(text: string): boolean {
    return (text.split(/["«»„“”']/).length - 1) % 2 === 0;
  }

  function removePhrases (text: string): string {
    return text.replace(/["«»„“”].*?["«»„“”]/g, '');
  }

  function checkForUseOfKeywords (text: string): boolean {
    var dephrasedTrimmedText = removePhrases(text.trim().toLowerCase());
    return !/((^|\s)and(\s|$)|(^|\s)or(\s|$))/.test(dephrasedTrimmedText);
  }

  function checkForDisallowedLeadingDash (text: string): boolean {
    return !/^\-/.test(text)
  }

  // the text can include the special characters, but not exclusively
  function checkForDisallowedSpecialCharacters (text: string): boolean {
    let pattern = /[€$".:<>\/§!$%?=’´`^°–—@\-']/g
    return !pattern.test(text) || text.replace(pattern, '').length !== 0
  }

  function checkForParentheses(text: string): boolean {
    return !/[()]/.test(text);
  }

  return checkEvenQuoteCount(text)
    && checkForUseOfKeywords(text)
    && checkForDisallowedLeadingDash(text)
    && checkForDisallowedSpecialCharacters(text)
    && checkForParentheses(text);
};

export default checkLiteralSyntax;
