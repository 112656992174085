import Validations from 'libraries/validations';
import ReaderAudienceReactAdapter from 'models/readerAudienceReactAdapter'
import Marionette from 'backbone.marionette';
import ReaderAudienceLayout from 'views/newsroom/readerAudienceLayout';
import _ from 'underscore';
import contactsInstance
    from 'collections/contactsInstance';
import Contact from 'models/contact';
import when from 'when';
import Notificator from 'libraries/notificator';
import Usage from 'models/usage';
import UnitOrderModalView from 'views/settings/unitOrderModalView';
import I18n from 'i18n'
import session from 'models/sessionInstance';

class ReaderAudienceController extends Marionette.Controller {
    initialize(options) {
        this.configuration = options.configuration;
        let editable = true;
        if (session.hasFeature('use_new_reader_management')) {
            editable = this.configuration.get('shared');
        }

        this.view = new ReaderAudienceLayout({ editable: editable });
        this.listenTo(this.view, 'render', this._onViewRender);
    }

    _onViewRender() {
        this.view.showAudienceWithAddressbook(this.audienceListViewProps());
    }

    audienceListViewProps() {
        const adapter = new ReaderAudienceReactAdapter(this.configuration)
        let audienceEditable = true;
        if (session.hasFeature('use_new_reader_management')) {
            audienceEditable = this.configuration.get('shared');
        }
        return {
            editContactId: null,
            highlightContactId: null,
            newContactInput: false,
            newContactEmailInvalid: false,
            newContactText: '',
            contacts: adapter.audienceToReactProps(),
            allContacts: adapter.addressbookToReactProps(),
            searchable: audienceEditable,
            useAudienceCount: true,
            searchInput: '',
            searchOpen: false,
            addressbookOnly: false,
            useButtonStyle: false,
            addressbookEditable: audienceEditable,
            addressbookRemovable: false,
            addressbookDestroyable: false,
            addressbookAddable: audienceEditable,
            contactsEditable: audienceEditable,
            contactsRemovable: audienceEditable,
            contactsDestroyable: false,
            contactsAddable: false,
            contactGroupAddable: audienceEditable,
            contactGroupContactEditable: audienceEditable,
            groupMember: false,
            searchText:
                I18n.t('webapp.settings.contacts.groups.search_or_add_contact'),
            contactsText:
                I18n.t('webapp.reader_admin.edit.audience_headline'),
            addressBookText:
                I18n.t('webapp.settings.contacts.groups.list_headline_address_book'),
            onAdd: (contact, searchInput) => {
                this._addContact(contact.id)
                this.renderAudienceWithAddressbook({
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        'webapp.reader_admin.edit.audience.list_add_label',
                        { "search_string": searchInput }
                    )
                })
            },
            onGroupAdd: (group, searchInput) => {
                this._addContactGroup(group.id)
                this.renderAudienceWithAddressbook({
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        'webapp.reader_admin.edit.audience.list_add_label',
                        { "search_string": searchInput }
                    )
                })
            },
            onCancel: (searchInput) => {
                this.renderAudienceWithAddressbook({
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        'webapp.reader_admin.edit.audience.list_add_label',
                        { "search_string": searchInput }
                    )
                })
            },
            onUpdate: (contact, name, email, searchInput) => {
                let model = contactsInstance.get(contact.id)
                model.set({ name: name, email: email})
                this.renderAudienceWithAddressbook({
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        'webapp.reader_admin.edit.audience.list_add_label',
                        { "search_string": searchInput }
                    )
                })
                this._updateAudienceMember(contact.id, name, email)
            },
            onCreateContact: (name, email, searchInput) => {
                const valid = Validations.isEmailValid(email)
                if (valid) {
                    let contact = new Contact(
                        {name: name, email: email, contact_group_ids: []}
                    )
                    contactsInstance.add(contact)
                    this.renderAudienceWithAddressbook({
                      newContactInput: false,
                      newContactEmailInvalid: false,
                      searchInput: searchInput,
                      newContactText: I18n.t(
                        "webapp.reader_admin.edit.audience.list_add_label",
                        { "search_string": searchInput }
                      )
                    })
                    this._createContactAndAdd(contact, searchInput)
                } else {
                  this.renderAudienceWithAddressbook({
                    newContactInput: true,
                    newContactEmailInvalid: true,
                    searchInput: searchInput,
                    newContactText: I18n.t(
                      "webapp.reader_admin.edit.audience.list_add_label",
                      { "search_string": searchInput }
                    )
                  })
                }
            },
            onRemove: (contact, searchInput) => {
                this._removeAudienceMemberByIdAndType(contact.id, 'contact');
                this.renderAudienceWithAddressbook({
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        'webapp.reader_admin.edit.audience.list_add_label',
                        { "search_string": searchInput }
                    )
                })
            },
            onGroupRemove: (group, searchInput) => {
                this._removeAudienceMemberByIdAndType(group.id, 'contact_group');
                this.renderAudienceWithAddressbook({
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        'webapp.reader_admin.edit.audience.list_add_label',
                        { "search_string": searchInput }
                    )
                })
            },
            onDestroy: () => {},
            onEdit: (contact, searchInput) => {
                this.renderAudienceWithAddressbook({
                    editContactId: contact.id,
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        'webapp.reader_admin.edit.audience.list_add_label',
                        { "search_string": searchInput }
                    )
                })
            },
            onListItemClick: () => {},
            onNewContact: (searchInput) => {
                this.renderAudienceWithAddressbook({
                    newContactInput: true,
                    newContactEmailInvalid: false,
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        "webapp.reader_admin.edit.audience.list_add_label",
                        { "search_string": searchInput }
                    )
                })
            },
            onSearch: (query) => {
                this.trigger('contact:close')
                this.renderAudienceWithAddressbook({
                    newContactInput: false,
                    newContactEmailInvalid: false,
                    searchInput: query,
                    newContactText: I18n.t(
                        "webapp.reader_admin.edit.audience.list_add_label",
                        { "search_string": query }
                    )
                })
            }
        }
    }

    renderAudienceWithAddressbook(props) {
        let fullProps = this.audienceListViewProps()
        if (props !== undefined) {
            fullProps = _.extend({}, fullProps, props)
        }
        this.view.showAudienceWithAddressbook(fullProps);
    }

    _addContact(contact_id) {
        const newAudience =
            this._addAudienceMemberByIdAndType(contact_id, 'contact');
        this._storeNewAudience(newAudience);
    }

    _addContactGroup(contactGroupId) {
        const newAudience =
            this._addAudienceMemberByIdAndType(contactGroupId, 'contact_group');
        this._storeNewAudience(newAudience);
    }

    _removeAudienceMemberByIdAndType(id, type) {
        let newAudience = _.reject(this.configuration.get('audience'),
                                   function (member) {
            return member.id === id && member.type === type;
        });
        this._storeNewAudience(newAudience);
    }

    _addAudienceMemberByIdAndType(id, type) {
        const audience = this.configuration.get('audience');
        return _.uniq(audience.concat([{id: id, type: type}]), false, function (data) {
            return `${data.id}${data.type}`;
        });
    }

    _storeNewAudience(newAudience) {
        const oldAudience = this.configuration.get('audience')
        const memberAdded = newAudience.length > oldAudience.length

        this._setAudience(newAudience)

        if (memberAdded) {
            let usage = new Usage()
            when.join(
                usage.load(),
                this.configuration.updateIncurCost()
            ).then((r) => {
                const response = r[1];
                if (response.incur_cost) {
                    this._showOrderModal(usage, oldAudience)
                } else {
                    this._performSave(oldAudience)
                }
            })
        } else {
            this._performSave(oldAudience)
        }
    }

    _performSave(oldAudience) {
        when(this.configuration.save()).otherwise((response) => {
            this._handleErrorResponse(response, oldAudience)
        });
    }

    _showOrderModal(usage, oldAudience) {
        this.orderModalView = new UnitOrderModalView({
            unitType: 'reader_seat',
            usage: usage
        })
        this.listenTo(this.orderModalView, 'modal:confirmed', () => this._performSave(oldAudience))
        this.listenTo(this.orderModalView, 'modal:cancelled', () => this._setAudience(oldAudience))
        this.view.orderModal.show(this.orderModalView);
        this.orderModalView.showModal();
    }

    _handleErrorResponse(response, oldAudience) {
        let error;
        if (response.responseText) {
            try {
                error = JSON.parse(response.responseText)['error'].join(' ');
            } catch(e) {
              // ignore errors
            }
        }
        let message;
        if (error && error.match(/reader seat maximum reached/i)) {
            message = I18n.t('webapp.notifications.error.reader_seat_maximum_reached');
        } else {
            message = I18n.t("webapp.notifications.error.not_saved");
        }
        Notificator.showNotification(message);
        this._setAudience(oldAudience)
    }

    _setAudience(newAudience) {
        this.configuration.set({ audience: newAudience });
    }

    _updateAudienceMember(contactId, name, email) {
        let usage = new Usage();
        let contact = contactsInstance.get(contactId);
        when.join(
            usage.load(),
            contact.updateIncurCost({ name: name, email: email })
        ).then((r) => {
            let response = r[1];
            if (response.incur_cost) {
                this._showUpdateContactOrderModal(usage, contact);
            } else {
                this._performUpdateAudienceMember(contact, name, email);
            }
        });
    }

    _showUpdateContactOrderModal(usage, contact) {
        this.orderModalView = new UnitOrderModalView({
            unitType: 'reader_seat',
            usage: usage
        });
        this.listenTo(
            this.orderModalView,
            'modal:confirmed',
            () => {
                this._performUpdateAudienceMember(contact)
            }
        );
        this.listenTo(
            this.orderModalView,
            'modal:cancelled',
            () => {
                contact.set(contact.previousAttributes());
                this.renderAudienceWithAddressbook()
            }
        );
        this.view.orderModal.show(this.orderModalView);
        this.orderModalView.showModal();
    }

    _performUpdateAudienceMember(contactId, name, email) {
        let contact = contactsInstance.get(contactId);
        contact.save({ name: name, email: email }).done(() => {
            this.renderAudienceWithAddressbook()
        });
    }

    _createContactAndAdd(contact, searchInput) {
        contact.save().done(() => {
            this._addContact(contact.id)
            this.renderAudienceWithAddressbook({
              newContactInput: false,
              newContactEmailInvalid: false,
              searchInput: searchInput,
              newContactText: I18n.t(
                "webapp.reader_admin.edit.audience.list_add_label",
                { "search_string": searchInput }
              )
            })
        });
    }
}

export default ReaderAudienceController;
