import I18n from 'i18n'

function agentResultReadingTime(agentResult) {
  var time = agentResult.get('reading_time');
  if (time > 0) {
    return I18n.t('webapp.agents.results.result_details.metadata.minutes',
                  {m: time});
  } else if (time === 0) {
    return I18n.t('webapp.agents.results.result_details.metadata.reading_time_short');
  } else {
    return null;
  }
}

export default agentResultReadingTime;

