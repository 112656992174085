import * as React from "react";

interface Props {
  label: string
}
export const Spinner = (props: Props) => {
  return (
    <div className="preloader">
      <p className="preloader__label">{props.label}</p>
    </div>
  )
}
export default Spinner