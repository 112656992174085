import Backbone from 'backbone'
import _ from 'underscore'
import I18n from 'i18n'
import session from 'models/sessionInstance'
import SocialMediaMonitors from 'collections/socialMediaMonitors'
import SourceExclusions from 'collections/sourceExclusions'
import AgentTopics from 'collections/agentTopics'
import pusherWrapper from 'models/pusherWrapper'
import users from 'collections/usersInstance';
import RestorableModel from "libraries/restorableModel";

class Agent extends RestorableModel {
  defaults() {
    return {
      name: I18n.t("webapp.agents.edit.new_agent_name"),
      colors: { 'default': ''},
      shared: true
    };
  }

  initialize() {
    this.on('sync', () => {
      this.sourceCategoriesChanged = false;
      this.sourceGroupsChanged = false;
    });
    this._initializeTopicsAndSocialMediaMonitors(this.get("id"));
  }

  isOwnAgent() {
    if (this.isNew()) {
        return true;
    } else {
        return this.get('user_id') === users.currentUser.get('id');
    }
  }

  toJSON(options) {
    let full_json = Backbone.Model.prototype.toJSON.call(this);
    let json;

    if (!_.isUndefined(options) && !_.isUndefined(options.updateOnly)) {
      if (options.updateOnly === 'name') {
        json = { agent: { name: full_json.name } };
      }
      else if (options.updateOnly === 'shared') {
        json = { agent: { shared: full_json.shared } };
      }
      else if (options.updateOnly === 'source_group_ids') {
        json = { agent: { source_group_ids: full_json.source_group_ids } };
      }
      else if (options.updateOnly === 'source_category_ids') {
        json = { agent: { source_category_ids: full_json.source_category_ids } };
      }
      else if (options.updateOnly === 'creation_finished') {
        json = {
          agent: {
            creation_finished: full_json.creation_finished
          }
        };
      }
      else if (options.updateOnly === 'search_start_date') {
        json = { agent: { search_start_date: full_json.search_start_date } };
      }
      else if (options.updateOnly === 'search_end_date') {
        json = { agent: { search_end_date: full_json.search_end_date } };
      }
      else if (options.updateOnly === 'source_groups') {
        json = { agent: {
          source_group_ids: full_json.source_group_ids
        } };
      }
      else if (options.updateOnly === 'created_at') {
        json = { agent: { created_at: full_json.created_at } };
      } else { throw 'Invalid value for updateOnly'; }
    } else {
      json = {
        agent: {
          name:     full_json.name,
          user_id:  full_json.user_id,
          shared:   full_json.shared,
          source_category_ids: full_json.source_category_ids,
          source_group_ids: full_json.source_group_ids,
          creation_finished: full_json.creation_finished,
          created_at: full_json.created_at
        }
      };
    }

    json = _.extend(json, {socket_id: pusherWrapper.socketId});
    return json;
  }

  parse(response) {
    let data = response.object ? response.object : response;
    this.buildSourceExclusionsCollection(data);

    return data;
  }

  sync(method, model, options) {
    if (method === "delete") {
      options.data = 'socket_id=' + pusherWrapper.socketId;
    }
    return Backbone.Model.prototype.sync.call(this,
                                              method,
                                              model,
                                              options);
  }

  buildSourceExclusionsCollection(response) {
    let sourceExclusions = new SourceExclusions(
      response.source_exclusions, { agentId: response.id }
    );
    this.set("source_exclusions", sourceExclusions);
    delete response.source_exclusions;
  }

  fetchSocialMediaMonitors() {
    let monitors = this.get('social_media_monitors');
    if (this.isNew()) {
      monitors.collectionLoaded();
    } else if (!monitors.isCollectionLoaded) {
      monitors.fetch({success: function () {
        monitors.collectionLoaded();
      }});
    }
  }

  fetchTopics() {
    let topics = this.get('topics');
    if (this.isNew()) {
      topics.collectionLoaded();
    } else if (!topics.isCollectionLoaded) {
      topics.fetch({success: function () {
        topics.collectionLoaded();
      }});
    }
  }

  topicSocialMediaMonitorLimitReached() {
    let limit = session.get('limits').topics_or_social_media_monitors_per_agent;
    let numberOfTopicsSocialMediaMonitors = this.get('topics').length;
    numberOfTopicsSocialMediaMonitors += this.get('social_media_monitors').length;

    return numberOfTopicsSocialMediaMonitors >= limit;
  }

  creationFinished() {
    return this.get('creation_finished') === true;
  }

  markAsCreated() {
    if (!this.creationFinished()) {
      this.set('creation_finished', true);
      this.save(null, { updateOnly: 'creation_finished' });
    }
  }

  _initializeTopicsAndSocialMediaMonitors() {
    this.set('topics', new AgentTopics([], {agent: this}));
    this.set('social_media_monitors',
             new SocialMediaMonitors([], {agent: this}));
  }
}
Agent.prototype.urlRoot = "/api/v3/agents";

export default Agent;
