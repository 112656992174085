/* eslint-disable react/display-name */
import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/settings/contacts/contactItem.html';
import _ from 'underscore';
import Dropdown from 'components/dropdown';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import I18n from 'i18n';

class ContactItemView extends Marionette.Layout {
    initialize(options) {
        _.bindAll(
            this,
            '_triggerNameFocus',
            '_triggerEmailFocus',
            '_triggerNameEmailChanged',
            '_triggerBlurSave',
            '_showLocalePicker'
        );
        this.readerNames = options.readerConfigurations;
    }

    serializeData() {
        let name = this.model.get('name');
        let email = this.model.get('email');
        let locale = this.model.get('reader_language');
        return {
            name: name,
            email: email,
            iconType: this.model.isUserContact() ? 'user' : 'envelope',
            showLanguageDropdown: !this.model.isUserContact(),
            title: name || email,
            readerConfigurations: this.readerNames,
            reader_language: locale,
        };
    }

    /*
     *  The backbone/marionette wiring is not working here at all!
     *
     *  If we call render() from controller after the setting of attributes,
     *  we do get the updated view, but we lose the cursor position of the user
     *  (he could be editing the name somewhere on middle not just the end).
     *
     *  This because we have the timer for saving on controller.
     *
     *  Its horrible I know.
     */
    renderTitle() {
        let name = this.$(this.ui.name).val();
        let email = this.$(this.ui.email).val();
        let iconType = this.model.isUserContact() ? 'user' : 'envelope';
        this.$(this.ui.title).html(
            `<i class="icon-${iconType} icon-large"></i> ${name || email}`
        );
    }

    onRender() {
        this.hideEmailError();
        this.$(this.ui.name).on('focus', this._triggerNameFocus);
        this.$(this.ui.email).on('focus', this._triggerEmailFocus);
        this.$(this.ui.name).on('keyup', this._triggerNameEmailChanged);
        this.$(this.ui.email).on('keyup', this._triggerNameEmailChanged);
        this.$(this.ui.name).on('blur', this._triggerBlurSave);
        this.$(this.ui.email).on('blur', this._triggerBlurSave);
        let locale = this.model.get('reader_language');
        this._setLocale(locale);
        this._showLocalePicker();
    }

    onClose() {
        this.$(this.ui.name).off('focus', this._triggerNameFocus);
        this.$(this.ui.email).off('focus', this._triggerEmailFocus);
        this.$(this.ui.name).off('keyup', this._triggerNameEmailChanged);
        this.$(this.ui.email).off('keyup', this._triggerNameEmailChanged);
        this.$(this.ui.name).off('blur', this._triggerBlurSave);
        this.$(this.ui.email).off('blur', this._triggerBlurSave);
    }

    showEmailError(text) {
        this.$(this.ui.emailError).show();
        this.$(this.ui.emailErrorContent).html(text);
    }

    formAttributes() {
        return {
            name: this.$(this.ui.name).val(),
            email: this.$(this.ui.email).val()
        };
    }

    hideEmailError() {
        this.$(this.ui.emailError).hide();
    }

    disableInputFields() {
        this.$(this.ui.email).prop('disabled', true);
        this.$(this.ui.name).prop('disabled', true);
    }

    enableInputFields() {
        this.$(this.ui.email).prop('disabled', false);
        this.$(this.ui.name).prop('disabled', false);
    }

    _triggerEmailFocus() {
        this.trigger("nameEmailFocus");
    }

    _triggerNameFocus() {
        this.trigger("nameEmailFocus");
    }

    _triggerNameEmailChanged() {
        this.trigger("nameEmailChanged", this.formAttributes());
    }

    _triggerBlurSave() {
        this.trigger("nameEmailBlur", this.formAttributes());
    }

    showReloadWarning() {
        this.ui.reloadWarning.removeClass('hidden');
    }

    hideReloadWarning() {
        this.ui.reloadWarning.addClass('hidden');
    }

    _setLocale(locale) {
        this.locale = locale;
    }

    _onLocaleChange(newOption) {
        this._setLocale(newOption)
        this.trigger('locale:change', newOption)
    }

    _showLocalePicker() {
        const availableLanguages = ['en', 'de', 'fr'];
        let options = [{ text: I18n.t("webapp.settings.contacts.contacts.default_language"), value: '' }];
        availableLanguages.forEach((language) => {
            const translatedText = I18n.t(`vendor.iso.languages.${language}`);
            options.push({ text: translatedText, value: language });
        });
        this.localePickerView = new ReactWrapper({
          getComponent: () => {
            return <Dropdown options={options}
                             selectedOption={this.locale}
                             onOptionChange={(newOption) => this._onLocaleChange(newOption)} />
          }
        })
        this.localePicker.show(this.localePickerView)
    }
}
ContactItemView.prototype.template = template;
ContactItemView.prototype.ui = {
    name: '[data-name]',
    email: '[data-email]',
    title: '[data-title]',
    emailError: '[data-email-error]',
    emailErrorContent: '[data-email-error-content]',
    languageDropdownBody: '[data-language-dropdown-body]',
};
ContactItemView.prototype.regions = {
    localePicker: '[data-locale-picker]'
};

export default ContactItemView;
