import deepMerge from 'libraries/deepMerge'
import _ from 'underscore'
import Chart from 'views/dashboard/widgetView/helpers/chart'

function BarChart(container, options) {
  this._initialize(container, options);
}

_.extend(BarChart.prototype, Chart.prototype, {
  _defaultOptions: function () {
    var defaults = Chart.prototype._defaultOptions.call(this);
    deepMerge(defaults, {
      chart: {
        type: 'bar',
        marginBottom: 7
      },
      xAxis: {
        gridLineColor: '#c0c0c0',
        tickWidth: 0,
        labels: {
          enabled: true
        }
      },
      yAxis: {
        gridLineColor: '#ffffff',
        labels: {
          enabled: false,
          style: {
            width: 1000
          }
        }
      }
    });
    return defaults;
  }
});

export default BarChart;

