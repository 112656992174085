import Backbone from 'backbone'
import I18n from 'i18n'
import sessionInstance from "models/sessionInstance";
import _ from "underscore";

class AgentTopic extends Backbone.Model {
  urlRoot() {
    return '/api/v3/agents/' + this.agent().id + '/topics/';
  }

  defaults() {
    let feature = sessionInstance.hasFeature('search_agent_topics_with_expert_search');
    if (feature) {
      return {
        topic_name: I18n.t("webapp.agents.edit.topic.title_placeholder")
      }
    } else {
      return {
        topic_name: undefined
      }
    }
  }

  parse(response) {
    if (response.object) {
      return response.object;
    } else {
      return response;
    }
  }

  toJSON() {
    return {
      topic: {
        id: this.get('id'),
        terms: this.get('terms'),
        contexts: this.get('contexts'),
        exclusions: this.get('exclusions'),
        topic_name: this.get('topic_name'),
        name: this.get('name'),
        json: this.parsedJson()
      }
    };
  }

  parsedJson() {
    let json = this.get('json');
    if (_.isString(json)) {
      return JSON.parse(json);
    } else {
      return json;
    }
  }

  agent() {
    return this.get('agent') || this.collection.agent;
  }
}

export default AgentTopic;
