import EntityPrefixSearch from "../collections/entityPrefixSearch";
import {IPrefixSearchEntityType} from '../models/prefixSearchResponse'
import Entity from '../models/entity'
import * as $ from 'jquery'

class EntityPrefixSearcher {
  entities: EntityPrefixSearch

  constructor() {
    this.entities = new EntityPrefixSearch([]);
  }

  search(input: string, entityType: IPrefixSearchEntityType): any {
    const queryParams = {
      q: input,
      type: entityType
    }
    if (input.trim().length > 0) {
      return this.entities.fetch({ data: queryParams });
    } else {
      return $.when([]);
    }
  }

  results(): Entity[] {
    return this.entities.models;
  }
}

export default EntityPrefixSearcher;
