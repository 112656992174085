import * as _ from "underscore";
import I18n from "i18n";

export interface IKbApiEntity {
  data: IKbApiEntityData
  included: IKbApiRepresentation[]
}

export interface IKbApiEntityData {
  attributes: IKbApiEntityAttributes
  id: string
  refs: IKbApiEntityRefs
  type: 'person' | 'business' | 'organization' | 'location'
}

export interface IKbApiRepresentation {
  id: string
  type: 'representation'
  attributes: IKbApiRepresentationAttributes
}

export type IKbApiRepType = 'last_name' | 'first_name' | 'official_name' | 'name' | 'code'

interface IKbApiRepresentationAttributes {
  lang: string
  rep_type: IKbApiRepType
  str: string
}


interface IKbApiEntityRefs {
  representations: IKbApiEntityRepresentationRefs
}

interface IKbApiEntityRepresentationRefs {
  data: IKbApiEntityRepresentationRef[]
  type: string
}

interface IKbApiEntityRepresentationRef {
  id: string
}

interface IKbApiEntityAttributes {
  created_at: number
  entity_type: string
  primary_representation: IKbApiEntityPrimaryRepresentation
  updated_at: number
}

export interface IKbApiEntityPrimaryRepresentation {
  en: string
  de: string
  fr: string
  it: string
}

export type SupportedLocale = 'de'| 'fr'| 'it' | 'en'

export function findRepresentation(
  entity: IKbApiEntity,
  repType: IKbApiRepType,
  language: SupportedLocale
): string | undefined {
  const representations = entity.included
  const result: IKbApiRepresentation | undefined =
    _.find(representations, (rep: IKbApiRepresentation) => {
      return rep.attributes.lang == language &&
        rep.attributes.rep_type == repType
    })
  if (result != undefined ) {
    return result.attributes.str
  } else {
    return undefined
  }
}

export function localizedName(
  entity: IKbApiEntity,
): string {
  return entity.data.attributes.primary_representation[locale()]
}

export function locale(): SupportedLocale {
  const supportedLocales: SupportedLocale[] = ['de', 'fr', 'it', 'en']
  const locale = _.contains(supportedLocales, I18n.locale) ?
    I18n.locale as SupportedLocale : 'de'
  return locale
}
