import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as jQuery from 'jquery'
import * as _ from 'underscore'
import I18n from 'i18n'
import Sortable from 'sortablejs'
import Dropzone from 'views/dropzone'

export interface Props {
  readonly images: Array<Readonly<Image>>
  onDelete: (image: Image) => void
  onAdd: (files: FileList) => void
  onUpdate: (image: Image) => void
  onMove: (image: Image, newIndex: number) => void
}

interface State {
  readonly selectedImageID: ImageID | undefined
  sorting: boolean
  typeErrors: String[]
}

export type ImageID = number | string

export interface Image {
  id: ImageID
  url: string
  caption?: string
  isImage: boolean
  extension?: string
}

interface ImageProps extends Readonly<Image> {
  onLoad: () => void
  onDeleteClick: () => void
  onEditClick: () => void
  readonly isSelected: boolean
}

interface EditProps {
  image: Image
  onUpdate(image: Image): void
}

class EditForm extends React.Component<EditProps, {}> {
  render() {
    const image = this.props.image
    const caption = I18n.t('webapp.agents.results.gallery.caption_placeholder')
    return (
      <div className="gallery-edit__form">
        <textarea
          className="textarea textarea--expanding"
          placeholder={ caption }
          key={image.id}
          onChange={(e) => this.handleUpdate(e)}
          defaultValue={image.caption}>
        </textarea>
      </div>
    )
  }

  private handleUpdate(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    let image = this.props.image
    let newCaption = e.target.value
    if (image.caption !== newCaption) {
      let newImage = _.extend({}, image, {caption: newCaption})
      this.props.onUpdate(newImage)
    }
  }
}

class ImageItem extends React.Component<ImageProps, {}> {
  li?: HTMLLIElement
  imageLoaded: boolean
  portrait: boolean

  constructor(props: ImageProps) {
    super(props)
    this.imageLoaded = false
    this.portrait = !props.isImage || false
  }

  getLi(): HTMLLIElement {
    if (this.li) {
      return this.li
    } else {
      throw("this.li is undefined")
    }
  }

  render() {
    const editIconTitle =
      I18n.t('webapp.agents.results.gallery.icon_title_edit')
    const deleteIconTitle =
      I18n.t('webapp.agents.results.gallery.icon_title_delete')

    if (this.props.isImage) {
      if (!this.imageLoaded) {
        let actualImage = new Image()
        actualImage.src = this.props.url
        actualImage.onload = () => {
          let el = jQuery(this.getLi())
          if (actualImage.width < actualImage.height) {
            el.addClass('portrait')
            this.portrait = true
          }
          el.fadeIn('fast').css('display', 'inline-block')
          this.imageLoaded = true
          this.props.onLoad()
        }
      }
    }

    let classes = ['gallery-edit__item']
    if (this.portrait) { classes.push('portrait') }
    if (!this.props.isImage) { classes.push('is-non-image') }
    if (this.props.isSelected) { classes.push('is-selected') }
    let style = {}
    if (!this.props.isImage) {
      style = {
        display: 'inline-block'
      }
    }
    return (
      <li ref={this.assignRef.bind(this)}
          className={ classes.join(' ') }
          style={style}
          data-img-index={ this.props.id }>
        {this.content()}
        <div className="gallery-edit__item-overlay">
          <div className="gallery-edit__item-overlay__edit"
               onClick={ (e) => this.onEditClick(e) }>
            <a href="#" title={ editIconTitle }>
              <i className="icon-pencil"></i>
            </a>
          </div>
          <div className="gallery-edit__item-overlay__delete"
               onClick={ (e) => this.onDeleteClick(e) }>
            <a href="#" title={ deleteIconTitle }>
              <i className="icon-trash"></i>
            </a>
          </div>
        </div>
      </li>
    )
  }

  assignRef(li: HTMLLIElement) {
    this.li = li
  }

  content() {
    if (this.props.isImage) {
      return (
        <div className="gallery-edit__item__image"
             style={ this.style() }
             data-alt={ this.props.caption }>
        </div>
      )
    } else {
      return (
        <div className="gallery-edit__item__file">
          <i className={`fa ${this.fileIcon()}`}></i>
        </div>
      )
    }
  }

  style() {
    return {
      backgroundImage: `url(${this.props.url})`
    }
  }

  private fileIcon(): string {
    switch(this.props.extension) {
      case 'zip':
      case 'rar':
      case 'gz':
      case 'tgz':
      case '7z':
      case 'xz':
      case 'bz':
        return 'fa-file-archive-o'
      case 'pdf':
        return 'fa-file-pdf-o'
      case 'doc':
      case 'docx':
      case 'docm':
        return 'fa-file-word-o'
      case 'xls':
      case 'xlsx':
      case 'xlsm':
        return 'fa-file-excel-o'
      case 'ppt':
      case 'pptx':
      case 'pptm':
        return 'fa-file-powerpoint-o'
      default:
        return 'fa-file'
    }
  }

  onDeleteClick(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault()
    let $this = jQuery(ReactDOM.findDOMNode(this)!)
    const fn: any = () => this.props.onDeleteClick()
    $this.fadeOut(fn)
  }

  onEditClick(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault()
    this.props.onEditClick()
  }
}

export default class ImageGalleryEditComponent extends React.Component<Props, State> {
  input?: HTMLInputElement
  sortable?: Sortable
  lastElementIndices?: number[]

  constructor(props: Props) {
    super(props)
    this.state = this.initialState()
  }

  initialState(): State {
    return {
      selectedImageID: undefined,
      sorting: false,
      typeErrors: []
    }
  }

  render() {
    let classes = ['gallery-edit']
    if (this.props.images.length <= 1) {
      classes.push('sorting-disabled')
    }
    if (this.state.sorting) {
      classes.push('sorting-active')
    }
    return (
      <Dropzone onDrop={(files: FileList) => {this.onAddToDropZone(files)}} enabled={!this.state.sorting}>
        <ol className={classes.join(' ')} id="gallery-edit">
          {this.images()}
          <li className="gallery-edit__add">
            <div className="gallery-edit__add-overlay" onClick={() => this.onAddClick()}>+</div>
            <input ref={this.assignInputRef.bind(this)}
                   onChange={() => {this.onAddCallback()}}
                   type="file"
                   className="gallery-edit__add-input"
                   value=""
                   multiple accept=".png,.jpg,.jpeg" />
          </li>
        </ol>
        {this.showTypeErrors()}
      </Dropzone>
    )
  }

  assignInputRef(input: HTMLInputElement) {
    this.input = input;
  }

  images(): Array<JSX.Element> {
    return this.props.images.map((image: Readonly<Image>) => {
      const imageProps = {
        id: image.id,
        url: image.url,
        extension: image.extension,
        caption: image.caption,
        isImage: image.isImage,
        isSelected: this.state.selectedImageID === image.id,
        onEditClick: () => this.onEditClick(image),
        onLoad: () => {},
        onDeleteClick: () => this.onDeleteClick(image)
      }
      return <ImageItem key={image.id} {...imageProps} />
    })
  }

  onAddCallback(): void {
    if (this.input && this.input.files !== null) {
      const files = this.input.files
      this.props.onAdd(files)
    } else {
      throw("this.input is undefined")
    }
    this.setState((prevState: State) => {
      return {
        typeErrors: []
      }
    })
  }

  onAddToDropZone(files: FileList): void {
    const badTypes = _.filter(files, (file) => {
      return file.type.match(/jpeg$|jpg$|png$/) === null
    } )
    const names = _.map(badTypes, (ba) => {return ba.name})
    this.setState((prevState: State) => {
      return {
        typeErrors: names
      }
    })
    this.props.onAdd(files)
  }

  showTypeErrors() {
    const typeErrors = _.map(this.state.typeErrors, (error, index) => {
      const errorText =
        I18n.t(
          'webapp.agents.results.gallery.error_wrong_filetype',
          { filename: error }
        )
      return (
        <span key={index} className="gallery-edit__feedback">
          <i className="icon-spam"></i> { errorText }
        </span>
      )
    })
    return (<div>{typeErrors}</div>)
  }

  selectedImage(): Readonly<Image> | undefined {
    return _.findWhere(this.props.images, {id: this.state.selectedImageID})
  }

  onAddClick(): void {
    jQuery(this.getInput()).click()
  }

  getInput(): HTMLInputElement {
    if (this.input) {
      return this.input
    } else {
      throw("this.input is undefined")
    }
  }

  onEditClick(image: Image): void {
    if (this.state.selectedImageID === image.id) {
      this.setState((prevState: State) => {
        return {
          selectedImageID: undefined,
          sorting: prevState.sorting,
          typeErrors: []
        }
      })
    } else {
      this.setState((prevState: State) => {
        return {
          selectedImageID: image.id,
          sorting: prevState.sorting,
          typeErrors: []
        }
      })
    }
  }

  onDeleteClick(image: Image): void {
    this.props.onDelete(image)
  }

  componentDidMount() {
    const callback: any = (indices: number[]) => {
      this.lastElementIndices = indices
      this.setupSortable()
      this.enableOrDisableSorting()
    }
    this.findLastElementsInRows().done(callback)
  }

  enableOrDisableSorting() {
    if (this.props.images.length <= 1) {
      this.disableSorting()
    } else {
      this.enableSorting()
    }
  }

  enableSorting() {
    if (this.sortable) {
      this.sortable.option('disabled', false)
    } else {
      throw("sortable is undefined")
    }
  }

  disableSorting() {
    if (this.sortable) {
      this.sortable.option('disabled', true)
    } else {
      throw("sortable is undefined")
    }
  }

  setupSortable() {
    let $this = jQuery(ReactDOM.findDOMNode(this)!)
    let sortableContainer = $this.find('.gallery-edit')[0]
    this.sortable = Sortable.create(sortableContainer, {
      chosenClass: 'is-dragged',
      ghostClass: 'gallery-edit__item-ghost',
      filter: ".gallery-edit__add",
      onStart: (event) => {
        this.setState((prevState) => {
          return {
            selectedImageID: prevState.selectedImageID,
            sorting: true,
            typeErrors: []
          }
        })
      },
      onEnd: (event) => {
        this.setState((prevState) => {
          let newState = {
            sorting: false,
            typeErrors: [],
            selectedImageID: prevState.selectedImageID
          }
          return newState
        })
        let fixOverflow = (index: number) => {
          let length = this.props.images.length
          return index >= length ? length - 1 : index
        }
        let oldIndex = fixOverflow(event.oldIndex!)
        let newIndex = fixOverflow(event.newIndex!)
        let image = this.props.images[oldIndex]
        this.props.onMove(image, newIndex)
      }
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const callback: any = (indices: number[]) => {
      this.lastElementIndices = indices
      this.renderEditForm(prevState)
      this.animateShownEditForm(prevState)
      this.enableOrDisableSorting()
    }
    this.findLastElementsInRows().done(callback)
  }

  animateShownEditForm(prevState: State) {
    let captionEditShown = prevState.selectedImageID !== this.state.selectedImageID &&
                           this.state.selectedImageID !== undefined
    if (captionEditShown) {
      this.destroyEditForm()
      window.requestAnimationFrame(() => {
        jQuery('.gallery-edit__form').slideDown(200)
      })
    } else if (prevState.selectedImageID !== this.state.selectedImageID) {
      window.requestAnimationFrame(() => {
        jQuery('.gallery-edit__form').slideUp(200, () => {
          this.destroyEditForm()
        })
      })
    }
  }

  private destroyEditForm(): void {
    const wrapper = jQuery('[data-edit-form-wrapper]')
    if (wrapper[0]) {
      ReactDOM.unmountComponentAtNode(wrapper[0])
      wrapper.remove()
    }
  }

  private renderEditForm(prevState: State) {
    const image = this.selectedImage()

    if (image && prevState.selectedImageID !== this.state.selectedImageID) {
      window.requestAnimationFrame(() => {
        let endlineElement = this.closestEndlineElement()
        if (endlineElement) {
          const wrapper = jQuery('<span data-edit-form-wrapper></span>')
          jQuery(endlineElement).after(wrapper)
          let editForm = <EditForm image={image} onUpdate={this.props.onUpdate} />
          ReactDOM.render(editForm, wrapper[0])
        }
      })
    }
  }

  private closestEndlineElement(): Element {
    const nearest = this.nearestLastElementIndex()
    const last = this.indexOfLastElement()

    if (nearest === last) {
      return jQuery('.gallery-edit__add')[0]
    } else if (nearest) {
      return jQuery('.gallery-edit__item')[nearest]
    } else {
      return jQuery('.gallery-edit__item')[0]
    }
  }

  private indexOfLastElement(): number {
    const allElements = jQuery('.gallery-edit__item')
    const lastElement = jQuery('.gallery-edit__item').last()
    return allElements.index(lastElement)
  }

  private nearestLastElementIndex(): number | undefined {
    if (this.lastElementIndices) {
      const allElements = jQuery('.gallery-edit__item')
      const selectedElement = jQuery('.gallery-edit__item.is-selected')
      const selectedIndex = allElements.index(selectedElement)
      const fn: any = (i: number) => selectedIndex <= i
      return _.find(this.lastElementIndices, fn)
    }
  }

  findLastElementsInRows(): JQueryPromise<number[]> {
    const lastElementIndices: number[] = []
    const deferred = jQuery.Deferred<number[]>();
    window.requestAnimationFrame(() => {
      setTimeout(() => {
        jQuery('.gallery-edit__item').each((index, domElement) => {
          let current = jQuery(domElement)
          let previous = current.prev('.gallery-edit__item')
          if (previous.length > 0) {
            if (current.position().top !== previous.position().top) {
              lastElementIndices.push(index - 1)
            }
          }
          if (current.next('.gallery-edit__item').length === 0) {
            lastElementIndices.push(index)
          }
        })
        deferred.resolve(lastElementIndices)
      }, 10)
    })
    return deferred.promise()
  }
}
