import Backbone from 'backbone';
import AgentTopic from 'models/agentTopic';
import Config from 'libraries/configuration';

class AgentTopics extends Backbone.Collection {
    initialize(models, options) {
        this.agent = options.agent;
    }
    collectionLoaded() {
        this.isCollectionLoaded = true;
    }
    url() {
        return Config.apiUrl + 'agents/' + this.agent.id + '/topics/';
    }
    parse(response) {
        return response.collection;
    }
}

AgentTopics.prototype.model = AgentTopic;

export default AgentTopics;
