import _ from 'underscore'
import Marionette from 'backbone.marionette'
import moment from 'moment'
import agents from 'collections/agentsInstance'
import AgentCollection from 'collections/agents'
import NotificationSummaryView from 'views/settings/notifications/notificationSummaryView'
import WeekdaySelectorController from 'controllers/settings/weekdaySelectorController'
import TimeSelectorController from 'controllers/settings/timeSelectorController'
import AgentSelectionController from 'controllers/settings/notifications/agentSelectionController'

var NotificationSummaryController = Marionette.Controller.extend({
  initialize: function (options) {
    this.settings = options.settings;
    this.view = new NotificationSummaryView();
    this._registerListeners();
  },
  _buildSelectedAgentsCollection: function () {
    var selectedAgents = _.map(this.settings.get('newsletter_agent_ids'), function (id) {
      return agents.get(id);
    }, this);
    return new AgentCollection(selectedAgents);
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'change', this._handleChange);
    this.listenTo(this.view, 'render', this._updateViewSelectors);
  },
  _handleChange: function (attributeName, value) {
    this._updateAttribute(attributeName, value);
    if (attributeName === "agents_order") {
      this._updateAgentSelector();
    }
  },
  _updateAttribute: function (attributeName, value) {
    this.settings.set(attributeName, this._convert[attributeName](value));
    this.settings.save();
  },
  _updateViewSelectors: function () {
    this._updateWeekdaySelector();
    this._updateTimeSelector();
    this._updateAgentSelector();
    this._updateOrderSelector();
  },
  _updateWeekdaySelector: function () {
    this._setupWeekdaySelector(this.settings.get('newsletter_days'));
  },
  _updateTimeSelector: function () {
    var duration = moment.duration(1, 'hour');
    var value = moment().startOf('day').add(this.settings.get('newsletter_hour'), 'hour');
    this._setupTimeSelector(value, duration);
  },
  _updateAgentSelector: function () {
    var selectedAgents = this._buildSelectedAgentsCollection();
    var order = this.settings.get('agents_order');
    this._setupAgentSelector(agents, selectedAgents, order);
    this.listenTo(selectedAgents, 'add remove', () => {
      this._updateAttribute('newsletter_agent_ids', selectedAgents);
    });
  },
  _updateOrderSelector: function () {
    this.view.updateOrderSelector(this.settings.get('agents_order'));
  },
  _convert: {
    'newsletter_days': function (days) { return days; },
    'newsletter_hour': function (mnt) { return mnt.hours(); },
    'newsletter_agent_ids': function (selectedAgents) { return selectedAgents.pluck('id'); },
    'agents_order': function (order) { return order; }
  },
  _updateOnChange: function (controller, attributeName) {
    this.listenTo(controller, 'change', (newValue) => {
      this._updateAttribute(attributeName, newValue);
    });
  },
  _setupWeekdaySelector: function (days) {
    this.weekdaySelectorController = new WeekdaySelectorController(days);
    this.view.weekdaySelector.show(this.weekdaySelectorController.view);
    this._updateOnChange(this.weekdaySelectorController, 'newsletter_days');
  },
  _setupTimeSelector: function (value, duration) {
    this.timeSelectorController = new TimeSelectorController({
      value: value,
      duration: duration
    });
    this.view.timeSelector.show(this.timeSelectorController.view);
    this._updateOnChange(this.timeSelectorController, 'newsletter_hour');
  },
  _setupAgentSelector: function (agents, selectedAgents, order) {
    this.agentSelectionController = new AgentSelectionController({
      agents: agents,
      selected_agents: selectedAgents,
      order: order
    });
    this.view.agentSelector.show(this.agentSelectionController.view);
    this._updateOnChange(this.agentSelectionController, 'newsletter_agent_ids');
  }
});

export default NotificationSummaryController;
