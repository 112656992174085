import RestorableModel from 'libraries/restorableModel'
import 'backbone.model.file-upload'

var ImportedClip = RestorableModel.extend({
  urlRoot: '/api/v3/imported_clips',
  parse: function (response) {
    var data = response.object ? response.object : response;
    return data;
  }
});

export default ImportedClip;

