import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/soprano_sda.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import I18n from 'i18n'

var SdaView = Marionette.ItemView.extend({
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    let metadata = {};

    const col1 = {}

    const language = this.agentResult.get('languages')[0];
    if (language) {
      col1['language'] =
        I18n.t("vendor.iso.languages." + language);
    }

    conditionalSetToMetadata(metadata, 'col1', col1);

    return metadata;
  }
});

export default SdaView;

