import * as React from "react";
import I18n from "i18n";
import * as jQuery from "jquery";
import * as _ from "underscore";
import Entity from 'models/entity'
import Spinner from './Spinner'

export interface Props {
  isLoading: boolean
  selection: Entity | undefined
  entities: Entity[]
  onClick: (entity: Entity) => void
  onActiveClick: (entity: Entity) => void
  onFilterChange: (input: string) => void
  searchPlaceholder: string
  filter: string
  initialLoad: boolean
}

export const Overview = (props: { headerLocale: string, children: any }) => {
  const header = I18n.t(props.headerLocale)
  return (
    <div className="pal">
      <div className="mbl">
        <h1 className="heading-xxlarge">
          {header}
        </h1>
      </div>
      {props.children}
    </div>
  )
}

interface State {
  filter: string
}

export class EntityList extends React.Component<Props, State> {
  private input: HTMLInputElement | null

  constructor(props: Props) {
    super(props)
    this.state = {
      filter: props.filter || ''
    }
    this.input = null
  }

  render() {
    const style: React.CSSProperties = {
      maxWidth: '800px'
    }
    return (
      <form> 
        <div className="button-group button-group--vertical mbm" style={style}>
          <div className="button-group__item-filter">
            <div className="button-group__item-lens"><i className="icon-lens"></i></div>
            <input autoFocus ref={(input) => { this.input = input}} type="text" className="button-group__item-filter__input text-input text-input--borderless" placeholder={this.props.searchPlaceholder} defaultValue={this.state.filter} onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => this.onKeyUp(e)} />
          </div>
          {this.entities()}
        </div>
      </form>
    )
  }

  onKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    const enteredText = e.currentTarget.value
    if (enteredText !== null) {
      this.setState(() => {
        return {
          filter: enteredText
        }
      })
      if (enteredText != this.state.filter) {
        this.props.onFilterChange(enteredText)
      }
    }
  }

  entities() {
    if (this.props.isLoading) {
      const label = I18n.t('webapp.components.entityList.loading')
      return (<Spinner label={label} />)
    } else {
      if ((this.props.entities.length == 0) && !this.props.initialLoad) {
        return this.noResults()
      } else {
        const markup = _.map(
          this.props.entities,
          (entity: Entity) => this.entityMarkup(entity)
        )
        return markup
      }
    }
  }

  noResults() {
    const css = "button-group__item is-disabled"
    const noResults = I18n.t('webapp.components.entityList.noResults')
    return (<a className={css}>{noResults}</a>)
  }

  entityMarkup(entity: Entity) {
    const name = entity.parseName()
    const key = 'entity-' + entity.id
    const active = this.isActive(entity)
    const css =
      "button-group__item button--pointing " +
      (this.isActive(entity) ? "is-active" : "")
    const clickHandler =
      active ? this.props.onActiveClick : this.props.onClick

    return (<a className={css} key={key} onClick={() => { clickHandler(entity)}}>{name}</a>)
  }

  isActive(entity: Entity) {
    return this.props.selection && (this.props.selection.id == entity.id)
  }
}

export default EntityList
