import _ from 'underscore';
import Backbone from 'backbone';
import contacts from 'collections/contactsInstance';
import contactGroups from 'collections/contactGroupsInstance';
import I18n from 'i18n';

class RecipientSelection extends Backbone.Model {
    initialize(attrs, options) {
        super.initialize(attrs);

        this._validateRecipients();

        this.options = options;
        this.on('change:selection', this.updateOptions);
        this.on('change:selection', this.checkForCompleteness);

        this.initializeSelection();
        this.updateOptions();

        if (!_.isUndefined(options)) {
            this.on('change:selection', this._runUpdateCallback);
        }
    }

    _validateRecipients() {
        var classMap = {
            'contact': contacts,
            'contact_group': contactGroups
        };

        _.each(this.get('recipients'), function(s) {
            var recipient = classMap[s.type].get(s.id);
            if (_.isUndefined(recipient)) {
                throw('Recipient list is invalid');
            }
        });
    }

    _runUpdateCallback() {
        let selection = this.get('selection');
        let recipients = this.selectionToRecipients(selection);
        this.options.updateCallback(recipients);
    }

    initializeSelection() {
        let recipients = this.get('recipients');
        if (recipients) {
            var selection = _.map(recipients, function (recipient) {
                return recipient.type + '-' + recipient.id;
            });

            this.set({selection: selection});
        }
    }

    checkForCompleteness() {
        var complete = !_.isEmpty(this.get('selection'));
        this.set({complete: complete});
    }

    selectionToRecipients(selection) {
        var classMap = {
            'contact': contacts,
            'contact_group': contactGroups
        };

        return _.map(selection, function (s) {
            var type, id;
            type = s.split('-')[0];
            id = parseInt(s.split('-')[1], 10);
            var recipient = classMap[type].get(id);

            return recipient;
        });
    }

    updateOptions() {
        this.set({options: this.availableOptions()});
    }

    availableOptions() {
        var options = [];
        options.push(this.contactGroupOptGroup());
        options.push(this.contactOptGroup());
        return options;
    }

    contactGroupOptGroup() {
        return {
            label: 'Empfängergruppen',
            options: this.markSelectedOptions(this.contactGroups())
        };
    }

    contactOptGroup() {
        return {
            label: 'Einzelpersonen',
            options: this.markSelectedOptions(this.contacts())
        };
    }

    contactGroups() {
        if (_.isUndefined(this.cachedContactGroupOptions)) {
            this.cachedContactGroupOptions = contactGroups.map(function (contactGroup) {
                var contactCount = contactGroup.get('contact_ids').length;
                var key = 'webapp.reader_admin.edit.recipient_contacts';
                var contactGroupCount = '(' +
                    I18n.t(key, { count: contactCount }) + ')';
                return {
                    id: 'contact_group-' + contactGroup.get('id'),
                    name: contactGroup.toString() + ' ' + contactGroupCount

                };
            });
        }

        return this.cachedContactGroupOptions;
    }

    contacts() {
        if (_.isUndefined(this.cachedContactOptions)) {
            this.cachedContactOptions = contacts.map(function (contact) {
                return {
                    id: 'contact-' + contact.get('id'),
                    name: contact.toString()
                };
            });
        }

        return this.cachedContactOptions;
    }

    markSelectedOptions(array) {
        return _.map(array, (option) => {
            var selected = _.include(this.get('selection'), option.id);
            return {
                id: option.id,
                name: option.name,
                selected: selected
            };
        }, this);
    }

    // The data method is used for clip count approximation in the
    // wizard. This step does not affect the number of clips, so
    // just returning an empty object.
    data() {
        return {};
    }

}

export default RecipientSelection;
