import _ from 'underscore'
import Marionette from 'backbone.marionette'
import vent from 'libraries/vent'
import I18n from 'i18n'
import AgentTopic from 'models/agentTopic'
import Users from 'collections/usersInstance'
import CreateSocialMediaMonitorController from 'controllers/agents/createSocialMediaMonitorController'
import SourceCategorySelectionController from 'controllers/agents/settings/sourceCategorySelectionController'
import AgentTopicController from 'controllers/agents/agentTopicController'
import NewAgentTopicController from 'controllers/agents/newAgentTopicController'
import EditAgentView from 'views/agents/settings/editAgentView'
import EditAgentLandingPageView from 'views/agents/settings/editAgentLandingPageView'
import DeletionModalView from 'views/deletionModalView'
import EditAgentTopicsController from 'controllers/agents/settings/editAgentTopicsController'
import EditAgentSourceSelectionController from 'controllers/agents/settings/editAgentSourceSelectionController'
import EditAgentSocialMediaMonitorController from 'controllers/agents/settings/editAgentSocialMediaMonitorController'
import EditAgentSettingsController from 'controllers/agents/settings/editAgentSettingsController'
import Notificator from 'libraries/notificator'
import sessionInstance from 'models/sessionInstance'
import { AgentsPermissions } from "components/agents/permissions";
import EditAgentUnsubscribeButtonController from 'controllers/agents/settings/editAgentUnsubscribeButtonController'
import AgentTimeframeController from "controllers/agents/agentTimeframeController";

export default class EditAgentController extends Marionette.Controller {
    initialize(options) {
        _.bindAll(this, '_saveFavoriteSourceGroups', '_saveSourceCategories', '_updateFavoriteSourceGroups');
        this.showRightHandContainer = options.showRightHandContainer;
        this.agent                  = options.agent;
        this.agent.fetch();
        this.agent.fetchTopics();
        this.agent.fetchSocialMediaMonitors();

        this.view  = new EditAgentView({
            agent: this.agent,
            user: Users.get(this.agent.get('user_id'))
        });

        this._registerListeners();
        this._showEditLandingPageView();
    }

    onClose() {
        this._setAgentCreationFinishedFlag();
    }

    _showEditLandingPageView() {
        this._deselectSelections();
        this.showRightHandContainer(new EditAgentLandingPageView());
        vent.trigger('layout:focus-left');
    }

    _showCreateSocialMediaMonitor() {
        this._deselectSelections();
        const createSMM = new CreateSocialMediaMonitorController(
            {
                agent: this.agent
            }
        );
        this.showRightHandContainer(createSMM.view);
        vent.trigger('layout:focus-right');
        this.listenTo(createSMM, 'social_media_monitor:saved', this._showEditLandingPageView);
        this.listenTo(createSMM, 'social_media_monitor:cancelled', this._showEditLandingPageView);
        this.listenTo(createSMM, 'social_media_monitor:limitReached', this._reactToTopicSocialMediaMonitorLimitReached);
        this.listenTo(createSMM, 'social_media_monitor:globalLimitReached', this._reactToGlobalTopicSocialMediaMonitorLimitReached);
        this.listenTo(createSMM, 'social_media_monitor:userNotFound', this._reactToTopicSocialMediaMonitorUserNotFound);
        this.listenTo(createSMM, 'social_media_monitor:invalidUsername', this._reactToTopicSocialMediaMonitorInvalidUsername);
    }

    _showSourceGroupAndCategories() {
        this._showSourceCategories();
        this._showFavoriteSourceGroups();
    }

    _showFavoriteSourceGroups() {
        this.listenTo(
            this.categoryController.sourceGroupController,
            "changed:favoriteSourceGroups",
            this._updateFavoriteSourceGroups
        );
    }

    _showSourceCategories() {
        const feature =
          sessionInstance.hasFeature('search_agent_topics_with_expert_search');
        let excludedCategoryIdentifiers = [];
        if (feature) {
          excludedCategoryIdentifiers = ['sm', 'rtv']
        }
        this._deselectSelections({ except: ['SourceSelections'] });
        const sourceExclusions = this.agent.get('source_exclusions');
        this.categoryController = new SourceCategorySelectionController({
            sourceCategoryIds: this.agent.get('source_category_ids'),
            sourceExclusions: sourceExclusions,
            excludedCategoryIdentifiers: excludedCategoryIdentifiers,
            agent: this.agent
        });

        this.listenTo(
            this.categoryController,
            "changed:sourceCategories",
            this._updateSourceCategories
        );
        this.showRightHandContainer(this.categoryController.view);
        vent.trigger('layout:focus-right');
    }

    _showEditTopic(topic) {
        this._deselectSelections();
        const agentTopicController = this._topicController(topic);
        this.listenTo(agentTopicController, 'topic:saved',      this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:cancelled',  this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:deleted',    this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:render', this._reRenderTopicForm);
        this.showRightHandContainer(agentTopicController.view);
        vent.trigger('layout:focus-right');
    }

    _reRenderTopicForm(topic, props) {
        const agentTopicController = this._topicController(topic, props);
        this.listenTo(agentTopicController, 'topic:saved',      this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:cancelled',  this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:deleted',    this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:render', this._reRenderTopicForm);
        this.listenTo(agentTopicController, 'topic:limitReached', this._reactToTopicSocialMediaMonitorLimitReached);
        this.listenTo(agentTopicController, 'topic:globalLimitReached', this._reactToGlobalTopicSocialMediaMonitorLimitReached);
        this.showRightHandContainer(agentTopicController.view);
        vent.trigger('layout:focus-right');
    }

    _reactToTopicSocialMediaMonitorLimitReached() {
        Notificator.showErrorNotification(
            I18n.t('webapp.notifications.error.limit_topics_monitors')
        );
    }

    _reactToGlobalTopicSocialMediaMonitorLimitReached() {
        Notificator.showErrorNotification(
            I18n.t('webapp.notifications.error.global_limit_topics_monitors')
        );
    }

    _reactToTopicSocialMediaMonitorUserNotFound() {
        Notificator.showErrorNotification(
            I18n.t('webapp.notifications.error.user_does_not_exist')
        );
    }

    _reactToTopicSocialMediaMonitorInvalidUsername() {
        Notificator.showErrorNotification(
            I18n.t('webapp.notifications.error.invalid_googleplus_username')
        );
    }

    _topicController(topic, props) {
        const feature =
          sessionInstance.hasFeature('search_agent_topics_with_expert_search');
        if (feature) {
          return new NewAgentTopicController({
            agent: this.agent,
            topic: topic,
            props: props
          });
        } else {
          return new AgentTopicController({ agent: this.agent, topic: topic });
        }
    }

    _showNewTopic() {
        this._deselectSelections();
        const agentTopicController = this._topicController(
          new AgentTopic({agent: this.agent})
        );
        this.listenTo(agentTopicController, 'topic:saved',      this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:cancelled',  this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:deleted',    this._closeTopicForm);
        this.listenTo(agentTopicController, 'topic:render', this._reRenderTopicForm);
        this.listenTo(agentTopicController, 'topic:limitReached', this._reactToTopicSocialMediaMonitorLimitReached);
        this.listenTo(agentTopicController, 'topic:globalLimitReached', this._reactToGlobalTopicSocialMediaMonitorLimitReached);
        this.showRightHandContainer(agentTopicController.view);
        vent.trigger('layout:focus-right');
    }

    _updateFavoriteSourceGroups(sourceGroups, isActive) {
        const currentSourceGroupIds = this.agent.get('source_group_ids');
        const updatedSourceGroupIds = _.pluck(sourceGroups, 'id');
        let selectedSourceGroupIds = [];
        if (isActive) {
          selectedSourceGroupIds =
            _.union(currentSourceGroupIds, updatedSourceGroupIds);
        } else {
          selectedSourceGroupIds =
            _.difference(currentSourceGroupIds, updatedSourceGroupIds);
        }
        this.agent.set({source_group_ids: selectedSourceGroupIds});
        // start save countdown or reset it
        if (!_.isUndefined(this.updateFavoriteSourceGroupsTimer)) {
            clearTimeout(this.updateFavoriteSourceGroupsTimer);
        }
        this.updateFavoriteSourceGroupsTimer =
            setTimeout(() => {
                this._saveFavoriteSourceGroups();
            }, 1500);
        this.editAgentSourceSelectionView.render();
    }

    _updateSourceCategories(sourceCategoryIds) {
        this.agent.set({source_category_ids: sourceCategoryIds});

        // start save countdown or reset it
        if (!_.isUndefined(this.updateSourceCategoriesTimer)) {
            clearTimeout(this.updateSourceCategoriesTimer);
        }
        this.updateSourceCategoriesTimer =
        setTimeout(this._saveSourceCategories, 1500);
        this.editAgentSourceSelectionView.render();
    }

    _saveFavoriteSourceGroups() {
        const res = this.agent.save(null, { updateOnly: 'source_groups' });
        res.error(function () {
            Notificator.showErrorNotification(I18n.t('webapp.agents.edit.agent_subscriptions.request_error'));
        })
    }

    _saveSourceCategories() {
        const res = this.agent.save(null, { updateOnly: 'source_category_ids' });
        res.error(function () {
            Notificator.showErrorNotification(I18n.t('webapp.agents.edit.agent_subscriptions.request_error'));
        })

    }

    _closeTopicForm() {
        this._deselectSelections();
        this._showEditLandingPageView();
    }

    _deselectSelections(opts) {
        let options = _.extend({}, { except: [] }, opts);

        this._deselectTopicListSelections();
        this._deselectSocialMediaMonitorSelections();
        if (_.contains(options.except, 'SourceSelections')) {
            if (!_.isUndefined(this.editAgentSourceSelectionController)) {
                this.editAgentSourceSelectionController.deselectTimeframeSelection();
            }
        }
        else if (_.contains(options.except, 'TimeframeSelections')) {
            if (!_.isUndefined(this.editAgentSourceSelectionController)) {
                this.editAgentSourceSelectionController.deselectSourceSelections();
            }
        } else {
            if (!_.isUndefined(this.editAgentSourceSelectionController)) {
                this.editAgentSourceSelectionController.deselectAll();
            }
        }
    }

    _deselectTopicListSelections() {
        if (!_.isUndefined(this.editAgentTopicsController)) {
            this.editAgentTopicsController.deselectAll();
        }
    }

    _deselectSocialMediaMonitorSelections() {
        if (!_.isUndefined(this.editAgentSocialMediaMonitorController)) {
            this.editAgentSocialMediaMonitorController.deselectAll();
        }
    }

    _registerListeners() {
        this.listenTo(this.view, 'render', () => {
            this._showEditAgentTopics();
            this._showEditAgentSourceSelection();
            this._showSocialMediaMonitors();
            this._showAgentSettings();
            this._showEditableTitle();
            this._updateUnSubscribeButtonVisibility();
        });

        this.listenTo(this.agent, 'changed:name', () => {
            this.agent.save(null, {updateOnly: 'name'});
        });

        this.listenTo(this.agent, 'changed:shared', this._updateAgentSharedValue);

        this.listenTo(this.agent, 'change:subscribed', () => {
            this._updateUnSubscribeButtonVisibility();
        });

      this.listenTo(this.agent, 'change:possible_values_for_shared_attribute', () => {
        this._showAgentSettings();
      })
      this.listenTo(this.view, 'showModal',
        this._maybeShowAgentDeletion);
    }

    _updateAgentSharedValue() {
        this.agent.store();
        this.agent.save(null, { updateOnly: 'shared' }).fail((res) => {
            Notificator.showNotification(I18n.t('webapp.agents.edit.agent_subscriptions.request_error'));
            if (res.status === 409) {
                this.agent.fetch();
            } else {
                this.agent.restore();
            }
        });
        this.view.render();
    }

    _setAgentCreationFinishedFlag() {
        this.agent.markAsCreated();
    }

    _showUnsubscribeButton() {
        const controller = new EditAgentUnsubscribeButtonController({
            agent: this.agent
        });
        this.view.showEditAgentUnsubscribeButton(controller.view);
    }

    _updateUnSubscribeButtonVisibility() {
      if (sessionInstance.hasFeature('new_search_agent_management') &&
          this.agent.get('subscribed')) {
        this._showUnsubscribeButton()
      } else {
        if (this.view) {
          this.view.hideUnSubscribeButton()
        }
      }
    }

    _showEditableTitle() {
        this.view.showEditableTitle({
            title: this.agent.get('name'),
            titleFieldName: 'name',
            model: this.agent
        });
    }

    _showEditAgentTopics() {
        this.editAgentTopicsController =
            new EditAgentTopicsController({
                agent: this.agent
            });

        this.editAgentTopicsView =
            this.editAgentTopicsController.view;

        this.view.showTopics(this.editAgentTopicsView);

        this.listenTo(
            this.editAgentTopicsController,
            'clicked:topic',
            this._showEditTopic
        );

        this.listenTo(
            this.editAgentTopicsController,
            'add:topic',
            this._showNewTopic
        );

        this.listenTo(
            this.editAgentTopicsController,
            'topics:changed',
            this._onTopicSocialMediaMonitorChanged
        );
    }

    _showEditAgentSourceSelection(timeframeProps) {
        this.editAgentSourceSelectionController =
            new EditAgentSourceSelectionController({
                agent: this.agent,
                timeframeProps: timeframeProps
            });

        this.editAgentSourceSelectionView =
            this.editAgentSourceSelectionController.view;

        this.listenTo(
            this.editAgentSourceSelectionView,
            'show:standard:sources',
            this._showSourceGroupAndCategories);

        this.listenTo(
            this.editAgentSourceSelectionController,
            'open:agentTimeframe',
            this._showEditAgentTimeframe
        );

        this.listenTo(
            this.editAgentSourceSelectionView,
            'close:agentTimeframe',
            this._showEditLandingPageView
        );

        this.view.showSourceSelection(this.editAgentSourceSelectionView);
    }

    _showSocialMediaMonitors() {
        this.editAgentSocialMediaMonitorController =
            new EditAgentSocialMediaMonitorController({
                agent: this.agent
            });

        this.editAgentSocialMediaMonitorView =
            this.editAgentSocialMediaMonitorController.view;

        this.view.showSocialMediaMonitors(this.editAgentSocialMediaMonitorView);

        this.listenTo(
            this.editAgentSocialMediaMonitorController,
            'clicked:social_media_monitor',
            this._showSocialMediaMonitorPage
        );

        this.listenTo(
            this.editAgentSocialMediaMonitorController,
            'add:smm',
            this._showCreateSocialMediaMonitor
        );

        this.listenTo(
            this.editAgentSocialMediaMonitorController,
            'social_media_monitors:changed',
            this._onTopicSocialMediaMonitorChanged
        );

        this._onTopicSocialMediaMonitorChanged();
    }

    _onTopicSocialMediaMonitorChanged() {
        sessionInstance.fetch().done(() => {
            if (this.agent.topicSocialMediaMonitorLimitReached() || sessionInstance.globalTopicSmtLimitReached()) {
                this.editAgentSocialMediaMonitorController.removeAddButton();
                this.editAgentTopicsController.removeAddButton();
            } else {
                this.editAgentSocialMediaMonitorController.addAddButton();
                this.editAgentTopicsController.addAddButton();
            }
        })
    }

    _showAgentSettings() {
        this.editAgentSettingsController =
            new EditAgentSettingsController({
                agent: this.agent,
                readOnly: !AgentsPermissions.canChangeAgentVisibility(this.agent)
            });

        this.editAgentSettingsView =
            this.editAgentSettingsController.view;

        this.view.showEditAgentSettings(this.editAgentSettingsView);
    }

    _showEditAgentTimeframe() {
        this._deselectSelections({ except: ['TimeframeSelections'] });
        this.agentTimeframeController = new AgentTimeframeController({
            agent: this.agent
        });
        this.listenTo(
            this.agentTimeframeController,
            "failed:timeframeSave",
            this._showEditAgentTimeframe
        );
        this.listenTo(
            this.agentTimeframeController,
            "successful:timeframeSave",
            () => {
                this._showEditAgentSourceSelection({ isActive: true });
                this._showEditAgentTimeframe();
            }
        );
        this.showRightHandContainer(this.agentTimeframeController.view());
        vent.trigger('layout:focus-right');
    }

    _maybeShowAgentDeletion() {
        if (!sessionInstance.hasFeature("new_search_agent_management")) {
            if (this.agent.isOwnAgent()) {
                this._showAgentDeletion()
            }
        } else {
            if (this.agent.isOwnAgent() ||
                sessionInstance.isGroupAdmin) {
                this._showAgentDeletion()
            }
        }
    }

    _showAgentDeletion() {
        this.deletionView = new DeletionModalView({
            confirmation: I18n.t('webapp.agents.edit.settings.delete.delete_confirmation',
                                 { name: this.agent.get('name')}),
            warning : I18n.t('webapp.agents.edit.settings.delete.delete_warning'),
            deleteLabel : I18n.t('webapp.agents.edit.settings.delete.delete'),
            cancelLabel : I18n.t('webapp.agents.edit.settings.delete.cancel')
        });
        this.listenTo(this.deletionView, 'modal:delete', this._deleteAgent);
        this.view.showDeletion(this.deletionView);
    }

    _showSocialMediaMonitorPage(smm) {
        this._deselectSelections();
        this.socialMediaMonitorController = new CreateSocialMediaMonitorController({
            socialMediaMonitor: smm
        });

        this.socialMediaMonitorView = this.socialMediaMonitorController.view;

        this.showRightHandContainer(this.socialMediaMonitorView);
        vent.trigger('layout:focus-right');

        this.listenTo(
            this.socialMediaMonitorController,
            'deleted:social_media_monitor',
            this._showEditLandingPageView
        );
    }

    _deleteAgent() {
        this.agent.set('creation_finished', true);
        this.agent.destroy();
        this.trigger('deleted:agent');
    }
}
