import Marionette from "backbone.marionette";
import SourceCategorySelectionFormView from "views/agents/sourceCategorySelectionFormView";

class SourceCategorySelectionFormController extends Marionette.Controller {
  initialize() {
    this.selectedCategories = this.options.sourceCategoryIds;
    this.view = new SourceCategorySelectionFormView(this.options);
    this._registerEventListeners();
  }

  setDefaultCategories(options = {silent: false}) {
    this.view.checkAll({ silent: options.silent });
    if (!options.silent) {
      this._handleSourceCategoryChange();
    }
  }

  selectedCategoryIds() {
    this.selectedCategories = this.view.getEnabledCheckboxes();
    this.selectedSubSubCategories = this.view.getSubSubCategories();
    var categoryIds = this.selectedCategories.concat(
      this.selectedSubSubCategories
    );
    return categoryIds;
  }

  _registerEventListeners() {
    this.listenTo(this.view, 'render', () => {
      this.view.initializeCheckboxes(this.selectedCategories);
      this.view.initializeSubSubCategoryDropdowns(this.selectedCategories);
    });
    this.listenTo(this.view, 'changed:sourceCategory', this._handleSourceCategoryChange);
  }

  _handleSourceCategoryChange() {
    this.trigger("changed:sourceCategories", this.selectedCategoryIds());
  }
}

export default SourceCategorySelectionFormController;
