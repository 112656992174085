import Backbone from 'backbone'

var ExcelContentSettings = Backbone.Model.extend({
  identifier: 'excelContentSettings',
  initialize: function () {
    this.set({
      include_clips_from_several_sources: this.get('report').get('include_clips_from_several_sources'),
      only_datasheet: this.get('report').get('only_datasheet')
    });
    this.on('change:only_datasheet', () => {
      this.get('report').set({
        only_datasheet: this.get('only_datasheet')
      });
    });
    this.on('change:include_clips_from_several_sources', () => {
      this.get('report').set({
        include_clips_from_several_sources: this.get('include_clips_from_several_sources')
      });
      this.checkForCompleteness();
    });
    this.checkForCompleteness();
  },

  data: function () {
    return {
      include_clips_from_several_sources: this.get('include_clips_from_several_sources')
    };
  },

  hasPrintClipsInFullLength: function () {
    return false;
  },

  hasGraph: function () {
    return false;
  },

  hasSnippets: function () {
    return false;
  },

  checkForCompleteness: function () {
    this.set({complete: true});
  }
});

ExcelContentSettings.hasContentSettings = function () {
  return true;
};

export default ExcelContentSettings;

