import * as React from "react";
import StaticWidget from "./StaticWidget";
import widgets, { WidgetStore, VisType, Widget } from "../redux/widgets";
import { connect } from "react-redux";
import { Store } from "../redux/store";
import { Responsive } from "react-grid-layout";
import WordCloud, { WordCloudItem } from "./WordCloud";
import TableWidget, { SearchAgentItem } from "./TableWidget";
import WidgetErrorMsg from "./WidgetErrorMsg";
import LineChart, { LineChartResponseItem } from "./LineChartWidget";
import NewstickerWidget, { NewstickerItem } from "./NewstickerWidget";
import { Dashboard } from "../redux/dashboards";
import { withSize } from "react-sizeme";
import WidgetContainer from './WidgetContainer';
import UnexpectedErrorMessage from "./UnexpectedErrorMessage";
import { BugsnagErrorBoundary } from "../../../libraries/bugsnag";

interface Props {
  widgets: WidgetStore;
  dashboards: Dashboard[];
  size: { width: number; height: number };
}

class WidgetSpace extends React.PureComponent<Props> {
  render() {
    const { widgets, dashboards } = this.props;
    return (
      <div className="layout-content layout-content__dashboard greyed-container">
        <div className="dashboard-grid">
          <BugsnagErrorBoundary FallbackComponent={UnexpectedErrorMessage}> 
            {showWidget(widgets, dashboards, {width: this.props.size.width})}
            </BugsnagErrorBoundary>
        </div>
      </div>
    );
  }
}

function showWidget(widgets: WidgetStore, dashboards: Dashboard[], size: { width: number }) {
  const layouts = {
    lg: widgets.value.map(({ x, y, h, w, title }) => ({
      x,
      y,
      i: title,
      h,
      w,
      isDraggable: false,
      isResizable: false
    }))
  };
  if (widgets.value.length > 0) {
    return (
      <Responsive
        width={size.width}
        rowHeight={120}
        margin={[20, 20]}
        layouts={layouts}
        cols={{ lg: 4, md: 4, sm: 4, xs: 1, xxs: 1 }}>
        {widgets.value.map(w => renderWidgetContainer(w))}
      </Responsive>
    );
  } else if (!widgets.isNetworkReqOngoing && dashboards.length > 0) {
    if (widgets.error) {
      return <UnexpectedErrorMessage />
    } else {
      return <WidgetErrorMsg />
    }
  }
  return null;
}

function renderWidgetContainer(w: Widget) {
  return (
    <WidgetContainer key={w.title} title={w.title}>
      {renderWidget(w)}
    </WidgetContainer>
  )
}

function renderWidget(w: Widget) {
  switch (w.visualizationType) {
    case VisType.Newsticker:
      return (
        <NewstickerWidget
          key={w.title}
          title={w.title}
          data={w.data as NewstickerItem[]}
        />
      );
    case VisType.Static:
      return (
        <StaticWidget key={w.title}>
          {`<div class="dashboard-widget__padding-static"><h1 class="dashboard-widget__headline">${
            w.title
            }</h1>${w.data as string}</div>`}
        </StaticWidget>
      );
    case VisType.Table:
      return (
        <TableWidget
          key={w.title}
          title={w.title}
          data={w.data as SearchAgentItem[]}
        />
      );
    case VisType.WordCloud:
      return (
        <WordCloud
          key={w.title}
          header={w.title}
          dataSeries={w.dataSeries}
          data={w.data as WordCloudItem[][]}
        />
      );

    case VisType.LineChart:
      return (
        <LineChart
          key={w.title}
          header={w.title}
          dataSeries={w.dataSeries}
          data={w.data as LineChartResponseItem[][]}
          />   
      );

    default:
      return <div key={w.title}>{w.title}</div>;
  }
}

export default connect(function({ widgets }: Store) {
  return { widgets };
})(withSize()(WidgetSpace));
