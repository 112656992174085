/*global require, tinyMCE */

import '@babel/polyfill'
import App from 'app'
import Login from 'login'
import Analytics from 'libraries/analytics'
import 'modules/reporting'
import 'modules/dashboard'
import 'modules/newDashboard'
import 'modules/tableauDashboard'
import 'modules/agents'
import 'modules/newAgents'
import 'modules/research'
import 'modules/settings'
import 'modules/newsroom'
import 'modules/notifications'
import 'modules/navigation'
import 'modules/styleguide'
import 'modules/sandbox'
import 'modules/relativeTimes'
import 'modules/shortcuts'
import 'modules/shortcutsHelp'
import 'modules/commonInitializer'

Analytics.init();

Login.start();
Login.whenLoggedIn(function () {
  Analytics.userTiming('br_startup_time', App.Agents.loadedPromise());
  App.loadSession().done(function () {
    App.start();
  });
});
