import Backbone from 'backbone'
import Deferred from 'libraries/deferred'
import Logger from 'libraries/logger'

/*
 * History is is a module to start the Backbone history as soon as
 * everyone is ready for it.
 *
 * As the Backbone history should only be started after all routers
 * have been created, we need to delay starting it. Components can
 * register themselves and then notify the History when they are
 * ready. As soon as all components are ready, the Backbone history is
 * started.
 *
 * A component registers itself by calling History.waitForMe(), which
 * returns an object that should be stored. When the component is
 * ready, it should call read() on that object.
 *
 * The application should call History.startWhenReady() after all
 * components have registered themselves. Components cannot register
 * anymore after this method has been called.
 */
var History = {};
var components = [];
var started = false;

History.waitForMe = function () {
  if (started) {
    Logger.error('Component tried to register after history was already started');
  } else {
    var component = Deferred.build();
    components.push(component);
    var res = {
      ready: function () {
        component.resolve();
      }
    };
    return res;
  }
};

function promiseForAllComponents() {
  return Deferred.when(...components);
}


History.startWhenReady = function () {
  started = true;
  var promise = promiseForAllComponents();

  promise.done(function () {
    Logger.debug('Starting Backbone history');
    Backbone.history.start();
  });
  promise.fail(function () {
    Logger.error('Could not start Backbone history because a component failed to load');
  });
};

export default History;

