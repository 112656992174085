import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/trendingTopicsOnAgentClipsWidget.html'
import _ from 'underscore'

WidgetView.TrendingTopicsOnAgentClipsWidget = WidgetView.extend({
  template: template,
  onInitialize: function () {
    this.enableAutoRefresh();
  },
  prepareWidgetBody: function () {
  },
  prepareSerializedData: function () {
    var tags = this.model.get('trending_tags');
    var noTagsClass = _.isEmpty(tags) ? ' no-tags' : '';

    _.each(tags, function (tag) {
      if (tag.count > 10) {
        tag.width = '100%';
      } else {
        tag.width = (10 * tag.count) + '%';
      }
    });

    return {
      tags: tags,
      total: this.model.get('total'),
      no_tags_class: noTagsClass
    };
  }
});


export default WidgetView.TrendingTopicsOnAgentClipsWidget;

