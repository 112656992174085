import Marionette from 'backbone.marionette'
import _ from 'underscore'
import Layout from 'views/layout'
import HeaderView from 'views/headerView'
import NavigationController from 'controllers/navigationController'
import GlobalSearchController from 'controllers/globalSearchController'
import LayoutContentResizer from 'modules/layoutContentResizer'
import vent from 'libraries/vent'

var LayoutController = Marionette.Controller.extend({
  initialize: function (options) {
    this.options = options;
    _.bindAll(this, 'setContent', 'showLegacyContent', 'appendToToolbar');

    this._createViews();
    this._registerListeners();
    this._registerCommandHandlers();
  },
  _createLayout: function () {
    this.layout = new Layout();
    this.layout.render();

    this.layout.addBodyClass('migrated-to-new-agents');
  },
  _createViews: function () {
    this._createLayout();
    this.headerView = new HeaderView();
    this.navigationController = new NavigationController();
    this.globalSearchController = new GlobalSearchController();
    this.globalSearchController.hide();
  },
  _registerListeners: function () {
    this._registerLayoutListener();
  },
  _registerLayoutListener: function () {
    this.listenTo(vent, 'layout:focus-left', () => {
      this.layout.focusLeft();
    });
    this.listenTo(vent, 'layout:focus-right', () => {
      this.layout.focusRight();
    });
    this.listenTo(this.layout, 'show-sidebar', () => {
      this.layout.showSidebar();
    });
    this.listenTo(this.layout, 'close-right-column', () => {
      vent.trigger('layout:focus-left');
    });

    this.listenTo(vent, 'navigation:activated', () => {
      this.layout.disableSidebarOverlay();
    });
    this.listenTo(vent, 'layout:focus-left', () => {
      this.layout.disableSidebarOverlay();
    });
    this.listenTo(vent, 'layout:focus-right', () => {
      this.layout.disableSidebarOverlay();
    });
  },
  _handleWindowResize: function () {
    window.onresize = LayoutContentResizer.resizeContent;
  },
  _registerCommandHandlers: function () {
    vent.commands.setHandler('setContent', this.setContent);
    vent.commands.setHandler('showLegacyContent', this.showLegacyContent);
    vent.commands.setHandler('toolbar:append', this.appendToToolbar);
  },
  setContent: function (view) {
    this.layout.showContent(view);
  },
  show: function (region) {
    this.layout.hide();
    region.show(this.layout);
    this._showNavigation();
    this._showHeader();
    this._showGlobalSearch();
    this.layout.show();
    this._handleWindowResize();
  },
  _showNavigation: function () {
    this.layout.navigation.show(this.navigationController.view);
  },
  _showHeader: function () {
    this.headerView = new HeaderView();
    this.layout.header.show(this.headerView);
  },
  _showGlobalSearch: function () {
    this.layout.globalSearch.show(this.globalSearchController.view);
  },
  // legacyContent is the region for all the old modules
  showLegacyContent: function () {
    this.layout.showLegacyContent();
  },
  refreshHeader: function () {
    this._showHeader()
  },
  appendToToolbar: function (element) {
    this.layout.appendToToolbar(element);
  }
});

export default LayoutController;
