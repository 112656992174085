import Marionette from "backbone.marionette";
import template from "text-loader!templates/research/searchSettings.html";
import _ from "underscore";

class SearchSettingsView extends Marionette.Layout {
  initialize() {
    _.bindAll(this, 'switchToTopics', 'switchToSourceCategories');
  }

  showTopic(view) {
    this.topicForm.show(view);
    this.listenTo(view, 'enter-pressed', () => {
      this.trigger('clicked:search');
    });
  }

  showSourceCategories(view) {
    this.sourceCategoriesForm.show(view);
  }

  switchToTopics() {
    this.activeTab = this.ui.topicsTab;
    this.ui.topicsTab.addClass('is-active');
    this.topicForm.$el.show();
    this.ui.sourceCategoriesTab.removeClass('is-active');
    this.sourceCategoriesForm.$el.hide();
  }

  switchToSourceCategories() {
    this.activeTab = this.ui.sourceCategoriesTab;
    this.ui.sourceCategoriesTab.addClass('is-active');
    this.sourceCategoriesForm.$el.show();
    this.ui.topicsTab.removeClass('is-active');
    this.topicForm.$el.hide();
  }

  deactivateTabs() {
    this.ui.tabsContent.hide();
    this.ui.sourceCategoriesTab.removeClass('is-active');
    this.ui.topicsTab.removeClass('is-active');
  }

  activateTabs() {
    this.ui.tabsContent.show();
    this.activeTab.addClass('is-active');
  }

  showInDetailView(view) {
    this.detailView.show(view);
  }

  showLivePreview(view) {
    this.livePreview.show(view);
  }

}

SearchSettingsView.prototype.template = template;
SearchSettingsView.prototype.regions = {
  topicForm: '[data-topic]',
  sourceCategoriesForm: '[data-source-categories]',
  detailView: '[data-detail-view]',
  livePreview: '[data-research-live-preview]',
};
SearchSettingsView.prototype.triggers = {
  'click [data-topics-tab] > a': 'clicked:topicsTab',
  'click [data-source-categories-tab] > a': 'clicked:sourceCategoriesTab',
  'click [data-button-reset]': 'clicked:reset',
  'click [data-button-search]': 'clicked:search'
};
SearchSettingsView.prototype.ui = {
  topicsTab: '[data-topics-tab]',
  sourceCategoriesTab: '[data-source-categories-tab]',
  tabsContent: '[data-tabs-content]'
};

export default SearchSettingsView;
