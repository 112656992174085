import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/reporting/wizard/stepLayout.html'

var StepLayout = Marionette.Layout.extend({
  template: template,
  regions: {
    step: '#step'
  }
});

export default StepLayout;

