import _ from 'underscore'
import StepLayout from 'views/reporting/wizard/stepLayout'
import Marionette from 'backbone.marionette'
import MedienspiegelEditTemplate from 'text-loader!templates/reporting/medienspiegelReportEditView.html'
import ExcelEditTemplate from 'text-loader!templates/reporting/excelReportEditView.html'
import NewsletterEditTemplate from 'text-loader!templates/reporting/newsletterEditView.html'
import ClipTopicSelectionView from 'views/reporting/wizard/clipTopicSelectionView'
import TopicSelectionView from 'views/reporting/wizard/topicSelectionView'
import TimespanSelectionView from 'views/reporting/wizard/timespanSelectionView'
import EditTitleSummaryView from 'views/reporting/wizard/editTitleSummaryView'
import FilterSortSettingsView from 'views/reporting/wizard/filterSortSettingsView'
import ContentSettingsView from 'views/reporting/wizard/contentSettingsView'
import ExcelContentSettingsView from 'views/reporting/wizard/excelContentSettingsView'
import NewsletterRecipientSelectionView from 'views/reporting/wizard/newsletterRecipientSelectionView'
import I18n from 'i18n'

var View = Marionette.Layout.extend({
  getTemplate: function () {
    var wizard = this.options.wizard;
    var report = wizard.get('report');
    switch (report.get('type')) {
    case 'Medienspiegel':
      return MedienspiegelEditTemplate;
    case 'ExcelReport':
      return ExcelEditTemplate;
    case 'Newsletter':
      return NewsletterEditTemplate;
    }
  },
  mapStepToView: function (step) {
    var opts = {model: step, scrollOff: true, showAllSteps: true, edit_mode: true};
    switch (step.identifier) {
    case 'clipTopicSelection':
      return (new ClipTopicSelectionView(opts));
    case 'topicSelection':
      opts.disabled = true;
      return (new TopicSelectionView(opts));
    case 'timespanSelection':
      opts.disabled = true;
      return (new TimespanSelectionView(opts));
    case 'editTitleSummary':
      return (new EditTitleSummaryView(opts));
    case 'newsletterRecipientSelection':
      opts.disabled = false;
      return (new NewsletterRecipientSelectionView(opts));
    case 'filterSortSettings':
      return (new FilterSortSettingsView(opts));
    case 'contentSettings':
      return (new ContentSettingsView(opts));
    case 'excelContentSettings':
      return (new ExcelContentSettingsView(opts));
    }
  },
  className: "wizard-form-container",
  regions: {
    clipTopicSelection: '#clipTopicSelection',
    topicSelection: '#topicSelection',
    timespanSelection: '#timespanSelection',
    editTitleSummary: '#editTitleSummary',
    filterSortSettings: '#filterSortSettings',
    newsletterRecipientSelection: '#newsletterRecipientSelection',
    contentSettings: '#contentSettings',
    excelContentSettings: '#excelContentSettings'
  },
  triggers: {
    'click .wizard-save': 'clicked:save',
    'click .cancel': 'clicked:cancel'
  },
  initialize: function () {
    var wizardView = this;

    this.addEventsToUpdateSaveButtonState();

    this.on('updateSaveButtonState', this.updateSaveButtonState);

    this.on('clicked:save', () => {
      this.$('.wizard-save').attr('disabled', 'disabled');
      this.$('.wizard-save').val(I18n.t('webapp.reporting.wizard.interact.please_wait'));
      this.options.wizard.save();
    });

    this.on('clicked:cancel', () => {
      this.options.wizard.get('report').open();
    });

    this.options.wizard.on('saveError', () => {
      wizardView.$('.wizard-save').removeAttr('disabled');
      wizardView.$('.wizard-save').val(I18n.t('webapp.reporting.wizard.interact.save'));
    });

    this.on('item:before:close', () => {
      var report = this.options.wizard.get('report');
      if (report.editing_in_progress) {
        report.restore();
      }
      report.editing_in_progress = false;
    });
  },
  addEventsToUpdateSaveButtonState: function () {
    var editView = this;
    var wizard = editView.options.wizard;

    // Could not figure out how to make this work with
    // wizard change:complete. Maybe I missed something obvious
    // but time was too limited.
    _.each(wizard.stepModels, function (stepModel) {
      stepModel.on('change:complete', () => {
        editView.updateSaveButtonState();
      });
    });
  },
  updateSaveButtonState: function () {
    var wizard = this.options.wizard;
    var stepsComplete = false;

    // Could not figure out how to make this work with
    // wizard.get("complete"). Maybe I missed something obvious
    // but time was too limited.
    stepsComplete = _.all(wizard.stepModels, function (step) {
      step.checkForCompleteness();
      return step.get("complete");
    });

    if (stepsComplete) {
      this.enableSaveButton();
    } else {
      this.disableSaveButton();
    }
  },
  enableSaveButton: function () {
    this.$('.wizard-save').removeAttr('disabled');
  },
  disableSaveButton: function () {
    this.$('.wizard-save').attr('disabled', 'disabled');
  },
  createStepViews: function () {
    // automagically create the views for the steps
    _.each(this.options.wizard.stepModels, (step) => {
      var view = this.mapStepToView(step);
      if (view) {
        var stepLayout = new StepLayout();
        this[step.identifier].show(view);
        stepLayout.step.show(view);
      }
    }, this);

    // show all steps at once
    this.$('.wizard-step').show();

    // hide 'Weiter' buttons
    this.$('.nextStep').hide();

    // show only last save button
    this.$('.wizard-save').hide();
    this.$('.wizard-save:last').show();

    this.$('.interact:last').show();
    this.$('.cancel:last').show();

  }
});

export default View;

