import Marionette from "backbone.marionette";
import template from "text-loader!templates/sourceExclusionItem.html";

class SourceExclusionItemView extends Marionette.ItemView {
  initialize() {
    this.listenTo(this.model, "change", this.render);
  }
}

SourceExclusionItemView.prototype.template = template;
SourceExclusionItemView.prototype.tagName = "li";
SourceExclusionItemView.prototype.triggers = {
    "click [data-action=remove]": "clicked:remove"
};

export default SourceExclusionItemView;

