import ModalView from 'views/components/modalView';
import I18n from 'i18n';

class RtvOrderNoQuotaModalView extends ModalView {
    initialize() {
        this.confirmation = this._modalTitle();
        this.headline = this._modalHeadline();
        this.warning = this._modalBody();
    }

    serializeData() {
        return {
            confirmation: this.confirmation,
            headline: this.headline,
            warning: this.warning,
            showHeader: true,
            showButtons: false
        };
    }

    _modalTitle() {
        return this._modalHeadline();
    }

    _modalHeadline() {
        return I18n.t(
            'webapp.agents.results.result_details.order_recording.headline_over_quota'
        );
    }

    _modalBody() {
        return I18n.t(
            'webapp.agents.results.result_details.order_recording.information_over_quota'
        );
    }
}

export default RtvOrderNoQuotaModalView;
