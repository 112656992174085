import Backbone from 'backbone'
import moment from 'moment'
import TimelineMonth from 'models/timeline/timelineMonth'
import TimelineMonths from 'collections/timeline/timelineMonths'

var timelineYear = Backbone.Model.extend({
  initialize: function (date) {
    this.set('date', date);
    this.months = new TimelineMonths();
  },
  fillMonths: function () {
    var date = this.get('date');
    var year = date.getFullYear();
    var now = new Date();
    this._addInitialMonth();
    for (var month = date.getMonth() + 1; month < 12; month++) {
      var monthStart = new Date(year, month, 1);
      if (now > monthStart) {
        this.months.add(new TimelineMonth(monthStart));
      } else {
        break;
      }
    }
  },
  isCurrent: function () {
    var today = new Date();
    return today.getFullYear() === this.get('date').getFullYear();
  },
  includes: function (day) {
    var d = moment(day.get('date'));
    var yearStart = moment(this.get('date'));
    var yearEnd = moment(yearStart).add(1, 'year');
    return (d.isSame(yearStart) || d.isAfter(yearStart)) && d.isBefore(yearEnd);
  },
  isBefore: function (day) {
    var d = moment(this.get('date'));
    return d.isBefore(day.get('date'), 'year');
  },
  isAfter: function (day) {
    var d = moment(this.get('date'));
    return d.isAfter(day.get('date'), 'year');
  },
  _addInitialMonth: function () {
    var date          = this.get('date');
    var initialDate   = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    var initialMonth  = new TimelineMonth(
      initialDate
    );
    this.months.add(initialMonth);
  }
});

export default timelineYear;

