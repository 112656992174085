import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'

interface TimeSelectorOption<T> {
  text: string
  value: T
}

interface ITimeSelectorProps<T> {
  options: TimeSelectorOption<T>[]
  object: any
  selectedOption?: T
  onOptionChange: (newOption: T, object: any) => void
}

interface ITimeSelectorState<T> {
  selectedOption: T
}

export default class TimeSelector<T> extends React.Component<ITimeSelectorProps<T>, ITimeSelectorState<T>> {
  constructor(props: ITimeSelectorProps<T>) {
    super(props)
    this.state = this.initialState(props)
  }

  private initialState(props: ITimeSelectorProps<T>): ITimeSelectorState<T> {
    return {
      selectedOption: props.selectedOption || props.options[0].value
    }
  }

  render() {
    const className = "dropdown__toggle button"
    return (
      <div className="time-selector">
        <button type="button"
                onClick={() => this.prevSelection() }
                className="time-selector__decrease-button"><i className="icon-triangle-left"></i></button>
        <div className="dropdown">
          <a className="time-selector__dropdown-button dropdown__toggle button" data-toggle="dropdown">
            {this.selectedOptionName()}
          </a>
          <ul className="time-selector__dropdown__menu dropdown__menu" role="menu">
            {this.options()}
          </ul>
        </div>
        <button type="button"
                onClick={() => this.nextSelection() }
                className="time-selector__increase-button"><i className="icon-triangle-right"></i></button>
      </div>
    )
  }

  selectedOptionName(): string {
    let option = _.find(this.props.options, option => option.value === this.state.selectedOption)
    if (option === undefined) {
      throw `Invalid selected option ${this.state.selectedOption}`
    }
    return option.text
  }

  onSelection(value: T) {
    this.props.onOptionChange(value, this.props.object)
    this.setState((prevState: ITimeSelectorState<T>) => {
      return {
        selectedOption: value
      }
    })
  }

  prevSelection() {
    const selectedIndex = this.selectedOptionIndex()
    const newOption = this.optionAtIndex(selectedIndex - 1)
    this.onSelection(newOption.value)
  }

  nextSelection() {
    const selectedIndex = this.selectedOptionIndex()
    const newOption = this.optionAtIndex(selectedIndex + 1)
    this.onSelection(newOption.value)
  }

  optionAtIndex(index: number) {
    // loop around
    const length = this.props.options.length;
    index = (index + length) % length;
    return this.props.options[index];
  }

  selectedOptionIndex() {
    let selected = this.state.selectedOption || this.props.options[0].value
    let optionValues = _.map(this.props.options, (option) => { return option.value })
    let selectedIndex = optionValues.indexOf(selected)
    return selectedIndex
  }

  options() {
    return this.props.options.map((option, index) => {
      return (
        <li className="dropdown__item"
            role="menuitem"
            key={index}>
          <a onClick={() => this.onSelection(option.value)}
             className="dropdown__link">
            {option.text}
          </a>
        </li>
      )
    })
  }
}
