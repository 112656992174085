import AbstractPatternController from 'controllers/newsroom/abstractPatternController';
import schedulingOptionsTemplate
    from 'text-loader!templates/newsroom/readerArchivePatternOptions.html';

class ReaderArchivePatternController extends AbstractPatternController {
    _setupTimeSelectorView() {
        AbstractPatternController.prototype._setupTimeSelectorView.call(this);

        let isRealtimeActive =
            this.configuration.get('starts_as_delivery_time');

        if (isRealtimeActive) {
            this.view.hideTimeSelector();
        }
    }

    scheduleTimeLabelKey() {
        return 'webapp.reader_admin.edit.weekday_schedule.editorial_deadline';
    }
}

ReaderArchivePatternController.prototype.patternAttribute =
  'archive_pattern';
ReaderArchivePatternController.prototype.scheduleAttribute =
  'archive_date_schedule';
ReaderArchivePatternController.prototype.titleKey =
  'webapp.reader_admin.edit.issue_pattern';
ReaderArchivePatternController.prototype.schedulingOptionsTemplate =
  schedulingOptionsTemplate;

export default ReaderArchivePatternController;

