import * as React from 'react';
import I18n from 'i18n';
import * as jQuery from 'jquery';
import * as _ from 'underscore';
import User from 'models/user';
import Agent from 'models/agent';
import truncate from 'libraries/truncate';
import users from 'collections/usersInstance';
import Sidebar from 'components/sidebar/sidebar';
import SidebarActions from 'components/sidebar/sidebarActions';
import SidebarActionsMenuItem from 'components/sidebar/sidebarActionsMenuItem';
import SidebarSubtreeItem from 'components/sidebar/sidebarSubtreeItem';
import SidebarNavItemTitle from 'components/sidebar/sidebarNavItemTitle';
import SidebarSubtree from 'components/sidebar/sidebarSubtree';

export interface AgentsSidebarComponentProps {
  agentLimitExceeded: boolean;
  newSearchAgentManagement: boolean;
  accessToNewResearch: boolean;
  clipCreationFeature: boolean;
  showTrashBin: boolean;
  isResearchActive: boolean;
  agents: Agent[];
  users: User[];
  onAgentClick: (agent: Agent) => void;
  onAgentCtrlClick: (agent: Agent) => void;
  onAgentShiftClick: (agent: Agent) => void;
  onHeaderClick: () => void;
  onUserClick: (user: User) => void;
  onUserCtrlClick: (user: User) => void;
  onUserShiftClick: (user: User) => void;
  onUserToggleClick: (user: User) => void;
  onEditAgentClick: (agent: Agent) => void;
  onNewAgentClick: () => void;
  onNewResultClick: () => void;
  onTrashBinClick: () => void;
  onResearchClick: () => void;
  selectedAgents: Agent[];
  openedUsers: User[];
}

export interface AgentsSidebarComponentState {
  lockHover?: Agent;
  isTrashBinActive: boolean;
  isEditAgentClicked: boolean;
  agentSelection: Agent[];
}

class AgentsSidebar extends React.Component<
  AgentsSidebarComponentProps,
  AgentsSidebarComponentState
> {
  state = {
    lockHover: undefined,
    isTrashBinActive: false,
    isEditAgentClicked: false,
    agentSelection: [...this.props.selectedAgents],
  };

  render() {
    const maybeTrash = () => {
      if (
        (this.props.showTrashBin &&
          this.props.newSearchAgentManagement &&
          this.props.agents.length > 0) ||
        (this.props.showTrashBin && !this.props.newSearchAgentManagement)
      ) {
        return this.trashBin();
      }
    };
    const maybeResearch = () => {
      if (this.props.accessToNewResearch) {
        return this.researchHeader();
      }
    };
    return (
      <Sidebar>
        {maybeResearch()}
        {this.agentList()}
        {maybeTrash()}
        {this.actions()}
      </Sidebar>
    );
  }

  agentList() {
    if (this.props.newSearchAgentManagement) {
      if (this.props.agents.length > 0) {
        const style = {
          display: 'list-item',
        };
        const key = 'webapp.agents.sidebar.agent_tree_header';
        const agentMarkup = this.props.agents.map((agent: Agent) =>
          this.agent(agent)
        );
        return (
          <li className="nav-tree__item is-open" style={style}>
            <SidebarNavItemTitle onClick={() => this.props.onHeaderClick()}>
              <span className="nav-tree__text">{I18n.t(key)}</span>
            </SidebarNavItemTitle>
            <SidebarSubtree>{agentMarkup}</SidebarSubtree>
          </li>
        );
      } else {
        return this.noAgentsNotice();
      }
    } else {
      return this.props.users.map((user: User) => this.user(user));
    }
  }

  isUserOpen(user: User): boolean {
    const ids = this.props.openedUsers.map((user: User) => user.get('id'));
    const userId = user.get('id');
    return _.contains(ids, userId);
  }

  isAgentSelected(agent: Agent): boolean {
    const ids = _.map(this.state.agentSelection, (agent: Agent) =>
      agent.get('id')
    );
    return _.contains(ids, agent.get('id'));
  }

  user(user: User) {
    if (user.agents.models.length > 0) {
      const isOpen = this.isUserOpen(user) ? 'is-open' : '';
      const klass = 'nav-tree__item ' + isOpen;
      const style = {
        display: 'list-item',
      };
      const name = this.shownUserName(user);
      const agents = user.agents.models.map((agent: Agent) =>
        this.agent(agent)
      );
      const key = 'user-' + user.get('id');
      return (
        <li className={klass} key={key} style={style}>
          <SidebarNavItemTitle
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              this.onUserClick(e, user)
            }>
            <span className="nav-tree__text">
              <span>{truncate(name, 25)}</span>
              <span className="nav-subtree__toggle">
                <i
                  className="nav-tree__toggle__indicator"
                  onClick={(e: React.MouseEvent<HTMLElement>) =>
                    this.onUserToggleClick(e, user)
                  }></i>
              </span>
            </span>
          </SidebarNavItemTitle>
          <SidebarSubtree>{agents}</SidebarSubtree>
        </li>
      );
    }
  }

  onUserToggleClick(e: React.MouseEvent<HTMLElement>, user: User) {
    this.props.onUserToggleClick(user);
    e.stopPropagation();
  }

  onUserClick(e: React.MouseEvent<HTMLElement>, user: User) {
    if (e.shiftKey) {
      this.props.onUserShiftClick(user);
    } else if (e.ctrlKey || e.metaKey) {
      this.props.onUserCtrlClick(user);
    } else {
      this.props.onUserClick(user);
    }
  }

  shownUserName(user: User) {
    if (this.isCurrentUser(user)) {
      return I18n.t('webapp.agents.sidebar.my_search_agents');
    } else {
      return user.get('full_name');
    }
  }

  isCurrentUser(user: User) {
    return user.get('id') === users.currentUser.get('id');
  }

  agent(agent: Agent) {
    const key = 'agent-sidebar-agent-' + agent.get('id');
    const klass = ['nav-subtree__link'];
    if (!agent.get('search_active')) {
      klass.push('text--grey');
    }
    return (
      <SidebarSubtreeItem
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          this.onAgentClick(e, agent);
        }}
        className={klass.join(' ')}
        key={key}
        isActive={this.isAgentSelected(agent)}
        onMouseOver={() => this.onMouseOverAgent(agent)}
        onMouseOut={() => this.onMouseOutsideAgent(agent)}>
        {this.agentNameLabel(agent)}
        {this.agentIcon(agent)}
      </SidebarSubtreeItem>
    );
  }

  agentNameLabel(agent: Agent) {
    const color = agent.get('colors')['default'];
    const style = {
      backgroundColor: color,
      borderColor: color,
    };
    const name = agent.get('name');

    if (!this.props.newSearchAgentManagement) {
      return (
        <div>
          <i className="icon-flag" style={style}></i>{' '}
          <span data-attribute="name">{truncate(name, 22)}</span>
        </div>
      );
    } else {
      return <span data-attribute="name">{truncate(name, 22)}</span>;
    }
  }

  agentIcon(agent: Agent) {
    if (this.props.newSearchAgentManagement && !agent.get('shared')) {
      return this.agentLockIcon(agent);
    } else {
      return this.agentGearIcon(agent);
    }
  }

  noAgentsNotice() {
    const key1 = 'webapp.agents.sidebar.agent_tree_header';
    const key2 = 'webapp.agents.sidebar.no_agents_header';
    const key3 = 'webapp.agents.sidebar.no_agents_description';
    const label1 = I18n.t(key1);
    const label2 = I18n.t(key2);
    const label3 = I18n.t(key3);
    return (
      <div className="contrasted-container">
        <ul className="nav-tree">
          <li className="nav-tree__item is-open">
            <h2 className="nav-tree__item-title">
              <a className="nav-tree__link">
                <span className="nav-tree__text">{label1}</span>
              </a>
            </h2>
            <span className="nav-tree__item-title-content--light">
              {label2}
              <br />
              {label3}
            </span>
          </li>
          {this.trashBin()}
        </ul>
      </div>
    );
  }

  agentLockIcon(agent: Agent) {
    const agentId = agent.get('id');
    if (this.state.lockHover && this.state.lockHover.get('id') == agentId) {
      return this.agentGearIcon(agent);
    } else {
      return (
        <span className="nav-subtree__toggle" data-target="edit-agent">
          <i className="fa fa-lock"></i>
        </span>
      );
    }
  }

  onMouseOverAgent(agent: Agent) {
    this.setState({ lockHover: agent });
  }

  onMouseOutsideAgent(agent: Agent) {
    this.setState({ lockHover: undefined });
  }

  agentGearIcon(agent: Agent) {
    return (
      <span className="nav-subtree__toggle" data-target="edit-agent">
        <i
          className="nav-subtree__toggle-icon"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            this.onEditAgentClick(e, agent);
            this.setState({
              isEditAgentClicked: true,
              agentSelection: [agent],
              isTrashBinActive: false,
            });
          }}></i>
      </span>
    );
  }

  onEditAgentClick(e: React.MouseEvent<HTMLElement>, agent: Agent) {
    this.props.onEditAgentClick(agent);
    e.stopPropagation();
  }

  onAgentClick(e: React.MouseEvent<HTMLElement>, agent: Agent) {
    if (e.shiftKey) {
      this.props.onAgentShiftClick(agent);
      this.setState(({ agentSelection }) => ({
        agentSelection: this.getShiftSelectedAgents(agentSelection, agent),
      }));
    } else if (e.ctrlKey || e.metaKey) {
      this.props.onAgentCtrlClick(agent);
      this.setState(({ agentSelection }) => {
        if (!agentSelection.includes(agent)) {
          return { agentSelection: [...agentSelection, agent] };
        } else if (agentSelection.length === 1) {
          return { agentSelection };
        } else {
          return {
            agentSelection: agentSelection.filter((a) => a !== agent),
          };
        }
      });
    } else {
      this.props.onAgentClick(agent);
      this.setState({ agentSelection: [agent] });
    }
    this.setState({ isTrashBinActive: false });
  }

  getShiftSelectedAgents(selectedAgents: Agent[], agent: Agent): Agent[] {
    //selectedAgent - previously selected agent
    //agent - currently clicked agent
    let foundStartItem = false; //selection start point
    let foundLastItem = false; //selection end point
    const res: Agent[] = [];

    for (let i = 0; i <= this.props.agents.length; i++) {
      if (
        (foundStartItem && this.props.agents[i].id === agent.id) ||
        (foundLastItem && this.props.agents[i].id === selectedAgents[selectedAgents.length - 1].id)
      ) {
        res.push(this.props.agents[i]);
        break;
      } else if (foundStartItem || foundLastItem)
        res.push(this.props.agents[i]);
      else if (
        !foundStartItem &&
        selectedAgents[0].id === this.props.agents[i].id
      ) {
        foundStartItem = true;
        res.push(this.props.agents[i]);
      } else if (!foundLastItem && agent.id === this.props.agents[i].id) {
        foundLastItem = true;
        res.push(this.props.agents[i]);
      }
    }
    return res;
  }

  trashBin() {
    const base = 'nav-tree__link ';
    const active = this.state.isTrashBinActive ? 'is-active' : '';
    return (
      <li className="nav-tree__item">
        <h2 className="nav-tree__item-title">
          <a
            className={base + active}
            data-target="trash-bin"
            onClick={() => {
              this.setState(({ isTrashBinActive }) => ({
                isTrashBinActive: !isTrashBinActive,
                agentSelection: [],
              }));
              this.props.onTrashBinClick();
            }}>
            {I18n.t('webapp.agents.sidebar.trash_bin')}
          </a>
        </h2>
      </li>
    );
  }

  researchHeader() {
    const base = 'nav-tree__link separated-bottom ';
    const active = this.props.isResearchActive ? 'is-active' : '';
    return (
      <li className="nav-tree__item">
        <h2 className="nav-tree__item-title">
          <a
            className={base + active}
            data-target="trash-bin"
            onClick={() => this.props.onResearchClick()}>
            {I18n.t('webapp.agents.sidebar.research')}
          </a>
        </h2>
      </li>
    );
  }

  actions() {
    return (
      <SidebarActions label={I18n.t('webapp.agents.sidebar.add_something')}>
        {this.newAgent()}
        {this.clipCreation()}
      </SidebarActions>
    );
  }

  newAgent() {
    if (this.props.newSearchAgentManagement || !this.props.agentLimitExceeded) {
      return (
        <SidebarActionsMenuItem
          label={I18n.t('webapp.agents.sidebar.add_agent')}
          onClick={() => this.props.onNewAgentClick()}
        />
      );
    }
  }

  clipCreation() {
    if (this.props.clipCreationFeature) {
      return (
        <SidebarActionsMenuItem
          label={I18n.t('webapp.agents.sidebar.add_result')}
          onClick={() => this.props.onNewResultClick()}
        />
      );
    }
  }
}

export default AgentsSidebar;
