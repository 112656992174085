import _ from 'underscore'
import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/deletionModal.html'
import 'bpopup'

var DeletionModalView = Marionette.ItemView.extend({
  initialize: function (opt) {
    this.confirmation = opt.confirmation;
    this.warning = opt.warning;
    this.deleteLabel = opt.deleteLabel;
    this.cancelLabel = opt.cancelLabel;
    this.disabled = opt.disabled;
  },
  template: template,
  onRender: function () {
    if (!this.disabled) {
    this.initializeModal();
    this._showModal();
    }
  },
  serializeData: function () {
    return {
      confirmation: this.confirmation,
      warning: this.warning,
      deleteLabel: this.deleteLabel,
      cancelLabel: this.cancelLabel
    };
  },
  ui: {
    modalElement: '[data-delete-modal]',
    modalDeleteButton: '[data-delete-delete]'
  },
  disable: function () {
    this.disabled = true;
  },
  enable: function () {
    this.disabled = false;
  },
  modalConfirm(modal) {
    modal.modal.close();
    modal.trigger('modal:delete');
  },
  initializeModal: function () {
    var self = this;
    this.ui.modalDeleteButton.on('click', function () {
      self.modalConfirm(self);
    });
    this.ui.modalDeleteButton.on('keydown', function (e) {
      if (e.key === "Enter") { 
        self.modalConfirm(self);
      }
    });
  },
  onClose: function () {
    this.ui.modalElement.remove();
  },
  _showModal: function () {
    if (!this.disabled) {
      this.modal = this.ui.modalElement.bPopup();
    }
    this.ui.modalDeleteButton.focus();
  }
});

export default DeletionModalView;

