import Marionette from 'backbone.marionette'
import moment from 'moment'
import _ from 'underscore'
import ClipCreationForm from 'models/clipCreationForm'
import ClipCreationFormView from 'views/clipCreationFormView'
import ImportedClip from 'models/importedClip'
import SelectedAgentResults from 'models/selectedAgentResults'
import ClipCreationTagController from 'controllers/clipCreationTagController'
import I18n from 'i18n'
import $ from 'jquery'
import Notificator from 'libraries/notificator'
import doubleSingleNewLines from 'libraries/doubleSingleNewLines'
import RichTextEditorController from 'controllers/components/richTextEditorController'
import SourceSearchPropFactory from "components/sourceSearchPropFactory";
import Sources from 'collections/sources';
import PmgUploadFormView from 'views/pmgUploadFormView';
import session from 'models/sessionInstance';

class ClipCreationFormController extends Marionette.Controller {
    initialize(options) {
        _.bindAll(this, '_isUrlPresent', '_isSourcePresent', '_isDatePresent',
                  '_isTitlePresent', '_isAgentsPresent', '_isFilePresent',
                  '_showUrlError', '_showSourceError', '_showTitleError',
                  '_showDateError', '_showAgentsError', '_fileSeemsOk',
                  '_showFileMissingError', '_showFileTypeError',
                  '_showBrowserError', '_isBrowserOk', '_avFileOk',
                  '_showAvFileError', '_showVideoUrlError', '_isVideoUrlAllowed');
        this.propFactory = new SourceSearchPropFactory(
            new Sources(),
            (source) => {
              this.model.set('source', source);
              if (this.formChecks.source.check()) {
                this.view.hideSourceError();
              }
            },
            'clipCreationForm'
        );
        this.formChecks = {};
        this._initializeViewAndModel();
        this._initRichTextEditor();
        this._registerFormChecks();
        this._registerListeners();
        this.pmgUploadFormController = options.pmgUploadFormController;
    }

    onClose() {
      if (this.tagController) {
        this.tagController.close();
      }
    }

    _initializeViewAndModel() {
      this.model =  new ClipCreationForm();
      this.view = new ClipCreationFormView({ model: this.model });
      this.pmgView = new PmgUploadFormView();
    }

    _registerFormChecks() {
      this._registerFormCheck('url',
                              this._isUrlPresent, this._showUrlError);
      this._registerFormCheck('file',
                              this._fileSeemsOk, this._showFileTypeError);
      this._registerFormCheck('videoUrl',
                              this._isVideoUrlAllowed, this._showVideoUrlError);
      this._registerFormCheck('avFile',
                              this._avFileOk, this._showAvFileError);
      this._registerFormCheck('filePresent',
                              this._isFilePresent, this._showFileMissingError);
      this._registerFormCheck('browser',
                              this._isBrowserOk, this._showBrowserError);
      this._registerFormCheck('source',
                              this._isSourcePresent, this._showSourceError);
      this._registerFormCheck('date',
                              this._isDatePresent, this._showDateError);
      this._registerFormCheck('title',
                              this._isTitlePresent, this._showTitleError);
      this._registerFormCheck('agents',
                              this._isAgentsPresent, this._showAgentsError);
    }

  _registerListeners() {
      this.listenTo(this.view, 'clicked.pmgClip',
                    this._pmgClipHandler);
      this.listenTo(this.view, 'clicked.onlineClip',
                    this._onlineClipClickHandler);
      this.listenTo(this.view, 'clicked.fullTextClip',
                    this._fullTextClipClickHandler);
      this.listenTo(this.view, 'clicked.pdfClip',
                    this._pdfClipClickHandler);
      this.listenTo(this.view, 'clicked.rtvClip',
                    this._rtvClipClickHandler);
      this.listenTo(this.view, 'clicked.addClip',
                    this._addClip);
      this.listenTo(this.view, 'change:url',
                    this._fetchClipText);
      this.listenTo(this.view, 'render', this._renderHandler);
      this.listenTo(this.view, 'change:url', () => {
        this.model.set('url', this.view.getUrl());
        if (this.formChecks.url.check()) {
          this.view.hideUrlError();
        }
      });
      this.listenTo(this.view, 'change:videoUrl', () => {
        const url = this.view.getVideoUrl();
        this.model.set('url', url);
        if (this.formChecks.videoUrl.check()) {
          this.view.hideAvError();
          this._switchAvDataSource('url');
        } else if (url === '') {
          this._switchAvDataSource('both');
        } else {
          this._switchAvDataSource('url');
        }
      });
      this.listenTo(this.view, 'change:date', () => {
        this.model.set('publish_date', this.view.getDate());
        if (this.formChecks.date.check()) {
          this.view.hideDateError();
        }
      });
      this.listenTo(this.view, 'change:title', () => {
        this.model.set('title', this.view.getTitle());
        if (this.formChecks.title.check()) {
          this.view.hideTitleError();
        }
      });
      this.listenTo(this.richTextEditorController,
                    'change:text', () => {
                      this.model.set('text',
                                     this.richTextEditorController.getPlainText());
                      this.model.set('rich_text',
                                     this.richTextEditorController.getRichText());
                    });
      this.listenTo(this.view, 'change:attachment', this._prepareAttachment);
      this.listenTo(this.view, 'update:attachments', this._updateAttachments);
      this.listenTo(this.view, 'change:pmg', () => {
        this.model.set('pmg', this.view.getPmg());
      });
      this.listenTo(this.view, 'change:pageNumber', () => {
        this.model.set('page_number', this.view.getPageNumber());
      });
      this.listenTo(this.view, 'change:author', () => {
        this.model.set('author', this.view.getAuthor());
      });
      this.listenTo(this.view, 'change:file', this._prepareFile);
      this.listenTo(this.view, 'change:avFile', this._prepareAvFile);
      this.listenTo(this.view, 'change:agentSelect', () => {
        if (this.formChecks.agents.check()) {
          this.view.hideAgentsError();
        }
      });
      this.listenTo(this.view, 'close', () => {
        this.close();
      });
      //default selected button
      this.view.once('render', () => {
      // find a way to get this check from the view
        if (session.isGermanCustomer() || session.hasFeature('pmg_upload')) {
          this._pmgClipHandler();
        } else {
          this._onlineClipClickHandler();
        }
      });
    }

    _addClip() {
      if (this._submitButtonDisabled) { return; }
      if (this._isFormComplete()) {
        var clipText = this.model.get('text') || '';
        var newText = doubleSingleNewLines(clipText);
        this.model.set({text: newText});
        var data = this.model.serializeData();
        var clip = new ImportedClip(data);

        var self = this;

        var handlers = {
          error(model, response) {
            Notificator.showErrorNotification(
              I18n.t("webapp.notifications.error.not_saved")
            );
            var responseText = response.responseText;
            var typeErrorText = "Couldn't guess the file extension for the given content-type";
            if (responseText.indexOf(typeErrorText) >= 0) {
              self._showAvTypeError();
            }
            self._enableSubmitButton();
          },
          success() {
            var agentIds = self.model.agentSelection();
            self.trigger('importedClip:created', agentIds);
          }
        };

        clip.save(data, handlers);
        this._disableSubmitButton();
      } else {
        this._showFormErrors();
      }
    }

    _displayNotificationOnFormIncomplete() {
      if (!this._isFormComplete()) {
        Notificator.showErrorNotification(
          I18n.t("webapp.notifications.error.not_saved")
        );
      }
    }

    _isFormComplete() {
      var base =
            this._isDatePresent() &&
            this._isTitlePresent() &&
            this._isSourcePresent() &&
            this._isAgentsPresent();

      if (this.model.get('category') === 'online') {
        return base && this._isUrlPresent();
      } else if (this.currentClipType === 'pdf') {
        return base && this._isBrowserOk() && this._fileSeemsOk();
      } else if (this.currentClipType === 'radio_tv') {
        return base && this._isBrowserOk() &&
               (this._avFileOk() || this._isVideoUrlAllowed());
      } else {
        return base;
      }
    }

    _renderHandler() {
      this.view.showSourceSearchForm(this.propFactory.generate());
      this.view.setDate(moment().format(I18n.t("date.formats.default_alternative").toUpperCase()));
      this._showTags();
      this._showRichTextEditor();
      this.view.useNewTextFormField();
      this.view.useNewTitleFormInput();
    }
    _pmgClipHandler() {
      const pmgView = this.pmgUploadFormController.view;
      this.view.showPmgView(pmgView);
      this.view.selectPmg();
      this.view.hideSpecialInputs();
      this.view.hideInputsForPmg();
      this.view.hideAttachmentUpload();
      this.view.hideAgents();
      this.view.showPmgImport();
    }

    _onlineClipClickHandler() {
      this.view.selectOnline();
      this.model.set({page_number: null});
      this.model.set({category: 'online'});
      this.currentClipType = 'online';
      this.view.hideSpecialInputs();
      this.view.showUrlInput();
      this.view.showFooterInputs();
    }

    _fullTextClipClickHandler() {
      this.view.selectFullText();
      this.model.set({category: 'print'});
      this.model.set({url: null});
      this.currentClipType = 'full_text';
      this.view.hideSpecialInputs();
      this.view.showPageNumberInput();
      this.view.showFooterInputs();
    }

    _pdfClipClickHandler() {
      this.view.selectPdf();
      this.model.set({category: 'print'});
      this.currentClipType = 'pdf';
      this.model.set({url: null});
      this.view.hideSpecialInputs();
      this.view.showPdfInput();
      this.view.showPageNumberInput();
      if (!this._isBrowserOk()) {
        this.view.hideFileButton();
        this._showBrowserError();
      }
      this.view.showFooterInputs();
    }

    _rtvClipClickHandler() {
      this.view.selectRtv();
      this.model.set({page_number: null});
      this.model.set({category: 'radio_tv'});
      this.currentClipType = 'radio_tv';
      this.model.set({url: null});
      this.view.hideSpecialInputs();
      this.view.showRtvInput();
      if (!this._isBrowserOk()) {
        this.view.hideFileButton();
        this._showBrowserError();
      }
      this.view.showFooterInputs();
    }

    _fetchClipText() {
      var view = this.view;
      view.showTitleTextPreloader();
      var url = view.getUrl();
      if (_.isEmpty(url.trim())) {
        return;
      }
      var encodedUrl = window.encodeURIComponent(url);
      $.ajax('api/v3/content_extraction?url=' + encodedUrl)
        .done((responseData) => {
          this.richTextEditorController.setPlainText(responseData.content);
          this.richTextEditorController.trigger('change:text');
          view.showTitleAndTextInputs();
        })
        .fail(() => {
          view.showTitleAndTextInputs();
          view.setI18nText('webapp.agents.add_result.full_text');
        });
    }

    _showTags() {
      var agentResults = new SelectedAgentResults([]);
      this.tagController = new ClipCreationTagController(
        agentResults.collection
      );
      this.listenTo(
          this.tagController,
          'add:tag',
          (tag) => { this.model.addTag(tag); }
      );
      this.listenTo(
          this.tagController,
          'remove:tag',
          (tag) => { this.model.removeTag(tag); }
      );
      this.view.tagsPartial.show(this.tagController.view);
    }

    _showFormErrors() {
      _.each(this.formChecks, (formCheck) => {
        if (!formCheck.check()) {
          formCheck.callback();
        }
      });
    }

    _registerFormCheck(checkName, check, callback) {
      this.formChecks[checkName] = {
        check: check,
        callback: callback
      };
    }

    _isUrlPresent() {
      return this.view.getUrl() !== "";
    }

    _isVideoUrlAllowed() {
      if (this.model.get('avData') === 'file') { return true; }
      const url = this.view.getVideoUrl();
      const vimeoRegExp =
          /^https?:\/\/(\S+.)?vimeo.com\/(?:(video\/)|(?:channels\/.*\/))?(\d+)(\/\w+)?\/?([#?].*)?$/;
      const youtubeRegExp =
          /^https?:\/\/www\.youtube\.com\/watch\?(?:.*&)?v=(\w+)([#&].*)?$/;
      return url.match(vimeoRegExp) || url.match(youtubeRegExp);
    }

    _isTitlePresent() {
      return !_.isUndefined(this.model.get('title')) &&
        this.model.get('title') !== ''
    }

    _isDatePresent() {
      return this.view.getDate() !== "";
    }

    _isSourcePresent() {
      return !_.isUndefined(this.model.get('source'));
    }

    _isAgentsPresent() {
      return !_.isEmpty(this.model.agentSelection());
    }

    _isFilePresent() {
      return !!this.view.getFile();
    }

    _fileSeemsOk() {
      var file = this.view.getFile();
      if (this._basicFileCheck(file)) {
        return this._correctFilePrefix;
      } else {
        return false;
      }
    }

    _avFileOk() {
      if (this.model.get('avData') === 'url') { return true; }
      return this._avFileCheck(this.view.getAvFile());
    }

    _isBrowserOk() {
      return !!window.FileReader && !!window.DataView && !this._isIOS();
    }

    _isIOS() {
      return navigator.userAgent.match(/(iPod|iPhone|iPad)/);
    }

    _showUrlError() {
      var key = "webapp.notifications.error.missing_information";
      this.view.setUrlError(I18n.t(key));
    }

    _showFileMissingError() {
      var key = "webapp.notifications.error.missing_information";
      this.view.setFileError(I18n.t(key));
    }

    _showFileTypeError() {
      var key = "webapp.notifications.error.pdf_file_erroneous";
      this.view.setFileError(I18n.t(key));
    }

    _showAvFileError() {
      var key = "webapp.notifications.error.audio_video_file_erroneous";
      this.view.setAvError(I18n.t(key));
    }

    _showAvFileTypeError() {
      var key = "webapp.notifications.error.audio_video_file_type_not_supported";
      this.view.setAvError(I18n.t(key));
    }

    _showDateError() {
      var key = "webapp.notifications.error.missing_information";
      this.view.setDateError(I18n.t(key));
    }

    _showSourceError() {
      var key = "webapp.notifications.error.missing_information";
      this.view.setSourceError(I18n.t(key));
    }

    _showTitleError() {
      this.view.setTitleError();
    }

    _showAgentsError() {
      var key = "webapp.notifications.error.missing_information";
      this.view.setAgentsError(I18n.t(key));
    }

    _showVideoUrlError() {
      var key = "webapp.notifications.error.audio_video_url_not_allowed";
      this.view.setVideoUrlError(I18n.t(key));
    }

    _showBrowserError() {
      var key = "webapp.notifications.error.incompatible_browser";
      this.view.setFileError(I18n.t(key));
      this.view.setAvError(I18n.t(key));
    }

    _basicFileCheck(file) {
      return !!file && !!file.name.match(/\.pdf$/i) && file.size <= 3e7;
    }

    _attachmentTypeCheck(file) {
      return !!file && !!file.type.match(/gif$|jpeg$|jpg$|png$|svg$/);
    }

    _attachmentSizeCheck(file) {
      return !!file && file.size <= 1e7;
    }

    _avFileCheck(file) {
      function isAudioOrVideo(file) {
        return !!file.type.match(/^audio\/|^video\//);
      }
      function isNotTooLarge(file) {
        return file.size <= 5e8;
      }
      function isNotQuicktime(file) {
        return file.type !== 'video/quicktime';
      }
      return !!file &&
        isAudioOrVideo(file) &&
        isNotTooLarge(file) &&
        isNotQuicktime(file);
    }

    _prepareFile() {
      var file = this.view.getFile();
      if (this._basicFileCheck(file)) {
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = () => {
          this._checkFilePrefix(reader);
          this._fileReady(file);
        };
      } else {
        this._fileReady(file);
      }
    }

    _prepareAvFile() {
      var file = this.view.getAvFile();
      if (this._avFileCheck(file)) {
        this.model.set('file', file);
        this.view.showAvFileName(file ? file.name : '');
        this.view.hideAvError();
        this.view.hideVideoUrlError();
        this._switchAvDataSource('file');
      } else {
        this.view.showAvFileName(file ? file.name : '');
        this._switchAvDataSource('both');
      }
    }

    _prepareAttachment() {
      var attachments = this.view.getAttachments();
      var last_attachment = attachments[attachments.length - 1];
      var name, message;
      if (!this._attachmentSizeCheck(last_attachment.file)) {
        name = last_attachment.caption;
        message = I18n.t('webapp.notifications.error.attachment_size_error', {file: name});
        this.view.handleAttachmentFileCheckFail(message);
      } else if (!this._attachmentTypeCheck(last_attachment.file)) {
        name = last_attachment.caption;
        message = I18n.t('webapp.notifications.error.attachment_type_error', {file: name});
        this.view.handleAttachmentFileCheckFail(message);
      } else {
        this._updateAttachments();
      }
    }

    _updateAttachments() {
      var attachments = this.view.getAttachments();
      this.model.set('attachments', attachments);
    }

    _checkFilePrefix(reader) {
      var result = false;
      if (reader.readyState === reader.DONE) {
        var dataView = new DataView(reader.result, 0, 4);
        result = _.every(this._pdfPrefix, (value, index) => {
          return value === dataView.getUint8(index);
        }, this);
      }
      this._correctFilePrefix = result;
    }

    _fileReady(file) {
      this.model.set('file', file);
      this.view.showFileName(file ? file.name : '');
      if (this.formChecks.file.check()) {
        this.view.hideFileError();
      }
    }

    _enableSubmitButton() {
      this._submitButtonDisabled = false;
      this.view.enableSubmitButton();
    }

    _disableSubmitButton() {
      this._submitButtonDisabled = true;
      this.view.disableSubmitButton();
    }

    _initRichTextEditor() {
      this.richTextEditorController = new RichTextEditorController({
        placeholderText: I18n.t('webapp.agents.add_result.full_text')
      });
    }

    _showRichTextEditor() {
      this.view.richTextEditor.show(
        this.richTextEditorController.view
      );
    }

    _switchAvDataSource(source) {
      this.view.toggleAVFileButton(source === 'file' || source === 'both');
      this.view.toggleVideoUrlInput(source === 'url' || source === 'both');
      if (source === 'both') {
        this.model.set('avData', '');
      } else {
        this.model.set('avData', source);
      }
    }

    _pdfPrefix() {
        return [37, 80, 68, 70];
    }
}

export default ClipCreationFormController;
