import Marionette from 'backbone.marionette'
import session from 'models/sessionInstance'
import ReactWrapper from 'views/marionetteReactWrapper';
import Header from 'components/Header';
import template from 'text-loader!templates/placeholderView.html';
import users from 'collections/usersInstance'
import * as React from "react";
import vent from 'libraries/vent'

var HeaderView = Marionette.Layout.extend({
  template: template,
  onRender: function () {
    const user = users.currentUser
    var userName = ""
    if (user) {
      userName = user.get('full_name')
    }
    const props = {
      isImpersonated: session.isImpersonated(),
      passwordEnabled: session.hasFeature('password_authentication'),
      deviceTypePhone: session.get('device_type') === 'phone',
      userName: userName,
      logoutCallback: () => {
        vent.commands.execute("logout");
      },
      unswitchCallback: () => {
        vent.commands.execute("unswitch");
      }
    }
    let component = <Header {...props} />
      let componentWrapper = new ReactWrapper({
        tagName: 'span',
        getComponent: () => component
      })
    this.container.show(componentWrapper);
  },
  regions: {
    container: '[data-placeholder]'
  }
});

export default HeaderView;
