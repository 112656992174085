import AgentResultTagComponent from 'components/agentResultTagComponent'
import {ITagViewProps} from 'components/agentResultTagComponent'
import * as Backbone from 'backbone'
import Marionette from 'backbone.marionette';
import * as template from 'text-loader!templates/placeholderView';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';

class AgentResultTagView extends Marionette.Layout {
  renderView(props: ITagViewProps) {
    const view = new ReactWrapper({
      getComponent: () =>  {
        return <AgentResultTagComponent {...props} />;
      }
    })
    this.container.show(view);
  }
}

AgentResultTagView.prototype.regions = {
  container: '[data-placeholder]'
};
AgentResultTagView.prototype.template = template;

export default AgentResultTagView;
