import * as React from 'react';
import I18n from 'i18n';
import * as _ from 'underscore';
import weekdaySort from 'libraries/weekdaySort';

class WeekdaySelectionButtonGroup extends React.Component<any, any> {
   constructor(props: any) {
     super(props);
     this.state = this.initialState(props);
   }

   initialState(props: any) {
        return {
          selectedValues: props.values || [1, 2, 3, 4, 5]
        };
   }

    render() {
        return (
          <div className="button-group button-group--horizontal">
            { _.map([1, 2, 3, 4, 5, 6, 0], (i: number) => { return this.dateButton(i) }) }
          </div>
        );
    }

    dateButton(dateIndex: number) {
        const values = this.state.selectedValues || this.props.values;
        let classes = ["button-group__item"]
        if (_.include(values, dateIndex)) {
            classes.push("is-active");
        }
        let name = I18n.t(`date.abbr_day_names.${dateIndex}`)
        return <a className={ classes.join(" ") }
                  key={dateIndex}
                  onClick={() => { this.onSelection(dateIndex) }}>{ name }</a>
    }

    onSelection(value: any) {
        let newValues = this.state.selectedValues || this.props.values;
        if (_.indexOf(newValues, value) !== -1) {
            newValues = _.without(newValues, value);
        } else {
            newValues = newValues.concat(value);
        }
        newValues = newValues.sort(weekdaySort);
        this.props.onWeekdaySelection(newValues, this.props.object);
        this.setState((prevState: any) => {
          return {
            selectedValues: newValues
          }
        });
    }
}

export default WeekdaySelectionButtonGroup;
