import _ from 'underscore'

var SelectionManager = function(collection, CollectionClass, maybeFetchItems) {
  this.collection = collection;
  this.selection = new CollectionClass();
  this.maybeFetchItems = maybeFetchItems;
  this.direction = '';
  this.lastIndex = -1;
  this.itemToSelectOnDelete = -1;
};
_.extend(SelectionManager.prototype, {
  setCollection: function (collection) {
    this.collection = collection;
    this.direction = '';
    this.lastIndex = -1;
    this.itemToSelectOnDelete = -1;
  },
  clearSelection: function () {
    this.direction = '';
    this.lastIndex = -1;
    this.selection.set();
  },
  addToSelection: function (items) {
    this.direction = '';
    this.lastIndex = -1;
    this.selection.add(items);
    this.itemToSelectOnDelete = this.getItemToSelectOnDelete();
  },
  removeFromSelection: function (items) {
    this.direction = '';
    this.lastIndex = -1;
    this.selection.remove(items);
    this.itemToSelectOnDelete = this.getItemToSelectOnDelete();
  },
  setSelection: function (items) {
    this.direction = '';
    this.lastIndex = -1;
    this.selection.set(items);
    this.itemToSelectOnDelete = this.getItemToSelectOnDelete();
  },
  getItemToSelectOnDelete: function () {
    var lastItemIndex = this.collection.indexOf(_.last(
      _.intersection(this.collection.models, this.selection.models)
    ));
    return this.collection.at(lastItemIndex+1);
  },
  selectUntil: function (item) {
    var itemPosition = this.collection.indexOf(item);
    var itemsBetween;
    var lastPosition = this._getLastPosition();
    this.lastIndex = itemPosition;
    if (itemPosition < lastPosition) {
      this.direction = 'up';
      itemsBetween = this.collection.slice(
        itemPosition,
        lastPosition + 1
      );
      this.selection.add(itemsBetween.reverse());
    } else {
      this.direction = 'down';
      itemsBetween = this.collection.slice(
        lastPosition,
        itemPosition + 1
      );
      this.selection.add(itemsBetween);
    }
    this.itemToSelectOnDelete = this.getItemToSelectOnDelete();
  },
  selectNextItem: function (direction) {
    this._failIfInvalidDirection(direction);
    var currentItem = this.collection.at(this._getLastPosition());

    var self = this;
    this.maybeFetchItems(currentItem, direction).done(function () {
      var index = self._getNextItemIndex(direction);
      self.direction = '';
      self.lastIndex = index;
      self.selection.set([self.collection.at(index)]);
      self.itemToSelectOnDelete = self.getItemToSelectOnDelete();
    });
  },
  shiftSelectNextItem: function (direction) {
    this._failIfInvalidDirection(direction);
    var currentItem = this.collection.at(this._getLastPosition());
    var self = this;
    this.maybeFetchItems(currentItem, direction).done(function () {
      var index = self._shiftSelectNextItemIndex(direction);
      self.direction = direction;
      self.lastIndex = index;
      self._toggleSelectionForIndex(index);
      self.itemToSelectOnDelete = self.getItemToSelectOnDelete();
    });
  },
  currentItem: function () {
    return this.collection.at(this._getLastPosition());
  },
  _shiftSelectNextItemIndex: function (direction) {
    var index;
    if (this.direction !== direction && this.direction !== '') {
      index = this._getLastPosition();
    } else {
      index = this._getNextItemIndex(direction);
    }
    return index;
  },
  itemThatShouldBeVisible: function () {
    var index = this.lastIndex;
    if (index >= 0) {
      return this.collection.at(index);
    } else {
      return null;
    }
  },
  _toggleSelectionForIndex: function (index) {
    var item = this.collection.at(index);
    if (this.selection.contains(item)) {
      this.selection.remove(item);
      // skips over the single selected item in the shift
      // down, up, up scenario
      this._skipSingleItemSelection();
    } else {
      this.selection.add(item);
    }
  },
  _skipSingleItemSelection: function () {
    if (this.selection.length === 1) {
      this.lastIndex = this.lastIndex + this._directionOffset();
    }
  },
  _directionOffset: function () {
    return this.direction === "up" ? -1 : 1;
  },
  _failIfInvalidDirection: function (direction) {
    if (!_.contains(['up', 'down'], direction)) {
      throw("the direction should be 'up' or 'down'");
    }
  },
  _getLastPosition: function () {
    if (this.lastIndex >= 0) {
      return this.lastIndex;
    } else {
      return this._mostRecentlySelectedItemPosition();
    }
  },
  _mostRecentlySelectedItemPosition: function () {
    var lastSelectedItem = this.selection.last();
    return this.collection.indexOf(lastSelectedItem);
  },
  _getNextItemIndex: function (direction) {
    var indexToSelectAfterDeletion =
          this._indexToSelectAfterDeletion(direction);
    if (this.selection.isEmpty() &&
        indexToSelectAfterDeletion >= 0) {
      return indexToSelectAfterDeletion;
    }

    var index = this._getLastPosition();
    var increment;
    if (direction === 'up') {
      increment = -1;
    } else {
      increment = 1;
    }
    var newIndex = index + increment;
    if (newIndex >= 0 && newIndex < this.collection.length) {
      return newIndex;
    } else {
      return index;
    }
  },
  _indexToSelectAfterDeletion: function (direction) {
    var baseIndex =
          this.collection.indexOf(this.itemToSelectOnDelete);
    if (direction === 'up') {
      return baseIndex-1;
    } else {
      return baseIndex;
    }
  }
});

export default SelectionManager;

