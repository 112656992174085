import * as React from 'react';
import I18n from "i18n";

export default function DashboardErrorMsg() {
  return(
    <div className="centered-notice">
      <p>{I18n.t("webapp.new_dashboard.dashboard_error_message.no_subscription")}</p>
    </div>
  );
}
