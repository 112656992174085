import Marionette from 'backbone.marionette'
import tags from 'collections/manualTagsInstance'
import TagListView from 'views/settings/tagListView'

var TagListController = Marionette.Controller.extend({
  initialize: function () {
    this.view = new TagListView({ tags: tags });

    this.listenTo(this.view, 'tag:selected', (tag) => {
      this.trigger('tag:selected', tag);
    });

    this.listenTo(this.view, 'tag:deselected', () => {
      this.trigger('tag:deselected');
    });
  },
  deselectAll: function () {
    this.view.deselectAll();
  },
  selectTag: function (tag) {
    this.view.selectTag(tag);
  }
});

export default TagListController;

