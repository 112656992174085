import Marionette from 'backbone.marionette';
import EditReaderCategoryView from 'views/newsroom/editReaderCategoryView';
import EditReaderCategoryResultSelectionView
    from 'views/newsroom/editReaderCategoryResultSelectionView';
import EditReaderCategoryMediaSelectionView
    from 'views/newsroom/editReaderCategoryMediaSelectionView';
import EditReaderCategoryLanguageSelectionView
    from 'views/newsroom/editReaderCategoryLanguageSelectionView';
import EditReaderCategoryTitleView
    from 'views/newsroom/editReaderCategoryTitleView';
import EditReaderCategoryTopicsView
    from 'views/newsroom/editReaderCategoryTopicsView';
import DeletionModalView from 'views/deletionModalView';
import I18n from 'i18n';
import _ from 'underscore';
import Notificator from "../../libraries/notificator";

class EditReaderCategoryController extends Marionette.Controller {
    initialize(options) {
        this.category = options.category;
        this.view = new EditReaderCategoryView();
        this.listenTo(this.view, 'render', () => {
            this._setupTitleEditView();
            this._setupTopicsView();
            this._setupResultSelectionView();
            this._setupLanguageSelectionView();
            this._setupMediaSelectionView();
        });
        this.listenTo(this.view, 'showModal',
            this._setupCategoryDeletionView);
    }

    onClose() {
        this.view.close();
        this.titleEditView.close();
        this.categoryTopicsView.close();
        this.resultSelectionView.close();
        this.languageSelectionView.close();
        this.mediaSelectionView.close();
        this.categoryDeletionView.close();
    }

    _setupTitleEditView() {
        this.titleEditView = new EditReaderCategoryTitleView(
            { model: this.category }
        );
        this.listenTo(
            this.titleEditView,
            'title:updated',
            this._handleTitleUpdate
        );
        this.view.showEditableTitle(this.titleEditView);
    }

    _setupTopicsView() {
        this.categoryTopicsView = new EditReaderCategoryTopicsView(
            { model: this.category }
        );
        this.view.showTopics(this.categoryTopicsView);
        this.listenTo(
            this.categoryTopicsView,
            'topic:selection-changed',
            this._handleTopicSelectionChange
        );
    }

    _setupResultSelectionView() {
        this.resultSelectionView = new EditReaderCategoryResultSelectionView(
            { model: this.category }
        );
        this.listenTo(
            this.resultSelectionView,
            'select:all-category-results',
            this._handleAllResultsSelection
        );
        this.listenTo(
            this.resultSelectionView,
            'select:only-important-results',
            this._handleOnlyImportantResultsSelection
        );
        this.view.showResultSelection(this.resultSelectionView);
    }

    _setupMediaSelectionView() {
        this.mediaSelectionView = new EditReaderCategoryMediaSelectionView(
            { model: this.category }
        );

        this.listenTo(
            this.mediaSelectionView,
            'select:media_types:selected',
            this._handleMediaSelection
        );
        this.view.showMediaSelection(this.mediaSelectionView);
    }

    _handleMediaSelection(media_types) {
        this.category.set('media_types', media_types).save();
    }

    _setupLanguageSelectionView() {
        this.languageSelectionView = new EditReaderCategoryLanguageSelectionView(
            { model: this.category }
        );
        this.listenTo(
            this.languageSelectionView,
            'select:languages:all',
            this._handleAllLanguagesSelection
        );
        this.listenTo(
            this.languageSelectionView,
            'select:languages:only-selected',
            this._handleOnlySelectedLanguageSelection
        );
        this.view.showLanguageSelection(this.languageSelectionView);
    }

    _handleAllLanguagesSelection() {
        this.category.set('languages', []).save();
    }

    _handleOnlySelectedLanguageSelection(langs) {
        this.category.set('languages', langs).save();
    }

    _setupCategoryDeletionView() {
        if (this.category.get('id')) {
            this.categoryDeletionView = new DeletionModalView({
                confirmation: I18n.t('webapp.reader_admin.edit.category.delete.confirmation',
                    { name: this.category.get('name')}),
                warning: I18n.t('webapp.reader_admin.edit.category.delete.warning'),
                deleteLabel: I18n.t('webapp.reader_admin.edit.category.delete.delete'),
                cancelLabel: I18n.t('webapp.reader_admin.edit.category.delete.cancel')
            });
            this.listenTo(
                this.categoryDeletionView,
                'modal:delete',
                this._handleCategoryDeletion
            );
            this.view.showCategoryDeletion(this.categoryDeletionView);
        }
    }

    _handleTitleUpdate(name) {
        this.category.set('name', name).save();
    }

    _handleTopicSelectionChange(newSelection, type) {
        newSelection = _.map(newSelection, (topicId) => {
            return Number(topicId);
        });

        let oldSelection = this.category.get('topic_ids');

        if (!_.isEqual(newSelection, oldSelection)) {
            this.category.store();
            this.category.save({
                topic_ids: newSelection,
                topic_type: type
            }).then(() => this.category.readerConfig().fetch())
              .fail((response) => {
                if (response.status === 409) {
                    Notificator.showNotification(I18n.t("webapp.reader_admin.edit.new_reader_audience.request_failed"));
                    this.category.collection.fetch();
                    this.trigger('topicSelection:error');
                } else {
                    this.category.restore();
                }
            });
            this.categoryTopicsView.afterSelectionChanged();
        }
    }

    _handleAllResultsSelection() {
        this.category.set('only_important_results', false).save();
    }

    _handleOnlyImportantResultsSelection() {
        this.category.set('only_important_results', true).save();
    }

    _handleCategoryDeletion() {
        this.category.destroy();
        this.close();
    }
}

export default EditReaderCategoryController;
