import Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import Agents from 'collections/agents';
import agents from 'collections/agentsInstance';
import users from 'collections/usersInstance';
import Users from 'collections/users';
import session from 'models/sessionInstance';
import Notificator from 'libraries/notificator';
import I18n from 'i18n';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import Sidebar from 'components/agentsSidebar'
import AgentsSidebarView from 'views/layoutView'

class AgentsSidebarController extends Marionette.Controller {
  initialize() {
    _.bindAll(this,
              '_handleAgentClick',
              '_handleUserClick',
              'handleEditAgentClick',
              'handleNewAgentClick',
              '_agentCount',
              'createNewAgent');

    this.view = new AgentsSidebarView();
    this.listenTo(this.view, 'render', () => this.renderView());
    this.listenTo(agents, 'remove', () => this.renderView());
    this.listenTo(agents, 'add:subscribed', () => this.renderView());

    this.shownAgents = this.sortedShownAgents();

    // agent selection collection belongs to the agents module
    // controller, it only lives here until that controller is
    // built. the sidebar is the most common interface to
    // change the agent selection, but it does not own the
    // selection, the module does.
    this.selectedAgents = new Agents();

    this.openedUsers = new Users();

    this.registerEventListeners();
  }

  sortedShownAgents() {
    if (session.hasFeature('new_search_agent_management')) {
      let sorted = _.sortBy(
        agents.subscribed(),
        (agent: Agent) => agent.get('name').toLowerCase()
      );
      sorted = _.sortBy(
        sorted, (agent: Agent) => !agent.get("search_active")
      );
      return sorted;
    } else {
      return _.flatten(users.map((user: User) => user.agents.models))
    }
  }

  renderView(additionalOpts?: object) {
    const props = this.buildProps(additionalOpts)
    const sidebarView = new ReactWrapper({
      getComponent: () => (<Sidebar {...props} />)
    });
    this.view.showView(sidebarView);
  }

  buildProps(additionalProps?: object): AgentsSidebarComponentProps {
    const base = {
      agentLimitExceeded: session.agentLimitExceeded(),
      clipCreationFeature: session.hasFeature('clip_creation'),
      showTrashBin: this._showTrashBin(),
      newSearchAgentManagement: session.hasFeature('new_search_agent_management'),
      accessToNewResearch: session.hasFeature('access_to_new_research'),
      users: users,
      agents: this.sortedShownAgents(),
      onAgentClick: (agent) => this._handleAgentClick(agent),
      onAgentCtrlClick: (agent) => this._handleAgentCtrlClick(agent),
      onAgentShiftClick: (agent) => this._handleAgentShiftClick(agent),
      onHeaderClick: () => this._handleHeaderClick(),
      onUserClick: (user) => this._handleUserClick(user),
      onUserCtrlClick: (user) => this._handleUserCtrlClick(user),
      onUserShiftClick: (user) => this._handleUserShiftClick(user),
      onUserToggleClick: (user) => this.handleUserToggleClick(user),
      onEditAgentClick: (agent) => this.handleEditAgentClick(agent),
      onNewAgentClick: () => this.handleNewAgentClick(),
      onNewResultClick: () => this.handleNewResultClick(),
      onTrashBinClick: () => this.handleTrashBinClick(),
      onResearchClick: () => this.handleResearchClick(),
      isResearchActive: false,
      selectedAgents: this.selectedAgents.models,
      openedUsers: this.openedUsers.models,
    }

    return _.extend(base, additionalProps)
  }

  activateResearch() {
    this.renderView({isResearchActive: true});
  }

  _showTrashBin() {
    if (session.isGroupAdmin()) {
      return !agents.isEmpty();
    } else {
      return users.get(users.currentUser.id).hasAgents();
    }
  }

  registerEventListeners() {
    this.listenTo(agents, 'change:name', this.renderView)
    const self = this
    this.listenTo(agents, 'change:subscribed', () => {
      this.updateShowAgents()
    })
    this.listenTo(this.selectedAgents, 'change:shared', () => {
      self.renderView()
    })
  }

  updateShowAgents() {
    let unsubscribedAgents = this.selectedAgents.where({subscribed: false})
    if(unsubscribedAgents.length > 0) {
      this.selectedAgents.remove(unsubscribedAgents)
    }
    this.shownAgents = this.sortedShownAgents()
    this.renderView()
  }

  _handleAgentClick(agent: Agent) {
    this.selectedAgents.reset([agent]);
    this.trigger('change:selection');
  }

  _handleAgentCtrlClick(agent: Agent) {
    let collection = this.selectedAgents;
    if (collection.contains(agent)) {
      if (collection.size() === 1) {
        return; // disallow selecting no agents
      }

      collection.remove(agent);
    } else {
      collection.add(agent);
    }

    this.trigger('change:selection');
  }

  _handleAgentShiftClick(agent: Agent) {
    let itemPosition = this.shownAgents.indexOf(agent);
    let resultsBetween;

    if (itemPosition < this._mostRecentlySelectedItemPosition()) {
      resultsBetween =
        this.shownAgents.slice(itemPosition,
                               this._mostRecentlySelectedItemPosition());
      this.selectedAgents.add(resultsBetween.reverse());
    } else {
      resultsBetween =
        this.shownAgents.slice(this._mostRecentlySelectedItemPosition() + 1,
                               itemPosition + 1);
      this.selectedAgents.add(resultsBetween);
    }

    this.trigger('change:selection');
  }

  _mostRecentlySelectedItemPosition() {
    let lastSelectedItem = this.selectedAgents.last();
    return this.shownAgents.indexOf(lastSelectedItem);
  }

  _handleHeaderClick() {
    this.selectedAgents.reset(this.shownAgents);
    this._addAgentUsersToOpenedUsers(agents);
    this.trigger('change:selection');
  }

  _handleUserClick(user) {
    this.selectedAgents.reset(user.agents.models);
    this._addAgentUsersToOpenedUsers(user.agents.models)
    this.trigger('change:selection');
  }

  _handleUserCtrlClick(user) {
    let agents = user.agents.models;
    let selectedAgents = this.selectedAgents.models;

    if (_.isEqual(agents, selectedAgents)) {
      return; // disallow selecting no agents
    }

    // remove if all user agents are already present in selection
    if (this._isSubset(agents, selectedAgents)) {
      _.each(agents, (agent) => {
        this.selectedAgents.remove(agent);
      }, this);
    } else {
      // add them otherwise
      this._addAgentUsersToOpenedUsers(agents)
      this.selectedAgents.add(agents);
    }

    this.trigger('change:selection');
  }

  _handleUserShiftClick(user) {
    let firstUserAgentPosition =
        this.shownAgents.indexOf(_.first(user.agents.models));
    let lastUserAgentPosition =
        this.shownAgents.indexOf(_.last(user.agents.models));
    let resultsBetween;

    if (firstUserAgentPosition < this._mostRecentlySelectedItemPosition()) {
      resultsBetween =
        this.shownAgents.slice(firstUserAgentPosition,
                               this._mostRecentlySelectedItemPosition());
      this._addAgentUsersToOpenedUsers(resultsBetween)
      this.selectedAgents.add(resultsBetween.reverse());
    } else {
      resultsBetween =
        this.shownAgents.slice(this._mostRecentlySelectedItemPosition() + 1,
                               lastUserAgentPosition + 1);
      this._addAgentUsersToOpenedUsers(resultsBetween)
      this.selectedAgents.add(resultsBetween);
    }

    this.trigger('change:selection');
  }

  _addAgentUsersToOpenedUsers(agents: Agent[]) {
    const ids = agents.map((agent: Agent) => agent.get('user_id'))
    _.each(users.models, (user: User) => {
      const userId = user.get('id')
      if (_.contains(ids, userId)) {
        this.openedUsers.add(user)
      }
    })
    this.renderView()
  }

  _isSubset(subset, set) {
    return _.difference(subset, set).length === 0;
  }

  handleUserToggleClick(user) {
    if (this.openedUsers.get(user.get('id'))) {
      this.openedUsers.remove(user);
      this.selectedAgents.remove(user.agents.models);
      this.trigger('change:selection');
    } else {
      this.openedUsers.add(user);
      this.trigger('change:selection');
    }
  }

  handleEditAgentClick(agent) {
    this.setSelection([agent], { silent: true })
    this.trigger('edit-agent', agent);
  }

  handleNewResultClick() {
    this.trigger('add-result');
  }

  handleNewAgentClick() {
    if (session.hasFeature('new_search_agent_management')) {
      this.trigger('agent-list');
    } else {
      this.createNewAgent();
    }
  }

  createNewAgent() {
    let newAgent = agents.create(
      {},
      {
        wait: true,
        parse: false,
        success: () => { setTimeout(() => this.renderView()) },
        error: function(model, xhr) {
          let response = JSON.parse(xhr.responseText);
          let message;
          if (response.validation_errors) {
            message = response.validation_errors.base;
          }

          if (message) {
            Notificator.showNotification(message);
          }
        }
      }
    );
    this.handleEditAgentClick(newAgent);
    this.trigger('created:agent');
  }

  _agentCount() {
    return users.currentUser.agents.length;
  }

  handleTrashBinClick() {
    this.trigger('trash-bin-list');
  }

  handleResearchClick() {
    this.trigger('new-research')
  }

  makeDefaultSelection() {
    if (session.hasFeature('new_search_agent_management')) {
      this.setSelection(this.shownAgents);
    } else {
      let currentUser = users.get(users.currentUser.id);
      if (currentUser.hasAgents()) {
        let agentList = currentUser.agents;
        this.openedUsers.add(currentUser);
        this.setSelection(agentList.models);
      } else {
        this.setSelection(agents.models);
      }
    }
    this.renderView();
  }

  setSelection(agents, options) {
    let newSelection = agents.filter((a) => this.shownAgents.includes(a))
    if (options && options.notifyOnMissingSubscription && agents.length !== newSelection.length) {
      let message
      if (newSelection.length === 0) {
        message = I18n.t('webapp.agents.none_of_the_selected_agents_are_subscribed')
      } else {
        message = I18n.t('webapp.agents.not_all_selected_agents_are_subscribed')
      }
      Notificator.showNotification(message)
    }
    this.selectedAgents.reset(newSelection);
    if (!_.isObject(options)) {
      options = {};
    }

    if (options.silent !== true) {
      this.trigger('change:selection');
    }
  }

  getSelection() {
    return this.selectedAgents.models.slice(0);
  }
}

export default AgentsSidebarController;
