import * as React from "react";
import * as moment from "moment";
import I18n from "i18n";
import Tooltip from "components/tooltip";
import PointingButton from "components/pointingButton";
import DateFormatter from "libraries/dateFormatter";

interface IAgentTimeframeButtonProps {
  onButtonClick: () => void;
  isActive: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
};

export default class AgentTimeframeButton extends React.Component<IAgentTimeframeButtonProps> {
  render() {
    const menuTitle = I18n.t("webapp.agents.edit.search_period.menu_title");
    const tooltipTitle = I18n.t("webapp.agents.edit.search_period.menu_tooltip_title");
    const tooltipBody = I18n.t("webapp.agents.edit.search_period.menu_tooltip_body");
    const startDateButton = this.props.startDate || moment();
    let buttonText =
      I18n.t("webapp.agents.edit.search_period.menu_button_open_end", {
        startDate: DateFormatter.formatForDatePicker(startDateButton)
      });

    if (this.props.endDate) {
      buttonText =
        I18n.t("webapp.agents.edit.search_period.menu_button_closing_date", {
          startDate: DateFormatter.formatForDatePicker(this.props.startDate),
          endDate: DateFormatter.formatForDatePicker(this.props.endDate),
        });
    }

    const tooltipProps = {
      heading: menuTitle,
      tooltipHeadline: tooltipTitle,
      tooltipBody: <span dangerouslySetInnerHTML={{ __html: tooltipBody }}/>
    };
    const buttonProps = {
      isActive: this.props.isActive,
      buttonText: buttonText,
      onClick: this.props.onButtonClick
    };

    return (
      <div>
        <Tooltip {...tooltipProps} />
        <PointingButton {...buttonProps} />
      </div>
    );
  }
}
