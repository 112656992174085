import SceneController from 'controllers/sceneController';
import _ from 'underscore';
import sourceCategoryLicensingController from './sourceCategoryLicensingController';
import SetUpLicensingController from './SetUpLicensingController';
import SetUpLicensingPlaceholder from '../../components/SetUpLicensingPlaceholder';
import ReactWrapper from 'views/marionetteReactWrapper';
import React from 'react';

var sourceCategoryLicensingSceneController = SceneController.extend({
  show: function () {
    this.controller = new sourceCategoryLicensingController();
    this._showSourceCategoryLicensingController();
    this._showPlaceholder();
  },
  _showSourceCategoryLicensingController: function () {
    this.showInLeftColumn(this.controller.view);
    this._registerListeners();
  },
  _showPlaceholder: function () {
    var placeholder = new ReactWrapper({
      getComponent: () => {
        return <SetUpLicensingPlaceholder />;
      },
    });
    this.showInRightColumn(placeholder);
  },
  _showSetUp: function (item) {
    this.detailController = new SetUpLicensingController({item});
    this.detailView = this.detailController.view;
    this.showInRightColumn(this.detailView);
  },
  _registerListeners: function () {
    this.listenTo(this.controller, 'setUpLicensing', this._showSetUp);
    this.listenTo(
      this.controller,
      'closeSetUpLicensing',
      this._closeDetailView
    );
  },
  _closeDetailView: function () {
    if (!_.isUndefined(this.detailView)) {
      this.detailView.close();
      delete this.detailView;
      this._showPlaceholder();
      this.focusRight();
    }
  },
});

export default sourceCategoryLicensingSceneController;
