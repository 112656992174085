import _ from 'underscore'
import Marionette from 'backbone.marionette'
import userListTemplate from 'text-loader!templates/settings/userList.html'
import UserListItemView from 'views/settings/userListItemView'

var UserListView = Marionette.CompositeView.extend({
  template: userListTemplate,
  itemView: UserListItemView,
  itemViewContainer: "#user-list",
  appendHtml: function (collectionView, itemView, index) {
    var child = collectionView.$(this.itemViewContainer).children()[index];

    if (_.isUndefined(child)) {
      collectionView.$(this.itemViewContainer).append(itemView.el);
    } else {
      collectionView.$(child).before(itemView.el);
    }
  },
  initialize: function () {
    this.collection = this.options.users;

    this.on("itemview:user:clicked", (itemView) => {
      if (itemView.$el.hasClass("is-active")) {
        this.trigger('user:deselected', itemView.model);
        this.deselectAll();
      } else {
        this.trigger('user:selected', itemView.model);
        this.deselectAll();
        this.selectUser(itemView.model);
      }
    });

    this.listenTo(this.collection, 'sort', this.render);
  },
  triggers: {
    "click #add-user-button": "user:add"
  },
  addCreateUserButton: function () {
    if (this.$("#add-user-button").length === 0) {
      this.$("#user-list").append(
        this.$("#add-user-button-template > a").clone()
      );
      this.$("#user-list > a:last").
        attr("id", "add-user-button");
    }
  },
  removeCreateUserButton: function () {
    this.$("#add-user-button").remove();
  },
  deselectAll: function () {
    this.children.each(function (view) {
      view.$el.removeClass('is-active');
    });
  },
  selectUser: function (user) {
    var itemView = this.children.findByModel(user);
    itemView.$el.addClass('is-active');
  }
});

export default UserListView;

