import Marionette from 'backbone.marionette'
import _ from 'underscore'
import SourceGroupsInstancePromise from 'models/sourceGroupsInstancePromise'
import FavoriteSourceGroupItemView from 'views/agents/settings/favoriteSourceGroupItemView'
import template from 'text-loader!templates/agents/settings/favoriteSourceGroups.html'
import handleTooltips from 'models/handleTooltips'

var FavoriteSourceGroupsView = Marionette.CompositeView.extend({
  itemView: FavoriteSourceGroupItemView,
  itemViewContainer: '[data-favorite-sources]',
  template: template,
  events: {
    "click .accordion__toggle": "_handleDropdownFolding",
    "change [data-fav-source-group]": "_headerCheckboxChange",
  },
  initialize: function (options) {
    _.bindAll(
      this,
      '_selectItemViewsById',
      '_handleDropdownFolding',
      '_activateInitialItemViews',
      'initializeCheckboxes',
      'updateAccordionHeader',
      '_headerCheckboxChange',
      'setCheckboxStates',
      '_handleBodyCheckboxChange',
    );
    this.initialSourceGroupIds       = options.initialSourceGroupIds;
    this._registerListeners();

    SourceGroupsInstancePromise.done((sourceGroups) => {
      this.collection = sourceGroups;
    });
    handleTooltips(this);
  },

  onRender: function () {
    var self = this;
    if (_.isUndefined(this._activeView())) {
      SourceGroupsInstancePromise.done(function () {
        self._activateInitialItemViews();
      });
    }
  },
  _registerListeners: function () {
    var self = this;
    this.on('itemview:clicked:sourceGroup', function (itemView) {
      if (!itemView.isActive()) {
        itemView.activate();
      } else {
        itemView.deactivate();
      }
      self.initializeCheckboxes();
      self.trigger('changed:favoriteSourceGroups', [itemView.model],
                   itemView.isActive());
    });
  },
  _activeView: function () {
    return this.children.find(function (itemView) {
      return itemView.isActive();
    });
  },

  _activateInitialItemViews: function () {
    if (!_.isUndefined(this.initialSourceGroupIds)) {
      this._selectItemViewsById(this.initialSourceGroupIds);
    }
    this.initializeCheckboxes();
  },
  _selectItemViewsById: function(ids) {
    var itemViews = this.children.filter(function (view) {
      return _.contains(ids, view.model.get('id'));
    });
    itemViews.forEach((itemView) => {
      itemView.activate();
    });
  },

  _handleDropdownFolding(e) {
    let dropdown = this.$(e.currentTarget).parent();
    dropdown.toggleClass("is-open");
  },

  _handleBodyCheckboxChange(e) {
    this.updateAccordionHeader(this.$(e.currentTarget).parents(".accordion"));
  },

  initializeCheckboxes() {
    _.each(this.$(".accordion"), (acc) => {
      this.updateAccordionHeader(this.$(acc));
    }, this);
  },

  updateAccordionHeader(accordion) {
    let headerCheckbox = accordion.find(".accordion__head input");
    let headerCheckboxLabel = accordion.find(".accordion__head label");
    let sourceGroupCount = accordion.find(".accordion__body input").length;

    let selectedSourceGroupCount = _.filter(accordion.find(".accordion__body input"), (element) => {
      return this.$(element).prop("checked") === true;
    }).length;

    if (selectedSourceGroupCount === 0) {
      headerCheckboxLabel.removeClass("is-semi-active");
      headerCheckbox.prop("checked", false);
    } else if (selectedSourceGroupCount > 0 && selectedSourceGroupCount < sourceGroupCount) {
      headerCheckboxLabel.addClass("is-semi-active");
      headerCheckbox.prop("checked", false);
    } else {
      headerCheckboxLabel.removeClass("is-semi-active");
      headerCheckbox.prop("checked", true);
    }
  },
  _headerCheckboxChange(e) {
    let isHeaderCheckboxChecked = !this.$(e.currentTarget).prop("checked");
    let isSemiActive = this.$(e.currentTarget).siblings("label").hasClass("is-semi-active");
    let sourceGroups = this.$(e.currentTarget).parents(".accordion").find(".accordion__body input");

    if (isHeaderCheckboxChecked) {
      this.setCheckboxStates(sourceGroups, false);
    } else if (isSemiActive) {
      this.setCheckboxStates(sourceGroups, true);
    } else {
      this.setCheckboxStates(sourceGroups, true);
    }
    this.$(e.currentTarget).siblings("label").removeClass("is-semi-active");
      this.trigger(
        "changed:favoriteSourceGroups",
        this.collection.models,
        !isHeaderCheckboxChecked
      );
  },

  setCheckboxStates(checkboxes, state) {
    _.each(checkboxes, (checkbox) => {
      this.$(checkbox).prop("checked", state);
    });
  }
});

export default FavoriteSourceGroupsView;
