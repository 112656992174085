import Marionette from 'backbone.marionette'
import vent from 'libraries/vent'

var SceneController = Marionette.Controller.extend({
  initialize: function (options) {
    this._options = options;
    this.showInLeftColumn  = options.showInLeftColumn;
    this.showInRightColumn = options.showInRightColumn;
    this._registerLayoutListeners();
    this.show();
  },
  show: function () {},
  _registerLayoutListeners: function () {
    this.listenTo(vent, 'layout:focus-left', this.onDetailClosed);
  },
  onDetailClosed: function () {},
  focusLeft: function () {
    vent.trigger('layout:focus-left');
  },
  focusRight: function () {
    vent.trigger('layout:focus-right');
  }
});

export default SceneController;
