import Marionette from 'backbone.marionette';
import _ from 'underscore';
import vent from "libraries/vent";
import NewSearchSettingsController from "controllers/research/newSearchSettingsController";
import NewResearchTimelineController from "controllers/newResearchTimelineController";
import ResearchLandingView from "views/research/researchLandingView";
import SourceCategoriesInstancePromise from "models/sourceCategoriesInstancePromise";

class NewResearchController extends Marionette.Controller {
  initialize() {
    this.showLeftHandContainer  = this.options.showLeftHandContainer;
    this.showRightHandContainer = this.options.showRightHandContainer;
    this._createAndShowController(this.options.query);
  }

  onClose() {
    if (this.settingsController) {
      this.settingsController.close();
    }
    if (!_.isUndefined(this.timelineController)) {
      this.timelineController.close();
    }
    if (!_.isUndefined(this.controller)) {
        this.controller.close();
    }
  }

  _createAndShowController(query) {
    if (this.settingsController) {
      this.settingsController.close();
    }
    if (!query) {
      this._showLanding();
      this._createSettings();
    } else {
      this._handleInitialQuery(query);
    }
    this._registerListeners();
  }

  _showLanding() {
    if (!_.isUndefined(this.timelineController)) {
      this.timelineController.close();
    }
    const view = new ResearchLandingView();
    this.showLeftHandContainer(view);
  }

  _registerListeners() {
    this.listenTo(vent, 'layout:focus-left', this._handleDetailClosed);
  }

  _createSettings(query = null) {
    let options = {filters: query}
    this.settingsController = new NewSearchSettingsController(options);
    this.listenTo(this.settingsController, 'reset:timeline', this._showLanding);

    this.showRightHandContainer(this.settingsController.view);

    this.listenTo(this.settingsController, 'clicked:search', this.handleSearch);
    this.listenTo(this.settingsController, 'clip:closed', this.handleClosedClip);
  }

  handleClosedClip() {
    if (!_.isUndefined(this.timelineController)) {
      this.timelineController.selection.set();
    }
  }

  handleSearch(options) {
    if (!_.isUndefined(this.timelineController)) {
      this.timelineController.close();
      this._showLanding();
    }
    this._createTimeline(options);
  }

  _handleInitialQuery(query) {
    if (this.settingsController) {
      this.settingsController.close();
    }
    this._createSettings(query);
    this._showLanding();
    SourceCategoriesInstancePromise.done((sourceCategories) => {
      let categoryIds = sourceCategories.map((sc) => { return sc.get("id"); });
      let subCategories = _.flatten(sourceCategories.map((sc) => {
        return sc.get("sub_categories").models;
      }));
      let subCategoryIds = _.map(subCategories, (sc) => { return sc.get("id"); });

      this.handleSearch({
        search_query: query,
        source_category_ids: categoryIds.concat(subCategoryIds),
        aggregateTimelineStart: true
      });
    });
  }

  _handleDetailClosed() {
    if (!_.isUndefined(this.timelineController)) {
      this.timelineController.clearSelection();
    }
  }

  _createTimeline(options) {
    this.timelineController = new NewResearchTimelineController(options);
    this.showLeftHandContainer(this.timelineController.view);
    this.listenTo(this.timelineController.selection, 'add', this._handleSelectionChange);
    this.listenTo(this.timelineController.selection, 'remove', this._handleSelectionChange);
  }

  _handleSelectionChange() {
    var selection = this.timelineController.selection;
    if (!selection.isEmpty()) {
      if (selection.length === 1) {
        var result = selection.first();
        this.settingsController.showClip(result);
      } else {
        this.settingsController.showClips(selection.models);
      }
    } else {
      this.settingsController.closeClip();
    }
    vent.trigger('layout:focus-right');
  }


  handleRightColumnClosed() {}
}

export default NewResearchController;
