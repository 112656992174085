import * as React from 'react';
import I18n from 'i18n';

export default function NoDataAvailableError () {
  return(
    <div className="dashboard-widget__no-data">
      <p>{I18n.t("webapp.new_dashboard.widgets.no_data_available")}</p>
    </div>
  );
}
