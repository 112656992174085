import Marionette from 'backbone.marionette'
import _ from 'underscore'
import template from 'text-loader!templates/settings/weekdaySelector.html'

var WeekdaySelector = Marionette.ItemView.extend({
  className: 'button-group button-group--horizontal',
  template: template,
  events: {
    'click a': '_clickHandler'
  },
  update: function (values) {
    _.each(this.$('a'), (element) => {
      var index = this._findElementIndex(element);
      if (values[index]) {
        this.$(element).addClass('is-active');
      } else {
        this.$(element).removeClass('is-active');
      }
    }, this);
  },
  _clickHandler: function (event) {
    this.trigger('toggle', this._findElementIndex(event.target));
  },
  _findElementIndex: function (targetElement) {
    return parseInt(this.$(targetElement).attr('data-index'), 10);
  }
});

export default WeekdaySelector;

