import Marionette from 'backbone.marionette';
import _ from 'underscore';
import when from 'when';

class TimelineCollectionListController extends Marionette.Controller {
    initialize(options) {
        this.listControllerId = 'id-' + (new Date()).getTime();
        _.bindAll(this,
                  '_appendItemsFinished',
                  '_handleScrolledToEndOfList',
                  '_initialResultsShown',
                  'disableAutoAppend',
                  'enableAutoAppend');
        this._bindInController();
        this._initializeApiCollection(options);
        this._initializeListView();
        this._initializeListItemViews();
        this._registerListeners();
        this._registerControllerSpecificListeners();
        this.loadListCollection(options);
        this.appendTracker = options.appendTracker;
    }

    _bindInController() {}
    _initializeApiCollection() {}
    _initializeListView() {}
    _initializeListItemViews() {}
    _registerControllerSpecificListeners() {}
    loadListCollection() {}
    allItemsLoaded() {}
    collectionSize() {}
    shownItems() {}
    collectionItemViews() {}
    _registerItemView() {}

    handleItemViewUpdate(listItem) {
        this.view.handleItemViewUpdate(listItem);
    }

    appendItems() {
        let self = this;
        this.appendTracker.recordAppend();
        this.view.showPreloader();
        return when(this.appendCollection()).then(function () {
            self._startChangingContent(self.view);
            self._appendItemsFinished();
            self._stopChangingContent(self.view);
        });
    }

    markAsSelected(item) {
        let itemViews = this.collectionItemViews();
        let view = itemViews[item.id];
        if (view) { view.markAsSelected(); }
    }

    markAsDeselected(item) {
        let itemViews = this.collectionItemViews();
        let view = itemViews[item.id];
        if (view) { view.markAsDeselected(); }
    }

    getDistances(item) {
        let view = this.collectionItemViews()[item.id];
        return view.getDistanceFromTopAndBottom();
    }

    disableAutoAppend() {
        this.stopListening(this.view, "scrolled-to-end-of-list");
    }

    enableAutoAppend() {
        this.listenTo(this.view, "scrolled-to-end-of-list",
                      this._handleScrolledToEndOfList);
    }

    canLoadMoreResults() {
        return !this.allItemsLoaded();
    }

    _appendItemsFinished() {
        this._notifyViewFetchFinished();
        this.trigger('newItemsLoaded');
        this.handleNoItemsToDisplay();
        this._handleAllItemsLoaded();
    }

    _initialResultsShown() {
        this._startChangingContent(this.view);
        this.trigger('initial-results-shown');
        this._stopChangingContent(this.view);
        this._registerMoreListeners();
    }

    _notifyViewFetchFinished() {
        this.view.fetchFinished();
    }

    _handleAllItemsLoaded() {
        if (this.allItemsLoaded()) {
            let resultSize = this.collectionSize();
            this.view.allItemsLoaded();
            this.trigger('allItemsLoaded', resultSize);
        }
    }

    _handleScrolledToEndOfList() {
        if (this.appendTracker.doAutomaticAppendNow()) {
            this.appendItems();
        }
    }

    _startChangingContent(view) {
        let element;
        if (view) { element = view.el; }
        this.trigger('change:content:start', element);
    }

    _stopChangingContent(view) {
        let element;
        if (view) { element = view.el; }
        this.trigger('change:content:stop', element);
    }

    _forwardEvent(name) {
        return (...args) => {
            this.trigger(this, ...[name].concat(args));
        };
    }

    _registerListeners() {
        this.listenTo(this.view, "clicked-show-more", this.appendItems);
        this.listenTo(this.view, "itemview:render", (itemView) => {
            this._registerItemView(itemView);
            this.trigger('result:add', itemView.model);
        });
        this.listenTo(
            this.view, "itemview:result:clicked", (itemView) => {
            this.trigger("result:clicked", itemView.model);
        });
        this.listenTo(this.view,
                      "itemview:result:ctrlClicked", (itemView) => {
            this.trigger("result:ctrlClicked", itemView.model);
        });
        this.listenTo(this.view,
                      "itemview:result:shiftClicked", (itemView) => {
            this.trigger("result:shiftClicked", itemView.model);
        });
        this.listenTo(this.view, 'change:content:start',
            this._forwardEvent('change:content:start'));
        this.listenTo(this.view, 'change:content:stop',
            this._forwardEvent('change:content:stop'));
        this.listenTo(this.view, "scrolled-to-end-of-list",
                      this._handleScrolledToEndOfList);
    }

    _registerMoreListeners() {
        this.listenTo(this.view, 'before:item:added', this._startChangingContent);
        this.listenTo(this.view, 'after:item:added', this._stopChangingContent);
    }
}

export default TimelineCollectionListController;
