import * as _ from 'underscore'
import * as Backbone from 'backbone'
import * as $ from 'jquery'
import I18n from 'i18n'
import agentsPromise from 'models/agentsInstancePromise'
import tagsPromise from 'models/tagsInstancePromise'
import sourceGroupsPromise from 'models/sourceGroupsInstancePromise'
import usersInstance from 'collections/usersInstance'
import session from 'models/sessionInstance'

const jQuery = $;

interface ICachedOption {
  id: number
  name: string
  user_id: number
}

interface ISelectedOption {
  id: number
  name: string
  selected: boolean
}

interface IOptionGroup {
  [key: string]: Array<ICachedOption>
}

interface ILabelWithOptions {
  label: string
  options: Array<ISelectedOption>
}

interface ITag {
  id: string
  text: string
}

interface ISourceGroup extends Backbone.Model {}
interface IAgent extends Backbone.Model {}
interface IAgentsCollection extends Backbone.Collection<IAgent> {}

class ClipCreationForm extends Backbone.Model {
  private cachedAgentOptions?: Array<ICachedOption>
  private sourceGroups?: Backbone.Collection<ISourceGroup>

  initialize() {
    _.bindAll(this,
              'defaultSortFunction', 'groupSortFunction', 'availableOptions',
              'updateAgentOptions');

    this.on('change:agentSelection', this.updateAgentOptions);
    this.set({agentSelection: []});
    this.set({ tags: [] });

    agentsPromise.done((agents: IAgentsCollection) => {
      this.cachedAgentOptions = this.toCachedOptions(agents)
      this.updateAgentOptions();
    });

    sourceGroupsPromise.done((sourceGroups: Backbone.Collection<ISourceGroup>) => {
      this.sourceGroups = sourceGroups;
    });
  }

  private toCachedOptions(agents: IAgentsCollection): Array<ICachedOption> {
    let toCachedOption = (agent: IAgent) => {
      return {
        id: agent.get("id"),
        name: agent.get("name"),
        user_id: agent.get("user_id")
      };
    };
    if (session.canAddClipsToOtherAgents()) {
      return agents.map((agent: IAgent) => { return toCachedOption(agent); });
    } else {
      let ownAgents = _.filter(agents.models, (agent: IAgent) => {
        return agent.get("user_id") === usersInstance.currentUser.id;
      });
      return ownAgents.map((agent: IAgent) => { return toCachedOption(agent); });
    }
  }

  agents(): Array<ICachedOption> {
    if (this.cachedAgentOptions) {
      return this.cachedAgentOptions;
    } else {
      return []
    }
  }

  agentSelection(): Array<ISelectedOption> {
    return this.get('agentSelection');
  }

  addTag(tag: ITag) {
    const tags = this.get('tags')
    if (!_.contains(tags, tag)) {
      this.get('tags').push(tag)
    }
  }

  removeTag(tag: ITag) {
    const newTags = _.without(this.get('tags'), tag)
    this.set({ tags: newTags })
  }

  tagSelection(): Array<string> {
    return _.pluck(this.get('tags'), 'text');
  }

  private markSelectedOptions(
    array: Array<ICachedOption>, selection: Array<ISelectedOption>
  ): Array<ISelectedOption> {
    return _.map(array, (option: ICachedOption) => {
      const selected = _.include(selection, option.id);
      return {
        id: option.id,
        name: option.name,
        selected: selected
      };
    }, this);
  }

  private updateAgentOptions() {
    this.set({agentOptions: this.availableOptions()});
  }

  private availableOptions(): Array<ILabelWithOptions> {
    let options: Array<ILabelWithOptions> = [];
    const groups: IOptionGroup = this.agentGroups();
    const sortedKeys = _.keys(this.agentGroups()).sort(this.groupSortFunction);

    _.each(sortedKeys, (key: string) => {
      let optionValues: Array<ICachedOption> = groups[key];
      let sorted = _.sortBy(optionValues, (optionValue: ICachedOption) => {
        return optionValue.name.toLowerCase();
      });

      options.push({
        label: key,
        options: this.markSelectedOptions(sorted, this.agentSelection())
      });
    });

    return options;
  }

  private agentGroups() {
    let groups: IOptionGroup = {};

    _.each(this.agents(), (agent: ICachedOption) => {
      const label = this.optGroupName(agent);

      if (_.isUndefined(groups[label])) {
        groups[label] = [];
      }
      groups[label].push(agent);
    });

    return groups;
  }

  private groupSortFunction(a: string, b: string): number {
    const regex =
          new RegExp("^" + I18n.t("webapp.dashboard.creation.my_agents"));
    if (a.match(regex)) {
      if (b.match(regex)) {
        return this.defaultSortFunction(a, b);
      } else {
        return -1;
      }
    } else if (b.match(regex)) {
      return 1;
    } else {
      return this.defaultSortFunction(a, b);
    }
  }

  private defaultSortFunction(a: string, b: string): number {
    if (a < b) {
      return -1;
    } else if (a === b) {
      return 0;
    } else {
      return 1;
    }
  }

  serializeData() {
    const source = this.get('source')
    return {
      category: this.get('category'),
      publish_date: this.get('publish_date'),
      url: this.get('url'),
      title: this.get('title'),
      text: this.get('text') || "",
      rich_text: this.get('rich_text') || '',
      page_number: this.get('page_number'),
      author: this.get('author'),
      source: {id: source.get('id'), name: source.get('name')},
      agent_ids: this.agentSelection(),
      tags: this.tagSelection(),
      file: this.get('file'),
      avData: this.get('avData'),
      attachments: this.get('attachments'),
      pmg: this.get('pmg')
    };
  }

  private optGroupName(agent: ICachedOption): string {
    const agentUserId = agent.user_id;
    const user = usersInstance.get(agentUserId);
    if (_.isUndefined(user)) {
      return this.generateOptGroupLabelForAgent("gelöschter Nutzer");
    } else {
      if (agentUserId === usersInstance.currentUser.id) {
        return this.generateOptGroupLabelForAgent("mine");
      } else {
        const fullName = user.get('full_name');
        return this.generateOptGroupLabelForAgent(fullName);
      }
    }
  }

  private generateOptGroupLabelForAgent(ownerName: string): string {
    const trimmedName = jQuery.trim(ownerName);
    if (ownerName === "mine") {
      return I18n.t("webapp.dashboard.creation.my_agents");
    } else {
      return I18n.t("webapp.dashboard.creation.users_agents",
                    { username: trimmedName });
    }
  }
}

export default ClipCreationForm;
