import EsResults from "collections/esResults";
import NewResearchQueryBuilder from "models/research/newResearchQueryBuilder";
import _ from "underscore";

class NewResearchResultApiCollection extends EsResults {
  initialize(models, options) {
    this.queryBuilder = new NewResearchQueryBuilder(options);
    this.startAt = options.startAt || Date.UTC(1849, 1, 23, 0, 0, 0);
  }
  url() {
    return this.queryBuilder.toUrl();
  }
  prependResults(opts) {
    let firstResult = this.first();
    if (firstResult) {
      let clip = firstResult.get("clip");
      this.queryBuilder.set("since", clip.publish_time);
    } else {
      this.queryBuilder.set("since", this.startAt);
    }
    opts = _.extend({remove: false, at: 0}, opts);
    let deferred = this.fetch(opts);
    deferred.done(() => { this.trigger('results:loaded'); });
    return deferred;
  }
  appendResults(opts) {
    let lastResult = this.last();
    if (lastResult) {
      let clip = lastResult.get("clip");
      this.queryBuilder.set("before", clip.publish_time);
    }
    opts = _.extend({remove: false}, opts);
    let deferred = this.fetch(opts);
    deferred.done(() => { this.trigger('results:loaded'); });
    return deferred;
  }
  parse(response) {
    let results = response.results;
    let numberOfResults = results.length;
    this.totalEntries = response.total;
    this.moreObjectsAvailable = this.totalEntries > numberOfResults;
    this.startAt = response.timeline_start;
    return results;
  }
  allItemsLoaded() {
    return !this.moreObjectsAvailable;
  }
}

export default NewResearchResultApiCollection;
