import * as React from "react";
import * as moment from "moment";
import I18n from "i18n";
import DateFormatter from "libraries/dateFormatter";

export interface AdditionalSourceListItemProps {
  sourceName: string;
  sourceFavicon: string;
  sourceReach?: number | null;
  sourceArticleLink?: string | null;
  articlePublishTime?: moment.Moment | Date;
  radioTv?: boolean;
}

export default class AdditionalSourceListItemView extends
  React.Component<AdditionalSourceListItemProps, {}> {
  render() {
    return (
      <li className="clip-additional-sources__item">
        <div className="clip-additional-sources__item-content ">
          <div className="clip-additional-sources__item-head">
            <div className="source">
              <div className="source__agent">
                <img src={this.props.sourceFavicon}
                     alt=""
                     className="source__agent-icon" />
                { this.renderSourceName() }
                { this.renderSourceReach() }
                { this.renderSourcePublishTime() }
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }

  private renderSourceName() {
    const viewArticleLabel = I18n.t(
      "webapp.agents.results.result_details.view_article"
    );

    if (this.props.sourceArticleLink) {
      if (this.props.radioTv) {
        return (
          <div className="dropdown">
            <span className="source__name">{this.props.sourceName}</span>
          </div>
        );
      } else {
        return (
          <div className="dropdown">
            <span className="source__agent-url dropdown__toggle"
                  data-toggle="dropdown">
              { this.props.sourceName }
            </span>
            <ul className="dropdown__menu" role="menu">
              <li className="dropdown__item" role="menuitem">
                <a href={this.props.sourceArticleLink}
                   className="dropdown__link"
                   target="_blank">
                  {viewArticleLabel}
                </a>
              </li>
            </ul>
          </div>
        );
      }
    } else {
      return (
        <div className="dropdown">
          <span className="source__name">{this.props.sourceName}</span>;
        </div>
      );
    }
  }

  private renderSourceReach() {
    if (this.props.sourceReach) {
      return (
        <div className="source__reach">
          <span className="source__reach-label">
            { ` ${I18n.toNumber(this.props.sourceReach, { precision: 0 })}` }
          </span>
          <span className="source__reach-value">
            { ` ${this.renderSourceReachLabel()}` }
          </span>
        </div>
      );
    }
  }

  private renderSourceReachLabel() {
    if (this.props.radioTv) {
      return I18n.t("webapp.agents.results.result_details.metadata.views");
    } else {
      return I18n.t("webapp.agents.results.result_details.metadata.visits_per_month");
    }
  }

  private renderSourcePublishTime() {
    if (this.props.articlePublishTime) {
      const publishTime =
        DateFormatter.formatRelativeDateTime(this.props.articlePublishTime);
      return (
        <span className="clip-additional-sources__item-timestamp">
          { ` ${publishTime}` }
        </span>
      );
    }
  }
}
