import Marionette from 'backbone.marionette'
import _ from 'underscore'
import I18n from 'i18n'
import OnlineClipOrder from 'libraries/onlineClipOrder';
import MultiAgentResultDetailView from 'views/agents/multiAgentResultDetailView'
import vent from 'libraries/vent'
import SelectedAgentResults from 'models/selectedAgentResults'
import AgentResultRatingController from 'controllers/agents/agentResultRatingController'
import AgentResultTagController from 'controllers/agents/agentResultTagController'
import AgentResultMarkImportantController from 'controllers/agents/agentResultMarkImportantController'
import CloseSubObjects from 'libraries/closeSubObjects'
import Shortcutter from 'libraries/shortcutter'
import Notificator from 'libraries/notificator'
import pusherWrapper from 'models/pusherWrapper'

var MultiAgentResultDetailController = Marionette.Controller.extend({
  initialize: function (agentResults) {
    this.agentResults = agentResults;
    this.selectedAgentResults = new SelectedAgentResults(this.agentResults);

    this.view = new MultiAgentResultDetailView({
      agentResults: this.agentResults,
      selectedAgentResult: _.last(this.agentResults)
    });

    this.registerEventListeners();
    this.listenTo(this.view, 'render', this.showRegions);
    this._bindShortcuts();
  },
  onClose: function () {
    CloseSubObjects.closeAll(
      [this.ratingController,
       this.tagController,
       this.markImportantController]
    );
    this._unbindShortcuts();
  },
  showRegions: function () {
    this.ratingController =
      new AgentResultRatingController(this.selectedAgentResults);
    this.tagController =
      new AgentResultTagController(this.selectedAgentResults.collection);
    var opts = {
      selectedAgentResults: this.selectedAgentResults
    };
    this.markImportantController =
      new AgentResultMarkImportantController(opts);
    this.view.showRatings(this.ratingController.view);
    this.view.showMarkImportant(this.markImportantController.view);
    this.view.showTags(this.tagController.view);
  },
  registerEventListeners: function () {
    this.listenTo(this.view, 'clicked:pdf:report', this.handlePdfReports);
    this.listenTo(this.view, 'clicked:excel:report', this.handleExcelReports);
    this.listenTo(this.view, 'clicked:newsletter:report', this.handleNewsletterReports);
    this.listenTo(this.view, 'clicked:delete:all', this.handleDeleteAgentResults);
    this.listenTo(this.view, 'click:onlineOrder', () => {
      this._displayOnlineClipOrderModal();
    });
    this.listenTo(this.view, 'modal:multiOnlineClipOrderConfirm',
      this._orderOnlineClip
    );
    this.agentResults.forEach((agentResult) => {
      this.listenTo(agentResult, 'updatedByOtherUser', this.view.render);
    });
    this.listenTo(pusherWrapper, 'agent_result_changed',
      this._handleAgentResultPusherChange);
  },

  _displayOnlineClipOrderModal() {
    this.view.showOnlineClipOrderModal();
  },

  _orderOnlineClip() {
    let _this = this;
    const selectedResultsIds = this.agentResults.map((i) => { return i.get("id");})
    OnlineClipOrder.order(selectedResultsIds).then(
      function () {
        let message = I18n.t(
          'webapp.agents.results.order_full_text.multiple_results.order_successful'
        );
        Notificator.showNotification(message);
      },
      function () {
        _this._orderError();
      }
    );
  },

  _handleAgentResultPusherChange: function (data) {
    this.agentResults.forEach((agentResult) => {
      if (data.agent_result_id === agentResult.get('id')) {
        var notification =
          I18n.t('webapp.notifications.agent_results.concurrent_update');
        Notificator.showNotification(notification);
      }
    })
  },

  _orderError() {
    let message = I18n.t("webapp.notifications.error.order_failed");
    Notificator.showNotification(message);
  },
  
  handlePdfReports: function () {
    vent.trigger('reporting:new:report', {
      topic_ids: this.clipIds(),
      topic_type: 'clip',
      type: "Medienspiegel"
    });
  },
  handleDeleteAgentResults: function () {
    var ownedResults = _.filter(this.selectedAgentResults.collection.models,
                                function (agentResult) {
                                  return agentResult.isOwnedByCurrentUser();
                                }
                               );

    var ownedSelectedResults = new SelectedAgentResults(ownedResults);

    if (ownedSelectedResults.collection.length <
        this.selectedAgentResults.collection.length) {
      var notification =
            I18n.t('webapp.notifications.agent_results.partially_deleted');
      Notificator.showNotification(notification);
    }
    ownedSelectedResults.destroy();
    vent.trigger('agentResult:delete');
  },
  handleExcelReports: function () {
    vent.trigger('reporting:new:report', {
      topic_ids: this.clipIds(),
      topic_type: 'clip',
      type: "ExcelReport"
    });
  },
  handleNewsletterReports: function () {
    vent.trigger('reporting:new:report', {
      topic_ids: this.clipIds(),
      topic_type: 'clip',
      type: "Newsletter"
    });
  },
  clipIds: function () {
    return _.map(this.agentResults, function (result) {
      return result.get("clip_id");
    });
  },
  _bindShortcuts: function () {
    var self = this;

    var deleteShortcuts = ['del', '#', 'mod+backspace', 'x'];
    Shortcutter.bindInModule('agents', deleteShortcuts, function () {
      self.handleDeleteAgentResults();
    });
    Shortcutter.bindInModule('agents', 'l', function () {
      if (self.view._resultsLicensable()) {
        self._displayOnlineClipOrderModal();
      }
    })
  },
  _unbindShortcuts: function () {
    Shortcutter.unbindInModule(
      'agents',
      ['del', '#', 'mod+backspace', 'x', 'l']
    );
  }
});

export default MultiAgentResultDetailController;
