import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import * as React from 'react'

export const bugsnagClient = bugsnag({
  apiKey: '0b0dd7f43d0c495fc4851ff651ccf5c1',
  releaseStage: 'none',
  autoDetectErrors: false,
  autoTrackSessions: false,
  notifyReleaseStages: []
})

bugsnagClient.use(bugsnagReact, React)

type IBugsnagErrorBoundary = React.ReactType<{ FallbackComponent: React.ReactType<{}> }>

export const BugsnagErrorBoundary: IBugsnagErrorBoundary = bugsnagClient.getPlugin('react')
