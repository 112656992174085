import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/timeline/timeline.html'
import TimelineYearView from 'views/timeline/timelineYearView'
import TimelineYears from 'collections/timeline/timelineYears'
import ScrollingHandler from 'models/timeline/scrollingHandler'
import _ from "underscore";

class TimelineView extends Marionette.CompositeView {
  initialize() {
    this.collection = new TimelineYears([], {
      startAt: this.options.startAt
    });

    this.shouldShowTimeline = false;
    this.shouldShowInitializingWarning = false;
    this.initScrollingHandler();
  }

  resetStartYear(options) {
    if (options.startAt) {
      this.options.startAt = options.startAt;
      this.collection.setStartDate(options);
    }
  }

  initScrollingHandler() {
    this.scrollingHandler = new ScrollingHandler(this.$el);
  }

  itemViewOptions() {
    return {customOptions: this.options.customOptions};
  }

  appendHtml(collectionView, itemView, index) {
    let container = collectionView.ui.yearsContainer;
    let followingElement = container.children()[index];
    if (followingElement) {
      collectionView.$(followingElement).before(itemView.el);
    } else {
      container.append(itemView.el);
    }
  }

  onRender() {
    this.updateTimelineVisibility();
  }

  showTimeline() {
    this.shouldShowTimeline = true;
    this.updateTimelineVisibility();
  }

  showNoResults(text) {
    if (_.isFunction(this.ui.noResults.text)) {
        this.ui.noResults.text(text);
        this.shouldShowNoResults = true;
        this.shouldShowTimeline = false;
        this.updateTimelineVisibility();
    }
  }

  showInitializingWarning() {
    this.shouldShowInitializingWarning = true;
    this.updateTimelineVisibility();
  }

  hideInitializingWarning() {
    this.shouldShowInitializingWarning = false;
    this.updateTimelineVisibility();
  }

  updateTimelineVisibility() {
    if (this.ui.timeline.show) {
      if (this.shouldShowTimeline) {
        this.ui.timeline.show();
        this.ui.preloader.hide();
        this.hideNoResultsElement();
      } else if (this.shouldShowNoResults) {
        this.ui.timeline.hide();
        this.ui.preloader.hide();
        if (!this.shouldShowInitializingWarning) {
          this.showNoResultsElement();
        } else {
          this.hideNoResultsElement();
        }
      } else {
        this.hideNoResultsElement();
        this.ui.timeline.hide();
      }
      if (this.shouldShowInitializingWarning) {
        this.showInitializingWarningElement();
      } else {
        this.hideInitializingWarningElement();
      }
    }
  }

  hideNoResultsElement() {
    if (this.ui.noResults) {
      this.ui.noResults.hide();
    }
  }

  showNoResultsElement() {
    if (this.ui.noResults) {
      this.ui.noResults.show();
    }
  }

  hideInitializingWarningElement() {
    if (this.ui.initializingWarning && this.ui.initializingWarning.hide) {
      this.ui.initializingWarning.hide();
    }
  }

  showInitializingWarningElement() {
    if (this.ui.initializingWarning && this.ui.initializingWarning.show) {
      this.ui.initializingWarning.show();
    }
  }

  scrollTimeline(pixels) {
    this.scrollingHandler.scrollTimeline(pixels);
  }

  getTopOffset() {
    return this.scrollingHandler.getTopOffset();
  }

  startChangingContent(element) {
    this.scrollingHandler.storePosition(element);
  }

  stopChangingContent(element) {
    this.scrollingHandler.restorePosition(element);
  }

  findYearViewByDate(date) {
    return this.children.find((view) => {
      const year = view.model.get('date').getFullYear();
      return date.getFullYear() === year
    });
  }

  findMonthViewByDate(date) {
    const yearView = this.findYearViewByDate(date);
    return yearView.children.find((view) => {
      const month = view.model.get('date').getMonth();
      return date.getMonth() === month;
    });
  }

  findDayViewByDate(date) {
    const monthView = this.findMonthViewByDate(date);
    return monthView.children.find((view) => {
      const day = view.model.get('date').toDate().getDate();
      return date.getDate() === day;
    });
  }
}
TimelineView.prototype.template = template;
TimelineView.prototype.itemView = TimelineYearView;
TimelineView.prototype.ui = {
  yearsContainer: '[data-container=years]',
  preloader: '[data-timeline-preloader]',
  noResults: '[data-timeline-noresults]',
  initializingWarning: '[data-timeline-initializing-warning]',
  timeline: '[data-timeline]'
};
TimelineView.prototype.itemViewEventPrefix = "years";

export default TimelineView;
