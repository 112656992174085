import WidgetView from 'views/dashboard/widgetView'

var AgentTagWidgetView = WidgetView.extend({
  onInitialize: function () {
    this.enableAutoRefresh();
    this.setClickHandler();
  },
  setClickHandler: function () {
    this.on('click:h1', this._showAgentTag, this);
  },
  removeClickHandler: function () {
    this.off('click:h1', this._showAgentTag);
  },
  enterEditMode: function () {
    this.removeClickHandler();
  },
  exitEditMode: function () {
    this.setClickHandler();
  },
  _showAgentTag: function () {
    this.model.showAgentTag();
  }
});

export default AgentTagWidgetView;

