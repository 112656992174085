/* eslint no-invalid-this: "off" */
import App from 'app'
import SandboxController from 'controllers/sandboxController'

/**
 * Sandbox module
 *
 * The sandbox module allows you to get a blank slate content area
 * very easily. It will automatically set up an event listener on
 * your view, so you can follow the events you trigger in the
 * console.
 *
 * Just add a new file controllers/sandboxes/foobar.js and going
 * to /sandbox/foobar will load that file. You need to implement
 * the expected interface and return an object that:
 *
 *   - has a setup method,
 *   - has a view property (which may be set by the setup method).
 *
 * See the default sandbox implementation (sandboxes/default.js)
 * for an example.
 */
App.module('Sandbox', {
  startWithParent: true,
  define: function () {
    App.Sandbox._createController = function () {
      this.controller = new SandboxController();
    };

    this._createController();

    this.addInitializer(function () {
      this.controller.ready();
    });
  }
});

export default App.Sandbox;

