import Marionette from 'backbone.marionette';
import NewsroomSummaryTimelineView from 'views/newsroom/newsroomSummaryTimelineView';
import when from 'when';
import _ from 'underscore';
import jQuery from 'jquery'
import I18n from 'i18n';
import SendNotificationModalView from 'views/newsroom/sendNotificationModalView';
import NotificationSentModal from 'views/newsroom/notificationSentModalView';
import NewsroomSummaryTimelineListView
    from 'views/newsroom/newsroomSummaryTimelineListView';
import moment from 'moment';
import usersInstance from 'collections/usersInstance';
import Analytics from 'libraries/analytics';

class NewsroomSummaryTimelineController extends Marionette.Controller {
    initialize(options) {
        this.selectedReaderConfigurationId = options.selectedReaderConfigurationId;
        this.view = new NewsroomSummaryTimelineView();
        _.bindAll(this,
                  '_sendNotification');
        this.listenTo(this.view,
                      'render',
                      this._onViewRender);
    }

    _registerListeners() {
        let self = this;
        this.listenTo(this.listView,
                      'itemview:addSummary',
                      function (view) {
                          self.view.trigger('itemview:addSummary', view);
                      });
        this.listenTo(this.listView,
                      'itemview:editSummary',
                      function (view) {
                          self.view.trigger('itemview:editSummary', view);
                      });

        this.listenTo(this.listView,
                      'itemview:toggleArchiveDate',
                      this._handleArchiveDateToggle);

        this.listenTo(this.listView,
                      'itemview:clearAllHighlights',
                      this.clearHighlightFromAllArchiveDates);

        this.listenTo(this.listView,
                      'itemview:bellClicked',
                      (view, id) => {
                          this.bellItemView = view;
                          this.showNotificationModal(id);
                      });

    }

    _onViewRender() {
        this.listView = new NewsroomSummaryTimelineListView({
            selectedReaderConfigurationId: this.selectedReaderConfigurationId,
        });
        this._initialFetch();
        this._setUpNotificationModalView();
        this.view.showList(this.listView);
        this._registerListeners();
    }

    _setUpNotificationModalView() {
        let title =
            I18n.t('webapp.reader_admin.notifications.modal_manual_sending.headline');
        let body =
            I18n.t('webapp.reader_admin.notifications.modal_manual_sending.body');
        let confirmLabel =
            I18n.t('webapp.reader_admin.notifications.modal_manual_sending.button_send');
        let cancelLabel =
            I18n.t('webapp.reader_admin.notifications.modal_manual_sending.button_cancel');
        let props = {
            title: title,
            body: body,
            confirmLabel: confirmLabel,
            cancelLabel: cancelLabel,
            readerConfigurationId: this.selectedReaderConfigurationId
        };
        this.modalView = new SendNotificationModalView(props);
        this.view.showModal(this.modalView);

        this.listenTo(this.modalView,
                'modal:confirm',
                this._sendNotification);
    }

    showNotificationModal(id) {
        this.archiveDateId = id;
        this.modalView.showModal();
    }

    _sendNotification(selectedNotificationConfigurationId) {
        let self = this;
        this.modalView.disableButtons();
        const data = {
          id: self.archiveDateId,
          notification_configuration_id: selectedNotificationConfigurationId
        }
        Analytics.sendEvent(
            'br_manual_notification_sent',
            {reader_id: self.selectedReaderConfigurationId}
        );
        jQuery.post('/api/v3/notifications', data , function () {
            let title =
                I18n.t('webapp.reader_admin.notifications.modal_manual_sending.headline');
            let body = I18n.t('webapp.reader_admin.notifications.modal_manual_sending.body_success');
            let closeLabel =
                I18n.t('webapp.reader_admin.notifications.modal_manual_sending.button_close');
            let props = {
                title: title,
                body: body,
                closeLabel: closeLabel
            };
            self.modalView = new NotificationSentModal(props);
            self.view.showModal(self.modalView);
            self.modalView.showModal();
            self.listenTo(self.modalView,
                    'modal:closed',
                    self._setUpNotificationModalView);

            self.bellItemView.model.get('archive_date_notifications').push({
                sent_at: moment().toString(),
                automatic: false,
                user_id: usersInstance.currentUser.id
            });
            self.bellItemView.updateTooltipWithNotification();
        })
        .fail(function (data, status, err) {
          self._setUpNotificationModalView();
          throw(
            "\narchiveDateId: " + self.archiveDateId +
              "\nstatus: " + status +
              "\nerror: " + err +
              "\ndata:\n" + JSON.stringify(data)
          );
        });
    }

    clearHighlightFromAllArchiveDates() {
        this.listView.children.each((itemView) => { itemView.clearHighlight(); });
    }

    _expandTopArchiveDates(count) {
        let firstThree = this.listView.children.toArray().slice(0, count);

        _.each(firstThree, (itemView) => {
            this._handleArchiveDateToggle(itemView);
        });
    }

    _handleArchiveDateToggle(itemView) {
        if (itemView.archiveDateCollapsed) {
            itemView.showPreloader();
            when(itemView.summaryModel.fetch()).then(() => {
                if (!itemView.isClosed) { itemView.reload(); }
            })

            .catch((error) => {
                if (error.status === 404) {
                    if (!itemView.isClosed) { itemView.showAddSummaryButton(); }
                }
            })

            .done(() => {
                if (!itemView.isClosed) { itemView.hidePreloader(); }
            });
        } else {
            itemView.collapseArchiveDate();
        }
    }

    _initialFetch() {
        this.listView.collection.fetch().done(() => {
            this.listView.showTimeline();
            this._expandTopArchiveDates(3);
        });
    }
}

export default NewsroomSummaryTimelineController;
