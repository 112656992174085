import * as _ from "underscore";
import * as Backbone from "backbone";
import SourceSearch from "collections/sourceSearch";
import Sources from "collections/sources";
import Source from "models/source";
import SourceExclusion from "models/sourceExclusion";

class SourceExclusionSearchPropFactory {
  seenSources: Backbone.Collection<Source>;
  selectedSources: Backbone.Collection<Source|SourceExclusion>;
  onSelection: (source: Source) => void;
  page?: string;

  constructor(
    sources: Backbone.Collection<Source>,
    onSelection: (source: Source) => void,
    page?: string
  ) {
    _.bindAll(this, "queryCallback", "selectedCallback", "generateSelect2Results");
    this.seenSources = new Sources();
    sources.each((source: Source) => {
      this.seenSources.add(source);
    });
    this.selectedSources = sources;
    this.onSelection = onSelection;
    this.page = page;
  }

  generate() {
    return {
        queryCallback: this.queryCallback,
        selectionCallback: this.selectedCallback,
        multiselect: true,
        minimumInputLength: 3,
        select2Id: "sourceExclusion"
    };
  }

  protected queryCallback(query: any) {
    let sourceSearch = new SourceSearch([], { query: query.term.trim() });
    let promise = sourceSearch.fetch();
    promise.done(() => {
      this.registerSources(sourceSearch);
      query.callback(this.generateSelect2Results(sourceSearch));
    }).fail(() => query.callback({ results: [] }));
  }

  protected selectedCallback(id: any) {
    let source = this.seenSources.get(id);
    this.onSelection(source);
  }

  protected registerSources(sourceSearch: Backbone.Collection<Source>) {
    sourceSearch.each((source: Source) => { this.seenSources.add(source); }, this);
  }

  protected generateSelect2Results(sourceSearch: Backbone.Collection<Source>) {
    // filter out sources that are already in the list
    let filtered = sourceSearch.filter((source: Source) => {
      return !this.selectedSources.findWhere({ id: source.id });
    });

    let results = _.map(filtered, (source: Source) => {
      return { id: source.id, text: source.get("name") };
    });

    return { results: results };
  }
}

export default SourceExclusionSearchPropFactory;
