import I18n from 'i18n';
import Holiday from './holiday';

export default function germanHolidays(): Holiday[] {
  return [
    {
      id: 'de',
      region: 'de',
      name: I18n.t(
        'webapp.reader_admin.edit.weekday_schedule.national_germany'
      ),
    },
    {
      id: 'de_bb',
      region: 'de_bb',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.bb'),
    },
    {
      id: 'de_be',
      region: 'de_be',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.be'),
    },
    {
      id: 'de_bw',
      region: 'de_bw',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.bw'),
    },
    {
      id: 'de_by',
      region: 'de_by',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.by'),
    },
    {
      id: 'de_hb',
      region: 'de_hb',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.hb'),
    },
    {
      id: 'de_he',
      region: 'de_he',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.he'),
    },
    {
      id: 'de_hh',
      region: 'de_hh',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.hh'),
    },
    {
      id: 'de_mv',
      region: 'de_mv',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.mv'),
    },
    {
      id: 'de_ni',
      region: 'de_ni',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.ni'),
    },
    {
      id: 'de_nw',
      region: 'de_nw',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.nw'),
    },
    {
      id: 'de_rp',
      region: 'de_rp',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.rp'),
    },
    {
      id: 'de_sh',
      region: 'de_sh',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.sh'),
    },
    {
      id: 'de_sl',
      region: 'de_sl',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.sl'),
    },
    {
      id: 'de_sn',
      region: 'de_sn',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.sn'),
    },
    {
      id: 'de_st',
      region: 'de_st',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.st'),
    },
    {
      id: 'de_th',
      region: 'de_th',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.de.th'),
    },
  ];
}
