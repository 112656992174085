import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/readerConfigurationDateExclusion.html';
import excludedDateTemplate
    from 'text-loader!templates/newsroom/readerConfigurationExcludedDate.html';
import $ from 'jquery';
import _ from 'underscore';
import moment from 'moment';
import I18n from 'i18n'

class ReaderConfigurationDateExclusionView extends Marionette.ItemView {
    initialize(options) {
        this.excludedDates = options.excludedDates;
    }

    onRender() {
        _.each(this.excludedDates, (excludedDate) => {
            let date = moment(excludedDate, 'YYYY/MM/DD').format('DD.MM.YYYY');
            this._renderExcludedDate({ date: date });
        });

        this.ui.dateExclusionSelector.bootstrapDatepicker({
            startDate: new Date(2009, 1, 1),
            autoclose: true,
            endDate: '+5y',
            orientation: 'auto',
            language: I18n.locale,
            format: 'yyyy-mm-dd',
            weekStart: 1
        });
    }

    _renderExcludedDate(excludedDate) {
        let excludeHtml = Marionette.Renderer.render(
            excludedDateTemplate, excludedDate
        );
        $(excludeHtml).insertBefore(this.ui.excludeDateInput);
    }

    _handleDateChange() {
        let excludeDate = this.ui.dateExclusionSelector.val();
        let formattedDate = moment(excludeDate, 'YYYY-MM-DD')
            .format('YYYY/MM/DD');

        if (!_.contains(this.excludedDates, formattedDate)) {
            this.excludedDates.push(formattedDate);
        }

        this.trigger('update:excludedDate', this.excludedDates);
        this.render();
    }

    _handleExcludedDateRemoval(e) {
        let excludeDate = e.currentTarget.dataset.removeExcludedDate;
        let formattedDate = moment(excludeDate, 'DD.MM.YYYY')
            .format('YYYY/MM/DD');
        this.excludedDates = _.without(this.excludedDates, formattedDate);
        this.trigger('update:excludedDate', this.excludedDates);
        this.render();
    }
}

ReaderConfigurationDateExclusionView.prototype.template = template;
ReaderConfigurationDateExclusionView.prototype.ui = {
    dateExclusionSelector: '[data-date-exclusion-picker]',
    excludedDates: '[data-excluded-dates]',
    removeExcludedDate: '[data-remove-excluded-date]',
    excludeDateInput: '[data-exclude-date-input]'
};
ReaderConfigurationDateExclusionView.prototype.events = {
    'change @ui.dateExclusionSelector': '_handleDateChange',
    'click @ui.removeExcludedDate': '_handleExcludedDateRemoval'
};

export default ReaderConfigurationDateExclusionView;
