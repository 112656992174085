import Marionette from 'backbone.marionette';
import when from 'when';
import I18n from 'i18n';
import Notificator from 'libraries/notificator';
import ReaderNewsroomSummaryLayout
    from 'views/newsroom/readerNewsroomSummaryLayout';
import BasicDeleteModalView from '../../views/basicDeleteModalView';

class EditNewsroomSummaryController extends Marionette.Controller {
    initialize(options) {
        this.model = options.model;
        this.selectedLanguage = this.model.getDefaultLanguage();
        this.view = new ReaderNewsroomSummaryLayout({
            model: this.model,
            selectedLanguage: this.selectedLanguage
        });
        this.listenTo(this.view, 'save', this._saveModel);
        this.listenTo(this.view, 'delete', this._showModal);
        this.listenTo(this.view, 'languageChanged', this._registerLanguage);
    }

    _registerLanguage(language) {
        this.selectedLanguage = language; 
    }

    _showModal() {
        const modal = this._createDeletionModal();
        this.view.showDeleteModal(modal);
        modal.showModal();
        this.listenTo(modal, 'modal:delete', this._deleteSummary)
    }

    _deleteSummary() {
        this.trigger('close:summaryEditForm');
        when(this.model.deleteSummary(this.selectedLanguage)).catch(() => {
            Notificator.showErrorNotification(I18n.t('webapp.notifications.error.not_saved'))
        })
    }

    _saveModel() {
        this.model.setTitle(this.selectedLanguage,
            this.view.getSummaryTitle());
        this.model.setText(this.selectedLanguage,
            this.view.getSummaryText());
        when(this.model.saveSummary(this.selectedLanguage))
        .catch((error) => {
            if (error.responseText === '{"error":"Badly formed summary update request"}') {
                Notificator.showErrorNotification(
                    I18n.t('webapp.reader_admin.summaries.validations.title_missing')
                );
            } else {
                Notificator.showErrorNotification(
                    I18n.t('webapp.notifications.error.not_saved')
                );
            }
        });
    }

    _createDeletionModal() {
        const title =
            I18n.t("webapp.reader_admin.summaries.delete_modal.confirmation");
        const description =
            I18n.t("webapp.agents.edit.settings.delete.delete_warning");
        const deleteLabel =
            I18n.t("webapp.reader_admin.summaries.edit.delete");
        const cancelLabel =
            I18n.t("webapp.agents.edit.settings.delete.cancel");

        const opts = {
            confirmation: title,
            warning: description,
            title: deleteLabel,
            deleteLabel: deleteLabel,
            cancelLabel: cancelLabel,
        };

        const deletionModal = new BasicDeleteModalView(opts);

        return deletionModal;
    }
}

export default EditNewsroomSummaryController;
