import _ from 'underscore'

var CloseSubObjects = {
  closeAll: function (subObjects) {
    var allObjects = subObjects;

    if (!_.isArray(subObjects)) {
      allObjects = new Array(subObjects);
    }

    _.each(allObjects, function(object) {
      if (!_.isUndefined(object)) { object.close(); }
    });
  }
};

export default CloseSubObjects;
