import RestorableModel from 'libraries/restorableModel'
import Config from 'libraries/configuration'
import pusherWrapper from 'models/pusherWrapper'
import _ from 'underscore'
import I18n from 'i18n'

var Tag = RestorableModel.extend({
  urlRoot: function () {
    return Config.apiUrl + "tags";
  },
  toJSON: function () {
    // add socket id
    var json = RestorableModel.prototype.toJSON.call(this);
    json.socket_id = pusherWrapper.socketId;

    return json;
  },
  isIncomplete: function () {
    return _.isUndefined(this.get('clip_count'));
  },
  isManual: function() {
    return this.get('kind') === 'manual';
  },
  visibleName: function () {
    var name = this.get('name');
    var kind = this.get('kind');
    var translationKeyMapping = {
      agent: 'search_agent',
      mention: 'mention'
    };
    var translationKey = translationKeyMapping[kind];
    if (translationKey) {
      return name.replace(/__ID\.\d+$/, ' (' +
                          I18n.t('webapp.tags.kinds.' + translationKey) + ')');
    } else {
      return name;
    }
  }
});

export default Tag;

