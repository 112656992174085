import * as React from "react";
import 'bpopup';
import Modal from "components/modal";

interface IModal {
  title: string
  cancelLabel: string
  confirmationLabel: string
  onConfirm: () => void;
  onClose: () => void;
  modalId: string;
  open?: boolean;
  close?: boolean;
  children: any;
  onKeyDown: (e: any) => void;
}

class Modal2 extends React.Component<IModal> {
  modal: React.RefObject<any> = React.createRef();

  render() {
    const modalStyle = { display: "none" };

    return (
      <div className="modal" ref={this.modal}
          id={this.props.modalId}
          style={modalStyle}>
        <div className="modal__head">
          <h2 className="modal__head-title">{this.props.title}</h2>
        </div>
        <div className="modal__body">
          <p>{this.props.children}</p>
        </div>
        <div className="modal__footer">
          <div className="action-bar">
            <div className="col1of2">
              <div className="action-bar__action">
                <a className="button button--block button--small b-close">
                  {this.props.cancelLabel}
                </a>
              </div>
            </div>
            <div className="col1of2">
              <div className="action-bar__action">
                <a className="button button--block button--small button--negative"
                  onClick={() => { this.props.onConfirm(); }}
                  onKeyDown={(e) => this.props.onKeyDown(e)}
                  tabIndex={0}
                >
                  {this.props.confirmationLabel}
                </a>
              </div>
            </div>
          </div>
        </div>
        <span className="modal__close b-close"></span>
      </div>
    );
  }

  opts() {
    return {
      onClose: () => {
        // remove the html that gets added directly to body when modal gets shown
        this.removeModal()
        // we need to rerender the html in its original location as it is removed
        // by the popup library each time the popup is displayed :-(
        this.props.onClose()
      }
    }
  }

  removeModal() {
    setTimeout(() => {
      const selector = 'body > #' + this.props.modalId
      const popup = ($(selector) as any)
      popup.remove()
      $('body > .b-modal').remove()
    })
  }

  componentDidUpdate() {
    if (this.props.open) {
      this.modal.current.focus();
      setTimeout(() => {
        ($(`#${this.props.modalId}`) as any).bPopup(this.opts());
      })
    }

    if (this.props.close) {
      this.closeModal()
    }
  }

  closeModal() {
    setTimeout(() => {
      const popup = ($('body > #' + this.props.modalId) as any)
      popup.bPopup(this.opts()).close()
    })
  }
}

export default Modal2;
