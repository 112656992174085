/*global window*/
// WidgetView base class
//
// To define a new subclass, define it (see agentChartWidgetView.js)
// and load it in widgetGridView.js.
import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/dashboard/widget/unsupportedWidget.html'
import _ from 'underscore'
import moment from 'moment'
import $ from 'jquery'

var WidgetView = Marionette.ItemView.extend({
  template: template,
  tagName: 'li',
  className: 'widget',
  ui: {
    widgetBody: '[data-widget-body]'
  },
  _truncateLabel: function (label, maxLength) {
    if (_.isUndefined(maxLength)) {
      maxLength = 20;
    }
    return (label.length > maxLength) ? label.substr(0, maxLength) + "…" : label;
  },
  _convertTimestamps: function (chartData) {
    return _.map(chartData, function (n) {
      return [
        n[0] * 1000,
        n[1]
      ];
    });
  },
  triggers: {
    'click h1': 'click:h1',
    'mouseover': 'hover',
    'mouseleave': 'hover:stop'
  },
  initialize: function () {
    _.bindAll(this,
              '_showOverlayAfterRerender',
              '_handleModelChange',
              '_handleLoading',
              '_handleLoadingFinished');
    this.onInitialize();
    this.listenTo(this.model, 'change', this._handleModelChange);
    this.listenTo(this.model, 'loading', this._handleLoading);
    this.listenTo(this.model, 'loading-finished', this._handleLoadingFinished);
    this.on('render', this._showOverlayAfterRerender);
    var self = this;
    this.on('resize', function () { self.render(); });
  },
  onRender: function () {
    this.ui.widgetBody.hide();
    if (this.model.isAllDataReady) {
      this.prepareWidgetBody();
      this.ui.widgetBody.show();
    }
  },
  prepareWidgetBody: function () {
    // override this method in the subclass it shall contain the code
    // that prepares all the needed data for the widget.
    //
    // this method can safely assume that all data is present in the
    // model.
  },
  serializeData: function () {
    var common_data = { name: this.model.get("name") };

    if (this.model.isAllDataReady) {
      return _.extend(this.prepareSerializedData(), common_data);
    } else {
      return common_data;
    }
  },
  prepareSerializedData: function () {
    // override this method in the subclass it shall contain the code
    // that prepares all the needed data for the widget.
    //
    // this method can safely assume that all data is present in the
    // model.
    return {};
  },
  showLoadingIndicator: function () {
    $(this.el).addClass("loading-indicator");
  },
  removeLoadingIndicator: function () {
    $(this.el).removeClass("loading-indicator");
  },
  showFetchingIndicator: function () {
    $(this.el).addClass("fetching-indicator");
  },
  removeFetchingIndicator: function () {
    $(this.el).removeClass("fetching-indicator");
  },
  _handleLoading: function () {
    this.showLoadingIndicator();
  },
  _handleLoadingFinished: function () {
    this.removeLoadingIndicator();
  },
  _handleModelChange: function () {
    var changes = this.model.changed;
    if (changes.size_x || changes.size_y) {
      this.trigger('resize');
    }
  },
  refresh: function (options) {
    var self = this;

    if (options !== undefined && options.visualFeedback === true) {
      this.showFetchingIndicator();
    }

    this.model.fetch({
      success: function () { self.onSuccessfulFetch(); },
      error: function () { self.onFailedFetch(); }
    });
  },
  onSuccessfulFetch: function () {
    this.model.isAllDataReady = true;
    this.render();
    this.removeFetchingIndicator();
  },
  onFailedFetch: function () {
    this.model.isAllDataReady = false;
  },
  enableAutoRefresh: function () {
    var self = this;
    this.autoRefresh = window.setInterval(function () { self.refresh(); }, this.refreshInterval());
  },
  disableAutoRefresh: function () {
    window.clearInterval(this.autoRefresh);
  },
  refreshInterval: function () {
    return moment.duration(1, 'hour');
  },
  onInitialize: function () {
    //override in derived classes for extended intialize
  },
  chartBackgroundColor: function (color) {
    // Defaults to transparent, 'transparent' does not work on
    // IE 8.0, that's why we give back rgba.
    if (_.isNull(color) || color === 'transparent') {
      return 'rgba(255, 255, 255, 0.1)';
    } else {
      return color;
    }
  },
  showOverlay: function (overlay) {
    this._overlay = overlay;
    $(this.el).append(overlay.el);
  },
  removeOverlay: function () {
    if (!_.isUndefined(this._overlay)) {
      $(this._overlay.el).detach();
      this._overlay = undefined;
    }
  },
  hasOverlay: function () {
    return !_.isUndefined(this._overlay);
  },
  _showOverlayAfterRerender: function () {
    var isDetached = function (el) {
      return !$(el).closest('html').length;
    };
    if (this._overlay && isDetached(this._overlay.el)) {
      this.showOverlay(this._overlay);
    }
  }
});

export default WidgetView;

