import session from 'models/sessionInstance'
import MetadataMMOView from 'views/agents/metadata/MMOView'
import MetadataTwitterView from 'views/agents/metadata/TwitterView'
import MetadataFacebookView from 'views/agents/metadata/FacebookView'
import MetadataYoutubeView from 'views/agents/metadata/YoutubeView'
import MetadataDefaultView from 'views/agents/metadata/DefaultView'
import MetadataEMMView from 'views/agents/metadata/EmmView'
import MetadataInstagramView from 'views/agents/metadata/InstagramView'
import MetadataGooglePlusView from 'views/agents/metadata/GooglePlusView'
import MetadataImportedView from 'views/agents/metadata/ImportedView'
import MetadataDpaView from 'views/agents/metadata/DpaView'
import MetadataCuriaView from 'views/agents/metadata/CuriaView'
import MetadataSopranoArgusView from "views/agents/metadata/SopranoArgusView";
import MetadataSopranoParlamentChView from "views/agents/metadata/SopranoParlamentChView";
import MetadataSopranoAdminChPressReleaseView from
    "views/agents/metadata/SopranoAdminChPressReleaseView";
import MetadataSopranoAdminChConsultationView from
    "views/agents/metadata/SopranoAdminChConsultationView";
import MetadataDictionaryView from 'views/agents/metadata/DictionaryView'
import MetadataSopranoSdaView from 'views/agents/metadata/SdaView'
import MetadataSopranoBlogView from 'views/agents/metadata/BlogView'
import MetadataSopranoVerbalixView from 'views/agents/metadata/VerbalixView'
import MetadataSopranoAdminChOfficialCompilationView from
    'views/agents/metadata/SopranoAdminChOfficialCompilationView';
import MetadataSopranoAdminChFederalGazetteView from
    'views/agents/metadata/SopranoAdminChFederalGazetteView';
import MetadataDataLakeView from 'views/agents/metadata/DataLakeView';

export default function (agentResult) {
  if (agentResult.isDpaClip()) {
    return (new MetadataDpaView({ agentResult: agentResult }))
  } else if (agentResult.isSopranoArgusClip()) {
    return (new MetadataSopranoArgusView({ agentResult: agentResult }));
  } else if (agentResult.isSopranoParlamentChClip()) {
    return (new MetadataSopranoParlamentChView({ agentResult: agentResult }));
  } else if (agentResult.isSopranoAdminChPressReleaseClip()) {
    return (new MetadataSopranoAdminChPressReleaseView({ agentResult: agentResult }));
  } else if (agentResult.isSopranoAdminChConsultationClip()) {
    return (new MetadataSopranoAdminChConsultationView({ agentResult: agentResult }));
  } else if (agentResult.isSopranoAdminChConsultationDocumentClip()) {
    return (new MetadataSopranoAdminChConsultationView({ agentResult: agentResult }));
  } else if (agentResult.isSopranoAdminChOfficialCompilationClip()) {
    return (new MetadataSopranoAdminChOfficialCompilationView({ agentResult: agentResult }));
  } else if (agentResult.isSopranoAdminChFederalGazetteClip()) {
    return (new MetadataSopranoAdminChFederalGazetteView({ agentResult: agentResult }));
  } else if (agentResult.isMMOClip()) {
    return (new MetadataMMOView({ agentResult: agentResult }));
  } else  if (agentResult.isTwitterClip()) {
    return (new MetadataTwitterView({ agentResult: agentResult }));
  } else  if (agentResult.isFacebookClip()) {
    return (new MetadataFacebookView({ agentResult: agentResult }));
  } else  if (agentResult.isYoutubeClip()) {
    return (new MetadataYoutubeView({ agentResult: agentResult }));
  } else if (agentResult.isRtvClip()) {
    return (new MetadataEMMView({ agentResult: agentResult }));
  } else if (agentResult.isInstagramClip()) {
    return (new MetadataInstagramView({ agentResult: agentResult }));
  } else if (agentResult.isGooglePlusClip()) {
    return (new MetadataGooglePlusView({ agentResult: agentResult }));
  } else if (agentResult.isImportedClip()) {
    return (new MetadataImportedView({ agentResult: agentResult, userCountry: session.get('country') }))
  } else if (agentResult.isCuriaClip()) {
    return (new MetadataCuriaView({ agentResult: agentResult }))
  } else if (agentResult.isDictionaryClip()) {
    return (new MetadataDictionaryView({ agentResult: agentResult }))
  } else if (agentResult.isSopranoParlamentSdaClip()) {
    return (new MetadataSopranoSdaView({ agentResult: agentResult }))
  } else if (agentResult.isSopranoBlogClip()) {
    return (new MetadataSopranoBlogView({ agentResult: agentResult }))
  } else if (agentResult.isVerbalixClip()) {
    return (new MetadataSopranoVerbalixView({ agentResult: agentResult }))
  } else if (agentResult.isDataLakeClip()) {
    return (new MetadataDataLakeView({ agentResult: agentResult, userCountry: session.get('country') }))
  } else {
    return (new MetadataDefaultView({ agentResult: agentResult, userCountry: session.get('country') }));
  }
}
