import Backbone from 'backbone';
import agents from 'collections/agentsInstance';
import _ from 'underscore';
import AgentResult from 'models/agentResult';

class ReaderResult extends Backbone.Model {
    initialize() {
        this.set({archiveDateId: this.collection.readerArchiveDateId});
    }

    parse(response) {
        let data = response.object || response;

        return _.extend({},
                        this._buildAgentResult(data),
                        data);
    }

    _buildAgentResult(data) {
        var agentResult =
            new AgentResult(data.agent_result, { parse: true });
        var ar = {
            agent_result: agentResult
        };

        delete data.agent_result;
        return ar;
    }

    isOwnedByCurrentUser() {
        return true;
    }

    downloadable() {
        let agentResult = this.get('agent_result');
        return agentResult.attributes.is_print_source;
    }

    updatePosition(opts) {
        let updateUrl = this.urlRoot + this.get('id');
        let options = {
            url: updateUrl,
            type: 'PUT'
        };
        _.extend(options, opts);

        return this.sync.call(this, null, this, options);
    }

    batchUpdate(opts) {
        let updateUrl = this.urlRoot;
        let options = {
            url: updateUrl + 'positions',
            type: 'PUT'
        };
        _.extend(options, opts);

        return this.sync.call(this, null, this, options);
    }

    destroyResultAndTags(opts) {
        let deleteUrl = '/api/v3/reader_results_with_tags/' + this.get('id');
        let origOnSuccess = opts.success;
        let onSuccess = () => {
            this.id = null;
            this.destroy();
            origOnSuccess();
        };

        opts.success = onSuccess;

        let options = {
            url: deleteUrl,
            type: 'DELETE',
        };
        _.extend(options, opts);

        return this.sync.call(this, null, this, options);
    }

    downloadableWithoutDownloadFeature() {
        return true;
    }

    agentResultData() {
        let agentResult   = this.get('agent_result');
        let metadata      = agentResult.attributes;
        let primarySource = metadata.primary_source;
        let sources       = metadata.sources;
        let additionalSourceCount   = 0;
        let agent = agents.get(agentResult.get('agent_id'));
        let color;
        if (agent) {
            color = agent.get('colors')['default'];
        } else {
            color = '#006699';
        }

        if (sources && sources.length > 1) {
            additionalSourceCount = sources.length - 1;
        }

        return {
            color: color,
            primarySourceName: primarySource.name,
            additionalSourceCount: additionalSourceCount,
            publishTime: metadata.clip_created_at,
            title: metadata.title,
            text: agentResult.get('text'),
            mentions: agentResult.get('mentions'),
            primarySourceFavicon: primarySource.favicon_url,
            isImportant: agentResult.get('important')
        };
    }
}
ReaderResult.prototype.urlRoot = '/api/v3/reader_results/';

export default ReaderResult;
