import Marionette from 'backbone.marionette';
import RichTextEditorView from 'views/components/richTextEditorView';
import _ from 'underscore';

class RichTextEditorController extends Marionette.Controller {
    initialize(options) {
        let richText = options.richText;
        const plainText = options.plainText;
        const placeholderText = options.placeholderText;
        if (!_.isString(richText) && _.isString(plainText)) {
                richText = this._convertToRichText(plainText);
        }
        this.view = new RichTextEditorView({
            richText: richText,
            placeholderText: placeholderText,
            isSummaryEdit: options.isSummaryEdit
        });
        this._registerListeners();
    }

    onClose() {
        this.view.close();
    }

    setRichText(richText) {
        this.view.setRichText(richText);
    }

    getRichText() {
        return this.view.getRichText();
    }

    setPlainText(plainText) {
        this.view.setRichText(this._convertToRichText(plainText));
    }

    getPlainText() {
        return this._convertToPlainText(this.view.getRichText());
    }

    focus() {
        this.view.focusOnText();
    }

    _registerListeners() {
        this.listenTo(this.view, 'change:text', this._forwardChangeEvent);
    }

    _forwardChangeEvent() {
        this.trigger('change:text');
    }

    _convertToRichText(plainText) {
        const doubleNewLines = /\n{2,}|\r{2,}|(?:\r\n){2,}/;
        const paragraphs = plainText.split(doubleNewLines);
        return '<p>' + paragraphs.join('</p><p>') + '</p>';
    }

    _convertToPlainText(richText) {
        const newlineCandidate = /^\/(?:p|li|h\d|normal)|^br$/i;
        return richText.replace(/<(\/?\w+).*?>/g, (tag, name) => {
            return name.match(newlineCandidate) ? '\n\n' : '';
        });
    }
}

export default RichTextEditorController;
