import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as jQuery from 'jquery'
import I18n from 'i18n'

export interface Props {
  onDrop: (files: FileList) => void
  enabled: boolean
}

export default class Dropzone extends React.Component<Props,{}> {
  element?: HTMLDivElement

  render() {
    return (
      <div ref={this.assignRef.bind(this)} className="dragdrop">
      {this.props.children}
      <div className="dragdrop__area">
        <div className="dragdrop__content">
          <h2 className="dragdrop__title">
            {I18n.t('webapp.agents.results.gallery.dragarea')}
          </h2>
        </div>
      </div>
      </div>
    )
  }

  assignRef(div: HTMLDivElement) {
    this.element = div
  }

  componentDidMount() {
    if (this.props.enabled) {
      this.setupDropZone()
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.enabled && this.props.enabled) {
      this.setupDropZone()
    } else if (prevProps.enabled && !this.props.enabled) {
      this.removeDropZone()
    }
  }

  removeDropZone() {
    let $el = jQuery(this.getElement())
    $el.unbind('dragenter dragover dragleave drop')
  }

  getElement(): HTMLDivElement {
    if (this.element) {
      return this.element
    } else {
      throw("this.element is undefined")
    }
  }

  setupDropZone() {
    let $el = jQuery(this.getElement())
    let eventCounter = 0;
    $el.on('dragenter', () => {
      this.addFocusClass()
      eventCounter++
      return false
    })

    $el.on('dragleave', () => {
      eventCounter--
      if (eventCounter <= 0) {
        this.removeFocusClass()
      }
    })

    $el.on('dragover', () => false)

    let eventName: any = "drop"
    $el.on(eventName, (event: JQueryEventObject) => {
      event.preventDefault()
      this.removeFocusClass()
      eventCounter = 0
      let originalEvent = event.originalEvent as DragEvent
      let files = originalEvent.dataTransfer.files
      this.props.onDrop(files)
    })
  }

  addFocusClass() {
    jQuery(this.getElement()).addClass('dragdrop--focus')
  }

  removeFocusClass() {
    jQuery(this.getElement()).removeClass('dragdrop--focus')
  }
}
