import Marionette from 'backbone.marionette'
import _ from 'underscore'
import $ from 'jquery'
import template from 'text-loader!templates/components/editableTitle.html'

var EditableTitleView = Marionette.ItemView.extend({
  initialize: function(options) {
    this.model = options.model;
    this.titleFieldName = options.titleFieldName;
    this.isAgent = options.isAgent;
  },

  template: template,

  regions: {
    deletion: '[data-editable-title]'
  },

  ui: {
    header: '[data-editable-title]'
  },

  events: {
    'click [data-editable-title]': 'enableInplaceEditForAgentHeading',
    'keyup [data-editable-title] input': 'saveOrCancelOrContinue',
    'blur [data-editable-title] input': 'assignNewTitle'
  },

  enableInplaceEditForAgentHeading: function() {
    if (!this.isHeaderInEditMode()) {
      $(this.ui.header).addClass('is-active');
      $(this.ui.header).find('input').val(this._title());
      $(this.ui.header).find('div').hide();
      $(this.ui.header).find('input').show();
      $(this.ui.header).find('input').focus();
    }
  },

  isHeaderInEditMode: function() {
    return $(this.ui.header).hasClass('is-active');
  },

  abortEdit: function() {
    if (this.isHeaderInEditMode()) {
      $(this.ui.header).find('div h1').text(this._title());
      $(this.ui.header).removeClass('is-active');
      $(this.ui.header).find('div').show();
      $(this.ui.header).find('input').hide();
    }
  },

  focusOnNameEdit: function() {
    if (this.isAgent &&
        !this.model.creationFinished() &&
        !this.options.readOnly) {
      $(this.ui.header).find('input').click().select();
    }
  },

  onRender: function() {
    var _this = this;
    if (this.isAgent) {
      setTimeout(function() {
        _this.focusOnNameEdit();
      }, 100);
    }
  },

  saveOrCancelOrContinue: function(e) {
    switch (e.keyCode) {
    case 13: // Enter key pressed
      this.assignNewTitle(); // save
      break;
    case 27: // Esc key pressed
      this.abortEdit(); // cancel
      break;
    default:
      return; // continue
    }
  },

  assignNewTitle: function() {
    if (this.isHeaderInEditMode()) {
      if (!_.isEmpty(this._newTitle())) {
        this.model.set(this.titleFieldName, this._newTitle());
        this._triggerModelUpdate(this.titleFieldName);
      } else {
        // Restore old if blank
        $(this.ui.header).find('input').text(this._title());
      }

      this.disabledInplaceEdit();
    }
  },

  disabledInplaceEdit: function() {
    if (this.isHeaderInEditMode()) {
      this.abortEdit();
    }
  },

  _newTitle: function() {
    return $(this.ui.header).find('input').val();
  },

  _triggerModelUpdate: function(attributeName) {
    if (this.model.changedAttributes([attributeName])) {
      this.model.trigger('changed:' + attributeName);
    }
  },

  serializeData: function() {
    return {
      title: this._title()
    };
  },

  _title: function () {
    return this.model.get(this.titleFieldName);
  }
});

export default EditableTitleView;

