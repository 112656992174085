import $ from 'jquery'
import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/socialMediaTrackerChartWidget.html'
import LineChart from 'views/dashboard/widgetView/helpers/lineChart'

WidgetView.SocialMediaTrackerChartWidget = WidgetView.extend({
  template: template,
  onInitialize: function () {
    this.enableAutoRefresh();
  },
  prepareWidgetBody: function () {
    var container = this.$('div.chart')[0];
    var backgroundColor = this.chartBackgroundColor($(container).css('background-color'));

    this.chart = new LineChart(container, {
      chart: {
        backgroundColor: backgroundColor,
        width: 246,
        height: 140
      }
    });

    this.chart.addSeries({
      color: this.model.get('color'),
      data: this._convertTimestamps(this.model.get('chart_data'))
    });

    this.chart.render();
  },
  truncatedLabel: function () {
    return this._truncateLabel(this.model.get('name'));
  },
  prepareSerializedData: function () {
    var values = {
      truncatedLabel: this.truncatedLabel(),
      clipCount: this.model.clipCount(),
      color: this.model.get('color')
    };
    return values;
  }
});

export default WidgetView.SocialMediaTrackerChartWidget;

