import * as React from "react";
import { connect } from "react-redux";
import {
  update,
  SelectedDateRange,
  dateRanges,
  dateRangeIdx
} from "../redux/selectedDateRange";
import { Store } from "../redux/store";
import { UpdateAction } from "../redux/selectedDateRange";
import Tooltip from "../../tooltip";
import * as moment from "moment";
import I18n from "i18n";
import DateFormatter from "../../../libraries/dateFormatter";
import { DashboardStore } from "../redux/dashboards";
import { filterDashboards } from "./Dashboard";

interface Props {
  update(payload: number): UpdateAction;
  selectedDateRange: number;
  dashboards: DashboardStore;
}

export interface DateRange {
  days: number;
  startTS: number;
  endTS: number;
}

function DateRange({ selectedDateRange, update, dashboards }: Props) {
  return <div className={`dashboard-datepicker float-right ${dashboards.value.length === 0 ? "dateRange-calender__disabled" : ""}`}>
      <div className="dropdown">
        <a className="dropdown__toggle" data-toggle="dropdown">
          <i className="icon-triangle-down float-right" />
          <i className="fa fa-calendar dateRange-calender" aria-hidden="true" />
          {I18n.t(dateRanges[selectedDateRange][dateRangeIdx.dateRangeName])}
        </a>
        <ul className="dropdown__menu">
          {dateRanges
            .map((item, idx) => [item, idx] as [SelectedDateRange, number])
            .map(([item, idx]) => (
              <li
                key={item[1]}
                className="dropdown__item"
                onClick={update.bind(null, idx)}>
                <a
                  className={`dropdown__link ${
                    selectedDateRange === idx
                      ? "dropdown__link--disabled"
                      : ""
                  }`}>
                  {I18n.t(item[dateRangeIdx.dateRangeName])}
                </a>
              </li>
            ))}
        </ul>
        <span className="float-right">
        <Tooltip positionMy="top center" positionAt="bottom center" tooltipHeadline={displayDates(dateRanges, selectedDateRange)} tooltipBody={I18n.t("webapp.new_dashboard.date_range.tooltip")} />
        </span>
      </div>
    </div>;
}

function displayDates(
  dateRanges: SelectedDateRange[],
  selectedDateRange: number
): string {
  const currentDate = moment();
  const dayDiff = dateRanges[selectedDateRange][0];
  let suffix = DateFormatter.formatDate(moment(dateRanges[selectedDateRange][3]*1000));
  let prefix = "";
  if (dayDiff !== 0) {
    prefix += `${DateFormatter.formatDate(
      currentDate.subtract(dateRanges[selectedDateRange][0] - 1, "d")
    )} - `;
  }
  return prefix + suffix;
}

export default connect(
  (store: Store) => ({
    dashboards: filterDashboards(store),
    selectedDateRange: store.selectedDateRange
  }),
  { update }
)(DateRange);
