import ClipTopicSelectionView from 'views/reporting/wizard/clipTopicSelectionView'
import TopicSelectionView from 'views/reporting/wizard/topicSelectionView'
import TimespanSelectionView from 'views/reporting/wizard/timespanSelectionView'
import EditTitleSummaryView from 'views/reporting/wizard/editTitleSummaryView'
import FilterSortSettingsView from 'views/reporting/wizard/filterSortSettingsView'
import ContentSettingsView from 'views/reporting/wizard/contentSettingsView'
import ExcelContentSettingsView from 'views/reporting/wizard/excelContentSettingsView'
import NewsletterRecipientSelectionView from 'views/reporting/wizard/newsletterRecipientSelectionView'
import StepLayout from 'views/reporting/wizard/stepLayout'
import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/reporting/wizard.html'
import _ from 'underscore'
import 'highcharts_legacy'
import I18n from 'i18n'

var View = Marionette.Layout.extend({
  template: template,
  className: "wizard-form-container",
  regions: {
    clipTopicSelection: '#clipTopicSelection',
    topicSelection: '#topicSelection',
    timespanSelection: '#timespanSelection',
    editTitleSummary: '#editTitleSummary',
    filterSortSettings: '#filterSortSettings',
    newsletterRecipientSelection: '#newsletterRecipientSelection',
    contentSettings: '#contentSettings',
    excelContentSettings: '#excelContentSettings'
  },
  triggers: {
    'click .nextStep': 'clicked:nextStep',
    'click .wizard-save': 'clicked:save'
  },
  mapStepToView: function (step) {
    switch (step.identifier) {
    case 'clipTopicSelection':
      return ClipTopicSelectionView;
    case 'topicSelection':
      return TopicSelectionView;
    case 'timespanSelection':
      return TimespanSelectionView;
    case 'editTitleSummary':
      return EditTitleSummaryView;
    case 'filterSortSettings':
      return FilterSortSettingsView;
    case 'contentSettings':
      return ContentSettingsView;
    case 'excelContentSettings':
      return ExcelContentSettingsView;
    case 'newsletterRecipientSelection':
      return NewsletterRecipientSelectionView;
    }
  },
  initialize: function () {
    var wizardView = this;

    _.bindAll(this, 'updateButtonVisibility');
    _.bindAll(this, 'approximateClipCountLoading');
    _.bindAll(this, 'updateApproximateClipCount');
    this.on('clicked:nextStep', () => {
      this.options.wizard.makeStep();
    });
    this.on('clicked:save', () => {
      this.$('.wizard-save').attr('disabled', 'disabled');
      this.$('.wizard-save').val(I18n.t('webapp.reporting.wizard.interact.please_wait'));
      this.options.wizard.save();
    });

    this.options.wizard.on('saveError', () => {
      wizardView.$('.wizard-save').removeAttr('disabled');
      wizardView.$('.wizard-save').val(I18n.t('webapp.reporting.wizard.interact.save'));
    });

    this.options.wizard.on('change:currentStepComplete', this.updateButtonVisibility);
    this.options.wizard.on('change:complete', this.updateButtonVisibility);
    this.options.wizard.on('change:currentStep', this.updateButtonVisibility);
    this.options.wizard.on('change:approximateClipCount', this.updateApproximateClipCount);
    this.options.wizard.on('change:currentStep', this.updateApproximateClipCount);

  },
  createStepViews: function () {
    // automagically create the views for the steps
    _.each(this.options.wizard.stepModels, (step) => {
      if (this.mapStepToView(step)) {
        var stepLayout = new StepLayout();
        var V = this.mapStepToView(step);
        var view = new V({model: step});
        this[step.identifier].show(view);
        stepLayout.step.show(view);
        if (step.identifier === 'newsletterRecipientSelection') {
          view.showSpacer();
        }
      }
    }, this);
    this.updateButtonVisibility();
  },
  updateButtonVisibility: function () {
    this.updateInteractionElementVisibility();
    this.updateNextButtonState();
    this.updateSaveButtonState();
  },
  updateInteractionElementVisibility: function () {
    this.$('.interact').hide();
    this.$('.wizard-step:visible .interact:last').show();
  },
  updateNextButtonState: function () {
    var currentStepComplete = this.options.wizard.get('currentStepComplete');
    var lastStep = this.options.wizard.get('lastStep');

    if (currentStepComplete) {
      this.$('.nextStep').removeAttr('disabled');
    } else {
      this.$('.nextStep').attr('disabled', 'disabled');
    }
    if (lastStep) {
      this.$('.nextStep').hide();
    } else {
      this.$('.nextStep').show();
    }
  },
  updateSaveButtonState: function () {
    if (this.options.wizard.get('lastStep')) {
      this.$('.wizard-save').show();
      if (this.options.wizard.get('complete')) {
        this.$('.wizard-save').removeAttr('disabled');
      } else {
        this.$('.wizard-save').attr('disabled', 'disabled');
      }
    } else {
      this.$('.wizard-save').hide();
    }
  },
  approximateClipCountLoading: function () {
    this.$('.interact .approximateClipCount').html('');
    this.$('.interact .clipCountLoading').html(I18n.t('webapp.reporting.wizard.interact.loading_articles'));
  },
  updateApproximateClipCount: function () {
    var clipCount = this.options.wizard.get('approximateClipCount');
    if (_.isUndefined(clipCount)) {
      this.approximateClipCountLoading();
    } else {
      var clipCountFormat = '' + clipCount;
      if (clipCount > 1000) {
        var cc = ('' + clipCount).split('');
        clipCount = parseInt(cc[0] + cc[1] + new Array(cc.length - 1).join('0'), 10);
        var formatedClipCount = this._formatNumber(clipCount);
        clipCountFormat = 'ca. ' + formatedClipCount;
      }
      var message = I18n.t(
        'webapp.reporting.wizard.interact.clip_count',
        {count: clipCount, formattedCount: clipCountFormat}
      );
      var wizard = this.options.wizard;
      if (wizard.get('lastStep') && wizard.isOverClipLimit()) {
        var limit = this.options.wizard.clipLimit();
        message += I18n.t('webapp.reporting.wizard.interact.clip_limit_reached', {limit: this._formatNumber(limit)});
      }
      this.$('.interact .approximateClipCount').html(message);
      this.$('.interact .clipCountLoading').html('');
    }
  },
  _formatNumber: function (number) {
    return I18n.toNumber(number, { precision: 0 });
  }
});

export default View;

