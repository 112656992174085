import * as React from "react"
import I18n from "i18n"
import * as _ from "underscore"
import ReaderConfiguration from 'models/newsroom/configuration'
import ReaderCategory from 'models/newsroom/category'
import Sidebar from 'components/sidebar/sidebar'
import SidebarActions from 'components/sidebar/sidebarActions'
import SidebarNavItemTitle from 'components/sidebar/sidebarNavItemTitle'
import SidebarSubtreeItem from 'components/sidebar/sidebarSubtreeItem'
import SidebarSubtree from 'components/sidebar/sidebarSubtree'
import session from 'models/sessionInstance';
import users from 'collections/usersInstance';
import SidebarActionsMenuItem from 'components/sidebar/sidebarActionsMenuItem'

export interface NewsroomSidebarProps {
  useNewReaderManagement: boolean
  allowedToAddReaderConfig: boolean
  readerConfigurations: ReaderConfiguration[]
  onReaderLinkClick: (config: ReaderConfiguration) => void
  onReaderEditLinkClick: (config: ReaderConfiguration) => void
  onAddReaderClick: (templateName: string) => void
  onSummaryClick: (config: ReaderConfiguration) => void
  onCategoryClick: (category: ReaderCategory) => void
  contractVersion: number
  isAdmin: boolean
}

interface State {
  lockHover?: ReaderConfiguration
  selection?: ReaderCategory | ReaderConfiguration
  isSummaryActive: boolean
  isReaderEditActive: boolean
}

export class NewsroomSidebar
extends React.Component<NewsroomSidebarProps, State> {
  constructor(props: NewsroomSidebarProps) {
    super(props)
    this.state = {
      lockHover: undefined,
      selection: this.selectedItemOnLoad(),
      isSummaryActive: false,
      isReaderEditActive: false,
    }
  }

  selectedItemOnLoad() {
    if (this.props.readerConfigurations.length > 0) {
      const reader = this.props.readerConfigurations[0]
      if (reader.categories.length > 0) {
        return this.props.readerConfigurations[0].categories.first();
      } else {
        return reader;
      }
    }
  }

  render() {
    const readerConfigurations =
      this.configurations().map(
        (r: ReaderConfiguration) => this.configuration(r)
      )
    return (
      <Sidebar>
        {readerConfigurations}
        {this.actions()}
      </Sidebar>
    );
  }

  configurations() {
    if (session.hasFeature('use_new_reader_management')) {
      return _.filter(this.props.readerConfigurations, (config: ReaderConfiguration) => {
        const owner = config.get('creator_id') == users.currentUser.id
        return config.get('shared') || owner
      })
    } else {
      return this.props.readerConfigurations;
    }
  }

  actions() {
    if (this.props.allowedToAddReaderConfig) {
      if (this.readerTemplateNames().length > 1) {
        return (
          <SidebarActions
            label={I18n.t('webapp.reader_admin.sidebar.add')}
          >
            {this.readerTemplateButtons()}
          </SidebarActions>
        )
      } else {
        return (
          <SidebarActions
            label={I18n.t('webapp.reader_admin.sidebar.add')}
            onClick={() => this.props.onAddReaderClick('default')}
          />
        )
      }
    }
  }

  private readerTemplateButtons() {
    return this.readerTemplateNames().map(
      (templateName: string) => {
        return (
          <SidebarActionsMenuItem
            key={templateName}
            label={I18n.t('webapp.reader_admin.sidebar.templates.' + templateName)}
            onClick={() => this.props.onAddReaderClick(templateName)}
          />
        )
      }
    )
  }

  private readerTemplateNames() {
    return _.keys(session.get('reader_templates'))
  }

  configuration(config: ReaderConfiguration) {
    const title = config.get('title')
    const isOpen = 'is-open'
    const klass = 'nav-tree__item ' + isOpen
    const unsortedCats: any = config.categories
    const sortedCats = _.sortBy(unsortedCats.models, (cat: ReaderCategory) => {
      return cat.get('position')
    })
    const categories = sortedCats.map(
      (category: ReaderCategory) => this.category(category)
    )
    const key = 'reader-sidebar-config-' + config.get('id')

    const summaryActive =
      this.isSummaryActive(config) ? "is-active" : ""
    const linkToReader = session.hasFeature('curation_module_links_to_reader')
    const summaryKlass = "nav-subtree__link " + summaryActive

    return (
      <li className={klass} key={key}>
        <SidebarNavItemTitle
         isActive={this.isConfigurationActive(config)}
         onClick={() => {if (linkToReader) {this.props.onReaderLinkClick(config)}}}
         clickable={linkToReader}
         onMouseOver={() => this.onMouseOverReader(config)}
         onMouseOut={() => this.onMouseOutsideReader(config)}
        >
            <span className="nav-tree__text"><span data-title>{ title }</span>{linkToReader ? <i className="mlx icon-eye"></i> : ""}</span>
            {this.readerIcon(config)}
        </SidebarNavItemTitle>
        <SidebarSubtree>
          <SidebarSubtreeItem
            className={summaryKlass}
            onClick={() => {
              this.props.onSummaryClick(config)
              this.setState({ selection: config, isSummaryActive: true });
            }}
          >
            <span className="mlm nav-subtree__text">{I18n.t('webapp.reader_admin.sidebar.summary')}</span>
          </SidebarSubtreeItem>
        </SidebarSubtree>
        <SidebarSubtree>
          {categories}
        </SidebarSubtree>
      </li>
    )
  }

  readerIcon(config: ReaderConfiguration) {
    if (this.props.useNewReaderManagement && !config.get('shared')) {
      return this.lockIcon(config)
    } else {
      return this.gearIcon(config)
    }
  }

  lockIcon(config: ReaderConfiguration) {
    const configId = config.get('id')
    if (this.state.lockHover &&
        this.state.lockHover.get('id') == configId) {
      return this.gearIcon(config)
    } else {
      return (
        <span className="nav-subtree__toggle"><i className="fa fa-lock"></i></span>
      )
    }
  }

  onMouseOverReader(config: ReaderConfiguration) {
    this.setState({lockHover: config})
  }

  onMouseOutsideReader(config: ReaderConfiguration) {
    this.setState({lockHover: undefined})
  }


  gearIcon(config: ReaderConfiguration) {
    const isOwner = config.get('creator_id') == users.currentUser.id
    if (!this.props.isAdmin && !isOwner) {
      return (<span></span>)
    } else {
      return (
        <span className="nav-subtree__toggle">
          <i
            className="nav-subtree__toggle-icon"
            data-edit
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              this.onReaderEditLinkClick(e, config);
              this.setState({
                isReaderEditActive: true,
                isSummaryActive: false,
                selection: config,
              });
            }}></i>
        </span>
      );
    }
  }

  onReaderEditLinkClick(e: React.MouseEvent<HTMLElement>, config: ReaderConfiguration) {
    this.props.onReaderEditLinkClick(config)
    e.stopPropagation()
  }

  isCategoryActive(category: ReaderCategory) {
    return !this.state.isSummaryActive &&
      this.state.selection &&
      this.state.selection instanceof ReaderCategory &&
      this.state.selection.get('id') == category.get('id')
  }

  isConfigurationActive(config: ReaderConfiguration) {
    return !this.state.isSummaryActive &&
      this.state.selection &&
      this.state.selection instanceof ReaderConfiguration &&
      this.state.selection.get('id') == config.get('id')
  }

  isSummaryActive(config: ReaderConfiguration) {
    return this.state.isSummaryActive &&
      this.state.selection &&
      this.state.selection instanceof ReaderConfiguration &&
      this.state.selection.get('id') == config.get('id')
  }

  category(category: ReaderCategory) {
    const name = category.get('name')
    const key='reader-sidebar-category-' + category.get('id')
    const klass = "nav-subtree__link"

    return (
      <SidebarSubtreeItem
        className={klass}
        key={key}
        onClick={() => {
          this.props.onCategoryClick(category)
          this.setState({selection: category, isSummaryActive: false})
        }}
        isActive={this.isCategoryActive(category)}
      >
        <span className="mlm nav-subtree__text">{ name }</span>
      </SidebarSubtreeItem>
    )
  }
}

export default NewsroomSidebar
