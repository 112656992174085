import AgentResultTagController from "controllers/agents/agentResultTagController";

interface ITag {
  id: string;
  text: string;
}

class ClipCreationTagController extends AgentResultTagController {
    private createTagging(tag: ITag) {
        (this as any).trigger("add:tag", tag);
    }

    private deleteTagging(tag: ITag) {
        (this as any).trigger("remove:tag", tag);
    }

    private viewInputClasses(): Array<string> { return []; }
}

export default ClipCreationTagController;
