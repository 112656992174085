/* eslint no-invalid-this: "off", no-undef: "off" */
import App from 'app'
import vent from 'libraries/vent'
import Deferred from 'libraries/deferred'
import History from 'libraries/history'
import Logger from 'libraries/logger'
import Analytics from 'libraries/analytics'
import session from 'models/sessionInstance'
import sessionPromise from 'models/sessionPromise'
import Marionette from 'backbone.marionette'
import _ from 'underscore'
import $ from 'jquery'

// A module that handles the main navigation in the application.
//
// A module can register itself by executing the
// 'navigation:register' command on vent.commands. The module and
// the navigation identifier (name of the module as used in
// session etc.) are expected as parameters, a third options
// parameter is optional. For all other commands, only the
// navigation identifier is expected.
//
// When the options object contains a “toolbar” key, its value
// will be treated as a view object to be shown as a toolbar when
// the module is activated.
//
// Execute the 'navigation:loading' and 'navigation:loaded'
// commands to update the (visual) state of the navigation entry.
// This visual behavior only applies to the legacy navigation view.
//
// Execute the 'navigation:deregister' command to deregister from
// navigation.
//
// The commands 'navigation:show:<navigation element>' and
// 'navigation:hide:<navigation element>' are executed when a
// module should show/hide itself.
App.module("Navigation", {
  startWithParent: true,
  define: function () {

    var commands = {
      "navigation:register": function (module, navigationName, options) {
        App.Navigation.register(module, navigationName, options);
      },
      "navigation:deregister": function (navigationName) {
        App.Navigation.deregister(navigationName);
      },
      "navigation:loading": function (navigationName) {
        App.Navigation.loading(navigationName);
      },
      "navigation:loaded": function (navigationName) {
        App.Navigation.loaded(navigationName);
      },
      "navigation:activate": function (navigationName) {
        App.Navigation.activate(navigationName);
      },
      "navigation:deactivateAll": function () {
        App.Navigation.deactivateAll();
      }
    };

    App.Navigation.addInitializer(function () {
      _.each(commands, function (handler, command) {
        vent.commands.setHandler(command, handler);
      });

      this.historyReady = History.waitForMe();
      sessionPromise.then(() => {
        this.createRouter();
        this.historyReady.ready();
      })
    });

    App.Navigation.addFinalizer(function () {
      _.each(commands, function (handler, command) {
        vent.commands.removeHandler(command);
      });
      App.Navigation.stopListening();
    });

    function navigationElement(name) {
      return $('#container-navigation *[rel="' + name + '"]');
    }

    App.Navigation.modules = {};

    App.Navigation.register = function (module, navigationName, options) {
      Logger.debug('Navigation: registering module ' + navigationName);
      options = _.extend({}, options);
      App.Navigation.modules[navigationName] = {
        module: module,
        options: options,
        deferred: Deferred.build()
      };

      App.Navigation.addModuleToolbar(options);
      vent.trigger('navigation:registered', navigationName);
    };

    App.Navigation.deregister = function (navigationName) {
      Logger.debug('Navigation: deregistering module ' + navigationName);
      App.Navigation.unload(navigationName);
      App.Navigation.modules[navigationName] = undefined;
      vent.trigger('navigation:deregistered', navigationName);
    };

    App.Navigation.loading = function (navigationName) {
      Logger.debug('Navigation: module ' + navigationName + ' is being loaded');
      navigationElement(navigationName).addClass('loading');
      vent.trigger('navigation:loading', navigationName);
    };
    App.Navigation.loaded = function (navigationName) {
      Logger.debug('Navigation: module ' + navigationName + ' is loaded');
      App.Navigation.modules[navigationName].deferred.resolve();
      navigationElement(navigationName).removeClass('loading');
      navigationElement(navigationName).addClass('loaded');
      vent.trigger('navigation:loaded', navigationName);
    };
    App.Navigation.unload = function (navigationName) {
      Logger.debug('Navigation: module ' + navigationName + ' is unloaded');
      App.Navigation.deactivate(navigationName);
      navigationElement(navigationName).removeClass('loaded');
    };

    App.Navigation.activate = function (navigationName) {
      Logger.debug('Navigation: activating module ' + navigationName);
      App.Navigation.modules[navigationName].deferred.promise().done(function () {
        var module = App.Navigation.modules[navigationName].module;
        var options = App.Navigation.modules[navigationName].options;
        if (!module.active) {
          module.active = true;

          App.Navigation.deactivateAll();

          App.Navigation.handleDarkOption(options, 'activate');
          App.Navigation.handleToolbarOption(options, 'activate');

          var title = __APPTITLE__ + ' ' + module.name;
          document.title = title;

          navigationElement(navigationName).addClass('active');
          vent.commands.execute('navigation:show:' + navigationName);
          vent.commands.execute('showLegacyContent');
          App.Navigation.navigationRouter.navigate(navigationName);
          App.Navigation.activeModuleName = navigationName;
          vent.trigger('navigation:activated', navigationName);
          Analytics.sendEvent('br_page_view', {page: '/' + navigationName});
        } else {
          Logger.debug('Navigation: module ' + navigationName + ' already active');
        }
      });
    };

    App.Navigation.deactivateAll = function () {
      if (!_.isUndefined(this.activeModuleName)){
        App.Navigation.deactivate(this.activeModuleName);
      }
    };

    App.Navigation.deactivate = function (navigationName) {
      Logger.debug('Navigation: deactivating module ' + navigationName);
      var module = App.Navigation.modules[navigationName].module;
      var options = App.Navigation.modules[navigationName].options;
      if (module.active) {
        module.active = false;
        delete this.activeModuleName;
        App.Navigation.handleDarkOption(options, 'deactivate');
        App.Navigation.handleToolbarOption(options, 'deactivate');
        navigationElement(navigationName).removeClass('active');
        vent.commands.execute('navigation:hide:' + navigationName);
        vent.trigger('navigation:deactivated', navigationName);
      } else {
        Logger.debug('Navigation: module ' + navigationName + ' already deactivated');
      }
    };

    App.Navigation.handleDarkOption = function (options, action) {
      if (action === 'activate') {
        if (options.dark) {
          App.Navigation.makeDark();
        } else {
          App.Navigation.makeLight();
        }
      }
    };

    App.Navigation.handleToolbarOption = function (options, action) {
      if (options.toolbar) {
        if (action === 'activate') {
          $(options.toolbar.el).show();
        } else {
          $(options.toolbar.el).hide();
        }
      }
    };

    App.Navigation.makeDark = function () {
      $('body').addClass('gradient-grey-dark');
      $('#container-navigation').addClass('shadow');
    };

    App.Navigation.makeLight = function () {
      $('body').removeClass('gradient-grey-dark');
      $('#container-navigation').removeClass('shadow');
    };

    App.Navigation.addModuleToolbar = function (options) {
      if (options.toolbar) {
        $(options.toolbar.el).hide();
        vent.commands.execute('toolbar:append', options.toolbar.el);
      }
    };

    App.Navigation.createRouter = function () {

      function activate(module) {
        vent.commands.execute("navigation:activate", module);
      }

      var modules = [
        'agents',
        'dashboard',
        'reports',
        'tags',
        'research',
        'settings',
        'newsroom',
        'sandbox'
      ]

      var controller = _.object(_.map(modules, function (module) {
        let action = _.partial(activate, module)
        if (!session.hasModule(module)) {
          action = () => App.Navigation.redirectToDefault()
        }

        return [module, action];
      }));

      var appRoutes = _.object(_.map(modules, function (module) {
        return [module, module];
      }));
      appRoutes[""] = App.Navigation.defaultModule(modules);

      var NavigationRouter = Marionette.AppRouter.extend({
        controller: controller,
        appRoutes: appRoutes
      });

      this.navigationRouter = new NavigationRouter();
    };

    App.Navigation.defaultModule = function (moduleOrder) {
      var firstAvailableModule = _.find(moduleOrder, function (module) {
        return session.hasModule(module);
      });

      return firstAvailableModule;
    };

    App.Navigation.redirectToDefault = function() {
      this.navigationRouter.navigate('', { trigger: true });
    }
  }
});
