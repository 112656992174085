import Marionette from 'backbone.marionette'
import tagListItemTemplate from 'text-loader!templates/settings/tagListItem.html'

var TagListItemView = Marionette.ItemView.extend({
  template: tagListItemTemplate,
  tagName: "a",
  className: "button-group__item button--pointing",
  triggers: {
    'click': 'tag:clicked'
  },
  initialize() {
    this.listenTo(this.model, 'change:name', this.render);
  },
  serializeData() {
    return this.model.toJSON();
  }
});

export default TagListItemView;

