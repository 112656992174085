// Widget base class
//
// To add a new subclass, define it (see agentChartWidget.js) and then
// load it in the widgets collection (widgets.js).

import Backbone from 'backbone'
import _ from 'underscore'
import $ from 'jquery'
import Config from 'libraries/configuration'

var Widget = Backbone.Model.extend({
  urlRoot: function () { return Config.apiUrl + "dashboard_widgets"; },
  parse: function (response) {
    // handle responses nested in an 'object' key
    if (response.object) {
      return response.object;
    } else {
      return response;
    }
  },
  toJSON: function () {
    var fullJSON = Backbone.Model.prototype.toJSON.call(this);
    return {
      'dashboard_widget': fullJSON
    };
  },
  isAllDataReady: false,
  destroy: function (options) {
    this.trigger('loading');
    Backbone.Model.prototype.destroy.call(this, options);
  },
  decreaseSize: function () {
    if (this.sizeDecreasable()) {
      this._makeResizeRequest('decrease_size');
    }
  },
  increaseSize: function () {
    if (this.sizeIncreasable()) {
      this._makeResizeRequest('increase_size');
    }
  },
  _makeResizeRequest: function (action) {
    var url = this.url() + '/' + action;
    var self = this;
    this.trigger('loading');
    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      success: function (data) {
        if (data.object) {
          self.set(data.object);
        }
      },
      complete: function () {
        self.trigger('loading-finished');
      }
    });
  },
  sizeDecreasable: function () {
    return this.get('size_decreasable');
  },
  sizeIncreasable: function () {
    return this.get('size_increasable');
  },
  _countClipsInChartData: function (chartData) {
    var sum = _.reduce(chartData, function (memo, el) {
      return memo + el[1];
    }, 0);
    return sum;
  },
  truncateSourceTitles: function () {
    _.each(this.get('sections'), function (section) {
      _.each(section.sources, function (source) {
        if (source.name.length > 30) {
          source.name = source.name.substring(0, 30) + " …";
        }
      });
    });
  },
  showAgentTag: function (additionalFilters) {
    if (typeof(this.agentIds) !== 'undefined') {
      let filters = this.agentIds().map((id) => {
        return { id, type: 'agent' }
      })
      this.trigger('showFilteredTimeline', filters.concat(additionalFilters))
    } else if (typeof(this.tagIds) !== 'undefined') {
      let filters = this.tagIds().map((id) => {
        return { id, type: 'tag' }
      })
      this.trigger('showFilteredTimeline', filters.concat(additionalFilters))
    }
  }
});

export default Widget;

