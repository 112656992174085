import Widget from 'models/dashboard/widget'

Widget.AgentChartWidget = Widget.extend({
        clipCount: function () {
            return this._countClipsInChartData(this.get('chart_data'));
        },
        showAgent: function () {
          let agentId = this.get('agent_id')
          this.trigger('showFilteredTimeline', [{type: 'agent', id: agentId}])
        }
    });

export default  Widget.AgentChartWidget;
