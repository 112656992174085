import Marionette from 'backbone.marionette'
import $ from 'jquery'
import researchResultListItemTemplate from 'text-loader!templates/researchResultListItem.html'
import moment from 'moment'

var ResearchResultListItemView = Marionette.ItemView.extend({
  template: researchResultListItemTemplate,
  tagName: "li",
  className: "clips-list__item",
  events: {
    'click': 'generalClickHandler'
  },
  initialize: function () {
    this.listenTo(this.model, 'change', this.render);
  },
  registerClickHandler: function (handler) {
    this.clickHandler = handler;
  },
  registerCtrlClickHandler: function (handler) {
    this.ctrlClickHandler = handler;
  },
  registerShiftClickHandler: function (handler) {
    this.shiftClickHandler = handler;
  },
  generalClickHandler: function (e) {
    if (!e.ctrlKey && !e.shiftKey && !e.metaKey) {
      this.clickHandler();
    } else if (e.ctrlKey || e.metaKey) {
      this.ctrlClickHandler();
    } else if (e.shiftKey) {
      this.shiftClickHandler();
    }
  },
  onRender: function () {
    var self = this;
    this.registerClickHandler(function () {
      self.trigger('result:clicked', self.model);
    });
    this.registerCtrlClickHandler(function () {
      self.trigger('result:ctrlClicked', self.model);
    });
    this.registerShiftClickHandler(function () {
      self.trigger('result:shiftClicked', self.model);
    });
  },
  attributes: function () {
    var clipId = this.model.get('id');
    return {
      'data-clip-id': clipId
    };
  },
  serializeData: function () {
    var clip = this.model;
    var sourceCount = clip.get('sources').length;
    var additionalSourceCount;

    if (sourceCount > 1) {
      additionalSourceCount = sourceCount - 1;
    }

    return {
      primary_source_name: clip.get('primary_source').name,
      additional_source_count: additionalSourceCount,
      publish_time: moment.unix(clip.get('publish_time')),
      title: clip.get('title'),
      mentions: (clip.get('mentions') || []).slice(0, 5),
      primarySourceFavicon: clip.get('primary_source').favicon_url
    };
  },
  markAsSelected: function () {
    this.$el.addClass('is-active');
  },
  markAsDeselected: function () {
    this.$el.removeClass('is-active');
  },
  getDistanceFromTopAndBottom: function () {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elementTop = this.$el.offset().top;
    var elementBottom = elementTop + this.$el.height();
    return {
      top: elementTop - docViewTop,
      bottom: docViewBottom - elementBottom
    };
  }
});

export default ResearchResultListItemView;

