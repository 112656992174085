import * as React from "react";
import * as _ from "underscore";
import I18n from "i18n";
import AdditionalSourceListItemView from
  "components/resultDetailView/additionalSourceListItemView";
import { IEsApiSource } from "models/esResult";
import * as moment from "moment";

export interface AdditionalSourcesProps {
  additionalSources: Array<IEsApiSource>;
  articlePublishTime: number;
  radioTv: boolean;
}

export default class DetailAdditionalSourcesView extends
  React.Component<AdditionalSourcesProps, {}> {
  render() {
    const additionalSources = this.props.additionalSources;
    const additionalSourcesLabel = I18n.t(
      "webapp.agents.results.result_details.sources",
      { count: additionalSources.length }
    );

    return (
      <div className="flaired-container bottom-rounded-container dropped-container">
        <ul className="clip-additional-sources">
          <li className="clip-additional-sources__item">
            <div className="clip-additional-sources__item-content ">
              <div className="clip-additional-sources__item-head">
                <h3 className="heading-large">
                  + {additionalSourcesLabel}
                </h3>
              </div>
            </div>
          </li>
          { this.renderAdditionalSources() }
        </ul>
      </div>
    );
  }

  private renderAdditionalSources() {
    return _.map(this.props.additionalSources, (additionalSource, idx) => {
      let sourceMeta = additionalSource.meta;
      return <AdditionalSourceListItemView {
        ...{
          sourceName: sourceMeta.name,
          sourceFavicon: sourceMeta.favicon_url,
          sourceArticleLink: sourceMeta.article_url,
          sourceReach: sourceMeta.reach,
          articlePublishTime: moment(this.props.articlePublishTime),
          radioTv: this.props.radioTv
        }
      } key={idx} />;
    });
  }
}
