import Marionette from 'backbone.marionette';
import { PersonDetailView, Props } from 'components/personDetailView'
import { IEsApiEntity as Entity } from 'models/esResult'
import * as _ from 'underscore'
import * as React from "react";
import ReactWrapper from 'views/marionetteReactWrapper';
import LayoutView from 'views/layoutView'
import { IKbApiEntity } from 'models/kbApiEntity'
import ReactController from 'controllers/reactController'

class PersonsDetailController extends ReactController<Props> {
  initialize(opts: { person?: IKbApiEntity }) {
    super.initialize(opts)
    if (opts.person) {
      this.person = opts.person
    }
  }

  defaultProps(): Props {
    let props: Props = {}
    if (this.person && this.person.data) {
      props['person'] = this.person
    }
    return props
  }

  getComponent(props: Props) {
    return (<PersonDetailView {...props} />)
  }
}

export default PersonsDetailController;