import Config from "libraries/configuration";
import * as Backbone from "backbone";
import Source from "models/source";
import * as _ from "underscore";

interface IResponse {
  collection: any;
}

interface IGlobalSearchSelect2 {
  id: number;
  type: string;
  text: string;
}

class Sources extends Backbone.Collection<Source> {
    parse(response: IResponse): any {
        return response.collection;
    }

    toSelect2(): Array<string> {
        return _.map(this.models, function (source: Source) {
            return source.get("name");
        });
    }

    toGlobalSearchSelect2(sources: Array<Source>): Array<IGlobalSearchSelect2> {
        let collection = sources;
        if (_.isUndefined(sources)) {
            collection = this.models;
        }

        return _.map(collection, function (source) {
            return {id: source.get("id"), type: "source", text: source.get("name")};
        });
    }

}

Sources.prototype.url = Config.apiUrl + "sources";
Sources.prototype.comparator = (source: Source): string => {
  if (!_.isUndefined(source.get("name"))) {
      return source.get("name").toLowerCase();
  } else {
      return "";
  }
};
Sources.prototype.model = Source;

export default Sources;
