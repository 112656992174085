import _ from 'underscore'
import $ from 'jquery'
import I18n from 'i18n'
import 'jquery.pnotify'

var defaults = {
  pnotify_history: false,
  pnotify_opacity: 0.9
};

var permanentNotice = {};

function notify(options) {
  return $.pnotify(_.extend({}, defaults, options));
}

var PNotify = {
  show: function (message) {
    notify({
      pnotify_text: message
    });
  },
  slow: function (message) {
    notify({
      pnotify_text: message,
      pnotify_delay: 11000,
      pnotify_closer: false
    });
  },
  permanent: function (message, identifier) {
    if (permanentNotice[identifier] !== undefined) { return; }
    permanentNotice[identifier] = notify({
      pnotify_text: message,
      pnotify_hide: false,
      pnotify_closer: false
    });
  },
  permanentClear: function (identifier) {
    if (permanentNotice[identifier] !== undefined) {
      permanentNotice[identifier].pnotify_remove();
      delete permanentNotice[identifier];
    }
  },
  error: function (message) {
    notify({
      pnotify_title: I18n.t('webapp.notifications.error.error_notification_title'),
      pnotify_text: message,
      pnotify_type: 'error'
    });
  }
};

export default PNotify;
