import template from 'text-loader!templates/reporting/wizard/contentSettings.html'
import StepView from 'views/reporting/wizard/stepView'
import 'backbone.marionette'

var View = StepView.extend({
  template: template,
  className: "wizard-step rounded clearfix",
  triggers: {
    'click #has_chart': 'click:has_chart',
    'click #include_clips_from_several_sources': 'click:include_clips_from_several_sources',
    'click #has_clip_snippets': 'click:has_clip_snippets',
    'click #has_print_clips_in_full_length': 'click:has_print_clips_in_full_length'
  },
  initialize: function () {
    this.on('click:has_chart', () => {
      this.$('#has_chart').toggleClass("checked");
      var value = this.$('#has_chart').hasClass("checked");
      this.model.set({has_chart: value});
    });
    this.on('click:include_clips_from_several_sources', () => {
      this.$('#include_clips_from_several_sources').toggleClass("checked");
      var value = this.$('#include_clips_from_several_sources').hasClass("checked");
      this.model.set({include_clips_from_several_sources: value});
    });
    this.on('click:has_clip_snippets', () => {
      this.$('#has_clip_snippets').toggleClass("checked");
      var value = this.$('#has_clip_snippets').hasClass("checked");
      this.model.set({has_clip_snippets: value});
    });
    this.on('click:has_print_clips_in_full_length', () => {
      this.$('#has_print_clips_in_full_length').toggleClass("checked");
      var value = this.$('#has_print_clips_in_full_length').hasClass("checked");
      this.model.set({has_print_clips_in_full_length: value});
    });

    StepView.prototype.initialize.call(this);
  },
  updateCheckboxes: function () {
    this.$('#has_chart').removeClass("checked");
    this.$('#has_clip_snippets').removeClass("checked");
    this.$('#has_print_clips_in_full_length').removeClass("checked");
    this.$('#include_clips_from_several_sources').removeClass("checked");


    if (this.model.get("has_chart") === true) {
      this.$('#has_chart').addClass("checked");
    }

    if (this.model.get("has_clip_snippets") === true) {
      this.$('#has_clip_snippets').addClass("checked");
    }

    if (this.model.get("has_print_clips_in_full_length") === true) {
      this.$('#has_print_clips_in_full_length').addClass("checked");
    }

    if (this.model.get("include_clips_from_several_sources") === true) {
      this.$('#include_clips_from_several_sources').addClass("checked");
    }

  },
  serializeData: function () {
    return {
      hasPrintClipsInFullLength: this.model.hasPrintClipsInFullLength(),
      hasGraph: this.model.hasGraph(),
      hasSnippets: this.model.hasSnippets()
    };
  },
  onRender: function () {
    this.updateCheckboxes();
  }
});

export default View;

