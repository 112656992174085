import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/newsroomSummaryTimeline.html';
import NewsroomSummaryTimelineListView
    from 'views/newsroom/newsroomSummaryTimelineListView';

class NewsroomSummaryTimelineView extends Marionette.Layout {
    initialize() {
        this.newsroomSummaryTimelineListView =
            new NewsroomSummaryTimelineListView({
                selectedReaderConfigurationId: this.readerConfigurationId
            });
    }

    showList(view) {
        this.listContainer.show(view);
    }

    showModal(view) {
        this.modalContainer.show(view);
    }
}

NewsroomSummaryTimelineView.prototype.regions = {
    listContainer: '[data-list-container]',
    modalContainer: '[data-modal-container]',
};

NewsroomSummaryTimelineView.prototype.template = template;
export default NewsroomSummaryTimelineView;
