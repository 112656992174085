import Marionette from 'backbone.marionette'
import I18n from 'i18n'
import _ from 'underscore'
import template from 'text-loader!templates/agents/metadata/imported.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import localizedLanguages from 'libraries/localizedLanguages'
import agentResultReadingTime from 'libraries/agentResultReadingTime'

var DefaultView = Marionette.ItemView.extend({
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
    this.userCountry = options.userCountry.toLowerCase();
  },
  serializeData: function () {
    return this._metadata();
  },
  _metadata: function () {
    var primarySource   = this.agentResult.get('primary_source');
    var sourceMetadata  = primarySource.source_metadata;
    var languageCodes = this.agentResult.get('languages');

    var metadata = {};

    // reach

    var reach = {
      unique_clients_per_month: sourceMetadata.unique_clients,
      page_impressions_per_month: sourceMetadata.page_impressions,
    };

    if (!this.agentResult.isPrintSource()) {
      reach.visits_per_month = sourceMetadata.reach;
    } else {
      reach.reach_number = sourceMetadata.reach;
    }

    var usagePerCountry = this._getUsagePerCountry(sourceMetadata);
    if (usagePerCountry) {
      reach.label_usage_per_country = this._labelUsagePerCountry();
      reach.usage_per_country = usagePerCountry;
    }
    if (sourceMetadata.page_number) {
      reach.source_pages = sourceMetadata.page_number;
    }
    if (sourceMetadata.circulation_distributed) {
      reach.circulation_distributed = sourceMetadata.circulation_distributed;
    }
    conditionalSetToMetadata(metadata, 'reach', reach);

    var engagement = {
      comment_count: sourceMetadata.comment_count
    };
    conditionalSetToMetadata(metadata, 'engagement', engagement);

    var information = {
      alexa_city: sourceMetadata.alexa_city,
      alexa_country: sourceMetadata.alexa_country,
      reading_time: agentResultReadingTime(this.agentResult),
      author: this.agentResult.get('author'),
      print_edition: this.agentResult.get('print_edition'),
    };
    if (!_.isEmpty(languageCodes)) {
      information.languages = localizedLanguages(languageCodes);
    }
    conditionalSetToMetadata(metadata, 'information', information);

    return metadata;
  },
  _getUsagePerCountry: function (sourceMetadata) {
    if (!this._userCountryCanHaveMetadata) {
      return;
    }
    if (sourceMetadata && sourceMetadata.usage_per_country) {
      return sourceMetadata.usage_per_country[this.userCountry];
    }
  },
  _userCountryCanHaveMetadata: function () {
    return _.contains(["at", "de", "ch"], this.userCountry);
  },
  _labelUsagePerCountry: function () {
    return I18n.t(
      "webapp.agents.results.result_details.metadata.usage_from_"
        .concat(this.userCountry)
    );
  }
});

export default DefaultView;

