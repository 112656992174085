import _ from 'underscore';
import Config from 'libraries/configuration';
import Backbone from 'backbone';
import SourceCategory from 'models/sourceCategory';

class SourceCategories extends Backbone.Collection {
    ids() {
        window.categories = this;
        let categoryIds = this.pluck('id');
        let subCategories = _.flatten(this.map(function (sc) {
            return sc.get('sub_categories').models;
        }));
        let subCategoryIds = _.map(subCategories, function (sc) {
            return sc.get('id');
        });

        return categoryIds.concat(subCategoryIds);
    }
    url() {
        return Config.apiUrl + "sources/source_categories/";
    }
    parse(response) {
        return response.collection || response;
    }
}

SourceCategories.prototype.model = SourceCategory;

export default SourceCategories;
