import vent from 'libraries/vent'
/*
 * Integrates a module with the navigation system.
 *
 * Expects a module and its navigationName as parameters. The
 * navigationName will be used for identifying the module and in
 * routes.
 *
 * The following interface is expected on the module:
 *
 * - A show() method that shows the module content. Will be called when the
 *   module is activated.
 * - A hide() method that hides the module content. Will be called when
 *   another module is activated.
 * - The event 'loaded' is triggered on the module when it is ready to be
 *   shown (i.e. all dependencies are loaded). This will make the module
 *   clickable in the navigation.
 */
var configureNavigationModule = function (module, navigationName) {
  var registerInNavigation = function () {
    var showModule = function () {
      module.show();
    };
    var hideModule = function () {
      module.hide();
    };

    vent.commands.execute('navigation:register', module, navigationName);
    vent.commands.execute('navigation:loading', navigationName);
    vent.commands.setHandler('navigation:show:' + navigationName, showModule);
    vent.commands.setHandler('navigation:hide:' + navigationName, hideModule);
  };

  var deregisterInNavigation = function () {
    vent.commands.execute('navigation:deregister', navigationName);
    vent.commands.removeHandler('navigation:show:' + navigationName);
    vent.commands.removeHandler('navigation:hide:' + navigationName);
  };

  module.addInitializer(registerInNavigation);
  module.addFinalizer(deregisterInNavigation);
  module.on('loaded', function () {
    vent.commands.execute('navigation:loaded', navigationName);
  });
};

export default configureNavigationModule;

