import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/settings/notifications/realtimeNotification.html'

var RealtimeNotificationView = Marionette.Layout.extend({
  template: template,
  regions: {
    agentSelector: '[data-agent-selector]'
  }
});

export default RealtimeNotificationView;

