import Marionette from 'backbone.marionette';
import * as _ from 'underscore'
import * as React from "react"
import ReactWrapper from 'views/marionetteReactWrapper';
import {IButton, RadioButtonGroup} from "components/radioButtonGroup"
import I18n from "i18n";
import LayoutView from 'views/layoutView'
import ReactController from 'controllers/reactController'
import DetailViewContainer from 'components/detailViewContainer'
import Modal from "components/modal2";
import Notificator from 'libraries/notificator';
import RestorableModel from 'libraries/restorableModel';
import vent from 'libraries/vent'
import users from 'collections/usersInstance';
import { NewsroomPermissions } from "components/newsroom/permissions";

interface PrivacyViewProps {
  openModal?: boolean;
  closeModal?: boolean
  model: RestorableModel;
  shared: boolean
  onPublicClick: () => void
  onPrivateClick: () => void
  onPrivatizeConfirm: () => void
  onModalClose: () => void
}
class PrivacyView extends React.Component<PrivacyViewProps, {}> {
  render() {
    const publicLabel =
      I18n.t('webapp.reader_admin.edit.new_reader_audience.public_button')
    const privateLabel =
      I18n.t('webapp.reader_admin.edit.new_reader_audience.private_button')
    const privatizationNotPermitted =
      I18n.t("webapp.reader_admin.edit.new_reader_audience.cannot_change_privacy_setting");
    const buttons = [
      {
        label: publicLabel,
        value: 'public'
      },
      {
        label: privateLabel,
        value: 'private'
      }
    ]
    const onClick = (value: string) => {
      value == 'public' ?
      this.props.onPublicClick() :
      this.props.onPrivateClick()
    }
    const header = I18n.t('webapp.reader_admin.edit.new_reader_audience.detail_headline')

    if (NewsroomPermissions.canUpdateSharedValue(this.props.model)) {
      return (
        <DetailViewContainer header={header}>
          <RadioButtonGroup
            buttons={buttons}
            selectedOption={this.selectedOpt()}
            onRadioButtonClick={onClick}
            disabled={false}
          />
          { this.modal() }
        </DetailViewContainer>
      )
    } else {
      return (
        <DetailViewContainer header={header}>
          <p>{privatizationNotPermitted}</p>
          <RadioButtonGroup
            buttons={buttons}
            selectedOption={this.selectedOpt()}
            onRadioButtonClick={onClick}
            disabled={true}
          />
        </DetailViewContainer>
      )
    }
  }

  private selectedOpt() {
    return this.props.shared ? 'public' : 'private'
  }

  handleKeyDown(e: any) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.props.onPrivatizeConfirm.bind(this)();
    }
  }

  modal() {
    const prefix =
      'webapp.reader_admin.edit.new_reader_audience.modal.'
      const body = I18n.t(prefix + 'body')
    const props = {
      open: this.props.openModal,
      close: this.props.closeModal,
      title: I18n.t(prefix + 'title'),
      cancelLabel: I18n.t(prefix + 'cancel'),
      confirmationLabel: I18n.t(prefix + 'confirm'),
      onConfirm: this.props.onPrivatizeConfirm.bind(this),
      modalId: 'reader-privatization-modal-' + this.props.model.get("id"),
      onClose: this.props.onModalClose.bind(this),
      onKeyDown: (e: any) => this.handleKeyDown(e)
    };
    return (
      <Modal {...props}> {body} </Modal>
    );
  }

  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown.bind(this));
  } 
}

class ReaderAudiencePrivacyController extends ReactController<PrivacyViewProps> {
  private _reader?: RestorableModel

  initialize(opts: any) {
    this._reader = opts.configuration
    super.initialize(opts)
    this.registerListeners()
  }

  private registerListeners() {
    this.listenTo(this._reader, 'change:possible_values_for_shared_attribute', () => this.rerender())
    this.listenTo(this._reader, 'change:shared', () => this.rerender())

  }

  getComponent(props: PrivacyViewProps) {
    return (<PrivacyView {...props} />)
  }

  defaultProps(): PrivacyViewProps {
    const self = this
    return {
      openModal: undefined,
      closeModal: undefined,
      shared: this.reader().get("shared"),
      model: this.reader(),
      onPublicClick: this.onPublicClick.bind(this),
      onPrivateClick: this.onPrivateClick.bind(this),
      onPrivatizeConfirm: this.onPrivatizeConfirm.bind(this),
      onModalClose: this.onModalClose.bind(this)
    }
  }

  onModalClose() {
    this.rerender()
  }

  reader(): RestorableModel {
    if (this._reader != undefined) {
      return this._reader
    } else {
      throw "impossible"
    }
  }

  onPublicClick() {
    if (this.isPrivate()) {
      this.renderView({
        shared: true,
        openModal: undefined,
        closeModal: undefined
      })
      this.reader().store()
      this.reader()
          .save({ shared: true })
          .fail((res: any) => this.handleError(res))
      this.trigger('readerPrivacyRerender')
      vent.trigger('refreshReaderSidebar')
    }
  }

  handleError(res: any) {
    const message = I18n.t('webapp.reader_admin.edit.new_reader_audience.request_failed')
    Notificator.showNotification(message)
    if (res.status === 409) {      
      this.reader().fetch().done(() => {
        this.triggerReRenderer();
      })
    } else {
      this.reader().restore();
      this.triggerReRenderer();
    }
  }

  triggerReRenderer() {
    this.renderView(this.defaultProps())
    this.trigger('readerPrivacyRerender')
    vent.trigger('refreshReaderSidebar')
  }

  onPrivatizeConfirm() {
    this.renderView({ shared: false, openModal: false, closeModal: true })
    this.reader().store()
    this.reader()
        .save({ shared: false })
        .fail((res: any) => this.handleError(res))
        .done(() => {
            if (this.isPrivate() && !this.isOwner()) {
              this.trigger('readerPrivacyClose')
            }
          })
    this.trigger('readerPrivacyRerender')
    vent.trigger('refreshReaderSidebar')
  }

  isOwner() {
    return this.reader().get('creator_id') == users.currentUser.id;
  }

  onPrivateClick() {
    if (this.isPublic()) {
      this.renderView({ openModal: true, closeModal: false })
    }
  }

  isPublic() {
    return this.reader().get('shared');
  }

  isPrivate() {
    return !this.isPublic();
  }
}

export default ReaderAudiencePrivacyController;
