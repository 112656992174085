import Widget from 'models/dashboard/widget'

Widget.AgentRatingWidget = Widget.extend({
  agentIds: function () {
    return this.get('agent_ids');
  }
});

export default Widget.AgentRatingWidget;

