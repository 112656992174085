import Marionette from 'backbone.marionette'
import * as template from 'text-loader!templates/editAgentResultForm.html'
import ImageGalleryViewExtension from 'views/imageGalleryViewExtension'
import session from 'models/sessionInstance'
import EditableDetailTitleView from 'views/components/editableDetailTitleView'
import SourceSearch from "components/sourceSearch";
import SourceSearchPropFactory from "components/sourceSearchPropFactory";
import Sources from "collections/sources";
import Source from "models/source";
import browserCheck from 'libraries/browserCheck';
import * as _ from 'underscore'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

interface IEditableDetailTitleViewOptionalProps {
  title?: string
  displayError?: boolean
  onEdit?: (inputValue: string) => void
  onEnter?: () => void
}

interface IEditViewProps {
  model: any
}

declare global {
  interface Window {
    MSInputMethodContext: any
  }

  interface Document {
    documentMode: any
  }
}

class EditAgentResultView extends Marionette.Layout {
  initialize(options: IEditViewProps) {
    this.model = options.model;
    this.sources = new Sources();
    this.sources.add(this.model.get("primary_source"));
    this.searchPropFactory = new SourceSearchPropFactory(
      this.sources,
      (source) => {
        this.sources.reset();
        this.sources.add(source);
      }
    );
    this.titleProps = {
      originalTitle: this.model.get('title'),
      title: this.model.get('title'),
      displayError: false,
      onEdit: (inputValue: string) => {
        this.renderEditableTitle({ title: inputValue })
      },
      onEnter: () => {
        this.trigger('focusOnText');
      }
    }
  }

  focusOnText() {
    this.ui.textarea.focus();
  }

  useNewTextFormField() {
    this.renderEditableTitle({})
  }

  useNewTitleFormInput() {
    this.ui.textInputLabel.addClass('hidden');
    this.ui.textInput.addClass('hidden');
  }

  showTitleAndTextInputs() {
    this.ui.titleTextInputs.show()
    this.ui.titleTextPreloader.hide()
  }

  showTitleTextPreloader() {
    this.ui.titleTextInputs.hide()
    this.ui.titleTextPreloader.show()
  }

  includeReaderBranding(brandingStyleView: any) {
    this.ui.editAgentResultForm.addClass('with-reader-branding')
    this.brandingStyle.show(brandingStyleView)
  }

  getAttachments() {
    if (this.galleryExtension === undefined) {
      return []
    } else {
      return this.galleryExtension.getAttachments();
    }
  }

  getSourceData() {
    const primarySource = this.model.get("primary_source");
    const currentSource = this.sources.pop();
    if (primarySource.id !== currentSource.get("id")) {
      return {
        oldSource: new Source({ id: primarySource.id, name: primarySource.name }),
        newSource: currentSource
      };
    }
  }

  onRender() {
    this.disableSaving()
    this.renderEditableTitle({})
    this.renderSourceSearch({});
    this.ui.attachments.show()
    if (this.galleryExtension === undefined) {
      let attachments = this.model.clipAttachments().map((att: any) => {
        return _.extend(att, { isImage: att.file_type === 'image' })
      })
      this.galleryExtension =
        new ImageGalleryViewExtension(
          attachments, this.ui.attachmentsList[0], {
          onAdd: () => {},
          onUpdate: () => {},
          onDelete: () => {},
          onMove: () => {}
        }
      )
    }
    this.galleryExtension.renderGallery()
  }

  disableSaving() {
    this.ui.saveButton.toggleClass('is-disabled')
    this.ui.textarea.attr('disabled', 'disabled')
  }

  showTitleValidationErrorMessage() {
    this.renderEditableTitle({ displayError: true })
  }

  hideTitleValidationErrorMessage() {
    this.renderEditableTitle({ displayError: false })
  }

  hideTextInput() {
    this.ui.textarea.addClass('hidden')
  }

  enableSaving() {
    this.ui.saveButton.toggleClass('is-disabled')
    this.ui.textarea.removeAttr('disabled')
  }

  getTitle() {
    return this.titleProps.title
  }

  getText() {
    return this.ui.textarea.val()
  }

  serializeData() {
    return {
      sourceTitle: this.model.get("primary_source").name,
      publishTime: this.model.timelineTime(),
      isIE11: browserCheck.isIE11(),
      unEditableSource: !this.model.isImportedClip()
    };
  }

  private renderEditableTitle(props: IEditableDetailTitleViewOptionalProps) {
    const fullProps = _.extend({}, this.titleProps, props)
    this.titleProps = fullProps
    const editableDetailTitleView = <EditableDetailTitleView {...fullProps}/>
    ReactDOM.render(editableDetailTitleView, this.ui.editableTitle[0]);
  }

  private renderSourceSearch(props: any) {
    if (this.model.isImportedClip()) {
      const fullProps = _.extend({}, this.sourceSearchProps(), props);
      const sourceSearch = <SourceSearch {...fullProps}/>;
      ReactDOM.render(sourceSearch, this.ui.sourceSearch[0]);
    }
  }

  private sourceSearchProps() {
    const source = this.model.get("primary_source");
    return _.extend(
      {},
      this.searchPropFactory.generate(),
      {
        select2Id: "sourceSearchOnEdit",
        preselectedItems: [{ id: source.id, text: source.name }]
      }
    );
  }
}

EditAgentResultView.prototype.template = template
EditAgentResultView.prototype.ui = {
  'saveButton': '[data-save]',
  'textarea': '[data-clip-full-text]',
  textInput: '[data-text]',
  textInputLabel: '[data-text-label]',
  titleTextInputs: '[data-title-text-inputs]',
  titleTextPreloader: '[data-title-text-preloader]',
  'titleValidationContainer': '[data-title-validation-container]',
  attachmentsList: '[data-attachments-list]',
  attachments: '[data-attachments-box]',
  editAgentResultForm: '#edit-clip-form',
  editableTitle: '[data-editable-detail-title]',
  sourceSearch: '[data-source-search]'
}
EditAgentResultView.prototype.triggers = {
  'click @ui.saveButton': 'click:saveAgentResult',
  'click [data-cancel]': 'click:cancelEditAgentResult'
}
EditAgentResultView.prototype.regions = {
  richTextEditor: '[data-rich-text-editor]',
  brandingStyle: '[data-branding-style]'
}

export default EditAgentResultView
