import _ from 'underscore'
import BaseObject from 'libraries/baseObject'
import I18n from 'i18n'


var ReportWordingPresenter = BaseObject.extend({
  initialize: function (report) {
    this.report = report;
  },
  includeClipsFromSeveralSourcesText: function () {
    if (this.report.get('include_clips_from_several_sources')) {
      return I18n.t('webapp.reporting.wizard.content.all_sources');
    } else {
      return false;
    }
  },
  hasChartText: function () {
    if (this.report.get('has_chart')) {
      return I18n.t('webapp.reporting.wizard.content.overview_chart');
    } else {
      return false;
    }
  },
  hasOnlyDatasheet: function () {
    if (this.report.get('only_datasheet')) {
      return I18n.t('webapp.reporting.wizard.excel.datasheet_only');
    } else {
      return false;
    }
  },
  hasClipSnippetsText: function () {
    if (this.report.get('has_clip_snippets')) {
      return I18n.t('webapp.reporting.wizard.content.text_snippets');
    } else {
      return false;
    }
  },
  hasPrintClipsInFullLengthText: function () {
    if (this.report.get('has_print_clips_in_full_length')) {
      return I18n.t('webapp.reporting.wizard.content.print_as_fulltext');
    } else {
      return false;
    }
  },
  noFeaturesSelectedText: function () {
    var report = this.report;

    if (report.isExcel()) {
      if (report.get('include_clips_from_several_sources')) {
        return false;
      } else {
        return I18n.t('webapp.reporting.wizard.content.main_sources_only');
      }
    } else {
      if (report.get('has_chart') ||
          report.get('has_clip_snippets') ||
          report.get('has_print_clips_in_full_length')) {
        return false;
      } else {
        return I18n.t('webapp.reporting.wizard.content.no_additional');
      }
    }
  },
  groupedByText: function () {
    var report = this.report;
    var groupedBy = "";

    if (report.get('group_by') === "publish_date") {
      groupedBy += I18n.t('webapp.reporting.filter_and_sorting.group_by.publish_date');
    }
    if (report.get('group_by') === "media_type") {
      groupedBy += I18n.t('webapp.reporting.filter_and_sorting.group_by.mediatype');
    }
    if (report.get('group_by') === "topic") {
      groupedBy += this.topicLabelText();
    }
    if (report.get('group_by') === "agent") {
      groupedBy += I18n.t('webapp.reporting.filter_and_sorting.group_by.searchagent');
    }
    if (report.get('group_by') === "none") {
      groupedBy += I18n.t('webapp.reporting.filter_and_sorting.group_by.none');
    }

    return groupedBy;
  },
  sortedByText: function () {
    var report   = this.report;

    if (report.get('order_by') === "publish_time") {
      return I18n.t('webapp.reporting.filter_and_sorting.order_by.publish_date');
    } else {
      return I18n.t('webapp.reporting.filter_and_sorting.order_by.reach');
    }
  },
  filteredByText: function () {
    var report = this.report;

    var nameMapping = {
      print: I18n.t('webapp.reporting.filter_and_sorting.limit_to.print'),
      online: I18n.t('webapp.reporting.filter_and_sorting.limit_to.online'),
      social_media: I18n.t('webapp.reporting.filter_and_sorting.limit_to.social_media'),
      radio_tv: I18n.t('webapp.reporting.filter_and_sorting.limit_to.tv_and_radio')
    };

    return _.map(report.get('clip_types_to_keep'), function (clipType) {
      return nameMapping[clipType];
    }).join(', ');
  },
  topicLabelText: function () {
    var report = this.report;

    switch (report.get('topic_type')) {
    case "agent":
      return I18n.t('webapp.reporting.topics.agents');
    case "tag":
      return I18n.t('webapp.reporting.topics.tags');
    }
  }
});

export default ReportWordingPresenter;

