import template from 'text-loader!templates/timeline/readerTimeline.html';
import ArchiveDateView from 'views/timeline/reader/archiveDateView';
import TimelineView from 'views/timeline/timelineView';
import ArchiveDateApiCollection from 'collections/archiveDateApiCollection';
import _ from 'underscore';
import moment from 'moment';

class ReaderTimelineView extends TimelineView {
    initialize() {
        this.collection = new ArchiveDateApiCollection([], {
            readerConfigurationId: this.options.readerConfigurationId
        });
        this.initScrollingHandler();
        this.cursorPositionY;
    }

    findViewsWithModelIds(modelIds) {
        let archiveDateViews = [];
        this.children.each((archiveDateView) => {
            let correctView = _.contains(
                modelIds, archiveDateView.model.id
            );
            if (correctView) {
                archiveDateViews.push(archiveDateView);
            }
        });
        return archiveDateViews;
    }

    findModelContainingTime(time) {
        let unixTime = moment(time).unix();

        return _.find(this.collection.models, function (archiveDate) {
            let starts = archiveDate.get('starts');
            let ends   = archiveDate.get('ends');
            return unixTime > starts && unixTime < ends;
        });
    }

    appendHtml(collectionView, itemView, index) {
        var container = collectionView.ui.archiveDatesContainer;
        var followingElement = container.children()[index];
        if (followingElement) {
            collectionView.$(followingElement).before(itemView.el);
        } else {
            container.append(itemView.el);
        }
    }

    handleMouseDown(event) {
        this.mousePositionY = event.pageY;
    }

    setTopScrollPlaceholderHeight(height) {
        if (this.ui.scrollPlaceholderTop.height) {
            this.ui.scrollPlaceholderTop.height(height);
        }
    }

    setBottomScrollPlaceholderHeight(height) {
        if (this.ui.scrollPlaceholderBottom.height) {
            this.ui.scrollPlaceholderBottom.height(height);
        }
    }
}

ReaderTimelineView.prototype.template = template;
ReaderTimelineView.prototype.itemView = ArchiveDateView;
ReaderTimelineView.prototype.itemViewEventPrefix = "archiveDate";
ReaderTimelineView.prototype.ui = {
    archiveDatesContainer: '[data-container=archive-dates]',
    preloader: '[data-timeline-preloader]',
    timeline: '[data-timeline]',
    scrollPlaceholderTop: '[data-scroll-placeholder-top]',
    scrollPlaceholderBottom: '[data-scroll-placeholder-bottom]'
};
ReaderTimelineView.prototype.events = {
   'mousedown @ui.timeline': 'handleMouseDown'
};

export default ReaderTimelineView;
