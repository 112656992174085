import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/tagCloudWidget.html'
import TagCloud from 'tagcloud'
import jQuery from 'jquery'
import _ from 'underscore'

var $ = jQuery;
WidgetView.TagCloudWidget = WidgetView.extend({
  template: template,
  ui: _.extend({}, WidgetView.prototype.ui, {
    tagCloudTag: 'a.tagcloud-anchor'
  }),
  events: {
    'click @ui.tagCloudTag': '_handleClickInTagCloud'
  },
  prepareWidgetBody: function () {
    var tagCloud = TagCloud.create();
    var topTags = _.shuffle(this.model.get('cloud'));
    var element = $(this.el).find('.cloud')[0];
    jQuery.each(topTags, function (index, tag) {
      var date = new Date(tag.updated_at * 1000);
      tagCloud.add(tag.name, tag.id, tag.taggings_count, "#", date);
    });
    tagCloud.loadEffector('CountSize').base(12).range(4);
    tagCloud.loadEffector('DateTimeColor');
    tagCloud.setup(element);
  },
  _handleClickInTagCloud: function(e) {
    var tagId = this.$(e.target).parent().data('tag-id');
    this.model.set({ 'tag_ids': [tagId] });
    this.model.showAgentTag();
  }
});

export default WidgetView.TagCloudWidget;

