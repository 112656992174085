import vent from 'libraries/vent'
import when from 'when'

var promise = when.promise(function (resolve) {
  vent.once('shortcuts:ready', resolve);
});
promise.otherwise(); // ignore rejection

var Shortcutter = {
  whenReady: promise,
  bind: function (keys, callback) {
    vent.trigger('shortcuts:bindInModule', 'global', keys, callback);
  },
  unbind: function (keys) {
    vent.trigger('shortcuts:unbindInModule', 'global', keys);
  },
  bindInModule: function (namespace, keys, callback) {
    vent.trigger('shortcuts:bindInModule', namespace, keys, callback);
  },
  unbindInModule: function (namespace, keys) {
    vent.trigger('shortcuts:unbindInModule', namespace, keys);
  },
  updateNamespace: function (namespace) {
    vent.trigger('shortcuts:updateNamespace', namespace);
  }
};

export default Shortcutter;

