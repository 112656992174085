import Notificator from "../libraries/notificator";
import I18n from 'i18n'

interface ApiPayload {
  agent_result_ids?: number[];
  clip_ids?: number[];
}

export default class AddToReaderClient {
  private confId: number;
  private catId: number;

  constructor(confId: number, catId: number) {
    this.confId = confId;
    this.catId = catId;
  }

  fromClips(clipIds: number[]) {
    this.save(
      `/api/v3/reader_configurations/${this.confId}` +
      `/categories/${this.catId}/reader_results/create_from_clips`,
      { clip_ids: clipIds },
      I18n.t("webapp.result_lists.result_details.add_to_reader.failure"),
      I18n.t("webapp.result_lists.result_details.add_to_reader.success"),
    );
  }

  fromAgentResults(agentResultIds: number[]) {
    this.save(
      `/api/v3/reader_configurations/${this.confId}` +
      `/categories/${this.catId}/reader_results/create_from_agent_results`,
      { agent_result_ids: agentResultIds },
      I18n.t("webapp.result_lists.result_details.add_to_reader.failure"),
      I18n.t("webapp.result_lists.result_details.add_to_reader.success"),
    );
  }

  private save(
    apiEndPoint: string,
    payload: ApiPayload,
    errorMessage: string,
    successMessage: string
  ) {
    fetch(
      apiEndPoint,
      {
        method: "POST",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
      })
     .then(res => {
          if (res.status > 300) { throw new Error("Request not successful"); }
          return res;
      })
     .then(() => {
          Notificator.showNotification(successMessage);
      })
     .catch(() => {
          Notificator.showNotification(errorMessage);
      });
  }
}
