import * as React from "react";
import I18n from "i18n";
import * as jQuery from "jquery";
import * as _ from "underscore";
import Spinner from './Spinner'
import {
  IKbApiEntity, IKbApiRepType, IKbApiRepresentation, findRepresentation,
  SupportedLocale, localizedName
} from 'models/kbApiEntity'
import { EntityDetailViewContainer as Container } from 'components/entityDetailViewContainer'
import * as moment from 'moment';

export interface Props {
  organization?: IKbApiEntity
}
export class OrganizationDetailView extends React.Component<any, {}> {
  render() {
    if (this.props.organization) {
      const organization = this.props.organization
      const name = localizedName(organization)
      return (
        <Container>
          {this.updatedAt()}
          <h2 className="heading-xlarge mtm">{name}</h2>
          {this.organizationMarkup()}
        </Container>
      )
    } else {
      const label = I18n.t('webapp.components.organizationDetailView.loading')
      return (
        <Container> <Spinner label={label} /> </Container>
      )
    }
  }

  organizationMarkup() {
    const supportedLocales: SupportedLocale[] = ['de', 'fr', 'it', 'en']
    return supportedLocales.map((locale)=> {
      const name =
        findRepresentation(this.props.organization, 'name', locale)
      const label = I18n.t('webapp.components.organizationDetailView.name_' + locale)
        if (name != undefined) {
          const key = 'organizations-' + locale
          return (
            <form className="form-item mvm" key={locale}>
              <label className="form-item__label">{label}</label>
              <div className="form-item__input">
                <input type="text" className="text-input text-input--block" disabled={true} value={name}/>
              </div>
            </form>
          )
        }

    })
  }

  updatedAt() {
    const updatedAt = this.props.organization.data.attributes.updated_at
    const fmt = moment(updatedAt).format("DD.MM.YYYY HH:MM")
    return (
      <div className="link-bar mbm">
        <span className="link-bar__right-item text--grey"> <i className="fa fa-refresh text--white"></i> {fmt}</span>
      </div>
    )
  }

}
