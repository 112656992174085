import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import template from 'text-loader!templates/reporting/report.html'
import actionsPartial from 'text-loader!templates/reporting/report/actions.html'
import inhaltPartial from 'text-loader!templates/reporting/report/inhalt.html'
import filterSortPartial from 'text-loader!templates/reporting/report/filter_sort.html'
import reportHeadPartial from 'text-loader!templates/reporting/report/report_head.html'
import session from 'models/sessionInstance'
import ContentSettings from 'models/reporting/wizard/contentSettings'
import ExcelContentSettings from 'models/reporting/wizard/excelContentSettings'
import ReportPresenter from 'models/reporting/wizard/presenters/reportWordingPresenter'
import 'backbone.marionette'
import I18n from 'i18n'

var View = Backbone.Marionette.ItemView.extend({
  template: template,
  className: 'report-view',
  onRender: function () {
    this.disableDownloadButton();
    this.model.set('report_url', null);
    this.model.startPolling();
  },
  onClose: function () {
    this.model.stopPolling();
  },
  updateDownloadButtonState: function () {
    if (this.model.get('report_url')) {
      this.enableDownloadButton();
    } else {
      this.disableDownloadButton();
    }
  },
  enableDownloadButton: function () {
    this.$('#download').removeAttr('disabled');
  },
  disableDownloadButton: function () {
    this.$('#download').attr('disabled', 'disabled');
  },
  serializeData: function () {
    var report = this.model;

    return {
      title:        report.get('title'),
      summary:      report.get('summary'),
      start_date:   report.get('start_date'),
      end_date:     report.get('end_date'),
      topic_description: this._topicDescription(report),
      topic_label:  this.topicLabel(),
      topic_is_clip: report.isClipTopic(),
      filtered_by:  this.filteredByText(),
      grouped_by:   this.groupedByText(),
      sorted_by:    this.sortedByText(),
      created_at:   report.get('created_at'),
      creator_name: report.get('creator').get('full_name'),
      updated_at:   report.get('updated_at'),
      updater_name: report.get('updater').get('full_name'),
      show_additional_features: this.showAdditionalFeatures(),
      show_filtering_options:   this.showFilteringOptions(),
      has_chart:                this.hasChartText(),
      has_clip_snippets:        this.hasClipSnippetsText(),
      has_no_features_selected: this.noFeaturesSelectedText(),
      has_print_clips_in_full_length:     this.hasPrintClipsInFullLengthText(),
      include_clips_from_several_sources: this.includeClipsFromSeveralSourcesText(),
      editable:         report.isEditable(),
      isSwissCustomer:  session.isSwissCustomer(),
      features:         session.get('features'),
      hasOnlyDatasheet: this.hasOnlyDatasheet()
    };
  },

  _topicDescription: function(report) {
    return _.map(report.get('topics'), function(topic) {
      var functions = _.functions(topic);
      if (_.contains(functions, 'visibleName')) {
        return topic.visibleName();
      } else {
        return topic.get('name');
      }
    }).join(', ');
  },

  showAdditionalFeatures: function () {
    return this.reportContentSettings().hasContentSettings();
  },
  reportContentSettings: function () {
    if (this.model.isExcel()) {
      return ExcelContentSettings;
    } else {
      return ContentSettings;
    }
  },
  triggers: {
    'click #destroy': 'clicked:destroy',
    'click #download': 'clicked:download',
    'click #edit': 'clicked:edit',
    'click #preview': 'clicked:preview',
    'click .use-as-template-container .template-option[data-type="Medienspiegel"]': 'clicked:use-as-template-Medienspiegel',
    'click .use-as-template-container .template-option[data-type="ExcelReport"]': 'clicked:use-as-template-ExcelReport',
    'click .use-as-template-container .template-option[data-type="Newsletter"]': 'clicked:use-as-template-Newsletter'
  },
  initialize: function () {
    _.bindAll(this, 'destroyReport');
    this.on('clicked:destroy', this.destroyReport);
    this.on('clicked:download', this.downloadReport);
    this.on('clicked:edit', this.editReport);
    this.on('render', function () {
      $("#main").animate({ scrollTop: 0 });
    });
    this.on('clicked:use-as-template-Medienspiegel', () => {
      this.model.trigger('new-from-template', this.model, 'Medienspiegel');
    });
    this.on('clicked:use-as-template-ExcelReport', () => {
      this.model.trigger('new-from-template', this.model, 'ExcelReport');
    });
    this.on('clicked:use-as-template-Newsletter', () => {
      this.model.trigger('new-from-template', this.model, 'Newsletter');
    });

    this.on('before:render', () => {
      this.registerPartial('actions', actionsPartial);
      this.registerPartial('inhalt', inhaltPartial);
      this.registerPartial('filter_sort', filterSortPartial);
      this.registerPartial('report_head', reportHeadPartial);
    });
    this.model.on('change:report_url', this.updateDownloadButtonState, this);
    this.reportPresenter = new ReportPresenter(this.model);
  },
  showFilteringOptions: function () {
    var report      = this.model;
    var reportType  = report.get('type');

    if (reportType === 'ExcelReport') {
      return false;
    } else {
      return true;
    }
  },
  topicLabel: function () {
    return this._reportPresenter().topicLabelText();
  },
  hasChartText: function () {
    return this._reportPresenter().hasChartText();
  },
  hasClipSnippetsText: function () {
    return this._reportPresenter().hasClipSnippetsText();
  },
  hasOnlyDatasheet: function () {
    return this._reportPresenter().hasOnlyDatasheet();
  },
  hasPrintClipsInFullLengthText: function () {
    return this._reportPresenter().hasPrintClipsInFullLengthText();
  },
  includeClipsFromSeveralSourcesText: function () {
    return this._reportPresenter().includeClipsFromSeveralSourcesText();
  },
  noFeaturesSelectedText: function () {
    return this._reportPresenter().noFeaturesSelectedText();
  },
  groupedByText: function () {
    return this._reportPresenter().groupedByText();
  },
  sortedByText: function () {
    return this._reportPresenter().sortedByText();
  },
  filteredByText: function () {
    return this._reportPresenter().filteredByText();
  },
  destroyReport: function () {
    var report = this.model;

    var answer = window.confirm(I18n.t ('webapp.reporting.notification_messages.deletion_alert'));
    if (answer) {
      report.destroy({wait: true, error: function () {
        report.trigger('destroyFailed');
      }});
    }
  },
  downloadReport: function () {
    var report = this.model;
    var reportView = this;
    if (this.downloadSupported()) {
      reportView.$('#downloadContent').attr('src', report.get('report_url'));
    } else {
      window.open(report.get('report_url'));
    }
  },

  downloadSupported: function () {
    return !navigator.userAgent.match(/(iPod|iPhone|iPad)/);
  },
  editReport: function () {
    var report = this.model;
    report.edit();
  },
  previewReport: function () {
    var report = this.model;
    var reportView = this;

    reportView.$("#preview").attr('disabled', 'disabled');
    report.preview(function () {
      reportView.$("#preview").removeAttr('disabled');
    });
  },
  _reportPresenter: function () {
    if (_.isUndefined(this.reportPresenter)) {
      this.reportPresenter = new ReportPresenter(this.model);
    }

    return this.reportPresenter;
  }
});

export default View;

