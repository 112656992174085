export default {
  isPresent: function (value: string): boolean {
    return value.length > 0
  },
  isEmailValid: function (email: string): boolean {
    return this.emailPattern().test(email);
  },
  emailPattern: function (): RegExp {
    return /^\w+([\w\.\-+!#$%&'*\/=?`{|}~^]*)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
  }
};
