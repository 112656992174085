import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/components/richTextEditorView.html';
import I18n from 'i18n';
import 'libraries/froalaConfig';
import froalaButtons from 'libraries/froalaButtons'

class RichTextEditorView extends Marionette.ItemView {
    initialize(options) {
        this._richText = options.richText;
        this._placeholderText = options.placeholderText;
        this._isSummaryEdit = options.isSummaryEdit;
    }

    _toolbarButtons() {
        return froalaButtons();
    }

    _editorClass() {
      if (this._isSummaryEdit === true) {
        return 'froala textarea textarea--xxlarge wysiwyg-summary-content'
      } else {
        return 'froala textarea textarea--xxlarge wysiwyg-clip-content'
      }
    }

    onRender() {
        this.ui.textInput.froalaEditor({
                language: I18n.locale.split("-")[0],
                key: 'bTYPASIBGMWC1YLMP==',
                toolbarInline: true,
                editorClass: this._editorClass(),
                charCounterCount: false,
                heightMin: 160,
                heightMax: '100%',
                toolbarButtons:   this._toolbarButtons(),
                toolbarButtonsMD: this._toolbarButtons(),
                toolbarButtonsSM: this._toolbarButtons(),
                toolbarButtonsXS: this._toolbarButtons(),
                shortcutsEnabled: ['bold',
                                   'italic',
                                   'undo',
                                   'redo'],
                placeholderText: this._placeholderText,
                wordPasteKeepFormatting: false,
                htmlAllowComments: false,
                toolbarVisibleWithoutSelection: false,
                htmlAllowedTags: ['ul', 'ol', 'li', 'hr', 'h2', 'span', 'em', 'strong', 'p', 'normal', 'br', 'a']
            });
        this.ui.textInput.on('froalaEditor.focus', () => {
            this.$('.fr-placeholder').addClass('in-focus');
        });
        this.ui.textInput.on('froalaEditor.blur', () => {
            this.$('.fr-placeholder').removeClass('in-focus');
        });
        this._registerListeners();
    }

    focusOnText() {
        this.$(".fr-element").focus();
    }

    getRichText() {
        this._richText = this.ui.textInput.val();
        return this._richText;
    }

    setRichText(richText) {
        this._richText = richText;
        this.ui.textInput.val(richText);
        this.ui.textInput.froalaEditor('html.set', richText);
    }

    _registerListeners() {
        this.ui.textInput.on('froalaEditor.contentChanged', () => {
            this.trigger('change:text');
        });
    }

    serializeData() {
        return {
            text: this._richText,
            placeholder: this._placeholderText
        };
    }
}

RichTextEditorView.prototype.template = template;
RichTextEditorView.prototype.ui = { textInput: '[data-rich-text]' };

export default RichTextEditorView;
