import _ from 'underscore'
import Marionette from 'backbone.marionette'
import FavoriteSourceGroupsView from 'views/agents/settings/favoriteSourceGroupsView'

var FavoriteSourceGroupsController = Marionette.Controller.extend({
  initialize: function (options) {
    this.agent  = options.agent;
    var initialSourceGroupIds = this._initialSourceGroupIds();
    this.view   = new FavoriteSourceGroupsView({
      initialSourceGroupIds: initialSourceGroupIds
    });

    this._registerListeners();
  },
  _registerListeners: function () {
    this.listenTo(
      this.view,
      "changed:favoriteSourceGroups",
      this._updateFavoriteSourceGroups
    );
  },
  _initialSourceGroupIds: function () {
    var sourceGroupIds = this.agent.get('source_group_ids');
    if (!_.isUndefined(sourceGroupIds)) {
      return sourceGroupIds;
    } else {
      return null;
    }
  },
  _updateFavoriteSourceGroups: function (models, isActive) {
    this.trigger("changed:favoriteSourceGroups", models, isActive);
  }
});

export default FavoriteSourceGroupsController;
