/* jshint module: true */
import _ from 'underscore';
import Backbone from 'backbone';

let timeoutFunction = 0;
let userWasAway = false;
let Visibility = _.extend({}, Backbone.Events, {
    isOn() {
        return !document[this._hiddenMethodName()];
    },
    userAwayForMoreThanFiveMinutes() {
        var self = this;
        if (this.isOn()) {
            clearTimeout(timeoutFunction);
            if(userWasAway) {
                userWasAway = false;
                self.trigger("user-is-back");
            }
        } else {
            timeoutFunction = setTimeout(function(){
                    if(!self.isOn()){
                        userWasAway = true;
                        self.trigger("user-is-away");
                    }
                }, 5 * 60 * 1000
            );
        }
    },
    addListener() {
        document.addEventListener(
            this.visibilityChangeCallbackName(),
            this._handleVisibilityChange.bind(this),
            false
        );
    },
    removeListener() {
        document.removeEventListener(
            this.visibilityChangeCallbackName(),
            this._handleVisibilityChange.bind(this),
            false
        );
    },
    _handleVisibilityChange: function(){
        this.userAwayForMoreThanFiveMinutes();
    },
    _hiddenMethodName() {
        let hidden;
        let browser_specific_methods = ["hidden", "mozHidden", "msHidden", "webkitHidden"];
        browser_specific_methods.forEach( method => {
                if(typeof document[method] !== "undefined"){
                    hidden = method;
                }
        });
        return hidden;
    },
    visibilityChangeCallbackName() {
        let hidden_callbacks_map = new Map([
            [ 'hidden', 'visibilitychange' ],
            [ 'mozHidden', 'mozvisibilitychange' ],
            [ 'msHidden', 'msvisibilitychange' ],
            [ 'webkitHidden', 'webkitvisibilitychange' ],
        ]);
        return hidden_callbacks_map.get(this._hiddenMethodName());
    },

});

export default Visibility;
