import * as React from 'react';
import I18n from 'i18n';
import * as _ from 'underscore';
import ReaderNotificationTrigger from "models/newsroom/readerNotificationTrigger";

interface IReaderTriggerProps {
  trigger: ReaderNotificationTrigger;
  onTriggerViewOpened: (trigger: ReaderNotificationTrigger) => void;
  onTriggerViewClosed: (trigger: ReaderNotificationTrigger) => void;
  onTriggerDestroy: (trigger: ReaderNotificationTrigger) => void;
  onTriggerClick: () => void;
  selectedTriggers: ReaderNotificationTrigger[];
  key: string;
  editorialReader: boolean;
}

interface IReaderTriggerState {
  isOpen: boolean;
}

class ReaderNotificationTriggerView extends React.Component<IReaderTriggerProps, IReaderTriggerState> {
  constructor(props: IReaderTriggerProps) {
    super(props);
    const trigger = props.trigger;
    const selectedTriggers = props.selectedTriggers;
    this.state = { isOpen: _.contains(selectedTriggers, trigger) };
  }

  render() {
    let listItemClasses = ['collapsible__item'];
    let headerIconClass = 'icon-triangle-up';
    let headerClasses = ["collapsible__header"]
    let contentStyle = {};
    if (this.state.isOpen) {
      listItemClasses.push('is-open');
      headerIconClass = 'icon-triangle-down';
    }
    if (this.disabledTrigger()) {
      headerClasses.push("is-disabled");
      contentStyle = { color: "#dddddd" };
    }
    return (
      <li className={listItemClasses.join(" ")}>
        <div className={headerClasses.join(" ")}
             onClick={ this.onHeaderClick.bind(this) }>
          { I18n.t(`${this.triggerI18nPath()}.title`) }
          <div className="collapsible__header__action"><i className={headerIconClass}></i></div>
        </div>
        <div className="collapsible__body">
          <div className="collapsible__body__content" style={contentStyle}>
            <p>{I18n.t(`${this.triggerI18nPath()}.description`)}</p>
          </div>
          <div className="collapsible__body__action">
            <a href="#" onClick={this.onDestroy.bind(this)}><i className="icon-trash"></i> { I18n.t("webapp.reader_admin.edit.notification_configuration.trigger.remove") }</a></div>
        </div>
      </li>
    );
  }

  private disabledTrigger(): boolean {
    return this.props.editorialReader &&
           this.props.trigger.get("trigger_type") === "new_results";
  }

  private triggerI18nPath(): string {
    let triggerPath = "webapp.reader_admin.edit.notification_configuration.trigger";
    switch (this.props.trigger.get("trigger_type")) {
      case "archive_date_published":
        return `${triggerPath}.sync_with_publish_time`;
      case "new_results":
        return `${triggerPath}.new_results`;
      default:
        throw(`${this.props.trigger.get("trigger_type")} is unknown trigger type!`);
    }
  }

  private onHeaderClick(): void {
    if (!this.state.isOpen) {
      this.props.onTriggerViewOpened(this.props.trigger);
    } else {
      this.props.onTriggerViewClosed(this.props.trigger);
    }
    this.setState({ isOpen: !this.state.isOpen });
  }

  private onDestroy(e: React.MouseEvent<HTMLAnchorElement>): void {
    this.props.onTriggerDestroy(this.props.trigger);
    e.preventDefault();
  }
}

export default ReaderNotificationTriggerView;
