import ModuleController from 'controllers/moduleController';
import ApogeeLayout from 'views/apogeeLayout';
import SettingsSidebarView from 'views/layoutView';
import usersPromise from 'models/usersInstancePromise';
import agentsPromise from 'models/agentsInstancePromise';
import tagsPromise from 'models/tagsInstancePromise';
import contactsInstancePromise from 'models/contactsInstancePromise';
import contactGroupsInstancePromise
from 'models/contactGroupsInstancePromise';
import when from 'when';
import notificationSettingsPromise
from 'models/settings/notificationSettingsPromise';
import UsersSceneController
from 'controllers/settings/usersSceneController';
import NotificationsSceneController
from 'controllers/settings/notificationsSceneController';
import ContactsSceneController
  from 'controllers/settings/contacts/contactsSceneController';
import SourceCategoryLicensingSceneController
  from 'controllers/settings/SourceCategoryLicensingSceneController';
import TagSceneController
from 'controllers/settings/tagSceneController';
import PersonsSceneController from 'controllers/settings/persons/personsSceneController';
import LocationsSceneController from 'controllers/settings/locations/locationsSceneController';
import OrganizationsSceneController from 'controllers/settings/organizations/organizationsSceneController';
import BusinessesSceneController from 'controllers/settings/businesses/businessesSceneController';
import LawsSceneController from 'controllers/settings/laws/lawsSceneController';
import SettingsSidebar from 'components/settingsSidebar'
import session from 'models/sessionInstance'
import * as _ from 'underscore'
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';

export default class SettingsController extends ModuleController {
  initialize() {
    var self = this;
    this._dependenciesPromise().done(function () {
      self._setupViews();
    });
  }

  onShow() {
    notificationSettingsPromise.fetch();
    this._fetchContacts();
  }

  onClose() {
    this.view.close();
    this._closeScene();
  }

  _setupViews() {
    this.view = new ApogeeLayout();
    this.view.render();

    this.sidebarView = new SettingsSidebarView();
    this._registerListeners();
    this._showUsers();
    this._showViews();
    this.trigger('loaded');
  }

  renderView(additionalOpts?: object) {
    const props = this.buildProps(additionalOpts)
    const sidebarView = new ReactWrapper({
      getComponent: () => (<SettingsSidebar {...props} />)
    });
    this.sidebarView.showView(sidebarView);
  }

  _showViews() {
    this.view.showSidebar(this.sidebarView);
    this.renderView({isUsersActive: true})
  }

  buildProps(additionalProps?: object): AgentsSidebarComponentProps {
    const showKnowledgebaseManagement =
      session.isGroupAdmin() &&
      session.hasFeature('knowledgebase_management')
    const base = {
      showContacts: this._isAllowedToSeeContacts(),
      showTags: session.isGroupAdmin(),
      showSourceCategory: session.isGroupAdmin() && session.isGermanCustomer() && session.isLicensingConfigurable(),
      onUsersClick: () => this._showUsers(),
      onNotificationsClick: () => this._showNotifications(),
      onContactsClick: () => this._showContacts(),
      onTagsClick: () => this._showTags(),
      onSourceCategoryClick: () => this._showLicensing(),
      onPersonsClick: () => this._showPersons(),
      onOrganizationsClick: () => this._showOrganizations(),
      onLocationsClick: () => this._showLocations(),
      onBusinessesClick: () => this._showBusinesses(),
      onLawsClick: () => this._showLaws(),
      showKnowledgebaseManagement: showKnowledgebaseManagement
    }

    return _.extend(base, additionalProps)
  }

  _isAllowedToSeeContacts() {
    return session.isGroupAdmin() &&
      (session.hasFeature('reports_newsletter') ||
       session.hasFeature('executive_reader'));
  }

  _registerListeners() {
    this.listenTo(this.sidebarView, 'render', () => this.renderView());
  }

  _showUsers() {
    this.renderView({ isUsersActive: true })
    this._showScene(UsersSceneController);
  }

  _showNotifications() {
    this.renderView({ isNotificationsActive: true })
    this._showScene(NotificationsSceneController);
  }

  _showContacts() {
    this.renderView({ isContactsActive: true })
    this._showScene(ContactsSceneController);
  }

  _showPersons() {
    this.renderView({ isPersonsActive: true })
    this._showScene(PersonsSceneController);
  }

  _showOrganizations() {
    this.renderView({ isOrganizationsActive: true })
    this._showScene(OrganizationsSceneController);
  }

  _showLocations() {
    this.renderView({ isLocationsActive: true })
    this._showScene(LocationsSceneController);
  }

  _showBusinesses() {
    this.renderView({ isBusinessesActive: true })
    this._showScene(BusinessesSceneController);
  }

  _showLaws() {
    this.renderView({ isLawsActive: true })
    this._showScene(LawsSceneController);
  }

  _showTags() {
    this.renderView({ isTagsActive: true })
    this._showScene(TagSceneController);
  }

  _showLicensing() {
    this.renderView({ isSourceCategoryActive: true })
    this._showScene(SourceCategoryLicensingSceneController);
  }

  _fetchContacts() {
    contactsInstancePromise.fetch();
    contactGroupsInstancePromise.fetch();

    var self = this;
    when.join(
      contactsInstancePromise,
      usersPromise
    ).spread(function (contacts, users) {
      var updateContacts = function () {
        contacts.fetch();
      };
      self.listenTo(users, 'sync', updateContacts); // update/add
      self.listenTo(users, 'remove', updateContacts);
    }).orElse();
  }

  _dependenciesPromise() {
    return when.join(
      usersPromise,
      agentsPromise,
      tagsPromise
    );
  }
}
