import * as React from "react";
import * as _ from "underscore";
import ResultMetadataColumnView, { ResultMetadataColumnViewProps }
  from "components/resultDetailView/resultMetadataColumnView";
import ResultAttachmentView from "components/resultDetailView/resultAttachmentView";
import { IEsApiResultContent } from "models/esResult";
import I18n from "i18n";
import * as moment from 'moment';
import localizedLanguages from "libraries/localizedLanguages";
import DateFormatter from 'libraries/dateFormatter'
import ClipLinks from "components/clipLinks";
import session from 'models/sessionInstance';
import ClipTopicEntityMetadata from 'components/clipTopicEntityMetadata'


export default class ResultFooterView extends React.Component<IEsApiResultContent, {}> {
  render() {
    return (
      <div>
        <div className="clip-detail__footer">
          <div className="meta">
            <div className="row">
              { this.renderResultAttachmentsComponent() }
            </div>
          </div>
        </div>
        { this.renderAdditionalClipLinks() }
        <div className="clip-detail__footer">
          <div className="meta">
            <div className="row">
              { this.renderResultMetadata() }
            </div>
          </div>
          <div className="mtm">
            { this.renderClipTopicEntityMetadata() }
          </div>
        </div>
      </div>
    );
  }

  private renderAdditionalClipLinks() {
    let clipLinks = this.props.clip.meta.clip_links;
    if (!_.isEmpty(clipLinks)) { return <ClipLinks clipLinks={clipLinks} />; }
  }

  private renderClipTopicEntityMetadata() {
    if (session.hasFeature("show_enriched_content")) {
      const newTopics        = this.props.topics || []
      const deprecatedTopics = this.props.clip.meta.topics || [];
      const entities         = this.props.entities || [];
      const topics           =
      !_.isEmpty(newTopics) ? newTopics : deprecatedTopics;
      const props = {
        topics: topics,
        entities: entities
      }
      return <ClipTopicEntityMetadata {...props} />
    }
  }

  private renderResultMetadata() {
    const additionalInformation = this.additionalInformationMetadata();
    const reachInformation = this.reachInformationMetadata();
    const engagementInformation = this.engagementInformationMetadata();
    const metadataGroupsWithContent = _.filter(
      [reachInformation, engagementInformation, additionalInformation], (metadata) => {
        return !_.isEmpty(metadata.items);
      });
    const cssClassName = `col1of${metadataGroupsWithContent.length}`;
    return _.map(metadataGroupsWithContent, (metadata, idx) => {
      if (!_.isEmpty(metadata.items)) {
        const newMetadata = _.extend({}, metadata, { cssClassName: cssClassName });
        return <ResultMetadataColumnView {...newMetadata} key={idx} />;
      }
    });
  }

  private renderResultAttachmentsComponent() {
    const metadata = this.props.clip.meta;
    if (!_.isEmpty(metadata.attachments)) {
      return (
        <div>
          <div className="meta__title">
            { I18n.t("webapp.components.ClipAttachmentList.title") }
          </div>
          <ul className="file-list">
            { this.renderResultAttachments() }
          </ul>
        </div>
      );
    }
  }

  private additionalInformationMetadata() {
    const languages = this.props.clip.languages;
    const clipMetadata = this.props.clip.meta;
    const clipProperties = clipMetadata.properties;
    const primarySourceMeta = this.props.sources[0].meta;
    const zeroPad = (num: number, size: number) => {
      let s = num + "";
      while (s.length < size) {
        s = "0" + s;
      }
      return s;
    };
    let items = [];
    if (!_.isEmpty(languages)) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.language"),
        value: localizedLanguages(languages)
      });
    }
    if (clipMetadata.status) {
      let value = this.sopranoStatusValue(clipMetadata.status);
      if (this.props.clip.kind === "CuriaClip") { value = clipMetadata.status; }
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.status"),
        value: value
      });
    }
    if (primarySourceMeta.alexa_city) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.city"),
        value: primarySourceMeta.alexa_city
      });
    }
    if (primarySourceMeta.alexa_country) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.country"),
        value: primarySourceMeta.alexa_country
      });
    }
    if (primarySourceMeta.country) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.country"),
        value: I18n.t(primarySourceMeta.country)
      });
    }
    if (clipMetadata.reading_time) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.reading_time"),
        value: this.readingTime(clipMetadata.reading_time)
      });
    }
    if (clipMetadata.sr_number) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.sr_number"),
        value: clipMetadata.sr_number
      });
    }
    if (clipMetadata.official_compilation_number) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.official_compilation_number"),
        value: clipMetadata.official_compilation_number
      });
    }
    if (clipMetadata.federal_gazette_number) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.federal_gazette_number"),
        value: clipMetadata.federal_gazette_number
      });
    }
    if (clipMetadata.source_name) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.source"),
        value: clipMetadata.source_name
      });
    }
    if (clipMetadata.responsible_office) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.author"),
        value: clipMetadata.responsible_office
      });
    }

    let lastUpdate = moment(clipMetadata.last_update).format("x")
    let lastUpdate_timestamp = Number(lastUpdate);
    let publishTime = this.props.clip.publish_time
    if (lastUpdate && publishTime) {
      lastUpdate = DateFormatter.formatDate(new Date(lastUpdate_timestamp));
      publishTime = DateFormatter.formatDate(new Date(publishTime))
      if (lastUpdate === publishTime) {
        items.push({
          title: I18n.t("webapp.agents.results.result_details.metadata.publish_time"),
          value: publishTime
        });
      } else {
        items.push({
          title: I18n.t("webapp.agents.results.result_details.metadata.last_modification"),
          value: lastUpdate
        });
        items.push({
          title: I18n.t("webapp.agents.results.result_details.metadata.publish_time"),
          value: publishTime
        });
      }
    } else {
      if (lastUpdate) {
        lastUpdate = DateFormatter.formatDate(new Date(lastUpdate_timestamp));
        items.push({
          title: I18n.t("webapp.agents.results.result_details.metadata.last_modification"),
          value: lastUpdate
        });
      }
      if (publishTime) {
        publishTime = DateFormatter.formatDate(new Date(publishTime));
        items.push({
          title: I18n.t("webapp.agents.results.result_details.metadata.publish_time"),
          value: publishTime
        });
      }
    }
    if (clipMetadata.youtube_tos) {
      let tosUri = clipMetadata.youtube_tos;
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.youtube_tos"),
        value: `<a href="${tosUri}">${tosUri}</a>`
      });
    }
    if (clipMetadata.google_privacy_policy) {
      let privacyUri = clipMetadata.google_privacy_policy;
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.google_privacy_policy"),
        value: `<a href="${privacyUri}">${privacyUri}</a>`
      });
    }
    if (this.props.clip.kind == 'SopranoParlamentChBlogClip') {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.author"),
        value: I18n.t('webapp.agents.results.result_details.metadata.soprano.blog_author')
      });
    } else if (clipMetadata.author) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.author"),
        value: clipMetadata.author
      });
    }
    if (clipProperties.industry) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.industry"),
        value: clipProperties.industry
      });
    }
    if (clipProperties.located) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.located"),
        value: clipProperties.located
      });
    }
    if (clipProperties.point_of_interest) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.poi"),
        value: clipProperties.point_of_interest
      });
    }
    if (clipProperties.editorial_department) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.ressort"),
        value: clipProperties.editorial_department
      });
    }
    if (clipProperties.genre) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.genre"),
        value: clipProperties.genre
      });
    }
    if (clipProperties.dpa_author) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.author"),
        value: clipProperties.dpa_author
      });
    }
    if (primarySourceMeta.phone_number) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.phone_number"),
        value: primarySourceMeta.phone_number
      });
    }
    if (primarySourceMeta.media_type) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.publication_frequency"),
        value: primarySourceMeta.media_type
      });
    }
    if (clipProperties.emm_duration) {
      let minutes = Math.floor(clipProperties.emm_duration / 60);
      let seconds = clipProperties.emm_duration % 60;
      let duration =
        I18n.t("webapp.agents.results.result_details.metadata.minutes_exact",
               { mm: zeroPad(minutes, 2), ss: zeroPad(seconds, 2) });
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.duration"),
        value: duration
      });
    }
    if (clipProperties.comvision_media_length) {
      let minutes = Math.floor(clipProperties.comvision_media_length / 60);
      let seconds = clipProperties.comvision_media_length % 60;
      let duration =
        I18n.t("webapp.agents.results.result_details.metadata.minutes_exact",
               { mm: zeroPad(minutes, 2), ss: zeroPad(seconds, 2) });
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.duration"),
        value: duration
      });
    }
    if (clipProperties.clip_print_edition) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.print_edition"),
        value: clipProperties.clip_print_edition
      });
    }
    if (clipMetadata.business_number) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.business_number"),
        value: clipMetadata.business_number
      });
    }
    if (clipMetadata.business_type) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.business_type"),
        value: clipMetadata.business_type
      });
    }
    if (clipMetadata.submission_council) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.submission_council"),
        value: clipMetadata.submission_council
      });
    }
    if (clipMetadata.department) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.responsible_department"),
        value: clipMetadata.department
      });
    }
    if (clipMetadata.committees) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.responsible_commissions"),
        value: clipMetadata.committees.join("; ")
      });
    }
    if (clipMetadata.done_date) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.done_date"),
        value: DateFormatter.formatDate(new Date(clipMetadata.done_date))
      });
    }
    if (clipMetadata.federal_council_proposal_date) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.date_of_answering"),
        value: DateFormatter.formatDate(new Date(clipMetadata.federal_council_proposal_date))
      });
    }
    if (clipMetadata.legislative_period) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.legislative_period"),
        value: clipMetadata.legislative_period
      });
    }
    if (clipMetadata.session) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.session"),
        value: clipMetadata.session
      });
    }
    if (clipMetadata.last_modified) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.last_modified"),
        value: DateFormatter.formatDate(new Date(clipMetadata.last_modified))
      });
    }
    if (clipProperties.description) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.user_description"),
        value: clipProperties.description
      });
    }
    if (clipProperties.location) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.user_location"),
        value: clipProperties.location
      });
    }
    if (clipProperties.statuses_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.tweets"),
        value: clipProperties.statuses_count
      });
    }
    if (clipMetadata.signatory_names &&
        clipMetadata.signatory_names.length > 0) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.soprano.signatory_names"),
        value: clipMetadata.signatory_names.join('; ')
      });
    }
    return {
      title: I18n.t("webapp.agents.results.result_details.information"),
      items: items
    };
  }

  private engagementInformationMetadata() {
    const clipMetadata = this.props.clip.meta;
    const clipProperties = clipMetadata.properties;
    let items = [];
    if (clipMetadata.comment_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.reader_comments"),
        value: I18n.toNumber(Number(clipMetadata.comment_count), { precision: 0 })
      });
    }
    if (clipProperties.retweets_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.retweets"),
        value: I18n.toNumber(Number(clipProperties.retweets_count), { precision: 0 })
      });
    }
    if (clipMetadata.favorites_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.favourites"),
        value: I18n.toNumber(Number(clipMetadata.favorites_count), { precision: 0 })
      });
    }
    if (clipMetadata.like_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.likes"),
        value: I18n.toNumber(Number(clipMetadata.like_count), { precision: 0 })
      });
    }
    if (clipMetadata.dislike_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.dislikes"),
        value: I18n.toNumber(Number(clipMetadata.dislike_count), { precision: 0 })
      });
    }
    if (clipMetadata.reply_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.comments"),
        value: I18n.toNumber(Number(clipMetadata.reply_count), { precision: 0 })
      });
    }
    if (clipMetadata.share_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.shares"),
        value: I18n.toNumber(Number(clipMetadata.share_count), { precision: 0 })
      });
    }
    if (clipMetadata.plus_one_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.plus1"),
        value: I18n.toNumber(Number(clipMetadata.plus_one_count), { precision: 0 })
      });
    }
    return {
      title: I18n.t("webapp.agents.results.result_details.engagement"),
      items: items
    };
  }

  private reachInformationMetadata() {
    const userCountry = session.get("country");
    const primarySourceMeta = this.props.sources[0].meta;
    const clipMetadata = this.props.clip.meta;
    const clipProperties = clipMetadata.properties;
    const usagePerCountry = primarySourceMeta.usage_per_country;
    let usageUserCountry;
    if (usagePerCountry) {
      usageUserCountry = (usagePerCountry as any)[userCountry];
    }
    let items = [];
    if (primarySourceMeta.page_impressions) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.page_impressions_per_month"),
        value: primarySourceMeta.page_impressions
      });
    }
    if (primarySourceMeta.page_number) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.source_pages"),
        value: primarySourceMeta.page_number
      });
    }
    if (primarySourceMeta.unique_clients) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.unique_clients_per_month"),
        value: primarySourceMeta.unique_clients
      });
    }
    if (primarySourceMeta.reach) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.visits_per_month"),
        value: primarySourceMeta.reach
      });
    }
    if (usagePerCountry && usageUserCountry && _.contains(["at", "de", "ch"], userCountry)) {
      items.push({
        title: I18n.t(
          `webapp.agents.results.result_details.metadata.usage_from_${userCountry}`
        ),
        value: usageUserCountry
      });
    }
    if (primarySourceMeta.reach) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.circulation"),
        value: I18n.toNumber(primarySourceMeta.reach, { precision: 0 })
      });
    }
    if (clipProperties.pages) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.source_pages"),
        value: clipProperties.pages
      });
    }
    if (clipProperties.clipping_area) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.source_article_area"),
        value: clipProperties.clipping_area
      });
    }
    if (clipProperties.article_ad_price) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.article_ad_price"),
        value: clipProperties.article_ad_price
      });
    }
    if (clipProperties.emm_ad_value) {
      let emm_ad_value = I18n.toNumber(Number(clipProperties.emm_ad_value), { precision: 0 });
      items.push({
        title: I18n.t("webapp.agents.results.result_details.article_ad_price"),
        value: `${emm_ad_value} ${clipProperties.emm_ad_value_currency}`
      });
    }
    if (clipProperties.comvision_media_kind) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.media_kind"),
        value: clipProperties.comvision_media_kind
      });
    }
    if (primarySourceMeta.circulation_distributed) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.circulation_distributed"),
        value: primarySourceMeta.circulation_distributed
      });
    }
    if (clipProperties.followers_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.followers"),
        value: I18n.toNumber(Number(clipProperties.followers_count), { precision: 0 })
      });
    }
    if (clipMetadata.view_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.views"),
        value: I18n.toNumber(Number(clipMetadata.view_count), { precision: 0 })
      });
    }
    if (clipMetadata.subscriber_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.subscribers"),
        value: I18n.toNumber(Number(clipMetadata.subscriber_count), { precision: 0 })
      });
    }
    if (clipMetadata.circled_by_count) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.circled_by"),
        value: I18n.toNumber(Number(clipMetadata.circled_by_count), { precision: 0 })
      });
    }
    if (clipMetadata.likes) {
      items.push({
        title: I18n.t("webapp.agents.results.result_details.metadata.likes_user"),
        value: I18n.toNumber(Number(clipMetadata.likes), { precision: 0 })
      });
    }
    return {
      title: I18n.t("webapp.agents.results.result_details.reach"),
      items: items
    };
  }

  private sopranoStatusValue(status: string) {
    if (status === "preview") {
      return I18n.t(
        "webapp.agents.results.result_details.metadata.consultation_state_planned"
      );
    } else if (status === "draft") {
      return I18n.t(
        "webapp.agents.results.result_details.metadata.verbalix_state_draft"
      );
    } else if (status === "in_progress") {
      return I18n.t(
        "webapp.agents.results.result_details.metadata.consultation_state_in_progress"
      );
    } else {
      return I18n.t(
        "webapp.agents.results.result_details.metadata.consultation_state_closed"
      );
    }
  }

  private renderResultAttachments() {
    const metadata = this.props.clip.meta;
    return _.map(metadata.attachments, (metadata, idx) => {
      return <ResultAttachmentView {...metadata} key={idx}/>;
    });
  }

  private readingTime(time: number) {
    if (time > 0) {
      return I18n.t(
        "webapp.agents.results.result_details.metadata.minutes",
        {m: time}
      );
    } else {
      return I18n.t(
        "webapp.agents.results.result_details.metadata.reading_time_short"
      );
    }
  }
}
