import $ from 'jquery';
import LRUCache from 'lru';

const queryCache = new LRUCache(100);

function parseResponse(response) {
    return response.valid;
}

function makeRequest(query) {
    const param = $.param({query: query});
    const url = 'api/v3/agent_results/validate_query?' + param;
    return $.getJSON(url);
}

function validateAgentResultQuery(query) {
    const modifiedQuery = query.replace(/:/, '');
    const cachedResult = queryCache.get(modifiedQuery);
    if (cachedResult) {
        return cachedResult;
    } else {
        const promise = makeRequest(modifiedQuery).then((response) => {
            return parseResponse(response);
        });
        queryCache.put(modifiedQuery, promise);
        return promise;
    }
}

export default validateAgentResultQuery;

