import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'

interface DropdownOption<T> {
  text: string;
  value: T;
  enabled?: boolean;
}

interface IDropdownProps<T> {
  options: DropdownOption<T>[]
  selectedOption?: T
  onOptionChange: (newOption: T) => void
  readonly: boolean
  small?: boolean
  block?: boolean
}

interface IDropdownState<T> {
  selectedOption: T;
}

export default class Dropdown<T> extends React.Component<IDropdownProps<T>, IDropdownState<T>> {
  private dropdown: any;

  constructor(props: IDropdownProps<T>) {
    super(props)
    if (props.options.length == 0) {
      throw "Cannot create dropdown without options!"
    }
    this.state = this.initialState(props)
  }

  private initialState(props: IDropdownProps<T>): IDropdownState<T> {
    return {
      selectedOption: props.selectedOption || props.options[0].value
    }
  }

  render() {
    let toggle = this.props.readonly ? "" : "dropdown"
    const smallClass = this.props.small ? "button--small " : ""
    const blockClass = this.props.block ? "dropdown--block " : ""
    const blockButtonClass = this.props.block ? "button--block align-left " : ""
    const className = ["dropdown", blockClass];
    const classNameButton = "dropdown__toggle button " + smallClass + blockButtonClass
    return (
      <div className={className.join(" ")} ref={(node: any) => { this.dropdown = node; } }>
        <a className={classNameButton}
           data-toggle={toggle}>
          <i className="icon-triangle-down float-right"></i>
          {this.selectedOptionName()}
        </a>
        <ul className="dropdown__menu" role="menu">
          {this.options()}
        </ul>
      </div>
    )
  }

  selectedOptionName(): string {
    let option = _.find(this.props.options, option => option.value === this.state.selectedOption)
    if (option === undefined) {
      throw `Invalid selected option ${this.state.selectedOption}`
    }
    return option.text
  }

  onSelection(value: T) {
    this.props.onOptionChange(value);
    this.setState((prevState: IDropdownState<T>) => {
      return {
        selectedOption: value
      };
    });
  }

  options() {
    return this.props.options.filter(option => option.text !== this.selectedOptionName()).map((option) => {
      const key='dropdown-' + option.value
      const enabled = _.isUndefined(option.enabled) ? true : option.enabled;
      if (enabled) {
        const clickHandler = () => { this.onSelection(option.value); };
        return (
          <li className="dropdown__item" role="menuitem" key={key}>
            <a onClick={ clickHandler } className="dropdown__link">
              {option.text}
            </a>
          </li>
        );
      } else {
        const disabledEvent = (e: React.MouseEvent<HTMLAnchorElement>) => {
          if (this.dropdown) {
            this.dropdown.classList.add("is-open");
          }
        };
        return (
          <li className="dropdown__item" role="menuitem" key={key}>
            <a className="dropdown__link dropdown__link--disabled"
               onClick={ disabledEvent }>
              {option.text}
            </a>
          </li>
        );
      }
    });
  }
}
