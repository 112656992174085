import * as React from 'react';
import Sortable from 'sortablejs';
import bowser from 'bowser';
import _ from 'underscore';

class SortableButtonListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { active: false };
    }

    render() {
        const linkClasses = ["button-group__item", "button--pointing"];

        if (this.props.selected) {
            linkClasses.push('is-active');
        }

        return (
            <a className={linkClasses.join(" ")}
               ref={(wrapper) => { this.wrapperRef = wrapper; }}
               data-object-id={this.props.objectId}
               data-object-position={this.props.position}
               data-object-parent-id={this.props.parentId}
               onClick={this.handleButtonClick.bind(this)}>
               {this.itemIcon()} { this.props.name } { this.itemNamePostfix() }
            </a>
        );
    }

    handleButtonClick(e) {
        e.preventDefault();
        if (e.target.classList.contains('icon-menu')) {
            return;
        }
        // This looks upside down because we are transforming from
        // open => closed or closed => open
        if (this.props.selected) {
            this.props.onObjectClose();
        } else {
            this.props.onObjectClick(this.props.object);
        }
        this.setState((prevState) => {
            return {
                active: !prevState.active
            };
        });
    }

    itemIcon() {
        const iconClasses = ["icon-menu"];

        if (this.props.sortableEnabled) {
            return <i className={iconClasses.join(" ")}></i>
        }
    }

    itemNamePostfix() {
        return <span className="text--grey">{this.props.namePostfix}</span>
    }
}

class SortableButtonList extends React.Component {
    render() {
        const buttonListClasses = [
            "button-group",
            "button-group--vertical",
            "mbm",
            "sortable-button-list",
            "button-group--sortable"
        ];

        return (
            <div>
                <h1 className="heading-xlarge mbs">{this.props.header}
                    <span style={{ fontStyle: 'italic', marginLeft: '0.3em', color: 'gray', fontWeight: 'lighter', fontSize: '1rem' }}>
                        {this.props.subText ? this.props.subText : ''}
                    </span>
                </h1>
                <div className={buttonListClasses.join(" ")}
                     id={this.props.htmlId}
                     ref={(element) => { this.sortableItemList = element; }}>
                  {this.sortableButtonListItems()}
                  <a className="button-group__item"
                     onClick={this.handleAddItemClick.bind(this)}>
                    <strong>
                      <i className="icon-plus"></i> {this.props.addObjectText}
                    </strong>
                  </a>
                </div>
            </div>
        );
    }

    handleAddItemClick(e) {
        e.preventDefault();
        this.props.onAddNewObject();
    }

    componentDidMount() {
        if (this.props.sortableEnabled) {
            let opts = {
                group: this.props.sortableGroup,
                ghostClass: 'button-group__item--ghost',
                chosenClass: 'button-group__item--chosen',
                dragClass: 'button-group__item--drag',
                handle: '.icon-menu',
                draggable: '.button--pointing',
                onStart: () => {
                    this.sortableItemList.classList.add("sortable--active");
                },
                onEnd: () => {
                    this.sortableItemList.classList.remove("sortable--active");
                },
                onAdd: () => {
                    this.sortable.option('disabled', true);
                },
                onUpdate: (updateEvent) => {
                    // this.sortable.option('disabled', true);
                    this.props.onSortableUpdate(updateEvent);
                }
            };

            if (bowser.msie || bowser.msedge) {
                opts.forceFallback = true;
            }

            this.sortable = Sortable.create(this.sortableItemList, opts);
        }
    }

    sortableButtonListItems() {
        return _.map(this.props.sortableListItems, (listItem, idx) => {
            const props = {
                object: listItem,
                sortableEnabled: this.props.sortableEnabled,
                selected: listItem === this.props.selectedObject,
                objectId: listItem.get(this.props.listItemObjectId),
                position: listItem.get(this.props.listItemPosition),
                parentId: listItem.get(this.props.listItemParentId),
                onObjectClick: this.props.onObjectClick,
                onObjectClose: this.props.onObjectClose,
                name: listItem.get(this.props.listItemName),
                namePostfix: this.props.listItemObjectNamePostfix(listItem),
                active: false,
                key: listItem.get(this.props.listItemObjectId),
            };
            return <SortableButtonListItem {...props} />
        });
    }
}

export default SortableButtonList;
