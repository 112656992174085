import Backbone from 'backbone';
import Report from 'models/report';
import _ from 'underscore';

class Reports extends Backbone.Collection {
    appendReports(opts) {
        let lastResult = this.last();
        let self = this;

        if (lastResult) {
            opts = _.extend({data: {before_id: lastResult.get('id')}}, opts);
        }
        opts = _.extend({
            remove: false,
            success: function () {
                self.trigger("appending:finished");
            }
        }, opts);

        this.fetch(opts);
    }
    parse(response) {
        this.moreObjectsAvailable = response.more_objects_available;
        return response.collection;
    }
    allItemsLoaded() {
        return !this.moreObjectsAvailable;
    }
}

Reports.prototype.model = Report;
Reports.prototype.url = "/api/v3/reports/";

export default Reports;
