import Widget from 'models/dashboard/widget'

Widget.MultiAgentChartWidget = Widget.extend({
  clipCount: function (agentIndex) {
    var data = this.get('chart_data')[agentIndex];
    return this._countClipsInChartData(data);
  },
  agentIds: function () {
    return this.get('agent_ids');
  }
});

export default Widget.MultiAgentChartWidget;
