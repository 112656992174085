/* eslint no-invalid-this: "off" */
import $ from 'jquery'
import 'jquery.qtip2'

var data = {};
var register = function () {
  var fadeInDelay = 250;
  var fadeInDuration = 500;
  var fadeOutDelay = 750;
  var fadeOutDuration = 500;
  var opaqueAlpha = 1;
  var transparentAlpha = 0;
  var htmlOptions;
  var viewData = data[this.cid];
  var plainOptions = {
    position: {
      my: $(this).data('qtip-my') || 'bottom center',  // Position my top left...
      at: $(this).data('qtip-at') || 'top center' // at the bottom right of...
    },
    style: {
      classes: 'bluereport-tooltip'
    },
    show: {
      effect: function () {
        $(this).fadeTo(fadeInDuration, opaqueAlpha);
      },
      delay: fadeInDelay
    },
    hide: {
      effect: function () {
        $(this).fadeTo(fadeOutDuration, transparentAlpha);
      },
      delay: fadeOutDelay
    }
  };
  // Toggle Tooltips
  viewData.tooltips.push(
    viewData.rootElement.find('.tooltip__toggle--plain')
      .qtip(plainOptions)
  );

  // Toggle Tooltips
  viewData.rootElement.find('.tooltip__toggle--html').each(function () {
    htmlOptions = $.extend(true, {}, plainOptions, {
      content: $(this).parent('.tooltip').find('.tooltip__content'),
      position: {
        my: $(this).data('qtip-my') || 'bottom center',  // Position my top left...
        at: $(this).data('qtip-at') || 'top center' // at the bottom right of...
      }
    });
    viewData.tooltips.push($(this).qtip(htmlOptions));
  });
};
var unregister = function () {
  var viewData = data[this.cid];
  if (!viewData) { return; }
  $.each(viewData.tooltips, function () {
    this.qtip('destroy');
  });
};
var handleTooltips = function (view) {
  var viewData = { tooltips: [] };
  data[view.cid] = viewData;
  viewData.rootElement = view.$el;
  view.on('render', register);
  view.on('registerTooltips', register);
  view.on('close', unregister);
  view.on('update-tooltips', register);
};

export default handleTooltips;

