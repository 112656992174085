import $ from 'jquery'
import viewTemplate from 'text-loader!templates/reporting/wizard/newsletterRecipientSelection.html'
import optionsTemplate from 'text-loader!templates/reporting/wizard/newsletterRecipientSelectionOptions.html'
import StepView from 'views/reporting/wizard/stepView'
import Marionette from 'backbone.marionette'
import _ from 'underscore'
import select2CloseOnBlur from 'libraries/select2CloseOnBlur'
import 'jquery.select2'

var View = StepView.extend({
  template: viewTemplate,
  className: "wizard-step rounded clearfix",
  triggers: {
    'change select': 'change:select'
  },
  ui: {
    'select': '[data-recipient-selection]'
  },
  initialize: function () {
    _.bindAll(this, 'updateOptions');
    _.bindAll(this, 'showSpacer');
    this.listenTo(this, 'change:select', () => {
      var selection = this.ui.select.val();
      this.model.set({selection: selection});
    });
    this.listenTo(this.model, 'change:options', this.updateOptions);

    this.listenTo(this.model, 'change:active', this.showSpacer);

    StepView.prototype.initialize.call(this);
  },
  serializeData: function () {
    return {
      optGroups: this.model.get('options'),
      selectState: this.options.disabled ? "disable" : "enable"
    };
  },
  onRender: function () {
    var select = this.ui.select;

    select.val(this.model.get('selection')).select2({
      containerCssClass: "custom-select2"
    });
    select2CloseOnBlur(select);

    this.updateOptions();
  },
  updateOptions: function () {
    var serializedData = this.serializeData();
    var html = Marionette.Renderer.render(optionsTemplate, this.serializeData());

    this.ui.select.html(html);
    this.ui.select.select2(serializedData.selectState).trigger('change');
  },
  showSpacer: function () {
    $('#dropdown-spacer').addClass('dropdown-spacer');
  }
});

export default View;

