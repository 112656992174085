import * as _ from 'underscore'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ImageGalleryEditComponent, {Image, ImageID} from 'components/agents/imageGalleryEditComponent'

interface FileAttachment {
  id?: number
  imageID: ImageID
  file?: File
  url: string
  extension?: string
  isImage: boolean
  caption?: string
}

class FileAttachmentsToImages {
  static convert(attachment: FileAttachment): Image {
    return { id: attachment.imageID, url: attachment.url, caption: attachment.caption, isImage: attachment.isImage, extension: attachment.extension }
  }

  static convertAll(attachments: Array<FileAttachment>): Array<Image> {
    return _.map(attachments, (attachment) => {
      return FileAttachmentsToImages.convert(attachment)
    })
  }
}

class ImagesToFileAttachments {
  static convert(image: Image): FileAttachment {
    let id: number | undefined = undefined
    if (typeof image.id === 'number') {
      id = image.id
    }
    return { id: id, imageID: image.id, url: image.url, caption: image.caption, isImage: image.isImage }
  }
}

interface GalleryCallbackOptions {
  onAdd: () => void
  onUpdate: () => void
  onDelete: () => void
  onMove: () => void
}

interface StoredAttachment {
  id: number
  caption: string
  url: string
  extension?: string
  isImage: boolean
}

let imageIdCounter = 0

export default class ImageGalleryViewExtension {
  attachments: Array<FileAttachment>
  element: Element
  callbacks: GalleryCallbackOptions

  constructor(
    attachments: Array<StoredAttachment>,
    element: Element,
    callbacks: GalleryCallbackOptions
  ) {
    this.attachments = []
    this.preloadAttachments(attachments)
    this.element = element
    this.callbacks = callbacks
  }

  private preloadAttachments(attachments: Array<StoredAttachment>) {
    _.each(attachments, (attachment) => {
      let caption: string | undefined = undefined
      if (typeof attachment.caption === 'string') {
        caption = attachment.caption.replace(/&lt;b&gt;/g,"").replace(/&lt;\/b&gt;/g,"")
      }
      const newAttachment: FileAttachment = {
        id: attachment.id, imageID: attachment.id, url: attachment.url, caption: caption, isImage: attachment.isImage, extension: attachment.extension
      }
      this.attachments.push(newAttachment)
    })
  }

  getAttachments(): Array<FileAttachment> {
    return this.attachments
  }

  renderGallery() {
    const imageProps =
      FileAttachmentsToImages.convertAll(this.attachments).filter(
        image => !image.extension || !image.extension.match(/pdf/i)
      )
    const props = {
      images: imageProps,
      onDelete: this.onDelete.bind(this),
      onUpdate: this.onUpdate.bind(this),
      onMove: this.onMove.bind(this),
      onAdd: this.onAdd.bind(this)
    }
    const view = <ImageGalleryEditComponent {...props}/>
    ReactDOM.render(view, this.element)
  }

  private onMove(image: Image, newIndex: number): void {
    const existingAttachment = this.findFileAttachment(image.id)
    if (existingAttachment) {
      const oldIndex = this.attachments.indexOf(existingAttachment)
      this.attachments.splice(newIndex, 0, this.attachments.splice(oldIndex, 1)[0])
      this.callbacks.onMove()
    }
    this.renderGallery()
  }

  private findFileAttachment(id: ImageID): FileAttachment | undefined {
    return _.findWhere(this.attachments, {imageID: id})
  }

  private onUpdate(image: Image): void {
    const existingAttachment = this.findFileAttachment(image.id)
    if (existingAttachment) {
      const index = this.attachments.indexOf(existingAttachment)
      this.attachments[index] = _.extend(
        {},
        existingAttachment,
        ImagesToFileAttachments.convert(image)
      )
      this.callbacks.onUpdate()
    }
    this.renderGallery()
  }

  private onAdd(files: FileList): void {
    _.each(files, (file) => {
      if (file.type.match(/jpeg$|jpg$|png$/) === null) return
      const id = this.getNewImageID()
      const newAttachment = this.buildAttachment(id, file)
      this.attachments.push(newAttachment)
      this.callbacks.onAdd()
      const reader = new FileReader()
      reader.readAsDataURL(newAttachment.file as Blob)
      reader.onloadend = () => {
        newAttachment.url = reader.result as string
        this.renderGallery()
      };
    })
  }

  private onDelete(image: Image): void {
    this.attachments = _.reject(this.attachments, function (att: FileAttachment) {
      return att.imageID === image.id;
    });
    this.callbacks.onDelete()
    this.renderGallery()
  }

  private buildAttachment(imageId: ImageID, file: File): FileAttachment {
    return { imageID: imageId, file: file, url: '', caption: '', isImage: true }
  }

  private getNewImageID(): string {
    imageIdCounter++
    return `i${imageIdCounter}`
  }
}
