import ResearchTimelineController from
  "controllers/researchTimelineController";
import NewResearchResultListController from
  "controllers/research/newResearchResultListController";
import NewResearchResultApiCollection from "collections/newResearchResultApiCollection";
import EsResults from "collections/esResults";
import moment from "moment";
import _ from 'underscore'
import when from 'when'
import Notificator from 'libraries/notificator'
import I18n from 'i18n'

class NewResearchTimelineController extends ResearchTimelineController {
  initialize(options) {
    ResearchTimelineController.prototype.initialize.apply(this, [options]);
  }
  _initialFetch() {
    this._loadingNewestResults = true;
    let newestResults = new this.ListItemApiCollection([], this.listOptions);
    newestResults.prependResults().done(() => {
      this.listOptions = _.extend(
        {}, this.options, { startAt: moment(newestResults.startAt) }
      );
      this.view.resetStartYear(this.listOptions);

      when(this._expandFirstMonth()).done(() => {
        this.newestResult = newestResults.first();
        if (newestResults.length > 0) {
          let oldestResultTime = this.getTime(newestResults.last());
          let newestResultTime = this.getTime(newestResults.first());
          this._expandNewRecords(
            newestResults, oldestResultTime, newestResultTime
          );
          this.showTimeline();
        } else {
          this._showNoResults();
        }
        this.pollNewResults();
        // Results are not rendered yet, so wait for the
        // next execution context.
        setTimeout(() => { this._loadingNewestResults = false; }, 0);
      });
    }).fail(function () {
      Notificator.showErrorNotification(
        I18n.t('webapp.agents.results.result_list.error_loading_results')
      );
    });
  }
  getTime(result) {
    return new Date(result.get('clip').publish_time);
  }
  _startDate(options) {
    if (options.startAt === undefined) {
      return moment(Date.UTC(1849, 1, 23, 0, 0, 0));
    } else {
      return moment(options.startAt);
    }
  }
}

NewResearchTimelineController.prototype.ListItemCollection = EsResults;
NewResearchTimelineController.prototype.ListController =
  NewResearchResultListController;
NewResearchTimelineController.prototype.ListItemApiCollection =
  NewResearchResultApiCollection;
NewResearchTimelineController.prototype.listOptionKeys = [
  'search_query', 'source_category_ids', 'startAt', 'aggregateTimelineStart'
];

export default NewResearchTimelineController;
