import {
  IAddressbookModelProps,
  IAddressbookContact,
  IAddressbookGroup,
  AddressbookType
} from 'models/addressbookModel'
import ContactView from 'views/contactGroup/contactView'
import ContactGroupView from 'views/contactGroup/contactGroupView'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'

interface IContactListViewProps {
  editContactId: number | null
  highlightContactId: number | null
  newContactInput: boolean
  newContactEmailInvalid: boolean
  newContactText: string
  addressBookText: string
  useButtonStyle: boolean
  contacts: Array<IAddressbookModelProps>
  visibilityMap: Map<string, boolean>
  searchInput: string
  searchOpen: boolean
  onAdd: (contact: IAddressbookContact, query: string) => void
  onEdit: (contact: IAddressbookContact, query: string) => void
  onRemove: (contact: IAddressbookContact, query: string) => void
  onDestroy: (contact: IAddressbookContact, query: string) => void
  onListItemClick: (contact: IAddressbookContact, query: string) => void
  onCancel: (query: string) => void
  onUpdate: (contact: IAddressbookContact, name: string, email: string, query: string) => void
  onNewContact: (query: string) => void
  onCreateContact: (name: string, email: string, query: string) => void
  onGroupAdd: (group: IAddressbookGroup, query: string) => void
  onGroupRemove: (group: IAddressbookGroup, query: string) => void
  addable: boolean
  editable: boolean
  removable: boolean
  destroyable: boolean
  groupAddable: boolean;
  groupContactEditable: boolean;
}

interface IContactSearchListState {
  addressBookOpen: boolean
}

class ContactsSearchListView extends React.Component<IContactListViewProps, IContactSearchListState> {
  constructor(props: IContactListViewProps) {
    super(props)
    this.state = this.initialState(props)
  }

  private initialState(props: IContactListViewProps) {
    return {
      addressBookOpen: props.searchInput !== '' || props.searchOpen
    }
  }

  componentWillReceiveProps(nextProps: IContactListViewProps) {
    if (this.props.searchInput !== nextProps.searchInput) {
      this.setState((prevState, props) => {
        return {
          addressBookOpen: nextProps.searchInput !== '' || props.searchOpen
        }
      })
    }
  }

  private arrowClass(): string {
    if (this.state.addressBookOpen) {
      return 'icon-triangle-down'
    } else {
      return 'icon-triangle-right'
    }
  }

  private toggleAddressBook() {
    this.setState((prevState, props) => {
      return { addressBookOpen: !prevState.addressBookOpen }
    })
  }

  render() {
    let classes = ["contact-list__item"]
    if (!this.props.useButtonStyle) {
      classes.push('contact-list__item-group-title')
    }
    return (
      <li className={classes.join(' ')}>
        { this.addressbookHeader() }
        <ul data-contact-group-address-book
            className={this.state.addressBookOpen ? '' : 'hidden'}>
          { this.contactItems() }
        </ul>
      </li>
    )
  }

  private addressbookHeader() {
    if (!this.props.useButtonStyle) {
      return (
        <span>
          <div className="contact-list__item-arrow"
               onClick={() => { this.toggleAddressBook() }}>
            <i className={this.arrowClass()}></i>
          </div>
          <div className="contact-list__item-field">
            <strong>{this.props.addressBookText}</strong>
          </div>
        </span>
      )
    } else {
      return <span></span>
    }
  }

  private contactItems() {
    return _.map(this.props.contacts, (contact) => {
      if (contact.type === AddressbookType.Contact) {
        return this.renderContact(contact)
      } else {
        return this.renderContactGroup(contact)
      }
    })
  }

  private renderContact(contact: IAddressbookModelProps) {
    const visible = this.props.visibilityMap.get(contact.id) || false
    return (
      <ContactView key={contact.id}
                   onAdd={this.props.onAdd}
                   onEdit={this.props.onEdit}
                   onRemove={this.props.onRemove}
                   onDestroy={this.props.onDestroy}
                   onListItemClick={this.props.onListItemClick}
                   onCancel={this.props.onCancel}
                   onUpdate={this.props.onUpdate}
                   onGroupAdd={this.props.onGroupAdd}
                   onGroupRemove={this.props.onGroupRemove}
                   removable={this.props.removable}
                   destroyable={this.props.destroyable}
                   addable={this.props.addable}
                   editable={this.props.editable}
                   editContactId={this.props.editContactId}
                   highlightContactId={this.props.highlightContactId}
                   visible={visible}
                   searchInput={this.props.searchInput}
                   useButtonStyle={this.props.useButtonStyle}
                   groupAddable={false}
                   groupRemovable={false}
                   contact={contact}
                   groupMember={false}/>
    )
  }

  private renderContactGroup(group: IAddressbookModelProps) {
    const visible = this.props.visibilityMap.get(group.id) || false
    return (
      <ContactGroupView key={group.id}
                        onAdd={this.props.onAdd}
                        onEdit={this.props.onEdit}
                        onRemove={this.props.onRemove}
                        onDestroy={this.props.onDestroy}
                        onListItemClick={this.props.onListItemClick}
                        onCancel={this.props.onCancel}
                        onUpdate={this.props.onUpdate}
                        onGroupAdd={this.props.onGroupAdd}
                        onGroupRemove={this.props.onGroupRemove}
                        removable={this.props.removable}
                        destroyable={this.props.destroyable}
                        addable={this.props.addable}
                        editable={this.props.editable}
                        editContactId={this.props.editContactId}
                        highlightContactId={this.props.highlightContactId}
                        visible={visible}
                        searchInput={this.props.searchInput}
                        useButtonStyle={false}
                        groupAddable={this.props.groupAddable}
                        groupContactEditable={this.props.groupContactEditable}
                        groupRemovable={false}
                        contact={group}
                        groupMember={false}/>
    )
  }
}

export default ContactsSearchListView
