import WidgetView from 'views/dashboard/widgetView'
import ChartWidgetView from 'views/dashboard/widgetView/chartWidgetView'
import template from 'text-loader!templates/dashboard/widget/tagChartWidget.html'

WidgetView.TagChartWidget = ChartWidgetView.extend({
  template: template,
  prepareSerializedData: function () {
    var values = {
      truncatedLabel: this.truncatedLabel(),
      clip_count: this.model.clipCount(),
      color: this.model.get('color')
    };
    return values;
  },
  getColor: function () { return '#006699'; }
});

export default WidgetView.TagChartWidget;

