import Backbone from 'backbone';
import ContactGroup from 'models/contactGroup';

class ContactGroups extends Backbone.Collection {
    parse(response) {
        return response.collection;
    }
    comparator(contactGroup) {
        return contactGroup.get('name').toLowerCase();
    }
}

ContactGroups.prototype.model = ContactGroup;
ContactGroups.prototype.url = '/api/v3/contact_groups';

export default ContactGroups;
