import * as React from "react";

interface Props {
  cssClass?: string
}

export default function Loading({cssClass}: Props) {
  return (
    <div className={`preloader ${cssClass}`}>
      <p className="preloader__label">Loading</p>
    </div>
  );
}