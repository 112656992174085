import AgentResultDetailController from
    'controllers/agents/agentResultDetailController';
import ReaderResultHeaderActionsController from
    'controllers/newsroom/readerResultHeaderActionsController';
import ReaderResultActionsController from
    'controllers/newsroom/readerResultActionsController';
import ReaderResultDetailView from 'views/newsroom/readerResultDetailView';
import EditReaderResultController from 'controllers/editReaderResultController';
import session from "models/sessionInstance";
import Shortcutter from 'libraries/shortcutter';
import _ from 'underscore';

class ReaderResultDetailController extends AgentResultDetailController {
    initialize(options) {
        this.readerResult = options.readerResult;
        this.agentResult  = options.agentResult;
        super.initialize(options.agentResult);
    }

     _initView() {
        this.view = new ReaderResultDetailView({model: this.agentResult});
    }

    context() {
        return 'newsroom';
    }

    _avFileNotifications() {}

    _defineHeaderActionsController() {
        var options = {
            agentResult: this.agentResult,
            readerResult: this.readerResult
        };
        this.headerActionsController =
            new ReaderResultHeaderActionsController(options);
    }

    _maybeMarkAgentResultAsRead() {}

    _bindShortcuts() {
        var self = this;

        Shortcutter.bindInModule('newsroom', 'alt+o', function () {
          if (session.isImpersonated() || session.can('internal_access')) {
            const clipId = self.agentResult.get('clip_id');
            const href = self.getClipAdminUrl(clipId);
            if (!_.isUndefined(href) && !_.isNull(href)) {
              window.open(href, '_blank');
            }
          }
        });
    }

    _unbindShortcuts() {
        Shortcutter.unbindInModule('newsroom', 'alt+o');
    }

    _initializeActionsController() {
        this.agentResultActionsController =
            new ReaderResultActionsController({
                readerResult: this.readerResult,
                agentResult: this.agentResult
            });
        this.view.actions.show(this.agentResultActionsController.view);
    }

    _createAndDisplayEditController() {
      this.editController = new EditReaderResultController({
        agentResult: this.agentResult,
        readerResult: this.readerResult
      });

      this.view.showEditFormView(this.editController.view);

      this.listenTo(this.editController, 'edit-form:finished', () => {
        this.view.showAgentResultDetailView();
        this.view.render();
        this.editController.close();
      });
    }
}

export default ReaderResultDetailController;
