import WidgetView from 'views/dashboard/widgetView'
import ChartWidgetView from 'views/dashboard/widgetView/chartWidgetView'
import template from 'text-loader!templates/dashboard/widget/agentChartWidget.html'
import _ from 'underscore'

WidgetView.AgentChartWidget = ChartWidgetView.extend({
  template: template,
  onInitialize: function () {
    _.bind(this.showAgent, this);
    this.enableAutoRefresh();
    this.setClickHandler();
  },
  showAgent: function () {
    this.model.showAgent();
  },
  setClickHandler: function () {
    this.on('click:h1', this.showAgent);
  },
  removeClickHandler: function () {
    this.off('click:h1', this.showAgent);
  },
  enterEditMode: function () {
    this.removeClickHandler();
  },
  exitEditMode: function () {
    this.setClickHandler();
  }
});

export default WidgetView.AgentChartWidget;

