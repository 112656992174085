import * as React from "react"

interface IAddContactViewProps {
  newContactInput: boolean
  newContactEmailInvalid: boolean
  newContactText: string
  searchInput: string
  useButtonStyle: boolean
  onCancel: (query: string) => void
  onNewContact: (query: string) => void
  onCreateContact: (name: string, email: string, query: string) => void
}

export default class AddContactView extends React.Component<IAddContactViewProps, {}> {
  render() {
    if (this.props.newContactInput) {
      return this.newContactInputView()
    } else {
      return this.addNewContactView()
    }
  }

  private addNewContactView() {
    const onNewContact = () => {
      this.props.onNewContact(this.props.searchInput)
    }
    let liClasses = ['contact-list__item']
    let divClasses = []
    if (this.props.useButtonStyle) {
      liClasses.push('contact-list__item--button')
    } else {
      divClasses.push("contact-list__item-button")
    }

    return (
      <li className={liClasses.join(' ')}>
        <div data-new-contact className={divClasses.join(' ')}>
          <div onClick={onNewContact} className="contact-list__item-field">
            <i className="icon-plus"></i> <span>{this.props.newContactText}</span>
          </div>
        </div>
      </li>
    )
  }

  private newContactInputView() {
    const onCreateClickHandler = () => {
      this.props.onCreateContact(
        this.getName(),
        this.getEmail(),
        this.props.searchInput
      )
    }
    const onKey = (e: any) => {
      if (e.keyCode == 13) {
        onCreateClickHandler()
      }
    }
    let emailInputCssClasses = 'form-item form-item--inline'
    if (this.props.newContactEmailInvalid) {
      emailInputCssClasses = emailInputCssClasses + ' is-erroneous'
    }
    let nameInput = ''
    let emailInput = ''
    if (/@/.test(this.props.searchInput)) {
      emailInput = this.props.searchInput
    } else {
      nameInput = this.props.searchInput
    }
    return (
      <div
        data-new-contact
        className="contact-list__item contact-list__item-button">
        <div data-form className="contact-list__item-field">
          <div className="contact-list__item-actions">
            <i onClick={onCreateClickHandler} className="fa fa-check" />{" "}
            <i
              onClick={() => {
                this.props.onCancel(this.props.searchInput);
              }}
              className="icon-delete"
            />
          </div>
          <i className="icon-envelope" />
          <form>
            <div className="form-item form-item--inline">
              <div className="form-item__input">
                <input
                  data-form-name-input
                  className="contact-list__filter-add text-input"
                  type="text"
                  onKeyUp={onKey}
                  defaultValue={nameInput}
                />
              </div>
            </div>
            <div className={emailInputCssClasses}>
              <div className="form-item__input">
                <input
                  data-form-email-input
                  className="contact-list__filter-add text-input"
                  type="text"
                  onKeyUp={onKey}
                  defaultValue={emailInput}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  getName(): string {
    const name = $('[data-form-name-input]').val()
    if (name)
      return name.toString()
    else
      throw("name is undefined")
  }

  getEmail(): string {
    const email = $('[data-form-email-input]').val()
    if (email)
      return email.toString()
    else
      throw("email is undefined")
  }
}
