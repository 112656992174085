import $ from 'jquery'
import when from 'when'

var RecordingOrder = {
  order: function (agentResultId, notes) {
    return when($.ajax({
      type: 'POST',
      url: '/api/v3/recording_orders/',
      data: { agent_result_id: agentResultId, notes: notes}
    }));
  }
};

export default RecordingOrder;
