import ContactReactAdapter from 'models/contactReactAdapter'
import Marionette from 'backbone.marionette';
import ContactGroupsView from 'views/settings/contacts/contactGroupListCollectionView';
import ContactsOverviewView from 'views/settings/contacts/contactsOverviewView';
import Deferred from 'libraries/deferred';
import contactGroupsInstance from 'collections/contactGroupsInstance';
import contactsInstance from 'collections/contactsInstance';
import I18n from 'i18n';
import _ from 'underscore';

class ContactsOverviewController extends Marionette.Controller {
    initialize() {
        this.view = new ContactsOverviewView();
        this.contactGroupsView = new ContactGroupsView({
            collection: contactGroupsInstance
        });
        this.listenTo(this.contactGroupsView, 'itemview:changed',
            this._handleGroupCollectionChange);
        this.listenTo(this.view, 'render', this._onViewRender);
        this.listenTo(contactsInstance, 'change', this.renderContactList);
    }

    contactsListViewProps() {
      return {
        contacts: contactsInstance.models,
        activeContactId: undefined,
        onAddButton: (filter) => {
          this.trigger('contact:add', filter)
          this.renderContactList()
        },
        onContactSelected: (contact) => {
          this.trigger('contact:clicked', contact)
          this.renderContactList({
            activeContactId: contact.id
          }),
          this.deselectAll();
        },
        onCleanupClick: () => {
          this.trigger('contacts:cleanup');
        }
      }
    }

    deselectAll() {
        _.each(this.groupViewHash, this._deselectView);
        this.currentSelection = null;
    }

    onClose() {
        this.groupViewHash = {};
        this.currentSelection = null;
    }

    selectContactGroup(contactGroup) {
        var self = this;
        Deferred.async(function () {
            self._selectContactGroup(contactGroup);
        });
    }

    selectContact(contact) {
        var self = this;
        Deferred.async(function () {
            self._selectContact(contact);
        });
    }

    _selectContactGroup(contactGroup) {
        this.deselectAll();
        var view = this.groupViewHash[contactGroup.cid];
        if (view) {
            view.setSelectionState(true);
            this.currentSelection = view.model;
        }
    }

    _selectContact(contact) {
        this._deselectAll();
    }

    _handleGroupClick(contactGroupView) {
        var model = contactGroupView.model;
        this.trigger('contact-group:clicked', model);
        this.renderContactList();
    }

    _onViewRender() {
        this.groupViewHash = {};
        this.currentSelection = null;
        this._registerViewListeners();
        this.view.contactGroupList.show(this.contactGroupsView);
        this.renderContactList();
        this.view.ui.addContactGroup.appendTo(this.contactGroupsView.$el);
    }

    refreshContactList() {
      const self = this;
      contactsInstance.fetch({
        success: function () {
          self.renderContactList();
        }
      });
    }

    renderContactList(props) {
        let fullProps = this.contactsListViewProps()
        if (props !== undefined) {
            fullProps = _.extend({}, fullProps, props)
        }
        this.view.showContactList(fullProps);
    }

    _registerViewListeners() {
        this.listenTo(this.contactGroupsView, 'itemview:clicked',
            this._handleGroupClick);
        this.listenTo(this.contactGroupsView, 'before:item:added',
            this._registerGroupView);
        this.listenTo(this.contactGroupsView.collection, 'sort',
            this._rerenderer(this.contactGroupsView));
        this.listenTo(this.view, 'add-contact-group:clicked',
            () => { this.trigger('contact-group:add'); });
        this.listenTo(this.view, 'add-contact:clicked',
            () => { this.trigger('contact:add'); });
    }

    _rerenderer(view) {
        return () => {
            var selection = this.currentSelection;
            view.render();
            if (selection) {
                if (this.groupViewHash[selection.cid]) {
                    this._selectContactGroup(selection);
                } else {
                    this._selectContact(selection);
                }
            }
        };
    }

    _registerGroupView(view) {
        this.groupViewHash[view.model.cid] = view;
    }

    _deselectView(view) {
        view.setSelectionState(false);
    }

    _deselectAll() {
        _.each(this.groupViewHash, this._deselectView);
        this.currentSelection = null;
    }

    _handleGroupCollectionChange() {
        contactGroupsInstance.sort();
    }
}

export default ContactsOverviewController;
