import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/agents/settings/editAgentSettings.html';
import handleTooltips from 'models/handleTooltips';
import _ from 'underscore';
import * as React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper';
import session from "models/sessionInstance";
import Modal from 'components/modal';
import 'bpopup';
import I18n from "i18n";
import { AgentsPermissions } from "components/agents/permissions";

class EditAgentSettingsView extends Marionette.Layout {
  initialize(options) {
    this.readOnly = options.readOnly;
    this.rssAvailable = options.rssAvailable;
    this.newSearchAgentManagement = options.newSearchAgentManagement;
    handleTooltips(this);
  }

  onRender() {
    this.updateSharedCheckbox();
  }

  updateSharedCheckbox() {
    var shared = this.model.get('shared');
    this.$(this.ui.sharedCheckbox).toggleClass('is-active', shared);
  }

  disableCheckbox() {
    this.ui.sharedCheckbox.prop('disabled', true);
  }

  serializeData() {
    return {
      readOnly: this.readOnly,
      rssAvailable: this.rssAvailable,
      sharedCanBeChanged: AgentsPermissions.canUpdateSharedValue(this.model),
      feed_url: this.model.get('feed_url'),
      newSearchAgentManagement: this.newSearchAgentManagement
    };
  }

  triggerAgentModelUpdate(attributeName) {
    if (this.model.changedAttributes([attributeName])) {
      this.model.trigger('changed:' + attributeName);
    }
  }

  _handleSharedClick() {
    if (session.hasFeature("new_search_agent_management")) {
        let shared = this.model.get('shared');
        if (shared) {
          this.showPrivatizationModal(
            this.privatizationProps({ open: true })
            );
        } else {
            this.trigger("click:sharedCheckbox");
        }
    } else {
        this.trigger("click:sharedCheckbox");
    }
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.showPrivatizationModal(this.privatizationProps({ close: true }));
      this.trigger("click:sharedCheckbox");
    }
  }

  privatizationProps(props) {
    const prefix =
      'webapp.agents.edit.agent_subscriptions.modals.toggle_visbility.'
    let defaultProps = {
      title: I18n.t(prefix + 'title'),
      cancelLabel: I18n.t(prefix + 'cancel'),
      confirmationLabel: I18n.t(prefix + 'confirm'),
      onConfirm: () => {
        this.showPrivatizationModal(this.privatizationProps({ close: true }));
        this.trigger("click:sharedCheckbox");
      },
      onKeyDown: (e) => this.handleKeyPress(e),  
      modalId: 'privatization-modal'
    };
    return _.extend({}, defaultProps, props);
  }

  showPrivatizationModal(props) {
    const key1 =
      'webapp.agents.edit.agent_subscriptions.modals.toggle_visbility.body'
    const key2 =
      'webapp.agents.edit.agent_subscriptions.modals.toggle_visbility.body_last_line'
    const body = I18n.t(key1)
    const lastLine = I18n.t(key2)

    this.reactModal = new ReactWrapper({
      getComponent: () => {
        return (<Modal {...props}>
            {body}<br />{lastLine}
          </Modal>
        );
      }
    });
    this.modal.show(this.reactModal);
  }

  showUnsubscribeButton(view) {
    if (this.unsubscribeButton){
      this.unsubscribeButton.show(view);
    }
  }

  showSubscriptions(view) {
    if (this.subscriptions) {
      this.subscriptions.show(view);
    }
  }

  hideUnSubscribeButton() {
    if (this.unsubscribeButton) {
      this.unsubscribeButton.close()
    }
  }

  hideSubscriptions() {
    if (this.subscriptions) {
      this.subscriptions.close()
    }
  }
}

EditAgentSettingsView.prototype.template = template;
EditAgentSettingsView.prototype.ui = {
  sharedCheckbox: "#shared-checkbox",
};
EditAgentSettingsView.prototype.regions = {
  modal: "[data-privatization-modal]",
  unsubscribeButton: "[data-unsubscribe-button]",
  subscriptions: "[data-subscriptions]"
};
EditAgentSettingsView.prototype.events = {
  'click #shared-checkbox': '_handleSharedClick'
};

export default EditAgentSettingsView;
