import Backbone from 'backbone';
import jQuery from 'jquery';
import _ from 'underscore';
import Config from 'libraries/configuration';
import Source from 'models/source';
import sources from 'collections/sourcesInstance';

class SourceSearch extends Backbone.Collection {
    initialize(models, options) {
        if (_.isObject(options)) {
            this.query = options.query;
            this.include_private = options.include_private;
            this.page = options.page;
        }
    }
    url() {
        if (_.isUndefined(this.query)) { throw "query is undefined"; }
        let queryParams = { q: this.query };
        if (!_.isUndefined(this.include_private)) {
            queryParams = _.extend({}, queryParams, { include_private: true });
        }
        if (!_.isUndefined(this.page)) {
            queryParams = _.extend({}, queryParams, { page: this.page });
        }

        let baseUrl = Config.apiUrl + "sources/prefix_search";
        return baseUrl + "?" + jQuery.param(queryParams);
    }
    parse(response) {
        return response.collection;
    }
    fetch() {
        const result = super.fetch();
        result.done(() => {
            this.each((source) => { sources.add(source); });
        });
        return result;
    }
}

SourceSearch.prototype.model = Source;

export default SourceSearch;
