import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/newsroom/multiReaderResultDetailView.html';
import highlight from 'libraries/highlight';
import convertNewlinesToParagraphs from 'libraries/convertNewlinesToParagraphs';
import I18n from 'i18n';
import _ from 'underscore';
import handleTooltips from 'models/handleTooltips';
import session from 'models/sessionInstance'
import AddToReaderClient from 'components/addToReaderClient';
import SearchableDropdown from 'components/SearchableDropdown';
import * as React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper';
import SendEmailModal from '../../components/SendEmailModal';
import MultipleOnlineClipOrderModalView from 'views/agents/multipleOnlineClipOrderModalView';

class MultiReaderResultDetailView extends Marionette.Layout {
  initialize(options) {
        this.readerResults = options.readerResults;
        this.selectedReaderResult = options.selectedReaderResult;
        handleTooltips(this);
    }

    onRender() {
      if (session.hasFeature('use_new_reader_management')) {
        const props = {
          onCategorySelect: (confId, catId) => {
            new AddToReaderClient(confId, catId).fromAgentResults(
              _.map(this.readerResults, (rr) => {
                  return rr.get('agent_result').get('id');
              })
            );
          }
        };
        let component = <SearchableDropdown {...props} />
        let componentWrapper = new ReactWrapper({
          tagName: 'span',
          getComponent: () => component
        })
        this.searchableDropdown.show(componentWrapper);
      }
      if (session.shareSelectedArticlesViaEmail()) {
        this.showArticleViaEmailModal();
      }
    }
    serializeData() {
        const agentResultData = this.selectedReaderResult.agentResultData();
        const titleTranslationKey =
            "webapp.agents.results.multiple_results.selected_results";
        const subTitleTranslationKey =
            "webapp.agents.results.multiple_results.from_sources";
        const deleteTranslationKey = "webapp.reader_admin.result.remove";

        return {
            title: I18n.t(titleTranslationKey, this._resultCount()),
            subTitle: I18n.t(subTitleTranslationKey, this._uniqueSourcesCount()),
            deleteResults: I18n.t(deleteTranslationKey),
            readerResultTitle: agentResultData.title,
            readerResultText: this._formatText(agentResultData.text),
            canBeAddedToReader: session.hasFeature('use_new_reader_management'),
            canShareArticleViaEmail: session.shareSelectedArticlesViaEmail(),
            showFullTextLicensing: this._resultsLicensable(),
        };
    }
  
    _resultsLicensable() {
      return this.readerResults.every((readerResult) => {
        const agentResult = readerResult.get("agent_result");
        if (agentResult) {
          return (
            agentResult.isOrderable() &&
            agentResult.isOrderableOnlineClip() &&
            !agentResult.isOrdered()
          );
        }
        return null;
      })
    }

    showArticleViaEmailModal() {
      let component = (
        <SendEmailModal
          emailAgentResultIds={this.readerResults.map(i => i.get('agent_result').get('id'))}
          emailClipIds={[]}
        />
      );
          let componentWrapper = new ReactWrapper({
              getComponent: () => component
          })
      this.articleViaEmail.show(componentWrapper);
  }

    _uniqueSourcesCount() {
        let sourceNames = _.flatten(_.map(this.readerResults, (readerResult) => {
            return readerResult.agentResultData().primarySourceName;
        }));

        return { count: _.unique(sourceNames).length };
    }

    _resultCount() {
        return { count: this.readerResults.length };
    }

    _formatText(text) {
        return convertNewlinesToParagraphs(highlight(text));
    }
  
    showTags(view) {
      this.tags.show(view);
    }
  
    _handleOrderClick() {
      this.trigger('click:onlineOrder');
    }
  
    _handleOrderConfirmation() {
      this.trigger('modal:multiOnlineClipOrderConfirm');
    }
  
    showOnlineClipOrderModal() {
      this.multipleOnlineClipOrderModalView = new MultipleOnlineClipOrderModalView();
      const self = this;
      this.listenTo(
          this.multipleOnlineClipOrderModalView,
          'modal:confirmed',
          function () { self._handleOrderConfirmation() }
      );
      this.orderModal.show(this.multipleOnlineClipOrderModalView);
      this.multipleOnlineClipOrderModalView.showModal();
    }
}

MultiReaderResultDetailView.prototype.template = template;
MultiReaderResultDetailView.prototype.regions = {
  searchableDropdown: '[data-add-to-reader]',
  articleViaEmail: '[data-article-email]',
  tags: '[data-tags-partial]',
  orderButton: '[data-clip-order]',
  orderModal: '[data-order-modal]',
};
MultiReaderResultDetailView.prototype.triggers = {
    'click [data-reader-results-delete]': 'click:readerResultsDelete',
    'click [data-reader-results-and-tags-delete]': 'click:readerResultsAndTagsDelete',
};
MultiReaderResultDetailView.prototype.events = {
  'click [data-clip-order]': '_handleOrderClick',
};

export default MultiReaderResultDetailView;
