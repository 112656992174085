import Marionette from 'backbone.marionette'
import _ from 'underscore'
import template from 'text-loader!templates/settings/notifications/notificationsList.html'
import session from 'models/sessionInstance'

var  NotificationsListView = Marionette.ItemView.extend({
  template: template,
  ui: {
    summary: '[data-target=summary]',
    realtime: '[data-target=realtime]',
    emailFormatDesktop: '[data-email-format=desktop]',
    emailFormatMobile: '[data-email-format=mobile]'
  },
  triggers: {
    'click @ui.summary': 'summary',
    'click @ui.realtime': 'realtime',
    'click @ui.emailFormatDesktop': 'email_format:desktop',
    'click @ui.emailFormatMobile': 'email_format:mobile'
  },
  serializeData: function () {
    return { realtimeNotificationsAvailable: session.hasFeature("realtime_notifications") };
  },
  activateSummary: function () {
    this._activate(this.ui.summary);
  },
  activateRealtime: function () {
    this._activate(this.ui.realtime);
  },
  deactivateSummary: function () {
    this._deactivate(this.ui.summary);
  },
  deactivateRealtime: function () {
    this._deactivate(this.ui.realtime);
  },
  activateDesktopFormat: function () {
    this._activate(this.ui.emailFormatDesktop);
  },
  deactivateDesktopFormat: function () {
    this._deactivate(this.ui.emailFormatDesktop);
  },
  activateMobileFormat: function () {
    this._activate(this.ui.emailFormatMobile);
  },
  deactivateMobileFormat: function () {
    this._deactivate(this.ui.emailFormatMobile);
  },
  _activate: function (el) {
    if (!_.isString(el)) {
      el.addClass('is-active');
    }
  },
  _deactivate: function (el) {
    if (!_.isString(el)) {
      el.removeClass('is-active');
    }
  }
});

export default NotificationsListView;

