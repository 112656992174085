import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/timeline/timelineDay.html'
import 'jquery.screwdefaultbuttonsV2'

var TimelineDayView = Marionette.Layout.extend({
  template: template,
  regions: {
    resultList: '[data-container=agent-result-list]'
  },
  ui: {
    checkbox: '[data-timeline-day-checkbox]'
  },
  triggers: {
    'click [data-day]': 'day:clicked'
  },
  events: {
    'change [data-timeline-day-checkbox]': '_onCheckboxChange'
  },
  serializeData: function () {
    var date = this.model.get('date');
    return {
      date: date,
      showCheckboxes: this.options.customOptions.showCheckboxes
    };
  },
  onRender: function () {
    if (this.model.isCurrent()) {
      this.trigger('currentDay:render');
    }
    this._renderCheckBoxes();
  },
  _renderCheckBoxes: function () {
    if (this.options.customOptions.showCheckboxes) {
      this._configureCheckbox();
      this.ui.checkbox.parent().on('click', function (event) { event.stopPropagation(); });
      // this.ui.checkbox.parent() is a div introduced by screwDefaultButtons
    }
  },
  setCheckboxState: function (state) {
    if (!this.options.customOptions.showCheckboxes) { return; }
    var checkbox = this.ui.checkbox;
    var checked = !!state.checked;
    var disabled = !!state.disabled;
    if (checkbox.prop('checked') === checked && checkbox.prop('disabled') === disabled) { return; }
    checkbox.prop('checked', checked);
    checkbox.prop('disabled', disabled);
    checkbox.screwDefaultButtons(checked ? 'check' : 'uncheck');
    checkbox.screwDefaultButtons(disabled ? 'disable' : 'enable');
  },
  hideDay: function () {
    this.$el.addClass('hidden');
  },
  showDay: function () {
    this.$el.removeClass('hidden');
  },
  _configureCheckbox: function () {
    this.ui.checkbox.screwDefaultButtons({
      image: 'url("images/icons/altered-checkbox.png")',
      width: 16,
      height: 16
    });
  },
  _onCheckboxChange: function () {
    this.trigger('checkbox:changed', this.ui.checkbox.prop('checked'));
  },
  getDistanceFromTopAndBottom: function () {
    var docViewTop = this.$(window).scrollTop();
    var docViewBottom = docViewTop + this.$(window).height();
    var elementTop = this.$el.offset().top;
    var elementBottom = elementTop + this.$el.height();
    var data = {
      top: elementTop - docViewTop,
      bottom: docViewBottom - elementBottom
    };
    return data;
  }
});

export default TimelineDayView;

