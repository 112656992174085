import _ from 'underscore';
import AgentResults from 'collections/agentResults';
import AgentResultQueryBuilder from 'models/agentResultsQueryBuilder';

class AgentResultApiCollection extends AgentResults {
    initialize(models, options) {
        this.queryBuilder = new AgentResultQueryBuilder(options);
    }
    url() {
        return this.queryBuilder.toUrl();
    }
    prependResults(opts) {
        let firstResult = this.first();
        if (firstResult) {
            this.queryBuilder.set("sinceId", firstResult.get("id"));
        }
        opts = _.extend({remove: false, at: 0}, opts);
        return this._loadResults(opts);
    }
    appendResults(opts) {
        let lastResult = this.last();

        if (lastResult) {
            this.queryBuilder.set("beforeId", lastResult.get("id"));
        }

        opts = _.extend({remove: false}, opts);
        return this._loadResults(opts);
    }
    updateResults(opts) {
        opts = _.extend({remove: false}, opts);
        return this._loadResults(opts);
    }
    parse(response) {
        this.moreObjectsAvailable = response.more_objects_available;
        return response.collection;
    }
    allItemsLoaded() {
        return !this.moreObjectsAvailable;
    }
    _loadResults(opts) {
        let deferred = this.fetch(opts);
        let self = this;
        deferred.done(function () { self.trigger('results:loaded'); });
        return deferred;
    }
}

export default AgentResultApiCollection;
