import _ from 'underscore'
import viewTemplate from 'text-loader!templates/reporting/wizard/topicSelection.html'
import optionsTemplate from 'text-loader!templates/reporting/wizard/topicSelectionOptions.html'
import StepView from 'views/reporting/wizard/stepView'
import Marionette  from 'backbone.marionette'
import I18n from 'i18n'
import select2CloseOnBlur from 'libraries/select2CloseOnBlur'
import 'jquery.select2'

var View = StepView.extend({
  template: viewTemplate,
  className: "wizard-step rounded clearfix",
  triggers: {
    'change select': 'change:select'
  },
  ui: {
    'select': 'select.topic-selection'
  },
  initialize: function () {
    _.bindAll(this, 'updateOptions');
    this.on('change:select', () => {
      var selection = this.ui.select.val();
      this.model.set({selection: selection});
    });
    this.listenTo(this.model, 'change:options', this.updateOptions);

    StepView.prototype.initialize.call(this);
  },
  disableTopicSelection() {
    this.ui.select.select2("disable").trigger('change');
  },
  serializeData: function () {
    return {
      optGroups: this.model.get('options'),
      selectState: this.options.disabled ? "disable" : "enable",
      label: this.model.label()
    };
  },
  onRender: function () {
    var select = this.ui.select;

    select.val(this.model.selection()).select2({
      containerCssClass: "custom-select2",
      formatNoMatches: function (term) {
        if (!_.isEmpty(term)) {
          return I18n.t('webapp.reports.topic.no_matches');
        } else {
          return I18n.t('webapp.reports.topic.limit_exceeded');
        }
      }
    });
    select2CloseOnBlur(select);

    this.updateOptions();
  },
  updateOptions: function () {
    var serializedData = this.serializeData();
    var html = Marionette.Renderer.render(optionsTemplate, serializedData);

    this.ui.select.html(html);
    this.ui.select.select2(serializedData.selectState).trigger('change');
  }
});

export default View;

