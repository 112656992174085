import * as React from "react";

interface Props {
  children: any;
  className?: string;
}

export default function StaticWidget({
  className,
  children,
  ...itemStyle
}: Props) {
  return (
    <div
      className={`dashboard-widget__container dashboard-widget__static`}
      {...itemStyle}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
}
