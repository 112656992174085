import Marionette from 'backbone.marionette'
import WeekdaySelectorView from 'views/settings/weekdaySelectorView'

var WeekdaySelectorController = Marionette.Controller.extend({
  initialize: function (values) {
    this.view = new WeekdaySelectorView();
    this.setValues(values);
    this.listenTo(this.view, 'toggle', this._toggleValue);
    this.listenTo(this.view, 'render', this._updateView);
  },
  setValues: function (values) {
    this._values = this._copyArray(values);
    this._updateView();
    this._notify();
  },
  getValues: function () {
    return this._copyArray(this._values);
  },
  _copyArray: function (array) {
    return array.slice(0, array.length);
  },
  _updateView: function () {
    this.view.update(this.getValues());
  },
  _notify: function () {
    this.trigger('change', this.getValues());
  },
  _toggleValue: function (index) {
    var values = this.getValues();
    values[index] = !values[index];
    this.setValues(values);
  }
});

export default WeekdaySelectorController;

