import Marionette from 'backbone.marionette'
import ResearchResultListItemView from 'views/research/researchResultListItemView'
import _ from 'underscore'
import LivePreviewListTemplate from 'text-loader!templates/agents/settings/livePreviewResultList.html'
import I18n from 'i18n'

var LivePreviewListView = Marionette.CompositeView.extend({
  template: LivePreviewListTemplate,
  itemView: ResearchResultListItemView,
  ui: {
    preloader: '[data-preloader]'
  },
  appendHtml: function (collectionView, itemView) {
    collectionView.$('[data-agent-result-list]').append(itemView.el);
  },
  initialize: function (options) {
    this.collection = options.results;
    this._resultsLoaded = false;
    this.bindUIElements();
  },
  fetchFinished: function () {
    this._resultsLoaded = true;
    this.render();
    this.ui.preloader.hide();
  },
  serializeData: function () {
    var data = {
      resultsLoaded: this._resultsLoaded
    };

    if (!_.isUndefined(this.collection.totalEntries)) {
      var count = this.collection.totalEntries;
      var info = {
        count: count,
        numDays: 30
      };
      var label =
            I18n.t('webapp.agents.edit.topic.results_in_the_last_days',
                   info);
      if (count > 1) {
        label = label.replace(count, I18n.toNumber(count, {precision: 0}));
      }
      return _.extend(data, {
        totalResultsLabel: label
      });
    }
  },
  showNoResultsMessage: function () {
    // Needed by base class
  },
  showPreloader: function () {
    this.ui.preloader.show();
  },
  allItemsLoaded: function () {}
});

export default LivePreviewListView;

