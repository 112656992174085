import * as React from "react";

interface Props {
  onClick: () => void
  children: any
  className?: string
}

class Button extends React.Component<Props> {
  render() {
    const additional = this.props.className ? this.props.className : ""
    const classes = "button button--block align-left " + this.props.className
    return (
      <button className={classes}
              type='button'
              onClick={this.props.onClick}>{this.props.children}</button>
    )
  }
}

export default Button
