import * as React from "react";
import Notificator from "../../libraries/notificator";
import I18n from "i18n";
import { BASE_PATH } from "../dashboard/DashboardAppConstants";
import Loading from "../dashboard/components/Loading";
import GlobalClipEditView from "./GlobalClipEditView";
import { Selections } from "./ClipEditFooterView";

interface Props {
  id: number;
  closeCallback(): void;
  onEditedResponse(res: any): void;
}

export interface Clip {
  clip: {
    id: number;
    title: string;
    full_text: string;
    meta: {
      rich_text: string | null;
    };
  };
  entities: Entity[];
  topics: Topic[];
}

export interface Entity {
  id: number;
  meta: {
    entity_type: string;
    name_en: string;
    name_fr: string;
    name_de: string;
    name_it: string;
  };
}
export interface Topic {
  id: number;
  meta: {
    name: string;
    identifier: string;
  };
}

export interface State {
  data: {
    object: Clip;
  };
  editedObject?: Clip;
  isDataReqOngoing: boolean;
  isSaveReqOngoing: boolean;
  isPlainText: boolean;
}

export function convertToRichText(plainText: string) {
  const doubleNewLines = /\n{2,}|\r{2,}|(?:\r\n){2,}/;
  const paragraphs = plainText.split(doubleNewLines);
  return paragraphs.join("</p><p>");
}

export default class GlobalClipView extends React.PureComponent<Props, State> {
  state: State = {
    data: {
      object: {
        clip: {
          id: this.props.id,
          title: "",
          full_text: "",
          meta: {
            rich_text: null
          }
        },
        entities: [] as Entity[],
        topics: [] as Topic[]
      }
    },
    isDataReqOngoing: true,
    isSaveReqOngoing: false,
    isPlainText: true
  };

  componentDidMount() {
    fetch(`${BASE_PATH}/clips/${this.props.id}.json?schema=2`, {
      credentials: "same-origin"
    })
      .then(res => {
        if (res.status < 300) {
          return res;
        } else {
          throw new Error("Request not successfull");
        }
      })
      .then(res => res.json())
      .then(
        ({
          object: {
            clip: {
              id,
              title,
              full_text,
              meta: { rich_text }
            },
            entities,
            topics
          }
        }: {
          object: Clip;
        }) => {
          const clip = {
            clip: {
              id,
              title,
              full_text,
              meta: { rich_text: rich_text? rich_text: convertToRichText(full_text) } ,
            },
            entities,
            topics
          };
          this.setState({
            data: {
              object: clip
            },
            editedObject: clip,
            isDataReqOngoing: false
          });
        }
      )
      .catch(function() {
        Notificator.showNotification(
          I18n.t("webapp.agents.results.result_details.edit_clip.request_error")
        );
      });
  }

  onSave = () => {
    fetch(`${BASE_PATH}/clips/${this.props.id}.json?schema=2`, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ object: this.state.editedObject })
    })
      .then(res => {
        if (res.status > 300) throw new Error("Request not successfull");
        return res;
      })
      .then(res => res.json())
      .then(json => this.props.onEditedResponse(json.object))
      .then(() =>
        Notificator.showNotification(
          I18n.t("webapp.agents.results.result_details.edit_clip.changes_pending")
        )
      )
      .then(this.props.closeCallback)
      .catch(function(e) {
        Notificator.showNotification(
          I18n.t("webapp.agents.results.result_details.edit_clip.request_error")
        );
      });
  };

  convertToPlainText(richText: string) {
    const newlineCandidate = /^\/(?:p|li|h\d|normal)|^br$/i;
    return richText.replace(/<(\/?\w+).*?>/g, (tag, name) => {
      return name.match(newlineCandidate) ? "\n\n" : "";
    });
  }

  onTitleEdit = (title: string) =>
    this.setState(function(prevState) {
      const newState = { ...prevState };
      if (newState.editedObject) {
        newState.editedObject.clip.title = title;
      }
      return newState;
    });

  onBodyEdit = (text: string) =>
    this.setState(prevState => {
      const newState = { ...prevState };
      if (newState.editedObject) {
        newState.editedObject.clip.full_text = this.convertToPlainText(text);
        newState.editedObject.clip.meta.rich_text = text;
      }
      return newState;
    });

  onFooterEdit = (selections: Selections) =>
    this.setState(prevState => {
      const newState = { ...prevState };
      if (newState.editedObject) {
        const topics = selections.topics.map(id => ({ id }));
        newState.editedObject.topics = topics as Topic[];
        const entities = selections.entities.map(id => ({ id }));
        newState.editedObject.entities = entities as Entity[];
      }
      return newState;
    });

  render() {
    return (
      <div className="lightened-container lifted-container pts phl pbm">
        {this.state.isDataReqOngoing || this.state.isSaveReqOngoing ? (
          <Loading cssClass="layout-fullHeight" />
        ) : (
          <GlobalClipEditView
            clipViewState={this.state}
            onTitleEdit={this.onTitleEdit}
            onBodyEdit={this.onBodyEdit}
            onFooterEdit={this.onFooterEdit}
            onSave={this.onSave}
            onCancel={this.props.closeCallback}
          />
        )}
      </div>
    );
  }
}
