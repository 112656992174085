import * as React from 'react';
import I18n from 'i18n';
import * as _ from 'underscore';
import { IEsApiTopic, IEsApiEntity } from 'models/esResult';
import { buildEntityFilters, buildTopicFilters } from '../controllers/research/newResearchController/buildFilters';
import vent from 'libraries/vent';
import * as entityCache from "../services/entityCache";
import * as topicCache from "../services/topicCache";
import Entity from '../models/entity';
import session from "models/sessionInstance";
import TopicModel from 'models/topic';


interface MetadataBoxProps {
  header: string
  colspec: 'col1of2' | 'col1of1'
  children: any
}

export interface EntityItem {
  entity_type: string,
  id: number,
  meta: {
    kb_id: string,
  },
  name_de: string,
  name_en: string,
  name_fr: string,
  name_it: string,
}

export interface EntityMeta {
  id: number,
  meta: {
    kb_id: string,
    entity_type: string,
    name_de: string,
    name_en: string,
    name_fr: string,
    name_it: string,
  }
}

export interface Topic {
  id: number,
  identifier: string,
  parent: boolean,
  meta: {
    identifier: string,
  },
  name_de: string,
  name_en: string,
  name_fr: string,
  name_it: string,
}

const MetadataBox = (props: MetadataBoxProps) => {
  return (
    <div className={props.colspec}>
      <h2 className="meta__title">
        {props.header}
      </h2>
      <dl className="meta__body" >
        {props.children}
      </dl>
    </div>
  )
}
const MetadataBox1of2 = (props: { header: string, children: any }) =>
  MetadataBox({header: props.header, colspec: 'col1of2', children: props.children})
const MetadataBox1of1 = (props: { header: string, children: any }) =>
  MetadataBox({ header: props.header, colspec: 'col1of1', children: props.children })

const entityLabel = (entity: any) => {
  const locales = ["en", "fr", "it", "de"];
  let lang;
  if (_.contains(locales, I18n.locale.split('-')[0])) {
    lang = I18n.locale.split('-')[0];
  } else {
    lang = "de";
  }
  if (entity.entity_type) {
    return entity["name_" + lang];
  } else if (entity.meta && entity.meta.entity_type) {
    return entity.meta["name_" + lang];
  } else {
    return entity.meta.name
  }
};

const topicLabel = (t: Topic) => {
  const topic = new TopicModel(t)
  let label = topic.parseName()
  if (label) {
    return label
  } 
  label = new Entity({ id: t.id, type: 'topic', parent: t.parent, meta: { identifier: t.identifier ?  t.identifier :  t.meta.identifier } }).parseName()
  return label
}

function mapEntityTypes (entity: EntityItem) {
  if (entity.entity_type && entity.entity_type !== "topic") {
    entity.entity_type = entity.entity_type.concat("_entity");
  }
  return entity;
}

const StringList = (props: {
  elements: (any)[];
  separator: string;
}): JSX.Element => {
  if (!_.isEmpty(props.elements)) {
    let sorted = props.elements.sort(
      (a: EntityItem | Topic, b: EntityItem | Topic) => {
        const alc = entityLabel(a).toLowerCase();
        const blc = entityLabel(b).toLowerCase();
        return alc > blc ? 1 : alc < blc ? -1 : 0;
      }
    );

    let sortedEntity = sorted.map(({ id, meta: { entity_type, identifier, name, name_de, name_en, name_fr, name_it, parent_id } }) => {
      if (entity_type) {
        return { id, entity_type, name_de, name_en, name_fr, name_it }
      } else {
        return { id, name_de, name_en, name_fr, name_it, identifier, parent: parent_id, type: "topic" }
      }
    });

    const lst = sortedEntity.map((entity: any, index: number) => {
      if (
        session.hasFeature('access_to_new_research') &&
        session.hasFeature(
          'start_entity_topic_search_from_article_detail_view'
        )
      ) {
        const separator = (index !== sortedEntity.length - 1) ? `${props.separator} ` : '';
        if (entity.entity_type) {
          entity = mapEntityTypes(entity);
          return [
            <a
              key={entity.id}
              onClick={ev => {
                entityCache.set([new Entity(entity)]),
                  vent.commands.execute(
                    'new-research:query',
                    buildEntityFilters(entity)
                  );
              }} dangerouslySetInnerHTML={{ __html: `${entityLabel(entity)}${separator}` }}>
            </a>,
            " "
          ];
        } else {
          return [
            <a
              key={entity.id}
              onClick={ev => {
                topicCache.set([new TopicModel(entity)]);
                vent.commands.execute(
                  'new-research:query',
                  buildTopicFilters(entity)
                );
              }} dangerouslySetInnerHTML={{ __html: `${topicLabel(entity)}${separator}` }}>
            </a>,
            " "
          ];
        }
      } else if (entity.entity_type) {
        return [
          <span key={entity.id}>{entityLabel(entity)}</span>,
          <span key={entity.id}>{props.separator}</span>
        ];
      } else {
        return [
          <span key={entity.id}>{topicLabel(entity)}</span>,
          <span key={entity.id}>{props.separator}</span>
        ];
      }
    });

    const array = _.flatten(lst);
    array.pop();
    const el: JSX.Element = <span>{array}</span>;
    return el;
  } else {
    return <span>-</span>;
  }
};


interface Props {
  topics: IEsApiTopic[];
  entities: IEsApiEntity[];
}
const ClipTopicEntityMetadata = (props: Props) => {
  const tr = (name: string) => {
    const prefix = 'webapp.components.clip_topic_entity_metadata.'
    return I18n.t(prefix + name)
  }
  const entitiesOfType = (type: string) => {
    const elements: IEsApiEntity[] =
      _.filter(props.entities, (e: IEsApiEntity) => {
        return e.meta.entity_type == type
      });
    return elements;
  }

  const entityList      = (type: string, sep?: string) => {
    let separator = sep || ', '
    return <StringList elements={entitiesOfType(type)} separator={separator} />
  }

  const topicList       =
    <StringList elements={props.topics} separator=", " />
  const actors =
    entitiesOfType('person').concat(entitiesOfType('organization'));
  const actorList       =
    <StringList elements={actors} separator="; " />

  return (
    <div className="meta">
      <div className="row">
        <MetadataBox1of2 header={tr('topics')}>
          {topicList}
        </MetadataBox1of2>
        <MetadataBox1of2 header={tr('places')}>
          {entityList('location')}
        </MetadataBox1of2>
      </div>
      <div className="row mtm">
        <MetadataBox1of2 header={tr('businesses')} >
          {entityList('business')}
        </MetadataBox1of2>
        <MetadataBox1of2 header={tr('laws')}>
          {entityList('law')}
        </MetadataBox1of2>
      </div>
      <div className="row mtm">
        <MetadataBox1of1 header={tr('actors')}>
          {actorList}
        </MetadataBox1of1>
      </div>
    </div>
  )
}

export default ClipTopicEntityMetadata;
