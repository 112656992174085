import _ from 'underscore'
import Report from 'models/report'
import ReportWizard from 'models/reporting/reportWizard'
import TopicSelection from 'models/reporting/wizard/topicSelection'
import TimespanSelection from 'models/reporting/wizard/timespanSelection'
import EditTitleSummary from 'models/reporting/wizard/editTitleSummary'
import NewsletterRecipientSelection from 'models/reporting/wizard/newsletterRecipientSelection'
import FilterSortSettings from 'models/reporting/wizard/filterSortSettings'
import session from 'models/sessionInstance'
import DateFormatter from '../../libraries/dateFormatter'
import I18n from 'i18n'

var NewsletterWizard = ReportWizard.extend({
  getReport: function () {
    return new Report({
      title: I18n.t('webapp.reporting.newsletter.headline_with_date', {date: DateFormatter.formatDate(new Date())}),
      type: 'Newsletter'
    });
  },

  initializeStepModels: function () {
    return _.filter([
      new TopicSelection({report: this.report}),
      new TimespanSelection({report: this.report}),
      new EditTitleSummary({report: this.report}),
      new NewsletterRecipientSelection({report: this.report}),
      new FilterSortSettings({report: this.report})
    ], function (step) {
      var features = session.get('features');
      if ((step instanceof FilterSortSettings) && !features.reports_grouping_sorting) {
        return false;
      } else {
        return true;
      }
    });
  }
});

export default NewsletterWizard;

