import {ContactListView} from 'views/settings/contacts/contactListView'
import Marionette from 'backbone.marionette'
import handleTooltips from 'models/handleTooltips';
import template from 'text-loader!templates/settings/contacts/contactsOverviewView.html'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

class ContactsOverviewView extends Marionette.Layout {
    initialize() {
        handleTooltips(this);
    }

    showContactList(props) {
        const contactsView = <ContactListView {...props}/>
        ReactDOM.render(contactsView, this.ui.contactList[0]);
        this.trigger('registerTooltips')
    }
}
ContactsOverviewView.prototype.template = template
ContactsOverviewView.prototype.regions = {
  contactGroupList: '[data-contact-group-list]'
}
ContactsOverviewView.prototype.triggers = {
  'click @ui.addContactGroup': 'add-contact-group:clicked'
}
ContactsOverviewView.prototype.ui = {
  addContactGroup: '[data-add-group]',
  contactList: '[data-contact-list]'
}
export default ContactsOverviewView
