import * as React from 'react';
import I18n from 'i18n';

export default function SetUpLicensingPlaceholder() {
  return (
    <div className="pal">
      <div className="notice">
        <p>
          <i className="icon-cog notice__marker"></i>{' '}
          {I18n.t('webapp.settings.licensing.placeholder')}
        </p>
      </div>
    </div>
  );
}
