import Widget from 'models/dashboard/widget'

Widget.MultiTagChartWidget = Widget.extend({
  clipCount: function (agentIndex) {
    var data = this.get('chart_data')[agentIndex];
    return this._countClipsInChartData(data);
  },
  tagIds: function () {
    return this.get('tag_ids');
  }
});

export default Widget.MultiTagChartWidget;
