import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/newsroom/readerResultActions.html'

var ReaderResultActionsView = Marionette.Layout.extend({
  template: template,
  regions: {
    mark_important: '[data-mark-important-partial]',
    tags:    '[data-tags-partial]',
  },
  showMarkImportant: function (view) {
    this.mark_important.show(view);
  },
  showTags: function (view) {
    this.tags.show(view);
  }
});

export default ReaderResultActionsView;

