import Backbone from 'backbone';
import moment from 'moment';
import DateFormatter from 'libraries/dateFormatter';

class ArchiveDate extends Backbone.Model {
    parse(response) {
        if (response.object) {
            return response.object;
        } else {
            return response;
        }
    }

    // TODO: TimelineController calls start from here, should be extracted to
    //       a facade. Model is not for timeline representation.
    getBeginningTimestamp() {
        return this.get('starts');
    }

    isCurrent() {
        var today = new Date();
        var date  = this._startsToDate();
        return today.getFullYear() === date.getFullYear() &&
               today.getMonth() === date.getMonth() &&
               today.getDate() === date.getDate();
    }

    isSame(day) {
        var date  = moment(this._startsToDate());
        return date.isSame(day.get('date'), 'day');
    }

    isAfter(day) {
        var date  = moment(this._startsToDate());
        return date.isAfter(day.get('date'), 'day');
    }

    isBefore(day) {
        var date  = moment(this._startsToDate());
        return date.isBefore(day.get('date'), 'day');
    }

    _startsToDate() {
        return new Date(this.get('starts') * 1000);
    }

    formattedDate() {
        let isLatestIssue =
            this.get('id') === this.collection.latestArchiveDateId();
        let date = this.get('delivery_time') * 1000;

        return DateFormatter.formatRelativeArchiveDate(date, isLatestIssue);
    }

    isUpcomingIssue() {
        let date = moment(this.get('delivery_time') * 1000);
        var now = moment();
        return date.isAfter(now);
    }
}

export default ArchiveDate;
