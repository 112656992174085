import Backbone from 'backbone';
import ReaderResult from 'models/newsroom/readerResult';

class ReaderResults extends Backbone.Collection {
    comparator(model) {
        return -model.get('position');
    }
}

ReaderResults.prototype.model = ReaderResult;

export default ReaderResults;
