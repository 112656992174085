import Widget from 'models/dashboard/widget'
import _ from 'underscore'

Widget.KeySourcesWidget = Widget.extend({
  setArticleVolumeClassPerSource: function () {
    var widget = this;

    _.each(widget.get("sources"), function (source) {
      source.articleVolumeClass = widget.getArticleVolumeClass(source.articles);
    });
  },
  getArticleVolumeClass: function (articles) {
    if (_.isUndefined(articles)) {
      return 'none';
    } else {
      return articles > 0 ? 'some' : 'none';
    }
  }
});

export default Widget.KeySourcesWidget;
