import Report from 'models/report'
import ReportWizard from 'models/reporting/reportWizard'
import TopicSelection from 'models/reporting/wizard/topicSelection'
import TimespanSelection from 'models/reporting/wizard/timespanSelection'
import EditTitleSummary from 'models/reporting/wizard/editTitleSummary'
import ExcelContentSettings from 'models/reporting/wizard/excelContentSettings'
import DateFormatter from '../../libraries/dateFormatter'
import I18n from 'i18n'

var ExcelWizard = ReportWizard.extend({
  getReport: function () {
    return new Report({
      title: I18n.t('webapp.reporting.excel.headline_with_date', {date: DateFormatter.formatDate(new Date())}),
      group_by: 'none',
      type: 'ExcelReport',
      has_chart: false,
      include_clips_from_several_sources: false,
      has_clip_snippets: false,
      has_print_clips_in_full_length: false,
      only_datasheet: false
    });
  },

  initializeStepModels: function () {
    return [
      new TopicSelection({report: this.report}),
      new TimespanSelection({report: this.report}),
      new EditTitleSummary({report: this.report}),
      new ExcelContentSettings({report: this.report})
    ];
  }
});

export default ExcelWizard;

