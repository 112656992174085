import _ from 'underscore'
import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/basicDeleteModal.html'
import 'bpopup'

const BasicDeleteModalView = Marionette.ItemView.extend({
  initialize: function (opt) {
    this.title = opt.title;
    this.headline = opt.headline;
    this.confirmation = opt.confirmation;
    this.warning = opt.warning;
    this.deleteLabel = opt.deleteLabel;
    this.cancelLabel = opt.cancelLabel;
    this.disabled = opt.disabled;
  },
  template: template,
  onRender: function () {
    if (!this.disabled) {
      this.initializeModal();
    }
  },
  serializeData: function () {
    return {
      title: this.title,
      headline: this.headline,
      confirmation: this.confirmation,
      warning: this.warning,
      deleteLabel: this.deleteLabel,
      showHeader:  !_.isEmpty(this.headline),
      cancelLabel: this.cancelLabel
    };
  },
  ui: {
    modalElement: '[data-delete-modal]',
    modalDeleteButton: '[data-delete-delete]'
  },

  modalConfirm(self) {
    self.modal.close();
    self.trigger('modal:delete');
  },

  initializeModal: function () {
    var self = this;
    this.ui.modalDeleteButton.on('click', function () {
      self.modalConfirm(self);
    });
    this.ui.modalDeleteButton.on('keydown', function (e) {
      if (e.key === "Enter") {
        self.modalConfirm(self);
      }
    });
  },
  onClose: function () {
    this.ui.modalElement.remove();
  },
  showModal: function () {
    this.modal = this.ui.modalElement.bPopup();
    this.ui.modalDeleteButton.focus();
  }
});

export default BasicDeleteModalView;

