import Backbone from 'backbone'
import _ from 'underscore'

/*
 * BaseObject provides Backbone style extend for plain Javascript
 * objects. Define an initialize method as a constructor.
 */
function BaseObject(...args) {
  this.initialize(...args);
}

_.extend(BaseObject.prototype, {
  initialize: function () {}
});

BaseObject.extend = Backbone.Model.extend;

export default BaseObject;

