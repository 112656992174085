/* jshint module: true */
import Backbone from 'backbone';
import pusherWrapper from 'models/pusherWrapper';
import _ from 'underscore';

const concurrentSelectionManager = _.extend({}, Backbone.Events, {
    init() {
        const Model = Backbone.Model;
        this.concurrentSelections = new Backbone.Collection({ model: Model });
        this._registerListeners();
    },
    getSelectionForId(id) {
        return this.concurrentSelections.findWhere({ agentResultId: id });
    },
    _registerListeners() {
        this.listenTo(
          pusherWrapper,
          'client-agent-result-selection',
          this._selectionChange
        );
        this.listenTo(pusherWrapper,
            'pusher:member_removed', this._removeUser);
        this.listenTo(this.concurrentSelections, 'add remove', () => {
            this.trigger('change');
        });
    },
    _selectionChange(data) {
        const {
            user_id: userId,
            added: addedIds,
            removed: removedIds,
            session_id: sessionId
        } = data;
        if (addedIds) {
          this._addSelections(sessionId, userId, addedIds);
        }
        if (removedIds) {
          this._removeSelections(sessionId, removedIds);
        }
    },
    _buildId(sessionId, agentResultId) {
        return `${sessionId},${agentResultId}`;
    },
    _addSelections(sessionId, userId, ids) {
        if (!ids) { return; }
        ids.forEach((id) => {
            const modelId = this._buildId(sessionId, id);
            const waitingTime = 600000;
            const timeout = window.setTimeout(() => {
                this.concurrentSelections.remove(modelId);
            }, waitingTime);
            this.concurrentSelections.add({
                sessionId,
                userId,
                timeout,
                agentResultId: id,
                id: modelId
            });
        });
    },
    _removeSelections(sessionId, ids) {
        ids.forEach((id) => {
            const modelId = this._buildId(sessionId, id);
            const selection = this.concurrentSelections.get(modelId);
            if (selection) {
                window.clearTimeout(selection.get('timeout'));
                this.concurrentSelections.remove(modelId);
            }
        });
    },
    _removeUser(data) {
        const sessionId = data.id;
        this.concurrentSelections.where({ sessionId }).forEach((selection) => {
            this.concurrentSelections.remove(selection);
        });
    }
});

export default concurrentSelectionManager;
