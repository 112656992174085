import SceneController from 'controllers/sceneController';
import NewsroomSummaryTimelineController
    from 'controllers/newsroom/newsroomSummaryTimelineController';
import ReaderPlaceholderView
    from 'views/newsroom/readerPlaceholderView';
import EditNewsroomSummaryController
    from 'controllers/newsroom/editNewsroomSummaryController';
import vent from 'libraries/vent';

export default class SummarySceneController extends SceneController {
    show() {
        this.selectedReaderConfiguration = this.options;

        this.timelineController = new NewsroomSummaryTimelineController({
            selectedReaderConfigurationId: this.selectedReaderConfiguration.get('id'),
        });

        this.showInLeftColumn(this.timelineController.view);
        this._showPlaceHolder();

        this.listenTo(this.timelineController.view,
                      'itemview:addSummary',
                      this._showSummaryEditForm);

        this.listenTo(this.timelineController.view,
                      'itemview:editSummary',
                      this._showSummaryEditForm);
    }

    _showSummaryEditForm(itemView) {
        let editSummaryController = new EditNewsroomSummaryController({
            model: itemView.summaryModel,
        });

        this.showInRightColumn(editSummaryController.view);
        vent.trigger('layout:focus-right');

        this.listenTo(editSummaryController, 'close:summaryEditForm', () => {
            this.timelineController.clearHighlightFromAllArchiveDates();
            editSummaryController.close();
            this._showPlaceHolder();
        });
    }

    _showPlaceHolder() {
        const placeholder = new ReaderPlaceholderView();
        this.showInRightColumn(placeholder);
        vent.trigger('layout:focus-left');
    }
}
