import $ from 'jquery';
import _ from 'underscore';

class ScrollingHandler {
    constructor(timelineElement) {
        this._$timelineElement = $(timelineElement);
        this._storedPositionDepth = 0;
    }

    storePosition(element) {
        this._storedPositionDepth++;
        if (this._storedPositionDepth === 1) {
            this._storedPosition = this._getPosition(element);
            this._previousHeight = this._getHeight();
            this._previousTimelineScroll = this._getTimelineScroll();
        }
    }

    restorePosition(element) {
        this._storedPositionDepth--;
        if (this._storedPositionDepth === 0) {
            const position = this._storedPosition || this._getPosition(element);
            if (this._isAboveVisibleTimeline(position)) {
                const heightDiff = this._getHeight() - this._previousHeight;
                this.scrollTimelineTo(heightDiff + this._previousTimelineScroll);
            }
        }
    }

    _getPosition(element) {
        let position;
        if (element && !_.isString(element) && $(element).is(':visible')) {
            const elementPosition = $(element).offset().top;
            position = elementPosition - $(window).scrollTop();
        }

        return position;
    }

    _getTimelineScroll() {
      return this.getScrollableElement().scrollTop();
    }

    _getHeight() {
        return this._$timelineElement.height();
    }

    scrollTimeline(pixels) {
        if (pixels === 0) { return; }
        const element = this.getScrollableElement();
        const oldValue = element.scrollTop();
        element.scrollTop(oldValue + pixels);
    }

    scrollTimelineTo(pixels) {
        const element = this.getScrollableElement();
        element.scrollTop(pixels);
    }

    getScrollableElement() {
        const el = this.safeGetScrollableElement();
        if (el) {
            return el;
        } else {
            throw('No scrollable element found');
        }
    }

    safeGetScrollableElement() {
        if (this._$scrollableElement) { return this._$scrollableElement; }
        const element = _.find(this._$timelineElement.parents(), function(el) {
            return $(el).css('overflow').match(/auto|scroll/i);
        });
        if (element) {
            this._$scrollableElement = $(element);
            return this._$scrollableElement;
        }
    }

    getTopOffset() {
        const element = this.getScrollableElement();
        return element.offset().top - $(window).scrollTop();
    }

    _isAboveVisibleTimeline(topPosition) {
        return topPosition && topPosition < this.getTopOffset();
    }
}

export default ScrollingHandler;
