import session from 'models/sessionInstance';
import $ from 'jquery';
import _ from 'underscore';

class DatePicker {
    /*
     * Sets the default settings for date picker based on the locale in
     * session.
     *
     * Note: dateFormat is always dd.mm.yy
     */
    static defaultToSessionLocale() {
        let defaultSettings = $.extend(
            {},
            $.datepicker.regional[this.getLocale()],
            { dateFormat: 'dd.mm.yy', defaultDate: 0 }
        );
        $.datepicker.setDefaults(defaultSettings);
    }

    /*
     * Date picker locale, defaults to german if given locale is not
     * supported.
     *
     * @returns {String}
     */
    static getLocale() {
        let locales = this.supportedLocaleMap();
        let locale = session.get('locale');
        let foundLocale = locales[locale];
        if (_.isUndefined(foundLocale) && !_.isString(foundLocale)) {
            foundLocale = 'de';
        }
        return foundLocale;
    }

    /*
     * Locale to date picker locale mapping hash.
     *
     * @returns {Object}
     */
    static supportedLocaleMap() {
        return {
            'de': 'de',
            'en': "en-GB",
            'en-GB': 'en-GB',
            'it': 'it',
            'it-CH': 'it',
            'es': 'es',
            'fr': 'fr',
            'fr-CH': 'fr-CH',
            'nl': 'nl',
            'pl': 'pl',
        };
    }
}

export default DatePicker;
