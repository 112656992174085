import Marionette from 'backbone.marionette';
import when from 'when';
import Domain from 'models/whitelistDomain';
import DomainListCollectionView from
    'views/settings/contacts/contactGroupDomainListCollectionView';

class ContactGroupDomainListController extends Marionette.Controller {
    initialize() {
        this.contactGroup = this.options.contactGroup;
        this.collection = this.contactGroup.get('domains');
        this.view = new DomainListCollectionView({ collection: this.collection });
        this._registerEventListeners();
    }

    _registerEventListeners() {
        this.listenTo(
            this.view,
            'itemview:clicked:remove',
            (_itemView, args) => {
                let domain = args.model;
                domain.destroy();
            }
        );

        this.listenTo(
            this.view,
            'domainNameAdded',
            (name) => {
                let domain = new Domain({
                    name: name,
                    contact_group_id: this.contactGroup.get('id')
                });
                let domains = this.collection.where({ name: domain.get('name')});
                if (domains.length === 0) {
                    this.trigger('hideDomainErrors');
                    this.collection.add(domain);
                    when(domain.save()).otherwise(() => {
                        this.collection.remove(domain);
                    });
                } else {
                    this.view.setDomainName(name);
                    this.trigger('showDuplicateDomainError');
                }
            }
        );

        this.listenTo(
            this.view,
            'domainError',
            () => { this.trigger('showDomainError') }
        )

        this.listenTo(
            this.view,
            'hideAllDomainErrors',
            () => { this.trigger('hideDomainErrors') }
        )
    }
}

export default ContactGroupDomainListController;
