import * as React from 'react';

interface Props {
    clipId: number;
    agentResultId: number;
}

function getAdminUrl(url: string) {
  return url.replace("app.", "admin.");
}

export default function ({ clipId, agentResultId }: Props) {
  
  const style = {
    margin: "0"
  }
  return (
    <div>
      <p style={style}>Clip ID:&nbsp; 
            <a href={getAdminUrl(`${window.location.protocol}//${window.location.hostname}/clips/${clipId}`)} target="_blank">{clipId}</a>
        </p>
      <p style={style}
      >Agents Result ID: {agentResultId}</p>
    </div>
  );
}
