import Marionette from 'backbone.marionette';
import contactGroupsInstance from 'collections/contactGroupsInstance';
import contactGroupsInstancePromise from 'models/contactGroupsInstancePromise';
import ContactDetailView from 'views/settings/contacts/contactDetailView';
import ContactItemView from 'views/settings/contacts/contactItemView';
import Notificator from 'libraries/notificator';
import I18n from 'i18n';
import _ from 'underscore';
import DeletionModalView from 'views/deletionModalView';
import Validations from 'libraries/validations';
import Usage from 'models/usage';
import UnitOrderModalView from 'views/settings/unitOrderModalView';
import when from 'when';
import configurations from 'collections/newsroom/configurationsInstance';
import Deferred from 'libraries/deferred'

class ContactDetailController extends Marionette.Controller {
    initialize(options) {
        _.bindAll(this,
                  '_bindListeners',
                  '_addContactToGroup', '_isEmailValid', '_isFormValid',
                  '_removeContactFromGroup', '_hideEmailError',
                  '_showEmailError', '_showDeletionModal',
                  '_triggerContactTimerSave',
                  '_deleteContact', '_areValidationErrorsAllowed',
                  '_validateAndSave', 'disableInputFields', 'enableInputFields',
                  '_createDeletionModal', '_handleViewRender',
                  '_maybeEnableDeleteButton', '_handleLocaleChange');
        this.contact = options.contact;

        const readerNames =
              this._readerNamesConnectedWithContact(this.contact.get('id'));
        this.view = new ContactDetailView({
            contact: this.contact
        });
        this.itemView = new ContactItemView({
            model: this.contact,
            readerConfigurations: readerNames
        });

        this._disableValidation();
        this._bindListeners();
        this._registerLocaleChangeListener();
    }

    _readerNamesConnectedWithContact(contactId) {
        const readerNames =
              _.chain(configurations.models)
              .filter((config) => {
                  // search for contactId in this audience contacts
                  const audienceContactIds =
                        _.chain(config.get('audience'))
                        .filter((el) => el.type === "contact")
                        .pluck('id')
                        .value();

                  // search for contactId in contact groups of this audience
                  const audienceContactGroupIds =
                        _.chain(config.get('audience'))
                        .filter((el) => el.type === "contact_group")
                        .map((el) => el.id)
                        .value()

                  const audienceContactGroupsContactsIds =
                        _.chain(contactGroupsInstance.models)
                        .filter((cg) => _.contains(audienceContactGroupIds,
                                                   cg.get('id')))
                        .map((cg) => cg.get('contact_ids'))
                        .flatten()
                        .value();

                  return _.contains(audienceContactIds, contactId) ||
                      _.contains(audienceContactGroupsContactsIds, contactId)
              })
              .map(config => config.get('title'))
              .value()
        return readerNames;
    }

    _bindListeners() {
        var self = this;
        contactGroupsInstancePromise.done(function () {
            self.view.setContactGroups(contactGroupsInstance);
        });

        this.listenTo(this.itemView, 'nameEmailFocus',
                      self._maybeAllowValidationErrors);
        this.listenTo(this.itemView, 'nameEmailChanged',
                      self._handleNameEmailChange);
        this.listenTo(this.itemView, 'locale:change',
            self._handleLocaleChange);
        this.listenTo(this.itemView, 'nameEmailBlur', self._validateAndSave);
        this.listenTo(this.view, 'close', function () {
            self._validateAndSave(self.itemView.formAttributes());
            self._destroyContactIfUnsaved();
        });
        this.listenTo(this.view, 'render', this._handleViewRender);
        this.listenTo(this.view, 'addContactToGroup',
                      self._addContactToGroup);
        this.listenTo(this.view, 'removeContactFromGroup',
                      self._removeContactFromGroup);
        this.listenTo(this.view, 'showModal',
            self._showDeletionModal);
        this.listenTo(this.contact, 'change',
                      self._maybeEnableDeleteButton);
    }

    _registerLocaleChangeListener() {
        this.listenTo(this.itemView, 'locale:change', self._handleLocaleChange);
    }

    _handleViewRender() {
        this.view.showContactView(this.itemView);
        this.disableInputFields();
        this.enableInputFields();
        if (!this._isContactSaved()) {
            this.view.disableDeleteButton();
        }
    }

    _isContactSaved() {
        return !this.contact.isNew();
    }

    _maybeEnableDeleteButton() {
        if (this._isContactSaved()) {
            this.view.enableDeleteButton();
        }
    }

    disableInputFields() {
        this.itemView.disableInputFields();
    }

    enableInputFields() {
        this.itemView.enableInputFields();
    }

    _maybeAllowValidationErrors() {
        if (this.itemView.formAttributes().email.length > 0) {
            this._allowValidationErrors();
        }
    }

    _allowValidationErrors() {
        this.areValidationErrorsAllowed = true;
    }

    _disableValidation() {
        this.areValidationErrorsAllowed = false;
    }

    _addContactToGroup(contactGroup) {
        this._allowValidationErrors();
        if (this._isFormValid()) {
            let usage = new Usage();
            when.join(
                usage.load(),
                contactGroup.addContactIncurCost(this.contact)
            ).then((r) => {
                let response = r[1];
                if (response.incur_cost) {
                    this._showOrderModal(usage, contactGroup);
                } else {
                    this._performAddContactToGroup(contactGroup);
                }
            });
        }
    }

    _performAddContactToGroup(contactGroup) {
        let contactSaved = Deferred.build();
        if (this.contact.isNew()) {
            let attributes = this.itemView.formAttributes();
            contactSaved = this._performSaveContact(attributes);
        } else {
            contactSaved.resolve();
        }
        contactSaved.then(() => {
            contactGroup.addContact(this.contact).otherwise((response) => {
                this._contactGroupSaveFailed(response, contactGroup);
            });
        }).fail(() => {
            this._contactGroupSaveFailed({}, contactGroup);
        });
    }

    _showOrderModal(usage, contactGroup) {
        this.orderModalView = new UnitOrderModalView({
            unitType: 'reader_seat',
            usage: usage
        });
        this.listenTo(
            this.orderModalView,
            'modal:confirmed',
            () => this._performAddContactToGroup(contactGroup, this.contact)
        );
        this.listenTo(
            this.orderModalView,
            'modal:cancelled',
            () => { this.view.render(); }
        );
        this.view.orderModal.show(this.orderModalView);
        this.orderModalView.showModal();
    }

    _removeContactFromGroup(contactGroup) {
        this._allowValidationErrors();
        var self = this;
        if (this._isFormValid()) {
            contactGroup.removeContact(this.contact).otherwise(function (response) {
                self._contactGroupSaveFailed(response, contactGroup);
            });
        }
    }

    _contactGroupSaveFailed(response, contactGroup) {
        var error;
        if (response.responseText) {
            try {
                error = JSON.parse(response.responseText)['error'];
            } catch(e) {
              // ignore errors
            }
        }
        var message;
        if (error && error.match(/reader seat maximum reached/i)) {
            message = I18n.t('webapp.notifications.error.reader_seat_maximum_reached');
        } else {
            message = I18n.t("webapp.notifications.error.not_saved");
        }
        Notificator.showNotification(message);
        this.view.toggleContactGroup(contactGroup);
    }

    _areValidationErrorsAllowed() {
        return this.areValidationErrorsAllowed;
    }

    _handleNameEmailChange(attributes) {
        this._maybeAllowValidationErrors();
        this._showOrHideValidationErrors();
        this._triggerContactTimerSave(attributes);
    }

    _isFormValid() {
        return this._isEmailValid();
    }

    _showOrHideValidationErrors() {
        if (this._areValidationErrorsAllowed() && !this._isEmailValid()) {
            this._showEmailError();
        } else {
            this._hideEmailError();
        }
    }

    _hideEmailError() {
        this.itemView.hideEmailError();
    }

    _showEmailError() {
        var key = "webapp.settings.contacts.contacts.email_invalid";
        this.itemView.showEmailError(I18n.t(key));
    }

    _isEmailValid() {
        var email = this.itemView.formAttributes().email;
        return Validations.isEmailValid(email);
    }

    _createDeletionModal() {
        var title =
            I18n.t("webapp.settings.contacts.contacts.delete.confirmation",
                  { name: this.contact.getName() });
        var description =
            I18n.t("webapp.settings.contacts.contacts.delete.description");
        var deleteLabel =
            I18n.t("webapp.settings.contacts.contacts.delete.delete");
        var cancelLabel =
            I18n.t("webapp.settings.contacts.contacts.delete.cancel");

        var opts = {
            confirmation: title,
            warning: description,
            deleteLabel: deleteLabel,
            cancelLabel: cancelLabel,
            disabled: false
        };

        var deletionModal = new DeletionModalView(opts);

        return deletionModal;
    }

    _showDeletionModal() {
        this.deletionModal = this._createDeletionModal();
        this.listenTo(this.deletionModal, 'modal:delete',
                      this._deleteContact);
        this.view.showDeletionModal(this.deletionModal);
    }

    _triggerContactTimerSave(attributes) {
        // start save countdown or reset it
        if (!_.isUndefined(this.saveTimer)) {
            clearTimeout(this.saveTimer);
        }

        var self = this;
        this.saveTimer = setTimeout(function () {
            self._validateAndSave(attributes);
        }, 1500);
    }

    _validateAndSave(attributes) {
        this._showOrHideValidationErrors();
        if (this._isFormValid()) {
            this.contact.set(attributes);
            this.itemView.renderTitle();
            if (this.contact.hasChanged()) {
                this._performSaveContact(attributes);
            }
        }
    }

    _performSaveContact(attributes) {
        var self = this;
        this.contact.store();
        let saving = Deferred.build();
        if (!this.contact.save(attributes, {
            error: function () {
                self.contact.restore();
                var message = I18n.t("webapp.notifications.error.not_saved");
                Notificator.showNotification(message);
                saving.fail();
            },
            success: function () {
                self.trigger("contact:saved");
                saving.resolve();
            }
        })) {
            this.contact.restore();
            var message = I18n.t("webapp.notifications.error.not_saved");
            Notificator.showNotification(message);
        }
        return saving.promise();
    }

    _destroyContactIfUnsaved() {
        if (this.contact.isNew()) {
            this.contact.destroy();
        }
    }

    _deleteContact() {
        this.contact.destroy();
        this.trigger('deleted', this.contact);
    }
    _handleLocaleChange(newLocale) {
        let attribute = { reader_language: newLocale }
        if (this._isFormValid()) {
            this.contact.set(attribute);
            this._performSaveContact(attribute);
        }
    }
}

export default ContactDetailController;
