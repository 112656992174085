import $ from 'jquery'
import vent from 'libraries/vent'
import App from 'app'
import * as React from 'react';
import * as ReactDom from "react-dom"
import configureNavigationModule from 'modules/configureNavigationModule'
import setupNavigationModule from 'modules/setupNavigationModule'
import LayoutContentResizer from 'modules/layoutContentResizer'
import I18n from 'i18n'

App.module('NewAgents', {
  startWithParent: false,
  define: function() {
    App.NewAgents.addInitializer(function () {
      App.NewAgents.name = I18n.t("webapp.agents.title");
    });

    App.NewAgents.show = function() {
      $('#container-new-agents').show()
      // TODO: put code here that needs to run when the module is clicked
      // in the navigation
    }
    App.NewAgents.hide = function() {
      $('#container-new-agents').hide()
    }

    App.NewAgents.on('loaded', function() {
      vent.commands.execute('navigation:loaded', 'agents')
    })

    App.NewAgents.addInitializer(function() {
      App.NewAgents.registerInNavigation()
      // TODO: render the actual component
      $('#container-content').append('<div id="container-new-agents" class="content"></div>');
      LayoutContentResizer.resizeContent()
      $('#container-new-agents').hide()
      ReactDom.render(<div>replace this with your root component</div>,
                      document.getElementById("container-new-agents"))
      // TODO: the navigation icon can only be clicked after this is triggered:
      App.NewAgents.trigger('loaded')
    })

    App.NewAgents.addFinalizer(function() {
      this.hide()
      this.deregisterInNavigation()
      ReactDom.unmountComponentAtNode(document.getElementById('container-new-agents'))
    })

    App.NewAgents.registerInNavigation = function () {
      vent.commands.execute('navigation:register',
                            App.NewAgents,
                            'agents')
      vent.commands.execute('navigation:loading', 'agents')
      vent.commands.setHandler('navigation:show:agents', function () {
        App.NewAgents.show()
      })
      vent.commands.setHandler('navigation:hide:agents', function () {
        App.NewAgents.hide()
      })
    }
  }
})

export default App.NewAgents
