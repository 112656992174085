import AgentResultMarkImportantController from
'controllers/agents/agentResultMarkImportantController';

class ReaderResultMarkImportantController
    extends AgentResultMarkImportantController {
        _bindShortcuts() {}

        _unbindShortcuts() {}

        _runAfterSaveCallback() {
           this.options.readerResult.trigger('change');
        }
}

export default ReaderResultMarkImportantController;
