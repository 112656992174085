import _ from 'underscore';
import TrashBinListView from 'views/agents/trashBinListView';
import users from 'collections/usersInstance';
import AgentResultApiCollection from 'collections/agentResultApiCollection';
import AgentResultListController from 'controllers/agents/agentResultListController';
import AgentResultTrashBinDetailController from 'controllers/agents/agentResultTrashBinDetailController';
import AutomaticAppendTracker from 'models/agents/automaticAppendTracker';
import session from 'models/sessionInstance';
import agents from 'collections/agentsInstance';

class TrashBinController extends AgentResultListController {
    initialize(options) {
        let trashBinOptions = _.extend(
            { appendTracker: new AutomaticAppendTracker(3) }, options
        );
        AgentResultListController.prototype.initialize.apply(this, [trashBinOptions]);
    }

    _initializeApiCollection(options) {
        this.agentResults = new AgentResultApiCollection([], {
            filter: options.filter,
            hidden: true,
            agentIds: this._agentIds()
        });
    }

    _agentIds() {
        if (session.isGroupAdmin()) {
            let sharedAgents = _.filter(agents.models, (agent) => {
                return agent.get('shared') === true;
            });
            return _.map(sharedAgents, (sharedAgent) => {
                return sharedAgent.get('id');
            });
        } else {
            return users.get(users.currentUser.id).agents.pluck('id');
        }
    }

    _initializeListView() {
        this.view = new TrashBinListView({
            results: this.agentResults
        });
    }

    loadListCollection() {
        let trashBinController = this;

        this.agentResults.fetch({success: function (model, response) {
            if (response.collection.length === 0) {
                trashBinController.handleNoItemsToDisplay();
            } else {
                trashBinController._appendItemsFinished();
            }
        }});
    }

    _registerControllerSpecificListeners() {
        this.listenTo(
            this.view, "itemview:result:clicked", this._handleResultClick);
    }

    _handleResultClick(itemView) {
        let agentResult = itemView.model;
        let otherResultViews = _.reject(this.agentResultListItemViews,
            function (resultView) {
            return resultView === itemView;
        });

        _.each(otherResultViews, function (result) {
            result.markAsDeselected();
        });

        this.agentResultDetailController = new AgentResultTrashBinDetailController({
            agentResult: agentResult
        });

        this.listenTo(
            this.agentResultDetailController, "clicked:restore",
            this._handleAgentResultRestore
        );

        if (!agentResult.get("read") && agentResult.isOwnedByCurrentUser()) {
            agentResult.save({read: true});
        }

        if (itemView.isSelected()) {
            this._deselectItemView(itemView);
        } else {
            this._selectItemView(itemView);
        }
    }

    deselectAll() {
        _.each(this.agentResultListItemViews, (itemView) => {
            if (itemView.isSelected()) {
                this._deselectItemView(itemView);
            }
        }, this);
    }

    _deselectItemView(itemView) {
        itemView.markAsDeselected();
        this.trigger('agentResult:deselected');
    }

    _selectItemView(itemView) {
        itemView.markAsSelected();
        this.trigger(
            'agentResult:selected',
            this.agentResultDetailController.view
        );
    }

    _handleAgentResultRestore(agentResult) {
        this.agentResults.remove(agentResult);
        this.trigger('agentResult:restored');
    }
}

export default TrashBinController;
