import * as Backbone from "backbone";
import Config from "libraries/configuration";
import Entity from "models/entity";
import {
  IPrefixSearchResponse
} from 'models/prefixSearchResponse'

class EntityPrefixSearch extends Backbone.Collection<Entity> {
  url = () => {
    return Config.apiUrl + "prefix_search";
  }

  parse(response: IPrefixSearchResponse) {
    return response.collection;
  }
}

EntityPrefixSearch.prototype.model = Entity;

export default EntityPrefixSearch;
