import {
  IAddressbookModelProps,
  IAddressbookGroup,
  IAddressbookContact
} from 'models/addressbookModel'
import ContactReactAdapter from 'models/contactReactAdapter'
import ContactsListView from 'views/contactGroup/contactsListView'
import ContactsSearchListView from 'views/contactGroup/contactsSearchListView'
import AddContactView from 'views/contactGroup/addContactView'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'

interface IContactGroupContactsProps {
  editContactId: number | null
  highlightContactId: number | null
  newContactInput: boolean
  newContactEmailInvalid: boolean
  newContactText: string
  searchText: string
  searchInput: string
  searchOpen: boolean
  addressbookOnly: boolean
  useButtonStyle: boolean
  contactsText: string
  addressBookText: string
  useAudienceCount: boolean
  searchable: boolean
  contacts: Array<IAddressbookModelProps>
  allContacts: Array<IAddressbookModelProps>
  onAdd: (contact: IAddressbookContact, query: string) => void
  onEdit: (contact: IAddressbookContact, query: string) => void
  onRemove: (contact: IAddressbookContact, query: string) => void
  onDestroy: (contact: IAddressbookContact, query: string) => void
  onListItemClick: (contact: IAddressbookContact, query: string) => void
  onCancel: (query: string) => void
  onUpdate: (contact: IAddressbookContact, name: string, email: string, query: string) => void
  onNewContact: (query: string) => void
  onCreateContact: (name: string, email: string, query: string) => void
  onSearch: (query: string) => void
  onGroupAdd: (group: IAddressbookGroup, query: string) => void
  onGroupRemove: (group: IAddressbookGroup, query: string) => void
  addressbookAddable: boolean
  addressbookEditable: boolean
  addressbookRemovable: boolean
  addressbookDestroyable: boolean
  contactsAddable: boolean
  contactsEditable: boolean
  contactsRemovable: boolean
  contactsDestroyable: boolean
  contactGroupAddable?: boolean
  contactGroupContactEditable?: boolean
}

class ContactGroupContactsView extends React.Component<IContactGroupContactsProps, {}> {
  render() {
    let ulClasses = ['contact-list']
    if (this.props.useButtonStyle) {
      ulClasses.push('contact-list--button-group')
    }

    return (
      <ul className={ulClasses.join(' ')}>
        { this.addSearchInput() }
        { this.contactList() }
        { this.addressbookList() }
        { this.addNewContact() }
      </ul>
    )
  }

  private contactList() {
    const contactsListProps = {
      editContactId: this.props.editContactId,
      highlightContactId: this.props.highlightContactId,
      contactsText: this.props.contactsText,
      contacts: this.props.contacts,
      visibilityMap: this.buildVisibilityMap(this.props.contacts, this.props.searchInput),
      onAdd: this.props.onAdd,
      onEdit: this.props.onEdit,
      onRemove: this.props.onRemove,
      onDestroy: this.props.onDestroy,
      onListItemClick: this.props.onListItemClick,
      onCancel: this.props.onCancel,
      onUpdate: this.props.onUpdate,
      onGroupAdd: this.props.onGroupAdd,
      useAudienceCount: this.props.useAudienceCount,
      onGroupRemove: this.props.onGroupRemove,
      searchInput: this.props.searchInput,
      addable: this.props.contactsAddable,
      editable: this.props.contactsEditable,
      removable: this.props.contactsRemovable,
      destroyable: this.props.contactsDestroyable,
      groupAddable: false,
      groupRemovable: true
    }

    if (this.props.addressbookOnly) {
      return <span></span>
    } else {
      return <ContactsListView {...contactsListProps}/>
    }
  }

  private addressbookList() {
    let groupAddable = true;
    let groupContactEditable = true;
    if (this.props.contactGroupAddable !== undefined) {
      groupAddable = this.props.contactGroupAddable;
    }
    if (this.props.contactGroupContactEditable !== undefined) {
      groupContactEditable = this.props.contactGroupContactEditable;
    }
    const contactsSearchListProps = {
      editContactId: this.props.editContactId,
      highlightContactId: this.props.highlightContactId,
      newContactInput: this.props.newContactInput,
      newContactEmailInvalid: this.props.newContactEmailInvalid,
      newContactText: this.props.newContactText,
      addressBookText: this.props.addressBookText,
      useButtonStyle: this.props.useButtonStyle,
      onNewContact: this.props.onNewContact,
      onCreateContact: this.props.onCreateContact,
      useAudienceCount: this.props.useAudienceCount,
      contacts: this.props.allContacts,
      visibilityMap: this.buildVisibilityMap(this.props.allContacts, this.props.searchInput),
      onAdd: this.props.onAdd,
      onEdit: this.props.onEdit,
      onRemove: this.props.onRemove,
      onDestroy: this.props.onDestroy,
      onListItemClick: this.props.onListItemClick,
      onCancel: this.props.onCancel,
      onUpdate: this.props.onUpdate,
      onGroupAdd: this.props.onGroupAdd,
      onGroupRemove: this.props.onGroupRemove,
      searchInput: this.props.searchInput,
      searchOpen: this.props.searchOpen,
      addable: this.props.addressbookAddable,
      editable: this.props.addressbookEditable,
      removable: this.props.addressbookRemovable,
      destroyable: this.props.addressbookDestroyable,
      groupAddable: groupAddable,
      groupRemovable: false,
      groupContactEditable: groupContactEditable
    }

    return <ContactsSearchListView {...contactsSearchListProps}/>
  }

  private buildVisibilityMap(contacts: Array<IAddressbookModelProps>, filter: string) {
    return ContactReactAdapter.filter(contacts, filter)
  }

  private addSearchInput() {
    if (this.props.searchable) {
      const onSearch = (e: any) => {
        const inputValue = $(e.target).val();
        const debouncedOnSearch = _.debounce(this.props.onSearch, 200);
        debouncedOnSearch(inputValue.toString());
      };
      return (
        <li className="contact-list__item contact-list__item-filter">
          <div className="contact-list__item-lens">
            <i className="icon-lens" />
          </div>
          <form>
            <input
              className="contact-list__filter text-input"
              onKeyUp={onSearch}
              data-contacts-search
              type="text"
              placeholder={this.props.searchText}
              defaultValue={this.props.searchInput}
            />
          </form>
        </li>
      );
    }
  }

  private addNewContact() {
    if (this.props.searchInput !== "") {
      return (
        <AddContactView onNewContact={this.props.onNewContact}
                        key={'newContact'}
                        onCancel={this.props.onCancel}
                        onCreateContact={this.props.onCreateContact}
                        newContactText={this.props.newContactText}
                        newContactInput={this.props.newContactInput}
                        useButtonStyle={this.props.useButtonStyle}
                        newContactEmailInvalid={this.props.newContactEmailInvalid}
                        searchInput={this.props.searchInput}/>
      )
    }
  }
}

export default ContactGroupContactsView
