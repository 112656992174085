import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/shortcutsHelpModal.html'
import session from 'models/sessionInstance'
import I18n from 'i18n'

var ShortcutsHelpView = Marionette.ItemView.extend({
  template: template,
  onRender: function () {
    this.ui.helpModal.hide();
  },
  ui: {
    'helpModal': '[data-help-modal]'
  },
  serializeData: function () {
    return { mod: this.mod() };
  },
  mod: function () {
    if(session.isOSX()) {
      return I18n.t('webapp.shortcuts.cmd_key');
    } else {
      return I18n.t('webapp.shortcuts.ctrl_key');
    }
  },
  closeModal: function () {
    this.ui.helpModal.close();
  },
  isOpen: function () {
    return this.ui.helpModal.is(":visible");
  },
  openModal: function () {
    this.ui.helpModal.bPopup({
      closeClass: 'modal__close',
      opacity: '0.8',
      appending: false
    });
  }
});

export default ShortcutsHelpView;

