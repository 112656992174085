import _ from 'underscore'

var ShortcutRegistry = function () {
  var registeredKeys = {};

  function eachKeyInNamespaces(callback) {
    var namespaces = _.keys(registeredKeys);

    _.each(namespaces, function (existingNamespace) {
      var keys = _.keys(registeredKeys[existingNamespace]);
      _.each(keys, function (existingKey) {
        callback(existingNamespace, existingKey);
      });
    });
  }

  function isDefined(namespace, key) {
    return !_.isUndefined(registeredKeys[namespace]) &&
      !_.isUndefined(registeredKeys[namespace][key]);
  }

  return {
    register: function (namespace, key, callback) {
      if (_.isUndefined(registeredKeys[namespace])) {
        registeredKeys[namespace] = {};
      }
      registeredKeys[namespace][key] = callback;
    },
    unregister: function (namespace, key) {
      if (!_.isUndefined(registeredKeys[namespace])) {
        delete registeredKeys[namespace][key];
      }
    },
    lookup: function (namespace, key) {
      if (isDefined(namespace, key)) {
        return registeredKeys[namespace][key];
      }

      if (isDefined('global', key)) {
        return registeredKeys.global[key];
      }

      return function () {};
    },
    registeredBindings: function () {
      var theBindings = [];
      eachKeyInNamespaces(function (namespace, key) {
        theBindings.push(namespace + ":" + key);
      });
      return theBindings;
    }
  };
};

export default ShortcutRegistry;

