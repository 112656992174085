import WidgetView from 'views/dashboard/widgetView'
import KeySourcesWidgetView from 'views/dashboard/widgetView/keySourcesWidgetView'
import template from 'text-loader!templates/dashboard/widget/keyCommentSourcesWidget.html'

WidgetView.KeyCommentSourcesWidget = KeySourcesWidgetView.extend({
  template: template,
  prepareSerializedData: function () {
    var widget = this.model;

    this.truncateSourceNames();
    widget.setCommentAndArticleVolumeClassPerSource();

    var values = {
      sources: widget.get('sources')
    };
    return values;
  }
});

export default WidgetView.KeyCommentSourcesWidget;

