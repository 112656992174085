import Marionette from 'backbone.marionette';
import Users from 'collections/usersInstance';
import EditAgentLandingPageView from 'views/agents/settings/editAgentLandingPageView';
import EditAgentView from 'views/agents/settings/editAgentView';
import EditAgentSettingsController from 'controllers/agents/settings/editAgentSettingsController';
import session from 'models/sessionInstance';
import { AgentsPermissions } from "components/agents/permissions";

class SharedAgentSettingsController extends Marionette.Controller {
  initialize(options) {
    this.showRightHandContainer = options.showRightHandContainer;
    this.agent                  = options.agent;

    this.view  = new EditAgentView({
      agent: this.agent,
      user: Users.get(this.agent.get('user_id')),
      readOnly: !AgentsPermissions.canChangeAgentVisibility(this.agent),
      rssAvailable: session.hasFeature("agent_rss")
    });

    this._registerListeners();
    this._showEditLandingPageView();
  }

  _showEditLandingPageView() {
    this.showRightHandContainer(
      new EditAgentLandingPageView({
        shared: true
      })
    );
  }

  _showAgentSettings() {
    this.editAgentSettingsController =
      new EditAgentSettingsController({
        agent: this.agent,
        readOnly: !AgentsPermissions.canChangeAgentVisibility(this.agent),
        rssAvailable: session.hasFeature("agent_rss")
      });

    this.editAgentSettingsView =
      this.editAgentSettingsController.view;

    this.view.showEditAgentSettings(this.editAgentSettingsView);
  }

  _registerListeners() {
    this.listenTo(this.view, 'render', () => {
      this._showAgentSettings();
    });
  }
}

export default SharedAgentSettingsController;
