import Backbone from 'backbone';
import Comment from 'models/comment';
import Config from 'libraries/configuration';

class Comments extends Backbone.Collection {
    initialize(commentsAttributes, opts) {
        this.clipId = opts.clip_id;
    }
    url() {
        return Config.apiUrl + "clips/" + this.clipId + "/comments/";
    }
}

Comments.prototype.model = Comment;

export default Comments;
