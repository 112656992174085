import _ from 'underscore'
import deepMerge from 'libraries/deepMerge'
import 'highcharts_legacy'

function Chart(container, options) {
  this._initialize(container, options);
}

_.extend(Chart.prototype, {
  _initialize: function (container, options) {
    this.container = container;
    this.options = options || {};
  },

  render: function () {
    this.chart = new Highcharts.Chart(this._options());
  },

  addSeries: function (series) {
    if (_.isUndefined(this.options.series)) {
      this.options.series = [];
    }
    this.options.series = this.options.series.concat(series);
  },

  _options: function () {
    var options = this._defaultOptions();
    deepMerge(options, this.options); 
    options.chart.renderTo = this.container;
    return options;
  },
  _defaultOptions: function () {
    return {
      chart: {
        backgroundColor: "rgba(0, 0, 0, 0)"
      },
      plotOptions: {
        series: {
          shadow: false,
          animation: true
        },
        area: {
          fillOpacity: 0.2,
          marker: {
            enabled: false
          },
          lineWidth: 2
        },
        pie: {
          dataLabels: {
            enabled: false
          },
          allowPointSelect: true,
          slicedOffset: 4
        },
        column: {
          groupPadding: 0.15,
          borderWidth: 0
        },
        bar: {
          dataLabels: {
            enabled: true,
            color: '#666'
          },
          marker: {
            enabled: false
          },
          shadow: false,
          borderWidth: 0
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      xAxis: {
        lineColor: '#ccc',
        gridLineWidth: 1,
        lineWidth: 1
      },
      series: []
    };
  }
});

export default Chart;

