import I18n from 'i18n'
import DateFormatter from 'libraries/dateFormatter'

let DateFilterProvider = {
  summaryOperator(exclude: boolean) {
    if (exclude) {
      return I18n.t('webapp.agents.edit.filters.filter_operator.isnot')
    } else {
      return I18n.t('webapp.agents.edit.filters.filter_operator.is')
    }
  },

  operatorOptions() {
    return [
      {
        text: I18n.t('webapp.agents.edit.filters.filter_operator.is'),
        value: false
      },
      {
        text: I18n.t('webapp.agents.edit.filters.filter_operator.isnot'),
        value: true
      }
    ]
  },

  dropDownOption() {
    return {
      text: I18n.t('webapp.agents.edit.filters.filter_type.date'),
      value: 'date'
    }
  },

  select2Options() {
    throw `Date is not a supported select2Option`
  },

  select2CustomConfiguration() {
    return {};
  },

  allowArbitraryInput() {
    return false
  },

  showSelection(selection: string[]) {
    return selection.map(
      (d) => DateFormatter.formatDate(new Date(d))
    ).join(' - ')
  },

  validate(selection: string[]) {
    let errors = []
    if (!this.validateLength(selection)) {
      errors.push(I18n.t('webapp.agents.edit.filters.selection_empty_error'))
    }
    return errors
  },

  isValid(selection: string[]) {
    return this.validate(selection).length === 0
  },

  validateLength(selection: string[]) {
    return selection.length === 2
  }
}

export default DateFilterProvider
