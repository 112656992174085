/* eslint no-invalid-this: "off" */
import App from 'app'
import ShortcutsHelpController from 'controllers/shortcutsHelpController'

App.module('Shortcuts.Help', {
  startWithParent: true,
  define: function () {
    this.addInitializer(function () {
      this.controller = new ShortcutsHelpController();
    });
  }
});

export default App.Shortcuts.Help;

