import SceneController from 'controllers/sceneController';
import BusinessesDetailController from 'controllers/settings/businesses/businessesDetailController';
import BusinessesPlaceholderController from 'controllers/settings/businesses/businessesPlaceholderController';
import * as _ from 'underscore'
import OverviewController from 'controllers/settings/entityOverviewController';

class BusinessesSceneController extends SceneController {
  show() {
    this.controller = new OverviewController({
      headerLocaleKey: 'webapp.settings.businesses.header',
      entityType: 'business_entity',
      prefixSearchErrorLocaleKey: 'webapp.settings.businesses.searchErrorNotification',
      knowledgebaseErrorLocaleKey: 'webapp.settings.businesses.businessLoadErrorNotification'
    });
    this.showInLeftColumn(this.controller.view);
    this._showPlaceholder();
    this._registerListeners();
  }

  _showPlaceholder() {
    this._closeDetailView();
    this._showDetailController (BusinessesPlaceholderController, {});
  }

  _showDetailController(Controller, param) {
    this.detailController = new Controller(param);
    this.detailView = this.detailController.view;
    this.showInRightColumn(this.detailView);
  }

  _closeDetailView() {
    if (!_.isUndefined(this.detailView)) {
      this.detailView.close();
      delete this.detailView;
      this.detailController.close();
      delete this.detailController;
      this.focusRight();
    }
  }

  _registerListeners() {
    this.listenTo(this.controller, 'entity:clicked', this._showBusinessDetails);
    this.listenTo(this.controller, 'entity:closed', this._closeDetailView);
  }

  _showBusinessDetails(data) {
    this._showDetailController(
      BusinessesDetailController,
      { business: data }
    );

  }
}

export default BusinessesSceneController;
