import Marionette from 'backbone.marionette';
import SetUpLicensing from '../../components/SetUpLicensing';
import ReactWrapper from 'views/marionetteReactWrapper';
import React from 'react';

class SetUpLicensingController extends Marionette.Controller {
  initialize(options) {
    this.item = options.item
    const props = {
      item: this.item
    }
    this.view = new ReactWrapper({
      getComponent: () => {
        return <SetUpLicensing {...props} />;
      },
    });
  }
}

export default SetUpLicensingController;
