import * as React from 'react'
import * as ReactDOM from 'react-dom'
import I18n from 'i18n'
import * as _ from 'underscore'
import { IEsApiResultClipLink } from "models/esResult";

interface Props {
  clipLinks: IEsApiResultClipLink[];
}

export default class ClipLinks extends React.Component<Props, {}> {
  render() {
    return (
      <div className="clip-detail__footer">
        <div className="meta__title">{I18n.t("webapp.components.clip_links.title")}</div>
        <ul className="crosslinks">
          {this.clip_links()}
        </ul>
      </div>
    )
  }

  private clip_links(): JSX.Element[] {
    return this.props.clipLinks.map(
      (l) => <ClipLink link={l} key={l.linked_clip_id} />
    )
  }
}

interface LinkProps {
  link: IEsApiResultClipLink
}

class ClipLink extends React.Component<LinkProps, {}> {
  render() {
    const link = this.props.link;
    const source = link.primary_source;
    let sourceClipUrl = source.clip_url || undefined;
    return (
        <li className="crosslinks__item">
          <img alt="" className="crosslinks__source-icon handheld-hidden" src={source.favicon_url} />
          <a href={sourceClipUrl} className="crosslinks__link" target="_blank">
            <span className="crosslinks__source-name">{source.name}:</span>
            <span className="crosslinks__clip-title">{link.title}</span>
          </a>
        </li>
      )
  }
}
