import Widget from 'models/dashboard/widget'

Widget.SwissFacebookWallWidget = Widget.extend({
  parse: function (response) {
    if (response.object) {
      response = response.object;
    }

    response.time = new Date(response.time * 1000);

    return response;
  }
});

export default Widget.SwissFacebookWallWidget;

