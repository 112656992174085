import Marionette from 'backbone.marionette';
import AbstractPatternView from 'views/newsroom/abstractPatternView';
import ReaderSchedulingView
    from 'views/newsroom/readerSchedulingView';
import WeekdaySelectorController
    from 'controllers/settings/weekdaySelectorController';
import ReaderConfigurationHolidayExclusionView
    from 'views/newsroom/readerConfigurationHolidayExclusionView';
import ReaderConfigurationDateExclusionView
    from 'views/newsroom/readerConfigurationDateExclusionView';
import TimeSelectorController from 'controllers/settings/timeSelectorController';
import Notificator from 'libraries/notificator';
import moment from 'moment';
import I18n from 'i18n';
import _ from 'underscore';

class AbstractPatternController extends Marionette.Controller {
    initialize(options) {
        this.configuration = options.configuration;
        const viewOptions = {
            configuration: this.configuration,
            titleKey: this.titleKey,
            scheduleTimeLabel: I18n.t(this.scheduleTimeLabelKey())
        };
        this.view = new AbstractPatternView(viewOptions);
        this._registerListeners();
    }

    _registerListeners() {
        this.listenTo(this.view, 'render', this._setupSubviews);
    }

    _setupSubviews() {
        this._setupPublicationTimePattern();
        this._setupPublicationTimelineOrder();
        this._setupWeekdaySelection();
        this._setupTimeSelectorView();
        this._setupHolidayExclusionView();
        this._setupDateExclusionsView();
    }

    _publicationTimePatternProps() {
        const realtimeKey =
            'webapp.reader_admin.edit.issue_pattern_publication_time.realtime';
        const deadlineKey =
            'webapp.reader_admin.edit.issue_pattern_publication_time.editorial_deadline';
        const isRealtimeActive =
            this.configuration.get('starts_as_delivery_time');

        return {
            buttons: [
                {
                    label: I18n.t(realtimeKey),
                    value: 'realtime'
                },
                {
                    label: I18n.t(deadlineKey),
                    value: 'deadline'
                }
            ],
            selectedOption: isRealtimeActive ? 'realtime' : 'deadline',
            onRadioButtonClick: (value) => {
                if (value === 'realtime') {
                    const sendNotificationPattern =
                        this.configuration.get('send_notification_pattern');
                    this.configuration.set({ starts_as_delivery_time: true });
                    if (sendNotificationPattern === 'same as archive') {
                        this.configuration.set({ send_notification_pattern: 'none' });
                    }
                    this.view.hideTimeSelector();
                    this.timeSelectorController.updateValue(moment().startOf('day'));
                } else {
                    this.configuration.set({ starts_as_delivery_time: false });
                    this.view.displayTimeSelector();
                }

                this.configuration.save();
                this._renderPublicationTimePattern({ selectedOption: value });
            }
        };
    }

    _setupPublicationTimePattern() {
        this._renderPublicationTimePattern();
    }

    _renderPublicationTimePattern(props) {
        let fullProps = this._publicationTimePatternProps();
        if (props !== undefined) {
            fullProps = _.extend({}, fullProps, props);
        }
        this.view.showPublicationTimeOptions(fullProps);
    }

    _setupPublicationTimelineOrder() {
        this._renderPublicationTimelineOrder({
            selectedOption: this.configuration.get('timeline_order')
        });
    }

    _renderPublicationTimelineOrder(props) {
        let fullProps = this._publicationTimelineOrderProps();
        if (props !== undefined) {
            fullProps = _.extend({}, fullProps, props);
        }
        this.view.showPublicationTimelineOrder(fullProps);
    }

    _publicationTimelineOrderProps() {
        return {
            buttons: this._publicationTimelineOrderButtons(),
            selectedOption: this.configuration.get('timeline_order'),
            onRadioButtonClick: (value) => {
                this.configuration.store();
                this.configuration.set({ timeline_order: value });
                this.configuration.save().fail(() => {
                    this.configuration.restore();
                    this._renderPublicationTimelineOrder({
                        selectedOption: this.configuration.get('timeline_order')
                    });
                });
                this._renderPublicationTimelineOrder({
                    selectedOption: value
                });
            }
        };
    }

    _publicationTimelineOrderButtons() {
        return [
            {
                label: I18n.t("webapp.reader_admin.edit.issue_settings.add_new_results_to_issue.based_on_aggregation"),
                value: 'based_on_aggregation'
            },
            {
                label: I18n.t("webapp.reader_admin.edit.issue_settings.add_new_results_to_issue.based_on_latest_issue"),
                value: 'based_on_tag'
            }
        ];
    }

    _schedulingViewData() {
        return {
            startsAsDeliveryTimeOptionEnabled: true,
            weekdaysOptionEnabled: true,
            noneOptionEnabled: true
        };
    }

    _setupSchedulingView() {
        const currentOption = this._getPattern();
        const subviewOptions = {
            template: this.schedulingOptionsTemplate,
            currentOption,
            viewData: this._schedulingViewData()
        };
        this.readerSchedulingView =
            new ReaderSchedulingView(subviewOptions);
        this.view.showScheduleOptions(this.readerSchedulingView);
        this.listenTo(this.readerSchedulingView,
            'change', this._changeScheduling);
        this._updateDetailsVisibility(currentOption);
    }

    _setupWeekdaySelection() {
        const archiveDateSchedule = this._weeklySchedule();

        // The weekday selector expects an array of booleans with
        // length 7, but the configuration has an array of enabled
        // days, so we need to convert.
        const days = archiveDateSchedule.weekdays;
        const weekdays = [0, 1, 2, 3, 4, 5, 6].map((i) => _.include(days, i));

        this.weekdaySelectorController = new WeekdaySelectorController(weekdays);
        this.view.showWeekdaySelection(this.weekdaySelectorController.view);
        this.listenTo(
            this.weekdaySelectorController,
            'change',
            this._handleWeekdaySelection
        );
    }

    _setupTimeSelectorView() {
        const time = this._timeForToday();
        this.timeSelectorController = new TimeSelectorController({
            value: time,
            duration: 60000 * 15 // 15 minutes
        });
        this.timeSelectorView = this.timeSelectorController.view;

        this.view.showTimeSelector(this.timeSelectorView);

        this.listenTo(this.timeSelectorController, 'change', this._changeTime);
    }

    _setupHolidayExclusionView() {
        const archiveDateSchedule = this._weeklySchedule();
        let excludedHolidays = [];

        if (archiveDateSchedule) {
            excludedHolidays = archiveDateSchedule.excluded_holidays;
        }

        this.holidayExclusionView = new ReaderConfigurationHolidayExclusionView(
            { excludedHolidays: excludedHolidays }
        );
        this.view.showHolidayExclusions(this.holidayExclusionView);
        this.listenTo(
            this.holidayExclusionView,
            'holiday-exclusion:selection-changed',
            this._handleHolidayExclusionChange
        );
    }

    _setupDateExclusionsView() {
        const archiveDateSchedule = this._weeklySchedule();

        const excludedDates = archiveDateSchedule.excluded_dates;

        this.dateExclusionView = new ReaderConfigurationDateExclusionView({
            model: this.configuration,
            excludedDates: excludedDates
        });

        this.view.showDateExclusions(this.dateExclusionView);
        this.listenTo(
            this.dateExclusionView,
            'update:excludedDate',
            this._handleExcludedDateUpdate
        );
    }

    _changeScheduling(value) {
        this._setPattern(value);
        this._updateDetailsVisibility(value);
        this._saveConfiguration();
    }

    _updateDetailsVisibility(value) {
        if (value === 'weekdays') {
            this.view.showSchedulingDetails();
        } else {
            this.view.hideSchedulingDetails();
        }
    }

    _saveConfiguration() {
        this.configuration.save(undefined, {
            error: () => { this._showSaveError(); }
        });
    }

    _weeklySchedule() {
        const schedule = this.configuration.get(this.scheduleAttribute);
        if (schedule) {
            return JSON.parse(JSON.stringify(schedule));
        }

        return {
            times: ['0:00'],
            weekdays: [0, 1, 2, 3, 4, 5, 6],
            excluded_dates: []
        };
    }

    _timeForToday() {
        const schedule = this._weeklySchedule();
        const time = schedule.times[0];
        const match = time.match(/(\d+):(\d+)/);
        if (!match) { throw `time ${time} is not well formatted`; }
        const hours = parseInt(match[1], 10);
        const minutes = parseInt(match[2], 10);
        const midnight = moment().startOf('day');
        return midnight.add(hours, 'hours').add(minutes, 'minutes');
    }

    _changeTime(value) {
        const schedule = this._weeklySchedule();
        schedule.times = [value.format('HH:mm')];
        this._setSchedule(schedule);
        this._saveConfiguration();
    }

    _handleHolidayExclusionChange(excludedHolidays) {
        const schedule = this._weeklySchedule();
        schedule.excluded_holidays = excludedHolidays;
        this._setSchedule(_.extend({}, schedule));
        this._saveConfiguration();
    }

    _handleExcludedDateUpdate(updatedExcludedDates) {
        const archiveDateSchedule = this._weeklySchedule();

        archiveDateSchedule.excluded_dates = updatedExcludedDates;

        this._setSchedule(archiveDateSchedule);
        this._saveConfiguration();
    }

    _handleWeekdaySelection(days) {
        // The weekday selector sends the data as an array of booleans
        // with length 7, but the configuration stores it as an array
        // of enabled days, so we need to convert.
        const weekdays = _.reduce(days, (w, d, i) => {
            if (d) {
                return w.concat(i);
            } else {
                return w;
            }
        }, []);
        const schedule = this._weeklySchedule();
        schedule.weekdays = weekdays;
        this._setSchedule(_.extend({}, schedule));
        this._saveConfiguration();
    }

    _showSaveError() {
        const errorMessage = I18n.t('webapp.notifications.error.not_saved');
        Notificator.showNotification(errorMessage);
    }

    _getPattern() {
        return this.configuration.get(this.patternAttribute);
    }

    _setPattern(value) {
        let newSchedule;
        if (value !== 'weekdays') {
            const schedule = this.configuration.get(this.scheduleAttribute);
            if (schedule) { this._schedule = schedule; }
            newSchedule = null;
        } else {
            newSchedule = this._schedule || this._weeklySchedule();
        }
        const attributes = {};
        attributes[this.patternAttribute] = value;
        attributes[this.scheduleAttribute] = newSchedule;
        this.configuration.set(attributes);
    }

    _setSchedule(value) {
        this.configuration.set(this.scheduleAttribute, value);
    }
}

export default AbstractPatternController;

