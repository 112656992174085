import * as React from 'react';
import I18n from 'i18n';
import { useEffect } from 'react';
import session from 'models/sessionInstance';

export interface IHeaderProps {
  isImpersonated: boolean;
  passwordEnabled: boolean;
  deviceTypePhone: boolean;
  userName: string;
  logoutCallback: () => void;
  unswitchCallback: () => void;
}

export default function Header({
  isImpersonated,
  passwordEnabled,
  deviceTypePhone,
  userName,
  logoutCallback,
  unswitchCallback,
}: IHeaderProps) {
  const listStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  function slaaskVisible(e: any) {
    e.preventDefault();
    if (!_slaask.isOpen()) {
      _slaask.show();
    } else {
      _slaask.hide();
    }
  }
  
  useEffect(() => {
    //hide slaask widget on page load even if the page was refreshed
    //while the widget was open
    if (_slaask.isOpen()) {
      _slaask.hide();
    }
    //override slaask rule for diplay none
    document.getElementsByTagName('body')[0].classList.remove('hideSlaask');
  }, []);
  
  const isSlaaskEnabled = session.hasFeature('enable_slaask');
  
  return (
    <div className="header">
      {logo()}
      <div
        className="header__user-menu user-menu"
        style={isSlaaskEnabled ? { marginTop: '-4px' } : {}}>
        <ul style={listStyle}>
          {isSlaaskEnabled && (
            <li className="user-menu__item">
              <a
                href="#"
                id="slaaskBtn"
                style={{ outline: 'none' }}
                onClick={slaaskVisible}>
                <img
                  src="/images/bR-Chat-Icon Slaask.png"
                  alt="Slaask"
                  width={30}
                  height={30}
                />
              </a>
            </li>
          )}
          {userIdentifierAndActions()}
        </ul>
      </div>
    </div>
  );

  function logo() {
    return (
      <a className="header__logo">
        <img
          src="/images/bluereport-logo-retina.png"
          alt="blueReport"
          className="header__logo__image"
        />
      </a>
    );
  }

  function userIdentifierAndActions() {
    if (isImpersonated) {
      return loginAsLogoutLink();
    } else {
      if (passwordEnabled) {
        return standardLogoutLink();
      } else {
        return ssoSignedIn();
      }
    }
  }

  function standardLogoutLink() {
    if (deviceTypePhone) {
      return (
        <li className="user-menu__item">
          <a className="user-menu__action" onClick={() => logoutCallback()}>
            <i className="icon-logout"></i> <span>{I18n.t("webapp.header.sign_out_before_name")}</span></a>
        </li>
      );
    } else {
      return (
        <li className="user-menu__item">
          <a className="user-menu__action" onClick={() => logoutCallback() }>
            <i className="icon-logout"></i> <span>{I18n.t("webapp.header.sign_out_before_name")}</span> <span className="header__user-menu__username handheld-hidden">{userName}</span></a>
        </li>
      );
    }
  }

  function ssoSignedIn() {
    return (
      <li className="user-menu__item">
        <span className="user-menu__action">
          <span>{I18n.t('webapp.header.signed_in_as')}</span>{' '}
          <span className="header__user-menu__username handheld-hidden">
            {userName}
          </span>
        </span>
      </li>
    );
  }

  function loginAsLogoutLink() {
    if (deviceTypePhone) {
      <li className="user-menu__item">
        <a className="user-menu__action" onClick={() => unswitchCallback()}>
          <i className="icon-logout"></i>{' '}
          <span className="handheld-hidden">
            {I18n.t('webapp.header.unswitch_short')}
          </span>
        </a>
      </li>;
    } else {
      return (
        <li className="user-menu__item">
          <a className="user-menu__action" onClick={() => unswitchCallback()}>
            <i className="icon-logout"></i>{' '}
            <span>{I18n.t('webapp.header.unswitch_before_name')}</span>{' '}
            <span className="header__user-menu__username handheld-hidden">
              {userName}
            </span>{' '}
            <span className="handheld-hidden">
              {I18n.t('webapp.header.unswitch_after_name')}
            </span>
          </a>
        </li>
      );
    }
  }
}
