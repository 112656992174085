import * as React from "react";

interface SidebarSubtreeItemProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  className: string
  children: any
  isActive?: boolean
  onMouseOver?: () => void
  onMouseOut?: () => void
}

const SidebarSubtreeItem = (props: SidebarSubtreeItemProps) => {
  const base = "nav-subtree__item "
  const activeClass = props.isActive ? "is-active" : ""
  const full = base + activeClass
  const attrs: any = {
    className: full
  }
  if (props.onMouseOver) {
    attrs['onMouseOver'] = props.onMouseOver
  }
  if (props.onMouseOut) {
    attrs['onMouseOut'] = props.onMouseOut
  }

  return (
    <li {...attrs}>
      <a className={props.className} onClick={props.onClick}>
        {props.children}
      </a>
    </li>
  );
}

export default SidebarSubtreeItem