import I18n from 'i18n'
import * as _ from 'underscore'

const languagesToOptions = (languages: string[]) => {
  let options = _.map(languages, (lang) => {
    return {
      text: languageKeyTranslation(lang),
      id: lang
    }
  })
  return _.sortBy(options, (opt) => {return opt['text']})
}

const languageKeyTranslation = (key: string) => {
  return I18n.t('vendor.iso.languages.' + key)
}

let LanguageFilterProvider = {
  summaryOperator(exclude: boolean) {
    if (exclude) {
      return I18n.t('webapp.agents.edit.filters.filter_operator.isnot')
    } else {
      return I18n.t('webapp.agents.edit.filters.filter_operator.is')
    }
  },

  operatorOptions() {
    return [
      {
        text: I18n.t('webapp.agents.edit.filters.filter_operator.is'),
        value: false
      },
      {
        text: I18n.t('webapp.agents.edit.filters.filter_operator.isnot'),
        value: true
      }
    ]
  },

  dropDownOption() {
    return {
      text: I18n.t('webapp.agents.edit.filters.filter_type.language'),
      value: 'language'
    }
  },

  select2Options() {
    let mainLanguages = ['de', 'en', 'fr', 'it']
    let translations: { [key: string]: any } = I18n.translations
    let languages = _.difference(Object.keys(translations['de'].vendor.iso.languages), mainLanguages)
    return languagesToOptions(mainLanguages).concat(languagesToOptions(languages))
  },

  select2CustomConfiguration() {
    return {};
  },

  allowArbitraryInput() {
    return false
  },

  showSelection(selection: string[]) {
    let or = ' ' + I18n.t('webapp.agents.edit.filters.multiple_selection_or') + ' '
    let languages = _.map(selection, (lang) => {
      return languageKeyTranslation(lang)
    })
    if (languages.length > 2) {
      return languages.slice(0, -1).join(', ') + or + languages.slice(-1)
    } else {
      return languages.join(or)
    }
  },

  validate(selection: string[]) {
    let errors = []
    if (!this.validateLength(selection)) {
      errors.push(I18n.t('webapp.agents.edit.filters.selection_empty_error'))
    }
    return errors
  },

  isValid(selection: string[]) {
    return this.validate(selection).length === 0
  },

  validateLength(selection: string[]) {
    return selection.length > 0
  }
}

export default LanguageFilterProvider
