import RestorableModel from "libraries/restorableModel";

interface IUsagePerCountry {
  ch: number;
  at: number;
  de: number;
}

interface IEsApiSourceMeta {
  favicon_url: string;
  name: string;
  reach: number | null;
  article_url?: string;
  unique_clients?: number | null;
  page_impressions?: number | null;
  usage_per_country?: IUsagePerCountry;
  alexa_city?: string | null;
  alexa_country?: string | null;
  source_category_ids?: number[];
  paywall_type?: string | null;
  page_number?: string | null;
  // SopranoArgusClip
  country?: string;
  // MMOClip
  phone_number?: string;
  media_type?: string;
  // RtvClip - Emm
  clip_published_at?: number;
  // ImportedClip
  circulation_distributed?: number | null;
}

export interface IEsApiSource {
  id: number | null;
  category_ids: number[];
  meta: IEsApiSourceMeta;
}

export interface IEsApiResultAttachment {
  id: number;
  file_type: string;
  file_name: string;
  kind: string;
  size: number;
  extension: string;
  url: string;
  caption: string | null;
}
export interface IEsApiResultClipLink {
  type: string;
  linked_clip_id: number;
  title: string;
  primary_source: {
    id: number;
    name: string;
    favicon_url: string;
    clip_url: string | null;
  }
}

interface IEsApiTopicMeta {
  identifier: string;
  name: string;
  parent_id: number;
}

interface IEsApiTopic {
  id: number;
  meta: IEsApiTopicMeta;
}

interface IEsApiResultProperties {
  // DpaClip
  article_url?: string;
  version?: string;
  filename?: string;
  clip_type?: string;
  urgency?: string;
  blocking_period?: string;
  publication_status?: string;
  industry?: string;
  located?: string;
  point_of_interest?: string;
  editorial_department?: string;
  genre?: string;
  dpa_author?: string;
  country?: string;
  // MMOClip
  clipping_id?: string;
  clipping_url?: string;
  clipping_area?: string;
  clipping_version?: string;
  mediacode?: string;
  clipping_code?: string;
  pages?: string;
  appearance_date?: string;
  appearance_number?: string;
  appearance_year?: string;
  article_ad_price?: string;
  // RtvClip - EMM
  emm_duration?: number;
  emm_ad_value_currency?: string;
  emm_ad_value?: string;
  emm_vimeo_id?: string;
  // RtvClip - Comvision
  comvision_raw_result_guid?: string;
  comvision_media_kind?: string;
  comvision_download_url?: string;
  comvision_station_id?: number;
  comvision_cutting_url?: string;
  comvision_keyframe_url?: string;
  av_processing_done?: boolean;
  comvision_media_length?: number;
  comvision_full_clip?: string;
  // ImportedClip
  clip_category?: string;
  clip_author?: string;
  clip_eur_ad_value?: string;
  unique_id?: string;
  clip_importer?: string;
  clip_print_edition?: string;
  vimeo_id?: string;
  thumbnail_url_with_play_button?: string;
  // SMTTwitterClip
  retweets_count?: string;
  favourites_count?: string;
  followers_count?: string;
  statuses_count?: string;
  screen_name?: string;
  description?: string;
  location?: string;
}

interface IEsApiResultMeta {
  text: string | null;
  rich_text: string | null;
  downloadable: boolean;
  reading_time: number | null;
  radio_tv_clip: boolean;
  is_print_source: boolean;
  attachments: IEsApiResultAttachment[];
  clip_links: IEsApiResultClipLink[];
  clip_topics: IEsApiTopic[];
  properties: IEsApiResultProperties;
  mentions?: string[];
  comment_count?: string | null;
  // SopranoAdminChConsultationClip SopranoAdminChConsultationDocumentClip
  status?: string;
  last_update?: string;
  // SopranoAdminChOfficialCompilationClip SopranoAdminChFederalGazetteClip
  sr_number?: string;
  official_compilation_number?: string;
  federal_gazette_number?: string;
  source_name?: string;
  responsible_office?: string;
  creation_year?: string;
  // SopranoAdminChPressReleaseClip SopranoParlamentChClip
  author?: string;
  // CuriaClip
  submission_council?: string;
  legislative_period?: string;
  signatory_names?: string[];
  federal_council_proposal_date?: string;
  session?: string;
  done_date?: string;
  business_number?: number;
  business_type?: string;
  committees?: string[];
  department?: string;
  last_modified?: string;
  // SMTClip
  video_url?: string;
  image_url?: string;
  url?: string;
  // SMTYoutubeClip
  view_count?: string;
  like_count?: string;
  dislike_count?: string;
  favorites_count?: string;
  subscriber_count?: string;
  // SMTGooglePlusClip
  reply_count?: string;
  share_count?: string;
  plus_one_count?: string;
  circled_by_count?: string;
  // SMTFacebookClip
  likes?: string;
}

interface IEsApiResult {
  id: number;
  kind: string;
  title: string;
  accessible: boolean | null;
  languages: string[];
  created_at: number;
  updated_at: number;
  publish_time: number;
  meta: IEsApiResultMeta;
}

interface IEsApiEntityMeta {
  name_en: string;
  name_fr: string;
  name_de: string;
  name_it: string;
  entity_type: string;
  kb_id: string;
}

interface IEsApiEntity {
  id: number;
  meta: IEsApiEntityMeta;
}

export interface IEsApiResultContent {
  id?: number
  doc_type: string;
  clip: IEsApiResult;
  entities: IEsApiEntity[];
  sources: IEsApiSource[];
  topics: IEsApiTopic[];
}

export interface IEsApiResultsContent {
  results: IEsApiResultContent[];
  total: number;
}

class ESResult extends RestorableModel {
  parse(response: IEsApiResultContent): IEsApiResultContent {
    // For backward compatibility with collections.
    // For example timeline result selection and collection handling expects
    // there to be an id on root level.
    response.id = response.clip.id;
    return response;
  }

  primarySource(): IEsApiSource {
    return this.get("sources")[0];
  }
}

export default ESResult;
