import Marionette from 'backbone.marionette'
import * as _ from 'underscore'
import * as $ from 'jquery'
import * as moment from 'moment'
import KeypressCallbacks from 'libraries/keypressCallbacks'
import * as template from 'text-loader!templates/clipCreationForm.html'
import * as pmgTemplate from 'text-loader!templates/pmgUploadForm.html'
import * as agentOptionsTemplate from 'text-loader!templates/reporting/wizard/topicSelectionOptions.html'
import I18n from 'i18n'
import select2CloseOnBlur from 'libraries/select2CloseOnBlur'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ImageGalleryViewExtension from 'views/imageGalleryViewExtension'
import session from 'models/sessionInstance'
import EditableDetailTitleView from 'views/components/editableDetailTitleView'
import SourceSearch, { ISourceSearchProps } from 'components/sourceSearch';
import ReactWrapper from 'views/marionetteReactWrapper';

interface IEditableDetailTitleViewOptionalProps {
  title?: string
  displayError?: boolean
  onEdit?: (inputValue: string) => void
}

interface BootstrapDragEvent extends JQueryEventObject {
  originalEvent: DragEvent
}

class ClipCreationFormView extends Marionette.Layout {
  initialize() {
    _.bindAll(this, 'updateAgentOptions', 'deselect');
    this.buttons = [this.ui.pmgButton,
                    this.ui.onlineButton,
                    this.ui.fullTextButton,
                    this.ui.pdfButton,
                    this.ui.avButton];
    this.on('change:agentSelect', () => {
      const agentSelection  = this.ui.agentSelect.val();
      this.model.set({ agentSelection: agentSelection });
    });
    this.listenTo(this.model, 'change:agentOptions', this.updateAgentOptions);

    this.titleProps = {
      title: this.model.get('title'),
      displayError: false,
      onEdit: (inputValue: string) => {
        this.renderEditableTitle({ title: inputValue })
        this.trigger('change:title', inputValue);
      }
    }
    this.pmgUploadEnabled = false;
  }

  serializeData() {
    return _.extend(
      {},
      super.serializeData(),
      {
        pmg_article_removal_days: session.get("pmg_article_removal_days"),
        videoUploadEnabled: session.hasFeature("video_upload_enabled"),
        pmgUploadEnabled: session.isGermanCustomer() || session.hasFeature('pmg_upload'),
      }
    )
  }

  showSourceSearchForm(viewProps: ISourceSearchProps) {
    const sourceSearchView = new ReactWrapper({
      getComponent: () => { return <SourceSearch {...viewProps} />; }
    });
    this.sourceSearchForm.show(sourceSearchView);
  }

  showTitleAndTextInputs() {
    this.ui.titleTextInputs.show()
    this.ui.titleTextPreloader.hide()
  }

  showTitleTextPreloader() {
    this.ui.titleTextInputs.hide()
    this.ui.titleTextPreloader.show()
  }

  private assignNewTitle() {
    const newTitle = $.trim(this.ui.editableTitle.text())
    this.ui.titleInput.val(newTitle)
    this.trigger('change:title')
  }

  setUrlError(message: string) {
    this.ui.urlError.show();
    this.ui.urlErrorContent.html(message);
  }

  hideUrlError() {
    this.ui.urlError.hide();
  }

  setVideoUrlError(message: string) {
    this.ui.avError.show();
    this.ui.avErrorContent.html(message);
  }

  hideVideoUrlError() {
    this.ui.avError.hide();
  }

  setFileError(message: string) {
    this.ui.fileError.show();
    this.ui.fileErrorContent.html(message);
  }

  hideFileError() {
    this.ui.fileError.hide();
  }

  setAvError(message: string) {
    this.ui.avError.show();
    this.ui.avErrorContent.html(message);
  }

  hideAvError() {
    this.ui.avError.hide();
  }

  setAttachmentError(message: string) {
    this.ui.attachmentError.show();
    this.ui.attachmentErrorContent.html(message);
  }

  hideAttachmentError() {
    this.ui.attachmentError.hide();
  }

  setDateError(message: string) {
    this.ui.dateError.show();
    this.ui.dateErrorContent.html(message);
  }

  hideDateError() {
    this.ui.dateError.hide();
  }

  setSourceError(message: string) {
    this.ui.sourceError.show();
    this.ui.sourceErrorContent.html(message);
  }

  hideSourceError() {
    this.ui.sourceError.hide();
  }

  setTitleError() {
    this.renderEditableTitle({ displayError: true })
  }

  hideTitleError() {
    this.renderEditableTitle({ displayError: false })
  }

  setAgentsError(message: string) {
    this.ui.agentsError.show();
    this.ui.agentsErrorContent.html(message);
  }

  hideAgentsError() {
    this.ui.agentsError.hide();
  }

  hideValidationMessages() {
    this.ui.urlError.hide();
    this.ui.fileError.hide();
    this.ui.avError.hide();
    this.ui.attachmentError.hide();
    this.ui.dateError.hide();
    this.ui.sourceError.hide();
    this.ui.titleError.hide();
    this.ui.agentsError.hide();
  }

  showPmgView(view: any) {
    this.pmgView.show(view);
  }  

  toggleAVFileButton(enabled: boolean) {
    this.ui.fileButton.toggleClass('is-disabled', !enabled);
    this.ui.avFileUpload.prop('disabled', !enabled);
    this.dragdropDisabled = !enabled;
  }

  toggleVideoUrlInput(enabled: boolean) {
    this.ui.videoUrlInput.prop('disabled', !enabled);
  }

  deselect() {
    _.each(this.buttons, (btn: string) => {
      this.$(btn).removeClass('is-active');
    }, this);
  }

  selectPmg() {
    this.deselect();
    this.$(this.ui.pmgButton).addClass('is-active');
  }

  selectOnline() {
    this.deselect();
    this.$(this.ui.onlineButton).addClass('is-active');
  }

  selectFullText() {
    this.deselect();
    this.$(this.ui.fullTextButton).addClass('is-active');
  }

  selectPdf() {
    this.deselect();
    this.$(this.ui.pdfButton).addClass('is-active');
  }

  selectRtv() {
    this.deselect();
    this.$(this.ui.avButton).addClass('is-active');
  }

  showUploadInProgress() {
    this.$(this.ui.uploadInProgress).show();
  }

  hideUploadInProgress() {
    this.$(this.ui.uploadInProgress).hide();
  }

  showUrlInput() {
    this.$(this.ui.urlBox).show();
  }

  hideUrlInput() {
    this.$(this.ui.urlBox).hide();
  }

  showPdfInput() {
    this.$(this.ui.pdfBox).show();
  }

  showRtvInput() {
    this.$(this.ui.rtvBox).show();
  }

  showPageNumberInput() {
    this.$(this.ui.pageNumberBox).show();
  }

  hidePdfInput() {
    this.$(this.ui.pdfBox).hide();
  }

  hideRtvInput() {
    this.$(this.ui.rtvBox).hide();
  }

  hidePageNumberInput() {
    this.$(this.ui.pageNumberBox).hide();
  }

  hideAuthorInput() {
    this.$(this.ui.authorBox).hide();
  }

  showAuthorInput() {
    this.$(this.ui.authorBox).show();
  }

  hideDateInput() {
    this.$(this.ui.dateBox).hide();
  }

  showDateInput() {
    this.$(this.ui.dateBox).show();
  }

  hideSourceInput() {
    this.$(this.ui.sourceBox).hide();
  }

  showSourceInput() {
    this.$(this.ui.sourceBox).show();
  }

  hideTitleAndText() {
    this.$(this.ui.titleTextInputs).hide();
  }

  showTitleAndText() {
    this.$(this.ui.titleTextInputs).show();
  }

  hideTags() {
    this.$(this.ui.tagBox).hide();
  }

  showTags() {
    this.$(this.ui.tagBox).show();
  }

  hideAgents() {
    this.$(this.ui.agentsBox).hide();
  }

  showAgents() {
    this.$(this.ui.agentsBox).show();
  }

  hideAddResult() {
    this.$(this.ui.addResult).hide();
  }

  showAddResult() {
    this.$(this.ui.addResult).show();
  }
  
  hideSeparator() {
    this.$(this.ui.separator).hide();
  }

  showSeparator() {
    this.$(this.ui.separator).show();
  }
  
  hidePmgImport() {
    this.$(this.regions.pmgView).hide();
  }

  showPmgImport() {
    this.$(this.regions.pmgView).show();
  }
  
  hidePmgLicensing() {
    this.$(this.regions.pmgLicensing).hide();
  }

  showPmgLicensing() {
    this.$(this.regions.pmgLicensing).show();
  }
  
  hideInputsForPmg() {
    this.hideAddResult();
    this.hideTags();
    this.hideAuthorInput();
    this.hideDateInput();
    this.hideSourceInput();
    this.hideSeparator();
    this.hideTitleAndText();
    this.hideAgents();
    this.hidePmgLicensing();
  }

  hideSpecialInputs() {
    this.hideUrlInput();
    this.hidePdfInput();
    this.hideRtvInput();
    this.hidePageNumberInput();
  }

  showFooterInputs() {
    this.showAttachmentUpload()
    this.showAddResult();
    this.showTags();
    this.showAuthorInput();
    this.showDateInput();
    this.showSourceInput();
    this.showSeparator();
    this.showTitleAndText();
    this.showAgents();
    this.showPmgLicensing();
    this.hidePmgImport();
  }

  setDate(date: string) {
    this.$(this.ui.dateInput).val(date);
  }

  getDate() {
    return this.$(this.ui.dateInput).val();
  }

  getTitle() {
    return this.titleProps.title;
  }

  getUrl() {
    return this.$(this.ui.urlInput).val();
  }

  getVideoUrl() {
    return this.$(this.ui.videoUrlInput).val();
  }

  getText() {
    return this.$(this.ui.textInput).val();
  }

  getPageNumber() {
    return this.$(this.ui.pageNumberInput).val();
  }

  getAuthor() {
    return this.$(this.ui.authorInput).val();
  }

  getPmg() {
    return this.ui.pmgCheckbox[0].checked;
  }

  getFile() {
    return this.file;
  }

  getAvFile() {
    return this.avFile;
  }

  getAttachments() {
    if (this.galleryExtension === undefined) {
      return [];
    } else {
      return this.galleryExtension.getAttachments();
    }
  }

  getCaption(id: string) {
    const captionId = '#image-caption-' + id;
    return this.$(captionId).val();
  }

  setI18nText(text: string) {
    this.setText(I18n.t(text));
  }

  setText(text: string) {
    this.$(this.ui.textInput).val(text);
  }

  showFileName(name: string) {
    this.ui.filePath.html(name);
  }

  showAvFileName(name: string) {
    this.ui.avPath.html(name);
  }

  showAttachments() {
    if (this.galleryExtension === undefined) {
      this.galleryExtension =
        new ImageGalleryViewExtension(
          [], this.ui.attachmentsList[0], {
            onAdd: () => { this.trigger('change:attachment') },
            onUpdate: () => { this.trigger('update:attachments') },
            onDelete: () => { this.trigger('update:attachments') },
            onMove: () => {}
          }
        )
    }
    this.galleryExtension.renderGallery()
  }

  handleAttachmentFileCheckFail(message: string) {
    this.setAttachmentError(message);
  }

  hideFileButton() {
    this.ui.fileButton.hide();
  }

  useNewTitleFormInput() {
    this.ui.oldTitleFormItem.hide();
    this.ui.newTitleFormItem.show();
  }

  useNewTextFormField() {
    this.ui.textInputLabel.addClass('hidden');
    this.ui.textInput.addClass('hidden');
  }

  hideAttachmentUpload() {
    this.ui.attachments.hide()
  }

  showAttachmentUpload() {
    this.ui.attachments.show()
  }

  onRender() {
    this.hideValidationMessages();
    this.initializeAgentSelector();
    this.initializeDatePicker();
    this.setupDropZone(this.ui.fileDropZone, this._ondropFile.bind(this));
    this.setupDropZone(this.ui.avFileDropZone, this._ondropAvFile.bind(this));
    this.showAttachments()
    this.renderEditableTitle({})
  }

  initializeDatePicker() {
    this.trigger('change:date');
    const element = this.$(this.ui.dateInput).bootstrapDatepicker({
      endDate: moment().toDate(),
      language: I18n.locale,
      orientation: 'auto',
    });

    const self = this;
    element.on('changeDate', function () {
      self.trigger('change:date');
    });
  }

  onClose() {
    this.ui.agentSelect.select2('close');
  }

  initializeAgentSelector() {
    const select = this.ui.agentSelect;

    select.val(this.model.agentSelection()).select2({
      containerCssClass: "custom-select2",
      formatNoMatches(term: Array<string>) {
        if (!_.isEmpty(term)) {
          return I18n.t('webapp.reports.topic.no_matches');
        } else {
          return I18n.t('webapp.reports.topic.limit_exceeded');
        }
      }
    });
    select2CloseOnBlur(select);
    select.prevObject.keyup(KeypressCallbacks.blurOnEscape);

    this.updateAgentOptions();
  }

  updateAgentOptions() {
    const serializedData = this.serializeAgentSelectData();
    const html = Marionette.Renderer.render(agentOptionsTemplate, serializedData);

    this.ui.agentSelect.html(html);
    this.ui.agentSelect.select2(serializedData.selectState).trigger('change');
  }

  serializeAgentSelectData() {
    return {
      optGroups: this.model.get('agentOptions'),
      selectState: "enable"
    };
  }

  disableSubmitButton() {
    this.ui.submitButton.addClass('is-loading');
  }

  enableSubmitButton() {
    this.ui.submitButton.removeClass('is-loading');
  }

  pmgClicked() {
    let checkbox = this.ui.pmgCheckbox[0]
    checkbox.checked = !checkbox.checked
    this.trigger('change:pmg')
  }

  filePickerChanged() {
    this.file = this.ui.fileUpload[0].files[0];
    this.trigger('change:file');
  }

  avFilePickerChanged() {
    this.avFile = this.ui.avFileUpload[0].files[0];
    this.trigger('change:avFile');
  }

  setupPMGDropZone() {
    this.setupDropZone(this.$el, this._ondropFile.bind(this));
  }

  setupDropZone(element: JQuery, ondrop: (file: File) => void) {
    let eventCounter = 0;
    let removeDropzoneClass = () => {
      element.removeClass('dragdrop--focus');
    }
    element.on('dragenter', () => {
      if (this.dragdropDisabled) { return false; }
      element.addClass('dragdrop--focus');
      eventCounter++;
      return false;
    });
    element.on('dragleave', () => {
      if (this.dragdropDisabled) { return false; }
      eventCounter--;
      if (eventCounter <= 0) {
        removeDropzoneClass();
      }
      return false;
    });
    const eventName: any = 'drop'
    element.on(eventName, (event: BootstrapDragEvent) => {
      event.preventDefault();
      if (this.dragdropDisabled) { return false; }
      removeDropzoneClass();
      eventCounter = 0;
      let file = event.originalEvent.dataTransfer.files[0];
      ondrop(file);
    });
    element.on('dragover', () => { return false; });
  }

  _ondropFile(file: File) {
    this.file = file;
    this.trigger('change:file');
  }

  _ondropAvFile(avFile: File) {
    this.avFile = avFile;
    this.trigger('change:avFile');
  }

  renderEditableTitle(props: IEditableDetailTitleViewOptionalProps) {
    const fullProps = _.extend({}, this.titleProps, props)
    this.titleProps = fullProps
    const editableDetailTitleView = <EditableDetailTitleView {...fullProps}/>
    ReactDOM.render(editableDetailTitleView, this.ui.editableTitle[0]);
  }
}

ClipCreationFormView.prototype.template = template;
ClipCreationFormView.prototype.ui = {
    pmgButton: '[data-pmg-clip]',
    onlineButton: '[data-online-clip]',
    fullTextButton: '[data-full-text-clip]',
    pdfButton: '[data-pdf-clip]',
    avButton: '[data-audio-video-clip]',
    urlInput: '[data-url]',
    videoUrlInput: '[data-video-url]',
    urlBox: '[data-url-box]',
    pdfBox: '[data-pdf-box]',
    rtvBox: '[data-rtv-box]',
    pageNumberBox: '[data-page-number-box]',
    dateInput: '[data-date]',
    dateBox: '[data-date-box]',
    oldTitleFormItem: '[data-old-title-form-item]',
    newTitleFormItem: '[data-new-title-form-item]',
    titleTextInputs: '[data-title-text-inputs]',
    titleTextPreloader: '[data-title-text-preloader]',
    titleInput: '[data-title]',
    textInput: '[data-text]',
    textInputLabel: '[data-text-label]',
    editableTitle: '[data-editable-detail-title]',
    pageNumberInput: '[data-page-number]',
    authorInput: '[data-author]',
    authorBox: '[data-author-box]',
    agentSelect: '#agentSelect',
    agentsBox: '[data-agents-box]',
    fileUpload: '#file-upload',
    pmgFileUpload: '#pmg-file-upload',
    avFileUpload: '#audio-video-upload',
    filePath: '#filepath',
    avPath: '#avpath',
    attachmentsList: '[data-attachments-list]',
    attachments: '[data-attachments-box]',
    fileDropZone: '[data-pdf-box]',
    avFileDropZone: '[data-rtv-dropzone]',
    attachmentsDropZone: '[data-attachments-box]',
    fileButton: '[data-file-button]',
    pmgCheckbox: '[data-pmg-checkbox]',
    submitButton: '[data-add-clip]',

    urlError: '[data-url-error]',
    urlErrorContent: '[data-url-error-content]',

    fileError: '[data-file-error]',
    fileErrorContent: '[data-file-error-content]',

    avError: '[data-av-error]',
    avErrorContent: '[data-av-error-content]',

    attachmentError: '[data-attachment-error]',
    attachmentErrorContent: '[data-attachment-error-content]',

    dateError: '[data-date-error]',
    dateErrorContent: '[data-date-error-content]',

    sourceError: '[data-source-error]',
    sourceErrorContent: '[data-source-error-content]',
    sourceBox: '[data-source-box]',

    titleError: '[data-title-error]',
    titleErrorContent: '[data-title-error-content]',

    agentsError: '[data-agents-error]',
    agentsErrorContent: '[data-agents-error-content]',
    uploadInProgress: '[data-uploading]',
    tagBox: '[data-tag-box]',
    addResult: '[data-add-result]',
    separator: '[data-separator]'
}

ClipCreationFormView.prototype.regions = {
    sourceSearchForm: '[data-source-search-form]',
    richTextEditor: '[data-rich-text-editor]',
    tagsPartial: '[data-tags-partial]',
    pmgView: '[data-pmg-view]',
    pmgLicensing: '[data-pmg]'
}

ClipCreationFormView.prototype.triggers = {
    'click [data-pmg-clip]': 'clicked.pmgClip',
    'click [data-online-clip]': 'clicked.onlineClip',
    'click [data-full-text-clip]': 'clicked.fullTextClip',
    'click [data-pdf-clip]': 'clicked.pdfClip',
    'click [data-audio-video-clip]': 'clicked.rtvClip',
    'click [data-add-clip]': 'clicked.addClip',
    'change #agentSelect': 'change:agentSelect',
    'change [data-url]': 'change:url',
    'change [data-video-url]': 'change:videoUrl',
    'change [data-date]': 'change:date',
    'change [data-text]': 'change:text',
    'change [data-page-number]': 'change:pageNumber',
    'change [data-author]': 'change:author'
}

ClipCreationFormView.prototype.events = {
    'change #file-upload': 'filePickerChanged',
    'change #audio-video-upload': 'avFilePickerChanged',
    'blur [data-editable-title]': 'assignNewTitle',
    'click [data-pmg]': 'pmgClicked',
}


export default ClipCreationFormView;
