import * as React from "react"
import Sidebar from 'components/sidebar/sidebar'
import SidebarNavItemTitle from 'components/sidebar/sidebarNavItemTitle'
import I18n from "i18n"
import session from 'models/sessionInstance'

interface SettingsSidebarProps {
  showContacts: boolean
  showTags: boolean
  showSourceCategory: boolean
  onUsersClick: () => void
  onContactsClick: () => void
  onTagsClick: () => void
  onNotificationsClick: () => void
  onSourceCategoryClick:() => void
  onPersonsClick: () => void
  onOrganizationsClick: () => void
  onLocationsClick: () => void
  onBusinessesClick: () => void
  onLawsClick: () => void
  isUsersActive: boolean
  isNotificationsActive: boolean
  isContactsActive: boolean
  isTagsActive: boolean
  isSourceCategoryActive: boolean
  isPersonsActive: boolean
  isOrganizationsActive: boolean
  isLocationsActive: boolean
  isBusinessesActive: boolean
  isLawsActive: boolean
  showKnowledgebaseManagement: boolean
}

class SettingsSidebar extends React.Component<SettingsSidebarProps> {
  contacts() {
    if (this.props.showContacts) {
      return (
        <SidebarNavItemTitle
          key="contacts"
          onClick={() => this.props.onContactsClick()}
          isActive={this.props.isContactsActive}
        >
          <span>{I18n.t('webapp.settings.sidebar.mailing_list')}</span>
        </SidebarNavItemTitle>
      )
    }
  }

  tags() {
    if (this.props.showTags) {
      return (
        <SidebarNavItemTitle
          onClick={() => this.props.onTagsClick()}
          key="tags"
          isActive={this.props.isTagsActive}
        >
          <span>{I18n.t('webapp.settings.sidebar.tags')}</span>
        </SidebarNavItemTitle>
      )
    }
  }

  users() {
    return (
      <SidebarNavItemTitle
        onClick={() => this.props.onUsersClick()}
        key="users"
        isActive={this.props.isUsersActive}
      >
        <span>{I18n.t('webapp.settings.sidebar.users')}</span>
      </SidebarNavItemTitle>
    )
  }

  notifications() {
    return (
      <SidebarNavItemTitle
        key="notifications"
        onClick={() => this.props.onNotificationsClick()}
        isActive={this.props.isNotificationsActive}
      >
        <span>{I18n.t('webapp.settings.sidebar.notifications')}</span>
      </SidebarNavItemTitle>
    )
  }

  sourceCategory() {
    if (this.props.showSourceCategory) {
      return (
        <SidebarNavItemTitle
          onClick={() => this.props.onSourceCategoryClick()}
          key="sourceCategory"
          isActive={this.props.isSourceCategoryActive}
        >
          <span>{I18n.t('webapp.agents.edit.settings.source_category_button')}</span>
        </SidebarNavItemTitle>
      )
    }
  }

  separator() {
    return (
      <li key="sep" className="nav-tree__item nav-tree__item--separator"></li>
    )
  }

  kbHeader() {
    return (
      <SidebarNavItemTitle clickable={false} key="header">
        <span>{I18n.t('webapp.settings.sidebar.knowledgebase.header')}</span>
      </SidebarNavItemTitle>
    )
  }

  kbPersons() {
    return (
      <SidebarNavItemTitle
        key="persons"
        onClick={() => this.props.onPersonsClick()}
        isActive={this.props.isPersonsActive}
      >
        <span>{I18n.t('webapp.settings.sidebar.knowledgebase.persons')}</span>
      </SidebarNavItemTitle>
    )
  }

  kbOrganizations() {
    return (
      <SidebarNavItemTitle
        key="organizations"
        onClick={() => this.props.onOrganizationsClick()}
        isActive={this.props.isOrganizationsActive}
      >
        <span>{I18n.t('webapp.settings.sidebar.knowledgebase.organizations')}</span>
      </SidebarNavItemTitle>
    )
  }

  kbLocations() {
    return (
    <SidebarNavItemTitle
      key="locations"
      onClick={() => this.props.onLocationsClick()}
      isActive={this.props.isLocationsActive}
      >
        <span>{I18n.t('webapp.settings.sidebar.knowledgebase.locations')}</span>
      </SidebarNavItemTitle>
    )
  }

  kbBusinesses() {
    return (
    <SidebarNavItemTitle
      key="businesses"
      onClick={() => this.props.onBusinessesClick()}
      isActive={this.props.isBusinessesActive}
      >
        <span>{I18n.t('webapp.settings.sidebar.knowledgebase.businesses')}</span>
      </SidebarNavItemTitle>
    )
  }

  kbLaws() {
    return (
    <SidebarNavItemTitle
      key="laws"
      onClick={() => this.props.onLawsClick()}
      isActive={this.props.isLawsActive}
      >
        <span>{I18n.t('webapp.settings.sidebar.knowledgebase.laws')}</span>
      </SidebarNavItemTitle>
    )
  }

  kbManagement() {
    if (this.props.showKnowledgebaseManagement) {
      return [
        this.separator(),
        this.kbHeader(),
        this.kbPersons(),
        this.kbOrganizations(),
        this.kbLocations(),
        this.kbBusinesses(),
        this.kbLaws()
      ]
    } else {
      return []
    }
  }

  render() {
    const base = [
      this.users(),
      this.notifications(),
      this.contacts(),
      this.tags(),
      this.sourceCategory()
    ]
    return (<Sidebar>{base.concat(this.kbManagement())}</Sidebar>)
  }
}

export default SettingsSidebar
