import _ from 'underscore'
function AutomaticAppendTracker(numberOfAutomaticAppends) {
  if (!_.isNumber(numberOfAutomaticAppends)) {
    numberOfAutomaticAppends = 3;
  }

  var counter = 0;

  this.doAutomaticAppendNow = function () {
    return counter < numberOfAutomaticAppends;
  };

  this.recordAppend = function () {
    if (counter >= numberOfAutomaticAppends) {
      counter = 0;
    } else {
      counter += 1;
    }
  };
}

export default AutomaticAppendTracker;

