import Marionette from 'backbone.marionette';
import _ from 'underscore';
import SharedAgentSettingsController from
    'controllers/agents/sharedAgentSettingsController';
import EditAgentController from 'controllers/agents/editAgentController';
import session from 'models/sessionInstance';

class AgentSettingsLayoutController extends Marionette.Controller {
    initialize(options) {
        this.showLeftHandContainer  = options.showLeftHandContainer;
        this.showRightHandContainer = options.showRightHandContainer;
        this.agent                  = options.agent;
        this._createAndShowController();
    }

    onClose() {
        if (!_.isUndefined(this.controller)) {
            this.controller.close();
        }
    }

    _createAndShowController() {
        let Controller;

        if (this.agent.isOwnAgent() ||
            session.isGroupAdmin()) {
            Controller = EditAgentController;
        } else {
            Controller = SharedAgentSettingsController;
        }

        this.controller = new Controller({
            agent: this.agent,
            showRightHandContainer: this.showRightHandContainer
        });

        this.listenTo(this.controller, 'deleted:agent', () => {
            this.trigger('deleted:agent');
        });

        this.showLeftHandContainer(this.controller.view);
    }

    handleRightColumnClosed() {}
}

export default AgentSettingsLayoutController;
