import Marionette from 'backbone.marionette';
import ReaderPresentationOptionsView
    from 'views/newsroom/readerPresentationOptionsView';

class ReaderPresentationOptionsController extends Marionette.Controller {
    initialize(options) {
        this.configuration = options.configuration;
        this.view =
            new ReaderPresentationOptionsView({configuration: this.configuration});
        this._registerListeners();
    }

    _registerListeners() {
        this.view.on('button:clicked',
                     this._handlePresentationOptionsChange,
                     this);
    }

    _handlePresentationOptionsChange() {
        this.configuration.set(this.view.data());
        this.configuration.save();
    }

}

export default ReaderPresentationOptionsController;
