import Config from 'libraries/configuration';
import Backbone from 'backbone';
import _ from 'underscore';
import SourceExclusion from 'models/sourceExclusion';

class SourceExclusions extends Backbone.Collection {
    initialize(sources, options) {
        if (_.isObject(options)) {
            this.agentId = options.agentId;
        }
    }
    url() {
        return Config.apiUrl + "agents/" + this.agentId + "/exclude_source/";
    }
    parse(response) {
        return response.collection;
    }
}

SourceExclusions.prototype.model = SourceExclusion;

export default SourceExclusions;
