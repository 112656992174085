import _ from 'underscore'
import Backbone from 'backbone'

var EditTitleSummary = Backbone.Model.extend({
  identifier: 'editTitleSummary',
  initialize: function () {
    var report = this.get('report');

    this.set({
      title:    report.get('title'),
      summary:  report.get('summary'),
      editable: report.get('editable')
    });

    this.on('change:title', () => {
      this.get('report').set({title: this.get('title')});
      this.checkForCompleteness();
    });
    this.on('change:summary', () => {
      this.get('report').set({summary: this.get('summary')});
      this.checkForCompleteness();
    });
    this.on('change:editable', () => {
      this.get('report').set({editable: this.get('editable')});
      this.checkForCompleteness();
    });

    this.checkForCompleteness();
  },

  data: function () {
    return {
      title:    this.get('title'),
      summary:  this.get('summary'),
      editable: this.get('editable')
    };
  },

  checkForCompleteness: function () {
    var title = this.get('title');
    var titlePresent = _.isString(title) && !_.isEmpty(title);
    var complete = titlePresent;
    this.set({complete: complete});
  }
});

export default EditTitleSummary;

