import Marionette from 'backbone.marionette';
import session from 'models/sessionInstance';
import $ from 'jquery';
import _ from 'underscore';
import I18n from 'i18n';
import template from 'text-loader!templates/settings/userSettings.html';
import DeleteUserModalView from 'views/settings/deleteUserModalView';
import ReactWrapper from 'views/marionetteReactWrapper'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Dropdown from 'components/dropdown'

class UserSettingsView extends Marionette.Layout {
    initialize(options) {
        this.model = options.model;
        this.listenTo(this.model, 'change:male', () => {
            this._salutationChanged();
        });
    }

    onRender() {
        this._salutationChanged();

        let locale = this.model.get("locale") || session.get("locale");
        this._setLocale(locale);
        this._showLocalePicker()
        this.hideEmailError();
        this.hideFirstNameError();
        this.hideLastNameError();
        this.hidePasswordError();
        this.disableSaveButton();
    }

    saveClicked() {
        if (this.saveButtonEnabled) {
            this.performSave();
        }
    }

    _handleLoginAs() {
        const user = this.model;

        if (session.canLoginAs() && user.canBeLoggedInAs()) {
            this.ui.loginAsButton.addClass('is-disabled');
            this.ui.loginAsButton.prop('disabled', true);
            this.trigger('login-as:user', user.get('login'));
        }
    }

    performSave() {
        let attributes = {
            first_name: this.ui.firstName.val(),
            last_name: this.ui.lastName.val(),
            email: this.ui.email.val(),
            male: this.male(),
            locale_override: this.locale
        };
        if (this.ui.password.length > 0 && this.ui.password.val().length > 0) {
            attributes.password = this.ui.password.val();
            attributes.password_confirmation = this.ui.password_confirmation.val();
        }
        this.trigger("save", attributes);
    }

    male() {
        return this.ui.male.hasClass('is-active');
    }

    showReloadWarning() {
        this.ui.reloadWarning.removeClass('hidden');
    }

    hideReloadWarning() {
        this.ui.reloadWarning.addClass('hidden');
    }

    _salutationChanged() {
        if (this.model.get('male')) {
            this._setMale();
        } else {
            this._setFemale();
        }
    }

    serializeData() {
        let user = this.model;
        return {
            canLoginAs: session.canLoginAs() && user.canBeLoggedInAs(),
            login: user.get('login'),
            firstName: user.get('first_name'),
            lastName: user.get('last_name'),
            male: user.get('male'),
            email: user.get('email'),
            available_languages: this._availableLanguages(),
            password_change_allowed: this._password_change_allowed()
        };
    }

    _setMale() {
        this._deactivateSalutation();
        this.ui.male.addClass('is-active');
    }

    _setFemale() {
        this._deactivateSalutation();
        this.ui.female.addClass('is-active');
    }

    _deactivateSalutation() {
        this.$("[data-salutation-male]").removeClass('is-active');
        this.$("[data-salutation-female]").removeClass('is-active');
    }

  _showLocalePicker() {
        let options = this._availableLanguages().map(language => {
            return {text: language.language, value: language.locale}
        })
        let selectedOption = this.locale
        this.localePickerView = new ReactWrapper({
          getComponent: () => {
            return <Dropdown options={options}
                             selectedOption={selectedOption}
                             onOptionChange={(newOption) => this._onLocaleChange(newOption)} />
          }
        })
        this.localePicker.show(this.localePickerView)
    }

    _onLocaleChange(newOption) {
        this._setLocale(newOption)
        this.trigger('locale:change', newOption)
    }

    _showDeleteModal(event) {
        event.preventDefault();
        this.deleteModalView = new DeleteUserModalView({
            user: this.model,
            new_search_agent_management: session.hasFeature("new_search_agent_management")
        });
        this.listenTo(this.deleteModalView, 'modal:confirmed', () => {
            this.trigger('delete:user');
        })
        this.deleteModal.show(this.deleteModalView)
        this.deleteModalView.showModal();
    }

    _setLocale(locale) {
        this.locale = locale;
    }

    _availableLanguages() {
        return session.get("available_languages");
    }
    _password_change_allowed () {
        return session.get("password_change_allowed");
    }

    showEmailError(text) {
        if (_.isFunction(this.ui.emailError.show)) {
            this.ui.emailError.show();
            this.ui.emailErrorContent.html(text);
        }
    }

    hideEmailError() {
        this.ui.emailError.hide();
    }

    showFirstNameError(text) {
        if (_.isFunction(this.ui.firstNameError.show)) {
            this.ui.firstNameError.show();
            this.ui.firstNameErrorContent.html(text);
        }
    }

    hideFirstNameError() {
        if (_.isFunction(this.ui.firstNameError.hide)) {
            this.ui.firstNameError.hide();
        }
    }

    showLastNameError(text) {
        if (_.isFunction(this.ui.lastNameError.show)) {
            this.ui.lastNameError.show();
            this.ui.lastNameErrorContent.html(text);
        }
    }

    hideLastNameError() {
        if (_.isFunction(this.ui.lastNameError.show)) {
            this.ui.lastNameError.hide();
        }
    }

    showPasswordError(text) {
        if (_.isFunction(this.ui.passwordError.show)) {
            this.ui.passwordError.show();
            this.ui.passwordErrorContent.html(text);
        }
    }

    hidePasswordError() {
        if (_.isFunction(this.ui.passwordError.show)) {
            this.ui.passwordError.hide();
        }
    }

    enableSaveButton() {
        this.$(this.ui.saveButton).removeClass('is-disabled');
        this.saveButtonEnabled = true;
    }

    disableSaveButton() {
        this.$(this.ui.saveButton).addClass('is-disabled');
        this.saveButtonEnabled = false;
    }

    getEmailValue() {
        if (_.isFunction(this.ui.email.val)) {
            return this.ui.email.val();
        }
    }

    getFirstNameLength() {
        if (_.isFunction(this.ui.firstName.val)) {
            return this.ui.firstName.val().length;
        }
    }

    getLastNameLength() {
        if (_.isFunction(this.ui.lastName.val)) {
            return this.ui.lastName.val().length;
        }
    }

    getPassword() {
        if (_.isFunction(this.ui.password.val)) {
            return this.ui.password.val();
        }
    }

    getPasswordConfirmation() {
        if (_.isFunction(this.ui.password_confirmation.val)) {
            return this.ui.password_confirmation.val();
        }
    }

    onClose() {
        if (!_.isUndefined(this.deleteModalView)) {
            this.deleteModalView.close();
        }
    }
}

UserSettingsView.prototype.saveButtonEnabled = true;
UserSettingsView.prototype.template = template;
UserSettingsView.prototype.triggers = {
    'click [data-salutation-male]': 'salutation:changed:male',
    'click [data-salutation-female]': 'salutation:changed:female',
    'click [data-reset]': 'reset:clicked',
    'keyup [data-email]': 'email:changed',
    'keyup [data-first-name]': 'firstName:changed',
    'keyup [data-password]': 'password:new',
    'keyup [data-password-confirmation]': 'password:new',
    'focusin [data-first-name]': 'firstName:changed',
    'keyup [data-last-name]': 'lastName:changed',
    'focusin [data-last-name]': 'lastName:changed'
};
UserSettingsView.prototype.ui = {
    male: '[data-salutation-male]',
    female: '[data-salutation-female]',
    firstName: 'input[data-first-name]',
    lastName: 'input[data-last-name]',
    email: 'input[data-email]',
    password: 'input[data-password]',
    password_confirmation: 'input[data-password-confirmation]',
    passwordError: '[data-password-error]',
    passwordErrorContent: '[data-password-error-content]',
    selectedLanguage: '[data-selected-language]',
    languageDropdownBody: '[data-language-dropdown-body]',
    reloadWarning: '[data-reload-warning]',
    emailError: '[data-email-error]',
    emailErrorContent: '[data-email-error-content]',
    firstNameError: '[data-first-name-error]',
    firstNameErrorContent: '[data-first-name-error-content]',
    lastNameError: '[data-last-name-error]',
    lastNameErrorContent: '[data-last-name-error-content]',
    saveButton: '[data-save]',
    loginAsButton: '[data-login-as-user]',
};
UserSettingsView.prototype.events = {
    'click [data-save]': 'saveClicked',
    'click [data-delete-user]': '_showDeleteModal',
    'click [data-language-dropdown-body]': '_handleLanguageChange',
    'click [data-login-as-user]': '_handleLoginAs',
};

UserSettingsView.prototype.regions = {
    deleteModal: '[data-delete-modal]',
    localePicker: '[data-locale-picker]'
}

export default UserSettingsView;
