import Backbone from 'backbone';
import User from 'models/user';
import session from 'models/sessionInstance';

class Users extends Backbone.Collection {
    initialize() {
        this.on('changed:currentUser', () => {
            this.sort();
        });
    }
    setCurrentUser() {
        let currentUserId = session.get('user').id;
        this.currentUser = this.get(currentUserId);

        this.trigger('changed:currentUser');
    }
    comparator(first, second) {
        let self = this;
        let isCurrentUser = function (user) {
            if (self.currentUser) {
                return self.currentUser.id === user.id;
            } else {
                return false;
            }
        };

        // current user is always at the front of the collection
        if (isCurrentUser(first)) {
            return -1;
        } else if (isCurrentUser(second)) {
            return 1;
        } else {
            // other users are sorted by full name
            let firstName = first.get('full_name');
            let secondName = second.get('full_name');

            if (firstName < secondName) {
                return -1;
            } else if (firstName > secondName) {
                return 1;
            } else {
                return 0;
            }
        }
    }
    parse(response) {
        return response.collection;
    }
}

Users.prototype.model = User;
Users.prototype.url = "/api/v3/users";

export default Users;
