import $ from 'jquery';
import 'froala';

function addFroalaHorizontalRowButton() {
    // Froala custom buttons: Horizontal row
    $.FroalaEditor.DefineIcon('hrButton', {NAME: 'minus'});
    $.FroalaEditor.RegisterCommand('hrButton', {
        title: 'Horizontal Row',
        icon: 'hrButton',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function() {
            this.html.insert('<hr class="horizontal-row" />');
        },
    });

}

export default addFroalaHorizontalRowButton;
