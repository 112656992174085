import Marionette from 'backbone.marionette'
import topicListItemTemplate from 'text-loader!templates/agents/topicListItem.html'

class TopicListItemView extends Marionette.ItemView {
  className() {
    let className = "button-group__item button--pointing topic";
    return className += " topic-" + this.model.get('id');
  }

  initialize() {
    this.listenTo(this.model, 'change', this.render);
  }

  serializeData() {
    return {
      name: this.model.get('topic_name') || this.model.get('name'),
      id: this.model.get('id')
    };
  }
}
TopicListItemView.prototype.template = topicListItemTemplate;
TopicListItemView.prototype.tagName = "a";
TopicListItemView.prototype.triggers = {
  'click': 'topic:clicked'
};

export default TopicListItemView;
