import _ from 'underscore'
import $ from 'jquery'
import Deferred from 'libraries/deferred'
import OptionsProvider from 'models/globalSearch/optionsProvider'
import validateAgentResultQuery from 'models/validateAgentResultQuery'

function isPresent(term) {
  return !_.isEmpty($.trim(term));
}

export default class GlobalSearchKeywordOptionsProvider extends OptionsProvider {
  results(term) {
    if (isPresent(term)) {
      term = term.replace(/:/, '')
      return validateAgentResultQuery(term).then(function (valid) {
        if (valid) {
          return {
            text: term,
            type: "keyword",
            id: "keyword:" + term,
            priority: 4
          };
        }
      });
    } else {
      var deferred = Deferred.build();
      deferred.resolve();
      return deferred.promise();
    }
  }
}

