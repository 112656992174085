import Backbone from 'backbone'
import users from 'collections/usersInstance'
import agents from 'collections/agentsInstance'
import tags from 'collections/tagsInstance'
import contacts from 'collections/contactsInstance'
import contactGroups from 'collections/contactGroupsInstance'
import $ from 'jquery'
import _ from 'underscore'
import session from 'models/sessionInstance'
import 'libraries/formatDate'

var Report = Backbone.Model.extend({
  defaults: {
    clip_types_to_keep: ['online', 'social_media', 'radio_tv', 'print'],
    group_by: 'topic',
    order_by: 'publish_time',
    has_print_clips_in_full_length: false,
    editable: false
  },
  initialize: function () {
    this.setDefaults();
    _.bindAll(this, 'poll');
  },
  setDefaults: function () {
    this.setDefaultTimespan();

    if (session.hasPrintClipsInReports() && this.isNew()) {
      this.set({clip_types_to_keep: ['radio_tv', 'print', 'online', 'social_media']});
    }

    if (_.isUndefined(this.get('include_clips_from_several_sources'))) {
      this.set({include_clips_from_several_sources: false}); // Default is false
    }

    var features = session.get("features");
    if (_.isUndefined(this.get('has_chart'))) {
      if (features.reports_graph) {
        this.set({has_chart: true});
      } else {
        this.set({has_chart: false});
      }
    }
    if (_.isUndefined(this.get('has_clip_snippets'))) {
      if (features.reports_snippets) {
        this.set({has_clip_snippets: true});
      } else {
        this.set({has_clip_snippets: false});
      }
    }

    if (this.isExcel() &&
        session.hasFeature('make_only_datasheet_default_in_reports')) {
      this.set({only_datasheet: true})
    }
  },
  setDefaultTimespan: function () {
    var todaysDate = (new Date()).getDate();
    if (_.isUndefined(this.get('start_date'))) {
      var start_date = new Date();
      start_date.setDate(todaysDate - 7);
      this.set({start_date: start_date});
    }
    if (_.isUndefined(this.get('end_date'))) {
      var end_date = new Date();
      end_date.setDate(todaysDate);
      this.set({end_date: end_date});
    }
  },
  open: function () {
    this.trigger('open', this);
  },
  edit: function () {
    this.trigger('edit', this);
  },
  topicDescription: function () {
    var topics = this.get('topics');

    if (this.isClipTopic()) {
      return 'Ausgewählte Treffer';
    } else if (this.isTagTopic()) {
      return _.map(topics, function (topic) {
        return topic.visibleName();
      }).join(', ');
    } else {
      return _.map(topics, function (topic) {
        return topic.get('name');
      }).join(', ');
    }
  },
  isNewsletter: function () {
    return this.get('type') === 'Newsletter';
  },
  isExcel: function () {
    return this.get('type') === 'ExcelReport';
  },
  isEditable: function () {
    var isOwner         = this.get('is_owner');
    var isSetAsEditable = this.get('editable');
    return isOwner || isSetAsEditable;
  },
  isPreviewable: function () {
    var hasFile = this.get('file_available');
    var hasPreviewLink = _.isString(this.get('preview_link'));
    return hasFile && hasPreviewLink;
  },
  isClipTopic: function () {
    return this.get('topic_type') === 'clip';
  },
  isTagTopic: function () {
    return this.get('topic_type') === 'tag';
  },
  toJSON: function () {
    var json = {};
    // attributes need to be wrapped in a 'report' key for the API
    var full_json = Backbone.Model.prototype.toJSON.call(this);

    // keep only the relevant attributes, we're assigning
    // topics in parse(), other listed attributes are read-only
    // the API does not care for these
    var attributes_to_filter = [
      'creator', 'updater', 'topics', 'is_owner',
      'updater_name', 'creator_name', 'download_link', 'file_available',
      'created_at', 'updated_at'
    ];
    json.report = this.filterJSONAttributes(full_json, attributes_to_filter);

    // default toString for date objects does not make the API happy
    json.report.start_date = this.formatDateForAPI(json.report.start_date);
    json.report.end_date   = this.formatDateForAPI(json.report.end_date);

    // formats created_at/updated_at in seconds, the same way we get them from the API
    // note that they will not be present while creating a report
    if (json.report.created_at) {
      json.report.created_at = json.report.created_at.getTime() / 1000;
    }
    if (json.report.updated_at) {
      json.report.updated_at = json.report.updated_at.getTime() / 1000;
    }

    if (json.report.recipients) {
      json.report.recipients = _.map(json.report.recipients, function (recipient) {
        return {
          type: recipient.type,
          id: recipient.get('id')
        };
      });
    }
    return json;
  },
  filterJSONAttributes: function (json, attributes_to_filter) {
    var newjson = _.clone(json);
    _.each(attributes_to_filter, function (key) {
      delete newjson[key];
    });

    return newjson;
  },
  formatDateForAPI: function (date) {
    return date.format('YYYY-MM-DD');
  },
  urlRoot: "/api/v3/reports/",
  parse: function (response) {
    // /reports returns an array of reports, while /reports/1
    // returns the report nested in a 'object' key
    var report = response;
    if (response.object) {
      report = response.object;
    }

    var transformations = [
      this.transformTimesInServerResponse,
      this.setCreatorUpdaterAttributes,
      this.setTopics,
      this.transformRecipients
    ];

    var transform = _.compose.apply(this, transformations);

    return transform(report);
  },
  transformTimesInServerResponse: function (response) {
    response.start_date = new Date(response.start_date);
    response.end_date = new Date(response.end_date);
    response.created_at = new Date(response.created_at * 1000);
    response.updated_at = new Date(response.updated_at * 1000);
    return response;
  },
  setCreatorUpdaterAttributes: function (attrs) {
    attrs.creator = users.get(attrs.creator_id);
    attrs.updater = users.get(attrs.updater_id);

    return attrs;
  },
  setTopics: function (attrs) {
    var topic_ids  = attrs.topic_ids;
    var topic_type = attrs.topic_type;

    // pick right collection
    var collection = [];
    if (topic_type === 'agent') {
      collection = _.filter(agents.models, function (agent) {
        return agent.get('shared') ||
          (agent.get('user_id') === users.currentUser.id);
      });
    } else if (topic_type === 'tag') {
      collection = tags.models;
    }

    if (!_.isEmpty(collection)) {
      // transform id list into a list of names
      attrs.topics = _.compact(_.map(topic_ids, function (topic_id) {
        return _.find(collection, function (element) {
          return (element.get('id') === topic_id);
        });
      }));
    } else {
      attrs.topics = [];
    }

    return attrs;
  },
  transformRecipients: function (response) {
    if (response.recipients) {
      response.recipients = _.map(response.recipients, function (recipient) {
        if (recipient.type === 'contact') {
          return contacts.get(recipient.id);
        } else {
          return contactGroups.get(recipient.id);
        }
      });
    }
    return response;
  },
  preview: function (enablePreview) {
    if (this.isPreviewable()) {
      this.trigger('preview', this);
      enablePreview();
    } else {
      this.fetch({
        success: function (report) {
          if (report.isPreviewable()) {
            report.trigger('preview', report);
          } else {
            report.trigger('previewNotReady', report);
          }
          enablePreview();
        },
        error: function (report) {
          report.trigger('previewFailure');
          enablePreview();
        }
      });
    }
  },
  deliver: function (onSuccess) {
    this.trigger('deliver', this, onSuccess);
  },
  approximateClipCount: function (formData, onSuccess) {
    if (formData.topic_type) {
      if ((formData.clip_types_to_keep !== undefined) && (formData.clip_types_to_keep.length === 0)) {
        onSuccess(0);
      } else {
        var parameters = {
          topic_ids: formData.topic_ids,
          topic_type: formData.topic_type,
          options: {
            clip_types_to_keep: formData.clip_types_to_keep
          }
        };
        if (formData.start_date) {
          parameters.options.start_date = this.formatDateForAPI(formData.start_date);
        }
        if (formData.end_date) {
          parameters.options.end_date = this.formatDateForAPI(formData.end_date);
        }
        $.ajax({
          url: this.urlRoot + 'approximate_clip_count/',
          data: parameters,
          type: 'GET',
          dataType: 'json',
          context: this,
          success: function (response) {
            var clipCount = response.approximate_clip_count;
            onSuccess(clipCount);
          }
        });
      }
    }
  },
  store: function () {
    this.stored_attributes = _.clone(this.attributes);
  },
  restore: function () {
    this.set(this.stored_attributes);
  },
  startPolling: function () {
    if (!this.pollingEnabled) {
      this.pollingEnabled = true;
      this.poll();
    }
  },
  stopPolling: function () {
    this.pollingEnabled = false;
  },
  pollInterval: 2000,
  poll: function () {
    if (this.pollingEnabled) {
      this.updateReportUrl();
      window.setTimeout(this.poll, this.pollInterval);
    }
  },
  updateReportUrl: function () {
    var url = '/api/v3/' + this.get('download_link');
    $.ajax({
      url: url,
      type: 'GET',
      context: this,
      dataType: 'text', // make sure jQuery does not try to parse
      success: function (data, textStatus, XMLHttpRequest) {
        switch (XMLHttpRequest.status) {
        case 201:
          var locationHeader = XMLHttpRequest.getResponseHeader('Location');
          this.set('report_url', locationHeader);
          this.stopPolling();
          break;

        case 202:
          this.set('report_url', null);
        }
      },
      error: function () {
        this.trigger('download_failed');
        this.stopPolling();
      }
    });
  }
});

export default Report;

