import $ from 'jquery';
import 'froala';

function addFroalaHighlightButton() {
    // Froala custom buttons: Highlight
    $.FroalaEditor.DefineIcon('highlightButton', {NAME: 'star'});
    $.FroalaEditor.RegisterCommand('highlight', {
        title: 'Highlight',
        icon: 'highlightButton',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        refresh: function (a) {
            let b = this.format.is('span')
            a.toggleClass('fr-active', b)
                .attr('aria-pressed', b)
        },
        callback: function() {
            if (this.format.is('span')) {
                this.format.remove('span')
            } else {
                this.format.toggle(
                    'span',
                    { class: "clip-detail__highlight manual"}
                )
            }

        }
    });
}

export default addFroalaHighlightButton;
