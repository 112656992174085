import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'underscore';
import I18n from 'i18n';
import agentsInstancePromise from 'models/agentsInstancePromise';
import session from 'models/sessionInstance';

interface IAgentListProps {
  onCreateAgent: () => void
  onSubscribeAgent: (agent_id: number) => void
  agents: Agent[]
  users: any
}

interface Agent {
  id: number;
  name: string;
  shared: Boolean;
  searchActive: Boolean;
  userId: number;
}

interface State {
  filter: string
}

export default class AgentsListView extends React.Component<IAgentListProps, State> {
  constructor(props: IAgentListProps) {
    super(props)
    this.state = {
      filter: ''
    }
  }

  render() {
    return (
      <div className="lightened-container lifted-container pal">
        {this.title()}
        {this.createAgentButton()}
        <div className="ptl"></div>
        {this.agentsList()}
      </div>
    )
  }

  title() {
    return (
      <div className="mbl">
        <h2 className="heading-xxlarge" >
          {I18n.t("webapp.agents.agents_list.title")}
        </h2>
      </div>
    )
  }

  createAgentButton() {
    if (!session.agentLimitExceeded()) {
      return (
        <div>
          <button className="button--block align-left" onClick={this.props.onCreateAgent}>
            <strong>
              <i className="icon-plus"></i> {I18n.t("webapp.agents.agents_list.add_agent")}
            </strong>
          </button>
        </div>
      )
    }
  }

  agentsList() {
    return (
      <form className="button-group button-group--vertical">
        <div className="button-group__item-filter">
          <div className="button-group__item-lens"><i className="icon-lens"></i></div>
          <input type="text" className="button-group__item-filter__input text-input text-input--borderless" placeholder={I18n.t("webapp.agents.agents_list.search")} onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => this.onKeyUp(e)} defaultValue={this.state.filter} />
        </div>
        { this.displayList() }
      </form>
    )
  }

  onKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    const enteredText = e.currentTarget.value
    this.setState(() => {
      return {
        filter: enteredText
      }
    })
  }

  agentCreatedByUser(users: any, userId: number) {
    const matchedUser = users.filter((user: any) => (user.get("id") === userId));
    const createdByUserMsg = I18n.t("webapp.agents.agents_list.created_by");
    if (matchedUser[0] != null) {
      const userName = matchedUser[0].get("full_name");
      return `${createdByUserMsg} ${userName}`
    } else {
      return I18n.t("webapp.agents.agents_list.created_by_delUser");
    }
  }

  displayList() {
    if (this.props.agents.filter(agent => (agent.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1)).length > 0) {
      return _.map(this.props.agents, (agent) => { return this.agentsListItem(agent) })
    }
    else {
      return <p className="button-group__item text--grey is-disabled">{I18n.t("webapp.agents.agents_list.no_results")}</p>
    }
  }

  agentsListItem(agent: Agent) {
    let classNames = ["button-group__item", "no-hover"];
    let inactiveLabel = "";
    if (agent.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) == -1) {
      classNames.push("is-hidden");
    }
    if (!agent.searchActive) {
      classNames.push("text--grey");
      inactiveLabel = I18n.t("webapp.agents.agents_list.inactive");
    }
    return (
      <a className={classNames.join(" ")} key={agent.id}>
        {agent.name} <span className="text--grey">{this.agentCreatedByUser(this.props.users, agent.userId)}</span> {inactiveLabel} {this.maybeShowPrivateIcon(agent.shared)}
        <span className="button-group__item__actions">
          <span className="button-group__item__actions__icon" onClick={() => { this.props.onSubscribeAgent(agent.id) }}>
            <i className="icon-eye"></i>
          </span>
        </span>
      </a>
    )
  }

  maybeShowPrivateIcon(shared: Boolean) {
    if (!shared) {
      return <i className="fa fa-lock"></i>
    }
  }
}
