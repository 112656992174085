import * as React from "react";
import { Entity, Topic } from "./GlobalClipView";
import ProviderFinder from "../../libraries/researchFilter/providerFinder";
import {
  Select2Option,
  Select2Configuration,
  Select2Id,
  ISelect2Props
} from "../researchFilter/select2Component";
import BackboneEntity from 'models/entity';
import * as Backbone from 'backbone';
import Select2 from "./Select2";
import I18n from "i18n";

interface Props {
  entities: Entity[];
  topics: Topic[];
  onSelectionChange: (selections: Selections) => void
}

export interface Selections {
  topics: Select2Id[]
  entities: Select2Id[]
}
type EntityType =
  'topic'                      |
  'business_entity'            |
  'location_entity'            |
  'law_entity'                 |
  // persons and orgs are the same thing as far as the UI is concerned
  // we need to handle those as a single thingy
  'person_organization_entity'
type InternalSelections = {
  [P in EntityType]: Select2Id[]
}

export default function ClipEditFooterView(props: Props) {
  const knownEntities = props.entities.map((e) => new BackboneEntity(e));
  const knownTopics = props.topics.map(({ id, meta: { identifier, name
  } }) => {
    return new BackboneEntity({
      id,
      meta: {
        identifier,
        name_en: I18n.t(identifier),
        name_de: I18n.t(identifier),
        name_fr: I18n.t(identifier),
        name_it: I18n.t(identifier),
      }
    })
  });
  const entityNameCache = new Backbone.Collection(knownEntities)
  const topicNameCache = new Backbone.Collection(knownTopics)

  const topicEntityIds = props.topics.map((t) => t.id)
  const locationEntityIds =
    props
      .entities
      .filter((e) => e.meta && e.meta.entity_type === 'location')
      .map(e => e.id)
  const businessEntityIds =
    props
      .entities
      .filter((e) => e.meta && e.meta.entity_type === 'business')
      .map(e => e.id)
  const personEntityIds =
    props
      .entities
      .filter((e) => e.meta && e.meta.entity_type === 'person')
      .map(e => e.id)
  const organizationEntityIds =
    props
      .entities
      .filter((e) => e.meta && e.meta.entity_type === 'organization')
      .map(e => e.id)
  const lawEntityIds =
    props
      .entities
      .filter((e) => e.meta && e.meta.entity_type === 'law')
      .map(e => e.id)
  const personOrganizationEntityIds =
    personEntityIds.concat(organizationEntityIds)

  let select2Selections: InternalSelections = {
    topic: topicEntityIds,
    location_entity: locationEntityIds,
    business_entity: businessEntityIds,
    person_organization_entity: personOrganizationEntityIds,
    law_entity: lawEntityIds
  }

  function mapSelection(selections: InternalSelections): Selections {
    return {
      topics: selections.topic,
      entities: selections.business_entity
        .concat(selections.law_entity)
        .concat(selections.location_entity)
        .concat(selections.person_organization_entity)
    };
  }

  return (
    <div className="clip-detail__footer mbl">
      <div className="form-item mbs">
        <label htmlFor="inputname" className="form-item__label">
          {I18n.t("webapp.components.clip_topic_entity_metadata.topics")}
        </label>
        <div className="form-item__input">
          <Select2 {...select2Props('topic', topicEntityIds)} />
        </div>
      </div>
      <div className="form-item mbs">
        <label htmlFor="inputname" className="form-item__label">
          {I18n.t("webapp.components.clip_topic_entity_metadata.places")}
        </label>
        <div className="form-item__input">
          <Select2 {...select2Props('location_entity', locationEntityIds)} />
        </div>
      </div>
      <div className="form-item mbs">
        <label htmlFor="inputname" className="form-item__label">
          {I18n.t("webapp.components.clip_topic_entity_metadata.businesses")}
        </label>
        <div className="form-item__input">
          <Select2 {...select2Props('business_entity', businessEntityIds)} />
        </div>
      </div>
      <div className="form-item mbs">
        <label htmlFor="inputname" className="form-item__label">
          {I18n.t("webapp.components.clip_topic_entity_metadata.laws")}
        </label>
        <div className="form-item__input">
          <Select2 {...select2Props('law_entity', lawEntityIds)} />
        </div>
      </div>
      <div className="form-item mbs">
        <label htmlFor="inputname" className="form-item__label">
          {I18n.t("webapp.components.clip_topic_entity_metadata.actors")}
        </label>
        <div className="form-item__input">
          <Select2 {...select2Props('person_organization_entity', personOrganizationEntityIds)} />
        </div>
      </div>
    </div>
  );

  
  function select2Props(
    type: EntityType,
    selection: Select2Id[]
  ): ISelect2Props {
    let provider = ProviderFinder[type];
    if (!provider) {
      throw("No provider found for type " + type)
    }
    let options: Select2Option[] = provider.select2Options();
    let config: Select2Configuration;
    let nameCache = entityNameCache
    if (type === 'topic') {
      nameCache = topicNameCache
    }
    if (nameCache &&
      nameCache.length > 0) {
    const opts = { entityNameCache: nameCache, topicNameCache: nameCache }
    config =
      provider.select2CustomConfiguration(opts);
  } else {
    config =
      provider.select2CustomConfiguration();
  }
    return {
      selection,
      options: options,
      configuration: config,
      onSelectionChange: (newSelection: Select2Id[]) => {
        select2Selections[type] = newSelection;
        props.onSelectionChange(mapSelection(select2Selections))
      },
      allowArbitraryInput: provider.allowArbitraryInput(),
      readonly: false
    };
  }
}
