import * as React from "react";
import Swiper from "react-id-swiper";
import DateFormatter, { AnyDate } from "../../../libraries/dateFormatter";
import I18n from "i18n";

export interface NewstickerItem {
  id: number;
  title: string;
  publishDate: AnyDate;
  sourceName: string;
  link: string;
}
interface Props {
  className?: string;
  title: string;
  data: NewstickerItem[];
}
const sliderParams = {
  slidesPerView: 1,
  spaceBetween: 30,
  centeredSlides: false,
  autoplay: {
    delay: 5000
  },
  loop: true,
  direction: "horizontal"
};

function renderNewsticker(data: NewstickerItem[]) {
  let swiper: HTMLElement | null = null;
  function swiperStop(e: React.MouseEvent<HTMLElement>) {
    (swiper as any).swiper.autoplay.stop();
  }

  function swiperStart(e: React.MouseEvent<HTMLElement>) {
    (swiper as any).swiper.autoplay.start();
  }

  function title(item: NewstickerItem) {
    if (item.link) {
      return (
        <a
          className="dashboard-widget__ticker__slide__headline"
          href={item.link}
          target="_blank"
          draggable={false}>
          {item.title}
        </a>
      )
    } else {
      return (
        <div className="dashboard-widget__ticker__slide__headline">
          {item.title}
        </div>
      )
    }
  }

  function metaData(item: NewstickerItem) {
    return (
      <div className="dashboard-widget__ticker__slide__meta">
        {item.sourceName},{" "}
        {DateFormatter.formatRelativeDateTime(
          item.publishDate
        )}
      </div>
    )
  }

  function newsItem(item: NewstickerItem) {
    return (
      <div>
        {title(item)}
        {metaData(item)}
      </div>
    )
  }

  if (data.length === 0) {
    return (
      <div className="dashboard-widget__no-data">
        {I18n.t("webapp.new_dashboard.newsticker_widget.no_results")}
      </div>
    );
  } else if (data.length === 1) {
    return (
      <div>
        {newsItem(data[0])}

      </div>
    );
  } else {
    return (
      <div onMouseEnter={swiperStop} onMouseLeave={swiperStart}>
        <Swiper {...sliderParams} ref={(node: HTMLElement) => (swiper = node)}>
          {data.map(newsTicker => (
              <div className="dashboard-widget__ticker__slide" 
              key={newsTicker.id.toString()}>
              {newsItem(newsTicker)}
            </div>
          ))}
        </Swiper>
      </div>
    );
  }
}
export default function NewstickerWidget({
  className,
  title,
  data,
  ...itemStyle
}: Props): JSX.Element {
  return (
    <div
      className={`dashboard-widget__container`}
      {...itemStyle}>
      <div className="dashboard-widget__ticker-headline">{title}</div>
      {renderNewsticker(data)}
    </div>
  );
}