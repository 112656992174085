import Marionette from 'backbone.marionette';
import Notificator from 'libraries/notificator';
import Validations from 'libraries/validations';
import Usage from 'models/usage';
import when from 'when';
import ContactReactAdapter from 'models/contactReactAdapter'
import Contact from 'models/contact'
import Contacts from 'collections/contacts';
import contactsInstancePromise from 'models/contactsInstancePromise';
import ContactGroupDetailsView from 'views/settings/contacts/contactGroupDetailsView';
import DeletionModalView from 'views/deletionModalView';
import ContactGroupContactsView from 'views/settings/contacts/contactGroupContactsView'
import DomainListController from
    'controllers/settings/contacts/contactGroupDomainListController';
import UnitOrderModalView from 'views/settings/unitOrderModalView';
import I18n from 'i18n';
import configurations from 'collections/newsroom/configurationsInstance';
import _ from 'underscore';
import * as React from 'react'
import $ from 'jquery'
import contactGroupsInstance from 'collections/contactGroupsInstance';

export default class ContactGroupDetailsController extends Marionette.Controller {
    initialize(contactGroup) {
        this.contactGroup = contactGroup;
        this.contacts = new Contacts();
        this.allContacts = new Contacts();
        this.dataReadyPromise = this._fetchAndPrepareData();
        const readerNames =
              this._readerNamesConnectedWithContactGroup(contactGroup);
        this.view = new ContactGroupDetailsView({
            model: contactGroup,
            readerConfigurations: readerNames
        });
        this._registerEventListeners();
    }

    _fetchAndPrepareData() {
      let promise = contactsInstancePromise.tap((contacts) => {
        this.contacts.reset(
          this.contactGroup.get('contact_ids').map((id) => contacts.get(id))
        )
        this.allContacts.reset(contacts.models)
        contactsInstancePromise.fetch();
      })

      return promise;
    }

    _readerNamesConnectedWithContactGroup(contactGroup) {
        const contactGroupId = contactGroup.get('id');
        const readerNames =
              _.chain(configurations.models)
              .filter((config) => {
                  const contactGroupIds =
                        _.chain(config.get('audience'))
                        .filter((el) => el.type === "contact_group")
                        .pluck('id')
                        .value();
                  return _.contains(contactGroupIds,
                                    contactGroupId)
              })
              .map((config) => config.get('title'))
              .value()
        return readerNames;
    }

    _registerEventListeners() {
        this.listenTo(this.view, 'render', () => {
            this._showContactList();
            this._showDomainList();
        });
        this.listenTo(this.contactGroup, 'change:name', () => {
            this.contactGroup.save();
        });
        this.listenTo(this.view, 'showModal',
            this._showContactGroupDeletion);
    }

    _deleteContactGroup() {
        this.contactGroup.destroy();
        this.trigger('deleted');
    }

    _showContactGroupDeletion() {
        this.deletionView = new DeletionModalView({
            confirmation: I18n.t('webapp.settings.contacts.groups.delete.confirmation',
                { name: this.contactGroup.get('name')}),
            warning : I18n.t('webapp.settings.contacts.groups.delete.description'),
            deleteLabel : I18n.t('webapp.settings.contacts.groups.delete.delete'),
            cancelLabel : I18n.t('webapp.settings.contacts.groups.delete.cancel')
        });
        this.listenTo(this.deletionView, 'modal:delete', this._deleteContactGroup);
        this.view.showDeletion(this.deletionView);
    }

    _reflectContactRemovalToGroups(contactId) {
        const contactGroupId = this.contactGroup.get('id')
        let groupContact = this.contacts.get(contactId)
        let newGroupIds = _.without(
            groupContact.get('contact_group_ids'), contactGroupId
        )
        groupContact.set({ contact_group_ids: newGroupIds })

        let addressbookContact = this.allContacts.get(contactId)
        // contact create does not add the contact to all contacts untill the
        // incur cost call to group is executed, when the API says that the
        // maximum is reached, we have addressbookContact undefined here
        if (addressbookContact) {
            newGroupIds = _.without(
                addressbookContact.get('contact_group_ids'), contactGroupId
            )
            addressbookContact.set({ contact_group_ids: newGroupIds })
        }
    }

    _reflectContactAdditionToGroups(contactId) {
        const contactGroupId = this.contactGroup.get('id')
        let groupContact = this.contacts.get(contactId)
        let newGroupIds = groupContact.get('contact_group_ids') || []
        groupContact.set(
            { contact_group_ids: newGroupIds.concat(contactGroupId) }
        )
        let addressbookContact = this.allContacts.get(contactId)
        newGroupIds = addressbookContact.get('contact_group_ids') || []
        addressbookContact.set(
            { contact_group_ids: newGroupIds.concat(contactGroupId) }
        )
    }

    _showContactList(listOptions) {
        let defaultOptions = {
            editContactId: null,
            highlightContactId: null,
            newContactInput: false,
            newContactEmailInvalid: false,
            useAudienceCount: false,
            newContactText: '',
            searchable: true,
            searchInput: '',
            searchOpen: false,
            addressbookOnly: false,
            useButtonStyle: false,
            addressbookEditable: true,
            addressbookRemovable: false,
            addressbookDestroyable: false,
            addressbookAddable: true,
            contactsEditable: true,
            contactsRemovable: true,
            contactsDestroyable: false,
            contactsAddable: false,
            searchText: I18n.t('webapp.settings.contacts.groups.search_or_add_contact'),
            contactsText: I18n.t('webapp.settings.contacts.groups.list_headline_contacts'),
            addressBookText: I18n.t('webapp.settings.contacts.groups.list_headline_address_book')
        }
        let currentOptions = defaultOptions
        if (listOptions !== undefined) {
            currentOptions = _.extend({}, defaultOptions, listOptions);
        }

        this.dataReadyPromise.done(() => {
            let json = currentOptions
            json.onDestroy = () => {},
            json.onListItemClick = () => {},
            json.onAdd = (contact, searchInput) => {
                const model = this.allContacts.get(contact.id)
                if (model !== undefined) {
                    this.contacts.add(contact);
                    this._reflectContactAdditionToGroups(contact.id)
                    this._showContactList({
                        editContactId: null,
                        searchInput: searchInput,
                        newContactText: I18n.t(
                            "webapp.settings.contacts.groups.list_add_label",
                            { "search_string": searchInput }
                        )
                    })
                    this._storeContact(model)
                }
            }

            json.onEdit = (contact, searchInput) => {
                const newOptions = _.extend({}, currentOptions, {
                    editContactId: contact.id,
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        "webapp.settings.contacts.groups.list_add_label",
                        { "search_string": searchInput }
                    )
                })
                this._showContactList(newOptions)
            }

            json.onRemove = (contact, searchInput) => {
                const model = this.contacts.get(contact.id)
                if (model !== undefined) {
                    this._reflectContactRemovalToGroups(contact.id)
                    this.contacts.remove(model)
                    this._showContactList({
                        editContactId: null,
                        searchInput: searchInput,
                        newContactText: I18n.t(
                            "webapp.settings.contacts.groups.list_add_label",
                            { "search_string": searchInput }
                        )
                    })
                    this.contactGroup.removeContact(model).otherwise(this._notSavedError(model, 'remove'))
                    contactGroupsInstance.fetch();
                }
            }

            json.onCancel = (searchInput) => {
                this._showContactList({
                    editContactId: null,
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        "webapp.settings.contacts.groups.list_add_label",
                        { "search_string": searchInput }
                    )
                })
            }

            json.onUpdate = (contact, name, email, searchInput) => {
                let model = this.allContacts.get(contact.id)
                model.set({ name: name, email: email})
                this._showContactList({
                    searchInput: searchInput,
                    newContactText: I18n.t(
                        "webapp.settings.contacts.groups.list_add_label",
                        { "search_string": searchInput }
                    )
                })
                this._updateContact(model)
            }

            json.onNewContact = () => {
                const inputValue = $("ul.contact-list [data-contacts-search]").val()
                this._showContactList({
                    newContactInput: true,
                    newContactEmailInvalid: false,
                    searchInput: inputValue,
                    newContactText: I18n.t(
                        "webapp.settings.contacts.groups.list_add_label",
                        { "search_string": inputValue }
                    )
                })
            }

            json.onCreateContact = (name, email, searchInput) => {
              const valid = Validations.isEmailValid(email)
              if (valid) {
                let contact = new Contact({ name: name, email: email })
                this.contacts.add(contact)
                this._showContactList({
                  newContactInput: false,
                  newContactEmailInvalid: false,
                  searchInput: searchInput,
                  newContactText: I18n.t(
                    "webapp.settings.contacts.groups.list_add_label",
                    { "search_string": searchInput }
                  )
                })
                this._performCreateAndAddContact(contact, () => {
                    this._showContactList({
                      newContactInput: false,
                      newContactEmailInvalid: false,
                      searchInput: searchInput,
                      newContactText: I18n.t(
                        "webapp.settings.contacts.groups.list_add_label",
                        { "search_string": searchInput }
                      )
                    })
                })
              } else {
                this._showContactList({
                  newContactInput: true,
                  newContactEmailInvalid: true,
                  searchInput: searchInput,
                  newContactText: I18n.t(
                    "webapp.settings.contacts.groups.list_add_label",
                    { "search_string": searchInput }
                  )
                })
              }
            }

            json.onSearch = (inputValue) => {
                this._showContactList({
                    newContactInput: false,
                    newContactEmailInvalid: false,
                    searchInput: inputValue,
                    newContactText: I18n.t(
                        "webapp.settings.contacts.groups.list_add_label",
                        { "search_string": inputValue }
                    )
                })
            }
            json.onGroupAdd = () => {}
            json.onGroupRemove = () => {}
            json.contacts = new ContactReactAdapter(this.contacts.models).toReactProps()
            json.allContacts = new ContactReactAdapter(
                this.allContacts.models
            ).excludingContactGroup(this.contactGroup.get('id'))
            this.contactList = <ContactGroupContactsView {...json}/>
            this.view.showContactList(this.contactList);
        })
    }

    _storeContact(contact) {
        let usage = new Usage();
        when.join(
            usage.load(),
            this.contactGroup.addContactIncurCost(contact)
        ).then((r) => {
            let response = r[1];
            if (response.incur_cost) {
                let confirmCallback = () => {
                    this.view.clearLiveFilter()
                    this._performAddContactToGroup(contact)
                }
                let cancelCallback = () => {
                    this.contacts.remove(contact);
                    this._showContactList();
                }
                this._showOrderModal(
                    usage, contact, confirmCallback, cancelCallback)
                ;
            } else {
                this._performAddContactToGroup(contact);
            }
        });
    }

    _updateContact(contact) {
        let usage = new Usage();
        when.join(
            usage.load(),
            contact.updateIncurCost(
                { name: contact.get('name'), email: contact.get('email') }
            )
        ).then((r) => {
            let response = r[1];
            if (response.incur_cost) {
                const confirmCallback = () => {
                    this._performUpdateContact(contact)
                }
                const cancelCallback = () => {
                    contact.set(contact.previousAttributes());
                    this._showContactList()
                }
                this._showOrderModal(
                    usage, contact, confirmCallback, cancelCallback
                );
            } else {
                this._performUpdateContact(contact);
            }
        });
    }

    _performCreateAndAddContact(contact, afterSaveCallback) {
        contact.save({}, {
            error: () => {
                this._notSavedError(contact, 'create')
            },
            success: (persistedContact) => {
                this._storeContact(persistedContact)
                afterSaveCallback()
            }
        });
    }

    _performAddContactToGroup(contact) {
        this.contactGroup.addContact(contact).then(() => {
            this.allContacts.add(contact);
            contactGroupsInstance.fetch();
        }).otherwise(this._notSavedError(contact, 'add'));
    }

    _performUpdateContact(contact) {
        contact.save().fail(this._notSavedError(contact, 'update'))
    }

    _notSavedError(contact, operation) {
        return (response) => {
            let error;
            if (response.responseText) {
                try {
                    error = JSON.parse(response.responseText)['error'];
                } catch(e) {
                  // ignore errors
                }
            }
            let message;
            if (error && error.match(/reader seat maximum reached/i)) {
                message = I18n.t('webapp.notifications.error.reader_seat_maximum_reached');
            } else {
                message = I18n.t("webapp.notifications.error.not_saved");
            }
            Notificator.showNotification(message);
            if (operation === 'add') {
                this._reflectContactRemovalToGroups(contact.get('id'))
                this.contacts.remove(contact);
            } else if (operation === 'remove') {
                this.contacts.add(contact);
                this._reflectContactAdditionToGroups(contact.get('id'))
            } else if (operation === 'update') {
                contact.set(contact.previousAttributes());
            } else if (operation === 'create') {
                this.contacts.remove(contact)
                this.allContacts.remove(contact)
            } else {
                throw 'unsupported operation "' + operation + '"';
            }
            this.view.clearLiveFilter()
            this._showContactList()
        };
    }

    _showDomainList() {
        this.contactGroup.fetchDomainsOnce().done(() => {
            this.domainListController = new DomainListController({
                contactGroup: this.contactGroup
            });
            this.view.showDomainList(this.domainListController.view);
            this.listenTo(
                this.domainListController,
                'showDuplicateDomainError',
                () => { this.view.showDuplicateDomainError() }
            );
            this.listenTo(
                this.domainListController,
                'showDomainError',
                () => { this.view.showDomainError() }
            );
            this.listenTo(
                this.domainListController,
                'hideDomainErrors',
                () => { this.view.hideDomainErrors() }
            );
        });
    }

    _showOrderModal(usage, contact, confirmCallback, cancelCallback) {
        let orderModalView = new UnitOrderModalView({
            unitType: 'reader_seat',
            usage: usage
        });
        this.listenTo(orderModalView, 'modal:confirmed', confirmCallback);
        this.listenTo(orderModalView, 'modal:cancelled', cancelCallback);
        this.view.orderModal.show(orderModalView);
        orderModalView.showModal();
    }
}
