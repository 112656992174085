import Backbone from 'backbone'
import RestorableModel from 'libraries/restorableModel'
import usersInstance from 'collections/usersInstance'
import pusherWrapper from 'models/pusherWrapper'
import I18n from 'i18n'

var Comment = RestorableModel.extend({
  // Options: uneditedJSON - the comment in the api form so that it is
  //                         parsable as model again
  toJSON: function (options) {
    let json = Backbone.Model.prototype.toJSON.call(this);
    if (options.uneditedJSON) {
      json.created_at = json.created_at.getTime() / 1000;
      return json;
    }
    // we don't need to send the timestamps back
    delete json.created_at;
    delete json.author;
    return { comment: json, socket_id: pusherWrapper.socketId };
  },
  parse: function (response) {
    var data = response.object ? response.object : response;
    data.created_at = new Date(data.created_at * 1000);
    var author = usersInstance.get(data.creator_id)
    var deleted_user = I18n.t("webapp.agents.results.result_details.comment_deleted_author")
    data.author = author ? author.get('full_name') : deleted_user;
    return data;
  },
  ownedByCurrentUser: function () {
    var currentUserId = usersInstance.currentUser.id;
    return this.get('creator_id') === currentUserId;
  },
  sync: function (method, model, options) {
    if (method === "delete") {
      options.data = 'socket_id=' + pusherWrapper.socketId;
    }
    return RestorableModel.prototype.sync.call(this,
                                               method,
                                               model,
                                               options);
  }
});

export default Comment;
