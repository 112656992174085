import Marionette from "backbone.marionette";
import TaggingManager from "models/taggingManager";
import AgentResultTagView from "views/agents/agentResultTagView";
import {ITagViewProps} from "components/agentResultTagComponent";
import Shortcutter from "libraries/shortcutter";
import AgentResult from "models/agentResult";
import Tag from "models/tag";
import vent from "libraries/vent";
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import * as _ from 'underscore';

interface ITag {
  id: string;
  text: string;
}

class AgentResultTagController extends Marionette.Controller {
  initialize(agentResults: Backbone.Collection<AgentResult>) {
    this.agentResults = agentResults;

    this.view = new AgentResultTagView();

    this.registerListeners();
  }

  renderView(additionalProps?: object) {
    const props = this.buildProps(additionalProps);
    this.view.renderView(props);
  }

  buildProps(additions?: object): ITagViewProps {
    const base = {
      agentResults: this.agentResults,
      classNames: this.viewInputClasses(),
      onTagAdd: (added: ITag) => this.createTagging(added),
      onTagRemove: (removed: ITag) => this.deleteTagging(removed)
    };
    if (additions !== undefined)
      return _.extend(base, additions);
    else
      return base;
  }

  private viewInputClasses(): Array<string> { return ["mtl"]; }

  private onClose() { this.unbindShortcuts(); }

  private registerListeners() {
    this.listenTo(this.view, 'render', () => { this.renderView(); });
    this.listenTo(this.view, "close", this.unbindShortcuts);
    this.listenTo(this.view, "render", this.bindShortcuts);
  }

  private createTagging(tag: ITag) {
    new TaggingManager(this.agentResults).createTaggings(
      { name: tag.text.toString() },
      () => { this.view.showValidTagInputOnly(tag); }
    );
    this.taggingCallback();
  }

  private deleteTagging(tag: ITag) {
    new TaggingManager(this.agentResults).destroyTaggings(
      { name: tag.text.toString() }
    );
    this.taggingCallback();
  }

  private taggingCallback() {
    this.agentResults.each((agentResult: AgentResult) => {
      vent.trigger("updatedByAgents", agentResult.get("id"));
    });
  }

  private bindShortcuts()  {
    Shortcutter.bindInModule("agents", "t", () => {
      this.renderView({ inputFocusOpen: true });
      return false;
    });
  }

  private unbindShortcuts() {
    Shortcutter.unbindInModule("agents", "t");
  }
}

export default AgentResultTagController;
