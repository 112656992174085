import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/newsroom/readerPresentationOptions.html';

class ReaderPresentationOptionsView extends Marionette.ItemView {
    initialize(options) {
        this.configuration = options.configuration;
    }

    onShow() {
        this.on('clicked:hideEmptyCategories', () => {
            this.toggleButton(this.ui.hideEmptyCategories);
            this.trigger('button:clicked');
        });

        this.on('clicked:expandCategories', () => {
            this.toggleButton(this.ui.expandCategories);
            this.trigger('button:clicked');
        });

        this.on('clicked:highlightImportantResults', () => {
            this.toggleButton(this.ui.highlightImportantResults);
            this.trigger('button:clicked');
        });

        this._setButton(this.ui.hideEmptyCategories,
                        this.configuration.get('hide_empty_categories'));
        this._setButton(this.ui.expandCategories,
                        this.configuration.get('expand_categories'));
        this._setButton(this.ui.highlightImportantResults,
                        this.configuration.get('highlight_important_results'));
    }

    data() {
        return {
            hide_empty_categories: this.isOn(this.ui.hideEmptyCategories),
            expand_categories: this.isOn(this.ui.expandCategories),
            highlight_important_results:
              this.isOn(this.ui.highlightImportantResults)
        };
    }

    isOn(btn) {
        return this.$(btn).hasClass('is-active');
    }

    toggleButton(btn) {
        this.$(btn).toggleClass('is-active');
    }

    _setButton(btn, value) {
        if (value) {
            this.$(btn).addClass('is-active');
        } else {
            this.$(btn).removeClass('is-active');
        }
    }
}

ReaderPresentationOptionsView.prototype.template = template;
ReaderPresentationOptionsView.prototype.ui = {
    hideEmptyCategories: '[data-hide-empty-categories]',
    expandCategories: '[data-expand-categories]',
    highlightImportantResults: '[data-highlight-important-results]'
};
ReaderPresentationOptionsView.prototype.triggers = {
    'click @ui.hideEmptyCategories': 'clicked:hideEmptyCategories',
    'click @ui.expandCategories': 'clicked:expandCategories',
    'click @ui.highlightImportantResults': 'clicked:highlightImportantResults'
};

export default ReaderPresentationOptionsView;
