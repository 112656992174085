import Marionette from "backbone.marionette";
import template from "text-loader!templates/agents/metadata/sopranoArgusView.html";
import I18n from "i18n";
import agentResultReadingTime from "libraries/agentResultReadingTime";
import localizedLanguages from "libraries/localizedLanguages";
import _ from "underscore";

class SopranoArgusView extends Marionette.ItemView {
  initialize() {
    this.agentResult = this.options.agentResult;
  }

  serializeData() {
    const metadata = this.agentResult.get('primary_source').source_metadata;
    let languageCodes = this.agentResult.get('languages');

    if (!_.isEmpty(languageCodes)) {
      languageCodes = localizedLanguages(languageCodes);
    }

    return {
      country: I18n.t(metadata.country),
      publishTime: this.agentResult.get('publish_time') * 1000,
      languages: languageCodes,
      readingTime: agentResultReadingTime(this.agentResult),
    };
  }
}

SopranoArgusView.prototype.template = template;

export default SopranoArgusView;
