import Backbone from 'backbone'
import session from 'models/sessionInstance'

var ContentSettings = Backbone.Model.extend({
  identifier: 'contentSettings',
  initialize: function () {
    this.set({
      has_chart: this.get('report').get('has_chart'),
      include_clips_from_several_sources: this.get('report').get('include_clips_from_several_sources'),
      has_clip_snippets: this.get('report').get('has_clip_snippets'),
      has_print_clips_in_full_length: this.get('report').get('has_print_clips_in_full_length')
    });
    this.on('change:has_chart', () => {
      this.get('report').set({has_chart: this.get('has_chart')});
      this.checkForCompleteness();
    });
    this.on('change:include_clips_from_several_sources', () => {
      this.get('report').set({include_clips_from_several_sources: this.get('include_clips_from_several_sources')});
      this.checkForCompleteness();
    });
    this.on('change:has_clip_snippets', () => {
      this.get('report').set({has_clip_snippets: this.get('has_clip_snippets')});
      this.checkForCompleteness();
    });
    this.on('change:has_print_clips_in_full_length', () => {
      this.get('report').set({has_print_clips_in_full_length: this.get('has_print_clips_in_full_length')});
      this.checkForCompleteness();
    });
    this.checkForCompleteness();
  },

  data: function () {
    return {
      has_chart: this.get('has_chart'),
      include_clips_from_several_sources: this.get('include_clips_from_several_sources'),
      has_clip_snippets: this.get('has_clip_snippets'),
      has_print_clips_in_full_length: this.get('has_print_clips_in_full_length')
    };
  },

  hasPrintClipsInFullLength: function () {
    return session.hasFeature('reports_pdf_print_clips_in_full_length');
  },

  hasGraph: function () {
    return session.get('features').reports_graph;
  },

  hasSnippets: function () {
    return session.get('features').reports_snippets;
  },

  checkForCompleteness: function () {
    this.set({complete: true});
  }
});

ContentSettings.hasContentSettings = function () {
  return ContentSettings.prototype.hasGraph() ||
    ContentSettings.prototype.hasSnippets() ||
    session.isSwissCustomer();
};


export default ContentSettings;

