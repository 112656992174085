import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/newsroom/readerEmailSettings.html';

class ReaderEmailSettingsView extends Marionette.Layout {
    toggleSendEmailAutomatically() {
        this.$(this.ui.sendEmailAutomatically).toggleClass('is-active');
    }

    showRecipientSelection(view) {
        this.recipientSelectionContainer.show(view);
    }

    data() {
        return {
            sendEmailAutomatically: this.isOn(this.ui.sendEmailAutomatically),
            senderName: this.$('[data-sender-name]').val(),
            senderEmail: this.$('[data-sender-email]').val()
        };
    }

    isOn(button) {
        return this.$(button).hasClass('is-active');
    }

    setSendEmailButton(value) {
        if (value) {
            this.$(this.ui.sendEmailAutomatically).addClass('is-active');
        } else {
            this.$(this.ui.sendEmailAutomatically).removeClass('is-active');
        }
    }

    setSenderName(value) {
        this.$('[data-sender-name]').val(value);
    }

    setSenderEmail(value) {
        this.$('[data-sender-email]').val(value);
    }

    showRefreshTokenModal(view) {
        this.refreshTokenModalContainer.show(view);
    }

}

ReaderEmailSettingsView.prototype.template = template;
ReaderEmailSettingsView.prototype.ui = {
    sendEmailAutomatically: '[data-send-email-automatically]',
    refreshToken: '[data-refresh-token-btn]'
};

ReaderEmailSettingsView.prototype.triggers = {
    'click @ui.refreshToken': 'refreshToken',
    'click @ui.sendEmailAutomatically': 'clicked:sendEmailAutomatically',
    'change [data-sender-name]': 'change:sender',
    'change [data-sender-email]': 'change:sender'
};

ReaderEmailSettingsView.prototype.regions = {
    recipientSelectionContainer: '[data-recipient-selection-container]',
    refreshTokenModalContainer: '[data-refresh-token-modal-container]'
};

export default ReaderEmailSettingsView;
