/*
 * Load I18n.js, translations.js and configure I18n
 */

import I18n from 'i18n.lib'
import translations from './vendor/translations.yml'
import QueryString from 'libraries/queryString'

I18n.translations = translations;
I18n.defaultLocale = 'en';

I18n.fallbacks = true;
// country specific locales like de-CH automatically fall back to
// their language
I18n.fallbackRules.en = ['de'];

if (QueryString.debugLocales) {
  I18n.t = function (key) {
    return key;
  }
}

export default I18n;

