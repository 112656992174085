import _ from 'underscore';

class DragAndDropManager {
    constructor(collection,
                readerResults,
                readerResultAfterId,
                readerResultBeforeId,
                archiveDateId) {
        this.collection = collection;
        this.results = readerResults;
        this.archiveDateId = archiveDateId;
        if (readerResultBeforeId) {
            this.before_reader_result =
                _.find(this.collection.models, function(result) {
                    return result.get('id') === readerResultBeforeId;
                });
        }
        if (readerResultAfterId) {
            this.after_reader_result =
                _.find(this.collection.models, function(result) {
                    return result.get('id') === readerResultAfterId;
                });
        }
    }

    dragAndDrop() {
        var positions = this.calculateNewPositions();
        var self = this;

        _.each(this.results, function (result) {
            result.set({
                archiveDateId: self.archiveDateId,
                position: positions[result.get('id')]
            });
        });

        return this.results;
    }

    calculateNewPositions() {
        var h = {};

        if (this.movingToEmptyArchiveDate()) {
            let self = this;
            _.each(this.results, function (result, idx) {
                h[result.get('id')] = self.n() - idx;
            });
        } else {
            var first_pos = this.calculateFirstResultPosition();
            var increment = this.calculatePositionIncrement();

            var base_range = _.range(this.n());

            var range = this.movingToBottom() ? base_range : base_range.reverse();

            var positions = _.map(range, function (i) {
                return first_pos + i * increment;
            });

            _.each(this.results, function (result, idx) {
                h[result.get('id')] = positions[idx];
            });
        }
        return h;
    }

    calculateFirstResultPosition() {
        if (this.movingToBottom()) {
            return this.after_reader_result.get('position') +
                this.calculatePositionIncrement();
        } else {
            return this.before_reader_result.get('position') +
                this.calculatePositionIncrement();
        }
    }

    calculatePositionIncrement() {
        if (this.movingToTop()) {
            return 1.0;
        } else if (this.movingToBottom()) {
            return - this.after_reader_result.get('position') / (this.n() + 1.0);
        } else {
            return (this.after_reader_result.get('position') -
             this.before_reader_result.get('position')) / (this.n() + 1.0);
        }
    }

    n() {
        return this.results.length;
    }

    movingToEmptyArchiveDate() {
        return !this.isPresent(this.before_reader_result) &&
            !this.isPresent(this.after_reader_result);
    }

    movingToTop() {
        return !this.isPresent(this.after_reader_result);
    }

    movingToBottom() {
        return !this.isPresent(this.before_reader_result);
    }

    movingBetweenResults() {
        return this.isPresent(this.after_reader_result) &&
            this.isPresent(this.before_reader_result);
    }

    isPresent(obj) {
        return !_.isUndefined(obj) && !_.isNull(obj);
    }
}

export default DragAndDropManager;
