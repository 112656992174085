import Backbone from 'backbone'
import moment from 'moment'

var timelineDay = Backbone.Model.extend({
  initialize: function(date) {
    var d = moment(date);
    this.set('date', d);
    var beginningTimestamp = moment(d).startOf('day').unix();
    this.set('beginningTimestamp', beginningTimestamp);
  },

  isCurrent: function() {
    var today = moment();
    return this.isSame(today);
  },

  isBeginningOfMonth: function() {
    var d = this.get('date');
    return d.isSame(moment(d).startOf('month'));
  },

  isBeginningOfYear: function() {
    var d = this.get('date');
    return d.isSame(moment(d).startOf('year'));
  },

  isSame: function(day) {
    var d = this.get('date');
    return d.isSame(day.get('date'), 'day');
  },

  isAfter: function(day) {
    var d = this.get('date');
    return d.isAfter(day.get('date'), 'day');
  },

  isBefore: function(day) {
    var d = this.get('date');
    return d.isBefore(day.get('date'), 'day');
  },

  getBeginningTimestamp: function() {
    return this.get('beginningTimestamp');
  }
});

export default timelineDay;

