import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/dictionary.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import I18n from 'i18n'

class DictionaryView extends Marionette.ItemView {
  initialize(options) {
    this.agentResult = options.agentResult;
  }

  serializeData() {
    let metadata = {};

    const col1 = {
      last_modified: this.agentResult.get('publish_time') * 1000
    }

    const language = this.agentResult.get('languages')[0];
    if (language) {
      col1['language'] =
        I18n.t("vendor.iso.languages." + language);
    }

    conditionalSetToMetadata(metadata, 'col1', col1);

    return metadata;
  }
}
DictionaryView.prototype.template = template;

export default DictionaryView;

