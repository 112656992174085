import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/newsroomSummaryTimelineList.html';
import NewsroomSummaryTimelineItemView
    from 'views/newsroom/newsroomSummaryTimelineItemView';
import ArchiveDateApiCollection
    from 'collections/archiveDateApiCollection';


class NewsroomSummaryTimelineListView extends Marionette.CompositeView {
    initialize() {
        this.readerConfigurationId = this.options.selectedReaderConfigurationId;
        this.collection = new ArchiveDateApiCollection([], {
            readerConfigurationId: this.readerConfigurationId,
        });
        this.timelineVisible = false;
    }

    itemViewOptions() {
        return {
            readerConfigurationId: this.readerConfigurationId
        };
    }

    onRender() {
        this._updateTimelineVisibility();
    }

    showTimeline() {
        this.timelineVisible = true;
        if (!this.isClosed) {
            this._updateTimelineVisibility();
        }
    }

    _updateTimelineVisibility() {
        if (this.timelineVisible) {
            this.ui.preloader.hide();
            this.ui.timeline.show();
        } else {
            this.ui.timeline.hide();
            this.ui.preloader.show();
        }
    }
}

NewsroomSummaryTimelineListView.prototype.template = template;
NewsroomSummaryTimelineListView.prototype.itemViewContainer = '[data-timeline-item-container]';
NewsroomSummaryTimelineListView.prototype.itemView = NewsroomSummaryTimelineItemView;

NewsroomSummaryTimelineListView.prototype.ui = {
    preloader: '[data-preloader]',
    timeline: '[data-timeline-item-container]',
};

export default NewsroomSummaryTimelineListView;
