import Marionette from 'backbone.marionette'
import ClipDetailView from 'views/agents/agentResultDetailView'
import agentResultMetadataFactory from 'controllers/agents/agentResultMetadataViewFactory'
import Shortcutter from 'libraries/shortcutter'
import _ from 'underscore'

export default class ClipDetailController extends Marionette.Controller {
  initialize(clip) {
    this.view = new ClipDetailView({model: clip});
    this.clip = clip;
    this._bindShortcuts();
    this._registerListeners();
  }

  _bindShortcuts() {
    var self = this;
    Shortcutter.bindInModule(this._shortcutModuleName(),
                             'o', function () {
                               var href =
                                   self.clip.get('primary_source').clip_url;
                               if (!_.isUndefined(href)) {
                                 window.open(href, '_blank');
                               }
                             });
  }

  _unbindShortcuts() {
    Shortcutter.unbindInModule(this._shortcutModuleName(), 'o');
  }

  _shortcutModuleName() {
    return 'research';
  }

  _registerListeners() {
    this.listenTo(this.view, 'render', () => {
      this.view.showAgentResultDetailFooter(
        agentResultMetadataFactory(this.clip)
      );
    });
  }

  onClose() {
    this._unbindShortcuts();
  }
}
