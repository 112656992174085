import Marionette from 'backbone.marionette'
import I18n from 'i18n'
import $ from 'jquery'
import _ from 'underscore'
import template from 'text-loader!templates/agents/metadata/data_lake.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import localizedLanguages from 'libraries/localizedLanguages'
import agentResultReadingTime from 'libraries/agentResultReadingTime'
import session from 'models/sessionInstance';

var DataLakeView = Marionette.ItemView.extend({
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
    this.userCountry = options.userCountry.toLowerCase();
    this.locale = session.get("locale");
  },
  serializeData: function () {
    return this._metadata();
  },

  _getLangString(locale) {
    let lang;
    if (locale === 'de-CH' || locale === 'de-AT') {
      lang = 'de';
    } else {
      lang = locale;
    }
    return lang;
  },

  _formatNumber(num) {
    return new Intl.NumberFormat(this._getLangString(this.locale)).format(num);
  },
  _metadata: function () {
    var languageCodes = this.agentResult.get('languages');
    var clipMetadata = this.agentResult.get('clip_metadata');
    var sourceMetadata = this.agentResult.get('primary_source').source_metadata;
    var metadata = {};

    let article_ad_price;
    if (
      clipMetadata &&
      _.has(clipMetadata, 'article_ad_price') &&
      clipMetadata.article_ad_price !== null
    ) {
      article_ad_price = $.trim(
        clipMetadata.article_ad_price.replace('Werbewert', '')
      );
    }
    // reach
    var reach = {
      uniqueClientsPerMonth: sourceMetadata.unique_clients,
      pageImpressionsPerMonth: sourceMetadata.page_impressions,
      reachNumber: this._formatNumber(sourceMetadata.reach),
      circulation: sourceMetadata.circulation,
      page: clipMetadata.page,
      sourceArticleArea: this._formatNumber(clipMetadata.source_article_area),
      articleAdPrice: article_ad_price,
    };

    let publicationFrequency, website, trimmedUrl;
    if (sourceMetadata) {
      publicationFrequency = sourceMetadata.media_type;
      //trim the "https://" or "http://" and/or "www",
      //and show only 12 characters.
      trimmedUrl = sourceMetadata.url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').slice(0, 11);
      website = sourceMetadata.url;
    }

    if (!this.agentResult.isPrintSource()) {
      reach.visitsPerMonth = sourceMetadata.reach;
    }

    var usagePerCountry = this._getUsagePerCountry(sourceMetadata);
    if (usagePerCountry) {
      reach.labelUsagePerCountry = this._labelUsagePerCountry();
      reach.usagePerCountry = usagePerCountry;
    }

    conditionalSetToMetadata(metadata, 'reach', reach);

    var engagement = {
      comment_count: sourceMetadata.comment_count
    };
    conditionalSetToMetadata(metadata, 'engagement', engagement);

    var information = {
      alexaCity: sourceMetadata.alexa_city,
      alexaCountry: sourceMetadata.alexa_country,
      readingTime: agentResultReadingTime(this.agentResult),
      publishDate: this.agentResult.get('publish_time') * 1000,
      author: clipMetadata.author,
      publicationFrequency: publicationFrequency,
      primarySourceUrl: website,
      trimmedUrl,
    };
    if (!_.isEmpty(languageCodes)) {
      information.languages = localizedLanguages(languageCodes);
    }
    conditionalSetToMetadata(metadata, 'information', information);

    return metadata;
  },
  _getUsagePerCountry: function (sourceMetadata) {
    if (!this._userCountryCanHaveMetadata) {
      return;
    }
    if (sourceMetadata && sourceMetadata.usage_per_country) {
      return sourceMetadata.usage_per_country[this.userCountry];
    }
  },
  _userCountryCanHaveMetadata: function () {
    return _.contains(["at", "de", "ch"], this.userCountry);
  },
  _labelUsagePerCountry: function () {
    return I18n.t(
      "webapp.agents.results.result_details.metadata.usage_from_"
        .concat(this.userCountry)
    );
  }
});

export default DataLakeView;
