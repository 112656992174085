import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/settings/notifications/agentSelection.html'

var AgentSelectionView = Marionette.ItemView.extend({
  template: template,
  events: {
    'click button[data-agent]': 'agentSelected'
  },
  initialize: function (options) {
    this.agents = options.agents;
    this.selected_agents = options.selected_agents;
  },
  serializeData: function () {
    var self = this;
    var attributes;
    var agents = this.agents.map(function (agent) {
      attributes = agent.attributes;
      attributes.selected = self.selected_agents.contains(agent);
      return attributes;
    });

    return {
      agents: agents
    };
  },
  toggleSelection: function (agent) {
    var element = this.$("[data-agent-id=" + agent.id + "]");
    this.$(element).toggleClass('is-active');
  },
  agentSelected: function (event) {
    this.trigger("agent:selected", +this.$(event.target).attr('data-agent-id'));
  }
});

export default AgentSelectionView;

