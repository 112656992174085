import Config from 'libraries/configuration'
import Backbone from 'backbone'
import _ from 'underscore'
import AgentResults from 'collections/agentResults'
import pusherWrapper from 'models/pusherWrapper'

var SelectedAgentResults = Backbone.Model.extend({
  initialize: function (agentResults) {
    var agentResultIds = _.map(agentResults, function (agentResult) {
      return agentResult.get("id");
    });
    this.url = Config.apiUrl + "agent_results/" + agentResultIds.join(';');
    this.collection = new AgentResults(agentResults);
    this.listenTo(this, "destroy", this.handleDestroy);
  },
  isNew: function () {
    return false;
  },
  parse: function (response) {
    if (response.object) {
      return response.object;
    } else if (response.collection) {
      return response.collection;
    } else {
      return response;
    }
  },
  handleDestroy: function () {
    this.collection.last().set("read", true);
    _.each(this.collection.toArray(), function (model) {
      model.trigger("destroy", model);
    });
  },
  toJSON: function () {
    return {
      "agent_result" : {
        "rating_value" : this.get("rating_value"),
        "read" : this.get("read"),
        "hidden" : this.get("hidden"),
        "important": this.get("important")
      }
    };
  },
  sync: function (method, model, options) {
    if (_.isUndefined(options.attrs)) {
      options.attrs = {};
    }
    options.attrs.socket_id = pusherWrapper.socketId;
    return Backbone.Model.prototype.sync.call(this, method, model, options);
  }
});

export default SelectedAgentResults;

