import _ from 'underscore'
import $ from 'jquery'
import LineChart from 'views/dashboard/widgetView/helpers/lineChart'
import AgentTagWidgetView from 'views/dashboard/agentTagWidgetView'

var MultiChartWidget = AgentTagWidgetView.extend({
  prepareWidgetBody: function () {
    var container = $(this.el).find('div.chart')[0];
    var backgroundColor = this.chartBackgroundColor($(container).css('background-color'));

    this.chart = new LineChart(container, {
      chart: {
        backgroundColor: backgroundColor,
        width: 246,
        height: 140
      }
    });

    var series = _.map(this.model.get('chart_data'), (chartData, index) => {
      return {
        color: this.getColor(index),
        data: this._convertTimestamps(chartData)
      };
    }, this);

    this.chart.addSeries(series);
    this.chart.render();
  },
  prepareSerializedData: function () {
    var itemValues = _.map(this.getItems(), (item, index) => {
      return {
        name: item.name,
        truncatedLabel: this._truncateLabel(item.name),
        clipCount: this.model.clipCount(index),
        color: this.getColor(index)
      };
    }, this);
    var values = {
      name: this.model.get('name'),
      items: itemValues
    };
    return values;
  },
  getColor: function (index) { return this.model.get('agents')[index].color; },
  getItems: function () { return this.model.get('agents'); }
});

export default MultiChartWidget;

