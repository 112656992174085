import _ from 'underscore'
import deepMerge from 'libraries/deepMerge'
import Chart from 'views/dashboard/widgetView/helpers/chart'
import I18n from 'i18n'
import moment from 'moment'
import 'highcharts_legacy'

function LineChart(container, options) {
  this._initialize(container, options);
}
_.extend(LineChart.prototype, Chart.prototype, {
  _defaultOptions: function () {
    var defaults = Chart.prototype._defaultOptions.call(this);
    deepMerge(defaults, {
      chart: {
        defaultSeriesType: 'area'
      },
      xAxis: {
        type: 'datetime',
        gridLineColor: '#fff',
        tickInterval: 24 * 60 * 60 * 1000,
        tickLength: 0,
        title: {
          enabled: false
        },
        labels: {
          formatter: function () {
            var days = I18n.t("date.abbr_day_names");
            var index = moment(this.value).format("d");
            return days[index];
          }
        }
      },
      yAxis: {
        min: 0,
        showFirstLabel: false,
        allowDecimals: false,
        tickPixelInterval: 40,
        labels: {
          align: 'left',
          x: 0,
          y: 12
        }
      }
    });
    return defaults;
  }
});

export default LineChart;

