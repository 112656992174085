import * as _ from "underscore";
import * as moment from "moment";
import Source from "models/source";
import Sources from "collections/sources";
import RestorableModel from "libraries/restorableModel";
import session from "models/sessionInstance";
import highlight from 'libraries/highlight';

interface IClipResponse {
  object: any;
}

interface IAdditionalSourceData {
  favicon_url: string;
  name: string;
  clip_url: string;
  reach?: string;
  clipPublishedAt?: any;
  fullClip?: boolean;
}

class Clip extends RestorableModel {
    parse(response: IClipResponse): any {
        if (response.object) {
            this.buildSourcesCollection(response.object);
            return response.object;
        } else {
            this.buildSourcesCollection(response);
            return response;
        }
    }

    richText(): string {
        return this.get("rich_text");
    }

    clipAttachments(): Array<any> {
        return this.get("clip_attachments") || [];
    }

    primarySource(): any {
        let source = this.get("primary_source");
        const metadata = source.source_metadata || {};

        return {
            id:           source.id,
            favicon_url:  source.favicon_url,
            name:         source.name,
            reach:        metadata.reach,
            paywallType:  metadata.paywall_type,
            fullClip:     metadata.full_clip,
            clip_url:     source.clip_url
        };
    }

    isPaywalled(): boolean {
        return _.isString(this.primarySource().paywallType);
    }

    additionalSources(): Array<any> {
        let primarySource = this.get("primary_source");
        let primarySourceMetadata = primarySource.source_metadata;

        let additionalSources = _.reject(this.get("sources").models, (source: Source) => {
            let sourceName = source.get("name");
            let publishedAt = source.get("source_metadata").clip_published_at;

            if (this.isRtvClip()) {
                return sourceName === primarySource.name &&
                       publishedAt === primarySourceMetadata.clip_published_at;
            } else {
                return sourceName === primarySource.name;
            }
        });

        return _.map(additionalSources, function (source) {
            let data: IAdditionalSourceData = {
                favicon_url:  source.get("favicon_url"),
                name:         source.get("name"),
                clip_url:     source.get("clip_url")
            };
            let metadata = source.get("source_metadata");
            if (!_.isEmpty(metadata)) {
                data.reach = metadata.reach;
                if (!_.isUndefined(metadata.clip_published_at)) {
                    data.clipPublishedAt =
                        moment.unix(metadata.clip_published_at);
                }
            }
            return data;
        });
    }

    buildSourcesCollection(response: any) {
        this.set("sources", (new Sources(response.sources)));
        delete response.sources;
    }

    downloadableWithoutDownloadFeature(): boolean {
        return this.isMMOClip() || this.isImportedClip();
    }

    isMMOClip(): boolean {
        return (this.get("kind") === "MMOClip");
    }

    isVerbalixClip(): boolean {
        return this.get("kind") === "VerbalixClip";
    }

    isDpaClip(): boolean {
        return this.get("kind") === "DpaClip";
    }

    isGooglePlusClip(): boolean {
        return (this.get("kind") === "SMTGooglePlusClip");
    }

    isImportedClip(): boolean {
        return (this.get("kind") === "ImportedClip");
    }

    isCuriaClip(): boolean {
        return (this.get("kind") === "CuriaClip");
    }

    isDictionaryClip(): boolean {
        return (this.get("kind") === "SopranoParlamentDictionaryClip");
    }

    isSopranoParlamentSdaClip(): boolean {
        return (this.get("kind") === "SopranoParlamentChSdaClip");
    }

    isSopranoBlogClip(): boolean {
        return (this.get("kind") === "SopranoParlamentChBlogClip");
    }

    isSopranoArgusClip(): boolean {
        return (this.get("kind") === "SopranoArgusClip");
    }

    isSopranoParlamentChClip(): boolean {
        return (this.get("kind") === "SopranoParlamentChClip");
    }

    isSopranoAdminChPressReleaseClip(): boolean {
        return (this.get("kind") === "SopranoAdminChPressReleaseClip");
    }

    isSopranoAdminChConsultationClip(): boolean {
        return (this.get("kind") === "SopranoAdminChConsultationClip");
    }

    isSopranoAdminChConsultationDocumentClip(): boolean {
        return (this.get("kind") === "SopranoAdminChConsultationDocumentClip");
    }

    isSopranoAdminChOfficialCompilationClip(): boolean {
        return (this.get("kind") === "SopranoAdminChOfficialCompilationClip");
    }

    isSopranoAdminChFederalGazetteClip(): boolean {
        return (this.get("kind") === "SopranoAdminChFederalGazetteClip");
    }

    isRtvClip(): boolean {
        return (this.get("kind") === "RtvClip");
    }

    isTwitterClip(): boolean {
        return (this.get("kind") === "SMTTwitterClip");
    }

    isFacebookClip(): boolean {
        return (_.include(["SMTFacebookPostClip", "SMTFacebookPageClip", "SMTFacebookCommentClip", "SMTFacebookGroupClip"], this.get("kind")));
    }

    isYoutubeClip(): boolean {
        if (this.get("kind") === "SMTYoutubeVideoClip") {
            return true;
        }
        if (this.isImportedClip()) {
            const primarySource = this.get("primary_source");
            const youtubeRegExp =
                /^https?:\/\/www\.youtube\.com\/watch\?(?:.*&)?v=([\w-]+)([#&].*)?$/;
            let clipUrl = primarySource.clip_url;
            return clipUrl && clipUrl.match(youtubeRegExp);
        } else { return false; }
    }

    isInstagramClip(): boolean {
        return (_.include(["SMTInstagramVideoClip", "SMTInstagramImageClip"], this.get("kind")));
    }

    avProcessingDone(): boolean {
        return this.isVimeoClip() && this.get("av_processing_done");
    }

    isVimeoClip(): boolean {
        let primarySource = this.get("primary_source") || {};
        if (this.isImportedClip()) {
            let vimeoRegExp =
                /^https?:\/\/(\S+.)?vimeo.com\/(?:(video\/)|(?:channels\/.*\/))?(\d+)(\/\w+)?\/?([#?].*)?$/;
            let clipUrl = primarySource.clip_url;
            return clipUrl && clipUrl.match(vimeoRegExp);
        } else {
            const metadata = primarySource.source_metadata || {};
            let vimeoId = metadata.vimeo_id;
            return !_.isEmpty(vimeoId);
        }
    }

    isDataLakeClip(): boolean {
        return this.get("kind") === "DataLake::Clip";
    }

    /**
     * The text to show for an article. Research is still Clip centric
     * and we need to fallback on using the teaser in response.
     *
     * @returns {String} Either teaser or full text.
     */
    articleText(): string {
        let text = this.get("text") || this.get("teaser");
        return highlight(text);
    }

    hasPreview(): any {
        return this.isRadioTvClip() && !this.get("preview_limit_reached");
    }

    isRadioTvClip(): string | undefined {
        return this.get("radio_tv_clip");
    }

    isOrdered(): string | undefined {
        return this.get("ordered");
    }

    isTextClip(): boolean {
        return (this.get("kind") === "TextClip");
    }

    isOrderedOnlineClip(): any {
        return this.isOrderableOnlineClip() && this.isOrdered();
    }

    isOrderableOnlineClip(): boolean {
        let kind = this.get("kind");
        if (this.isOrderableImportedClip() || this.isDataLakeClip()) {
          return true;
        }
        return (kind === "TextClip" || kind === "OtsClip");
    }

    isOrderableImportedClip(): boolean {
      const kind = this.get('kind');
      return kind === "ImportedClip" && this.get('ordered') !== undefined;
    }

    timelineTime(): any {
      if (session.sortByPublishTimeEnabled()) {
        return moment.unix(
          this.get("publish_time") || this.get("clip_created_at")
        );
      } else {
        return moment.unix(
          this.get("clip_created_at") || this.get("publish_time")
        );
      }
    }

    youtubeContentRemoved(): boolean {
        const metadata = this.get("clip_metadata");
        if (metadata) {
            return metadata.youtube_content_removed;
        } else {
            return false;
        }
    }

    isCuttable(): boolean {
      if (this.isOrdered()) { return false; }
      if (this.isFullRtvClip()) { return false; }
      const suiteUrl = this.get("editing_suite_url");
      if (typeof suiteUrl === "string") {
        return session.isImpersonated() && this.isRtvClip() && suiteUrl.length > 0;
      } else {
        return false;
      }
    }

    isFullRtvClip(): boolean {
      const fullClip = this.primarySource().fullClip;
      if (_.isBoolean(fullClip) && this.isRtvClip()) {
        return fullClip;
      } else {
        return false;
      }
    }

    cuttingUrl(): string {
      return this.get("editing_suite_url");
    }

    isPrintSource(): boolean {
      return false;
    }
}

Clip.prototype.urlRoot = "/api/v3/clips/";

export default Clip;
