import SceneController from 'controllers/sceneController';
import ReaderEditController
    from 'controllers/newsroom/readerEditController';
import ReaderPlaceholderView
    from 'views/newsroom/readerPlaceholderView';

export default class EditSceneController extends SceneController {
    show() {
        this.controller = new ReaderEditController({
            configurationId: this.options.configurationId
        });
        this.showInLeftColumn(this.controller.view);

        const placeholder = new ReaderPlaceholderView();
        this.showInRightColumn(placeholder);
    }

    close() {
        if (this.controller && this.controller.view) {
            this.controller.view.close();
        }
    }
}
