import * as React from 'react';
import I18n from 'i18n';

export default function WidgetErrorMsg() {
  return(
    <div className="centered-notice">
      <p>{I18n.t("webapp.new_dashboard.widget_error_message.no_widgets_found")}</p>
    </div>
  );
}
