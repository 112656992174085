import ResearchResultListController from 'controllers/research/researchResultListController';
import NewResearchResultListView from 'views/research/newResearchResultListView';
import NewResearchResultApiCollection from 'collections/newResearchResultApiCollection'
import when from 'when'

class NewResearchResultListController extends ResearchResultListController {
  initialize(options) {
    this.listItemViews = {};
    this.appendTracker = options.appendTracker;

    this.clips = new NewResearchResultApiCollection([], {
      search_query: options.search_query,
      source_category_ids: options.source_category_ids,
      date: options.createdAtDate,
      startAt: options.startAt
    });

    this.view = new NewResearchResultListView({
      results: this.clips
    });

    this._registerListeners();

    this.view.render();

    let initialResultsShown = () => { this.trigger('initial-results-shown'); };
    if (options.results) {
      this.clips.add(options.results);
      this.clips.moreObjectsAvailable = !options.noMoreResults;
      this._appendItemsFinished();
      when().then(initialResultsShown);
    } else {
      this.appendItems().then(initialResultsShown);
    }
  }
}

export default NewResearchResultListController;
