import NewUserView from 'views/settings/newUserView';
import UserSettingsController from
    'controllers/settings/userSettingsController';
import usersInstance from 'collections/usersInstance';
import Notificator from 'libraries/notificator';
import I18n from 'i18n';

class NewUserController extends UserSettingsController {
    initialize(options) {
        this.user = options.user;
        this.view = new NewUserView({
            model: this.user
        });
        this._registerListeners();
        this._registerSaveUserListener();
    }

    _registerSaveUserListener() {
        let self = this;
        this.listenTo(this.view, 'save', (attributes) => {
            this.view.disableSaveButton();
            if (!this.user.save(attributes, {
                error: function () {
                    Notificator.showNotification(
                        I18n.t("webapp.notifications.warning.update_failed")
                    );
                    self.view.enableSaveButton();
                },
                success: function () {
                    usersInstance.add(self.user);
                    self.trigger("user:saved");
                }
            })) {
                Notificator.showNotification(
                    I18n.t("webapp.notifications.warning.update_failed")
                );
                this.view.enableSaveButton();
            }
        });
    }
}

export default NewUserController;
