import ItemView from 'views/reporting/reportsCollectionItemView'
import Backbone from 'backbone'
import template from 'text-loader!templates/reporting/collectionView.html'
import $ from 'jquery'
import _ from 'underscore'

var View = Backbone.Marionette.CompositeView.extend({
  itemView: ItemView,
  template: template,
  itemViewContainer: '.report-list',
  ui: {
    loader: '.reportLoader'
  },
  initialize: function () {
    _.bindAll(this, 'scrollingReports', 'finishedLoading');
    this.listenTo(this.collection, "appending:finished", this.finishedLoading);
  },
  scrollingReports: function () {
    var container = $('#main');
    var height = container.children().height();
    var bottomPixelsHidden = height - container.height() - container.scrollTop();
    if (!this.collection.allItemsLoaded() && !this.alreadyLoading() && bottomPixelsHidden <= 0) {
      this.startedLoading();
      this.collection.appendReports();
    }
  },
  onRender: function () {
    var container = $('#main');
    container.on('scroll', this.scrollingReports);
  },
  onClose: function () {
    var container = $('#main');
    container.off('scroll', this.scrollingReports);
  },
  alreadyLoading: function () {
    return $(this.ui.loader).is(":visible");
  },
  finishedLoading: function () {
    $(this.ui.loader).hide();
  },
  startedLoading: function () {
    $(this.ui.loader).show();
  }
});

export default View;

