function froalaButtons() {
    return ['bold', 'italic', 'highlight',
            '|',
            'formatUL', 'formatOL',
            '|',
            'hrButton',
            'normal',
            'heading', '|', 'insertLink', 'clearFormatting'];
}

export default froalaButtons;
