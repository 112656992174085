import Backbone from 'backbone'
import layoutTemplate from 'text-loader!templates/reporting/layout.html'
import 'backbone.marionette'

var ReportsLayout = Backbone.Marionette.Layout.extend({
  template: layoutTemplate,
  className: 'content legacy',
  id: 'container-reporting',

  regions: {
    main: '#main',
    sidebar: '#sidebar',
    toolbar: '#toolbar'
  }
});

export default ReportsLayout;

