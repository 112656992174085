import Backbone from 'backbone';
import Contact from 'models/contact';

class Contacts extends Backbone.Collection {
    parse(response) {
        return response.collection;
    }
    comparator(contact) {
        let prefix = contact.get('is_user_contact') ? '0' : '1';
        return (prefix + contact.get('name')).toLowerCase();
    }
}

Contacts.prototype.model = Contact;
Contacts.prototype.url = '/api/v3/contacts';

export default Contacts;
