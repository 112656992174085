import * as React from "react";
import I18n from "i18n";

interface Props {
  feedUrl: string
  className?: string
}

class RssFeedButton extends React.Component<Props> {
  render() {
    const key = "webapp.agents.edit.settings.rss_feed_subscribe"
    const label = I18n.t(key)
    const additional = this.props.className ? this.props.className : ""
    const classes = "button button--block align-left " + additional
    return (
      <a className={classes} href={this.props.feedUrl} target="_blank"><i className="icon-rss"></i> {label}</a>
    )
  }
}

export default RssFeedButton