import React, { useState } from 'react';
import SortableButtonList from '../../components/sortableButtonList';
import I18n from 'i18n';
import axios from 'axios';
import Notificator from "../../libraries/notificator";

export default function MediaInsights(props) {
  const { onSelection, initialInsights, configurationId, selectedId } = props;
  const [insights, setInsights] = useState(initialInsights);
  const [selectedInsightId, setSelectedInsightId] = useState(selectedId);

  const overviewKey = "overview";
  const overviewExists = insights.some(
    (i) => i.type === overviewKey
  );

  function handleInsightClick(insight) {
    onSelection(insight, overviewExists);
    setSelectedInsightId((prevId) =>
      prevId === insight.id ? null : insight.id
    );
  }

  function handleInsightClose() {
    setSelectedInsightId(null);
  }

  async function handleSortableUpdate(sortableUpdateEvent) {
    const { oldIndex, newIndex } = sortableUpdateEvent;
    const previousInsights = [...insights];
    const updatedInsights = [...insights];
    const [movedItem] = updatedInsights.splice(oldIndex, 1);
    updatedInsights.splice(newIndex, 0, movedItem);
  
    const updatedInsightsWithPosition = updatedInsights.map(
      (insight, index) => ({
        ...insight,
        position: index,
      })
    );
  
    setInsights(updatedInsightsWithPosition);
    setSelectedInsightId(null);
    const insightsSequence = updatedInsightsWithPosition.map(
      (insight) => insight.id
    );
  
    try {
      await axios.put(`/api/reader/${configurationId}/reader_insights/v1/sequence`, {
        insights_sequence: insightsSequence,
      });
    } catch (error) {
        setInsights(previousInsights);
        handleErrorResponse(error.response);  }
  }

  function handleErrorResponse(response) {
    if (response) {
      if (response.data?.error) {
        Notificator.showErrorNotification(`Error: ${(response.data.error)}`);
      } else {
        Notificator.showErrorNotification(response.statusText);
      }
    } else {
      Notificator.showErrorNotification("Error: network failure");
    }
  }

  function handleAddNewInsight() {
    const newInsight = {title: "New Insight", id: null};
    handleInsightClick(newInsight);
  }

  const modifiedInsights = insights
    .sort((a, b) => a.position - b.position)
    .map((insight) => ({
      ...insight,
      get: (key) => insight[key],
      className: insight.id === selectedInsightId ? 'is-active' : '',
      key: insight.id,
    }));

  const insightProps = {
    sortableEnabled: true,
    selectedObject:
      modifiedInsights.find((insight) => insight.id === selectedInsightId) ||
      null,
    sortableListItems: modifiedInsights,
    sortableGroup: "MediaInsights",
    listItemName: "title",
    listItemObjectId: "id",
    listItemPosition: "position",
    listItemObjectNamePostfix: (insight) => {
      const key = `webapp.reader_admin.edit.media_insights.type_${insight.type.toLowerCase()}`;
      return I18n.t(key);
    },
    header: I18n.t("webapp.reader_admin.edit.media_insights.header"),
    subText: "beta",
    addObjectText: I18n.t(
      "webapp.reader_admin.edit.media_insights.add_media_insights"
    ),
    onObjectClick: handleInsightClick,
    onObjectClose: handleInsightClose,
    onAddNewObject: handleAddNewInsight,
    onSortableUpdate: handleSortableUpdate,
  };

  return (
    <div>
      <SortableButtonList {...insightProps} />
    </div>
  );
}
