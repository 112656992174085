import * as Backbone from 'backbone';
import * as moment from 'moment';
import weekdaySort from 'libraries/weekdaySort';

class ReaderNotificationSchedule extends Backbone.Model {
    public urlRoot: () => string;

    constructor(params: any) {
        super(params);
        this.urlRoot = () => {
            return '/api/v3/notification_configurations/' +
                this.get('reader_notification_configuration_id') +
                '/notification_schedules/';
        }
        if (this.get('weekdays') == undefined) {
            this.set('weekdays', [1, 2, 3, 4, 5]);
        }
        if (this.get('time') == undefined) {
            this.set('time', moment.utc("2000-01-01 00:00 +00:00"));
        }
    }

    // From API endpoint
    parse(response: any) {
        response.weekdays = response.weekdays.sort(weekdaySort);
        return response;
    }

    // To API endpoint
    toJSON() {
        const fullJson = Backbone.Model.prototype.toJSON.call(this);
        const time = fullJson.time;
        fullJson.weekdays = fullJson.weekdays.sort(weekdaySort);
        if (time.length > 5) {
            fullJson.time = moment.utc(
                time, "YYYY/MM/DD HH:mm:ss Z"
            ).format('HH:mm');
        }
        return {
            notification_schedule: fullJson
        };
    }
}

export default ReaderNotificationSchedule;
