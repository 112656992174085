import Marionette from 'backbone.marionette'
import $ from 'jquery'
import _ from 'underscore'
import template from 'text-loader!templates/agents/metadata/mmo.html'
import localizedLanguages from 'libraries/localizedLanguages'
import agentResultReadingTime from 'libraries/agentResultReadingTime'

var MMOView = Marionette.Layout.extend({
  regions: {
  },
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    return {
      mmoMetadata: this.metadata()
    };
  },
  metadata: function () {
    var agentResult = this.agentResult;

    if (agentResult.isMMOClip()) {
      var clipMetadata = agentResult.get('clip_metadata');
      var primarySource = agentResult.get('primary_source');
      var languageCodes = this.agentResult.get('languages');

      var phoneNumber, publicationFrequency, reach;
      if (primarySource && primarySource.source_metadata) {
        phoneNumber = primarySource.source_metadata.phone_number;
        publicationFrequency = primarySource.source_metadata.media_type;
        reach = primarySource.source_metadata.reach;
      }

      var article_ad_price;
      if(clipMetadata && _.has(clipMetadata, "article_ad_price") && clipMetadata.article_ad_price !== null) {
        article_ad_price = $.trim(clipMetadata.article_ad_price.replace("Werbewert", ""));
      }

      var source_pages;
      if(clipMetadata && _.has(clipMetadata, "source_pages") && clipMetadata.source_pages !== null) {
        source_pages = $.trim(clipMetadata.source_pages.replace("Seite", ""));
      }
      return {
        reach: {
          circulation: reach,
          source_pages: source_pages,
          source_article_area: clipMetadata.source_article_area,
          article_ad_price: article_ad_price
        },
        information: {
          phone_number: phoneNumber,
          publication_frequency: publicationFrequency,
          languages: localizedLanguages(languageCodes),
          reading_time: agentResultReadingTime(this.agentResult)
        }
      };

    } else {
      return null;
    }
  }
});

export default MMOView;
