import _ from 'underscore'
import I18n from 'i18n'

function localizedLanguages(languageCodes) {
  return _.map(languageCodes, function (code) {
    return I18n.t("vendor.iso.languages." + code);
  }).join(", ");
}

export default localizedLanguages;

