import _ from 'underscore'

function convertNewlinesToParagraphs(text, lang) {
  var paragraphs = text.split(/\r\n\r\n|\n\n/);

  var wrapped = _.map(paragraphs, function(paragraph){
    if (paragraph.length > 0 && lang) {
      return "<p class='hyphenate' lang='" + lang + "'>" + paragraph + "</p>";
    }
    else if (paragraph.length > 0) {
      return "<p>" + paragraph + "</p>";
    }
  });

  return _.compact(wrapped).join("");
}

export default convertNewlinesToParagraphs;
