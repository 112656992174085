import * as React from "react";
import * as ReactDOM from "react-dom";
import * as moment from "moment";
import * as _ from "underscore";
import I18n from "i18n";

interface IDatePickerSetupProps {
  startDate?: Date;
  autoclose?: boolean;
  endDate?: Date;
  orientation?: string;
  language?: string;
  format?: string;
  weekStart?: number;
  daysOfWeekDisabled?: Array<number>;
  clearBtn?: boolean;
}

interface IDatePickerProps {
  onAdd: (date: string) => void;
  selected?: string;
  inputClassNames?: Array<string>;
  datePickerSetup?: IDatePickerSetupProps;
  selectionDisabled?: boolean;
  inputDisabled?: boolean;
}

export default class DatePicker extends React.Component<IDatePickerProps> {
  datePickerInput: HTMLInputElement | null;
  datePickerButton: HTMLElement | null;

  constructor(props: IDatePickerProps) {
      super(props);
      this.datePickerInput = null;
      this.datePickerButton = null;
  }

  render() {
    let inputClassNames = [
      "text-input", "input-group__item-input", "date-picker__input"
    ];

    if (this.props.inputClassNames) { inputClassNames = this.props.inputClassNames; }

    let klass = "input-group__item-action"
    if (this.selectionEnabled()) {
      klass += " input-group__item-action--add"
    }

    return (
      <div>
        <form className="input-group__item-action">
          <input className={inputClassNames.join(" ")}
                 ref={(element) => { this.datePickerInput = element; }}
                 defaultValue={this.props.selected} data-placeholder=""
                 disabled={this.props.inputDisabled}
                 type="text" />
        </form>
        <div className="input-group__item-actions">
          <span className={klass}
                ref={(element) => { this.datePickerButton = element; }}>
          </span>
        </div>
      </div>
    );
  }

  private selectionEnabled(): boolean {
      return this.props.selectionDisabled ? false : true;
  }

  componentDidMount() {
    const datePicker = jQuery((this.datePickerInput as any));
    const dateButton = jQuery((this.datePickerButton as any));
    const defaultPickerOptions = {
      startDate: new Date(2009, 1, 1),
      autoclose: true,
      endDate: "+5y",
      orientation: "auto",
      language: I18n.locale,
      format: "yyyy-mm-dd",
      weekStart: 1,
      daysOfWeekDisabled: [],
      clearBtn: false
    };
    const pickerOptions = _.extend({}, defaultPickerOptions, this.props.datePickerSetup);
    (datePicker as any).bootstrapDatepicker(pickerOptions);

    dateButton.click(() => { datePicker.focus(); });
    datePicker.on("changeDate", () => {
      const date = (datePicker as any).bootstrapDatepicker("getDate");
      this.props.onAdd(moment(date, "DD.MM.YYYY").format("YYYY/MM/DD"));
    });
    if (!this.selectionEnabled()) { (datePicker as any).prop("disabled", true); }
  }
}
