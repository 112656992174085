import Marionette from 'backbone.marionette'
import _ from 'underscore'
import DateFormatter from 'libraries/dateFormatter'
import template from 'text-loader!templates/settings/timeSelector.html'

var TimeSelectorView = Marionette.ItemView.extend({
  template: template,
  className: 'time-selector',
  ui: {
    value: '[data-value]'
  },
  initialize: function (options) {
    this._options = options;
  },
  triggers: {
    'click [data-trigger=previous]': 'previous',
    'click [data-trigger=next]': 'next'
  },
  events: {
    'click [data-dropdown-option]': '_dropdownOptionClicked'
  },
  setValue: function (value) {
    this._value = value;
    this._showValue();
  },
  _showValue: function () {
    if (!_.isString(this.ui.value)) {
      this.ui.value.html(this._formattedValue());
    }
  },
  onRender: function () {
    this._showValue();
  },
  serializeData: function () {
    var options = _.map(this._options, (option, index) => {
      return {
        text: this._formatOption(option),
        index: index
      };
    }, this);
    return {
      options: options
    };
  },
  _formattedValue: function () {
    if (!_.isUndefined(this._value)) {
      return this._formatOption(this._value);
    }
  },
  _formatOption: function (option) {
    return DateFormatter.formatTime(option);
  },
  _dropdownOptionClicked: function (event) {
    var index = parseInt(this.$(event.target).attr('data-dropdown-option'), 10);
    var option = this._options[index];
    this.trigger('selected', option);
  }
});

export default TimeSelectorView;

