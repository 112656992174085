import Marionette from 'backbone.marionette'
import users from 'collections/usersInstance'
import UserListView from 'views/settings/userListView'
import session from 'models/sessionInstance'

var UserListController = Marionette.Controller.extend({
  initialize: function () {
    this.view = new UserListView({users: users});

    this.listenTo(this.view, 'after:item:added', this._handleListChange)
    this.listenTo(this.view, 'item:removed', this._handleListChange)

    this.listenTo(this.view, 'user:selected', (user) => {
      this.trigger('user:selected', user);
    });

    this.listenTo(this.view, 'user:add', () => {
      this.view.deselectAll();
      this.trigger('user:addClicked');
    });

    this.listenTo(this.view, 'user:deselected', () => {
      this.trigger('user:deselected');
    });
  },
  deselectAll: function () {
    this.view.deselectAll();
  },
  selectUser: function (user) {
    this.view.selectUser(user);
  },
  _handleListChange: function () {
    if (session.userLimitReached(users)) {
      this.view.removeCreateUserButton();
    } else {
      this.view.addCreateUserButton();
    }
  }
});

export default UserListController;

