import * as React from 'react';
import { BugsnagErrorBoundary } from "libraries/bugsnag";
import UnexpectedErrorMsgWidget from './UnexpectedErrorMsgWidget';

interface Props {
    children: any
    className?: string;
    title: string;
}

export default function WidgetContainer({ children, className, title, ...itemStyle }: Props) {
    return (
        <div className={`${className} dashboard-widget lightened-container`} {...itemStyle}>
            <BugsnagErrorBoundary FallbackComponent={renderError.bind(null, title)}>
                {children}
            </BugsnagErrorBoundary>
        </div>
    );
}

function renderError(title: string) {
    return (<div className="dashboard-widget__headline">
        {title}
        <UnexpectedErrorMsgWidget />
    </div>);
}