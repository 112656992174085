import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/geochartSwitzerlandWidget.html'
import notSupportedTemplate from 'text-loader!templates/dashboard/widget/geochartSwitzerlandWidgetNotSupported.html'
import session from 'models/sessionInstance'
import _ from 'underscore'
import AgentTagWidgetView from 'views/dashboard/agentTagWidgetView'
import I18n from 'i18n'
import $script from 'scriptjs'

WidgetView.GeochartSwitzerlandWidget = AgentTagWidgetView.extend({
  initialize: function () {
    _.bindAll(this, "_drawVisualization");
  },
  getTemplate: function () {
    if (this.isWidgetSupportedByBrowser()) {
      return template;
    } else {
      return notSupportedTemplate;
    }
  },
  prepareWidgetBody: function () {
    if (this.isWidgetSupportedByBrowser()) {
      this.drawVisualization();
    }
  },
  emptyCantonData: {'Aargau' : 0,
                    'Appenzell Innerrhoden' : 0,
                    'Appenzell Ausserrhoden' : 0,
                    'Bern' : 0,
                    'Basel-Landschaft' : 0,
                    'Basel-Stadt' : 0,
                    'Freiburg' : 0,
                    'Genf' : 0,
                    'Glarus' : 0,
                    'Graubünden' : 0,
                    'Jura' : 0,
                    'Luzern' : 0,
                    'Neuenburg' : 0,
                    'Nidwalden' : 0,
                    'Obwalden' : 0,
                    'St. Gallen' : 0,
                    'Schaffhausen' : 0,
                    'Solothurn' : 0,
                    'Schwyz' : 0,
                    'Thurgau' : 0,
                    'Tessin' : 0,
                    'Uri' : 0,
                    'Waadt' : 0,
                    'Wallis' : 0,
                    'Zug' : 0,
                    'Zürich' : 0
                   },
  completeCantonData: function (cantonData) {
    var data = _.extend({}, this.emptyCantonData, _.object(cantonData));
    return _.pairs(data);
  },
  maxCantonValue: function (cantonData) {
    var max = _.max(_.map(cantonData, function (canton) {return canton[1]; }));
    if (!max) { max = 0; }
    return _.max([max, 1]);
  },
  colorThemes: {
    'default': {
      colors: ['#ffffff', '#7e95a8', '#5481a8', '#2a6da8', '#005aa8', '#002d54'],
      background: 'transparent'
    },
    swiss: {
      colors: ['#ffffff', '#f6cccc', '#ec9999', '#e36666', '#d93333', '#d00000'],
      background:  'white'
    }
  },
  drawVisualization: function () {
    $script('//www.google.com/jsapi', () => {
      /* global google */
      google.load('visualization','1',{packages:['corechart', 'geochart'], callback: this._drawVisualization})
    })
  },
  _drawVisualization: function () {
    var articleLabel =
          I18n.t("webapp.dashboard.widget_content.geochart_article_count");
    var labels = [['State', articleLabel]];
    var originalCantonData = this.model.get('chart_data').cantons;
    var chartData = labels.concat(this.completeCantonData(originalCantonData));
    var data = google.visualization.arrayToDataTable(chartData);
    var max = this.maxCantonValue(originalCantonData);

    var colorTheme = this.colorThemes[session.get('dashboard_theme')];
    var colors = colorTheme.colors;
    var background = colorTheme.background;

    var geochart = new google.visualization.GeoChart(this.$('#visualization')[0]);
    geochart.draw(
      data, {
        backgroundColor: {
          fill: 'transparent'
        },
        width: 455,
        height: 330,
        region: "CH",
        datalessRegionColor: background,
        colorAxis: {
          minValue: 0,
          maxValue: max,
          colors: colors
        },
        displayMode: "auto",
        resolution: "provinces"
      }
    );
  },
  truncatedLabel: function () {
    var label = this.model.get('name');
    return (label.length > 60) ? label.substr(0, 60) + " …" : label;
  },
  prepareSerializedData: function () {
    return {
      name: this.truncatedLabel(),
      clip_count: this.model.get('chart_data').total,
      theme: this.getThemeCSSClass(),
      swiss: (session.get('dashboard_theme') === 'swiss')
    };
  },
  getThemeCSSClass: function () {
    if (session.get('dashboard_theme') === 'swiss') {
      return 'swiss-widget';
    }
  },
  isWidgetSupportedByBrowser: function () {
    // detect IE version
    // stolen from: http://james.padolsey.com/javascript/detect-ie-in-js-using-conditional-comments/
    // and edited so JSHint doesn’t die of pain
    var ie = (function () {

      var undef,
          v = 3,
          div = document.createElement('div'),
          all = div.getElementsByTagName('i'),
          finished = false;

      while (!finished) {
        div.innerHTML = '<!--[if gt IE ' + (++v) + ']><i></i><![endif]-->';
        finished = !all[0];
      }

      return v > 4 ? v : undef;

    }());

    // not working in IE8 and below
    return _.isUndefined(ie) || ie > 8;
  }
});

export default WidgetView.GeochartSwitzerlandWidget;

