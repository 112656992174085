import Marionette from 'backbone.marionette';
import SourceExclusionsCollectionView from
    'views/sourceExclusionsCollectionView';
import Notificator from 'libraries/notificator';
import session from 'models/sessionInstance';
import SourceExclusionSearchPropFactory from "components/sourceExclusionSearchPropFactory";

class SourceExclusionsController extends Marionette.Controller {
    initialize() {
        this.sourceExclusions = this.options.sourceExclusions;
        this.view = new SourceExclusionsCollectionView({
            collection: this.sourceExclusions
        });

        this.propFactory = new SourceExclusionSearchPropFactory(
            this.options.sourceExclusions,
            (source) => {
                this.sourceExclusions.add({ id: source.id, name: source.get('name') });
                this.view.showSearchForm(this.propFactory.generate());
            }
        );

        this._registerEventListeners();
    }

    _registerEventListeners() {
        let self = this;

        this.listenTo(this.view, 'render', () => {
            this.view.showSearchForm(this.propFactory.generate());
            this._maybeHideExclusionSearch();
        });

        this.listenTo(this.view, 'close', () => {
            this.close();
        });

        this.listenTo(this.view, 'itemview:clicked:remove', (itemView, args) => {
            this.sourceExclusions.remove(args.model);
        });

        this.listenTo(this.sourceExclusions, 'remove', (exclusion) => {
            exclusion.destroy({
                success: function () {
                    self._maybeShowExclusionSearch();
                }
            });
        });
        this.listenTo(this.sourceExclusions, 'add', function (exclusion) {
            exclusion.save(null, {
                success: function () {
                    self._maybeHideExclusionSearch();
                },
                error: function(_, data) {
                    var error = JSON.parse(data.responseText).error;
                    Notificator.showNotification(error);
                }
            });
        });
    }

    _maybeHideExclusionSearch() {
        if (this._isExclusionLimitReached()) {
            this.view.hideSearchForm();
        }
    }

    _maybeShowExclusionSearch() {
        if (!this._isExclusionLimitReached()) {
            this.view.displaySearchForm();
        }
    }

    _isExclusionLimitReached() {
        return this.sourceExclusions.length >=
            session.get('limits').source_exclusions_per_agent;
    }
}

export default SourceExclusionsController;
