import $ from 'jquery';
import vent from 'libraries/vent';
import notificator from 'libraries/notificator';
import I18n from 'i18n';

/** Central place for global application state */
class AppState {
    isBusy() {
        return this.isAppBusy;
    }

    requestStart() {
        if (!this.isAppBusy) {
            this.isAppBusy = true;
            this._disableFunctionality();
        }
    }

    requestCompleted() {
        this.isAppBusy = false;
        this._enableFunctionality();
    }

    displayWarning() {
        var key = 'webapp.notifications.reader.wait_for_pending_actions';
        notificator.showNotification(I18n.t(key));
    }

    _disableFunctionality() {
        vent.trigger('disableSortable');
        this._setCursor();
    }

    _enableFunctionality() {
        vent.trigger('enableSortable');
        this._resetCursor();
    }

    _setCursor() {
        $('body').addClass('cursor-progress');
    }

    _resetCursor() {
        $('body').removeClass('cursor-progress');
    }

}

export default new AppState();
