import Config from 'libraries/configuration';
import Backbone from 'backbone';
import SourceGroup from 'models/sourceGroup';

class SourceGroups extends Backbone.Collection {
    url() {
        return Config.apiUrl + "sources/source_groups/";
    }
    parse(response) {
        return response.collection;
    }
}

SourceGroups.prototype.model = SourceGroup;

export default SourceGroups;
