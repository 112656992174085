import App from 'app'
import EventLogger from 'libraries/eventLogger'
import when from 'when'
import configureNavigationModule from 'modules/configureNavigationModule'
import setupNavigationModule from 'modules/setupNavigationModule'
import AgentsController from 'controllers/agents/agentsController'
import I18n from 'i18n'

App.module('Agents', {
  startWithParent: false,
  define: function (Agents) {
    EventLogger.logEventsOn(App.Agents, 'Agents');
    configureNavigationModule(App.Agents, 'agents');
    setupNavigationModule(this, AgentsController);

    // Set the title only when module is started
    // The locales might not be loaded before
    var self = this;
    Agents.addInitializer(function () {
      self.name = I18n.t("webapp.agents.title");
    });

    Agents.loadedPromise = function () {
      if (!this._loadedPromise) {
        this._loadedPromise = when.promise(function (resolve) {
          Agents.listenTo(Agents, 'loaded', resolve);
        });
      }
      return this._loadedPromise;
    };
  }
});

export default App.Agents;

