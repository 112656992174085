import * as React from 'react';
import * as ReactDOM from 'react-dom';
import I18n from 'i18n';
import * as _ from 'underscore';
import * as jQuery from 'jquery';
import * as moment from 'moment';
import DatePicker from "components/datePicker";

interface IExcludedDateProps {
    date: string
    onDateExclusionRemove: (date: string) => void
}

interface IDateExclusionViewProps {
  excludedDates: string[]
  onDateExclusionRemove: (date: string) => void
  onDateExclusionAdd: (date: string) => void
  selectionDisabled?: boolean;
}

class ExcludedDate extends React.Component<IExcludedDateProps> {
    render() {
        const formattedDate =
          moment(this.props.date, "YYYY/MM/DD").format("DD.MM.YYYY");
        return (
            <li className="input-group__item">
              <div className="input-group__item-field">
                {formattedDate}
              </div>
              <div className="input-group__item-actions">
                <span className="input-group__item-action input-group__item-action--remove"
                      onClick={this.handleExcludedDateRemoval.bind(this)}>
                </span>
              </div>
            </li>
        );
    }

    handleExcludedDateRemoval() {
        this.props.onDateExclusionRemove(this.props.date);
    }
}

class DateExclusionView extends React.Component<IDateExclusionViewProps> {
    render() {
        const datePickerProps = {
          onAdd: this.props.onDateExclusionAdd,
          selectionDisabled: this.props.selectionDisabled
        };
        return (
            <ul className="input-group input-group--compact">
              {this.renderExcludedDates()}
              <li className="input-group__item">
                <DatePicker {...datePickerProps} />
              </li>
            </ul>
        );
    }

    renderExcludedDates() {
        return _.map(this.props.excludedDates, (excludedDate, idx) => {
            const excludedDateProps = {
                onDateExclusionRemove: this.props.onDateExclusionRemove,
                date: excludedDate,
                key: idx
            };
            return <ExcludedDate {...excludedDateProps} />;
        });
    }
}

export default DateExclusionView;
