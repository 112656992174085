import * as React from "react";
import session from "models/sessionInstance";
import { connect } from "react-redux";
import { Store } from "../redux/store";
import { updateSubscription, UserAgentItem } from "../redux/searchAgents";
import { User } from "../redux/user";
import Notificator from "../../../libraries/notificator";
import I18n from "i18n";
import DateFormatter from "../../../libraries/dateFormatter";
import * as moment from "moment";
import NoDataAvailableError from "./NoDataAvailableError";

interface Props {
  user: User;
  updateSubscription(userId: number, agentId: number): any;
  className?: string;
  data: SearchAgentItem[];
  title: string;
  searchAgents: UserAgentItem[];
}

interface State {
  agentId: number;
}

export interface SearchAgentItem {
  id: number;
  name: string;
  created_at: moment.Moment | Date;
  subscribed: boolean;
  search_active: boolean;
}

class TableWidget extends React.PureComponent<Props, State> {
  state = {
    agentId: -1
  };

  componentDidUpdate({ searchAgents }: Props) {
    if (this.props.searchAgents !== searchAgents) {
      for (const item of this.props.searchAgents) {
        if (
          this.state.agentId === item.agentId &&
          item.userIds.includes(this.props.user.id)
        ) {
          Notificator.showNotification(
            I18n.t("webapp.new_dashboard.table_widget.subscription_successful")
          );
          this.setState({ agentId: -1 });
        }
      }
    }
  }

  checkSubscription(agentId: number, userId: number) {
    for (const agent of this.props.searchAgents) {
      if (agent.agentId === agentId && agent.userIds.includes(userId)) {
        return true;
      }
    }
    return false;
  }

  updateSubscritpion = (agentId: number) => {
    this.setState({ agentId });
    this.props.updateSubscription(this.props.user.id, agentId);
  };

  render() {
    const {
      className,
      title,
      data,
      user,
      updateSubscription,
      searchAgents,
      ...itemStyle
    } = this.props;

    return (
      <div
        className={`dashboard-widget__container`}
        {...itemStyle}>
        <h3 className="dashboard-widget__headline">{title}</h3>
        <div className="dashboard-widget__padding">
          {data.length > 0  && data.filter(i => i.search_active).length > 0 ? (
            <table className="table">
              <thead>
                <tr className="table__header">
                  <td className="table__header table__data">
                    {I18n.t(
                      "webapp.new_dashboard.table_widget.table_header.Created"
                    )}
                  </td>
                  <td className="table__header table__data">
                    {I18n.t(
                      "webapp.new_dashboard.table_widget.table_header.Search_Agents"
                    )}
                  </td>
                  <td className="table__header table__data" />
                </tr>
              </thead>
                <tbody>
                {data.map(searchAgent => ( 
                  searchAgent.search_active &&
                  <tr key={searchAgent.id.toString()} className="table__row">
                    <td className="table__data">
                      {DateFormatter.formatRelativeListDateTime(
                        searchAgent.created_at
                      )}
                    </td>
                    <td className="table__data">{searchAgent.name}</td>
                    <td className="table__data align-right">
                      {session.hasFeature("new_search_agent_management") ? (
                        <span className="table__actions">
                          {!searchAgent.subscribed &&
                            !this.checkSubscription(searchAgent.id, user.id) && (
                              <i
                                className="table__actions--icon icon-eye"
                                onClick={this.updateSubscritpion.bind(
                                  this,
                                  searchAgent.id
                                )}
                              />
                            )}
                        </span>
                      ) : null}
                    </td>
                  </tr>
              ))}
                </tbody>
            </table>
          ) : <NoDataAvailableError />}
        </div>
      </div>
    );
  }
}

export default connect(
  function({ user, searchAgents }: Store) {
    return { user, searchAgents };
  },
  { updateSubscription }
)(TableWidget);
