import Handlebars from 'handlebars'
import _ from 'underscore'
import DateFormatter from 'libraries/dateFormatter'
import I18n from 'i18n'
import truncate from 'libraries/truncate'

Handlebars.registerHelper('truncate', truncate);

/*
 * Handlebar helpers to translate using I18n js.
 *
 * Use like this in views: {{t "translate.this"}}
 * Use like this in views: {{l "number" 1000000}}
 */
Handlebars.registerHelper('t', function (...args) { return I18n.t.apply(I18n, [args[0], _.last(args).hash]); });
Handlebars.registerHelper('l', function (...args) { return I18n.l(...args); });

/*
 * Handlebars helper for number formatting
 *
 * Use like this in views:
 *
 *    {{number 2254.3 }}
 *    {{number 225434 precision=0 }}
 */
Handlebars.registerHelper('number', function (...args) {
  return I18n.toNumber(_.first(args), _.last(args).hash);
});

/*
 * Handlebars helper for percentual number formatting
 *
 * Use like this in views:
 *
 *    {{percent 2254.3 }}
 *    {{percent 225434 precision=0 }}
 */
Handlebars.registerHelper('percent', function (...args) {
  return I18n.toPercentage(_.first(args), _.last(args).hash);
});

function safe(string) {
  return new Handlebars.SafeString(string);
}

/*
 * A Handlebars helper to format dates (14.07.2012).
 *
 * You can pass in a Moment.js or Date object or any string
 * Moment.js can parse.
 *
 * Use like this in views: {{formatDate date}}
 */
Handlebars.registerHelper('formatDate', function (date) {
  return safe(DateFormatter.formatDate(date));
});

/*
 * A Handlebars helper to format dates (13.11.2015).
 *
 * You can pass in a Moment.js or Date object or any string
 * Moment.js can parse. Does not use localized formatting.
 *
 * Use like this in views: {{formatForDatePicker date}}
 */
Handlebars.registerHelper('formatForDatePicker', function (date) {
  return safe(DateFormatter.formatForDatePicker(date));
});

/*
 * A Handlebars helper to format times (13:24).
 *
 * You can pass in a Moment.js or Date object or any string
 * Moment.js can parse.
 *
 * Use like this in views: {{formatTime time}}
 */
Handlebars.registerHelper('formatTime', function (time) {
  return safe(DateFormatter.formatTime(time));
});

/*
 * A Handlebars helper to format date times (14.11.2012 13:24).
 *
 * You can pass in a Moment.js or Date object or any string
 * Moment.js can parse.
 *
 * Use like this in views: {{formatDateTime dateTime}}
 */
Handlebars.registerHelper('formatDateTime', function (dateTime) {
  return safe(DateFormatter.formatDateTime(dateTime));
});

/*
 * A Handlebars helper to format dates and times with a custom format.
 *
 * You can pass in a Moment.js or Date object or any string
 * Moment.js can parse.
 *
 * Use like this in views: {{formatCustomDateTime dateTime "DD.MM."}}
 */
Handlebars.registerHelper('formatCustomDateTime', function (dateTime, formatString) {
  return safe(DateFormatter.format(dateTime, formatString));
});

function dateContainer(date, format) {
  var timestamp = DateFormatter.formatTimestamp(date);
  var formatter = {
    date: DateFormatter.formatRelativeDate,
    dateTime: DateFormatter.formatRelativeDateTime,
    listDateTime: DateFormatter.formatRelativeListDateTime
  }[format];
  return [
    '<span ',
    'data-date-auto-update ',
    'data-date-format="' + format + '" ',
    'data-timestamp="' + timestamp + '">',
    formatter(date),
    '</span>'
  ].join('');
}

Handlebars.registerHelper('formatRelativeDateTime', function (dateTime) {
  return safe(dateContainer(dateTime, 'dateTime'));
});

Handlebars.registerHelper('formatRelativeListDateTime', function (dateTime) {
  return safe(dateContainer(dateTime, 'listDateTime'));
});

Handlebars.registerHelper('formatRelativeDate', function (date) {
  return safe(dateContainer(date, 'date'));
});
