import Marionette from 'backbone.marionette'
import AgentResultRatingView from 'views/agents/agentResultRatingView'
import _ from 'underscore'
import Shortcutter from 'libraries/shortcutter'
import vent from "libraries/vent"

var AgentResultRatingController = Marionette.Controller.extend({
  initialize: function (selectedAgentResults) {
    this.selectedAgentResults = selectedAgentResults;
    this.agentResults = selectedAgentResults.collection;
    this.view = new AgentResultRatingView();

    this._registerListeners();
    this._bindShortcuts();
  },
  _registerListeners: function () {
    _.bindAll(this, 'saveRating', 'updateViewButtons');
    this.listenTo(this.view, 'clicked.rate_positive',
                  this.positiveButtonClickHandler);
    this.listenTo(this.view, 'clicked.rate_neutral',
                  this.neutralButtonClickHandler);
    this.listenTo(this.view, 'clicked.rate_negative',
                  this.negativeButtonClickHandler);
    this.listenTo(this.view, 'render', this.updateViewButtons);
  },
  onClose: function () {
    this._unbindShortcuts();
  },
  setRating: function (rating) {
    // Store previous model values in case update fails
    this.agentResults.each(function (agentResult) {
      agentResult.store();
    });

    // If we clicked the same button twice, reset the rating
    var newRatingValue;
    if (this.currentRating() === rating) {
      newRatingValue = null;
    } else {
      newRatingValue = rating;
    }
    this.agentResults.each(function (agentResult) {
      agentResult.set('rating_value', newRatingValue);
    });
    this.selectedAgentResults.set('rating_value', newRatingValue);
    vent.trigger(
      'changed:resultSentiment',
      this.agentResults.map((r) => r.get('id')),
      newRatingValue,
    );

    this.updateViewButtons();

    return newRatingValue;
  },
  currentRating: function () {
    if (this.haveSameRating(this.agentResults)) {
      var ratingValue = this.agentResults.first().get('rating_value');
      return ratingValue;
    } else {
      return null;
    }
  },
  haveSameRating: function (agentResultsCollection) {
    if (agentResultsCollection.length === 1) {
      return true;
    } else {
      var ratingValue =
            agentResultsCollection.first().get('rating_value');
      return agentResultsCollection.every(function (agentResult) {
        return agentResult.get('rating_value') === ratingValue;
      });
    }
  },
  positiveButtonClickHandler: function () {
    var rating = this.setRating(10);
    this.saveRating(rating);
  },
  neutralButtonClickHandler: function () {
    var rating = this.setRating(0);
    this.saveRating(rating);
  },
  negativeButtonClickHandler: function () {
    var rating = this.setRating(-10);
    this.saveRating(rating);
  },
  updateViewButtons: function () {
    var rating = this.currentRating();

    if (rating > 0) {
      this.view.ratePositive();
    } else if (rating === 0) {
      this.view.rateNeutral();
    } else if (rating < 0) {
      this.view.rateNegative();
    } else {
      this.view.deselect();
    }
  },
  saveRating: function (rating) {
    // Restore stored model values on update failure
    var self = this;
    this.selectedAgentResults.save({
      agent_result: {
        rating_value: rating
      }
    }, {
      patch: true,
      error: function () {
        self.agentResults.each(function (agentResult) {
          agentResult.restore();
        });
        self.updateViewButtons();
      }});
  },
  _bindShortcuts: function () {
    var self = this;
    Shortcutter.bindInModule('agents', ['+', '3'], function () {
      self.positiveButtonClickHandler();
    });
    Shortcutter.bindInModule('agents', ['=', '2'], function () {
      self.neutralButtonClickHandler();
    });
    Shortcutter.bindInModule('agents', ['-', '1'], function () {
      self.negativeButtonClickHandler();
    });
  },
  _unbindShortcuts: function () {
    Shortcutter.unbindInModule('agents', ['+', '3', '=', '2', '-', '1']);
  }
});

export default AgentResultRatingController;
