import Marionette from 'backbone.marionette'
import vent from 'libraries/vent'
import session from 'models/sessionInstance';
import template from 'text-loader!templates/apogeeLayout.html'

var ApogeeLayout = Marionette.Layout.extend({
  template: template,
  regions: {
    sidebar: '#sidebar-content',
    leftContainer: '#left',
    rightContainer: '#right'
  },
  ui: {
    leftColumn: '[data-layout-column=left]',
    rightColumn: '[data-layout-column=right]'
  },
  initialize: function () {
    this.listenTo(vent, 'layout:focus-left', () => {
      this.focusLeft();
    });
    this.listenTo(vent, 'layout:focus-right', () => {
      this.focusRight();
    });
  },
  onRender: function () {
    this.ui.leftColumn.scroll(function () {
      vent.trigger('layout:scroll-left');
    });
    if (session.timeline2Enabled()) {
      this.ui.leftColumn.addClass('is-timeline-2');
    }
  },
  showSidebar: function (view) {
    this.sidebar.show(view);
  },
  showLeftContainer: function (view) {
    this.leftContainer.show(view);
  },
  showRightContainer: function (view) {
    this.rightContainer.show(view);
    this.ui.rightColumn.scrollTop(0);
  },
  focusLeft: function () {
    this.ui.leftColumn.removeClass('handheld-hidden');
    this.ui.rightColumn.addClass('handheld-hidden');
  },
  focusRight: function () {
    this.ui.rightColumn.removeClass('handheld-hidden');
    this.ui.leftColumn.addClass('handheld-hidden');
  }
});

export default ApogeeLayout;
