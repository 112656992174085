import Marionette from 'backbone.marionette'
import EditAgentSourceSelectionView from 'views/agents/settings/editAgentSourceSelectionView'
import _ from 'underscore'
import { AgentsPermissions } from "components/agents/permissions";

var EditAgentSourceSelectionController = Marionette.Controller.extend({
  initialize: function () {
    this.agent = this.options.agent;
    this.view = new EditAgentSourceSelectionView({
      model: this.options.agent
    });
    this._registerListeners();
    this.timeframeProps = this.options.timeframeProps;
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'render', () => {
        this._showAgentTimeframeButton(this.timeframeProps);
    });
  },
  deselectTimeframeSelection() {
    this._showAgentTimeframeButton({ isActive: false });
  },
  deselectSourceSelections() {
    this.view.deselectSourceSelections();
  },
  deselectAll() {
    this.view.deselectSourceSelections();
    this.deselectTimeframeSelection();
  },
  _showAgentTimeframeButton(props) {
    if (AgentsPermissions.canChangeAgentTimeframe(this.agent)) {
        this.currentProps = {
            onButtonClick: () => {
                this.currentProps = _.extend(
                    {}, this.currentProps, { isActive: !this.currentProps.isActive }
                );
                this.view.showTimeframeSettings(this.currentProps);
                if (this.currentProps.isActive) {
                    this.trigger('open:agentTimeframe');
                } else {
                    this.trigger('close:agentTimeframe');
                }
            },
            startDate: this.agent.get('search_start_date') ||
                       this.agent.get('created_at') * 1000,
            endDate: this.agent.get('search_end_date'),
            isActive: false
        };
        let overwrittenProps = _.extend({}, this.currentProps, props);
        this.view.showTimeframeSettings(overwrittenProps);
    }
  },
});

export default EditAgentSourceSelectionController;
