import Widget from 'models/dashboard/widget'
import moment from 'moment'
import _ from 'underscore'

Widget.TickerWidget = Widget.extend({
  parse: function (response) {
    var parsedResponse = Widget.prototype.parse(response);
    _.each(parsedResponse.clips, function (clip) {
      clip.time = moment.unix(clip.time);
    });
    return parsedResponse;
  },
  agentIds: function () {
    return this.get('agent_ids');
  }
});

export default Widget.TickerWidget;
