import WidgetView from 'views/dashboard/widgetView'
import AdValueWidgetView from 'views/dashboard/widgetView/adValueWidgetView'
import template from 'text-loader!templates/dashboard/widget/agentAdValueWidget.html'

WidgetView.AgentAdValueWidget = AdValueWidgetView.extend({
  template: template
});

export default WidgetView.AgentAdValueWidget;

