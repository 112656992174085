import I18n from 'i18n';
import * as React from "react";

interface Props {
  clipUrl: string
}
class SeeFullArticleComponent extends React.Component<Props> {
  render() {
    const prefix = 'webapp.agents.results.result_details.'
    const text = I18n.t(
      prefix + 'click_here_to_see_full_article'
    )
    const linkText = I18n.t(
      prefix + 'click_here_to_see_full_article_link'
    )

    return (
      <span><i className="icon-arrow-right"></i> {text} <a href={this.props.clipUrl} target="_blank">{linkText}</a></span>
    )
  }
}
export default SeeFullArticleComponent
