import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/settings/sourceCategorySelection.html'
import handleTooltips from 'models/handleTooltips'

var SettingsSourceCategorySelectionView = Marionette.Layout.extend({
  template: template,
  regions: {
    sourceCategoryForm: '[data-source-category-selection-form]',
    sourceExclusionsList: '[data-source-exclusions-list]',
    sourceGroups: '[data-source-groups]',
  },
  ui: {
    preloader: '[data-preloader]',
  },
  initialize: function () {
    handleTooltips(this);
  },
  showViews: function (categoryView, exclusionView, sourceGroupView) {
    this.ui.preloader.hide();
    this.sourceCategoryForm.show(categoryView);
    this.sourceExclusionsList.show(exclusionView);
    sourceGroupView && this.sourceGroups.show(sourceGroupView);
  },
});

export default SettingsSourceCategorySelectionView;

