import NotificationSettings from 'models/settings/notificationSettings'
import Deferred from 'libraries/deferred'

var notificationSettings = new NotificationSettings();
var promise = Deferred.build();
var fetched = false;

// multiple calls to fetch will still result in exactly one fetch
promise.fetch = function () {
  if (!fetched) {
    notificationSettings.fetch().done(function () {
      promise.resolve(notificationSettings);
    });
    fetched = true;
  }
};

export default promise;

