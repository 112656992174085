import Marionette from "backbone.marionette";
import template from "text-loader!templates/agents/metadata/sopranoAdminChPressReleaseView.html";
import agentResultReadingTime from "libraries/agentResultReadingTime";
import localizedLanguages from "libraries/localizedLanguages";

class SopranoAdminChPressReleaseView extends Marionette.ItemView {
  initialize() {
    this.agentResult = this.options.agentResult;
  }

  serializeData() {
    const languageCodes = this.agentResult.get('languages');
    const clipMetadata = this.agentResult.get('clip_metadata');
    const author = clipMetadata.author;

    return {
      languages: localizedLanguages(languageCodes),
      readingTime: agentResultReadingTime(this.agentResult),
      author: author,
    };
  }
}

SopranoAdminChPressReleaseView.prototype.template = template;

export default SopranoAdminChPressReleaseView;
