import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/editReaderCategoryMediaSelection.html';
import $ from 'jquery';
import _ from 'underscore';
import session from 'models/sessionInstance'

class EditReaderCategoryMediaSelectionView extends Marionette.ItemView {
    initialize() {
        this.listenTo(this.model, 'change', this.render);
        this.selectors = ['radio_tv', 'print', 'online', 'social_media'];
    }

    serializeData() {
        return _.extend(
          {},
          super.serializeData(),
          {
            videoUploadEnabled: session.hasFeature("video_upload_enabled")
          }
        )
      }

    onRender() {
        let medias = this.model.get('media_types');
        _.each(medias, (media) => {
            if (media === "radio_tv") {
                $(this.ui.radio_tv).addClass('is-active');
            }
            if (media === "print") {
                $(this.ui.print).addClass('is-active');
            }
            if (media === "online") {
                $(this.ui.online).addClass('is-active');
            }
            if (media === "social_media") {
                $(this.ui.social_media).addClass('is-active');
            }
        });
        this._maybeDisableTheOnlySelectedOne();
    }

    selectedMedias() {
        var medias = [];
        _.each(this.selectors, (media) => {
            if ($(this.ui[media]).hasClass('is-active')) {
                medias = medias.concat(media);
            }
        });
        return medias;
    }

    _enableAll(){
        _.each(this.selectors, (media) => {
            $(this.ui[media]).removeClass('disabled');
        });
    }

    _disableTheOnlySelectedOne(){
        _.each(this.selectors, (media) => {
            if ($(this.ui[media]).hasClass('is-active')) {
                $(this.ui[media]).attr('disabled', 'disabled');
            }
        });
    }

    _maybeDisableTheOnlySelectedOne(){
        if(this._justOneIsSelected()){
            this._disableTheOnlySelectedOne();
        }else{
            this._enableAll();
        }
    }

    _justOneIsSelected(){
        var tot = 0;
        _.each(this.selectors, (media) => {
            if ($(this.ui[media]).hasClass('is-active')) {
                tot +=1;
            }
        });
        return tot === 1;
    }

    _handleRadioTv() {
        $(this.ui.radio_tv).toggleClass('is-active');
        this._maybeDisableTheOnlySelectedOne();
        this._triggerModelChanged();
    }

    _handlePrint() {
        $(this.ui.print).toggleClass('is-active');
        this._maybeDisableTheOnlySelectedOne();
        this._triggerModelChanged();
    }

    _handleOnline() {
        $(this.ui.online).toggleClass('is-active');
        this._maybeDisableTheOnlySelectedOne();
        this._triggerModelChanged();
    }

    _handleSocialMedia() {
        $(this.ui.social_media).toggleClass('is-active');
        this._maybeDisableTheOnlySelectedOne();
        this._triggerModelChanged();
    }

    _triggerModelChanged() {
        let self = this;
        if (!_.isUndefined(this.timer)) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            self.trigger('select:media_types:selected',
                         self.selectedMedias());
        }, 1000);
    }
}

EditReaderCategoryMediaSelectionView.prototype.template = template;
EditReaderCategoryMediaSelectionView.prototype.ui = {
    list: '[data-media-list]',
    radio_tv: '[data-radio_tv]',
    print: '[data-print]',
    online: '[data-online]',
    social_media: '[data-social_media]'
};
EditReaderCategoryMediaSelectionView.prototype.events = {
    'click @ui.radio_tv': '_handleRadioTv',
    'click @ui.print': '_handlePrint',
    'click @ui.online': '_handleOnline',
    'click @ui.social_media': '_handleSocialMedia'
};

export default EditReaderCategoryMediaSelectionView;
