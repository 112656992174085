import * as React from "react";
import * as moment from "moment";
import * as _ from "underscore";
import Agent from "models/agent";
import AgentTimeframe from "views/agents/agentTimeframe";
import ReactWrapper from "views/marionetteReactWrapper";
import Marionette from "backbone.marionette";
import RestorableModel from "libraries/restorableModel";

interface IAgentTimeframeOptions {
  agent: Agent;
}

export default class AgentTimeframeController extends Marionette.Controller {
  agent: Agent | RestorableModel | undefined;

  initialize(options: IAgentTimeframeOptions) {
    this.agent = options.agent;
  }

  view() {
    const props = this.agentTimeframeSettingsProps()
    return new ReactWrapper({
        getComponent: () => {
            return <AgentTimeframe {...props} />;
        }
    });
  }

  creationFinished(): boolean {
    if (this.options.agent) {
      return this.options.agent.get('creation_finished');
    } else {
      throw("agent is undefined");
    }
  }

  private agentTimeframeSettingsProps() {
    return {
        agentCreationFinished: this.creationFinished(),
        onStartDateClick: (date: string) => {
          if (this.options.agent) {
            (this.options.agent as RestorableModel).store();
            this.options.agent.set({ search_start_date: date });
            (this.options.agent as any).save(
              null, { updateOnly: "search_start_date" }
            ).then(() => {
                this.trigger("successful:timeframeSave");
            }).fail((response: any) => {
              if (this.options.agent) {
                (this.options.agent as RestorableModel).restore();
                if (response.responseText.match(
                    /"Cannot be updated after creation is finished!"/
                    )) {
                  this.options.agent.set({ creation_finished: true });
                }
                this.trigger("failed:timeframeSave");
              }
            });
          }
        },
        onEndDateClick: (date: string) => {
          if (this.options.agent) {
            (this.options.agent as RestorableModel).store();
            this.options.agent.set({ search_end_date: date });
            (this.options.agent as any).save(
              null, { updateOnly: "search_end_date" }
            ).then(() => {
                this.trigger("successful:timeframeSave");
            }).fail(() => {
              if (this.options.agent) {
                (this.options.agent as RestorableModel).restore();
                this.trigger("failed:timeframeSave");
              }
            });
          }
        },
        startDate: this.agentSearchStartDate(),
        endDate: this.agentSearchEndDate()
    };
  }

  private agentSearchStartDate() {
    if (!this.options.agent) { return moment(); }

    if (this.options.agent.get("search_start_date")) {
      return moment(this.options.agent.get("search_start_date"), "YYYY/MM/DD");
    } else {
      let creationDate = this.options.agent.get("created_at") * 1000;
      return creationDate ? moment(creationDate) : moment();
    }
  }

  private agentSearchEndDate() {
    if (this.options.agent && this.options.agent.get("search_end_date")) {
      return moment(this.options.agent.get("search_end_date"), "YYYY/MM/DD");
    }
  }
}
