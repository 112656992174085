import AgentTagWidgetView from 'views/dashboard/agentTagWidgetView'
import I18n from 'i18n'
import 'highcharts_legacy'

var AdValueWidget = AgentTagWidgetView.extend({
  formatToCurrencyCHF: function (number, withSpan) {
    var amount    = number;
    var currency  = "CHF";
    var precision = 0;

    if (number >= 1000 && number < 1000000) {
      amount    = amount / 1000;
      currency  = I18n.t("number.currency.kilo");
      precision = 1;
    }

    if (number >= 1000000) {
      amount    = amount / 1000000;
      currency  = I18n.t("number.currency.million");
      precision = 1;
    }

    if (withSpan) {
      currency = "<span> " + currency + "</span>";
    } else {
      currency = " " + currency;
    }

    return Highcharts.numberFormat(amount, precision, '.', "'") + currency;
  },
  prepareSerializedData: function () {
    var adValueLastMonth = this.formatToCurrencyCHF(this.model.get('ad_value_last_month'), true);
    var adValueThisMonth = this.formatToCurrencyCHF(this.model.get('ad_value_this_month'), true);
    var adValueLastMonthNoSpan =
          this.formatToCurrencyCHF(this.model.get('ad_value_last_month'), false);

    var values = {
      adValueLastMonth: adValueLastMonth,
      adValueThisMonth: adValueThisMonth,
      adValueLastMonthNoSpan: adValueLastMonthNoSpan
    };
    return values;
  }
});

export default AdValueWidget;

