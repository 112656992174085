import UserSettingsView from 'views/settings/userSettingsView';
import template from 'text-loader!templates/settings/newUser.html';
import _ from 'underscore';
import Usage from 'models/usage';
import UnitOrderModalView from 'views/settings/unitOrderModalView';

class NewUserView extends UserSettingsView {
    serializeData(...args) {
        let parentData = super.serializeData(args);
        return parentData;
    }

    _saveOrShowSaveModal(event) {
        event.preventDefault();
        if (this.saveButtonEnabled) {
            this.disableSaveButton();
            let usage = new Usage()
            usage.load().then(() => {
                if (usage.isMinimumUsageReached('newsroom_seat')) {
                    this._showOrderModal(usage);
                } else {
                    this.performSave();
                }
            })
        }
    }

    _showOrderModal(usage) {
        this.orderModalView = new UnitOrderModalView({
            unitType: 'newsroom_seat',
            usage: usage
        })
        this.listenTo(this.orderModalView, 'modal:confirmed', this.performSave)
        this.listenTo(this.orderModalView, 'modal:cancelled', this.enableSaveButton)
        this.orderModal.show(this.orderModalView);
        this.orderModalView.showModal();
    }

    onClose() {
        super.onClose()
        if (!_.isUndefined(this.orderModalView)) {
            this.orderModalView.close();
        }
    }
}
NewUserView.prototype.template = template;
NewUserView.prototype.events = _.extend({}, UserSettingsView.prototype.events, {
    'click [data-save]': '_saveOrShowSaveModal',
    'click [data-language-dropdown-body]': '_handleLanguageChange'
});

NewUserView.prototype.ui = _.extend({}, UserSettingsView.prototype.ui, {
    saveButton: '[data-save]'
});
NewUserView.prototype.regions = _.extend({}, UserSettingsView.prototype.regions, {
    orderModal: '[data-order-modal]'
});

export default NewUserView;
