/*
 * EventLogger object that logs all events on any given object to the
 * debug log.
 *
 * Start logging events by calling EventLogger.logEventsOn(object),
 * then stop again by calling EventLogger.stopLoggingEventsOn(object).
 */
import _ from 'underscore'
import Backbone from 'backbone'
import Logger from 'libraries/logger'

var EventLogger = _.extend({}, Backbone.Events, {
  logEventsOn: function (object, name) {
    this.listenTo(object, 'all', function (...args) {
      var eventName = _.first(args);
      var eventArguments = _.rest(args);
      Logger.debug(name + ': event `' + eventName + '\' with arguments ', eventArguments);
    });
  },
  stopLoggingEventsOn: function (object) {
    this.stopListening(object);
  }
});

export default EventLogger;

