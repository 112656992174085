import Backbone from 'backbone';
import moment from 'moment';
import _ from 'underscore';
import TimelineYear from 'models/timeline/timelineYear';

class TimelineYears extends Backbone.Collection {
    initialize(models, options) {
        this.setStartDate(options);
    }
    setStartDate(options) {
        this.startAt = moment('01-01-2009', 'DD-MM-YYYY');

        if (!_.isUndefined(options.startAt)) {
            this.startAt = moment(options.startAt);
        }

        this.startYear = this.startAt.year() + 1;
        this.startDate = new TimelineYear(
            new Date(this.startAt.year(),
                this.startAt.month(),
                this.startAt.date()
            )
        );
    }
    fill() {
        this.reset(this.years());
    }
    years() {
        let years = [this.startDate];
        let endYear = (new Date().getFullYear());
        for (let year = this.startYear; year <= endYear; year++) {
            years.push(new TimelineYear(new Date(year, 0, 1)));
        }
        return years;
    }
    comparator(year) {
        return -year.get('date').getTime();
    }
    findYearContaining(day) {
        return this.find(function (year) {
            return year.includes(day);
        });
    }
}

TimelineYears.prototype.model = TimelineYear;

export default TimelineYears;
