const UPDATE: 'app/selectedDashboard/UPDATE' = 'app/selectedDashboard/UPDATE';

const initialState = 0;

// Reducer
export default function (state = initialState, action: Action): number {
  switch (action.type) {
    case UPDATE:
    return action.payload;
    default:
    return state;
  }
}

type Action = UpdateAction;

// Action Creators
export interface UpdateAction { type: typeof UPDATE; payload: number; }
export function update(id: number ): UpdateAction {
  return { type: UPDATE, payload:  id};
}

