import _ from 'underscore'
import SceneController from 'controllers/sceneController'
import TagListController from 'controllers/settings/tagListController'
import TagPlaceholderView from 'views/settings/tagsPlaceholderView'
import TagDetailController from 'controllers/settings/tagDetailController'

var TagSceneController = SceneController.extend({
  show: function () {
    this._showTagListController();
    this._showPlaceholder();
  },
  _showTagListController: function () {
    this.controller = new TagListController();
    this.showInLeftColumn(this.controller.view);
    this._registerListeners();
  },
  _showPlaceholder: function () {
    var placeholder = new TagPlaceholderView();
    this.showInRightColumn(placeholder);
  },
  _showTag: function (tag) {
    this.detailController = new TagDetailController(tag);
    this.detailView = this.detailController.view;
    this.listenTo(this.detailController,
                  'tag:deleted',
                  this._closeTagDetailView
                 );
    this.showInRightColumn(this.detailView);
  },
  _registerListeners: function () {
    this.listenTo(
      this.controller,
      'tag:selected',
      this._showTag
    );
    this.listenTo(
      this.controller,
      'tag:deselected',
      this._closeTagDetailView
    );
  },
  _closeTagDetailView: function () {
    if (!_.isUndefined(this.detailView)) {
      this.detailView.close();
      this.focusRight();
      delete this.detailView;
      this._showPlaceholder();
      this.controller.deselectAll();
      this.focusRight();
    }
  }
});

export default TagSceneController;

