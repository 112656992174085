import WidgetView from 'views/dashboard/widgetView'
import MultiChartWidgetView from 'views/dashboard/widgetView/multiChartWidgetView'
import template from 'text-loader!templates/dashboard/widget/multiTagChartWidget.html'

WidgetView.MultiTagChartWidget = MultiChartWidgetView.extend({
  template: template,
  getColor: function (index) { return ['#006699', '#993300'][index]; },
  getItems: function () { return this.model.get('tags'); }
});

export default WidgetView.MultiTagChartWidget;

