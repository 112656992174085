import Marionette from 'backbone.marionette'
import AgentSelectionView from 'views/settings/notifications/agentSelectionView'
import Agents from 'collections/agents'

var AgentSelectionController = Marionette.Controller.extend({
  initialize: function (options) {
    this.selected_agents = options.selected_agents;
    this.agents = new Agents(options.agents.models);
    this.order = options.order;
    this._sortAgents();
    this.view = new AgentSelectionView({
      agents: this.agents,
      selected_agents: this.selected_agents
    });
    this._registerListeners();
  },
  _registerListeners: function () {
    this.listenTo(this.view, "agent:selected", (agent_id) => {
      var targetAgent = this.agents.get(agent_id);
      if (this.selected_agents.contains(targetAgent)) {
        this.selected_agents.remove(targetAgent);
      } else {
        this.selected_agents.add(targetAgent);
      }
    });

    this.listenTo(this.selected_agents, "add", (agent) => {
      this.view.toggleSelection(agent);
    });

    this.listenTo(this.selected_agents, "remove", (agent) => {
      this.view.toggleSelection(agent);
    });
  },
  _sortAgents: function () {
    switch (this.order) {
    case "chronological":
      this.agents = new Agents(this.agents.sortBy('created_at'));
      break;
    case "alphabetical":
      this.agents = new Agents(this.agents.sortBy(function (agent) { return agent.get('name').toLowerCase(); }));
      break;
    }
  }
});

export default AgentSelectionController;

