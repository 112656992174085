import Backbone from 'backbone'

var NotificationSettings = Backbone.Model.extend({
  url: '/api/v3/notification_settings',
  parse: function (response) {
    return response.object;
  },
  isNew: function () { return false; },
  toJSON: function () {
    return {
      notification_settings: this.attributes
    };
  }
});

export default NotificationSettings;

