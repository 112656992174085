import template from 'text-loader!templates/timeline/archiveDate.html';
import TimelineDayView from 'views/timeline/timelineDayView';

class ArchiveDateView extends TimelineDayView {
    serializeData() {
        return {
            date: this.model.formattedDate()
        };
    }

    setCheckboxState() {}

    _renderCheckBoxes() {}
}

ArchiveDateView.prototype.template = template;
ArchiveDateView.prototype.regions  = {
    resultList: '[data-container=reader-result-list]'
};
ArchiveDateView.prototype.triggers = {
    'click [data-archive-date]': 'clicked'
};

export default ArchiveDateView;
