import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/soprano_blog.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import I18n from 'i18n'
import DateFormatter from 'libraries/dateFormatter'

class DictionaryView extends Marionette.ItemView {
  initialize(options) {
    this.agentResult = options.agentResult;
  }

  serializeData() {
    let metadata = {};
    const col1 = {}
    const language = this.agentResult.get('languages')[0];
    let lastUpdate = this.agentResult.get('clip_metadata').last_update;
    let publishTime = this.agentResult.get('publish_time');
    if (publishTime) {
      publishTime = DateFormatter.formatDate(new Date(publishTime * 1000));
      metadata.publishTime = publishTime;
    }
    if (lastUpdate) {
      lastUpdate = DateFormatter.formatDate(new Date(lastUpdate));
      metadata.lastUpdate = lastUpdate === publishTime ? null : lastUpdate
    }
    if (language) {
      col1['language'] =
        I18n.t("vendor.iso.languages." + language);
    }

    conditionalSetToMetadata(metadata, 'col1', col1);

    return metadata;
  }
}
DictionaryView.prototype.template = template;

export default DictionaryView;
