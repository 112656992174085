import Marionette from 'backbone.marionette'
import moment from 'moment'
import _ from 'underscore'
import vent from 'libraries/vent'
import deepMerge from 'libraries/deepMerge'
import session from 'models/sessionInstance';
import AgentResultFilterController from 'controllers/agents/agentResultFilterController'
import AgentsTimelineController from 'controllers/agentsTimelineController'
import AgentResultDetailController from 'controllers/agents/agentResultDetailController'
import MultiAgentResultDetailController from 'controllers/agents/multiAgentResultDetailController'
import ClipCreationFormController from 'controllers/clipCreationFormController'
import PmgUploadFormController from 'controllers/pmgUploadFormController'
import PmgAgentSelectionController from 'controllers/pmgAgentSelectionController'
import ResultListLandingPageView from 'views/agents/resultListLandingPageView'
import ResultListLayout from 'views/agents/resultListLayout'
import AgentCollection from 'collections/agents'
import clipAccessLogger from 'libraries/clipAccessLogger'
import singleAsyncCall from 'libraries/singleAsyncCall'
import CloseSubObjects from 'libraries/closeSubObjects'
import Headroom from 'headroom.js'
import $ from 'jquery';
import stickybits from 'stickybits';

var AgentsTimelineLayoutController = Marionette.Controller.extend({
  initialize: function (options) {
    this.showLeftHandContainer  = options.showLeftHandContainer;
    this.showRightHandContainer = options.showRightHandContainer;
    this.agents                 = options.agents;
    this.filter                 = options.filter;
    this.initialJumpDate        = options.initialJumpDate;
    this._createAndShowController();
  },
  onClose: function () {
    CloseSubObjects.closeAll(
      [this.filterController,
       this.timeline,
       this.rightHandController]
    );
  },
  _createAndShowController: function () {
    if (this.agents.length > 0) {
      this.resultListLayout = new ResultListLayout();
      this.showLeftHandContainer(this.resultListLayout);
      if (!session.timeline2Enabled()) {
        this.filterController = new AgentResultFilterController({
          agents: this.agents,
          filter: this._cloneFilter()
        });
        this.resultListLayout.filters.show(this.filterController.view);
      }
      this._showTimeline();
    } else {
      var emptyView = new Marionette.ItemView({
        template: ' '
      });
      this.showLeftHandContainer(emptyView);
    }
    this._showLandingPage();
    this._registerListeners();
  },
  _closeTimeline: function() {
    if (!_.isUndefined(this.timeline)) {
      this.stopListening(this.timeline.selection);
      this.timeline.close();
    }
  },
  _showTimeline: function () {
    this._closeTimeline();
    var agentIds = _.pluck(this.agents, 'id');
    var firstResultAt = new AgentCollection(this.agents).firstResultAt();
    this.timeline = new AgentsTimelineController({
      startAt: firstResultAt,
      agentIds: agentIds,
      filter: this._cloneFilter(),
      query: this.filter.query
    });
    this._initHeadroom();
    this._registerTimelineListeners();
    if (session.timeline2Enabled()) {
      this.resultListLayout.list.show(this.timeline.getTimeline2());
    } else {
      this.resultListLayout.list.show(this.timeline.view);
    }
    vent.trigger('layout:focus-left');
  },
  _initHeadroom: function () {
    this.listenTo(this.timeline, 'scrollableElementUpdated', (scroller) => {
      const el = $('[data-container=filters]').get()[0];
        const opts = {
            tolerance: {
                down: 20
            },
            scroller: scroller
        };
      const headroom = new Headroom(el, opts);
      headroom.init();
      stickybits('.headroom');
    })
  },
  _showLandingPage: function () {
    var view = new ResultListLandingPageView();
    this.showRightHandContainer(view);
    vent.trigger('layout:focus-left');
  },
  _registerListeners: function () {
    if (this.filterController) {
      this.listenTo(this.filterController, 'changed:filterParameters', (filter) => {
        this._mergeFilter(filter);
        this.trigger('changed:filter', this._cloneFilter());
        this._showTimeline();
      });
      var self = this;
      this.listenTo(this.filterController, 'changed:date',
                    (date) => {
                      self.openDate(date);
                    })
    }
  },
  openDate: function(date) {
    this.timeline.openDate(moment(date));
  },
  _registerTimelineListeners: function () {
    if (this.timeline) {
      if (this.initialJumpDate) {
        this.listenTo(this.timeline, 'initialFetchDone', () => {
          this.openDate(this.initialJumpDate)
        })
      }
      var callback = singleAsyncCall(
        'handleAgentResultSelectionChange',
        this.handleAgentResultSelectionChange
      );
      if (session.timeline2Enabled()) {
        this.listenTo(vent, 'agentsTimeline:selectionChanged', callback);
      } else {
        this.listenTo(this.timeline.selection, 'add', callback);
        this.listenTo(this.timeline.selection, 'remove', callback);
        this.listenTo(this.timeline.selection, 'reset', callback);
      }
    }
  },
  handleRightColumnClosed: function () {
  },
  showClipCreation: function () {
    this._clearTimeline();
    if (this.rightHandController) {
        this.rightHandController.close();
    }
    var pmgUploadFormController = new PmgUploadFormController();
    var clipCreationFormController = new ClipCreationFormController({ pmgUploadFormController: pmgUploadFormController });

    this.listenTo(clipCreationFormController, 'importedClip:created', function (agentIds) {
      vent.commands.execute('agents:showAgents', agentIds);
    });
    this.listenTo(pmgUploadFormController, 'pmgUploadSuccess', this.showAgentSelection);
    var view = clipCreationFormController.view;
    this.showRightHandContainer(view);
    vent.trigger('layout:focus-right');
  },

  showAgentSelection: function(response) {
    this._clearTimeline();

    var pmgAgentSelectionController = new PmgAgentSelectionController(
      { response: response }
    );
    this.listenTo(
      pmgAgentSelectionController,
      'agentSelectionFinished',
      function(agentIds) {
        vent.commands.execute('agents:showAgents', agentIds);
      });

    var view = pmgAgentSelectionController.view;
    this.showRightHandContainer(view);
    vent.trigger('layout:focus-right');
  },

  _clearTimeline: function() {
    if (this.timeline) {
      this.stopListening(this.timeline.selection);
      this.timeline.clearSelection();
      this._registerTimelineListeners();
    }
  },
  _cloneFilter: function () {
    return deepMerge({}, this.filter);
  },
  _mergeFilter: function (filter) {
    _.extend(this.filter, filter);
  },
  handleAgentResultSelectionChange: function () {
    var selection = this.timeline.selection;
    if (!_.isUndefined(this.rightHandController)) {
      this.rightHandController.close();
    }
    if (selection.isEmpty()) {
      this._showLandingPage();
    } else {
      if (selection.length === 1) {
        var result = selection.first();
        this.rightHandController =
          new AgentResultDetailController(result);
        clipAccessLogger.createFromAgentResult(result);
      } else {
        this.rightHandController =
          new MultiAgentResultDetailController(selection.models);
      }
      this.showRightHandContainer(this.rightHandController.view);
      vent.trigger('layout:focus-right');
    }
  }
});

export default AgentsTimelineLayoutController;
