import ModalView from 'views/components/modalView';
import I18n from 'i18n';
import _ from 'underscore';
import template from 'text-loader!templates/components/rtvModal.html'

class RtvOrderModalView extends ModalView {
    initialize(options) {
        this.upgradePrice = options.upgradePrice;
        this.disabled = false;
        _.bindAll(this, 'close');
    }

    serializeData() {
        return {
            headline: this._modalHeadline(),
            confirmation: this._modalTitle(),
            warning: this._modalBody(),
            confirmationLabel: this._confirmationLabel(),
            showHeader: true,
            cancelLabel: this._cancelLabel(),
            confirmationButtonStyle: 'button--primary',
            showButtons: true,
        };
    }

    _showError() {
        const key = 'webapp.general.modals.confirm_order.error_message';
        const label =
              I18n.t(key);
        this.ui.error.show();
        this.ui.errorMessage.html(label);
    }

    _hideError() {
        this.ui.error.hide();
    }

    _disableSubmit() {
        this.ui.modalConfirmButton.addClass('is-disabled');
        this.disabled = true;
    }

    _enableSubmit() {
        this.ui.modalConfirmButton.removeClass('is-disabled');
        this.disabled = false;
    }

    _modalTitle() {
        return this._modalHeadline();
    }

    initializeModal() {
        const self = this;
        this.ui.modalConfirmButton.on('click', function() {
            if (!self.disabled) {
                self._confirmed = true;
                self.triggerModalConfirmed();
            }
        });

        const maxLength = 250;
        this.ui.textField.on('keyup', function () {
            if (self.ui.textField.val().length > maxLength) {
                self._showError();
                self._disableSubmit();
            } else {
                if (self.disabled) {
                    self._enableSubmit();
                    self._hideError();
                }
            }
        });
    }

    _modalHeadline() {
        return I18n.t(
            'webapp.agents.results.result_details.order_recording.headline'
        );
    }

    _modalBody() {
        let modalInfo = I18n.t(
            'webapp.agents.results.result_details.order_recording.information_free'
        );
        if (this._priceAvailable()) {
            modalInfo = I18n.t(
                'webapp.agents.results.result_details.order_recording.information_with_price',
                { price: this.upgradePrice }
            );
        }

        return modalInfo;
    }

    _priceAvailable() {
        return !(_.isUndefined(this.upgradePrice) || _.isNull(this.upgradePrice));
    }

    _confirmationLabel() {
        return I18n.t(
            'webapp.agents.results.result_details.order_recording.confirm'
        );
    }

    _cancelLabel() {
        return I18n.t(
            'webapp.agents.results.result_details.order_recording.cancel'
        );
    }

    triggerModalConfirmed() {
        const notes = this.ui.textField.val()
        this.trigger('modal:confirmed', notes);
    }

    close() {
        if (this.modal) {
            this.ui.modalElement.remove();
        }
    }

    closeModal() {
        this.modal.bPopup().close();
    }

    _onModalClose() {
        if (!this._confirmed) {
            this.trigger('modal:cancelled');
        }
        this.ui.modalElement.remove();
    }
}

RtvOrderModalView.prototype.template = template;
RtvOrderModalView.prototype.ui = {
    modalElement: '[data-modal]',
    modalConfirmButton: '[data-modal-confirm]',
    textField: '[data-notes]',
    error: '[data-error]',
    errorMessage: '[data-error-message]'
}

export default RtvOrderModalView;
