import _ from 'underscore'
import Mousetrap from 'mousetrap'
import Analytics from 'libraries/analytics'
import ShortcutRegistry from 'libraries/shortcutRegistry'

var MousetrapCheese = function () {
  var registry = new ShortcutRegistry();
  var currentNamespace;

  function eachKey(keys, iteratee) {
    function toArray(arg) {
      if (_.isArray(arg)) {
        return arg;
      } else {
        return [arg];
      }
    }

    _.each(toArray(keys), iteratee);
  }

  function wrapCallback(callback, key) {
    return function () {
      Analytics.sendEvent('br_shortcut_used', {key});
      return callback();
    };
  }

  return {
    bind: function (namespace, keys, callback) {
      eachKey(keys, function (key) {
        var wrapped = wrapCallback(callback, key);
        registry.register(namespace, key, wrapped);
        Mousetrap.bind(key, function () {
          return registry.lookup(currentNamespace, key)();
        });
      });
    },
    unbind: function (namespace, keys) {
      eachKey(keys, function (key) {
        registry.unregister(namespace, key);
      });
    },
    bindings: function () {
      return registry.registeredBindings();
    },
    updateCurrentNamespace: function (namespace) {
      currentNamespace = namespace;
    }
  };
};

export default MousetrapCheese;
