import ModalView from 'views/components/modalView';
import I18n from 'i18n';

class MultipleOnlineClipOrderModalView extends ModalView {
    initialize() {}

    serializeData() {
        return {
            headline: this._modalHeadline(),
            confirmation: this._modalTitle(),
            warning: this._modalBody(),
            confirmationLabel: this._confirmationLabel(),
            showHeader: true,
            cancelLabel: this._cancelLabel(),
            confirmationButtonStyle: 'button--primary',
            showButtons: true
        };
    }

    _modalTitle() {
        return this._modalHeadline();
    }

    _modalHeadline() {
        return I18n.t(
            'webapp.agents.results.order_full_text.multiple_results.modal_headline'
        );
    }

    _modalBody() {
        return I18n.t(
            'webapp.agents.results.order_full_text.multiple_results.modal_description'
        );
    }

    _confirmationLabel() {
        return I18n.t('webapp.agents.results.result_details.order');
    }

    _cancelLabel() {
        return I18n.t(
            'webapp.agents.results.order_full_text.cancel'
        );
    }
}

export default MultipleOnlineClipOrderModalView;
