/*
 * A module that wraps Google Analytics so we do not have to use their
 * function everywhere. This is also the place for any additional
 * tools we build on top of the GA API.
 */
import moment from 'moment'

function initialize() {
  console.log(`initialize analytics (${GA_TRACKING_ID})`);
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID);
}

function setUserProperties(props) {
  gtag('set', 'user_properties', props)
}

function sendEvent(name, params) {
  gtag('event', name, params);
}

function userTiming(name, promise) {
  var startTime = moment();
  promise.done(function () {
    var duration = moment() - startTime;
    gtag('event', name, {duration});
  });
}

function disableReporting() {
  console.log(`disable analytics (${GA_TRACKING_ID})`);
  window[`ga-disable-${GA_TRACKING_ID}`] = true;
}

export default {
  init: initialize,
  setUserProperties,
  sendEvent,
  userTiming,
  disableReporting,
};
