import Marionette from 'backbone.marionette'
import EditAgentSettingsView from 'views/agents/settings/editAgentSettingsView'
import session from 'models/sessionInstance'
import _ from 'underscore'
import { AgentsPermissions } from "components/agents/permissions";
import EditAgentUnsubscribeButtonController from 'controllers/agents/settings/editAgentUnsubscribeButtonController'
import Notificator from 'libraries/notificator'
import I18n from 'i18n'
import $ from 'jquery';
import EditAgentSubscriptionsController from 'controllers/agents/settings/editAgentSubscriptionsController'

var EditAgentSettingsController = Marionette.Controller.extend({
  initialize: function (options) {
    this.agent = options.agent;
    this.view = new EditAgentSettingsView({
      model: this.agent,
      readOnly: options.readOnly,
      rssAvailable: session.hasFeature("agent_rss"),
      newSearchAgentManagement: session.hasFeature('new_search_agent_management')
    });
    this._registerListeners();
    _.bindAll(this, '_updateAgentIfAllowed', '_maybeDisableCheckbox');
  },

  _registerListeners: function () {
    const self = this;
    this.listenTo(this.view, 'click:sharedCheckbox',
                  this._handleSharedCheckbox);
    this.listenTo(this.view, 'render', this._checkSharedAgent);
    this.listenTo(this.agent, 'change:shared',
                  () => {
                    self.view.updateSharedCheckbox()
                    self._updateSubscriptionsVisibility()
                  })
    this.listenTo(this.agent, 'change:subscribed', () => {
      self._updateUnSubscribeButtonVisibility()
    })

    this.listenTo(this.view, 'render', function () {
      self._updateSubscriptionsVisibility()
      self._updateUnSubscribeButtonVisibility()
      self.view.updateSharedCheckbox()
    })
  },

  _updateUnSubscribeButtonVisibility() {
    if (this.agent.get('subscribed') &&
        !session.isGroupAdmin() &&
        !this.agent.isOwnAgent()) {
      this._showUnsubscribeButton()
    } else {
      if (this.view) {
        this.view.hideUnSubscribeButton()
      }
    }
  },

  _updateSubscriptionsVisibility() {
    if (session.isGroupAdmin() &&
        session.hasFeature('new_search_agent_management') &&
        this.agent.get('shared')) {
      this._showSubscriptions();
    } else {
      if (this.view) {
        this.view.hideSubscriptions();
      }
    }
  },

  _showUnsubscribeButton: function() {
      const controller = new EditAgentUnsubscribeButtonController({
          agent: this.agent
      });
      this.view.showUnsubscribeButton(controller.view);
  },

  _showSubscriptions: function () {
      const controller = new EditAgentSubscriptionsController({
        agent: this.agent
      });
      this.view.showSubscriptions(controller.view);
  },

  _handleSharedCheckbox: function () {
    this._updateAgentIfAllowed();
  },

  privatizeAgent: function (){
    const url =
          '/api/v3/agents/' + this.agent.get('id') + '/privatize_agent'
    const self = this;
    self.agent.store();
    $.post(url, {}, function (data) {
      self.updateSubscribedUsers(data.user_ids)
      if (!self.agent.isOwnAgent()) {
        self.agent.set({subscribed: false})
      }
    }).fail(() => {
      self.handleRequestError()
    })
  },

  updateSubscribedUsers: function(userIds) {
    this.agent.set({subscribed_user_ids: userIds})
  },

  handleRequestError: function () {
    const key =
      'webapp.agents.edit.agent_subscriptions.request_error'
    Notificator.showNotification(I18n.t(key));
  },

  _checkSharedAgent: function () {
    this._maybeDisableCheckbox();
  },

  _maybeDisableCheckbox: function () {
    if (!AgentsPermissions.canPrivatizeAgent(this.agent)) {
      this.view.disableCheckbox();
    }
  },

  _updateAgentIfAllowed: function () {
    if (this.agent.get('shared')) {
      if (AgentsPermissions.canPrivatizeAgent(this.agent)) {
        this.privatizeAgent();
        this.agent.set('shared', false);
        this.view.triggerAgentModelUpdate('shared');
      }
    } else {
      this.agent.set('shared', true);
      this.view.triggerAgentModelUpdate('shared');
    }
    this.view.updateSharedCheckbox();
  }
});

export default EditAgentSettingsController;

