import _ from 'underscore'
import template from 'text-loader!templates/reporting/wizard/filterSortSettings.html'
import StepView from 'views/reporting/wizard/stepView'
import session from 'models/sessionInstance'
import 'backbone.marionette'
import I18n from 'i18n'

var View = StepView.extend({
  template: template,
  className: "wizard-step rounded clearfix",
  triggers: {
    'change #groupTopic': 'change:group_by',
    'change #groupMediaType': 'change:group_by',
    'change #groupDate': 'change:group_by',
    'change #groupNone': 'change:group_by',
    'change #orderTime': 'change:order_by',
    'change #orderReach': 'change:order_by',
    'click #filterRadioTV': 'click:filter_radio_tv',
    'click #filterPrint': 'click:filter_print',
    'click #filterOnline': 'click:filter_online',
    'click #filterSocialMedia': 'click:filter_social_media'
  },
  initialize: function () {
    _.bindAll(this, 'render');
    this.on('change:group_by', () => {
      var value = this.$('input:radio[name=group_by]:checked').val();
      this.model.set({group_by: value});
    });
    this.on('change:order_by', () => {
      var value = this.$('input:radio[name=order_by]:checked').val();
      this.model.set({order_by: value});
    });
    this.on('click:filter_radio_tv', () => {
      this.$('#filterRadioTV').toggleClass("checked");
      this.updateFilter();
    });
    if (session.hasPrintClipsInReports()) {
      this.on('click:filter_print', () => {
        this.$('#filterPrint').toggleClass("checked");
        this.updateFilter();
      });
    }
    this.on('click:filter_online', () => {
      this.$('#filterOnline').toggleClass("checked");
      this.updateFilter();
    });
    this.on('click:filter_social_media', () => {
      this.$('#filterSocialMedia').toggleClass("checked");
      this.updateFilter();
    });
    this.model.on('change:topic_type', this.render);
    if (this.model.get('topic_type') === 'clip') {
      this.$('#groupNone').toggleClass("checked");
      this.model.set({group_by: 'none'});
    }

    StepView.prototype.initialize.call(this);
  },
  updateFilter: function () {
    var radio_tv = this.$('#filterRadioTV').hasClass("checked");
    var print = this.$('#filterPrint').hasClass("checked");
    var online = this.$('#filterOnline').hasClass("checked");
    var social_media = this.$('#filterSocialMedia').hasClass("checked");

    var keep = [];

    if (radio_tv)     { keep.push('radio_tv'); }
    if (print)        { keep.push('print'); }
    if (online)       { keep.push('online'); }
    if (social_media) { keep.push('social_media'); }

    this.model.set({clip_types_to_keep: keep});
  },
  updateForm: function () {
    // could do this in the template, but it is easier with jQuery
    this.$('input:radio[name=group_by]').val([this.model.get('group_by')]);
    this.$('input:radio[name=order_by]').val([this.model.get('order_by')]);

    this.$('#filterRadioTV').removeClass("checked");
    this.$('#filterPrint').removeClass("checked");
    this.$('#filterOnline').removeClass("checked");
    this.$('#filterSocialMedia').removeClass("checked");

    var clip_types_to_keep = this.model.get("clip_types_to_keep");
    var has_print;

    var has_radio_tv = _.indexOf(clip_types_to_keep, "radio_tv") === -1 ? false : true;

    if (!session.hasPrintClipsInReports()) {
      has_print = false;
      this.$('#filterPrint').addClass("ui-state-disabled", true);
    } else {
      has_print = _.indexOf(clip_types_to_keep, "print") === -1 ? false : true;
    }

    var has_online = _.indexOf(clip_types_to_keep, "online") === -1 ? false : true;
    var has_social_media = _.indexOf(clip_types_to_keep, "social_media") === -1 ? false : true;

    if (has_radio_tv) { this.$('#filterRadioTV').addClass("checked"); }
    if (has_print) { this.$('#filterPrint').addClass("checked"); }
    if (has_online) { this.$('#filterOnline').addClass("checked"); }
    if (has_social_media) { this.$('#filterSocialMedia').addClass("checked"); }
  },
  onRender: function () {
    var report = this.model.get('report');
    this.updateForm();
    if (report.isClipTopic()) {
      this.$('.filtering').hide();
    }
  },
  serializeData: function () {
    var topicName;
    if (this.model.get('topic_type') === 'agent') {
      topicName = I18n.t('webapp.reporting.filter_and_sorting.group_by.searchagent');
    } else {
      topicName = I18n.t('webapp.reporting.filter_and_sorting.group_by.tag');
    }
    return {
      topicName: topicName,
      canGroupByTopic: this.model.canGroupByTopic(),
      showRTvFilter: session.hasFeature("video_upload_enabled")
    };
  }
});

export default View;

