import Marionette from 'backbone.marionette'
import $ from 'jquery'
import _ from 'underscore'
import DashboardFullscreen from 'views/dashboard/dashboardFullscreen'
import layoutTemplate from 'text-loader!templates/dashboard/layout.html'
import session from 'models/sessionInstance'

var DashboardLayout = Marionette.Layout.extend({
  template: layoutTemplate,
  className: 'content legacy',
  id: 'container-dashboard',

  regions: {
    'grid': '#grid',
    'createAWidgetWindowContainer': '#create-a-widget-window-container'
  },
  triggers: {
    'click .fullscreen': 'fullscreen'
  },
  initialize: function () {
    _.bindAll(this, 'fullScreenExitedCallback');
    _.bindAll(this, 'handleUserActivity');
    this.dashboardFullscreen = new DashboardFullscreen(this);
  },
  startFullScreen: function (options) {
    this.dashboardFullscreen.start(this.fullScreenExitedCallback, options);
    this.showFullScreenControls();
    this.handleUserActivity(); // initialize the timer for the first time
    $(document).on('mousemove', this.handleUserActivity);
  },
  exitFullScreen: function () {
    this.hideFullScreenControls();
    this.dashboardFullscreen.exit();
    $(document).off('mousemove', this.handleUserActivity);
  },
  showFullScreenControls: function () {
    this.$('#dashboard-fullscreen-controls').fadeIn(100);
  },
  fullScreenExitedCallback: function () {
    this.trigger('fullscreen-exited');
  },
  hideFullScreenControls: function () {
    $('#dashboard-fullscreen-controls').fadeOut(100);
  },
  handleUserActivity: function () {
    window.clearTimeout(this.userIdleTimeout);
    this.showFullScreenControls();
    this.userIdleTimeout = window.setTimeout(this.hideFullScreenControls, 3000);
  },

  enterEditMode: function () {
    $(this.el).addClass('edit-mode');
  },
  exitEditMode: function () {
    $(this.el).removeClass('edit-mode');
  },
  addThemeClass: function () {
    $(document).find('body').addClass(this._themeClass());
  },
  removeThemeClass: function () {
    $(document).find('body').removeClass(this._themeClass());
  },
  _themeClass: function () {
    var theme = session.get('dashboard_theme');
    return 'theme-' + theme;
  }
});

export default DashboardLayout;

