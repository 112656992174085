import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/timeWidget.html'
import moment from 'moment'
import _ from 'underscore'

WidgetView.TimeWidget = WidgetView.extend({
  template: template,
  onInitialize: function () {
    _.bindAll(this, 'render');
    this._startInterval();
  },
  onClose: function () {
    this._clearInterval();
  },
  enterEditMode: function () {
    // Constant rerendering is not wanted in edit-mode, and it
    // also causes problems with the overlay.
    this._clearInterval();
  },
  exitEditMode: function () {
    this._startInterval();
  },
  prepareSerializedData: function () {
    return {time: moment()};
  },
  _startInterval: function () {
    this.interval = window.setInterval(this.render, 1000);
  },
  _clearInterval: function () {
    window.clearInterval(this.interval);
  }
});

export default WidgetView.TimeWidget;

