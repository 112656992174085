import Backbone from 'backbone'

var Login = Backbone.Model.extend({
  url: '/api/v3/session',
  isLoggedIn: function () {
    return this.get('logged_in');
  }
});

export default Login
