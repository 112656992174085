import Marionette from 'backbone.marionette'
import Handlebars from 'handlebars'

// use Handlebars to render templates
Marionette.TemplateCache.prototype.compileTemplate = function (rawTemplate) {
  return Handlebars.compile(rawTemplate);
};

Marionette.View.prototype.registerPartial = function (partialName, template) {
  Handlebars.registerPartial(partialName, template);
};

// templates are loaded by RequireJS text! plugin and passed into
// the module as a string. Overwrite the default behaviour of
// looking for the given jQuery selector and returning it’s
// content with a function that just returns the passed in
// template directly.
Marionette.TemplateCache.prototype.loadTemplate = function (template) {
  return template;
};

