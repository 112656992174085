import * as React from 'react';
import * as ReactDOM from 'react-dom';
import I18n from 'i18n';
import ReaderNotificationTriggers from "collections/newsroom/readerNotificationTriggers";
import ReaderNotificationTrigger from "models/newsroom/readerNotificationTrigger";
import ReaderNotificationSchedules from "collections/newsroom/readerNotificationSchedules";
import ReaderNotificationSchedule from "models/newsroom/readerNotificationSchedule";
import ReaderScheduleTriggerView
    from 'views/newsroom/readerScheduleTriggerView';
import ReaderNotificationTriggerView
    from 'views/newsroom/readerNotificationTriggerView';
import * as _ from 'underscore';
import * as moment from "moment";

interface IReaderTriggersProps {
  triggers: ReaderNotificationTriggers;
  triggerTypes: string[];
  schedules: ReaderNotificationSchedules;
  selectedTriggers: ReaderNotificationTrigger[];
  onTimeChange: (time: number, schedule: ReaderNotificationSchedule) => void;
  onWeekdaySelection: (values: number[], schedule: ReaderNotificationSchedule) => void;
  onAddTriggerSelectionClick: (triggerType: string) => void;
  onTriggerViewOpened: (trigger: ReaderNotificationTrigger) => void;
  onTriggerViewClosed: (trigger: ReaderNotificationTrigger) => void;
  onTriggerDestroy: (trigger: ReaderNotificationTrigger) => void;
  onScheduleDestroy: (schedule: ReaderNotificationSchedule) => void;
  onTriggerClick: () => void;
  editorialReader: boolean;
}

interface IReaderTriggersState {
  createTriggerDropdownOpen: boolean;
}

class ReaderNotificationTriggersView extends React.Component<IReaderTriggersProps, IReaderTriggersState> {
    private wrapperRef: any;

    constructor(props: IReaderTriggersProps) {
        super(props);
        this.state = { createTriggerDropdownOpen: false };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    handleClickOutside(e: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.setState({
                createTriggerDropdownOpen: false
            });
        }
    }

    render() {
        let createTriggerDropdownClasses = ['collapsible__header', 'dropdown__toggle'];
        if (this.state.createTriggerDropdownOpen) {
            createTriggerDropdownClasses.push('is-open');
        }
        return (
            <ul className="collapsible mbl"
                ref={this.setWrapperRef}>
                {this.renderTriggers()}
                {this.renderSchedules()}
                <li className="collapsible__item"
                    onClick={ this.toggleTriggerDropdown.bind(this) }>
                  <div className={createTriggerDropdownClasses.join(' ')}
                       ref='createTriggerDropdown'
                       data-toggle="dropdown">
                    <span className=""><i className="icon-plus"></i> { I18n.t("webapp.reader_admin.edit.notification_configuration.trigger.add") }</span>
                    <ul className="dropdown__menu" role="menu">
                      { this.createArchiveDatePublishTriggerListItem() }
                      { this.createNewResultsTriggerListItem() }
                      { this.createByScheduleTriggerListItem() }
                    </ul>
                  </div>
                </li>
            </ul>
        );
    }

    private createArchiveDatePublishTriggerListItem(): React.ReactNode {
        const title = I18n.t(
          "webapp.reader_admin.edit.notification_configuration.trigger.sync_with_publish_time.title"
        );
        if (this.canCreateArchiveDatePublishTrigger()) {
          return this.enabledTriggerListItem(title, "archive_date_published");
        } else {
          return this.disabledTriggerListItem(title);
        }
    }

    private createNewResultsTriggerListItem(): React.ReactNode {
        const title = I18n.t(
          "webapp.reader_admin.edit.notification_configuration.trigger.new_results.title"
        );
        if (this.canCreateNewResultsTrigger()) {
          return this.enabledTriggerListItem(title, "new_results");
        } else {
          return this.disabledTriggerListItem(title);
        }
    }

    private canCreateByScheduleTrigger(): boolean {
        return !this.triggerSelected("new_results");
    }

    private canCreateArchiveDatePublishTrigger(): boolean {
        const triggers: ReaderNotificationTrigger[] = this.props.triggers.models;
        return this.triggerTypeIsAvailable("archive_date_published") &&
               triggers.length === 0;
    }

    private canCreateNewResultsTrigger(): boolean {
      if (this.props.editorialReader) {
        return false;
      } else {
        const triggers: ReaderNotificationTrigger[] = this.props.triggers.models;
        const schedules: ReaderNotificationSchedule[] = this.props.schedules.models;
        return this.triggerTypeIsAvailable("new_results") &&
               triggers.length === 0 &&
               schedules.length === 0;
      }
    }

    private triggerSelected(type: string): boolean {
        const triggers: ReaderNotificationTrigger[] = this.props.triggers.models;
        const selectedTriggerTypes: string[] = _.map(triggers, (trigger) => {
          return trigger.get("trigger_type");
        });
        return _.contains(selectedTriggerTypes, type);
    }

    private triggerTypeIsAvailable(type: string): boolean {
        const availableTypes = this.props.triggerTypes;
        return _.contains(availableTypes, type);
    }

    private createByScheduleTriggerListItem(): React.ReactNode {
        const title =  I18n.t(
          "webapp.reader_admin.edit.notification_configuration.trigger.by_schedule.title"
        );
        if (this.canCreateByScheduleTrigger()) {
          return this.enabledTriggerListItem(title, "by_schedule");
        } else {
          return this.disabledTriggerListItem(title);
        }
    }

    private enabledTriggerListItem(title: string, triggerType: string): React.ReactNode {
        return this.triggerListItem(
          title, ["dropdown__link"], (e: React.MouseEvent<HTMLAnchorElement>) => {
              this.onAddTriggerButtonClick(triggerType);
            }
        );
    }

    private disabledTriggerListItem(title: string): React.ReactNode {
        return this.triggerListItem(
          title,
          ["dropdown__link", "dropdown__link--disabled"],
          (e: React.MouseEvent<HTMLAnchorElement>) => {
                e.stopPropagation();
                e.preventDefault();
            }
        );
    }

    private triggerListItem(
      title: string,
      anchorClasses: string[],
      clickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => void
    ): React.ReactNode {
        return (
          <li>
            <a className={anchorClasses.join(" ")} onClick={ clickHandler }>
            { title }
            </a>
          </li>
        );
    }

    toggleTriggerDropdown() {
        this.setState({
            createTriggerDropdownOpen: !this.state.createTriggerDropdownOpen }
        );
    }

    onAddTriggerButtonClick(triggerType: string) {
        this.toggleTriggerDropdown();
        this.props.onAddTriggerSelectionClick(triggerType);
    }

    renderTriggers() {
        return _.map(this.props.triggers.models, (trigger: ReaderNotificationTrigger) => {
            const triggerProps = {
                trigger: trigger,
                onTriggerViewOpened: this.props.onTriggerViewOpened,
                onTriggerViewClosed: this.props.onTriggerViewClosed,
                onTriggerDestroy: this.props.onTriggerDestroy,
                onTriggerClick: this.props.onTriggerClick,
                selectedTriggers: this.props.selectedTriggers,
                key: trigger.get("id"),
                editorialReader: this.props.editorialReader
            };
            return <ReaderNotificationTriggerView {...triggerProps}/>;
        });
    }

    renderSchedules() {
        return _.map(this.props.schedules.models, (schedule: ReaderNotificationSchedule) => {
            const triggerProps = {
                schedule: schedule,
                values: schedule.get("weekdays"),
                onTriggerViewOpened: this.props.onTriggerViewOpened,
                onTriggerViewClosed: this.props.onTriggerViewClosed,
                onTriggerDestroy: this.props.onScheduleDestroy,
                onTriggerClick: this.props.onTriggerClick,
                onWeekdaySelection: this.props.onWeekdaySelection,
                onTimeChange: this.props.onTimeChange,
                selectedTriggers: this.props.selectedTriggers,
                key: schedule.get("id"),
            };
            return <ReaderScheduleTriggerView {...triggerProps}/>;
        });
    }
}

export default ReaderNotificationTriggersView;
