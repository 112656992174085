import template from 'text-loader!templates/reporting/wizard/excelContentSettings.html'
import StepView from 'views/reporting/wizard/stepView'
import 'backbone.marionette'
import session from 'models/sessionInstance';

var View = StepView.extend({
  template: template,
  className: "wizard-step rounded clearfix",
  ui: {
    onlyDataSheet: '[data-only-datasheet]'
  },
  triggers: {
    'click #include_clips_from_several_sources': 'click:include_clips_from_several_sources',
    'click @ui.onlyDataSheet': 'click:dataSheetSelection'
  },
  initialize: function () {
    this.on('click:include_clips_from_several_sources', () => {
      this.$('#include_clips_from_several_sources').toggleClass("checked");
      var value = this.$('#include_clips_from_several_sources').hasClass("checked");
      this.model.set({include_clips_from_several_sources: value});
    });
    this.on('click:dataSheetSelection', () => {
      this.ui.onlyDataSheet.toggleClass("checked");
      var value = this.ui.onlyDataSheet.hasClass("checked");
      this.model.set({only_datasheet: value});
    });

    StepView.prototype.initialize.call(this);
  },
  updateCheckboxes: function () {
    this.$('#include_clips_from_several_sources').removeClass("checked");
    this.ui.onlyDataSheet.removeClass("checked");

    if (this.model.get('only_datasheet') === true) {
      this.ui.onlyDataSheet.addClass("checked");
    }

    if (this.model.get("include_clips_from_several_sources") === true) {
      this.$('#include_clips_from_several_sources').addClass("checked");
    }

  },
  onRender: function () {
    this.updateCheckboxes();
  },
  serializeData: function () {
    return {
      make_only_datasheet_default_in_reports: session.hasFeature('make_only_datasheet_default_in_reports')
    }
  }
});

export default View;

