import {
  IAddressbookModelProps,
  IAddressbookGroup,
  IAddressbookContact
} from 'models/addressbookModel'
import I18n from 'i18n'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'

interface IContactActionsProps {
  editContactId: number | null
  contact: IAddressbookModelProps
  addable: boolean
  editable: boolean
  removable: boolean
  destroyable: boolean
  onAdd: (contact: IAddressbookContact, query: string) => void
  onEdit: (contact: IAddressbookContact, query: string) => void
  onRemove: (contact: IAddressbookContact, query: string) => void
  onDestroy: (contact: IAddressbookContact, query: string) => void
  searchInput: string
}
class ContactActionsView extends React.Component<IContactActionsProps, {}> {
  render() {
    const cssProps = {} // { display: 'none' }
    const disabledCss = { color: 'grey' }
    const contact = this.props.contact.data as IAddressbookContact
    const persisted = contact.id !== undefined
    let actions: Array<JSX.Element> = []
    if (this.props.editable && !contact.is_user_contact && persisted) {
      actions.push(
        <i onClick={() => { this.props.onEdit(contact, this.props.searchInput) }}
           key={`editContact-%{this.props.contact.id}`}
           className="icon-pencil"></i>
      )
    }
    if (this.props.editable && contact.is_user_contact && persisted) {
      actions.push(
        <span className="tooltip" key={`editContact-${contact.id}`}>
          <span className="tooltip__toggle tooltip__toggle--html"
                data-qtip-my='bottom right' data-qtip-at='top center'>
            <i className="icon-pencil" style={disabledCss}></i>
          </span>
          <span className="tooltip__content tooltip__content--html">
            <span className="tooltip__text">
              { I18n.t('webapp.settings.contacts.tooltips.list_edit_disabled') }
            </span>
          </span>
        </span>
      )
    }
    if (this.props.addable && persisted) {
      actions.push(
        <i onClick={() => { this.props.onAdd(contact, this.props.searchInput) }}
           key={`addContact-%{contact.id}`}
           className="icon-plus"></i>
      )
    }
    if (this.props.removable && persisted) {
      const onRemove = () => {
        this.props.onRemove(contact, this.props.searchInput)
      }
      actions.push(
        <i onClick={onRemove}
           key={`removeContact-%{this.props.contact.id}`}
           className="icon-delete"></i>
      )
    }
    if (this.props.destroyable && !contact.is_user_contact && persisted) {
      const onDestroy = () => {
        this.props.onDestroy(contact, this.props.searchInput)
      }
      actions.push(
        <i onClick={onDestroy}
           key={`destroyContact-%{this.props.contact.id}`}
           className="icon-delete"></i>
      )
    }

    function spaceArray<T>(array: Array<T>) : Array<T | ' '> {
      return _.flatten(_.map(array, (value: T, index: number) => {
        if (array.length - 1 !== index) {
          return [value, ' ']
        } else {
          return value
        }
      }))
    }

    let spacedActions = spaceArray(actions)

    return (<div className="contact-list__item-actions">{ spacedActions }</div>)
  }
}

interface IContactNameProps {
  name: string | null
  email: string
}
class ContactName extends React.Component<IContactNameProps, {}> {
  render() {
    if (this.props.name !== null && this.props.name.length > 0) {
      return (
        <span>
          <span data-contact-name-field> { this.props.name } </span>
          <span data-contact-email-field className="text--grey">
            &lt;{ this.props.email }&gt;
          </span>
        </span>
      )
    } else {
      return (<span data-contact-email-field> { this.props.email }</span>)
    }
  }
}

export interface IContactProps {
  id: number
  is_user_contact: boolean
  contact_group_ids: Array<number>
  email: string
  name: string | null
}

class ContactInputView extends React.Component<IContactViewProps, {}> {
  nameInput?: HTMLInputElement

  getName(): string {
    const name = $('[data-form-name-input]').val()
    if (name)
      return name.toString()
    else
      throw("name is undefined")
  }

  getEmail(): string {
    const email = $('[data-form-email-input]').val()
    if (email)
      return email.toString()
    else
      throw("email is undefined")
  }

  render() {
    const contact = this.props.contact.data as IAddressbookContact
    const updateClickHandler = () => {
      this.props.onUpdate(
        contact,
        this.getName(),
        this.getEmail(),
        this.props.searchInput
      )
    }
    const onKey = (e: any) => {
      if (e.keyCode == 13) {
        updateClickHandler()
      }
    }
    let nameValue =
      "webapp.reader_admin.edit.audience.list_add_placeholder_name"
    if (contact.name !== null) {
      nameValue = contact.name
    }

    return (
      <div data-form className="contact-list__item-field">
        <div className="contact-list__item-actions">
          <i onClick={updateClickHandler} className="fa fa-check" />{" "}
          <i
            onClick={() => {
              this.props.onCancel(this.props.searchInput);
            }}
            className="icon-delete"
          />
        </div>
        <i className="icon-envelope" />
        <form>
          <div className="form-item form-item--inline">
            <div className="form-item__input">
              <input
                data-form-name-input
                className="contact-list__filter-add text-input"
                type="text"
                onKeyUp={onKey}
                ref={this.setNameInputRef.bind(this)}
                defaultValue={nameValue}
              />
            </div>
          </div>
          <div className="form-item form-item--inline">
            <div className="form-item__input">
              <input
                data-form-email-input
                className="contact-list__filter-add text-input"
                type="text"
                onKeyUp={onKey}
                defaultValue={contact.email}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }

  setNameInputRef(input: HTMLInputElement) {
    this.nameInput = input
  }

  componentDidMount() {
    if (this.nameInput) {
      this.nameInput.focus();
    }
  }
}
class EditContactView extends React.Component<IContactViewProps, {}> {
  render() {
    return (
      <ContactInputView {...this.props} />
    )
  }
}

class ShowContactView extends React.Component<IContactViewProps, {}> {
  render() {
    const contact = this.props.contact.data as IAddressbookContact
    let contactIconClass = 'icon-envelope'
    if (contact.is_user_contact) { contactIconClass = 'icon-user' }
    const groupMemberString = this.props.groupMember ? '-group-members' : ''

    return (
      <div className={`contact-list__item-field${groupMemberString}`}>
        <ContactActionsView {...this.props}/>
        <i className={contactIconClass}></i>
        <ContactName name={contact.name} email={contact.email} />
      </div>
    )
  }
}

export interface IContactViewProps {
  editContactId: number | null
  highlightContactId: number | null
  contact: IAddressbookModelProps
  visible: boolean
  onAdd: (contact: IAddressbookContact, query: string) => void
  onEdit: (contact: IAddressbookContact, query: string) => void
  onRemove: (contact: IAddressbookContact, query: string) => void
  onDestroy: (contact: IAddressbookContact, query: string) => void
  onListItemClick: (contact: IAddressbookContact, query: string) => void
  onCancel: (query: string) => void
  onUpdate: (contact: IAddressbookContact, name: string, email: string, query: string) => void
  onGroupAdd: (group: IAddressbookGroup, query: string) => void
  onGroupRemove: (group: IAddressbookGroup, query: string) => void
  removable: boolean
  destroyable: boolean
  addable: boolean
  editable: boolean
  searchInput: string
  useButtonStyle: boolean
  groupAddable: boolean
  groupRemovable: boolean
  groupMember: boolean
  groupContactEditable?: boolean;
}
export default class ContactView extends React.Component<IContactViewProps, {}> {
  render() {
    const contact = this.props.contact.data as IAddressbookContact
    const clickHandler = () => {
      this.props.onListItemClick(contact, this.props.searchInput)
    }
    const editContact = this.props.editContactId
    let shownComponent: any

    if (editContact !== null && contact.id === editContact) {
      shownComponent = <EditContactView {...this.props} />
    } else {
      shownComponent = <ShowContactView {...this.props} />
    }

    let name = contact.name || ''
    let filterableText = name + ' ' + contact.email;

    const groupMemberString = this.props.groupMember ? '__group-members' : ''
    let classes = [`contact-list${groupMemberString}__item`]
    if (this.props.useButtonStyle && this.props.visible) {
      classes.push('contact-list__item--button')
    }
    if (!this.props.visible) {
      classes.push('hidden')
    }
    if (this.props.highlightContactId === contact.id) {
      classes.push('is-active')
    }

    return (
      <li className={classes.join(' ')}
          data-filterable-text={filterableText}
          onClick={clickHandler}>
        {shownComponent}
      </li>
    )
  }
}
