import Marionette from "backbone.marionette";
import SearchSettingsView from "views/research/searchSettingsView";
import {FilterBuilderView} from "views/components/advancedFilterBuilderView"
import SourceCategorySelectionFormController from "controllers/agents/sourceCategorySelectionFormController";
import * as React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper'
import _ from "underscore";
import ResultDetailView from "components/resultDetailView";
import ProviderFinder from 'libraries/researchFilter/providerFinder'
import { buildFilters } from "controllers/research/newResearchController/buildFilters";
import MultiClipDetailController from 'controllers/research/multiClipDetailController'
import GlobalClipView from "../../components/globalClipEditor/GlobalClipView";
import ArticleCountQueryBuilder from '../../models/research/ArticleCountQueryBuilder';
import ArticleCountView from './ArticleCountView';
import {bugsnagClient} from 'libraries/bugsnag'

class NewSearchSettingsController extends Marionette.Controller {
  initialize(options) {
    this.count = null;
    this.isClipDetailViewOpen = false;
    this.isNetworkRequestOngoing = false;
    this.view = new SearchSettingsView();
    this.emptyFilters = buildFilters(null);
    this.filters = options.filters;
    this.queryBuilder = new ArticleCountQueryBuilder({search_query: options.filters});
    this.count = 0;
    this.validForm = _.every(_.flatten(this.filters), (filter) => {
      return ProviderFinder[filter.type].isValid(filter.selection)
    });
    _.bindAll(this,
              '_showFiltersFormRegion',
              '_showSourceCategorySelectionFormRegion');
    this.listenTo(this.view, 'render', () => {
      this._showFiltersFormRegion(this.filters);
      this._showSourceCategorySelectionFormRegion();
      this.view.switchToTopics();
    });
    this.listenTo(this.view, 'clicked:topicsTab',
                  this._switchToFilters);
    this.listenTo(this.view, 'clicked:sourceCategoriesTab',
                  this._switchToSourceCategories);
    this.listenTo(this.view, 'clicked:reset',
                  this._resetForm);
    this.listenTo(this.view, 'clicked:search',
      this._saveForm);
  }

  onClose() {
    if (!_.isUndefined(this.clipDetailComponent)) {
        this.clipDetailComponent.close();
    }
    if (!_.isUndefined(this.sourceCategorySelectionFormController)) {
        this.sourceCategorySelectionFormController.close();
    }
    this.view.close();
  }

  _switchToFilters() {
    this.closeClip();
    this.view.switchToTopics();
  }

  _switchToSourceCategories() {
    this.closeClip();
    this.view.switchToSourceCategories();
  }

  _showFiltersFormRegion(filters) {
    if (!filters) {
      filters = buildFilters(null)
    }
    const props = this._buildProps(filters)
    let component = <FilterBuilderView {...props} />
    let componentWrapper = new ReactWrapper({getComponent: () => component})
    this.view.showTopic(componentWrapper);
  }

  _buildProps(filters) {
    return {
      filters: filters,
      onFiltersChange: (filters, valid) => this.onFiltersChange(filters, valid),
      allowedTypes: ['fulltext', 'language', 'date', 'topic', 'location_entity', 'person_entity', 'organization_entity', 'business_entity', 'law_entity'],
      readonly: false
    }
  }

  _getCount() {
    if (this.validForm) {
      this.isNetworkRequestOngoing = true;
      fetch(this.queryBuilder.toUrl(), {
        credentials: "same-origin"
      })
        .then(res => {
          if (res.status === 200) {
            this.isNetworkRequestOngoing = false;
            return res;
          }
          throw new Error("network error")
        })
        .then(res => res.json())
        .then(json => {
          this.count = json.count;
          this.showArticleCount();
        }).catch(bugsnagClient.notify)
    }
  }

  onFiltersChange(filters, valid) {
    this.filters = filters
    this.validForm = valid
    this.queryBuilder.set(this.searchOptions());
    this._getCount();
    if (!this.validForm) {
      this.count = 0;
      this.showArticleCount();
    }
  }

  _showSourceCategorySelectionFormRegion() {
    this.sourceCategorySelectionFormController =
      new SourceCategorySelectionFormController({
        sourceCategoryIds: [],
        excludedCategoryIdentifiers: ['sm', 'rtv']
      });
    this.sourceCategoryFormView =
      this.sourceCategorySelectionFormController.view;
    this.view.showSourceCategories(this.sourceCategoryFormView);
    this.sourceCategorySelectionFormController.setDefaultCategories();

    this.listenTo(
      this.sourceCategorySelectionFormController,
      'changed:sourceCategories', this._getCountBySourceCategoryChange
    );

  }

  _getCountBySourceCategoryChange() {
    const existingCategoryIds = this.queryBuilder.get('source_category_ids');
    const newCategoryIds = this.sourceCategorySelectionFormController.selectedCategoryIds();
    if (!_.isEmpty(_.difference(existingCategoryIds, newCategoryIds)) || (_.difference(newCategoryIds,existingCategoryIds))) {
      this.queryBuilder.set('source_category_ids', newCategoryIds);
      this._getCount();
    }
  }

  _resetForm() {
    this.filters = this.emptyFilters;
    this._showFiltersFormRegion(this.filters);
    this.sourceCategorySelectionFormController.setDefaultCategories({ silent: true })
    this.queryBuilder.set(this.searchOptions());
    this.count = 0;
    this.showArticleCount();
    this.trigger("reset:timeline");
  }

  _saveForm() {
    if (this.validForm) {
      this.trigger("clicked:search", this.searchOptions());
    } else {
      let newFilters = this.filters.map((block) => {
        return block.map((filter) => {
          return {
            type: filter.type,
            exclude: filter.exclude,
            id: filter.id,
            selection: filter.selection,
            active: filter.active,
            showErrors: true
          }
        })
      })
      this._showFiltersFormRegion(newFilters);
      this.view.switchToTopics();
    }
  }

  searchOptions() {
    var sourceCategoryIds =
      this.sourceCategorySelectionFormController.selectedCategoryIds();
    return {
      search_query: this.filters,
      source_category_ids: sourceCategoryIds,
      aggregateTimelineStart: true
    };
  }

  showClip(clip) {
    this.view.deactivateTabs();
    let component = <ResultDetailView clip={clip.toJSON()} editClipClicked={() => this.showEditClip(clip)} />
    this.clipDetailComponent = new ReactWrapper({getComponent: () => component})
    this.view.showInDetailView(this.clipDetailComponent);
    this.isClipDetailViewOpen = true;
    this.showArticleCount();
  }

  showEditClip(clip) {
    this.view.deactivateTabs();
    let component = <GlobalClipView
      id={clip.id}
      closeCallback={() => this.showClip(clip)}
      onEditedResponse={(data) => {
        clip.set(data)
        this.showClip(clip)
      }}
    />
    this.clipDetailComponent = new ReactWrapper({getComponent: () => component})
    this.view.showInDetailView(this.clipDetailComponent);
  }

  showClips(esResults) {
    this.view.deactivateTabs();
    this.clipController = new MultiClipDetailController(esResults);
    this.view.showInDetailView(this.clipController.view);
    this.isClipDetailViewOpen = true;
    this.showArticleCount();
  }

  closeClip() {
    this.view.activateTabs();
    if (!_.isUndefined(this.clipDetailComponent)) {
      this.clipDetailComponent.close();
      this.trigger('clip:closed');
      this.isClipDetailViewOpen = false;
      this.showArticleCount();
    }
  }

  showArticleCount() {
    const props = {
      count: this.count,
      preloading: this.isNetworkRequestOngoing,
      displayCount: !this.isClipDetailViewOpen
    };
    let component = <ArticleCountView {...props} />
    this.articleCountComponent = new ReactWrapper({getComponent: () => component})
    this.view.showLivePreview(this.articleCountComponent);
  }
}

export default NewSearchSettingsController;
