import Marionette from 'backbone.marionette'
import _ from 'underscore'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
// import { Badge } from 'newsroom-component-library'
//the commented functions in the file are to run bR without 'newsroom-component-library' dependency
import AgentResultDetailView from 'views/agents/agentResultDetailView'
import HeaderActionsController from 'controllers/agents/agentResultHeaderActionsController'
import AgentResultActionsController from 'controllers/agents/agentResultActionsController'
import EditAgentResultController from 'controllers/editAgentResultController'
import agentResultMetadataFactory from 'controllers/agents/agentResultMetadataViewFactory'
import GlobalClipView from "../../components/globalClipEditor/GlobalClipView";
import Analytics from 'libraries/analytics'
import Shortcutter from 'libraries/shortcutter'
import CloseSubObjects from 'libraries/closeSubObjects'
import I18n from 'i18n'
import Notificator from 'libraries/notificator'
import pusherWrapper from 'models/pusherWrapper'
import vent from "libraries/vent";
import ClipAttachmentList from 'components/clipAttachmentList'
import ClipLinks from 'components/clipLinks'
import ClipTopicEntityMetadata from 'components/clipTopicEntityMetadata'
import ReactWrapper from 'views/marionetteReactWrapper'
import session from "models/sessionInstance"

var AgentResultDetailController = Marionette.Controller.extend({
  initialize: function (agentResult) {
    this.agentResult = agentResult;
    this._initView();
    this._defineHeaderActionsController();
    this._maybeMarkAgentResultAsRead();

    this._registerListeners();
    this._bindShortcuts();
    this._avFileNotifications();
    Analytics.sendEvent(
      'br_agent_result_opened',
      {kind: agentResult.get('kind')}
    );

  },
  _initView() {
    this.view = new AgentResultDetailView({model: this.agentResult});
  },
  _showClipLinks: function() {
    let clipLinks = this.agentResult.get('clip_links');
    if (clipLinks && clipLinks.length > 0) {
      let component = <ClipLinks clipLinks={clipLinks} />
      let componentWrapper =
        new ReactWrapper({getComponent: () => component})
      this.view.showAgentResultDetailClipLinks(componentWrapper)
    }
  },
  _showClipTopicEntityMetadata: function() {
    if (session.hasFeature("show_enriched_content")) {
      const newTopics        = this.agentResult.get('topics') || [];
      const deprecatedTopics = this.agentResult.get('soprano_topics') || [];
      const entities         = this.agentResult.get('entities') || [];
      const topics           =
      !_.isEmpty(newTopics) ? newTopics : deprecatedTopics;
      const props = {
        topics: topics,
        entities: entities
      }
      let component        = <ClipTopicEntityMetadata {...props} />
      let componentWrapper =
      new ReactWrapper({getComponent: () => component})
      this.view.showAgentResultDetailClipTopics(componentWrapper)
    }
  },
  _maybeShowClipAttachmentList: function() {
      if (session.hasFeature("new_result_attachment_list")) {
          let attachments = this.agentResult.clipAttachments().map((a) => {
            let caption = a.caption
            if (caption) {
              caption = caption
                .replace('&lt;b&gt;', '')
                .replace('&lt;/b&gt;', '')
            }
            return {
              url: a.url,
              caption: caption,
              extension: a.extension,
              size: a.size,
              name: a.file_name
            }
          })
          if (attachments && attachments.length > 0) {
            let component = <ClipAttachmentList attachments={attachments} />
            let componentWrapper = new ReactWrapper({getComponent: () => component})
            this.view.showAgentResultDetailAttachments(componentWrapper)
          }
      }
  },
  _maybeMarkAgentResultAsRead: function() {
    if (!this.agentResult.get('read') &&
        this.agentResult.isOwnedByCurrentUser()) {
      this.agentResult.set({read: true});
      vent.trigger(
        'changed:resultRead',
        [this.agentResult.get('id')],
        true
      );
      this.agentResult.save(
        {
          agent_result: {read: true},
          socket_id: pusherWrapper.socketId
        },
        { patch: true }
      );
    }
  },
  _defineHeaderActionsController: function() {
    this.headerActionsController =
      new HeaderActionsController({agentResult: this.agentResult});
  },
  _showHeaderActions: function() {
    this.view.headerActions.show(this.headerActionsController.view);
  },
  // _showEditLabel: function() {
  //   let edited = this.agentResult.wasClipEdited()
  //   if (session.showEditLabel() && edited) {
  //   let component =
  //     <Badge
  //       translationKey={'timeline.editLabel'}
  //       blue={true}
  //       compact={true}
  //       />;
  //   let componentWrapper = new ReactWrapper({getComponent: () => component});
  //   this.view.editLabel.show(componentWrapper);
  //   }
  // },
  _initializeActionsController: function() {
    this.agentResultActionsController =
      new AgentResultActionsController({agentResult: this.agentResult});
    this.view.actions.show(this.agentResultActionsController.view);
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'render', () => {
      if (!_.isUndefined(this.agentResultActionsController)) {
        this.agentResultActionsController.close();
      }

      this._showHeaderActions();
      // this._showEditLabel();
      this._initializeActionsController();
      this._maybeShowClipAttachmentList();
      this._showClipLinks();
      this._showClipTopicEntityMetadata();
      this.view.showAgentResultDetailFooter(
        agentResultMetadataFactory(this.agentResult)
      );
    });

    this.listenTo(this.headerActionsController, 'edit-form:start', () => {
      this._createAndDisplayEditController();
    });

    this.listenTo(this.headerActionsController, 'clip-edit-form:start', () => {
      this._createAndDisplayClipEditController();
    });

    this.listenTo(pusherWrapper,
                  'agent_result_changed',
                  this._handleAgentResultPusherChange);

    this.listenTo(this.agentResult,
                  'change',
                  this._handleAgentResultChange);


    this.listenTo(this.agentResult, 'updatedByOtherUser', this.view.render);
    this.listenTo(vent, "updatedByNewsroom", (agentResultId) => {
      if (this.agentResult.get('id') === agentResultId && this.context() === 'agents') {
        this.agentResult.fetch().done(() => { this.view.render(); });
      }
    });
    this.listenTo(vent, "updatedByAgents", (agentResultId) => {
      if (this.agentResult.get('id') === agentResultId && this.context() === 'newsroom') {
        this.agentResult.fetch().done(() => { this.view.render(); });
      }
    });
  },
  context() {
    return 'agents';
  },
  _handleAgentResultPusherChange: function (data) {
    if (data.agent_result_id === this.agentResult.get('id')) {
      var notification =
            I18n.t('webapp.notifications.agent_results.concurrent_update');
      Notificator.showNotification(notification);
    }
  },
  onClose: function () {
    CloseSubObjects.closeAll(
      [this.headerActionsController,
       this.agentResultActionsController]
    );
    this._unbindShortcuts();
  },
  _createAndDisplayEditController: function () {
    this.editController = new EditAgentResultController({
      agentResult: this.agentResult
    });

    this.view.showEditFormView(this.editController.view);

    this.listenTo(this.editController, 'edit-form:finished', () => {
      this.view.showAgentResultDetailView();
      this.view.render();
      this.editController.close();
    });
  },
  _createAndDisplayClipEditController: function () {
    let clipId = this.agentResult.get('clip_id')
    let restoreDetailView = () => {
      this.view.showAgentResultDetailView();
      this.view.render();
    }
    const onEditedResponse = () => {
      this.agentResult.fetch().done(() => { this.view.render(); });
    }
    let view = new ReactWrapper({getComponent: () => {
      return <GlobalClipView id={clipId} closeCallback={restoreDetailView} onEditedResponse={onEditedResponse}/>
    }});
    this.view.showEditFormView(view);
  },
  getClipAdminUrl: function (clipId) {
    return `${window.location.protocol}//${window.location.hostname}/clips/${clipId}`.replace("app.", "admin.");
  },
  _bindShortcuts: function () {
    var self = this;
    var href;
    Shortcutter.bindInModule(this._shortcutModuleName(),
                             'o', function () {
                               href = self.agentResult.get('primary_source').clip_url;
                               if (!_.isUndefined(href) && !_.isNull(href)) {
                                 window.open(href, '_blank');
                               }
    });
    Shortcutter.bindInModule(this._shortcutModuleName(), 'alt+o', function () {
      if (session.isImpersonated() || session.can('internal_access')) {
        const clipId = self.agentResult.get("clip_id");
        const href = self.getClipAdminUrl(clipId);
        if (!_.isUndefined(href) && !_.isNull(href)) {
          window.open(href, '_blank');
        }
      }
    });
  },
  _unbindShortcuts: function () {
    Shortcutter.unbindInModule(this._shortcutModuleName(), 'o');
    Shortcutter.unbindInModule(this._shortcutModuleName(), 'alt+o');
  },
  _shortcutModuleName: function () {
    return 'agents';
  },
  _avFileNotifications: function () {
    if (this.agentResult.isRadioTvClip() && this.agentResult.hasPreview()) {
      if (this.agentResult.get('av_processing_failed') === false) {
        if (_.isEmpty(this.agentResult.get('primary_source').clip_url)) {
          if (this.agentResult.avProcessingTimeout()) {
            Notificator.showNotification(I18n.t("webapp.notifications.error.audio_video_file_not_available"));
          } else {
            Notificator.showNotification(I18n.t("webapp.notifications.warning.file_in_progress"));
          }
        }
      } else {
        if (_.isEmpty(this.agentResult.get('primary_source').clip_url)) {
          Notificator.showNotification(I18n.t("webapp.notifications.error.audio_video_not_saved"));
        }
      }
    }
  }
});

export default AgentResultDetailController;
