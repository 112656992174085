import Marionette from 'backbone.marionette'
import _ from 'underscore'
import NotificationsListView from 'views/settings/notifications/notificationsListView'

var NotificationsListController = Marionette.Controller.extend({
  initialize: function (options) {
    this._settings = options.notification_settings;
    this._checkSettings();
    this.view = new NotificationsListView();
    this._registerListeners();
  },
  _summary: function () {
    this.view.activateSummary();
    this.view.deactivateRealtime();
    this.trigger('summary');
  },
  _realtime: function () {
    this.view.activateRealtime();
    this.view.deactivateSummary();
    this.trigger('realtime');
  },
  _desktopFormat: function () {
    this._settings.save({
      email_format: 'default'
    });
    this._updateView();
  },
  _mobileFormat: function () {
    this._settings.save({
      email_format: 'mobile'
    });
    this._updateView();
  },
  _updateView: function () {
    var format = this._settings.get('email_format');
    if (format === 'default') {
      this.view.activateDesktopFormat();
      this.view.deactivateMobileFormat();
    } else {
      this.view.activateMobileFormat();
      this.view.deactivateDesktopFormat();
    }
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'summary', this._summary);
    this.listenTo(this.view, 'realtime', this._realtime);
    this.listenTo(this.view, 'render', this._updateView);
    this.listenTo(this.view, 'email_format:desktop', this._desktopFormat);
    this.listenTo(this.view, 'email_format:mobile', this._mobileFormat);
  },
  _checkSettings: function () {
    if(_.isUndefined(this._settings)) {
      throw "no NotificationSettings given";
    }
  },
  activateSummary: function () {
    this.view.deactivateRealtime();
    this.view.activateSummary();
  },
  activateRealtime: function () {
    this.view.deactivateSummary();
    this.view.activateRealtime();
  }
});

export default NotificationsListController;

