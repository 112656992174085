import Marionette from "backbone.marionette";
import template from "text-loader!templates/agents/metadata/verbalix.html";
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import I18n from 'i18n'
import _ from 'underscore';

class SopranoVerbalixView extends Marionette.ItemView {
  initialize() {
    this.agentResult = this.options.agentResult;
  }

  serializeData() {
    const data = this.agentResult.get('clip_metadata');

    let metadata = {};

    let prefix = 'webapp.agents.results.result_details.metadata.soprano.verbalix.status_'
    let translated_status;
    if (data.status === 'draft') {
      translated_status = I18n.t(prefix + 'draft');
    } else if (data.status === 'final') {
      translated_status = I18n.t(prefix + 'final');
    } else {
      translated_status = data.status;
    }
    const col1 = {
      last_modified: data.last_modified,
      status: translated_status
    }
    if (data.language) {
      col1['language'] =
        I18n.t("vendor.iso.languages." + data.language);
    }

    conditionalSetToMetadata(metadata, 'col1', col1);

    const col2 = {
      council: data.council,
      session: data.session,
      meeting_order: data.meeting_order,
      meeting_date: data.meeting_date
    }
    const publish_notes =  _.compact(
      _.map(
        data.businesses,
        (business) => business.published_note
      )
    )
    if (publish_notes.length > 0) {
      col2['publish_notes'] = publish_notes.join("; ");
    }
    conditionalSetToMetadata(metadata, 'col2', col2);

    const business_numbers = _.compact(
      _.map(
        data.businesses,
        (business) => business.business_number
      )
    )
    const business_types = _.compact(
      _.map(
        data.businesses,
        (business) => business.business_type
      )
    )
    let col3 = {}
    if (business_numbers.length > 0) {
      col3['business_numbers'] = business_numbers.join("; ");
    }
    if (business_types.length > 0) {
      col3['business_types'] = business_types.join("; ");
    }
    conditionalSetToMetadata(metadata, 'col3', col3);

    return metadata;
  }
}

SopranoVerbalixView.prototype.template = template;

export default SopranoVerbalixView;
