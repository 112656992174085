import * as Backbone from 'backbone'
import Marionette from 'backbone.marionette';
import * as template from 'text-loader!templates/newsroom/readerEditNotificationConfiguration.html';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import ReaderEditNotificationConfigurationView
    from 'views/newsroom/readerEditNotificationConfigurationView';

class ReaderNotificationConfigurationView extends Marionette.Layout {
    renderView(props: any) {
        const editNotificationConfigurationView = new ReactWrapper({
          getComponent: () => {
            return <ReaderEditNotificationConfigurationView {...props}/>
          }
        });
        this.editNotificationConfiguration.show(editNotificationConfigurationView);
    }
}

ReaderNotificationConfigurationView.prototype.regions = {
    editNotificationConfiguration: '[data-edit-notification-configuration]',
};
ReaderNotificationConfigurationView.prototype.template = template;

export default ReaderNotificationConfigurationView;
