import PieChart from 'views/dashboard/widgetView/helpers/pieChart'
import formatNumber from 'libraries/formatNumber'
import I18n from 'i18n'
import _ from 'underscore'
import $ from 'jquery'
import AgentTagWidgetView from 'views/dashboard/agentTagWidgetView'

var SourceDistributionPieWidgetView = AgentTagWidgetView.extend({
  onInitialize: function () {
    this.enableAutoRefresh();
  },
  prepareWidgetBody: function () {
    var container = this.$('div.chart')[0];
    var legend = this.$('.legend');
    var default_legend = this.$('.default-legend');
    var backgroundColor = this.chartBackgroundColor($(container).css('background-color'));
    var _this = this;

    this.chart = new PieChart(container, {
      chart: {
        width: 246,
        height: 140,
        backgroundColor: backgroundColor
      },
      plotOptions: {
        pie: {
          point: {
            events: {
              mouseOver: function () {
                this.select();
                legend.html(this.name + ' ' + this.y + '<b>(' + formatNumber(this.percentage) + '%)</b>');
                default_legend.addClass('invisible');
              },
              mouseOut: function () {
                this.select(false);
                legend.html('');
                default_legend.removeClass('invisible');
              },
              click: function () {
                _this._clickInChart(this.color);
              }
            }
          }
        }
      }
    });

    var data = _.map(this.seriesData(), function (item) {
      return {
        name: item.name,
        y: item.value,
        color: item.color
      };
    });
    this.chart.addSeries({
      data: data
    });

    this.chart.render();
  },
  _clickInChart: function (color) {
    var type = this.colorToType[color];
    this._showFilteredResultsByType(type);
  },
  _showFilteredResultsByType: function () {
  },
  colorToType: {
    '#339900': 'print',
    '#991100': 'social_media',
    '#006699': 'online',
    '#EEBB00': 'radio_tv'
  },
  seriesData: function () {
    var distribution = this.model.get('distribution');
    return [
      {
        name: I18n.t('webapp.dashboard.widget_content.source_distribution_print'),
        value: distribution.print,
        color: '#339900'
      },
      {
        name: I18n.t('webapp.dashboard.widget_content.source_distribution_social_media'),
        value: distribution.social_media,
        color: '#991100'
      },
      {
        name: I18n.t('webapp.dashboard.widget_content.source_distribution_online'),
        value: distribution.online,
        color: '#006699'
      },
      {
        name: I18n.t('webapp.dashboard.widget_content.source_distribution_radio_tv'),
        value: distribution.radio_tv,
        color: '#EEBB00'
      }
    ];
  },
  clickInLegend: function (event) {
    var target = this.$(event.target);
    var type = target.data('legend');
    this._showFilteredResultsByType(type);
  },
  ui: _.extend({}, AgentTagWidgetView.prototype.ui, {
    legend: '[data-legend]'
  }),
  events: {
    'click @ui.legend': 'clickInLegend'
  }

});

export default SourceDistributionPieWidgetView;

