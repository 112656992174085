import Widget from 'models/dashboard/widget'

Widget.SocialMediaTrackerChartWidget = Widget.extend({
  clipCount: function () {
    return this._countClipsInChartData(this.get('chart_data'));
  }
});

export default Widget.SocialMediaTrackerChartWidget;

