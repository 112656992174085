import * as Backbone from "backbone";
import * as _ from "underscore";
import I18n from "i18n";

export type EntityType =
    'law'
  | 'topic'
  | 'location'
  | 'person'
  | 'organization'
  | 'business'

export default class Entity extends Backbone.Model {
  parseName(): string {
    if (this.get('type') === 'topic') {
      return this.parseTopicName()
    } else {
      return this.parseEntityName()
    }
  }

  private parseTopicName(): string {
    let identifier = this.get('meta').identifier
    if (!this.get('parent')) {
      identifier = `${identifier}.__main`
    }
    return I18n.t(identifier)
  }

  private parseEntityName(): string {
    const names = this.names()
    const defaultLocale = "de"
    const locale = I18n.locale.split('-')[0]
    const restrictedLocale =
      _.contains(_.keys(names), locale) ? locale : defaultLocale;
    const localizedName = names[restrictedLocale]
    if (localizedName && localizedName.length > 0) {
      return localizedName
    } else {
      return names[defaultLocale]
    }
  }

  parse(response: any) {
    if (response.doc_type === 'entity') {
      response.entity.type = response.doc_type
      return response.entity
    } else {
      response.topic.type = response.doc_type
      response.topic.parent = new Entity(response.parent_topic)
      return response.topic
    }
  }

  // names can be either embedded directly or under "meta"
  // TODO: make the json structure uniform
  names(): { [key: string]: string } {
    let names: { [key: string]: string }
    let meta = this.get("meta") || {}
    if (meta.name_en ||
        meta.name_de ||
        meta.name_fr ||
        meta.name_it
       ) {
      names = {
        "en": meta.name_en,
        "de": meta.name_de,
        "fr": meta.name_fr,
        "it": meta.name_it,
      };
    } else {
      names = {
        "en": this.get("name_en"),
        "de": this.get("name_de"),
        "fr": this.get("name_fr"),
        "it": this.get("name_it")
      };
    }
    return names
  }
}
