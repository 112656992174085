import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/trendingTopicsWidget.html'
import BarChart from 'views/dashboard/widgetView/helpers/barChart'
import session from 'models/sessionInstance'
import _ from 'underscore'

WidgetView.TrendingTopicsWidget = WidgetView.extend({
  template: template,
  onInitialize: function () {
    this.enableAutoRefresh();
  },
  prepareWidgetBody: function () {
    var container = this.$('div.chart')[0];
    var tags = this.model.get('trending_tags');

    if (_.isEmpty(tags)) {
      this.$(container).html('<p class="trending-topics-message">Diese Woche wurden noch keine Treffer ausgewertet.</p>');
      this.$('.trending-topics-text').hide();
    } else {
      var categories = _.map(tags, (tag) => {
        return this._truncateLabel(tag.name);
      }, this);

      var theme = session.get('dashboard_theme');
      var options = {
        chart: {
          width: 246,
          height: 140
        },
        xAxis: {
          categories: categories,
          labels: {
            style: {
              width: 1000
            }
          }
        }
      };
      options.plotOptions = {bar: {} };
      if (theme === 'swiss') {
        options.plotOptions.bar.color = '#ED1C24';
      } else {
        options.plotOptions.bar.color = '#006699';
      }
      this.chart = new BarChart(container, options);

      var data = _.map(tags, function (tag) {
        return tag.count;
      });

      this.chart.addSeries({
        data: data
      });

      this.chart.render();
    }
  },
  prepareSerializedData: function () {
    return {
      total: this.model.get('total')
    };
  }
});

export default WidgetView.TrendingTopicsWidget;

