import Marionette from 'backbone.marionette'
import TimelineView from 'views/timeline/timelineView'
import AutomaticAppendTracker from 'models/agents/automaticAppendTracker'
import Day from 'models/timeline/timelineDay'
import AgentResult from 'models/agentResult'
import _ from 'underscore'
import when from 'when'
import Shortcutter from 'libraries/shortcutter'
import Notificator from 'libraries/notificator'
import I18n from 'i18n'
import SelectionManager from 'models/timeline/selectionManager'
import moment from 'moment'
import session from 'models/sessionInstance';
import vent from 'libraries/vent'

var TimelineController = Marionette.Controller.extend({
  expandCurrentDay: false,
  initialize: function (options) {
    _.bindAll(this, '_handleNewerDayBinding',
              '_handleOlderDayBinding', '_handleSelectAllBinding',
              '_scrollItemIntoViewTop', '_expandNewRecords');

    this.showTimeline2 = session.timeline2Enabled();
    this._initializeTimelineView(options);
    this.listOptions = _.pick(options, this.listOptionKeys);
    this.listControllers = {};
    this.dayViews = {};
    this.listItemDay = {};
    this.resultsForDay = {};
    this.shownLists = {};
    this.appendTracker = new AutomaticAppendTracker(3);
    this.shownListItems = new this.ListItemCollection();
    var self = this;
    this.selectionManager = new SelectionManager(
      this.shownListItems,
      this.ListItemCollection,
      function (item, direction) {
        return self._maybeFetchNewItems(item, direction);
      }
    );
    this.selection = this.selectionManager.selection;
    this.listenTo(
      this.selection,
      'remove',
      this._handleSelectionRemoval
    );
    this._registerListeners();
    this.spillOverCount = 0;
    this._initialFetch();
  },
  clearSelection: function () {
    this.selectionManager.clearSelection();
  },
  _initialFetch: function () {
    when(this._expandFirstMonth()).done(() => {
      this._loadNewestResults().then(() => {
        setTimeout(() => {
          this.trigger('initialFetchDone')
        }, 300);
      });
    });
  },
  _initializeTimelineView: function (options) {
    this.view = new TimelineView({
      startAt: this._startDate(options),
      customOptions: {
        showCheckboxes: !this.disableMultipleSelection()
      }
    });
  },
  _startDate: function (options) {
    return options.startAt;
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'years:months:days:day:clicked', this.toggleList);
    this.listenTo(this.view, 'years:months:month:clicked', this.toggleDays);
    this.listenTo(this.view, 'years:year:clicked', this.toggleMonths);
    this.listenTo(this.view, 'years:months:days:checkbox:changed', this.checkboxStateChanged);
    this.listenTo(this.view, 'years:months:days:close', this.dayViewClosing);
    this.listenTo(this.view, 'years:months:days:render', this.registerDayView);
    this.listenTo(this.selection, 'add', this.notifySelect);
    this._bindShortcuts();
  },

  _handleSelectionRemoval: function (listItem) {
    this.notifyDeselect(listItem);
  },
  notifyDeselect: function (listItem) {
    if (_.isUndefined(this.listItemDay[listItem.id]) ||
        _.isUndefined(this.listItemDay[listItem.id].cid) ||
        _.isUndefined(this.listControllers[this.listItemDay[listItem.id].cid])) {
      return;
    }

    var dayId = this.listItemDay[listItem.id].cid;
    var listController = this.listControllers[dayId];
    listController.markAsDeselected(listItem);
    this.updateCheckbox(this.dayViews[dayId], listController);
    this._maybeScroll(listController, listItem);
  },
  _expandFirstMonth: function () {
    var years = this.view.collection;
    years.fill();
    var year = years.first();
    year.fillMonths();
    var month = year.months.first();
    month.fillDays();
    return when.resolve();
  },
  disableMultipleSelection: function () { return false },
  pollNewResults: function () {},
  _loadNewestResults: function () {
    if (this.expandCurrentDay) {
      this.showTimeline();
      this.pollNewResults();
    } else {
      return new Promise((resolve) => {
        this._loadingNewestResults = true;
        var newestResults = new this.ListItemApiCollection([], this.listOptions);
        var self = this;
        newestResults.prependResults().done(function () {
          self.newestResult = newestResults.first();

          if (newestResults.length > 0) {
            var oldestResultTime = self.getTime(newestResults.last());
            var newestResultTime = self.getTime(newestResults.first());
            self._expandNewRecords(
              newestResults, oldestResultTime, newestResultTime
            );
            self.showTimeline();
          } else {
            self._showNoResults();
          }

          self.pollNewResults();

          // Results are not rendered yet, so wait for the
          // next execution context.
          setTimeout(function() {
            self._loadingNewestResults = false;
            resolve();
          }, 0);
        }).fail(function () {
          Notificator.showErrorNotification(
            I18n.t('webapp.agents.results.result_list.error_loading_results')
          );
        });
      })
    }
  },
  _showNoResults: function () {
    this.view.showNoResults(
      I18n.t('webapp.agents.results.result_list.no_search_results')
    );
  },
  _expandNewRecords: function(newRecords, oldestTime, newestTime) {
    var monthsToExpand = _.uniq(newRecords.map((result) => {
      var time = this.getTime(result);
      var mom = moment(time);
      mom.startOf('month');
      return mom.unix();
    }));
    this.expandYearsAndMonthsBetween(
      new Day(oldestTime), new Day(newestTime), monthsToExpand
    );
    this.expandOrHideDays(oldestTime, newestTime, newRecords);
  },
  expandOrHideDays: function (oldestResultTime, newestResultTime, results) {
    var resultsForDay = results.groupBy((result) => {
      var time = this.getTime(result);
      return this.getDayContaining(time).cid;
    }, this);
    var oldestDay = this.getDayContaining(oldestResultTime);
    var newestDay = this.getDayContaining(newestResultTime);
    _.each(this.dayViews, (dayView, dayId) => {
      if (resultsForDay[dayId]) {
        this.showList(dayView, resultsForDay[dayId], dayId !== oldestDay.cid);
      } else {
        var day = dayView.model;
        if (day.isAfter(oldestDay)) {
          if (day.isBefore(newestDay)) {
            this.showList(dayView, [], true);
          } else {
            dayView.hideDay();
          }
        }
      }
    }, this);
  },
  getDayContaining: function (date) {
    var day = new Day(date);
    var year = this._findYearContainingDay(day);
    var month = year.months.findMonthContaining(day);
    if (!month) {
      throw new Error('Could not find the month in the timeline');
    }
    var dayFound = month.days.findSameDay(day);
    if (!dayFound) {
      throw new Error('Could not find the day in the timeline');
    }
    return dayFound;
  },
  safeGetDayContaining: function (date) {
    var day = new Day(date);
    var year = this._findYearContainingDay(day);
    var month = year.months.findMonthContaining(day);
    if (!month) {
      return undefined;
    }
    var dayFound = month.days.findSameDay(day);
    if (!dayFound) {
      return undefined;
    }
    return dayFound;
  },
  openDate: function (moment) {
    if (this.showTimeline2 === false) {
      this.collapseAll();

      // show two last days of month
      const jsDate = moment.toDate()
      const day = new Day(jsDate)
      this.expandYearsAndMonthsBetween(day, day)

      const day1 = this.safeGetDayContaining(jsDate)
      if (!_.isUndefined(day1)) {
        const dayView = this.dayViews[day1.cid];
        const self = this;
        self._scrollToDate(jsDate);
        this.showList(dayView, undefined, undefined, true);
      }
    }
  },
  _scrollToDate: function (date) {
    const day = this.view.findDayViewByDate(date);
    if (!_.isUndefined(day)) {
      this._scrollIntoViewTop(
        day.getDistanceFromTopAndBottom()
      );
    }
  },
  collapseAll: function () {
    const months = _.chain(this.view.collection.models)
    .map(function (year) {
      return year.months.models
    }).flatten().value();
    _.each(months, function (month) {
      const fmt = 'MM-YYYY'
      if (moment(month.get('date')).format(fmt) !==
          moment().format(fmt)) {
        month.days.reset();
      }
    });
  },
  expandYearsAndMonthsBetween: function (oldestDay, newestDay,
                                         monthsToExpand) {
    var years = this._getYears();
    years.each((year) => {
      if (year.months.isEmpty()) {
        if (this._isBetween(year, oldestDay, newestDay)) {
          year.fillMonths();
        }
      }
      year.months.each((month) => {
        var monthTimestamp = moment(month.get('date')).startOf('month').unix();
        if (!monthsToExpand || _.contains(monthsToExpand, monthTimestamp)) {
          if (month.days.isEmpty()) {
            if (this._isBetween(month, oldestDay, newestDay)) {
              month.fillDays();
            }
          }
        }
      }, this);
    }, this);
  },
  _isBetween: function (yearOrMonth, oldestDay, newestDay) {
    if (yearOrMonth.isBefore(newestDay) || yearOrMonth.includes(newestDay)) {
      return yearOrMonth.isAfter(oldestDay) || yearOrMonth.includes(oldestDay);
    }
    return false;
  },
  toggleList: function (_yearView, _monthView, dayView) {
    if (this.isResultListShown(dayView.model)) {
      this.setSelectionForDay(dayView.model, false);
      this.hideList(dayView);
    } else { this.showList(dayView, undefined, undefined, true); }
  },
  isResultListShown: function (day) {
    return _.isObject(this.listControllers[day.cid]);
  },
  toggleDays: function (yearView, monthView) {
    if (monthView.model.days.length > 0) { this.hideDays(monthView); }
    else { this.showDays(monthView); }
  },
  toggleMonths: function (yearView) {
    if (yearView.model.months.length > 0) { this.hideMonths(yearView); }
    else { this.showMonths(yearView); }
  },
  checkboxStateChanged: function (yearView, monthView, dayView, isChecked) {
    this.setSelectionForDay(dayView.model, isChecked);
  },
  setSelectionForDay: function (day, isSelected) {
    var controller = this.listControllers[day.cid];
    if (!controller) { return; }
    var listItems = controller.shownItems();
    if (isSelected) {
      this.selectionManager.addToSelection(listItems);
    } else {
      this.selectionManager.removeFromSelection(listItems);
    }
  },
  showList: function (dayView, results, noMoreResults, showEmptyDays) {
    if (!dayView.resultList) { return when.resolve(); }
    var noResults = !results || results.length === 0;
    var self = this;
    return when.promise(function (resolve) {
      var date = dayView.model.get('date');
      var listController = new self.ListController(_.extend({
        dateModel: dayView.model,
        createdAtDate: date,
        appendTracker: self.appendTracker,
        results: results,
        noMoreResults: noMoreResults,
        selectionManager: self.selectionManager
      }, self.listOptions));

      self.listenTo(listController, 'result:clicked', function (model) {
        self.updateSelection(model);
      });

      self.listenToOnce(listController, 'allItemsLoaded', function () {
        self.maybeSpillOver(dayView.model);
      });

      if (!self.disableMultipleSelection()) {
        self.listenTo(listController, 'result:ctrlClicked', self.toggleSelection);
        self.listenTo(listController, 'result:shiftClicked', self.handleShiftClick);
      }

      self.listControllers[dayView.model.cid] = listController;
      self.listenTo(listController.view, 'close', function () {
        listController.close();
        delete self.listControllers[dayView.model.cid];
      });
      self.listenTo(listController, 'newItemsLoaded', function () {
        self.newItemsLoaded(dayView, listController);
      });
      self.listenTo(listController, 'result:add', function (listItem) {
        self.listItemDay[listItem.id] = dayView.model;
      });
      var maybeHideDay = function () {
        if (listController.shownItems().length === 0 && showEmptyDays !== true) {
          dayView.hideDay();
        }
        resolve();
      };
      self.listenToOnce(listController, 'initial-results-shown', maybeHideDay);
      self.listenTo(listController, 'change:content:start',
                    self._startChangingContent);
      self.listenTo(listController, 'change:content:stop',
                    self._stopChangingContent);
      dayView.resultList.show(listController.view);
      dayView.setCheckboxState({checked: false, disabled: false});
      if (!noResults){
        self.newItemsLoaded(dayView, listController);
      }
    });
  },
  // Gather all shownListItems so that the newest ones are first in the
  // shownListItems collection
  setShownListItems: function () {
    this.shownListItems = this.getShownListItems();
    this.selectionManager.setCollection(this.shownListItems);
  },
  getShownListItems: function () {
    var shownListItems = new this.ListItemCollection();
    var keys = _.keys(this.listControllers);
    var sortedKeys = _.sortBy(keys, (key) => {
      var day = this.dayViews[key].model;
      return -day.getBeginningTimestamp();
    }, this);

    var self = this;
    _.each(sortedKeys, function (key) {
      var controller = self.listControllers[key];
      shownListItems.add(controller.shownItems());
    });

    return shownListItems;
  },
  getSortedListKeys: function () {
    var keys = _.keys(this.listControllers);
    return _.sortBy(keys, (key) => {
      var day = this.dayViews[key].model;
      return -day.getBeginningTimestamp();
    }, this);
  },
  newItemsLoaded: function (dayView, listController) {
    this.updateCheckbox(dayView, listController);
    this.setShownListItems();
  },
  handleShiftClick: function (listItem) {
    if (this.selection.isEmpty()) {
      return;
    }

    this.selectionManager.selectUntil(listItem);
  },
  showDays: function (monthView) {
    monthView.model.fillDays();
  },
  showMonths: function (yearView) {
    yearView.model.fillMonths();
  },
  hideList: function (dayView) {
    dayView.setCheckboxState({checked: false, disabled: true});
    this._removeReferences(dayView.model);
    dayView.resultList.close();
  },
  hideDays: function (monthView) {
    monthView.model.days.reset();
  },
  hideMonths: function (yearView) {
    yearView.model.months.reset();
  },
  updateSelection: function (listItem) {
    if (this.selection.get(listItem.id) && this.selection.length === 1) {
      this.selectionManager.clearSelection();
    } else {
      this.selectionManager.setSelection([listItem]);
    }
  },
  setSelectedAgentResults: function (toBeSelected) {
    if (toBeSelected.map) {
      let agentResults = toBeSelected.map((item) => {
        if (item instanceof AgentResult === false) {
          let str = JSON.stringify(item, (key, value) => {
            if (key === 'ref') {
              return undefined
            }
            return value;
          });
          let obj = JSON.parse(str);
          const agentResult = new AgentResult(obj, { parse: true });
          return agentResult;
        }
        return item;
      })
      this.selectionManager.clearSelection();
      this.selectionManager.addToSelection(agentResults);
      vent.trigger("agentsTimeline:selectionChanged");
    }
  },
  toggleSelection: function (listItem) {
    if (this.selection.contains(listItem)) {
      this.selectionManager.removeFromSelection(listItem);
    } else {
      this.selectionManager.addToSelection(listItem);
    }
  },
  notifySelect: function (listItem) {
    if (_.isUndefined(this.listItemDay[listItem.id]) ||
        _.isUndefined(this.listItemDay[listItem.id].cid) ||
        _.isUndefined(this.listControllers[this.listItemDay[listItem.id].cid])) {
      return;
    }

    var dayId = this.listItemDay[listItem.id].cid;
    var listController = this.listControllers[dayId];
    listController.markAsSelected(listItem);
    this.updateCheckbox(this.dayViews[dayId], listController);
    this._maybeScroll(listController, listItem);
  },
  dayViewClosing: function (yearView, monthView, dayView) {
    this.setSelectionForDay(dayView.model, false);
    this.dayViews[dayView.model.cid] = null;
    this._removeReferences(dayView.model);
  },
  registerDayView: function (yearView, monthView, dayView) {
    this.dayViews[dayView.model.cid] = dayView;
    if (this.expandCurrentDay && dayView.model.isCurrent()) {
      this.toggleList(null, null, dayView);
    }
  },
  updateCheckbox: function (dayView, controller) {
    if (this.disableMultipleSelection()) { return; }
    var listItems = controller.shownItems();
    if (listItems.length === 0) {
      dayView.setCheckboxState({checked: false, disabled: true});
    } else {
      dayView.setCheckboxState({checked: this.allListItemsSelected(listItems)});
    }
  },
  allListItemsSelected: function (listItems) {
    return _.every(listItems, (listItem) => {
      return this.selection.contains(listItem);
    }, this);
  },
  _removeReferences: function (day) {
    var controller = this.listControllers[day.cid];
    if (controller) {
      _.each(controller.shownItems(), (listItem) => {
        this.shownListItems.remove(listItem);
        delete this.listItemDay[listItem.id];
      }, this);
      delete this.listControllers[day.cid];
    }
  },
  expandPreviousDay: function (day) {
    this.expandMonthContainingPreviousDay(day);
    var previousDay = this.findPreviousDay(day);
    if (previousDay && !this.isResultListShown(previousDay)) {
      return this.showList(this.dayViews[previousDay.cid]);
    } else {
      return when.resolve();
    }
  },
  _expandDay: function (day) {
    if (this.isResultListShown(day)) {
      return when(1);
    }

    var self = this;
    var promise1 = when.promise(function (resolve) {
      self._expandMonthContainingDay(day);
      resolve(self.showList(self.dayViews[day.cid]));
    });

    var promise2 = when.promise(function (resolve) {
      resolve(self.listControllers[day.cid].appendItems());
    });

    return when.join(promise1, promise2);
  },
  _findMonth: function (day) {
    var year = this._getYears().findYearContaining(day);
    var month = year.months.findMonthContaining(day);
    if (year.months.last() !== month) {
      var index = year.months.indexOf(month);
      return year.months.at(index + 1);
    } else {
      var previousYear = this.findPreviousYear(day);
      if (previousYear) {
        return previousYear.months.first();
      }
    }
  },
  _expandMonthContainingDay: function (day) {
    if (day.isBeginningOfYear()) {
      var year = this._getYears().findYearContaining(day);
      if (year && year.months.isEmpty()) {
        year.fillMonths();
      }
    }
    if (day.isBeginningOfMonth()) {
      var month = this._findMonth(day);
      if (month && month.days.isEmpty()) {
        month.fillDays();
      }
    }
  },
  spillOverLimit: 10,
  maybeSpillOver: function (day) {
    this.expandMonthContainingPreviousDay(day);
    var previousDay = this.findPreviousDay(day);
    if (previousDay) {
      if (!this.isResultListShown(previousDay)) {
        if (this.spillOverCount < this.spillOverLimit) {
          this.spillOverCount += 1;
          this.showList(this.dayViews[previousDay.cid]);
        } else {
          this.spillOverCount = 0;
        }
      }
    }
  },
  expandMonthContainingPreviousDay: function (day) {
    if (day.isBeginningOfYear()) {
      var previousYear = this.findPreviousYear(day);
      if (previousYear && previousYear.months.isEmpty()) {
        previousYear.fillMonths();
      }
    }
    if (day.isBeginningOfMonth()) {
      var previousMonth = this.findPreviousMonth(day);
      if (previousMonth && previousMonth.days.isEmpty()) {
        previousMonth.fillDays();
      }
    }
  },
  findPreviousDay: function (day) {
    var month = this._findMonthContainingDay(day);
    if (month.days.last() !== day) {
      var index = month.days.indexOf(day);
      return month.days.at(index + 1);
    } else {
      var previousMonth = this.findPreviousMonth(day);
      if (previousMonth) {
        return previousMonth.days.first();
      }
    }
  },
  findPreviousYear: function (day) {
    var years = this._getYears();
    var year = years.findYearContaining(day);
    var index = years.indexOf(year);
    return years.at(index + 1);
  },
  findPreviousMonth: function (day) {
    var year = this._findYearContainingDay(day);
    var month = year.months.findMonthContaining(day);
    if (year.months.last() !== month) {
      var index = year.months.indexOf(month);
      return year.months.at(index + 1);
    } else {
      var previousYear = this.findPreviousYear(day);
      if (previousYear) {
        return previousYear.months.first();
      }
    }
  },
  showTimeline: function () {
    this.view.showTimeline();
    const el =
      this.view.scrollingHandler.safeGetScrollableElement()
    if (el) {
        this.trigger('scrollableElementUpdated', el.get()[0])
    }
  },
  onClose: function () {
    this._unbindShortcuts();
  },
  _getYears: function () {
    return this.view.collection;
  },
  _findYearContainingDay: function (day) {
    var years = this._getYears();
    var year = years.findYearContaining(day);
    return year;
  },
  _findMonthContainingDay: function (day) {
    var year = this._findYearContainingDay(day);
    var month = year.months.findMonthContaining(day);
    return month;
  },
  _maybeFetchNewItems: function (item, direction) {
    return when.join(
      this._maybeLoadMoreResults(item, direction),
      this._maybeExpandNextDay(item, direction)
    );
  },
  _maybeLoadMoreResults: function (item, direction) {
    // We only ever might fetch new things when moving down
    // and when there is a current item
    if (direction !== 'down' || _.isUndefined(item)) {
      return when.resolve();
    }
    var self = this;
    var promise = when.promise(function (resolve) {
      var listController = self._findListControllerForItem(item);
      var lastItem = _.last(listController.shownItems());
      if (lastItem === item && listController.canLoadMoreResults()) {
        resolve(listController.appendItems());
      } else {
        resolve();
      }
    });
    return promise;
  },
  _maybeExpandNextDay: function (item, direction) {
    // We only ever might expand the next day when moving down
    // and when there is a current item
    if (direction !== 'down' || _.isUndefined(item)) {
      return when.resolve();
    }
    var self = this;
    var promise = when.promise(function (resolve) {
      var listController = self._findListControllerForItem(item);
      var lastItem = _.last(listController.shownItems());
      if (lastItem === item && !listController.canLoadMoreResults()) {
        var day = self.listItemDay[item.id];
        var prevDayPromise = self.expandPreviousDay(day);
        resolve(prevDayPromise);
      } else {
        resolve();
      }
    });
    return promise;
  },
  _findListControllerForItem: function (item) {
    var dayId = this.listItemDay[item.id].cid;
    return this.listControllers[dayId];
  },
  /**
   * Finds a list controller using a unique id. Ids are generated in
   * ListControllers constructors
   *
   * @param {String} id
   * @returns {ListController}
   */
  _findListControllerById: function (id) {
    return _.pairs(this.listControllers)
      .map(_.last)
      .filter(function(listController) {
        return listController.listControllerId === id;
      })[0];
  },
  _bindShortcuts: function () {
    var self = this;
    Shortcutter.updateNamespace(this.moduleName);
    if (this.moduleName === 'agents' || this.moduleName === 'research') {
      Shortcutter.bindInModule(this.moduleName,
                               'esc',
                               function () {
                                 self.selectionManager.clearSelection();
                               });
      if (this.showTimeline2 === false) {
        Shortcutter.bindInModule(this.moduleName,
          ['down', 'j'],
          function () {
            self.selectionManager.selectNextItem('down');
            return false;
          }
        );
        Shortcutter.bindInModule(this.moduleName,
          ['up', 'k'],
          function () {
            self.selectionManager.selectNextItem('up');
            return false;
          }
        );
      }
    }

    if (
      (this.moduleName === 'agents') ||
        (this.moduleName === 'research' &&
         session.hasFeature('access_to_new_research'))
       ) {

      Shortcutter.bindInModule(this.moduleName,
                               ['shift+down', 'shift+j'],
                               function () {
                                 self.selectionManager.shiftSelectNextItem('down');
                                 return false;
                               });
      Shortcutter.bindInModule(this.moduleName,
                               ['shift+up', 'shift+k'],
                               function () {
                                 self.selectionManager.shiftSelectNextItem('up');
                                 return false;
                               });
      Shortcutter.bindInModule(this.moduleName,
                               ['mod+a'],
                               self._handleSelectAllBinding);
    }

    if (this.moduleName === 'agents') {
      Shortcutter.bindInModule(this.moduleName,
                               ['mod+up', 'mod+k'],
                               self._handleNewerDayBinding);
      Shortcutter.bindInModule(this.moduleName,
                               ['mod+down', 'mod+j'],
                               self._handleOlderDayBinding);
    }
  },
  _handleSelectAllBinding: function () {
    this.selectionManager.setSelection(this.shownListItems.models);

    return false;
  },
  _handleOlderDayBinding: function () {
    var selectedItem = this.selectionManager.currentItem();

    this._findOlderDayWithResultsAndSelectFirstResult(selectedItem);

    return false;
  },
  _findOlderDayWithResultsAndSelectFirstResult: function (selectedItem) {
    if (this.showTimeline2 === false) {
      var currentDate = moment(selectedItem.get('created_at'));
      var scrollingDonePromise = when.defer();
      var olderDay =
            this._findOlderDayWithResults(selectedItem,
                                          currentDate,
                                          currentDate,
                                          1,
                                          scrollingDonePromise.promise);
      var self = this;
      olderDay.done(function (day) {
        if (!_.isUndefined(day)) {
          var item = self._firstResultForDay(day);
          if (!_.isUndefined(item) && !_.isNull(item)) {
            self.selectionManager.setSelection([item]);
            self._scrollItemIntoViewTop(self.listControllers[day.cid], item);
          }
        }
        scrollingDonePromise.resolve();
      });
    }
  },
  _handleNewerDayBinding: function () {
    if (this.showTimeline2 === false) {
      var selectedItem = this.selectionManager.currentItem();
      var currentDate = moment(selectedItem.get('created_at'));
      var day = this.getDayContaining(currentDate.toDate());
      var today = moment();

      if (!this._isFirstResultForDay(day, selectedItem) || currentDate.isSame(today, 'day')) {
        this.selectionManager.setSelection([this._firstResultForDay(day)]);
      } else {
        this._findNewerDayWithResultsAndSelectFirstResult(selectedItem);
      }
    }
    return false;
  },
  _findNewerDayWithResultsAndSelectFirstResult: function (selectedItem) {
    if (this.showTimeline2 === false) {
      var currentDate = moment(selectedItem.get('created_at'));
      var newerDay =
            this._findNewerDayWithResults(selectedItem, currentDate);
      var self = this;
      newerDay.done(function (day) {
        if (!_.isUndefined(day)) {
          var item = self._firstResultForDay(day);
          self.selectionManager.setSelection([item]);
          self._scrollItemIntoView(self.listControllers[day.cid], item);
        }
      });
    }
  },
  _findOlderDayWithResults: function (selectedItem, startDate,
                                      currentDate,
                                      iteration,
                                      scrollingDonePromise) {
    if (this.showTimeline2 === false) {
      if (iteration >= this.spillOverLimit) {
        return when(this.getDayContaining(startDate.toDate()));
      }
      var day = this.getDayContaining(currentDate.toDate());
      // don't autoload new results if we're moving down
      var listController = this.listControllers[day.cid];
      listController.disableAutoAppend();
      scrollingDonePromise.done(function () {
        listController.enableAutoAppend();
      });

      var olderDate = moment(currentDate).subtract(1, 'day');
      var oldDay;
      try {
        oldDay = this.getDayContaining(olderDate.toDate());
      } catch (err) {
        return when(day);
      }
      var self = this;

      return this
        ._expandDay(oldDay).then(function () {
          var first = self._firstResultForDay(oldDay);
          var isPresent = !_.isUndefined(first) && !_.isNull(first);
          if (isPresent) {
            return oldDay;
          } else {
            return self._findOlderDayWithResults(selectedItem,
                                                 startDate,
                                                 olderDate,
                                                 iteration+1,
                                                 scrollingDonePromise);
          }
        });
    }
  },
  _findNewerDayWithResults: function (selectedItem, currentDate) {
    if (this.showTimeline2 === false) {
      var today = moment();
      var newerDate = moment(currentDate).add(1, 'day');
      var newDay = this.getDayContaining(newerDate.toDate());
      var self = this;

      return this._expandDay(newDay).then(function () {
        var first = self._firstResultForDay(newDay);
        var isPresent = !_.isUndefined(first) && !_.isNull(first);
        if (newerDate.isSame(today, 'days') || isPresent) {
          return newDay;
        } else {
          return self._findNewerDayWithResults(selectedItem,
                                               newerDate);
        }
      });
    }
  },
  _scrollIntoViewTop: function (distances) {
    this.view.scrollTimeline(this._getScrollOffsetTop(distances));
  },
  _scrollItemIntoViewTop: function (listController, item) {
    var distances = listController.getDistances(item);
    this.view.scrollTimeline(this._getScrollOffsetTop(distances));
  },
  _scrollItemIntoView: function (listController, item) {
    var distances = listController.getDistances(item);
    this.view.scrollTimeline(this._getScrollOffset(distances));
  },
  _firstResultForDay: function (day) {
    if (!_.isUndefined(this.listControllers[day.cid])) {
      return this.listControllers[day.cid].agentResults.first();
    } else {
      return null;
    }
  },
  _lastResultForDay: function (day) {
    if (!_.isUndefined(this.listControllers[day.cid])) {
      return this.listControllers[day.cid].agentResults.last();
    } else {
      return null;
    }
  },
  _isFirstResultForDay: function (day, result) {
    if (!_.isUndefined(day) && !_.isUndefined(result)) {
      return this._firstResultForDay(day) === result;
    } else {
      return false;
    }
  },
  _unbindShortcuts: function () {
    Shortcutter.unbindInModule(this.moduleName, [
      'esc', 'down', 'j', 'up', 'k',
      'shift+down', 'shift+j', 'shift+up', 'shift+k',
      'mod+up', 'mod+k', 'mod+down', 'mod+j', 'mod+a'
    ]);
  },
  _getScrollOffsetTop: function (distances) {
    var timelineTopOffset = this.view.getTopOffset();
    var top = distances.top - timelineTopOffset;
    if (top < 0) {
      return top + 666;
    }
    if (distances.bottom < 0) {
      return Math.min(top, -distances.bottom + 666);
    }
    return 0;
  },
  _getScrollOffset: function (distances) {
    var timelineTopOffset = this.view.getTopOffset();
    var top = distances.top - timelineTopOffset;
    if (top < 0) {
      return top;
    }
    if (distances.bottom < 0) {
      return Math.min(top, -distances.bottom);
    }
    return 0;
  },
  _maybeScroll: function (listController, listItem) {
    var itemToShow = this.selectionManager.itemThatShouldBeVisible();
    if (itemToShow === listItem) {
      var distances = listController.getDistances(itemToShow);
      this.view.scrollTimeline(this._getScrollOffset(distances));
    }
  },
  _startChangingContent: function (element) {
    if(!this._loadingNewestResults) {
      this.view.startChangingContent(element);
    }
  },
  _stopChangingContent: function (element) {
    if(!this._loadingNewestResults) {
      this.view.stopChangingContent(element);
    }
  },
  _findAgentResultById: function (agentResultId) {
    return this.shownListItems.get(agentResultId);
  },
});
export default TimelineController;
