import OtherUserSettingsView from 'views/settings/otherUserSettingsView';
import UserSettingsController from
    'controllers/settings/userSettingsController';
import Notificator from 'libraries/notificator';
import I18n from 'i18n';
import session from 'models/sessionInstance';

class OtherUserSettingsController extends UserSettingsController {
    initialize(options) {
        this.user = options.user;
        this.view = new OtherUserSettingsView({
            model: this.user
        });
        this._registerListeners();
        this._registerSaveUserListener();
        this._registerDeleteUserListener();
        this._registerLoginAsUserListener();
    }

    _registerDeleteUserListener() {
        let self = this;
        this.listenTo(this.view, 'delete:user', () => {
            this.user.destroy({
                wait: true,
                error: function () {
                    Notificator.showNotification(
                        I18n.t("webapp.notifications.warning.update_failed")
                    );
                },
                success: function () {
                    self.trigger("user:deleted", self.user);
                }
            });
        });
    }

    _registerLoginAsUserListener() {
      const user = this.user;
      if (session.canLoginAs() && user.canBeLoggedInAs()) {
          this.listenTo(this.view, 'login-as:user', (login) => {
            window.location.href = `/switch_user?login=${login}`;
          });
      }
    }
    _updateLocaleReloadWarning() {}
}

export default OtherUserSettingsController;
