import ClipApiCollection from 'collections/researchClipApiCollection'
import LivePreviewListView from 'views/agents/livePreviewListView'
import ResearchResultListController from 'controllers/research/researchResultListController'
import AutomaticAppendTracker from 'models/agents/automaticAppendTracker'
import moment from 'moment'

var LivePreviewListController = ResearchResultListController.extend({
  initialize: function (options) {
    this.listItemViews = {};
    this.clips = new ClipApiCollection([], {
      //search options
      topicBuildParameters: options.topicBuildParameters,
      sourceCategoryIds: options.sourceCategoryIds,
      sourceIdsToExclude: options.sourceIdsToExclude,
      sourceGroupIds: options.sourceGroupIds,
      since: moment().subtract('month', 1).unix(),
      before: moment().unix()
    });

    this.view = new LivePreviewListView({
      results: this.clips,
      appendTracker: options.appendTracker
    });

    this.appendTracker = new AutomaticAppendTracker();

    this._registerListeners();

    this.appendItems();
  }
});

export default LivePreviewListController;
