import Backbone from 'backbone';
import ReaderNotificationTrigger from 'models/newsroom/readerNotificationTrigger';

class ReaderNotificationTriggers extends Backbone.Collection {
    initialize(models, options) {
        this.notificationConfiguration = options.notificationConfiguration;
    }

    url() {
        const notificationConfigId = this.notificationConfiguration.get('id');
        return `api/v3/notification_configurations/${notificationConfigId}` +
                '/notification_triggers';
    }
}

ReaderNotificationTriggers.prototype.model = ReaderNotificationTrigger;

export default ReaderNotificationTriggers;
