import * as React from "react";
import I18n from "i18n";
import {IButton, RadioButtonGroup} from "components/radioButtonGroup";
import Tooltip from "components/tooltip";
import * as _ from "underscore";

interface IPublicationTimeOptionProps {
  buttons: IButton[];
  selectedOption: string;
  onRadioButtonClick: (value: string) => void;
}

class PublicationTimelineOrder extends React.Component<IPublicationTimeOptionProps> {
  render() {
    const heading = I18n.t("webapp.reader_admin.edit.issues");
    const tooltipHeading = I18n.t(
      "webapp.reader_admin.edit.issue_pattern_publication_time.subheading"
    );
    const tooltipHeadline = I18n.t(
      "webapp.reader_admin.edit.issue_pattern_publication_time.tooltip.title"
    );
    const tooltipBodyRealtime = I18n.t(
      "webapp.reader_admin.edit.issue_pattern_publication_time.tooltip.realtime_text"
    );
    const tooltipBodyEditorial = I18n.t(
      "webapp.reader_admin.edit.issue_pattern_publication_time.tooltip.editorial_deadline_text"
    );
    const tooltipBodyHtml = `${tooltipBodyRealtime}<br />${tooltipBodyEditorial}`;
    const tooltipProps = {
      heading: tooltipHeading,
      tooltipHeadline: tooltipHeadline,
      tooltipBody: <span dangerouslySetInnerHTML={{ __html: tooltipBodyHtml}}/>
    };
    const buttonGroupProps = _.extend({}, this.props, { disabled: false });

    return (
      <div>
        <h2 className="heading-xlarge mtl mbs">{heading}</h2>
        <Tooltip {...tooltipProps} />
        <RadioButtonGroup {...buttonGroupProps} />
      </div>
    );
  }
}

export default PublicationTimelineOrder;
