import * as React from "react";
import I18n from "i18n";
import {IButton, RadioButtonGroup} from "components/radioButtonGroup";
import Tooltip from "components/tooltip";
import * as _ from "underscore";

interface IPublicationTimelineOrderProps {
  buttons: IButton[];
  selectedOption: string;
  onRadioButtonClick: (value: string) => void;
}

class PublicationTimelineOrder extends React.Component<IPublicationTimelineOrderProps> {
  render() {
    const heading = I18n.t(
      "webapp.reader_admin.edit.issue_settings.add_new_results_to_issue.headline"
    );
    const tooltipHeadline = I18n.t(
      "webapp.reader_admin.edit.issue_settings.add_new_results_to_issue.tooltip_headline"
    );
    const tooltipBody = I18n.t(
      "webapp.reader_admin.edit.issue_settings.add_new_results_to_issue.tooltip_body"
    );
    const tooltipProps = {
      heading: heading,
      tooltipHeadline: tooltipHeadline,
      tooltipBody: tooltipBody
    };
    const buttonGroupProps = _.extend({}, this.props, { disabled: false });

    return (
      <div>
        <Tooltip {...tooltipProps} />
        <RadioButtonGroup {...buttonGroupProps} />
      </div>
    );
  }
}

export default PublicationTimelineOrder;
