import HeaderActionsController from 'controllers/agents/agentResultHeaderActionsController';
import ReaderResultHeaderActionsView from 'views/newsroom/readerResultHeaderActionsView';
import UserReaderResultPresenter from 'models/presenters/userReaderResultPresenter';
import appState from 'models/appStateInstance';
import Shortcutter from 'libraries/shortcutter';

class ReaderResultHeaderActionsController extends HeaderActionsController {
    constructor(options) {
        super(options);
        this.readerResult = options.readerResult;
    }

    _initializeView() {
        this.presenter = new UserReaderResultPresenter(this.agentResult);
        this.view = new ReaderResultHeaderActionsView({
            model: this.agentResult,
            presenter: this.presenter
        });
    }

    serializeData() {
        var data = HeaderActionsController.prototype.serializeData.call(this);
        data.isTranslatable = false;
        data.isEmm = false;
        data.isOwnedAgentResult = true;
        return data;
    }

    _shortcutModuleName() {
        return 'newsroom';
    }

    _bindShortcuts() {
        let self = this;
        Shortcutter.bindInModule(this._shortcutModuleName(), 'l', function () {
          if (
            self.agentResult.isOrderable() &&
            self.agentResult.isOrderableOnlineClip()
          ) {
            self._displayOnlineClipOrderModal();
          }
        });
    }
    _unbindShortcuts() {
        Shortcutter.unbindInModule(this._shortcutModuleName(), 'l');
    }

    _registerListeners() {
        this.listenTo(this.view, 'clicked:download', () => {
            this._downloadPdf();
        });

        this.listenTo(this.view, 'click:deleteReaderResult', () => {
            this._handleDeleteResult({deleteTags: false});
        });

        this.listenTo(this.view, 'click:deleteReaderResultAndTags', () => {
            this._handleDeleteResult({deleteTags: true});
        });

        this.listenTo(this.view, 'click:editAgentResult', () => {
            this.trigger('edit-form:start');
        });

        this.listenTo(this.view, 'click:rtvOrder', () => {
            this._verifyUsageLimit();
        });

        this.listenTo(this.view, 'click:onlineOrder', () => {
            this._displayOnlineClipOrderModal();
        });

        this.listenTo(this.view, 'click:translate', () => {
            this._openTranslateLink();
        });

        this.listenTo(this.view, 'click:cut', () => {
            this._openCuttingLink();
        });

        this.listenTo(this.view, 'click:editClip', () => {
            this.trigger('clip-edit-form:start');
        });
 
        this.listenTo(this.view, 'modal:rtvOrderConfirm', this._recordingOrder);
        this.listenTo(this.view, 'modal:onlineClipOrderConfirm', this._orderOnlineClip);
    }

    _handleDeleteResult(params) {
        if (!appState.isBusy()) {
            appState.requestStart();

            var onComplete = function() {
                appState.requestCompleted();
            };

            var opts = {
                success: onComplete,
                error: onComplete
            };

            if (params.deleteTags === true) {
                this.readerResult.destroyResultAndTags(opts);
            } else {
                this.readerResult.destroy(opts);
            }
        } else {
            appState.displayWarning();
        }
    }
}

export default ReaderResultHeaderActionsController;
