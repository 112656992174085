import Marionette from 'backbone.marionette'
import ContactGroupView from 'views/settings/contacts/contactGroupListItemView'

var ContactGroupListCollectionView = Marionette.CollectionView.extend({
  itemView: ContactGroupView,
  appendHtml: function (collectionView, itemView, index) {
    var child = collectionView.$el.children().eq(index);
    if (child.length === 1) {
      collectionView.$(child).before(itemView.el);
    } else {
      collectionView.$el.append(itemView.el);
    }
  }
});

export default ContactGroupListCollectionView;

