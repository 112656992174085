import _ from 'underscore';
import RestorableModel from 'libraries/restorableModel';
import Agents from 'collections/agents';
import session from 'models/sessionInstance';

class User extends RestorableModel {
    initialize() {
        this.agents = new Agents();
    }

    urlRoot() {
        return '/api/v3/users';
    }

    hasAgents() {
        return !this.agents.isEmpty();
    }

    canBeLoggedInAs() {
        const notLoggedInAs = this.get('id') !== session.userId();
        return notLoggedInAs && this.get('log_in_as_enabled');
    }

    validate(user) {
        if (!_.isUndefined(user.first_name) && user.first_name.length < 1) {
            return "Error";
        }

        if (!_.isUndefined(user.last_name) && user.last_name.length < 1) {
            return "Error";
        }

        if (!_.isUndefined(user.email) && user.email.length < 1) {
            return "Error";
        }

        if (user.password) {
            if (!user.password_confirmation) {
                return 'Error';
            } else {
                if (user.password !== user.password_confirmation) {
                    return 'Error';
                }
            }
        }
    }

    parse(response) {
        let data = response.object ? response.object : response;
        return data;
    }

    toJSON() {
        return { user: this.attributes };
    }
}

export default User;
