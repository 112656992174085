import when from 'when';
import AgentResultApiCollection from 'collections/agentResultApiCollection';
import AgentResultListView from 'views/agents/agentResultListView';
import TimelineCollectionListController from 'controllers/timelineCollectionListController';

class AgentResultListController extends TimelineCollectionListController {
    initialize(options) {
        TimelineCollectionListController.prototype.initialize.apply(this, [options]);
    }

    _initializeApiCollection(options) {
        this.agentResults = new AgentResultApiCollection([], {
            agentIds: options.agentIds,
            filter: options.filter,
            createdAtDate: options.createdAtDate
        });
    }

    _initializeListView() {
        this.view = new AgentResultListView({
            results: this.agentResults
        });
    }

    _initializeListItemViews() {
        this.agentResultListItemViews = {};
    }

    loadListCollection(options) {
        var agentResultListController = this;

        if (options.results) {
            this._startChangingContent(this.view);
            this.agentResults.add(options.results);
            this.agentResults.moreObjectsAvailable = !options.noMoreResults;
            this._appendItemsFinished();
            when().then(this._initialResultsShown);
            this._stopChangingContent(this.view);
        } else {
            var self = this;
            this.agentResults.fetch({success: function () {
                self._startChangingContent(self.view);
                agentResultListController._appendItemsFinished();
                agentResultListController._initialResultsShown();
                self._stopChangingContent(self.view);
            }});
        }
    }

    shownItems() {
        return this.agentResults.slice(0, this.agentResults.length);
    }

    handleNoItemsToDisplay() {
        if (this.agentResults.isEmpty()) {
            this.view.showNoResultsMessage();
        }
    }

    collectionItemViews() {
        return this.agentResultListItemViews;
    }

    appendCollection() {
        return this.agentResults.appendResults();
    }

    collectionSize() {
        return this.agentResults.size();
    }

    allItemsLoaded() {
        return this.agentResults.allItemsLoaded();
    }

    _registerItemView(itemView) {
        var model = itemView.model;
        if (this.agentResultListItemViews[model.id] ===
            itemView) { return; }
        this.agentResultListItemViews[model.id] = itemView;
        this.listenTo(itemView, 'item:before:close', () => {
            this._startChangingContent(itemView);
        });

        this.listenTo(itemView, 'item:closed', () => {
            this._stopChangingContent();
        });
    }
}

export default AgentResultListController;
