import { EntityType } from 'models/entity'

export interface IPrefixSearchResponse {
  total_entries: number
  collection: IPrefixSearchResult[]
}

export interface IPrefixSearchResult {
  doc_type: string
  // TODO: separate types & nicer handling for those cases
  entity?: IPrefixSearchEntity
  topic?: IPrefixSearchEntity
  parent_topic?: IPrefixSearchEntity
}

// TODO: remove this, use models/esResult
export interface IPrefixSearchEntity {
  name_en: string;
  name_fr: string;
  name_de: string;
  name_it: string;
  entity_type: string;
  id: number;
  meta: { kb_id: string }
}

export type IPrefixSearchEntityType =
  'topic'                      |
  'person_entity'              |
  'business_entity'            |
  'location_entity'            |
  'organization_entity'        |
  'law_entity'                 |
  // for searching both at the same time, in webapp persons and orgs are
  // lumped together in the UI
  'person_organization_entity'

// handles naming difference between the prefix api and the entity
// types we store in ES
export function entityTypeToPrefixSearchEntityType(
  entityType: EntityType
): IPrefixSearchEntityType {
  return entityType + '_entity' as IPrefixSearchEntityType
}
