import Deferred from 'libraries/deferred'

var deferred = Deferred.build();

var promise = deferred.promise();

export default {
  done: promise.done,
  resolve: deferred.resolve
};

