import ReaderResults from 'collections/newsroom/readerResults';

class ReaderResultApiCollection extends ReaderResults {
    initialize(collection, options) {
        this.options = options;
        this.readerConfigurationId = options.readerConfigurationId;
        this.readerCategoryId = options.readerCategoryId;
        this.readerArchiveDateId = options.readerArchiveDateId;
        this.limit = options.limit || 25;
    }

    url() {
        return '/api/v3/reader_configurations/' +
                this.readerConfigurationId +
               '/archive_dates/' +
                this.readerArchiveDateId +
               '/categories/' +
                this.readerCategoryId +
               '/reader_results?limit=' +
                this.limit;
    }

    prependResults(options) {
        return this._loadResults(options);
    }

    appendResults(options) {
        return this._loadResults(options);
    }

    parse(response) {
        return response.collection;
    }

    allItemsLoaded() {
        return true;
    }

    _loadResults(options) {
        let deferred = this.fetch(options);
        let self = this;
        deferred.done(function () { self.trigger('results:loaded'); });
        return deferred;
    }
}

export default ReaderResultApiCollection;
