import _ from 'underscore';

const ratings = [null, -10, 0, 10];
const mediaTypes = ['online', 'social_media', 'print', 'radio_tv'];
const resultImportance = ['important', 'normal'];

function compare(arr1, arr2) {
    return _.isEqual(arr1.sort(), arr2.sort());
}

export default class TimelineFilter {
    constructor(filter) {
        this.filter = filter
        this._under = _;
    }

    isFiltered() {
        return !compare(this.filter.ratings, ratings) ||
            !compare(this.filter.mediaTypes, mediaTypes) ||
            !compare(this.filter.resultImportance, resultImportance) ||
            this.filter.query ||
            !_.isEmpty(this.filter.tagIds) ||
            !_.isEmpty(this.filter.sourceIds);
    }
}
