import Marionette from "backbone.marionette";
import SourceCategorySelectionView from "views/agents/settings/sourceCategorySelectionView";
import SourceCategorySelectionFormController from "controllers/agents/sourceCategorySelectionFormController";
import SourceExclusionsController from "controllers/sourceExclusionsController";
import FavoriteSourceGroupsController from 'controllers/agents/favoriteSourceGroupsController';
import when from 'when';
import SourceGroupsInstancePromise from 'models/sourceGroupsInstancePromise'
import SourceCategoriesInstancePromise from "models/sourceCategoriesInstancePromise";

class SourceCategorySelectionController extends Marionette.Controller {
  initialize(options) {
    this.sourceCategoryIds = options.sourceCategoryIds;
    this.sourceExclusions = options.sourceExclusions;
    this.agent = options.agent;
    this.excludedCategoryIdentifiers = this.options.excludedCategoryIdentifiers;
    this.view = new SourceCategorySelectionView();
    SourceCategoriesInstancePromise.fetch();
    SourceGroupsInstancePromise.fetch();
    this._initializeSourceCategoryForm();
    this._initializeSourceExclusions();
    this._initializeSourceGroup();
    this._renderViews();
  }

  _initializeSourceCategoryForm() {
    this.sourceCategorySelectionFormController =
      new SourceCategorySelectionFormController({
        sourceCategoryIds: this.sourceCategoryIds,
        excludedCategoryIdentifiers: this.excludedCategoryIdentifiers
      });
    this.listenTo(this.sourceCategorySelectionFormController,
                  'changed:sourceCategories', (categoryIds) => {
                    this.trigger('changed:sourceCategories', categoryIds);
      }
    );
  }

  _initializeSourceGroup() {
    this.sourceGroupController = new FavoriteSourceGroupsController({
      agent: this.agent,
    });
  }

  _initializeSourceExclusions() {
    this.sourceExclusionsController =
      new SourceExclusionsController({
        sourceExclusions: this.sourceExclusions
      });
  }

  _renderViews() {
    this.listenTo(this.view, 'render', () => {
      when.all([SourceCategoriesInstancePromise, SourceGroupsInstancePromise]).done(([_, sourceGroupCollection]) => {
        let sourceGrpView = sourceGroupCollection.length !== 0 && this.sourceGroupController.view
        this.view.showViews(this.sourceCategorySelectionFormController.view, this.sourceExclusionsController.view, sourceGrpView)
      });
    });
  }
}

export default SourceCategorySelectionController;
