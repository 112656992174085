import { createStore, combineReducers, applyMiddleware } from "redux";
import user, { User } from "./user";
import dashboards, { DashboardStore } from "./dashboards";
import promiseMiddleware from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import selectedDateRange from "./selectedDateRange";
import widgets, { WidgetStore } from "./widgets";
import selectedDashboard from "./selectedDashboard";
import searchAgents, { UserAgentItem } from "./searchAgents";
import showDashboard from "./showDashboard";
export interface Store{
  user: User;
  dashboards: DashboardStore;
  widgets: WidgetStore;
  selectedDashboard: number;
  searchAgents: UserAgentItem[];
  selectedDateRange: number;
  showDashboard: boolean;
} 


export default createStore(
  combineReducers({ user, dashboards, selectedDateRange, widgets, selectedDashboard, searchAgents, showDashboard  }),
  composeWithDevTools(applyMiddleware(promiseMiddleware()))
);
