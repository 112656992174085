import * as _ from 'underscore';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useState } from 'react';

interface Props {
  languages: string[];
  summary: any;
  handleLanguageChange(language: string): void;
}

export default function MultiLanguageTabs({
  languages,
  summary,
  handleLanguageChange,
}: Props) {
  const [selectedLang, setSelectedLang] = useState<string>(summary.getDefaultLanguage());
  return (
    <div className="tabs summaryTabs is-active">
      <div className="tabs__row row">
        {languages.map((i) => {
          const title = summary.getTitle(i);
          return (
            <div
              key={i}
              className={`
              ${
                title === '' || title === undefined || title === null
                  ? 'tabs__item empty col1of6'
                  : 'tabs__item filled col1of6'
              } ${
                selectedLang &&
                selectedLang.toLowerCase() === i.toLowerCase() &&
                'active'
              }`}>
              <a
                className="summaryTabs__link"
                onClick={(e) => {
                  if (e.currentTarget.textContent !== null) {
                    setSelectedLang(e.currentTarget.textContent);
                    handleLanguageChange(e.currentTarget.textContent);
                  }
                }}>
                {i}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
