import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/newsroom/readerTokenUrlView.html';
import _ from 'underscore';

class ReaderTokenUrlView extends Marionette.ItemView {
    initialize(options) {
        this.model = options.model;
        this._registerListeners();
    }

    serializeData() {
        let newestReaderLink = this.model.get('newest_reader_link');
        return { newestReaderLink: newestReaderLink };
    }

    _registerListeners() {
        this.listenTo(this.model, 'change:newest_reader_link', this.render);
    }

    _selectTokenUrl() {
        this.ui.readerTokenUrl.select();
    }

    _getNewestReaderLink() {
        let url = this.model.get('newest_reader_link');
        let link = _.isNull(url) ? false : url;
        return link;
    }
}

ReaderTokenUrlView.prototype.template = template;
ReaderTokenUrlView.prototype.ui = {
    readerTokenUrl: '[data-reader-token-url]'
};
ReaderTokenUrlView.prototype.events = {
    'click @ui.readerTokenUrl': '_selectTokenUrl'
};

export default ReaderTokenUrlView;
