import App from 'app'
import AgentsInstancePromise from 'models/agentsInstancePromise'
import UsersInstancePromise from 'models/usersInstancePromise'
import TagsInstancePromise from 'models/tagsInstancePromise'
import users from 'collections/usersInstance'

App.module('CommonInitializer', {
  startWithParent: true,
  define: function () {
    this.addInitializer(function () {
      UsersInstancePromise.fetch();
      UsersInstancePromise.done(function () {
        users.setCurrentUser();
        AgentsInstancePromise.fetch();
        TagsInstancePromise.fetch();
      });
    });
  }
});

export default App.CommonInitializer;

