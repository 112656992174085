/* eslint no-invalid-this: "off" */
import Marionette from "backbone.marionette";
import LoginModel from "models/login";
import Deferred from "libraries/deferred";
import _ from "underscore";
import params from "libraries/queryString";

var Login = new Marionette.Application();

Login._deferred = new Deferred();
Login.whenLoggedIn = function (callback) {
  Login._deferred.done(callback);
};
Login._loginSuccessful = function () {
  Login._deferred.resolve();
};

Login.addInitializer(function () {
  if (!_.isUndefined(params.reset_password_token)) {
      window.location.href =
          `/new_password_form?reset_password_token=${params.reset_password_token}`;
  } else {
      var loginModel = new LoginModel();
      loginModel.fetch().done(function () {
        if (loginModel.isLoggedIn()) {
          Login._setHashFromStorage();
          Login._setSearchFromStorage();
          Login._loginSuccessful();
        } else {
          window.sessionStorage.remember_hash = window.location.hash;
          window.sessionStorage.remember_search = window.location.search;
          window.location.href = '/login';
        }
      });
  }
});

Login._setHashFromStorage = function () {
  var remember_hash = window.sessionStorage.remember_hash;
  if (window.location.hash === "") {
    if (remember_hash !== undefined && remember_hash !== "") {
      window.location.hash = remember_hash;
      window.sessionStorage.removeItem('remember_hash');
    }
  }
}

Login._setSearchFromStorage = function () {
  var remember_search = window.sessionStorage.remember_search;
  if (window.location.search === "") {
    if (remember_search !== undefined && remember_search !== "") {
      window.location.search = remember_search;
      window.sessionStorage.removeItem('remember_search');
    }
  }
}

export default Login;
