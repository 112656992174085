import * as React from "react";
import DateRange from "./components/DateRange";
import { Provider } from "react-redux";
import store from "./redux/store";

export default function Toolbar() {
  return (
    <Provider store={store}>
      <DateRange />
    </Provider>
  );
}
