/* eslint no-invalid-this: "off" */
import App from 'app'
import vent from 'libraries/vent'
import $ from 'jquery'
import * as React from 'react';
import * as ReactDom from "react-dom"
import DashboardApp from "../components/dashboard/DashboardApp";
import Toolbar from '../components/dashboard/Toolbar'
import LayoutContentResizer from 'modules/layoutContentResizer'
import { update as updateShowDashBoard } from "../components/dashboard/redux/showDashboard";
import store from "../components/dashboard/redux/store"

App.module("NewDashboard", {
  startWithParent: false,
  define: function() {
    App.NewDashboard.name = 'Dashboard'
    App.NewDashboard.show = function () {
      $('#container-new-dashboard').show();
      store.dispatch(updateShowDashBoard(true));
    }
    App.NewDashboard.hide = function () {
      $('#container-new-dashboard').hide();
    }

    App.NewDashboard.on('loaded', function () {
      vent.commands.execute('navigation:loaded', 'dashboard');
      
    })

    App.NewDashboard.addInitializer(function () {
      App.NewDashboard.registerInNavigation();
      $('#container-content').append('<div id="container-new-dashboard" class="content"></div>');
      LayoutContentResizer.resizeContent();
      $('#container-new-dashboard').hide();
      // eslint-disable-next-line react/react-in-jsx-scope
      ReactDom.render(<DashboardApp />, document.getElementById("container-new-dashboard"));

      // called when the module can be shown:
      App.NewDashboard.trigger('loaded')
    })

    App.NewDashboard.addFinalizer(function () {
      this.hide();
      this.deregisterInNavigation();
      ReactDom.unmountComponentAtNode(document.getElementById("container-new-dashboard"))
    })

    App.NewDashboard.registerInNavigation = function () {
      this.createToolbar();
      vent.commands.execute('navigation:register',
                            App.NewDashboard,
                            'dashboard',
                            { toolbar: this.toolbar });
      // The toolbar component has to be rendered after the toolbar
      //element has been added to the DOM by the navigation:register
      // call
      this.toolbar.render();
      vent.commands.execute('navigation:loading', 'dashboard');
      vent.commands.setHandler('navigation:show:dashboard', function () {
        App.NewDashboard.show();
      });
      vent.commands.setHandler('navigation:hide:dashboard', function () {
        App.NewDashboard.hide();
      });
    };

    App.NewDashboard.createToolbar = function () {
      // create an object that looks like a Backbone.View to the
      // navigation module (i.e. has a `el` property)
      const el = document.createElement("div")
      this.toolbar = {
        el,
        render: () => {
          ReactDom.render(<Toolbar />, el)
        }
      }
    }
  }
});

export default App.NewDashboard
