import _ from 'underscore'
import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/newsroom/notificationSentModal.html'
import 'bpopup'

var NotificationSentModalView = Marionette.ItemView.extend({
  initialize: function (opt) {
    this.title = opt.title;
    this.body = opt.body;
    this.closeLabel = opt.closeLabel;
    _.bindAll(this, 'close', 'onClose', 'showModal',
              'initializeModal');
  },
  template: template,
  onRender: function () {
    this.initializeModal();
  },
  serializeData: function () {
    return {
      title: this.title,
      body: this.body,
      closeLabel: this.closeLabel,
    };
  },
  ui: {
    modalElement: '[data-notification-sent-modal]',
    modalCloseButton: '[data-notification-sent-modal-close]'
  },
  handleCloseBtn(modal) {
    modal.notificationSentModal.close();
    modal.close();
    modal.trigger('modal:closed');
  },
  initializeModal: function () {
    var self = this;
    this.ui.modalCloseButton.on('click', function () {
      self.handleCloseBtn(self);
    });
    this.ui.modalCloseButton.on('keydown', function (e) {
      if (e.key === "Enter") {
        self.handleCloseBtn(self);
      }
    });
  },
  onClose: function () {
    this.ui.modalElement.remove();
  },
  showModal: function () {
    this.notificationSentModal = this.ui.modalElement.bPopup();
    this.ui.modalCloseButton.focus();
  }
});

export default NotificationSentModalView;

