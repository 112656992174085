import _ from 'underscore'
import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/newsroom/sendNotificationModal.html'
import 'bpopup'
import Dropdown from 'components/dropdown'
import I18n from 'i18n'
import ReactWrapper from 'views/marionetteReactWrapper'
import * as React from 'react'
import configurations from 'collections/newsroom/configurationsInstance'
import handleTooltips from 'models/handleTooltips'

var SendNotificationModalView = Marionette.Layout.extend({
  initialize: function (opt) {
    handleTooltips(this);
    this.title = opt.title;
    this.body = opt.body;
    this.cancelLabel = opt.cancelLabel;
    this.confirmLabel = opt.confirmLabel;
    this.readerConfigurationId = opt.readerConfigurationId;
    _.bindAll(this, 'close', 'onClose', 'showModal');
  },
  template: template,
  onRender: function () {
    this.initializeModal();
  },
  serializeData: function () {
    return {
      title: this.title,
      body: this.body,
      confirmLabel: this.confirmLabel,
      cancelLabel: this.cancelLabel,
      multipleConfigs: this._multipleConfigs(),
      configName: this._dropdownOptions()[0].text
    };
  },
  ui: {
    modalElement: '[data-modal]',
    modalConfirmButton: '[data-confirm]',
    modalCancelButton: '[data-confirm]'
  },
  modalConfirm(modal) {
    modal.trigger('modal:confirm', modal.selectedId);
    modal.modal.close();
    modal.close();
  },
  regions: {
    templateDropdown: '[data-template-dropdown]'
  },
  initializeModal: function () {
    var self = this;
    this.ui.modalConfirmButton.on('click', function () {
      if (!self.ui.modalConfirmButton.hasClass('is-disabled')) {
        self.modalConfirm(self);
      }
    });
    this.ui.modalConfirmButton.on('keydown', function (e) {
      if (e.key === "Enter") {
        self.modalConfirm(self);
      }
    });
  },
  _maybeDisableButtons: function() {
    if (this._multipleConfigs()) {
      this.ui.modalConfirmButton.addClass('is-disabled');
    } else {
      this.selectedId = this._dropdownOptions()[0].value;
    }
  },
  _multipleConfigs: function() {
    return this._dropdownOptions().length > 1;
  },
  disableButtons: function () {
    this.ui.modalConfirmButton.addClass('is-disabled');
    this.ui.modalCancelButton.addClass('is-disabled');
  },
  onClose: function () {
    this.ui.modalElement.remove();
  },
  showModal: function () {
    const self = this;
    const opts = {
      onOpen: function () {
        self.selectedId = undefined;
        self._maybeDisableButtons();
        if (self._multipleConfigs()) {
          self._showTemplateDropdown();
        }
      },
      onClose: function () {
        self.selectedId = undefined;
      }
    }
    this.modal = this.ui.modalElement.bPopup(opts);
    this.ui.modalConfirmButton.focus();
  },
  _showTemplateDropdown() {
    const key =
      'webapp.reader_admin.notifications.modal_manual_sending.no_selection'
    const noneOption = {
      text: I18n.t(key),
      value: undefined
    }

    const options = this._dropdownOptions()
    options.unshift(noneOption);

    this.templateDropdownView = new ReactWrapper({
      getComponent: () => {
        return <
        Dropdown
          options={options}
          selectedOption={options[0].value}
          onOptionChange={(opt) => this._templateChange(opt)}
          small={true}
        />
      }
    })
    this.templateDropdown.show(this.templateDropdownView)
  },
  _dropdownOptions() {
    const config = configurations.findWhere({
      id: this.readerConfigurationId
    });

    return config.
      notification_configurations
      .map(function (nc) {
        return {
          text: nc.get('name'),
          value: nc.get('id')
        }
      })
  },
  _templateChange(newOpt) {
    this.selectedId = newOpt;
    if (this.selectedId !== undefined) {
      this.ui.modalConfirmButton.removeClass('is-disabled');
    } else {
      this.ui.modalConfirmButton.addClass('is-disabled');
    }
  }
});

export default SendNotificationModalView;

