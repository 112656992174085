import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'
import * as jQuery from 'jquery'
import I18n from 'i18n'
import DateFormatter from 'libraries/dateFormatter'

export interface DatepickerRangeDateRange {
  startDate: Date
  endDate: Date
}

interface IDatepickerRangeProps {
  startDate?: Date
  endDate?: Date
  onRangeChange: (range: DatepickerRangeDateRange) => void
}

interface DatepickerRangeState {
  startDate?: Date
  endDate?: Date
  startOpen: boolean
  endOpen: boolean
}

export class DatepickerRange extends React.Component<IDatepickerRangeProps, DatepickerRangeState> {
  constructor(props: IDatepickerRangeProps) {
    super(props)
    this.state = this.initialState(props)
  }

  private initialState(props: IDatepickerRangeProps) {
    return {
      startDate: props.startDate,
      endDate: props.endDate,
      startOpen: false,
      endOpen: false
    }
  }

  render() {
    return (
      <div>
        <div className="col1of2">
          <div className={this.openClosedClass('date-picker-dropdown advancedsearch--container-filter-datepicker--start', this.state.startOpen)}>
            <div className="date-picker-dropdown__trigger" onClick={() => this.onStartTriggerClicked()}>{this.startDateText()}</div>
            <div className="date-picker-dropdown__datepicker" data-date={this.startDate()}></div>
          </div>
        </div>
        <div className="col1of2">
          <div className={this.openClosedClass('date-picker-dropdown advancedsearch--container-filter-datepicker--end', this.state.endOpen)}>
            <div className="date-picker-dropdown__trigger" onClick={() => this.onEndTriggerClicked()}>{this.endDateText()}</div>
            <div className="date-picker-dropdown__datepicker" data-date={this.endDate()}></div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.initializeDatepicker()
    this.registerBodyHandler()
  }

  componentWillUnmount() {
    this.deregisterBodyHandler()
  }

  private bodyClickHandler?: (e: JQueryEventObject) => any

  private registerBodyHandler() {
    this.bodyClickHandler = (el: JQueryEventObject) => {
      if (!this.rootEl()[0].contains(el.target) && jQuery('body')[0].contains(el.target)) {
        if (this.state.startOpen || this.state.endOpen) {
          this.setState((prevState: DatepickerRangeState) => {
            return {
              startOpen: false,
              endOpen: false
            }
          })
        }
      }
    }
    jQuery('body').on('click', this.bodyClickHandler)
  }

  private deregisterBodyHandler() {
    if (this.bodyClickHandler) {
      jQuery('body').off('click', this.bodyClickHandler)
      this.bodyClickHandler = undefined
    }
  }

  private startDateText(): string {
    if (this.state.startDate) {
      return DateFormatter.formatDate(this.state.startDate)
    } else {
      return I18n.t('webapp.agents.edit.filters.datepicker_start_date')
    }
  }

  private endDateText(): string {
    if (this.state.endDate) {
      return DateFormatter.formatDate(this.state.endDate)
    } else {
      return I18n.t('webapp.agents.edit.filters.datepicker_end_date')
    }
  }

  private startDate(): string {
    if (this.state.startDate) {
      return formatDate(this.state.startDate)
    } else {
      return ''
    }
  }

  private endDate(): string {
    if (this.state.endDate) {
      return formatDate(this.state.endDate)
    } else {
      return ''
    }
  }

  private onStartTriggerClicked() {
    this.setState((prevState: DatepickerRangeState) => {
      return {
        startOpen: !prevState.startOpen
      }
    })
  }

  private onEndTriggerClicked() {
    this.setState((prevState: DatepickerRangeState) => {
      return {
        endOpen: !prevState.endOpen
      }
    })
  }

  private openClosedClass(classes: string, open: boolean): string {
    if (open) {
      return classes + ' is-open'
    } else {
      return classes + ' is-closed'
    }
  }

  private inputs() {
    return this.rootEl().find('.date-picker-dropdown__datepicker').toArray()
  }

  private initializeDatepicker() {
    let inputs = this.inputs()
    let opts = {
      format: 'yyyy-mm-dd',
      language: I18n.locale,
      weekStart: 1,
      orientation: 'auto',
      inputs: inputs,
      endDate: '0d'
    }
    let picker = (this.rootEl() as any).bootstrapDatepicker(opts)
    let [startPicker, endPicker] = this.inputs().map(jQuery)
    startPicker.on('changeDate', () => this.onChangeStartDate())
    endPicker.on('changeDate', () => this.onChangeEndDate())
  }

  private onChangeStartDate() {
    this.setState(
      (prevState: DatepickerRangeState) => {
        let picker = jQuery(this.inputs()[0])
        let startDate: Date = (picker as any).bootstrapDatepicker('getDate')
        if (isNaN(startDate.getTime())) {
          return {
            startDate: undefined,
            startOpen: prevState.startOpen
          }
        } else {
          return { startDate, startOpen: false }
        }
      },
      () => this.notifyOnChange()
    )
  }

  private onChangeEndDate() {
    this.setState(
      (prevState: DatepickerRangeState) => {
        let picker = jQuery(this.inputs()[1])
        let endDate: Date = (picker as any).bootstrapDatepicker('getDate')
        if (isNaN(endDate.getTime())) {
          return { endDate: undefined, endOpen: prevState.endOpen }
        } else {
          return { endDate, endOpen: false }
        }
      },
      () => this.notifyOnChange()
    )
  }

  private notifyOnChange() {
    if (this.state.startDate && this.state.endDate) {
      this.props.onRangeChange({
        startDate: this.state.startDate,
        endDate: this.state.endDate
      })
    }
  }

  private rootEl() {
    return jQuery(ReactDOM.findDOMNode(this)!)
  }
}

export function formatDate(date: Date): string {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month}-${day}`
}
