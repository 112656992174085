import * as React from "react";
import * as moment from "moment";
import ResultSourceHeaderView from "./resultDetailView/resultSourceHeaderView";
import ResultFooterView from "./resultDetailView/resultFooterView";
import DetailAdditionalSourcesView from "./resultDetailView/detailAdditionalSourcesView";
import { IEsApiResultContent } from "models/esResult";
import * as _ from "underscore";
import I18n from "i18n";
import highlight from "libraries/highlight";
import highlightRichText from "libraries/highlightRichText";
import { IEsApiResultAttachment } from "models/esResult";
import * as blueimp from "blueimp";
import session from "models/sessionInstance";
import SearchableDropdown from "./SearchableDropdown";
import AddToReaderClient from "./addToReaderClient";
import ShowEmailModal from "./SendEmailModal";
import truncate from 'libraries/truncate'
import SeeFullArticleComponent from 'components/seeFullArticleComponent'
import browserCheck from 'libraries/browserCheck'

interface Props {
  clip: IEsApiResultContent
  editClipClicked?: () => void
}

export default class ResultDetailView extends React.Component<Props> {

  private gallery: any;

  private isClipEditable() {
    return session.can("edit_clips_globally");
  }

  private canBeAddedToReader() {
    return session.hasFeature("use_new_reader_management");
  }

  private showLinkBar() {
    return this.isClipEditable() || this.canBeAddedToReader();
  }

  private showEditedBadge() {
    return this.props.clip.clip.meta.was_clip_edited && session.showEditLabel()
  }

  render() {
    const primarySource = this.props.clip.sources[0];

    return (
      <div data-detail-view="">
        <div className="lightened-container lifted-container pvm phm">
          {this.showLinkBar() ? (
            <div className="link-bar mbl">
              {this.renderAddToReaderDropdown()}
              {this.renderClipEditLink()}
              {session.shareSelectedArticlesViaEmail() && this.articleViaEmailModal()}
            </div>
          ) : null}
          <ResultSourceHeaderView
            {...{
              sourceName: primarySource.meta.name,
              sourceFavicon: primarySource.meta.favicon_url,
              sourceReach: primarySource.meta.reach,
              sourceReachLabel: I18n.t(
                "webapp.agents.results.result_details.metadata.visits_per_month"
              ),
              articlePublishTime: moment(this.props.clip.clip.publish_time),
              paywall: primarySource.meta.paywall_type,
              editedBadge: this.showEditedBadge()
            }}
          />
          <div className="clip-detail__head">
            {this.renderArticleTitle()}
            {this.renderMentions()}
          </div>
          <div className="clip-detail__body pbm">
            {this.renderYoutubeVideo()}
            {this.renderVimeoVideo()}
            {this.renderInstagramImage()}
            {this.renderInstagramVideo()}
            {this.renderGallery()}
            {this.renderText()}
          </div>
          <ResultFooterView {...this.props.clip} />
        </div>
        {this.renderAdditionalSources()}
      </div>
    );
  }

  articleViaEmailModal() {
    return (
      <ShowEmailModal emailClipIds={[this.props.clip.id]} emailAgentResultIds={[]} />
    );
  }


  private renderClipEditLink() {
    if (session.can("edit_clips_globally")) {
      return (
        <a className="link-bar__right-item" onClick={this.props.editClipClicked}>
          <i className="icon-pencil" />
          {I18n.t("webapp.agents.results.result_details.edit_clip.edit")}
        </a>
      );
    }
  }

  private renderAddToReaderDropdown() {
    if (session.hasFeature("use_new_reader_management")) {
      const props = {
        onCategorySelect: (confId: number, catId: number) => {
          new AddToReaderClient(confId, catId).fromClips([this.props.clip.id]);
        }
      };
      return <SearchableDropdown {...props} />;
    }
  }

  private renderAdditionalSources() {
    let additionalSources = _.rest(this.props.clip.sources);
    if (!_.isEmpty(additionalSources)) {
      return (
        <DetailAdditionalSourcesView
          additionalSources={additionalSources}
          articlePublishTime={this.props.clip.clip.publish_time}
          radioTv={this.props.clip.clip.meta.radio_tv_clip}
        />
      );
    }
  }

  private renderArticleTitle() {
    let primarySource = this.props.clip.sources[0];

    if (this.props.clip.clip.meta.youtube_content_removed) {
      return (
        <h1 className="heading-xlarge">
	  {I18n.t("webapp.agents.results.result_details.youtube_content_removed")}
        </h1>
      );
    }

    if (primarySource.meta.article_url) {
      if (!_.isEmpty(this.props.clip.clip.languages)) {
        return (
          <h1 className="heading-xlarge">
            <a
              href={primarySource.meta.article_url}
              className="clip-detail__title hyphenate"
              lang={this.props.clip.clip.languages[0]}
              target="_blank">
              {this.props.clip.clip.title}
            </a>
          </h1>
        );
      } else {
        return (
          <h1 className="heading-xlarge">
            <a
              href={primarySource.meta.article_url}
              className="clip-detail__title"
              target="_blank">
              {this.props.clip.clip.title}
            </a>
          </h1>
        );
      }
    } else {
      if (!_.isEmpty(this.props.clip.clip.languages)) {
        return (
          <h1 className="heading-xlarge">
            <span
              className="clip-detail__title hyphenate"
              lang={this.props.clip.clip.languages[0]}>
              {this.props.clip.clip.title}
            </span>
          </h1>
        );
      } else {
        return (
          <h1 className="heading-xlarge">
            <span className="clip-detail__title">{this.props.clip.clip.title}</span>
          </h1>
        );
      }
    }
  }

  private renderMentions() {
    if (this.props.clip.clip.meta.mentions) {
      return (
        <div className="mentions">
          <ul className="mentions__list">
            {_.map(this.props.clip.clip.meta.mentions, (mention: string) => {
              return <li key={mention} className="mentions__list-item"> {mention}</li>;
            })}
          </ul>
        </div>
      );
    }
  }

  private renderText() {
    let primarySource = this.props.clip.sources[0];
    const clipUrl = primarySource.meta.article_url;

    let highlighted_text = highlight(this.props.clip.clip.meta.text || "");
    if (this.props.clip.clip.meta.rich_text) {
      highlighted_text = highlightRichText(this.props.clip.clip.meta.rich_text);
    }
    let showArticleLink = false
    const limit = session.getTextLengthLimit();
    let text = truncate(highlighted_text, limit)
    if (browserCheck.isIE11() && highlighted_text.length > limit && clipUrl) {
      showArticleLink = true
    }

    if (!_.isEmpty(this.props.clip.clip.languages)) {

      if (showArticleLink && clipUrl) {
        return (
          <div>
            <p
              className="hyphenate"
              lang={this.props.clip.clip.languages[0]}
              onClick={this.openInNewTab}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <SeeFullArticleComponent clipUrl={clipUrl} />
          </div>
        )
      } else {
        return (
          <p
            className="hyphenate"
            lang={this.props.clip.clip.languages[0]}
            onClick={this.openInNewTab}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        );
      }
    } else {
      if (showArticleLink && clipUrl) {
        return (
          <div>
            <p
              className="hyphenate"
              onClick={this.openInNewTab}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <SeeFullArticleComponent clipUrl={clipUrl} />
          </div>
        );
      } else {
        return (
          <p
            className="hyphenate"
            onClick={this.openInNewTab}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        );
      }
    }
  }

  openInNewTab(event: React.MouseEvent<HTMLElement>) {
    let target = event.target as HTMLElement;
    while (target.tagName !== 'A' && target !== event.currentTarget) {
      if (target.parentElement) {
        target = target.parentElement
      } else {
        return true;
      }
    }
    if (target.tagName !== 'A') {
      return true
    }
    event.preventDefault();
    let anchor = target as HTMLAnchorElement
    window.open(anchor.href, '_blank', 'noopener');
  }



  private renderYoutubeVideo() {
    let clipProperties = this.props.clip.clip.meta.properties;
    let articleUrl = clipProperties.article_url;
    if (this.props.clip.clip.meta.youtube_content_removed) {
      return (
        <p>{I18n.t("webapp.agents.results.result_details.youtube_content_removed")}</p>
      );
    }
    if (
      articleUrl &&
      articleUrl.match(/^https?:\/\/www\.youtube\.com/) &&
      articleUrl.match(/[?&]v=(\w+)/)
    ) {
      let match = articleUrl.match(/[?&]v=(\w+)/);
      if (match) {
        const iframeSource = `//www.youtube-nocookie.com/embed/${match[1]}`;
        return (
          <div className="clip-detail__video clip-detail__video_ext">
            <iframe src={iframeSource} frameBorder="0" allowFullScreen />
          </div>
        );
      }
    }
  }

  private renderVimeoVideo() {
    let clipProperties = this.props.clip.clip.meta.properties;
    let vimeoId = clipProperties.vimeo_id || clipProperties.emm_vimeo_id;
    if (vimeoId) {
      const iframeSource = `//player.vimeo.com/video/${vimeoId}`;
      return (
        <div className="clip-detail__video clip-detail__video_ext">
          <iframe
            src={iframeSource}
            width="500"
            height="281"
            frameBorder="0"
            //                  mozallowfullscreen={true}
            //                  webkitallowfullscreen={true}
            allowFullScreen={true}
          />
        </div>
      );
    }
  }

  private renderInstagramImage() {
    let clipMetadata = this.props.clip.clip.meta;
    let url = clipMetadata.url;
    let imageUrl = clipMetadata.image_url;
    let videoUrl = clipMetadata.video_url;
    if (
      !videoUrl &&
      imageUrl &&
      url &&
      url.match(/^https?:\/\/www\.instagram\.com/)
    ) {
      return (
        <div>
          <a href={url} target="_blank">
            <img src={imageUrl} className="clip-detail__image" />
          </a>
        </div>
      );
    }
  }

  private renderInstagramVideo() {
    let clipMetadata = this.props.clip.clip.meta;
    let url = clipMetadata.url;
    let imageUrl = clipMetadata.image_url;
    let videoUrl = clipMetadata.video_url;
    if (
      videoUrl &&
      imageUrl &&
      url &&
      url.match(/^https?:\/\/www\.instagram\.com/)
    ) {
      return (
        <div className="clip-detail__image">
          <a href={url} className="clip-detail__video__link" target="_blank">
            <img src={imageUrl} className="clip-detail__image" />
          </a>
        </div>
      );
    }
  }

  componentDidMount() {
    const images = this.images();
    if (!_.isEmpty(images)) {
      setTimeout(() => {
        this.initImageGallery(images);
      }, 100);
    }
  }

  private renderGallery() {
    const images = this.images();
    if (!_.isEmpty(images)) {
      let galleryClassNames = [
        "blueimp-gallery",
        "blueimp-gallery-carousel",
        "blueimp-gallery-controls"
      ];
      if (images.length === 1) {
        galleryClassNames.push("single");
      }
      return (
        <div id={this.galleryId()} className={galleryClassNames.join(" ")}>
          <div className="slides" />
          <div className="slides-background" />
          <span className="counter" />
          <span className="caption" />
          <a className="download" target="_new">
            <i className="icon-download" />
          </a>

          <a className="prev">
            <i className="prevIcon fa fa-caret-left" />
          </a>
          <a className="next">
            <i className="nextIcon fa fa-caret-right" />
          </a>
        </div>
      );
    }
  }

  private galleryId() {
    return "blueimp-gallery-carousel-research";
  }

  private initImageGallery(images: IEsApiResultAttachment[]) {
    const data = _.map(images, (img: IEsApiResultAttachment) => {
      let caption, title;

      if (img.caption) {
        caption = highlight(img.caption);
        title = caption.replace(/<\/?[^>]+(>|$)/g, "");
      }

      return {
        title: title,
        caption: caption,
        href: img.url,
        thumbnail: img.url
      };
    });

    let firstHighlightedCaption = _.find(data, (d: any) => {
      return /clip-detail__highlight/.test(d.caption);
    });
    let startIndex = 0;
    if (firstHighlightedCaption) {
      startIndex = _.indexOf(data, firstHighlightedCaption);
    }

    const multipleImages = images.length > 1;
    const options = {
      container: `#${this.galleryId()}`,
      index: startIndex,
      carousel: true,
      startSlideshow: false,
      enableKeyboardNavigation: multipleImages,
      emulateTouchEvents: multipleImages
    };
    this.gallery = blueimp(data, options);
    this.gallery.onclick = () => {
      this.gallery.next();
    };
  }

  private images() {
    return _.select(
      this.props.clip.clip.meta.attachments,
      (attachment: IEsApiResultAttachment) => {
        return attachment.file_type === "image";
      }
    );
  }
}
