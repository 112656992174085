import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/curia.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import I18n from 'i18n'

var DefaultView = Marionette.ItemView.extend({
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    const data = this.agentResult.get('clip_metadata');

    let metadata = {};

    const col1 = {
      business_number: data.business_number,
      business_type: data.business_type,
      submission_council: data.submission_council,
      responsible_department: data.department,
      responsible_commissions: data.committees.join('; ')
    }

    conditionalSetToMetadata(metadata, 'col1', col1);

    const col2 = {
      submission_date: data.publish_time,
      done_date: data.done_date,
      date_of_answering: data.federal_council_proposal_date,
      status: data.status,
      legislative_period: data.legislative_period,
      session: data.session
    }
    conditionalSetToMetadata(metadata, 'col2', col2);

    let col3 = {
      author: data.author,
      language: data.language,
      last_modified: data.last_modified
    }
    if (data.signatory_names) {
      col3['signatory_names'] = data.signatory_names.join('; ')
    }
    if (data.language) {
      col3['language'] =
        I18n.t("vendor.iso.languages." + data.language);
    }
    conditionalSetToMetadata(metadata, 'col3', col3);

    return metadata;
  }
});

export default DefaultView;

