import * as React from "react";
import * as _ from "underscore";

interface IButton {
  label: string;
  value: string;
}

interface IRadioButtonProps {
  button: IButton;
  selectedOption: string;
  onRadioButtonClick: (value: string) => void;
  disabled: boolean;
}

interface IRadioButtonGroupProps {
  buttons: IButton[];
  selectedOption: string;
  onRadioButtonClick: (value: string) => void;
  disabled: boolean;
}

class RadioButton extends React.Component<IRadioButtonProps> {
  render() {
    let classNames = ["button-group__item"];
    if (this.props.selectedOption === this.props.button.value) {
      classNames.push("is-active");
    }

    return (
      <button type="button"
              data-option={this.props.button.value}
              onClick={this.onRadioButtonClick.bind(this)}
              className={classNames.join(" ")}
              disabled={this.props.disabled}> {this.props.button.label}
      </button>
    );
  }

  onRadioButtonClick(e: Event) {
    e.preventDefault();
    this.props.onRadioButtonClick(this.props.button.value);
  }
}

class RadioButtonGroup extends React.Component<IRadioButtonGroupProps> {
  render() {
    const radioButtonDivClassNames = [
      "button-group", "button-group--radio", "button-group--vertical", "mbl"
    ];
    return (
      <div className={radioButtonDivClassNames.join(' ')}>
        {this.renderButtons()}
      </div>
    );
  }

  renderButtons() {
    return _.map(this.props.buttons, (button, idx) => {
      const radioButtonProps = {
        button: button,
        selectedOption: this.props.selectedOption,
        onRadioButtonClick: this.props.onRadioButtonClick,
        disabled: this.props.disabled
      };
      return <RadioButton {...radioButtonProps} key={idx} />;
    });
  }
}

export {IButton, RadioButtonGroup};
