/* eslint no-invalid-this: "off" */
import App from 'app';
import vent from 'libraries/vent';
import $ from 'jquery';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import { createRoot } from 'react-dom/client';
import Toolbar from '../components/dashboard/Toolbar';
import LayoutContentResizer from 'modules/layoutContentResizer';
import session from 'models/sessionInstance';

App.module('TableauDashboard', {
  startWithParent: false,
  define: function () {
    App.TableauDashboard.name = 'Dashboard';
    App.TableauDashboard.show = function () {
      $('#container-tableau-dashboard').show();
    };
    App.TableauDashboard.hide = function () {
      $('#container-tableau-dashboard').hide();
    };

    App.TableauDashboard.on('loaded', function () {
      vent.commands.execute('navigation:loaded', 'dashboard');
    });

    App.TableauDashboard.addInitializer(function () {
      App.TableauDashboard.registerInNavigation();
      $('#container-content').append(
        `<div id="container-tableau-dashboard" class="content" style="overflow: auto; margin-top: 0.5em;"><div style="width: 1400px; height: 910px; margin: 0 auto;">${session.get(
          'tableau_snippet'
        )}</div></div>`
      );
      LayoutContentResizer.resizeContent();
      $('#container-tableau-dashboard').hide();
      // called when the module can be shown:
      App.TableauDashboard.trigger('loaded');
    });

    App.TableauDashboard.addFinalizer(function () {
      this.hide();
      this.deregisterInNavigation();
      ReactDom.unmountComponentAtNode(
        document.getElementById('container-tableau-dashboard')
      );
    });

    App.TableauDashboard.registerInNavigation = function () {
      this.createToolbar();
      vent.commands.execute(
        'navigation:register',
        App.TableauDashboard,
        'dashboard',
        { toolbar: this.toolbar }
      );
      // The toolbar component has to be rendered after the toolbar
      //element has been added to the DOM by the navigation:register
      // call
      this.toolbar.render();
      vent.commands.execute('navigation:loading', 'dashboard');
      vent.commands.setHandler('navigation:show:dashboard', function () {
        App.TableauDashboard.show();
      });
      vent.commands.setHandler('navigation:hide:dashboard', function () {
        App.TableauDashboard.hide();
      });
    };

    App.TableauDashboard.createToolbar = function () {
      // create an object that looks like a Backbone.View to the
      // navigation module (i.e. has a `el` property)
      const el = document.createElement('div');
      const root = createRoot(el);
      this.toolbar = {
        render: () => {
          root.render(<Toolbar />);
        },
      };
    };
  },
});

export default App.TableauDashboard;
