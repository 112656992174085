import _ from 'underscore'
import $ from 'jquery'
import I18n from 'i18n'
import Deferred from 'libraries/deferred'
import OptionsProvider from 'models/globalSearch/optionsProvider'
import validateAgentResultQuery from 'models/validateAgentResultQuery'

function isPresent(term) {
  return !_.isEmpty($.trim(term));
}

export default class GlobalSearchOptionsProvider extends OptionsProvider {
  results(term) {
    if (isPresent(term)) {
      return validateAgentResultQuery(term).then(function (valid) {
        if (valid) {
          return {
            text: term,
            type: "search",
            id: "search",
            priority: 8
          };
        } else {
          var text = I18n.t('webapp.global_search.invalid_input');
          return {
            text: text,
            type: "search",
            id: "search",
            disabled: true
          };
        }
      });
    } else {
      var deferred = Deferred.build();
      deferred.resolve();
      return deferred.promise();
    }
  }
}
