import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/youtube.html'
import agentResultReadingTime from 'libraries/agentResultReadingTime'

var youtubeView = Marionette.Layout.extend({
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    return {
      metadata: this.metadata()
    };
  },
  metadata: function () {
    var agentResult = this.agentResult;
    var clipMetadata = agentResult.get('clip_metadata');
    var data = {};
    if (clipMetadata) {
      data = {
        information: {
            reading_time: agentResultReadingTime(this.agentResult),
            youtubeTos: clipMetadata.youtube_tos,
            googlePrivacyPolicy: clipMetadata.google_privacy_policy
        }
      };
    }
    return data;
  }
});

export default youtubeView;
