import React, { useEffect, useState } from 'react';
import I18n from 'i18n';
import axios from 'axios';
import Loading from './dashboard/components/Loading';
import Notificator from '../libraries/notificator';

interface Props {
  handleClickSourceCategory: (i: SourceCategoryItem) => void;
}

export interface SourceCategoryItem {
  enabled: boolean;
  id: number;
  label: string;
}

interface SourceCategoryList {
  data: SourceCategoryItem[];
}

export default function SourceCategoryLicensingList({
  handleClickSourceCategory,
}: Props) {
  const [list, setList] = useState<SourceCategoryItem[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get('/api/v3/licensing_settings')
      .then(function (response) {
        const resp = response.data;
        setList(resp);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        Notificator.showErrorNotification(error.message);
      });
  }, []);

  return (
    <form>
      {loading ? (
        <Loading />
      ) : (
        list.map((i: SourceCategoryItem) => {
          return (
            <a
              key={i.id}
              onClick={() => handleClickSourceCategory(i)}
              id={`${i.id}`}
              className="mbl button button--block button--pointing align-left">
              {i.label}
            </a>
          );
        })
      )}
    </form>
  );
}
