import * as React from "react";
import 'bpopup';

interface IModal {
  title: string
  cancelLabel: string
  confirmationLabel: string
  onConfirm: () => void;
  modalId: string;
  open: boolean;
  close?: boolean;
  children: any;
  onKeyDown?: (e: any) => void; 
}

class Modal extends React.Component<IModal> {
  modal: React.RefObject<any> = React.createRef();
  confirmBtnRef: React.RefObject<any> = React.createRef();

  render() {
    const modalStyle = { display: "none" };

    if (this.props.open) {
      return (
        <div className="modal"
            id={this.props.modalId}
            style={modalStyle}
            ref={this.modal}>
          <div className="modal__head">
            <h2 className="modal__head-title">{this.props.title}</h2>
          </div>
          <div className="modal__body">
            <p>{this.props.children}</p>
          </div>
          <div className="modal__footer">
            <div className="action-bar">
              <div className="col1of2">
                <div className="action-bar__action">
                  <a className="button button--block button--small b-close">
                    {this.props.cancelLabel}
                  </a>
                </div>
              </div>
              <div className="col1of2">
                <div className="action-bar__action">
                  <a className="button button--block button--small button--negative"
                    ref={this.confirmBtnRef}>
                    {this.props.confirmationLabel}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <span className="modal__close b-close"></span>
        </div>
      );
    } else {
      return (<div></div>);
    }
  }

  bindKeyHandler = (e: any) => this.props.onKeyDown && this.props.onKeyDown(e);

  componentDidMount() {
    if (this.props.open) {
      this.modal.current.focus();
      setTimeout(() => {
        ($(`#${this.props.modalId}`) as any).bPopup(this.opts());
      });
      if (this.props.onKeyDown) {
        document.addEventListener("keydown", this.bindKeyHandler);
      }
    }

    if (this.props.close) {
      if (this.props.onKeyDown) {
        document.removeEventListener("keydown", this.bindKeyHandler);
      };
      this.closeModal();
    }

    if (this.confirmBtnRef.current != null) {
      this.confirmBtnRef.current.onclick = () => {
        this.props.onConfirm()
      };
    }
  }

  opts() {
    return {
      onClose: () => {
        this.removeModal();
      }
    }
  }

  closeModal() {
    setTimeout(() => {
      const popup = ($('#' + this.props.modalId) as any)
      popup.bPopup(this.opts()).close()
    })
  }

  removeModal() {
    setTimeout(() => {
      const selector = '#' + this.props.modalId
      const popup = ($(selector) as any)
      popup.remove()
      $('.b-modal').remove()
    })
  }
}

export default Modal;
