import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'
import * as jQuery from 'jquery'
import I18n from 'i18n'
import Dropdown from 'components/dropdown'
import {Select2WithAndSeparator, Select2Id, Select2Option, Select2Configuration}
  from 'components/researchFilter/select2Component'
import {DatepickerRange, DatepickerRangeDateRange, formatDate} from 'components/researchFilter/datepickerRange'
import ProviderFinder from 'libraries/researchFilter/providerFinder'
import { IEsApiEntity as Entity, IEsApiTopic as Topic } from 'models/esResult'

export type FilterType = "fulltext" | "language" | "date" | "topic" | "location_entity" | "person_entity" | "organization_entity" | "business_entity" | "law_entity"

interface IFilterProps {
  entityNameCache?: Backbone.Collection<Entity>
  topicNameCache?: Backbone.Collection<Topic>
  id: number
  type: FilterType
  exclude: boolean
  selection: Select2Id[]
  onDeleteClick: () => void
  deletable: boolean
  onFilterChange: (newFilterInfo: FilterInfo) => void
  allowedTypes: FilterType[]
  readonly: boolean
  draggable: boolean;
  singleFilter: boolean;
  active: boolean;
  showErrors: boolean
}

interface IFilterState {
  type: FilterType
  currentSelection: Select2Id[]
  exclude: boolean
  active: boolean
  showErrors: boolean
}

interface FilterInfo {
  type: FilterType
  exclude: boolean
  id: number
  selection: Select2Id[]
  active: boolean
  showErrors: boolean
}

export class Filter extends React.Component<IFilterProps, IFilterState> {
  constructor(props: IFilterProps) {
    super(props)
    this.state = this.initialState(props)
  }

  private initialState(props: IFilterProps): IFilterState {
    let type = props.type
    let exclude = props.exclude
    return {
      type: type,
      exclude: exclude,
      currentSelection: props.selection.slice(),
      active: props.active,
      showErrors: props.showErrors
    }
  }

  render() {
    return (
      <div className={this.mainDivClasses()}
           draggable={this.props.draggable}
           onDragStart={(e) => {this.dragStartHandler(e)}}
           onDragEnd={(e) => {this.dragEndHandler(e)}}>
        {this.summaryButton()}
        {this.filterContainer()}
      </div>
    )
  }

  private filterContainer() {
    if (this.state.active) {
      return (
        <div className="advancedsearch--container">
          <div className="row mbm">
            <div className="col1of2">{this.filterDropdown()}</div>
            <div className="col1of2">{this.operatorDropdown()}</div>
          </div>
          {this.selectionRow()}
          {this.maybeErrorMessages()}
        </div>
      );
    }
  }

  private selectionRow() {
    if (this.state.type === 'date') {
      return (
        <div className="row">
          <DatepickerRange onRangeChange={(range) => this.onDaterangeChange(range)}
          {...this.dateRangeProps()}/>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="col1of1">
            <Select2WithAndSeparator {...this.select2Props()} />
          </div>
        </div>
      )
    }
  }

  private dateRangeProps() {
    function parseDate(d: string | number): Date {
      if (typeof d === 'number') {
        throw 'Numbers are not dates!'
      }
      let [year, month, day] = d.split('-').map(s => parseInt(s, 10))
      return new Date(year, month - 1, day)
    }
    if (this.state.currentSelection.length === 2) {
      return {
        startDate: parseDate(this.state.currentSelection[0]),
        endDate: parseDate(this.state.currentSelection[1])
      }
    } else {
      return {}
    }
  }

  private isErroneous(): boolean {
    return !ProviderFinder[this.state.type].isValid(this.state.currentSelection)
  }

  private errorMessages(): String[] {
    return ProviderFinder[this.state.type].validate(this.state.currentSelection)
  }

  private mainDivClasses(): string {
    let classes = 'advancedsearch--filter form-item'
    if (this.isErroneous()) {
      classes = classes + ' is-erroneous'
    }
    return classes
  }

  private maybeErrorMessages() {
    if (this.state.showErrors) {
      return this.errorMessages().map((error: String) => {
        return <div className="form-item__feedback">
          <i className="icon-spam"></i> {error}
        </div>
      })
    }
  }

  private summaryButton() {
    let menuClassName = "advancedsearch-menu-icon";
    if (!this.props.draggable) {
      menuClassName = "advancedsearch-menu-icon--disabled";
    }
    return (
      <div className="advancedsearch--summary">
        <i className={menuClassName}></i> <div className="advancedsearch--summary__text">{this.summaryText()}</div>
        {this.summaryActions()}
      </div>
    )
  }

  private summaryText() {
    let filterType = this.mapFilterTypeToDropdownOption(this.state.type)['text'];
    let filterOperator = this.summaryOperator();

    // entity names are loaded separately
    const provider = ProviderFinder[this.state.type];
    let filterSelection;
    if (this.state.type.indexOf('_entity') !== -1) {
      filterSelection  = provider.showSelection(
        this.props.entityNameCache,
        this.state.currentSelection
      )
    } else if (this.state.type === 'topic') {
      filterSelection  = provider.showSelection(
        this.props.topicNameCache,
        this.state.currentSelection
      )
    } else {
      filterSelection =
        provider.showSelection(this.state.currentSelection);
    }
    return (
      filterType + " " + filterOperator + " " + filterSelection
    )
  }

  private summaryActions() {
    let pencilActionClasses = this.summaryPencilClasses();
    if (!this.props.readonly) {
      return (
        <span className="advancedsearch--summary__actions">
          <span className={pencilActionClasses}>
            <i className="advancedsearch-pencil-icon" onClick={() => this.onPencilClick()}></i>
          </span>
          {this.deleteAction()}
        </span>
      )
    }
  }

  private deleteAction() {
    if (this.props.deletable) {
      return (
        <span className="advancedsearch--summary__actions__icon">
          <i className="advancedsearch-delete-icon" onClick={() => this.onDeleteClick()}></i>
        </span>
      )
    }
  }

  private summaryPencilClasses() {
    let classes = "advancedsearch--summary__actions__icon"
    if (this.state.active) {
      classes = classes + " is-active"
    }
    return classes
  }

  private summaryOperator() {
    return ProviderFinder[this.state.type].summaryOperator(this.state.exclude)
  }

  private onDeleteClick() {
    this.props.onDeleteClick()
  }

  private onPencilClick() {
    this.setState((prevState: IFilterState) => {
      if (this.state.active) {
        return {
          active: false
        }
      } else {
        return {
          active: true
        }
      }
    }, () => {
      this.handleFilterChange()
    })
  }

  private mapFilterTypeToDropdownOption(type: FilterType): DropdownOption<FilterType> {
    return ProviderFinder[type].dropDownOption()
  }

  private filterDropdownOptions() {
    return this.props.allowedTypes.map(type => this.mapFilterTypeToDropdownOption(type))
  }

  private filterDropdown() {
    let options: DropdownOption<FilterType>[] = this.filterDropdownOptions()
    return (
      <div className="advancedsearch--container-filter-type">
        <FilterTypeDropdown options={options}
                            selectedOption={this.state.type}
                            onOptionChange={(v) => this.onFilterTypeChange(v)}
                            readonly={this.props.readonly}
                            small={true}
                            block={true}
      />
      </div>
    )
  }

  private buildFilterInfo(): FilterInfo {
    return {
      selection: this.state.currentSelection,
      type: this.state.type,
      exclude: this.state.exclude,
      id: this.props.id,
      active: this.state.active,
      showErrors: this.state.showErrors
    }
  }

  private handleFilterChange() {
    this.props.onFilterChange(this.buildFilterInfo())
  }

  private onFilterTypeChange(newType: FilterType) {
    this.setState((prevState: IFilterState) => {
      if (prevState.type !== newType) {
        return {
          type: newType,
          currentSelection: []
        }
      } else {
        return {
          type: newType,
          currentSelection: prevState.currentSelection
        }
      }
    }, () => {
      this.handleFilterChange()
    })
  }

  private operatorOptions(): DropdownOption<boolean>[] {
    return ProviderFinder[this.state.type].operatorOptions()
  }

  private operatorDropdown() {
    let options = this.operatorOptions()
    return (
      <div className="advancedsearch--container-filter-operator">
        <OperatorDropdown options={options}
                          selectedOption={this.state.exclude}
                          onOptionChange={(v) => this.onOperatorChange(v)}
                          readonly={this.props.readonly}
                          small={true}
                          block={true}
      />
      </div>
    )
  }

  private onOperatorChange(newOperator: boolean) {
    this.setState((prevState: IFilterState) => {
      return { exclude: newOperator }
    }, () => {
      this.handleFilterChange()
    })
  }

  private select2Props() {
    let provider = ProviderFinder[this.state.type];
    let options: Select2Option[] = provider.select2Options();
    let config: Select2Configuration
    const opts: any = {}
    if (this.props.entityNameCache &&
      this.props.entityNameCache.length > 0) {
      opts.entityNameCache = this.props.entityNameCache
    }
    if (this.props.topicNameCache &&
      this.props.topicNameCache.length > 0) { 
      opts.topicNameCache = this.props.topicNameCache
    }
    config =
        provider.select2CustomConfiguration(opts);
    
    return {
      selection: this.state.currentSelection.slice(),
      options: options,
      configuration: config,
      onSelectionChange: (newSelection: Select2Id[]) => this.onSelectionChange(newSelection),
      allowArbitraryInput: ProviderFinder[this.state.type].allowArbitraryInput(),
      readonly: this.props.readonly
    }
  }

  private onSelectionChange(newSelection: Select2Id[]) {
    this.setState((prevState: IFilterState) => {
      return {
        currentSelection: newSelection.slice(),
        showErrors: true
      }
    }, () => {
      this.handleFilterChange()
    })
  }

  private onDaterangeChange(range: DatepickerRangeDateRange) {
    this.setState((prevState: IFilterState) => {
      return {
        currentSelection: [formatDate(range.startDate), formatDate(range.endDate)]
      }
    }, () => {
      this.handleFilterChange()
    })
  }

  private dragStartHandler(event: any) {
    event.currentTarget.classList.add("is-dragged");
    $(event.currentTarget).closest(".advancedsearch--or-block")
                          .find(".separator--drop")
                          .addClass("notDroppable");
    if (this.props.singleFilter) {
      $(".advancedsearch > .separator--drop").addClass("notDroppable");
    }
    event.dataTransfer.setData("Text", String(this.props.id));
    setTimeout(() => {
      this.showDropZones()
    }, 1);
  }

  private showDropZones() {
    $(".separator--drop:not('.notDroppable')").show();
  }

  private dragEndHandler(event: any) {
    event.currentTarget.classList.remove("is-dragged");

    event.dataTransfer.clearData();
    this.hideDropZones();
  }

  private hideDropZones() {
    $(".separator--drop").removeClass("notDroppable");
    $(".separator--drop").hide();
  }
}

interface DropdownOption<T> {
  text: string
  value: T
}

// Generics cannot be used directly in JSX, so I have to name them.
class FilterTypeDropdown extends Dropdown<FilterType> {}
class OperatorDropdown extends Dropdown<boolean> {}
