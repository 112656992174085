import Marionette from 'backbone.marionette'
import $ from 'jquery'
import _ from 'underscore'
import template from 'text-loader!templates/layout.html'

var Layout = Marionette.Layout.extend({
  template: template,
  regions: {
    header: '[data-container=header]',
    navigation: '[data-container=navigation]',
    globalSearch: '[data-container=global-search]',
    legacyContent: '[data-container=legacy-content]',
    content: '[data-container=content]'
  },
  ui: {
    toolbar: '[data-container=toolbar]',
    leftColumn: '[data-layout-column=left]',
    rightColumn: '[data-layout-column=right]',
    overlayMask: '[data-overlay-mask]'
  },
  triggers: {
    'click [data-action=show-sidebar]': 'show-sidebar',
    'click [data-action=close-right-column]': 'close-right-column'
  },
  initialize: function () {
    _.bindAll(this, 'disableSidebarOverlay');
    this._initializeSidebarOverlay();
  },
  _initializeSidebarOverlay: function () {
    $('body').addClass('no-sidebar-overlay');
    this.disableSidebarOverlay();
  },
  showSidebar: function () {
    this.enableSidebarOverlay();
  },
  enableSidebarOverlay: function () {
    $('body').addClass('sidebar-overlay');
    $('body').removeClass('no-sidebar-overlay');
    this._registerSidebarCloseHandlers();
  },
  disableSidebarOverlay: function () {
    $('body').removeClass('sidebar-overlay');
    $('body').addClass('no-sidebar-overlay');
    this._deregisterSidebarCloseHandlers();
  },
  _registerSidebarCloseHandlers: function () {
    this.ui.overlayMask.one('click.sidebarOverlay', this.disableSidebarOverlay);
  },
  _deregisterSidebarCloseHandlers: function () {
    this.$(this.ui.overlayMask).off('click.sidebarOverlay');
  },
  showLegacyContent: function () {
    this.content.close();
    this.$(this.legacyContent.el).show();
    this.$(this.content.el).hide();
  },
  showContent: function (view) {
    this.makeContentLight();
    this.$(this.legacyContent.el).hide();
    this.$(this.content.el).show();
    if (_.isObject(view)) {
      this.content.show(view);
    }
  },
  hide: function () {
    this.$el.hide();
  },
  show: function () {
    this.$el.fadeIn('slow');
  },
  addBodyClass: function (klass) {
    $('body').addClass(klass);
  },
  makeContentLight: function () {
    $('body').removeClass('gradient-grey-dark');
    $('#container-navigation').removeClass('shadow');
  },
  appendToToolbar: function (element) {
    this.ui.toolbar.append(element);
  },
  focusLeft: function () {
    this.ui.leftColumn.removeClass('handheld-hidden');
    this.ui.rightColumn.addClass('handheld-hidden');
  },
  focusRight: function () {
    this.ui.rightColumn.removeClass('handheld-hidden');
    this.ui.leftColumn.addClass('handheld-hidden');
  }
});

export default Layout;

