import ClipCreationFormController from 'controllers/clipCreationFormController';
import PmgUploadFormView from 'views/pmgUploadFormView';
import PmgZipFile from 'models/pmgZipFile';
import I18n from 'i18n'
import _ from 'underscore'

class PmgUploadFormController extends ClipCreationFormController {
    _initializeViewAndModel() {
        this.view = new PmgUploadFormView();
        this.model = new PmgZipFile();
        _.bindAll(this, '_fileSizeCheck', '_showFileSizeError')
    }

    _registerFormChecks() {
        this._registerFormCheck(
            'file', this._fileSeemsOk, this._showFileTypeError
        );
        this._registerFormCheck(
            'fileSize', this._fileSizeCheck, this._showFileSizeError
        );
    }

    _registerListeners() {
        this.listenTo(this.view, 'change:file', this._prepareFile);
    }

    _fileReady(file) {
        let handlers = {
            error: (file, error) => {
                let errorMessage = JSON.parse(error.responseText).error;
                this.view.hideUploadInProgress();
                this.view.showUpload();
                this.view.setFileError(errorMessage);
                this.view.showFileName('');
            },

            success: (file, response) => {
                this.view.hideUploadInProgress();
                this.view.hideFileError();
                this.trigger('pmgUploadSuccess', response);
            }
        }

        this.model.set('file', file);

        if (this._isFormComplete()) {
            this.view.hideUpload();
            this.view.showUploadInProgress();
            this.view.hideFileError();
            this.model.save({}, handlers);
        } else {
            this.view.showUpload();
            this._showFormErrors();
        }
    }

    _isFormComplete() {
        return this._isBrowserOk() && this._fileSeemsOk() && this._fileSizeCheck();
    }

    _checkFilePrefix() {
        this._correctFilePrefix = true;
    }

    _basicFileCheck(file) {
        return !!file && !!file.name.match(/\.zip$/i);
    }

    _fileSizeCheck() {
        var file = this.view.getFile();
        return !!file && file.size <= 1e8;
    }

    _showFileTypeError() {
        const key = 'webapp.agents.pmg_upload.invalid_file_type';
        this.view.setFileError(I18n.t(key));
    }

    _showFileSizeError() {
        const key = 'webapp.agents.pmg_upload.file_too_big';
        this.view.setFileError(I18n.t(key, {maxFilesize: '20 MB'}));
    }
}

export default PmgUploadFormController;
