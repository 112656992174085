import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/facebook.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import agentResultReadingTime from 'libraries/agentResultReadingTime'

var facebookView = Marionette.Layout.extend({
  regions: {
  },
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    return {
      metadata: this.metadata()
    };
  },
  metadata: function () {
    var agentResult = this.agentResult;
    var data = {};
    if (agentResult.isFacebookClip() && agentResult.get('author') !== null) {
      var author = agentResult.get('author');
      if (author.properties.likes) {
        data.reach = {
          likes: author.properties.likes
        };
      }
    }

    var information = {
      reading_time: agentResultReadingTime(agentResult)
    };

    conditionalSetToMetadata(data, 'information', information);

    return data;
  }
});

export default facebookView;

