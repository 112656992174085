import I18n from 'i18n'
import * as _ from 'underscore'

let FulltextFilterProvider = {
  summaryOperator(exclude: boolean) {
    if (exclude) {
      return I18n.t('webapp.agents.edit.filters.filter_operator.containsnot')
    } else {
      return I18n.t('webapp.agents.edit.filters.filter_operator.contains')
    }
  },

  operatorOptions() {
    return [
      {
        text: I18n.t('webapp.agents.edit.filters.filter_operator.contains'),
        value: false
      },
      {
        text: I18n.t('webapp.agents.edit.filters.filter_operator.containsnot'),
        value: true
      }
    ]
  },

  dropDownOption() {
    return {
      text: I18n.t('webapp.agents.edit.filters.filter_type.fulltext'),
      value: 'fulltext'
    }
  },

  select2Options() {
    return []
  },

  select2CustomConfiguration() {
    return {};
  },

  allowArbitraryInput() {
    return true
  },

  showSelection(selection: string[]) {
    let or = ' ' + I18n.t('webapp.agents.edit.filters.multiple_selection_or') + ' '
    if (selection.length > 2) {
      return selection.slice(0, -1).join(', ') + or + selection.slice(-1)
    } else {
      return selection.join(or)
    }
  },

  validate(selection: string[]) {
    let errors = []
    if (!this.validateLength(selection)) {
      errors.push(I18n.t('webapp.agents.edit.filters.selection_empty_error'))
    } else if (!this.validateWildcardPositioning(selection)) {
      errors.push(I18n.t('webapp.agents.edit.filters.wildcard_positioning_error'))
    }
    return errors
  },

  isValid(selection: string[]) {
    return this.validate(selection).length === 0
  },

  validateLength(selection: string[]) {
    return selection.length > 0
  },

// wildcards should only be at the end of a word
  validateWildcardPositioning(selection: string[]) {
    return _.every(selection, (phrase: string) => {
      return _.every(phrase.split(' '), (word: string) => {
        return /^(?!.*\*).*$|^[^\*]+\*$/.test(word.trim())
      })
    })
  }
}

export default FulltextFilterProvider
