import Backbone from 'backbone';
import WhitelistDomain from 'models/whitelistDomain';
import Config from 'libraries/configuration';

class WhitelistDomains extends Backbone.Collection {
    initialize(models, options) {
        this.contactGroup = options.contactGroup;
    }

    url() {
        const groupId = this.contactGroup.id;
        return `${Config.apiUrl}contact_groups/${groupId}/whitelist_domains`;
    }

    parse(response) {
        return response.collection;
    }
}

WhitelistDomains.prototype.model = WhitelistDomain;

export default WhitelistDomains;
