import UserAgentResultPresenter from 'models/presenters/userAgentResultPresenter';

class UserReaderResultPresenter extends UserAgentResultPresenter {
    canDownloadResult() {
        return this.agentResult.get('is_print_source') &&
            this.agentResult.downloadable();
    }
}

export default UserReaderResultPresenter;
