import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/settings/editAgentLandingPage.html'

var EditAgentLandingPageView = Marionette.ItemView.extend({
  template: template,
  serializeData: function () {
    return {
      shared: this.options.shared
    };
  }
});

export default EditAgentLandingPageView;
