import WidgetView from 'views/dashboard/widgetView'
import RatingWidgetView from 'views/dashboard/widgetView/ratingWidgetView'
import _ from 'underscore'

WidgetView.TagRatingWidget = RatingWidgetView.extend({
  ui: _.extend({}, RatingWidgetView.prototype.ui, {
    rating: '[data-rating]'
  }),
  events: {
    'click @ui.rating': '_handleClickInRating'
  },
  prepareSerializedData: function () {
    return {
      widget_type: 'tag',
      positive: this.model.get('positive'),
      neutral: this.model.get('neutral'),
      negative: this.model.get('negative')
    };
  },
  _handleClickInRating: function (e) {
    var rating = this.$(e.target).data('rating');
    this.model.showAgentTag([{type: 'rating', id: rating}])
  }
});

export default WidgetView.AgentRatingWidget;

