import Marionette from 'backbone.marionette';
import usersInstance from 'collections/usersInstance';
import template from 'text-loader!templates/agents/agentEditWarning.html';

class AgentEditWarningView extends Marionette.ItemView {
    initialize() {
        this.template = template;
    }
    serializeData() {
        let name;
        if (this.model) {
            const id = this.model.get('userId');
            const user = usersInstance.get(id);
            if (user) {
                name = user.get('full_name');
            }
        }
        return { fullname: name };
    }
    onRender() {
        if (this.model) {
            this.$el.show();
        } else {
            this.$el.hide();
        }
    }
}

export default AgentEditWarningView;
