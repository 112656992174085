import _ from 'underscore'
import Marionette from 'backbone.marionette'
import agentResultRating from 'text-loader!templates/agents/agentResultRating.html'

var AgentResultRatingView = Marionette.ItemView.extend({
  template: agentResultRating,
  ui: {
    positiveButton: '#rate-positive',
    neutralButton: '#rate-neutral',
    negativeButton: '#rate-negative'
  },
  triggers: {
    'click #rate-positive': 'clicked.rate_positive',
    'click #rate-neutral': 'clicked.rate_neutral',
    'click #rate-negative': 'clicked.rate_negative'
  },
  initialize: function () {
    _.bindAll(this, 'ratePositive', 'rateNegative', 'rateNeutral',
              'deselect');
    this.buttons = [this.ui.positiveButton,
                    this.ui.neutralButton,
                    this.ui.negativeButton];
  },
  clearButtons: function (buttons) {
    _.each(buttons, (btn) => {
      this.$(btn).removeClass('is-active');
    }, this);
  },
  deselect: function () {
    this.clearButtons(this.buttons);
  },
  ratePositive: function () {
    this.clearButtons(this.buttons);
    this.$(this.ui.positiveButton).addClass('is-active');
  },
  rateNeutral: function () {
    this.clearButtons(this.buttons);
    this.$(this.ui.neutralButton).addClass('is-active');
  },
  rateNegative: function () {
    this.clearButtons(this.buttons);
    this.$(this.ui.negativeButton).addClass('is-active');
  }
});

export default AgentResultRatingView;

