import Marionette from 'backbone.marionette'
import topicListTemplate from 'text-loader!templates/agents/topicList.html'
import TopicListItemView from 'views/agents/topicListItemView'

class TopicListView extends Marionette.CompositeView {
  appendHtml(collectionView, itemView) {
    const addTopicButton = collectionView.$("#add-topic-button");

    if (addTopicButton.length > 0) {
      addTopicButton.before(itemView.el);
    } else {
      collectionView.$("#topic-list").append(itemView.el);
    }
  }

  initialize() {
    this.on("itemview:topic:clicked", (itemView) => {
      this.trigger('clicked:topic', itemView.model);
      this.deselectAll();
      itemView.$el.addClass("is-active");
    });
  }

  onRender() {
    this.deselectAll();
  }

  deselectAll() {
    this.children.each((view) => {
      view.$el.removeClass('is-active');
    });
  }

  removeAddTopicButton() {
    if (this.$("#add-topic-button").length > 0) {
      this.$("#add-topic-button").remove();
    }
  }

  addAddTopicButton() {
    if (this.$("#add-topic-button").length === 0) {
      this.$("#topic-list").append(
        this.$("#add-topic-button-template > a").clone()
      );
      this.$("#topic-list > a:last").attr("id", "add-topic-button");
    }
  }
}
TopicListView.prototype.template = topicListTemplate;
TopicListView.prototype.itemView = TopicListItemView;
TopicListView.prototype.triggers = {
  "click #add-topic-button": "add:topic"
};

export default TopicListView;
