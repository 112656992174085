import * as React from "react";
import * as ReactDOM from "react-dom";
import * as moment from "moment";
import * as _ from "underscore";
import I18n from "i18n";
import Tooltip from "components/tooltip";
import DatePicker from "components/datePicker";
import DateFormatter from "libraries/dateFormatter";
import session from "models/sessionInstance";

interface IAgentTimeframeProps {
  agentCreationFinished: boolean;
  onStartDateClick: (date: string) => void;
  onEndDateClick: (date: string) => void;
  startDate: moment.Moment;
  endDate: moment.Moment | undefined;
}

export default class AgentTimeframe extends React.Component<IAgentTimeframeProps> {
  render() {
    const timeframeTitle = I18n.t("webapp.agents.edit.search_period.menu_title");

    return (
      <div className="lightened-container lifted-container pts phl pbl">
        <h2 className="heading-xlarge mbs">{timeframeTitle}</h2>
        <div className="col1of2">
          <div className="form-item--inline mbs">
            <Tooltip {...this.tooltipStartDateProps()} />
            <DatePicker {...this.datePickerStartDateProps()} />
          </div>
        </div>
        <div className="col2of2">
          <div className="form-item--inline mbs">
            <Tooltip {...this.tooltipEndDateProps()} />
            <DatePicker {...this.datePickerEndDateProps()} />
          </div>
        </div>
      </div>
    );
  }

  private datePickerStartDateProps() {
    let datePickerSetup = {
      endDate: moment().startOf("day").toDate(),
      format: "dd.mm.yyyy"
    };

    let agentHistoryStart = session.agentHistoryStart();
    if (!this.props.agentCreationFinished && agentHistoryStart) {
      datePickerSetup = _.extend({}, datePickerSetup, {
        startDate: moment(agentHistoryStart).startOf("day").toDate()
      });
    }

    return {
      onAdd: this.props.onStartDateClick,
      inputClassNames: ["text-input", "date-picker__input"],
      selected: DateFormatter.formatForDatePicker(this.props.startDate),
      datePickerSetup: datePickerSetup,
      inputDisabled: this.props.agentCreationFinished
    };
  }

  private datePickerEndDateProps() {
    let defaultProps = {
      onAdd: this.props.onEndDateClick,
      inputClassNames: ["text-input", "date-picker__input"],
      datePickerSetup: {
        startDate: moment().startOf("day").add(1, "days").toDate(),
        format: "dd.mm.yyyy",
        clearBtn: true
      }
    };
    if (this.props.endDate) {
      return _.extend(
        {},
        defaultProps,
        { selected: DateFormatter.formatForDatePicker(this.props.endDate) }
      );
    } else {
      return defaultProps;
    }
  }

  private tooltipStartDateProps() {
    const label = I18n.t("webapp.agents.edit.search_period.detail_start_date_label");
    const tooltipTitle =
      I18n.t("webapp.agents.edit.search_period.detail_start_date_tooltip_title");
    const tooltipBody =
      I18n.t("webapp.agents.edit.search_period.detail_start_date_tooltip_body");

    return {
      heading: label,
      tooltipHeadline: tooltipTitle,
      tooltipBody: <span dangerouslySetInnerHTML={{ __html: tooltipBody }} />
    };
  }

  private tooltipEndDateProps() {
    const label = I18n.t("webapp.agents.edit.search_period.detail_end_date_label");
    const tooltipTitle =
      I18n.t("webapp.agents.edit.search_period.detail_end_date_tooltip_title");
    const tooltipBody =
      I18n.t("webapp.agents.edit.search_period.detail_end_date_tooltip_body");

    return {
      heading: label,
      tooltipHeadline: tooltipTitle,
      tooltipBody: <span dangerouslySetInnerHTML={{ __html: tooltipBody }} />
    };
  }
}
