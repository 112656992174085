import agents from 'collections/agentsInstance'
import when from 'when'

var resolvePromise;
var promise = when.promise(function (resolve) {
  resolvePromise = resolve;
});

var fetched = false;
promise.fetch = function () {
  if (!fetched) {
    fetched = true;
    agents.fetch().done(function () {
      resolvePromise(agents);
    });
  }
};

export default promise;
