import * as React from 'react';
import I18n from 'i18n';

class ReaderNotificationConfigurationDeleteButtonView extends React.Component<any, {}> {
    render() {
        const deleteNotificationConfigurationText =
            I18n.t('webapp.reader_admin.edit.notification_configuration.remove');

        return (
            <a className="link-bar__right-item" onClick={this.onDeleteClick.bind(this)}>
              <i className="icon-trash"></i> {deleteNotificationConfigurationText}
            </a>
        );
    }

    onDeleteClick(e: any) {
        e.preventDefault();
        this.props.onDeleteClick();
    }
}

export default ReaderNotificationConfigurationDeleteButtonView;
