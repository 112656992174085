import template from 'text-loader!templates/reporting/wizard/editTitleSummary.html'
import StepView from 'views/reporting/wizard/stepView'
import 'tinymce'
import 'backbone.marionette'

var View = StepView.extend({
  template: template,
  className: "wizard-step rounded clearfix",
  triggers: {
    'keyup #title': 'change:title',
    'change #summary': 'change:summary',
    'click #editable': 'click:editable'
  },
  initialize: function () {
    this.on('change:title', () => {
      var value = this.$('#title').val();
      this.model.set({title: value});
    });
    this.on('change:summary', () => {
      var value = this.$('#summary').val();
      this.model.set({summary: value});
    });
    this.on('click:editable', () => {
      this.$('#editable').toggleClass("checked");
      var value = this.$('#editable').hasClass("checked");
      this.model.set({editable: value});
    });

    this.model.on('change:currentStep', function() {
      tinyMCE.triggerSave();
    });

    StepView.prototype.initialize.call(this);
  },
  serializeData: function () {
    var report = this.model.get('report');

    return {
      title: this.model.get('title'),
      summary: this.model.get('summary'),
      editable: this.model.get('editable'),
      canChangeEditability: report.isNew() || report.get('is_owner'),
      summaryMaxLength: 5000,
      titleMaxLength: 127
    };
  },
  updateForm: function () {
    this.$('#editable').removeClass("checked");

    if (this.model.get("editable") === true) {
      this.$('#editable').addClass("checked");
    }
  },
  onRender: function () {
    var view = this;
    view.updateForm();

    if (tinyMCE.getInstanceById('summary')) {
      tinyMCE.remove(tinyMCE.getInstanceById('summary'));
    }
    tinyMCE.init({
      mode : "exact",
      elements : "summary",
      theme : "advanced",

      theme_advanced_buttons1 : "italic,|,forecolor,backcolor,|,bullist,numlist,|,outdent,indent",
      theme_advanced_toolbar_location : "top",
      theme_advanced_toolbar_align : "left",
      theme_advanced_statusbar_location : "none",

      onchange_callback : function () {
        tinyMCE.triggerSave();
        view.trigger('change:summary');
      },

      width: '100%',
      height: '200px',
      content_css: '/css/tiny_mce_custom.css'
    });
  }
});

export default View;

