import Marionette from 'backbone.marionette';
import * as React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as template from 'text-loader!templates/newsroom/abstractPattern.html';
import Configuration from 'models/newsroom/configuration';
import PublicationTimelineOrder from 'views/newsroom/publicationTimelineOrder';
import PublicationTimeOptions from 'views/newsroom/publicationTimeOptions';

interface IAbstractPatternViewOptions {
  configuration: Configuration
  titleKey: string
  scheduleTimeLabel: string
}

class AbstractPatternView extends Marionette.Layout {
    initialize(options: IAbstractPatternViewOptions) {
        this.configuration = options.configuration;
        this.titleKey = options.titleKey;
        this.scheduleTimeLabel = options.scheduleTimeLabel;
    }

    serializeData() {
        return {
            titleKey: this.titleKey,
            scheduleTimeLabel: this.scheduleTimeLabel
        };
    }

    showPublicationTimeOptions(props: any) {
        const publicationTimeOptionsView = new ReactWrapper({
          getComponent: () => {
            return <PublicationTimeOptions {...props} />;
          }
        });
        this.publicationTimeOptions.show(publicationTimeOptionsView);
    }

    showPublicationTimelineOrder(props: any) {
        const publicationTimelineOrderView = new ReactWrapper({
          getComponent: () => {
            return <PublicationTimelineOrder {...props} />;
          }
        });
        this.publicationTimelineOrder.show(publicationTimelineOrderView);
    }

    showWeekdaySelection(view: any) {
        this.weekdaySelection.show(view);
    }

    showTimeSelector(view: any) {
        this.timeSelector.show(view);
    }

    displayTimeSelector() {
        this.ui.timeSelectorContainer.show();
    }

    hideTimeSelector() {
        this.ui.timeSelectorContainer.hide();
    }

    showHolidayExclusions(view: any) {
        this.holidayExclusions.show(view);
    }

    showDateExclusions(view: any) {
        this.dateExclusions.show(view);
    }

    showScheduleOptions(view: any) {
        this.scheduleOptions.show(view);
    }

    showSchedulingDetails() {
        this.ui.schedulingDetails.show();
    }

    hideSchedulingDetails() {
        this.ui.schedulingDetails.hide();
    }

    showAdditionalData(view: any) {
        this.additionalData.show(view);
    }

    hideAdditionalData() {
        this.additionalData.hide();
    }
}

AbstractPatternView.prototype.regions = {
    publicationTimeOptions: '[data-publication-time-options]',
    publicationTimelineOrder: '[data-publication-timeline-order]',
    scheduleOptions: '[data-schedule-options]',
    weekdaySelection: '[data-weekday-selection]',
    timeSelector: '[data-time-selector]',
    dateExclusions: '[data-date-exclusions]',
    holidayExclusions: '[data-holiday-exclusions]',
    additionalData: '[data-additional-data]'
};
AbstractPatternView.prototype.ui = {
    schedulingDetails: '[data-scheduling-details]',
    timeSelectorContainer: '[data-time-selector-container]'
};
AbstractPatternView.prototype.template = template;

export default AbstractPatternView;
