import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/dpa.html'
import conditionalSetToMetadata from 'libraries/conditionalSetToMetadata'
import _ from 'underscore'
import localizedLanguages from 'libraries/localizedLanguages'

var DpaView = Marionette.ItemView.extend({
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    return { metadata: this._metadata() };
  },
  _metadata: function () {
    var metadata = {};
    var languageCodes = this.agentResult.get('languages');

    var information = {
      industry: this.agentResult.get('industry'),
      located: this.agentResult.get('located'),
      poi: this.agentResult.get('poi'),
      ressort: this.agentResult.get('ressort'),
      genre: this.agentResult.get('genre'),
      author: this.agentResult.get('author')
    };
    if (!_.isEmpty(languageCodes)) {
      information.languages = localizedLanguages(languageCodes);
    }
    conditionalSetToMetadata(metadata, 'information', information);

    return metadata;
  }
});

export default DpaView;

