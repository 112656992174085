import Marionette from 'backbone.marionette'
import $ from 'jquery'
import viewTemplate from 'text-loader!templates/components/newsletterRecipientSelection.html'
import optionsTemplate from 'text-loader!templates/components/newsletterRecipientSelectionOptions.html'
import _ from 'underscore'
import select2CloseOnBlur from 'libraries/select2CloseOnBlur'
import 'jquery.select2'

var View = Marionette.ItemView.extend({
  template: viewTemplate,
  triggers: {
    'change select': 'change:select'
  },
  ui: {
    select: '[data-recipient-selection]'
  },
  initialize: function (options) {
    this.model = options.model;
    _.bindAll(this, 'updateOptions');
    _.bindAll(this, 'showSpacer');
    this.listenTo(this, 'change:select', () => {
      var selection = this.ui.select.val();
      this.model.set({selection: selection});
    });
    this.listenTo(this.model, 'change:options', this.updateOptions);

    this.listenTo(this.model, 'change:active', this.showSpacer);
    this.listenTo(this, 'render', this.onRender);
  },
  serializeData: function () {
    return {
      optGroups: this.model.get('options')
    };
  },
  onRender: function () {
    var select = this.ui.select;

    select.val(this.model.get('selection')).select2({
      containerCssClass: 'custom-select2'
    });
    select2CloseOnBlur(select);

    this.updateOptions();
  },
  updateOptions: function () {
    var html = Marionette.Renderer.render(optionsTemplate, this.serializeData());

    this.ui.select.html(html);
    this.ui.select.select2('enable').trigger('change');
  },
  showSpacer: function () {
    $('#dropdown-spacer').addClass('dropdown-spacer');
  }
});

export default View;

