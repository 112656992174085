/* eslint no-invalid-this: "off" */
import $ from 'jquery'
import App from 'app'
import vent from 'libraries/vent'
import EventLogger from 'libraries/eventLogger'
import Notificator from 'libraries/notificator'
import when from 'when'
import Report from 'models/report'
import MedienspiegelWizard from 'models/reporting/medienspiegelWizard'
import ExcelWizard from 'models/reporting/excelWizard'
import NewsletterWizard from 'models/reporting/newsletterWizard'
import agentsInstancePromise from 'models/agentsInstancePromise'
import usersInstancePromise from 'models/usersInstancePromise'
import contactGroupsInstancePromise from 'models/contactGroupsInstancePromise'
import contactsInstancePromise from 'models/contactsInstancePromise'
import Reports from 'collections/reports'
import Layout from 'views/reporting/layout'
import ReportsCollectionView from 'views/reporting/reportsCollectionView'
import ReportView from 'views/reporting/reportView'
import SidebarView from 'views/reporting/sidebarView'
import WizardView from 'views/reporting/wizardView'
import ReportPreviewView from 'views/reporting/reportPreviewView'
import NewsletterView from 'views/reporting/newsletterView'
import ReportEditView from 'views/reporting/reportEditView'
import I18n from 'i18n'

App.module("Reporting", {
  startWithParent: false,
  define: function () {
    EventLogger.logEventsOn(App.Reporting, 'Reporting');

    App.Reporting.name = 'Reports';

    App.Reporting.show = function () {
      $(this.layout.el).show();
    };

    App.Reporting.hide = function () {
      $(this.layout.el).hide();
    };

    App.Reporting.loadReports = function (finishedCallback) {
      this.collection.fetch({
        complete: finishedCallback,
        reset: true
      });
    };

    App.Reporting.createLayout = function () {
      this.layout = new Layout();
      this.layout.render();
      this.createSidebarView();
      this.createReportsListView();
      $('#container-content').append(this.layout.el);
    };

    App.Reporting.createSidebarView = function () {
      this.sidebarView = new SidebarView();
      this.sidebarView.on('clicked:new-medienspiegel', function () {
        App.Reporting.showMedienspiegelWizard();
      });
      this.sidebarView.on('clicked:new-excel-export', function () {
        App.Reporting.showExcelWizard();
      });
      this.sidebarView.on('clicked:new-newsletter', function () {
        App.Reporting.showNewsletterWizard();
      });
      this.sidebarView.on('clicked:list', function () {
        App.Reporting.showReportsList();
      });
      this.layout.sidebar.show(this.sidebarView);
    };

    App.Reporting.createReportsListView = function () {
      this.reportsListView = new ReportsCollectionView({
        collection: this.collection
      });
    };

    App.Reporting.showReportsList = function () {
      this.layout.main.show(this.reportsListView);
      this.sidebarView.activateEntry('list');
    };

    App.Reporting.showReport = function (report) {
      var view;
      if (report.get('type') === 'Newsletter') {
        view = new NewsletterView({ model: report });
      } else {
        view = new ReportView({ model: report });
      }
      this.layout.main.show(view);
      this.sidebarView.activateEntry('none');
    };

    App.Reporting.editReport = function (report) {
      var wizard, editView;

      report.editing_in_progress = true;
      report.store();

      switch (report.get('type')) {
      case 'Medienspiegel':
        wizard = this._wizardFactory(MedienspiegelWizard, {report: report});
        break;
      case 'ExcelReport':
        wizard = this._wizardFactory(ExcelWizard, {report: report});
        break;
      case 'Newsletter':
        wizard = this._wizardFactory(NewsletterWizard, {report: report});
        break;
      }
      editView = new ReportEditView({wizard: wizard});


      this.layout.main.show(editView);
      editView.createStepViews();
    };

    App.Reporting.newClipReport = function (params) {
      var report = new Report({
        title: params.type + " " + (new Date()).format(),
        topic_type: 'clip',
        topic_ids: params.topic_ids,
        type: params.type,
        group_by: 'none'
      });
      switch (report.get('type')) {
      case 'Medienspiegel':
        App.Reporting.showMedienspiegelWizard({report: report});
        break;
      case 'ExcelReport':
        report.set({title: "Excel-Report " + (new Date()).format()});
        App.Reporting.showExcelWizard({report: report});
        break;
      case 'Newsletter':
        App.Reporting.showNewsletterWizard({report: report});
        break;
      }
    };

    App.Reporting.previewReport = function (report) {
      var previewView;

      previewView = new ReportPreviewView({model: report});
      this.layout.main.show(previewView);
    };

    App.Reporting.showMedienspiegelWizard = function (opts) {
      var wizard = this._wizardFactory(MedienspiegelWizard, opts);
      var wizardView = new WizardView({wizard: wizard});
      this.layout.main.show(wizardView);
      wizardView.createStepViews();
      this.sidebarView.activateEntry('new-medienspiegel');
    };

    App.Reporting.showExcelWizard = function (opts) {
      var wizard = this._wizardFactory(ExcelWizard, opts);
      var wizardView = new WizardView({wizard: wizard});
      this.layout.main.show(wizardView);
      wizardView.createStepViews();
      this.sidebarView.activateEntry('new-excel-export');
    };

    App.Reporting.showNewsletterWizard = function (opts) {
      var wizard = this._wizardFactory(NewsletterWizard, opts);
      var wizardView = new WizardView({wizard: wizard});
      this.layout.main.show(wizardView);
      wizardView.createStepViews();
      this.sidebarView.activateEntry('new-newsletter');
    };

    App.Reporting._wizardFactory = function (WizardType, opts) {
      if (!opts) {
        opts = {};
      }
      opts.reportsCollection = this.collection;
      return new WizardType(opts);
    };

    App.Reporting.on("initialize:before", function () {
      this.registerInNavigation();
      this.loadContacts();
    });

    App.Reporting.on("loaded", function () {
      vent.commands.execute('navigation:loaded', 'reports');
    });

    App.Reporting.addInitializer(function (options) {
      App.Reporting.trigger('initialize:before');
      this.loadingDelay = options.delay || 0;

      this._waitForDependencies();

      this.reportsModuleVisited = false;
      this.initializeCollection();

      this.createLayout();

      vent.on("reporting:report:open", function (report) {
        App.Reporting.activate();
        App.Reporting.trigger('report:open', report);
      });

      this.on('report:open', function (report) {
        App.Reporting.showReport(report);
      });

      this.on('report:edit', function (report) {
        App.Reporting.editReport(report);
      });

      this.on('report:preview', function (report) {
        App.Reporting.previewReport(report);
      });

      this.listenTo(vent, 'reporting:new:report', function (args) {
        vent.commands.execute('navigation:activate', 'reports');
        App.Reporting.newClipReport(args);
      });

      this.showReportsList();

      App.Reporting.on('contactsLoaded', function () {
        App.Reporting._dependenciesPromise.done(function () {
          App.Reporting.loadReports(function () {
            App.Reporting.trigger('loaded');
          });
        });
      });
    });

    App.Reporting.addFinalizer(function () {
      this.hide();
      this.deregisterInNavigation();
    });

    App.Reporting.initializeCollection = function () {
      this.collection = new Reports();

      this.collection.on('open', function (report) {
        App.Reporting.trigger('report:open', report);
      });

      this.collection.on('edit', function (report) {
        App.Reporting.trigger('report:edit', report);
      });

      this.collection.on('new-from-template', function (report, new_report_type) {
        var new_report = report.clone();
        new_report.set({id: null, type: new_report_type});

        var opts = {useAsTemplate: true, report: new_report};

        if (new_report.get('type') === 'Medienspiegel')  {
          // respect default values of Medienspiegel report while creating
          // one from an ExcelReport
          if (report.get('type') === 'ExcelReport') {
            opts.report.set((new Report()).defaults);
          }

          App.Reporting.showMedienspiegelWizard(opts);
        } else if (new_report.get('type') === 'ExcelReport') {
          // Force group_by with none for ExcelReports
          opts.report.set({group_by: 'none' });
          App.Reporting.showExcelWizard(opts);
        } else if (new_report.get('type') === 'Newsletter') {
          App.Reporting.showNewsletterWizard(opts);
        }
      });

      this.collection.on('destroy', function () {
        Notificator.showNotification(I18n.t('webapp.reporting.notification_messages.report_deleted'));
        App.Reporting.showReportsList();
      });

      this.collection.on('destroyFailed', function () {
        Notificator.showErrorNotification(I18n.t('webapp.reporting.notification_messages.report_not_deleted'));
      });

      this.collection.on('deliver', function (report, onSuccess) {
        $.ajax({
          url: report.url() + '/deliver',
          type: 'GET',
          dataType: 'json',
          context: this,
          success: function () {
            Notificator.showNotification(I18n.t('webapp.reporting.notification_messages.sending_email'));
            onSuccess();
          },
          error: function () {
            Notificator.showNotification(I18n.t('webapp.reporting.notification_messages.document_delivery_error'));
          }
        });
      });

      this.collection.on('preview', function (report) {
        App.Reporting.trigger('report:preview', report);
      });

      this.collection.on('previewFailure', function () {
        Notificator.showNotification(I18n.t('webapp.reporting.notification_messages.report_loading_error'));
      });

      this.collection.on('previewNotReady', function () {
        Notificator.showNotification(I18n.t('webapp.reporting.notification_messages.document_not_ready'));
      });

      this.collection.comparator = function (report) {
        return -1 * (report.get('updated_at').getTime());
      };

      var reports = this.collection;
      this.collection.on('change:updated_at', function () {
        reports.sort();
      });

      reports.on('download_failed', function () {
        Notificator.showNotification(I18n.t('webapp.reporting.notification_messages.document_not_created'));
      });
    };

    App.Reporting.registerInNavigation = function () {
      vent.commands.execute('navigation:register', App.Reporting, 'reports');
      vent.commands.execute('navigation:loading', 'reports');
      vent.commands.setHandler('navigation:show:reports', function () {
        if (!App.Reporting.reportsModuleVisited) {
          App.Reporting.reportsModuleVisited = true;
          App.Reporting.collection.appendReports();
        }
        App.Reporting.show();
      });
      vent.commands.setHandler('navigation:hide:reports', function () { App.Reporting.hide(); });
    };

    App.Reporting.deregisterInNavigation = function () {
      vent.commands.execute('navigation:deregister', 'reports');
      vent.commands.removeHandler('navigation:show:reports');
      vent.commands.removeHandler('navigation:hide:reports');
    };

    App.Reporting.loadContacts = function () {
      window.setTimeout(
        function () {
          contactGroupsInstancePromise.fetch();
          contactsInstancePromise.fetch();
        },
        this.loadingDelay
      );
      when.join(
        contactGroupsInstancePromise,
        contactsInstancePromise
      ).done(
        function () {
          App.Reporting.trigger('contactsLoaded');
        },
        Notificator.showLoadError
      );
    };

    App.Reporting._waitForDependencies = function () {
      App.Reporting._dependenciesPromise = when.join(
        usersInstancePromise,
        agentsInstancePromise
      ).delay(this.loadingDelay);
    };

    return App.Reporting;
  }
});


