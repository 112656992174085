import Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import session from 'models/sessionInstance';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import Layout from 'views/layoutView'
import AgentSubscriptions from 'components/agentSubscriptions'
import users from 'collections/usersInstance'
import Notificator from 'libraries/notificator'
import I18n from 'i18n';

class EditAgentSubscriptionsController extends Marionette.Controller {
  initialize(opts) {
    this.agent = opts.agent
    this.view = new Layout();
    this.listenTo(this.view, 'render', () => this.renderView());
    this.listenTo(this.agent, 'change:subscribed_user_ids', () => this.renderView());

    this.subscribedUserIds = this.agent.get('subscribed_user_ids')
  }

  url() {
    const url =
      '/api/v3/agents/' + this.agent.get('id') + '/subscribed_users'
    return url
  }

  renderView(additionalOpts?: object) {
    const props = this.buildProps(additionalOpts)
    const view = new ReactWrapper({
      getComponent: () => (<AgentSubscriptions {...props} />)
    });
    this.view.showView(view);
  }

  buildProps(additionalProps?: object): AgentsSidebarComponentProps {
    const base = {
      agent: this.agent,
      users: users.models,
      subscribedUserIds: this.agent.get('subscribed_user_ids'),
      onUnSubscribeClick: (user) => this.onUnSubscribeClick(user),
      onSubscribeClick: (user) => this.onSubscribeClick(user)
    }

    return _.extend(base, additionalProps)
  }

  onUnSubscribeClick(user: User) {
    const self = this
    const userId = user.get('id')
    const ownerId = this.agent.get('user_id')
    const unsubscribingOwner = userId == ownerId
    const ownerIsCurrentUser =
      ownerId == users.currentUser.get('id')

    this.disableButtons()
    $.ajax({
      url: this.url() + '/' + user.get('id'),
      type: 'DELETE',
      dataType: 'json',
      success: function (data) {
        self.updateSubscribedUsers(data.user_ids)
        if (unsubscribingOwner && ownerIsCurrentUser) {
          self.agent.set({subscribed: false})
        }
      },
      error: function () {
        self.handleRequestError()
      }
    }).always(() => this.renderView())
  }

  disableButtons() {
    const noop = () => {}
    this.renderView({
      onSubscribeClick: noop,
      onUnSubscribeClick: noop
    })
  }

  onSubscribeClick(user: User) {
    const self = this

    const data = {
      id: user.get('id')
    }

    this.disableButtons()
    $.post(this.url(), data, function (data) {
      self.updateSubscribedUsers(data.user_ids)
    }).fail(() => {
      self.handleRequestError()
    }).always(() => this.renderView())
  }

  handleRequestError() {
    const key =
      'webapp.agents.edit.agent_subscriptions.request_error'
    Notificator.showNotification(I18n.t(key));
  }

  updateSubscribedUsers(userIds) {
    this.agent.set({subscribed_user_ids: userIds})
  }
}

export default EditAgentSubscriptionsController;
