import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/settings/contacts/contactGroupListItem.html'

var ContactGroupListItemView = Marionette.ItemView.extend({
  template: template,
  tagName: 'a',
  className: 'button-group__item button--pointing',
  triggers: {
    'click': 'clicked'
  },
  initialize: function () {
    this.listenTo(this.model, 'change', this._handleChange);
  },
  setSelectionState: function (isSelected) {
    this.$el.toggleClass('is-active', isSelected);
  },
  _handleChange: function () {
    this.trigger('changed');
  }
});

export default ContactGroupListItemView;

