/*
 * Logger object that supports log levels.
 *
 * You can call log/debug/info/warn/error methods on the object.
 * Multiple arguments are supported.
 *
 * You can set the logger level globally:
 *
 *   Logger.level = Logger.LEVELS.INFO;
 *
 * The object falls back to do nothing when there is no console to log
 * to. Extending it to log to other locations should be trivial.
 * 
 * I am using Function.prototype.apply a lot so variable length
 * argument lists work.
 */
var console = window.console;

var noopAdapter = {
  log: function () {},
  debug: function () {},
  info: function () {},
  warn: function () {},
  error: function () {}
};

var consoleAdapter = {
  log: function (...args) { console.log(...args); },
  debug: function (...args) { console.debug(...args); },
  info: function (...args) { console.info(...args); },
  warn: function (...args) { console.warn(...args); },
  error: function (...args) { console.error(...args); }
};

var adapter;
if (console) {
  adapter = consoleAdapter;
} else {
  adapter = noopAdapter;
}

var LEVELS = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
  SILENCE: 4
};

var Logger = {
  LEVELS: LEVELS,
  level: LEVELS.DEBUG,

  log: function (...args) {
    if (Logger.level < LEVELS.SILENCE) {
      adapter.log(...args);
    }
  },
  debug: function (...args) {
    if (Logger.level <= LEVELS.DEBUG) {
      adapter.debug(...args);
    }
  },
  info: function (...args) {
    if (Logger.level <= LEVELS.INFO) {
      adapter.info(...args);
    }
  },
  warn: function (...args) {
    if (Logger.level <= LEVELS.WARN) {
      adapter.warn(...args);
    }
  },
  error: function (...args) {
    if (Logger.level <= LEVELS.ERROR) {
      adapter.error(...args);
    }
  }
};

export default Logger;
