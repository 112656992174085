import Backbone from 'backbone';
import TimelineMonth from 'models/timeline/timelineMonth';

class TimelineMonths extends Backbone.Collection {
    initialize(timelineYear) {
        this.timelineYear = timelineYear;
    }
    comparator(month) {
        return -month.get('date').getTime();
    }
    findMonthContaining(day) {
        return this.find(function (month) {
            return month.includes(day);
        });
    }
}

TimelineMonths.prototype.model = TimelineMonth;

export default TimelineMonths;
