/* eslint no-invalid-this: "off" */
import Deferred from 'libraries/deferred'

var identifiers = {};
export default function (identifier, callback) {
  return function () {
    if (identifiers[identifier]) {
      return;
    } else {
      identifiers[identifier] = true;
      var self = this;
      Deferred.async(function () {
        delete identifiers[identifier];
        callback.call(self);
      });
    }
  };
}

