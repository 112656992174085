var closeOnBlur = function ($element) {
  var timeout;
  var blurHandler = function () {
    timeout = window.setTimeout(function () {
      $element.select2('close');
    }, 200);
  };
  $element.on('select2-blur', blurHandler);
  var focusHandler = function () {
    if (timeout) {
      window.clearTimeout(timeout);
      timeout = null;
    }
  };
  $element.on('select2-focus', focusHandler);
  $element.one('select2-destroy', function () {
    $element.off('select2-blur', blurHandler);
    $element.off('select2-focus', focusHandler);
  });
};
export default closeOnBlur;

