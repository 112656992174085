import Marionette from 'backbone.marionette';
import template from
    'text-loader!templates/settings/contacts/contactGroupDomainListItem.html';

class ContactGroupDomainListItemView extends Marionette.CompositeView {
    serializeData() {
        return { name: this.model.get('name') };
    }
}
ContactGroupDomainListItemView.prototype.template = template;
ContactGroupDomainListItemView.prototype.tagName = "li";
ContactGroupDomainListItemView.prototype.className = "input-group__item";
ContactGroupDomainListItemView.prototype.triggers = {
    'click [data-action=remove]': 'clicked:remove'
};

export default ContactGroupDomainListItemView;
