import * as React from "react";
import FroalaEditor from "react-froala-wysiwyg";
import I18n from "i18n";
import "libraries/froalaConfig";
interface Props {
  text: string;
  onEdit(text: string): void;
}

export default function EditorConfig({ text, onEdit }: Props) {
  return (
    <FroalaEditor
      config={{
        language: I18n.locale.split("-")[0],
        key: "bTYPASIBGMWC1YLMP==",
        toolbarInline: true,
        editorClass:
          "froala textarea textarea--xxlarge wysiwyg-summary-content",
        charCounterCount: false,
        heightMin: 160,
        heightMax: "100%",
        shortcutsEnabled: ["bold", "italic", "undo", "redo"],
        toolbarVisibleWithoutSelection: false,
        htmlAllowedTags: [
          "ul",
          "ol",
          "li",
          "hr",
          "h2",
          "span",
          "em",
          "strong",
          "p",
          "normal",
          "br",
          "a"
        ]
      }}
      onModelChange={onEdit}
      model={text}
      tag="textarea"
    />
  );
}
