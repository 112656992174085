import * as React from "react";

interface Props {
  header?: string
  children: any
}
export const DetailViewContainer = (props: Props) => {
  const headline = () => {
    if (props.header) {
      return (
        <h1 className="heading-xlarge mbs mtl">{props.header}</h1>
      )
    }
  }

  return (
    <div className="lightened-container lifted-container pts phl pbl">
      {headline()}
      {props.children}
    </div>
  )
}

export default DetailViewContainer
