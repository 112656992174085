import * as React from "react";
import { IEsApiResultAttachment } from "models/esResult";
import I18n from "i18n";
import * as _ from "underscore";

export default class ResultAttachmentView extends React.Component<IEsApiResultAttachment, {}> {
  render() {
    return (
      <li>
        <a href={this.props.url}
           title={this.fullName()}
           target="_blank"
           className="file-list__link">
          <span className="file-list__name">
            <i className="icon-download"></i> {this.name()}
          </span>
          {" "}
          <span className="file-list__meta"> {this.fileInfo()}
          </span>
        </a>
      </li>
    );
  }

  private fullName(): string | undefined {
    if (this.props.caption) {
      return this.props.caption;
    } else if (this.props.file_name) {
      return this.props.file_name;
    }
  }

  private name(): string | JSX.Element {
    let fullName = this.fullName();
    if (fullName) {
      return fullName;
    } else {
      return <i>{I18n.t("webapp.components.ClipAttachmentList.unnamed")}</i>;
    }
  }

  private fileInfo(): string {
    let info = _.compact([this.extension(), this.size()]);
    if (info.length > 0) {
      return `(${info.join(", ")})`;
    } else {
      return "";
    }
  }

  private extension(): string | undefined {
    if (this.props.extension) {
      return this.props.extension.toLowerCase();
    }
  }

  private size(): string | undefined {
    if (this.props.size) {
      return I18n.toHumanSize(
        this.props.size, { format: I18n.t("number.human.storage_units.format") }
      );
    }
  }
}
