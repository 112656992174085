import Marionette from 'backbone.marionette';
import ReaderNotificationConfigurationView from 'views/newsroom/readerNotificationConfigurationView';
import I18n from 'i18n';
import * as _ from 'underscore';
import ReaderNotificationTrigger from 'models/newsroom/readerNotificationTrigger';
import ReaderNotificationSchedules from "collections/newsroom/readerNotificationSchedules";
import ReaderNotificationSchedule from 'models/newsroom/readerNotificationSchedule';
import Validations from 'libraries/validations';
import { AvailableButtons }
    from 'views/newsroom/readerNotificationConfigurationSenderButtonsView';
import { SenderDefaultValues }
    from 'views/newsroom/readerNotificationConfigurationSenderFormView';
import session from 'models/sessionInstance';
import Notificator from 'libraries/notificator'

interface ITemplateOption {
  text: string;
  value: string;
  enabled: boolean;
}

class ReaderNotificationConfigurationController extends Marionette.Controller {
    initialize(options: any) {
        this.configuration = options.configuration;
        this.notificationConfiguration = options.notificationConfiguration;
        this.view = new ReaderNotificationConfigurationView();
        if (options.selectedTriggerIds) {
            this.selectedTriggers =
                this.triggersFromIds(options.selectedTriggerIds);
        } else {
            this.selectedTriggers = [];
        }
        this.registerListeners();
    }

    private registerListeners() {
        this.listenTo(this.view, 'render', () => { this.renderView({}); });
        this.listenTo(this.notificationConfiguration, 'changed:name', () => {
            this.saveNotificationConfiguration();
        });
    }

    renderView(props: any) {
        let fullProps = this.notificationConfigurationDetailProps();
        if (props !== undefined) {
            fullProps = _.extend({}, fullProps, props);
        }
        this.view.renderView(fullProps);
    }

    notificationConfigurationDetailProps() {
        const notificationConfigurationCount =
            this.configuration.notification_configurations.length;
        const senderProps = this.senderProps();

        return {
            displayDeleteButton: notificationConfigurationCount > 1,
            onDeleteClick: () => {
                const nc = this.notificationConfiguration;
                nc.destroy();
                this.trigger('reader-notification-configuration-closed');
            },
            editableTitleViewProps: {
                title: this.notificationConfiguration.get('name'),
                titleFieldName: 'name',
                model: this.notificationConfiguration
            },
            dropdownProps: this.templateDropdownProps(),
            triggersProps: this.triggerProps(),
            triggerNotesProps: this.triggerNotesProps(),
            senderButtonGroupProps: senderProps.senderButtonGroupProps,
            senderFormProps: senderProps.senderFormProps,
            holidayExclusionsProps: this.holidayExclusionProps(),
            dateExclusionsProps: this.dateExclusionsViewProps(),
            email: this.notificationConfiguration.get('sender_email'),
            notificationConfigurationId: this.notificationConfiguration.get('id')
        };
    }

    private senderProps() {
        const onInputBlurKeyup = (email: string, name: string) => {
            if (Validations.isEmailValid(email) &&
                Validations.isPresent(name)) {
                const nc = this.notificationConfiguration;
                nc.set('sender_email', email);
                nc.set('sender_name', name);
                this.saveNotificationConfiguration();
            }
        };
        const onButtonClick = (button: any) => {
            const nc = this.notificationConfiguration;

            if (AvailableButtons.bluereport === button) {
                nc.set('sender_email', SenderDefaultValues.email());
                nc.set('sender_name', SenderDefaultValues.name());
                this.saveNotificationConfiguration();
                this.renderView({
                    senderButtonGroupProps: {
                        activeButton: AvailableButtons.bluereport,
                        onButtonClick: onButtonClick
                    },
                    senderFormProps: {
                        hidden: true,
                        name: SenderDefaultValues.email(),
                        email: SenderDefaultValues.name(),
                        onInputBlurKeyup: onInputBlurKeyup
                    }
                });
            } else {
                this.renderView({
                    senderButtonGroupProps: {
                        activeButton: AvailableButtons.custom,
                        onButtonClick: onButtonClick
                    },
                    senderFormProps: {
                        hidden: false,
                        name: nc.get('sender_name'),
                        email: nc.get('sender_email'),
                        onInputBlurKeyup: onInputBlurKeyup
                    }
                });
            }
        };

        const nc = this.notificationConfiguration;
        const senderName = nc.get('sender_name');
        const senderEmail = nc.get('sender_email');

        if (senderEmail !== SenderDefaultValues.email() ||
            senderName !== SenderDefaultValues.name()) {
            return {
                senderButtonGroupProps: {
                    activeButton: AvailableButtons.custom,
                    onButtonClick: onButtonClick
                },
                senderFormProps: {
                    hidden: false,
                    name: senderName,
                    email: senderEmail,
                    onInputBlurKeyup: onInputBlurKeyup
                }
            };
        } else {
            return {
                senderButtonGroupProps: {
                    activeButton: AvailableButtons.bluereport,
                    onButtonClick: onButtonClick
                },
                senderFormProps: {
                    hidden: true,
                    name: SenderDefaultValues.email(),
                    email: SenderDefaultValues.name(),
                    onInputBlurKeyup: onInputBlurKeyup
                }
            };
        }
    }

    private templateDropdownProps() {
        let options = session
            .get('notification_templates')
            .map(function (template: any) {
                let key = template.config.namespace + '.name';
                let translated = I18n.t(key);
                return {
                    text: translated,
                    value: template.config.name,
                    enabled: true
                };
            });
        let forceSelected = undefined;
        if (this.dateExclusionsDisabled()) {
          let forceSelectedOption = _.find(options, (option: ITemplateOption) => {
            return option.value === "reader_content";
          });
          forceSelected = forceSelectedOption ? forceSelectedOption.value : undefined;
          options = _.map(options, (option: ITemplateOption) => {
            if (option.value === "reader_content") {
              option.enabled = true;
            } else {
              option.enabled = false;
            }
            return option;
          });
        }
        const templateKey =
              forceSelected ||
              this.notificationConfiguration.get('template_key') ||
              options[0].value;

        return {
            options: options,
            selectedOption: templateKey,
            onOptionChange: (newOption: string) => {
                const nc = this.notificationConfiguration;
                nc.set('template_key', newOption);
                this.saveNotificationConfiguration();
            },
            small: true
        };
    }

    private triggerNotesProps() {
      return {
        triggers: this.notificationConfiguration.triggers,
        editorialReader: this.configuration.editorialReader()
      }
    }

    private triggerProps() {
        return {
            onTriggerViewOpened: (trigger: ReaderNotificationTrigger) => {
                this.selectedTriggers = this.selectedTriggers.concat(trigger);
            },
            onTriggerViewClosed: (trigger: ReaderNotificationTrigger) => {
                this.selectedTriggers = _.without(this.selectedTriggers, trigger);
            },
            onAddTriggerSelectionClick: (triggerType: string) => {
                if (_.contains(["archive_date_published", "new_results"], triggerType)) {
                    let trigger = new ReaderNotificationTrigger({
                        reader_notification_configuration_id:
                            this.notificationConfiguration.get("id"),
                        trigger_type: triggerType
                    });
                    this.selectedTriggers = this.selectedTriggers.concat(trigger);
                    this.notificationConfiguration.triggers.add(trigger);

                    if (triggerType === "new_results") {
                      this.notificationConfiguration.set("excluded_dates", []);
                      this.notificationConfiguration.set("excluded_holidays", []);
                      this.notificationConfiguration.set("template_key", "reader_content")
                      this.saveNotificationConfiguration();
                      this.renderView({
                        selectedTriggers: this.selectedTriggers,
                        dateExclusionProps: { excludedDates: [] }
                      });
                    } else {
                      this.renderView({selectedTriggers: this.selectedTriggers});
                    }

                    const params = {
                        url: trigger.url(),
                        attrs: trigger.attributes
                    }
                    this.saveModel(trigger, params);
                } else {
                    let schedule = new ReaderNotificationSchedule({
                        reader_notification_configuration_id:
                            this.notificationConfiguration.get('id')
                    });
                    this.selectedTriggers = this.selectedTriggers.concat(schedule);
                    this.notificationConfiguration.schedules.add(schedule);
                    this.renderView({ selectedTriggers: this.selectedTriggers });

                    const params = {
                        url: schedule.url(),
                        attrs: schedule.attributes
                    }
                    this.saveModel(schedule, params);
                }
            },
            onTriggerDestroy: (trigger: ReaderNotificationTrigger) => {
                this.notificationConfiguration.triggers.remove(trigger);
                this.selectedTriggers = _.without(this.selectedTriggers, trigger);
                const params = {
                    url: trigger.url(),
                    method: 'destroy'
                }
                this.destroyModel(trigger, params);
                this.renderView({ selectedTriggers: this.selectedTriggers });
            },
            onScheduleDestroy: (schedule: ReaderNotificationSchedule) => {
                this.notificationConfiguration.schedules.remove(schedule);
                this.selectedTriggers = _.without(this.selectedTriggers, schedule);
                const params = {
                    url: schedule.url(),
                    method: 'destroy'
                }
                this.destroyModel(schedule, params);
                this.renderView({ selectedTriggers: this.selectedTriggers });
            },
            onWeekdaySelection: (values: number[], schedule: ReaderNotificationSchedule) => {
                const schedules =
                    this.notificationConfiguration.schedules;
                const notificationSchedule =
                    schedules.findWhere({ id: schedule.id });

                if (notificationSchedule) {
                  let collection: any = schedule.collection;
                    notificationSchedule.set('weekdays', values);
                    collection.notificationConfiguration.trigger(
                      'change',
                      this.notificationConfiguration
                    );
                }

                const params = {
                    url: schedule.url(),
                    attributes: schedule.attributes
                }
                this.saveModel(schedule, params, { weekdays: values });
            },
            onTimeChange: (time: number, schedule: ReaderNotificationSchedule) => {
                const schedules =
                    this.notificationConfiguration.schedules;
                const notificationSchedule =
                    schedules.findWhere({ id: schedule.id });
                if (notificationSchedule) {
                  let collection: any = schedule.collection;
                    notificationSchedule.set('time', time);
                    collection.notificationConfiguration.trigger(
                      'change',
                      this.notificationConfiguration
                    );
                }

                const params = {
                    url: schedule.url(),
                    attributes: schedule.attributes
                }
                this.saveModel(schedule, params, { time: time });
            },
            selectedTriggers: this.selectedTriggers,
            triggers: this.notificationConfiguration.triggers,
            triggerTypes: session.availableNotificationTriggerTypes(),
            schedules: this.notificationConfiguration.schedules,
            editorialReader: this.configuration.editorialReader()
        };
    }

    private selectedTriggerIds() {
        return _.map(this.selectedTriggers, (trigger: any) => {
            return trigger.get('id');
        });
    }

    private triggersFromIds(ids: number[]) {
        const triggers: any = _.filter(
            this.notificationConfiguration.triggers.models,
            (trigger: any) => {
                return _.contains(ids, trigger.get('id'));
            }
        );
        const schedules: any = _.filter(
            this.notificationConfiguration.schedules.models,
            (schedule: any) => {
                return _.contains(ids, schedule.get('id'));
            });
        return [].concat(triggers).concat(schedules);
    }

    private holidayExclusionProps() {
        return {
            selectionDisabled: this.dateExclusionsDisabled(),
            excludedHolidays:
                this.notificationConfiguration.get('excluded_holidays'),
            onHolidayChange: (excludedHolidays: any) => {
                this.notificationConfiguration
                    .set('excluded_holidays', excludedHolidays);
                this.saveNotificationConfiguration();
            }
        };
    }

    private dateExclusionsDisabled(): boolean {
        const triggers: ReaderNotificationTrigger[] =
          this.notificationConfiguration.triggers.models;
        const triggerTypes: string[] = _.map(triggers, (trigger) => {
          return trigger.get("trigger_type");
        });
      return _.contains(triggerTypes, "new_results");
    }

    private dateExclusionsViewProps() {
        return {
            selectionDisabled: this.dateExclusionsDisabled(),
            excludedDates: this.notificationConfiguration.get('excluded_dates'),
            onDateExclusionRemove: (excludedDate: any) => {
                const excludedDates = this.notificationConfiguration.get('excluded_dates');
                const currentExcludedDates = excludedDates || [];
                const updatedExcludedDates = _.without(currentExcludedDates, excludedDate);
                this.notificationConfiguration.set('excluded_dates', updatedExcludedDates);
                this.saveNotificationConfiguration();
                this.renderView({
                    dateExclusionProps: {
                        excludedDates: updatedExcludedDates
                    }
                });
            },
            onDateExclusionAdd: (excludedDate: any) => {
                const excludedDates = this.notificationConfiguration.get('excluded_dates');
                const newExcludedDates = excludedDates || [];
                newExcludedDates.push(excludedDate);
                this.notificationConfiguration.set('excluded_dates', newExcludedDates);
                this.saveNotificationConfiguration();
                this.renderView({
                    dateExclusionProps: {
                        excludedDates: newExcludedDates
                    }
                });
            }
        };
    }

    private saveNotificationConfiguration() {
        const debugParams = {
            url: this.notificationConfiguration.url(),
            attrs: this.notificationConfiguration.attributes
        }
        return this.saveModel(this.notificationConfiguration, debugParams);
    }

    private handleError(error: any, debugParams: any) {
        this.trigger('reader-notification-configuration-reload',
                     this.notificationConfiguration,
                     error,
                     debugParams,
                     this.selectedTriggerIds());
    }

    private destroyModel(model: Backbone.Model, debugParams: any) {
        return model.destroy().fail((error: any) => {
            this.handleError(error, debugParams);
        });
    }

    private saveModel(model: Backbone.Model, debugParams: any, params?: any) {
        return model.save(params).fail((error: any) => {
            this.handleError(error, debugParams);
        });
    }

}

export default ReaderNotificationConfigurationController;
