import Marionette from "backbone.marionette";
import template from "text-loader!templates/agents/metadata/sopranoAdminChFederalGazetteView.html";
import localizedLanguages from "libraries/localizedLanguages";

class SopranoAdminChFederalGazetteView extends Marionette.ItemView {
  initialize() {
    this.agentResult = this.options.agentResult;
  }

  serializeData() {
    const languageCodes = this.agentResult.get('languages');
    const clipMetadata = this.agentResult.get('clip_metadata');

    return {
      languages: localizedLanguages(languageCodes),
      publishTime: this.agentResult.get('publish_time') * 1000,
      author: clipMetadata.responsible_office,
      source: clipMetadata.source_name,
      federalGazetteNumber: clipMetadata.federal_gazette_number
    };
  }
}

SopranoAdminChFederalGazetteView.prototype.template = template;

export default SopranoAdminChFederalGazetteView;
