import I18n from 'i18n'
import _ from 'underscore'
import $ from 'jquery'
import 'bootstrap-datepicker'

var datepicker = $.fn.datepicker.noConflict();
$.fn.bootstrapDatepicker = datepicker;

var translations = I18n.translations;

_.each(_.keys(translations), function (locale) {
  datepicker.dates[locale] = {
    days: I18n.t("date.day_names", { locale: locale }),
    daysShort: I18n.t("date.abbr_day_names", { locale: locale }),
    daysMin: I18n.t("date.abbr_day_names", { locale: locale }),
    months: I18n.t("date.month_names", { locale: locale }).slice(1),
    monthsShort: I18n.t("date.abbr_month_names", { locale: locale }).slice(1),
    today: I18n.t("webapp.date.today", { locale: locale }),
    format: I18n.t("date.formats.default_alternative", { locale: locale }),
    titleFormat: I18n.t("date.formats.default_alternative", { locale: locale }),
    clear: "Clear"
  };
});

export default datepicker;
