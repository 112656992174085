import TimelineController from 'controllers/timelineController';
import ReaderResultListController from 'controllers/newsroom/readerResultListController';
import ReaderResultsSelectionCollection from 'collections/newsroom/readerResultsSelectionCollection';
import ReaderResultApiCollection from 'collections/newsroom/readerResultApiCollection';
import ReaderTimelineView from 'views/timeline/reader/readerTimelineView';
import app from 'app';
import _ from 'underscore';
import when from 'when';
import vent from 'libraries/vent';
import Logger from 'libraries/logger'
import pusherWrapper from 'models/pusherWrapper';

class ReaderTimelineController extends TimelineController {
    initialize(options) {
        TimelineController.prototype.initialize.apply(this, [options]);
        this.listenTo(
            vent,
            'readerResultsMoved',
            this.handleResultMovement
        );
        this.listenTo(
            vent,
            'dragStart',
            this._handleDragStart
        );
        this.listenTo(vent, 'dragStop', this._handleDragStop);

        this.listenTo(pusherWrapper,
                      'agent_result_changed',
                      this._handleAgentResultChange);
    }

    _handleAgentResultChange(data) {
        if (this._isCategoryWithIdSelected(data.reader_category_ids)) {
          var self = this;
          _.each(data.reader_result_ids, (id) => {
            var readerResult =
                self.shownListItems.get(id);
            if (!_.isUndefined(readerResult)) {
              var agentResult =
                  self.shownListItems.get(id).get('agent_result')
              agentResult.fetch().done(() => {
                agentResult.trigger('updatedByOtherUser')
              })
            }
          })
        }
    }

    _isCategoryWithIdSelected(readerCategoryIds) {
      return _.contains(readerCategoryIds,
                        this.options.readerCategoryId);
    }

    hideSelectedItemViews(draggedReaderResult) {
        _.each(this.selectedItemViews(), function(view) {
            if (draggedReaderResult.get('id') !== view.model.get('id')) {
                view.$el.hide();
            }
        });
    }

    selectedItemViews() {
        let selectionIds = this.selection.pluck('id');
        let views = _.map(this.listControllers, function(listController) {
            return _.filter(listController.collectionItemViews(), function(view) {
                return _.contains(selectionIds, view.model.get('id'));
            });
        });

        return _.flatten(views);
    }

    getListControllerId(readerResultId) {
        let dayView = this.listItemDay[readerResultId];
        //controller of given day
        let dayController = this.listControllers[dayView.cid];
        return dayController.listControllerId;
      }
    
    
    handleResultMovement(attrs) {
        let resultsByArchiveDate = {};
        const destinationController = this._findListControllerById(
          attrs.destinationControllerId
        );
        if (destinationController) {
          this.selection.each((result) => {
            const id = result.id;
            const listControllerId = this.getListControllerId(id);
            if (!resultsByArchiveDate[listControllerId]) {
              resultsByArchiveDate[listControllerId] = [];
            }
            resultsByArchiveDate[listControllerId].push(result);
          });
          _.forEach(resultsByArchiveDate, (results, listControllerId) => {
            const controller = this._findListControllerById(listControllerId);
            if (listControllerId === destinationController.listControllerId) {
              controller.moveResultsInCollection(results, attrs);
            } else {
              controller.removeResultsFromCollection(results);
              destinationController.addResultsToCollection(results, attrs.newIndex);
            }
          });
          let selectedItems = [...this.selection.models];
          this.selectionManager.clearSelection();
          this.setShownListItems();
          this.selectionManager.setSelection(selectedItems);
        }
      }

    _handleDragStart(draggedReaderResult) {
        // something is selected but we start dragging on some unselected
        // result
        if (_.isEmpty(this.listControllers)) {
            return;
        }
        if (this.selection.length > 0 &&
            this._isDraggingNonSelectedResult(draggedReaderResult)) {
            this.selectionManager.clearSelection();
            this.selectionManager.addToSelection(draggedReaderResult);
        } else if (this.selection.length === 0) {
            this.selectionManager.addToSelection(draggedReaderResult);
        }
        this.dragStartScroll();
        this.hideSelectedItemViews(draggedReaderResult);
        this.draggedResults = new ReaderResultsSelectionCollection();
        this.draggedResults.add(this.selection.models);
    }

    dragStartScroll() {
        const selectedItems = this.selectedItemViews();
        let selectionHeight = 0;
        const container = this.view.$el.parent();
        const cursorYCoOrd = this.view.mousePositionY;
        let selectionHeightAboveCursor = 0; 
        selectedItems.forEach((item) => {
            const yTop = item.$el.offset().top;
            const itemHeight = item.$el.height();
            selectionHeight += itemHeight;
            if (yTop < cursorYCoOrd) {
                if (yTop + itemHeight < cursorYCoOrd) {
                    selectionHeightAboveCursor += itemHeight;
                } else {
                    selectionHeightAboveCursor += cursorYCoOrd - yTop;
                }
            }
        });
        //dragging while at the top part of the timeline
        const scrollHeightNeeded =
          container.scrollTop() - selectionHeightAboveCursor ;
        if (scrollHeightNeeded < 0) {
          this.view.setTopScrollPlaceholderHeight(-scrollHeightNeeded);
        }
        //Dragging while at the bottom part of the timeline
        const hiddenTimelineBottomHeight =
          this.view.$el.height() - container.height() - container.scrollTop();
        const cursorToBottomSelection =
            selectionHeight - selectionHeightAboveCursor ;
        
        if (hiddenTimelineBottomHeight < cursorToBottomSelection) {
          this.view.setBottomScrollPlaceholderHeight(
            cursorToBottomSelection - hiddenTimelineBottomHeight
          );
        }
        container.scrollTop(scrollHeightNeeded);
    }

    _handleDragStop() {
        this.view.setTopScrollPlaceholderHeight(0);
        this.view.setBottomScrollPlaceholderHeight(0);
    }

    _isDraggingNonSelectedResult(draggedResult) {
        return !_.contains(this.selection.pluck('id'), draggedResult.get('id'));
    }

    getTime(readerResult) {
        let agentResult = readerResult.get('agent_result');
        return new Date(agentResult.attributes.created_at);
    }

    getDayContaining(date) {
        let archiveDate = this.view.findModelContainingTime(date);
        if (!archiveDate) {
            throw new Error('Could not find archive date in the timeline');
        }

        return archiveDate;
    }

    maybeSpillOver() {
        // Disabled
    }

    registerArchiveDayView(archiveDayView) {
        this.dayViews[archiveDayView.model.cid] = archiveDayView;
    }

    toggleArchiveDateList(archiveDate) {
        this.toggleList(null, null, archiveDate);
    }

    _initializeTimelineView(options) {
        this.view = new ReaderTimelineView({
            readerConfigurationId: options.readerConfigurationId
        });
    }

    _handleSelectionRemoval(listItem) {
        var listItemDay = this.listItemDay[listItem.id];
        if (_.isUndefined(listItemDay) ||
            _.isUndefined(listItemDay.cid) ||
            _.isUndefined(this.listControllers[listItemDay.cid])) {
            return;
        }

        var dayId = listItemDay.cid;
        var listController = this.listControllers[dayId];
        listController.markAsDeselected(listItem);
    }

    _registerListeners() {
        this.listenTo(this.view, 'archiveDate:clicked', this.toggleArchiveDateList);
        this.listenTo(this.view, 'archiveDate:clickedBell',
                      this._showNotificationModal);
        this.listenTo(this.view, 'archiveDate:close', this.dayViewClosing);
        this.listenTo(this.view, 'archiveDate:render', this.registerArchiveDayView);
        this.listenTo(this.selection, 'add', this.notifySelect);
    }

    _showNotificationModal() {
        Logger.debug('notification modal')
    }

    _bindShortcuts() {}

    _unbindShortcuts() {}

    _initialFetch() {
        let loadedPromise =
            when(this.view.collection.fetch()).then((results) => {
                let expandModelIds =
                    _.pluck(results.slice(0, this.expandInitialDaysNumber),
                            'id');

                let views = this.view.findViewsWithModelIds(expandModelIds);
                this.showTimeline();
                return when.all(_.map(views, (articleDateView) => {
                    return this.showList(articleDateView,
                                         undefined,
                                         undefined,
                                         true);
                }));
            });

        loadedPromise.done(() => {
            if (app.Navigation.activeModuleName === 'newsroom') {
                this.selectFirstResult();
            }
        });
    }

    selectFirstResult() {
        const orderedKeys = this.getSortedListKeys();
        let firstItem;
        _.find(orderedKeys, (key) => {
            const items = this.listControllers[key].shownItems();
            if (items.length === 0) { return false; }
            firstItem = _.max(items, (item) => {
                return item.get('position');
            });
            return true;
        });

        if (firstItem) {
            this.selectionManager.setSelection([firstItem]);
        }
    }

    _expandNewRecords(newRecords, oldestTime, newestTime) {
        this.expandOrHideDays(oldestTime, newestTime, newRecords);
    }

    _maybeScroll() {
    }
}

ReaderTimelineController.prototype.ListItemCollection = ReaderResultsSelectionCollection;
ReaderTimelineController.prototype.ListItemApiCollection = ReaderResultApiCollection;
ReaderTimelineController.prototype.ListController = ReaderResultListController;
ReaderTimelineController.prototype.listOptionKeys = [
    'readerConfigurationId',
    'readerCategoryId',
    'readerArchiveDateId'
];
ReaderTimelineController.prototype.moduleName = 'reader';
ReaderTimelineController.prototype.expandInitialDaysNumber = 3;

export default ReaderTimelineController;
