import App from 'app'
import vent from 'libraries/vent'
import PNotify from 'libraries/pnotify'

App.module("Notifications", {
  startWithParent: true,
  define: function () {
    this.addInitializer(function () {
      vent.commands.setHandler("notifications:show", function (message) {
        PNotify.show(message);
      });
      vent.commands.setHandler("notifications:showError", function (message) {
        PNotify.error(message);
      });
      vent.commands.setHandler("notifications:showSlow", function (message) {
        PNotify.slow(message);
      });
      vent.commands.setHandler("notifications:showPermanent", function (message, identifier) {
        PNotify.permanent(message, identifier);
      });
      vent.commands.setHandler("notifications:clearPermanent", function (identifier) {
        PNotify.permanentClear(identifier);
      });
    });

    this.addFinalizer(function () {
      vent.commands.removeHandler('notifications:show');
      vent.commands.removeHandler('notifications:showError');
      vent.commands.removeHandler('notifications:showSlow');
      vent.commands.removeHandler('notifications:showPermanent');
      vent.commands.removeHandler('notifications:clearPermanent');
    });
  }
});
