import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/metadata/twitter.html'
import agentResultReadingTime from 'libraries/agentResultReadingTime'

var twitterView = Marionette.Layout.extend({
  regions: {
  },
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    return {
      metadata: this.metadata()
    };
  },
  metadata: function () {
    var agentResult = this.agentResult;
    var data = {
      engagement: {
        retweets_count: this.agentResult.get('clip_metadata').retweets_count,
        favourites_count: this.agentResult.get('clip_metadata').favourites_count
      }
    };
    if (agentResult.isTwitterClip() && agentResult.get('author') !== null) {
      var author = agentResult.get('author');
      data.reach = {
        followers_count: author.properties.followers_count
      };
      data.information = {
        username: author.properties.user_screen_name,
        user_description: author.properties.user_description,
        user_location: author.properties.user_location,
        statuses_count: author.properties.statuses_count,
        reading_time: agentResultReadingTime(agentResult)
      };
    }
    return data;
  }
});

export default twitterView;

