import * as React from "react";
import ArticleToReaderDropdown from "./ArticleToReaderDropdown";
import readerConfigurations from "collections/newsroom/configurationsInstance";

interface Props {
  onCategorySelect: (configurationId: number, categoryId: number) => void;
}

export default class SearchableDropdown extends React.PureComponent<Props, {}> {
  render() {
    const props = {
      models: readerConfigurations.models,
      onCategorySelect: this.props.onCategorySelect
    };
    return <ArticleToReaderDropdown {...props} />;
  }
}
