import Marionette from 'backbone.marionette';
import handleTooltips from 'models/handleTooltips';
import * as template from 'text-loader!templates/settings/contacts/contactGroupDetails.html';
import * as _ from 'underscore';
import * as ReactDOM from 'react-dom';
import I18n from 'i18n';

class ContactGroupDetailsView extends Marionette.Layout {
  /*   private template: string = template*/

  initialize(opts: any) {
        handleTooltips(this);
        this.opts = opts;
    }

    onRender() {
        const keyInvalidDomain =
            'webapp.settings.contacts.groups.validations.domain_invalid';
        const keyDuplicateDomain =
            'webapp.settings.contacts.groups.validations.domain_duplicate';
        this.ui.domainErrorContent.html(I18n.t(keyInvalidDomain));
        this.ui.domainError.hide();
        this.ui.duplicateDomainErrorContent.html(I18n.t(keyDuplicateDomain));
        this.ui.duplicateDomainError.hide();
    }

    serializeData(){
        return {
            name: this.model.get("name"),
            readerConfigurations: this.opts.readerConfigurations
        };
    }

    showDeletion(view: any) {
        this.deletion.show(view);
    }

    showContactList(view: any) {
        ReactDOM.render(view, this.ui.contactList[0]);
        this.trigger('registerTooltips')
    }

    clearLiveFilter() {
      this.$("[data-contacts-search]").val('')
      this.$("[data-contacts-search]").change()
    }

    showDomainList(view: any) {
        this.domainList.show(view);
    }

    enableInplaceEditForContactGroupHeading() {
        if (!this.isHeaderInEditMode()) {
            this.$('#edit-contactGroup-name').addClass("is-active");
            this.$('#edit-contactGroup-name input').val(this.model.get("name"));
            this.$('#edit-contactGroup-name div').hide();
            this.$('#edit-contactGroup-name input').show();
            this.$('#edit-contactGroup-name input').focus();
        }
    }

    isHeaderInEditMode() {
        return this.$('#edit-contactGroup-name').hasClass("is-active");
    }

    saveOrCancelOrContinue(e: any) {
        switch (e.keyCode) {
        case 13: // Enter key pressed
            this.assignNewContactGroupName(); // save
            return false;
        case 27: // Esc key pressed
            this.abortNewContactGroupName(); // cancel
            return false;
        default:
            return false; // continue
        }
    }

    preventPropagation(e: any) {
        return false
    }

    assignNewContactGroupName() {
        if (this.isHeaderInEditMode()) {
            if (!_.isEmpty(this._newContactGroupName())) {
                this.model.set("name", this._newContactGroupName());
            } else {
                // Restore old if blank
                this.$('#edit-contactGroup-name input').text(this.model.get("name"));
            }
            this.disabledInplaceEditForContactGroupHeadint();
        }
    }

    abortNewContactGroupName() {
        if (this.isHeaderInEditMode()) {
            this.$('#edit-contactGroup-name div h1').text(this.model.get("name"));
            this.$('#edit-contactGroup-name').removeClass("is-active");
            this.$('#edit-contactGroup-name div').show();
            this.$('#edit-contactGroup-name input').hide();
        }
    }

    disabledInplaceEditForContactGroupHeadint() {
        if (this.isHeaderInEditMode()) {
            this.abortNewContactGroupName();
        }
    }

    onClose() {
        if (this._testInterval !== undefined) {
            clearInterval(this._testInterval);
        }
    }

    _newContactGroupName() {
        return this.$('#edit-contactGroup-name input').val();
    }

    showDuplicateDomainError() {
        this.ui.domainError.hide();
        this.ui.duplicateDomainError.show();
    }

    showDomainError() {
        this.ui.domainError.show();
        this.ui.duplicateDomainError.hide();
    }

    hideDomainErrors() {
        this.ui.domainError.hide();
        this.ui.duplicateDomainError.hide();
    }
    handleClick(event: any) {
        event.preventDefault();
        this.trigger('showModal');
    }
}
ContactGroupDetailsView.prototype.template = template;
ContactGroupDetailsView.prototype.regions = {
    deletion: '[data-edit-contactGroup-deletion]',
    domainList: '[data-domain-list]',
    orderModal: '[data-order-modal]'
};
ContactGroupDetailsView.prototype.events = {
    'click #edit-contactGroup-name': "enableInplaceEditForContactGroupHeading",
    'keyup #edit-contactGroup-name input': 'saveOrCancelOrContinue',
    'submit #edit-contactGroup-form': 'preventPropagation',
    'blur #edit-contactGroup-name input': 'assignNewContactGroupName',
    'click [data-delete]': 'handleClick'
};
ContactGroupDetailsView.prototype.ui = {
  reactTest: '[data-react-test]',
  deleteBtn: '[data-delete]',
  contactList: '[data-contact-group-contacts-list]',
  domainError: '[data-domain-error]',
  domainErrorContent: '[data-domain-error-content]',
  duplicateDomainError: '[data-duplicate-domain-error]',
  duplicateDomainErrorContent: '[data-duplicate-domain-error-content]',
};

export default ContactGroupDetailsView;
