/* jshint module: true */
import ModuleController from 'controllers/moduleController';
import ApogeeLayout from 'views/apogeeLayout';
import ReaderResultsSceneController
    from 'controllers/newsroom/resultsSceneController';
import SummarySceneController
    from 'controllers/newsroom/summarySceneController';
import readerConfigurationsInstancePromise
    from 'models/newsroom/configurationsInstancePromise';
import ReaderSidebarController
    from 'controllers/newsroom/readerSidebarController';
import when from 'when';
import ReaderEditSceneController
    from 'controllers/newsroom/editSceneController';
import ReaderAdminEditSceneController
    from 'controllers/newsroom/adminEditSceneController';
import ReaderConfiguration from 'models/newsroom/configuration';
import session from 'models/sessionInstance';
import agentPromise from 'models/agentsInstancePromise';
import vent from 'libraries/vent'
import users from 'collections/usersInstance';

export default class NewsroomController
    extends ModuleController {
    initialize() {
        this._dependenciesPromise().done(() => {
            this.view = new ApogeeLayout();
            this.view.render();
            this._setupView();
            this.trigger('loaded');
        });
    }

    _setupView() {
        this.sidebarController = new ReaderSidebarController();
        this.sidebarView = this.sidebarController.view;
        this.view.showSidebar(this.sidebarView);
        this._registerListeners();
        this.sidebarController.trigger('loaded');
        this.sidebarController.selectFirst();
    }

    _registerListeners() {
        this.listenTo(this.sidebarController,
                      'edit-link:clicked',
                      this._handleEditClick);
        this.listenTo(this.sidebarController,
                      'category:clicked',
                      this._handleCategoryClick);
        this.listenTo(this.sidebarController,
                      'add-reader:clicked',
                      this._addReaderClick);
        this.listenTo(this.sidebarController,
                      'summary-link:clicked',
                      this._handleSummaryClick);
        this.listenTo(
          vent,
          'showFirstReaderSettings',
          () => {
            this.sidebarController.selectFirst()
          }
        )
    }

    _handleSummaryClick(readerConfiguration) {
        this._showScene(SummarySceneController, readerConfiguration);
    }

    _handleCategoryClick(category) {
        let options = {
            readerConfigurationId: category.get('reader_configuration_id'),
            readerCategoryId: category.get('id')
        };
        this._showScene(ReaderResultsSceneController, options);
    }

    _handleEditClick(readerConfiguration) {
        this._showEditScene(readerConfiguration);
    }

    _showEditScene(readerConfiguration) {
        const options = {
            configurationId: readerConfiguration.get('id')
        };
        const isOwner =
              users.currentUser.id === readerConfiguration.get('creator_id')
        if (session.isGroupAdmin() || isOwner) {
            this._showScene(ReaderAdminEditSceneController, options);
        } else {
            this._showScene(ReaderEditSceneController, options);
        }
        this.listenTo(readerConfiguration, 'destroy', this._closeScene);
    }

    _addReaderClick(templateName) {
      const attrs = session.get('reader_templates')[templateName]
      const readerConfiguration = new ReaderConfiguration(attrs);
      readerConfiguration.save().done(() => {
        readerConfiguration.initializeNotificationConfigurations();
        this.configurationsInstance.add(readerConfiguration);
        this.sidebarController.deselectAll();
        this._showEditScene(readerConfiguration);
      });
    }

    _dependenciesPromise() {
        const configurationInstancePromise =
            readerConfigurationsInstancePromise.fetch().then((configs) => {
            this.configurationsInstance = configs;
            const promises = configs.map((config) => {
                return config.fetchCategories();
            });

            return when.all(promises);
        });

        return when.join(
            agentPromise,
            configurationInstancePromise
        );
    }
}
