import Marionette from 'backbone.marionette'
import _ from 'underscore'
import template from 'text-loader!templates/agents/metadata/emm.html'
import localizedLanguages from 'libraries/localizedLanguages'
import I18n from 'i18n'
import moment from 'moment'

var emmView = Marionette.Layout.extend({
  template: template,
  initialize: function (options) {
    this.agentResult = options.agentResult;
  },
  serializeData: function () {
    return {
      metadata: this.metadata()
    };
  },
  metadata: function () {
    var primarySource   = this.agentResult.get('primary_source');
    var sourceMetadata  = primarySource.source_metadata;
    var languageCodes = this.agentResult.get('languages');

    var data = {};
    if (sourceMetadata) {
      var publishTime = sourceMetadata.clip_published_at;
      var durationSeconds = sourceMetadata.duration ||
                            sourceMetadata.media_length;

      var duration;
      if (durationSeconds) {
        var minutes = Math.floor(durationSeconds / 60);
        var seconds = durationSeconds % 60;
        duration =
          I18n.t("webapp.agents.results.result_details.metadata.minutes_exact",
                 {mm: this._zeroPad(minutes, 2),
                  ss: this._zeroPad(seconds, 2)});
      }
      var languages;
      if (!_.isEmpty(languageCodes)) {
        languages = localizedLanguages(languageCodes);
      }
      if (!_.isUndefined(publishTime)) {
        publishTime = moment.unix(publishTime);
      }
      var informationValues = [duration, languages, publishTime];
      var anyInformationDefined = _.any(informationValues, (value) => {
        return !_.isUndefined(value);
      });
      if (anyInformationDefined) {
        data = {
          information: {
            duration: duration,
            languages: languages,
            publishTime: publishTime
          }
        };
      }

      if (
        !_.isEmpty(sourceMetadata.reach) ||
          !_.isEmpty(sourceMetadata.ad_value) ||
          !_.isUndefined(sourceMetadata.media_kind)
         ) {
        data.reach = {
          mediaKind: sourceMetadata.media_kind,
          viewCount: sourceMetadata.reach,
          adValue: sourceMetadata.ad_value,
          adValueCurrency: sourceMetadata.ad_value_currency
        };
      }
    }
    return data;
  },
  _zeroPad: function (num, size) {
    var s = num+"";
    while (s.length < size) {
      s = "0" + s;
    }
    return s;
  }
});

export default emmView;
