import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';
import browserCheck from 'libraries/browserCheck';

class Session extends Backbone.Model {
    url() {
        return '/api/v3/config/bluereport';
    }

    userId() {
        return this.get("user").id;
    }

    timeline2Enabled() {
        return this.hasFeature('show_timeline_2_0');
    }

    availableNotificationTriggerTypes() {
        return this.get("notification_trigger_types");
    }

    canLoginAs() {
        return this.isGroupAdmin() && this.hasFeature('log_in_as_in_webapp');
    }

    shareSelectedArticlesViaEmail() {
      return this.hasFeature('share_selected_articles_via_email');
    }

    showEditLabel() {
        return this.hasFeature('show_global_clip_edit_label');
    }

    contractVersion() {
        return this.get("contract_version");
    }

    rtvMaximumReached() {
        return this.get("rtv_maximum_reached");
    }

    agentHistoryStart() {
        return this.get("agent_history_start");
    }

    isImpersonated() {
        return this.get("impersonated");
    }

    sortByPublishTimeEnabled() {
      return this.hasFeatureSetting('timeline', 'sort_by_publish_time')
    }

    featureSettings() {
      return this.get("feature_settings");
    }

    hasFeatureSetting(flagName, featureName) {
      const featureSettings = this.featureSettings()
      if (featureSettings) {
        const flag = featureSettings[flagName]
        if (flag === undefined) { return false }
        const feature = flag['features'][featureName]
        if (feature === undefined) { return false }
        const value = feature['enabled']
        return (value !== "false" && !!value)
      } else {
        return false
      }
    }

    isSwissCustomer() {
        let country = this.get("country");
        return country === "CH";
    }

    isGermanCustomer() {
        let country = this.get("country");
        return country === "DE";
    }

    isAustrianCustomer() {
        let country = this.get("country");
        return country === "AT";
    }

    hasPrintClipsInReports() {
        return this.get('features').reports_include_print;
    }

    dashboardRefreshInterval() {
        let interval = this.get("dashboard_refresh_interval_in_minutes");
        return moment.duration(interval, 'minutes');
    }

    agentLimitExceeded() {
        return false; // never!
    }

    hasClipDownloadFeature() {
        return this.get("features").clip_pdf;
    }

    userLimitReached(users) {
        let limit = this.get('limits').users
        if (_.isNull(limit)) {
            return false;
        } else {
            return limit <= users.length;
        }
    }

    canAddClipsToOtherAgents() {
        if (this.get("features").admins_can_add_manual_clips) {
            return this.isGroupAdmin();
        } else {
            return true;
        }
    }

    globalTopicSmtLimitReached() {
        return this.get('limits').global_topics_or_social_media_monitors_reached;
    }

    isGroupAdmin() {
        return this.hasModule('group_admin');
    }

    isLicensingConfigurable() {
        return this.get('licensing_configurable');
    }

    hasOnlineArticleLicensing() {
        return this.hasFeature('full_text_licensing');
    }

    hasFeature(feature) {
        let f = this.get('features')[feature];
        return !_.isUndefined(f) && f !== false;
    }

    hasModule(moduleName) {
        return _.indexOf(this.get('modules'), moduleName) !== -1;
    }

    can(privilege) {
        return this.get('user_privileges')[privilege];
    }

    isOSX() {
        return /OS X/i.test(navigator.userAgent);
    }

    getTextLengthLimit() {
      if (browserCheck.isIE11()) {
        return this.get('detail_view_text_length_ie_limit');
      } else {
        return this.get('detail_view_text_length_limit');
      }
    }
}

export default Session;
