import Config from 'libraries/configuration';
import Backbone from 'backbone';
import SocialMediaMonitor from 'models/socialMediaMonitor';

class SocialMediaMonitors extends Backbone.Collection {
    initialize(models, options) {
        this.agent = options.agent;
    }
    collectionLoaded() {
        this.isCollectionLoaded = true;
    }
    url() {
        return Config.apiUrl + "agents/" + this.agent.id + "/social_media_monitors/";
    }
    parse(response) {
        return response.collection;
    }
}

SocialMediaMonitors.prototype.model = SocialMediaMonitor;

export default SocialMediaMonitors;
