import $ from 'jquery';
import when from 'when';

class OnlineClipOrder {
    static order(agentResultId) {
        return when($.ajax({
            type: 'POST',
            url: '/api/v3/full_text_orders/',
            data: { agent_result_id: agentResultId }
        }));
    }
}

export default OnlineClipOrder;
