import App from 'app'
import 'bootstrapDropdown'
import 'bootstrapDropdownMultiselect'

App.module('Styleguide', {
  startWithParent: false,
  define: function () {
  }
});

export default App.Styleguide;

