import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/timeline/timelineYear.html'
import TimelineMonthView from 'views/timeline/timelineMonthView'

var TimelineYearView = Marionette.CompositeView.extend({
  template: template,
  itemView: TimelineMonthView,
  itemViewOptions: function () {
    return {customOptions: this.options.customOptions};
  },
  itemViewEventPrefix: "months",
  ui: {
    monthsContainer: '[data-container=months]'
  },
  triggers: {
    'click [data-year]': 'year:clicked'
  },
  initialize: function () {
    this.collection = this.model.months;
  },
  serializeData: function () {
    var year = this.model.get('date').getFullYear();
    return { year: year };
  },
  appendHtml: function (collectionView, itemView, index) {
    var container = collectionView.ui.monthsContainer;
    var followingElement = container.children()[index];
    if (followingElement) {
      collectionView.$(followingElement).before(itemView.el);
    } else {
      container.append(itemView.el);
    }
  },
  onRender: function () {
    if (this.model.isCurrent()) {
      this.$('[data-year]').hide();
    }
  }
});

export default TimelineYearView;

