import _ from 'underscore'
import Marionette from 'backbone.marionette'
import agents from 'collections/agentsInstance'
import Agents from 'collections/agents'
import RealtimeNotificationView from 'views/settings/notifications/realtimeNotificationView'
import AgentSelectionController from 'controllers/settings/notifications/agentSelectionController'

var RealtimeNotificationController = Marionette.Controller.extend({
  initialize: function (options) {
    this.settings = options.settings;
    this.view = new RealtimeNotificationView();
    this.listenTo(this.view, 'render', this._createSelectionController);
  },
  _createSelectionController: function () {
    var order = this.settings.get('agents_order');

    this.selection = this._selectionInSettings();

    this.selectionController = new AgentSelectionController({
      agents: agents,
      selected_agents: this.selection,
      order: order
    });
    this.listenTo(this.selection, 'add remove', this._selectionUpdated);
    this.view.agentSelector.show(this.selectionController.view);
  },
  _selectionUpdated: function () {
    this.settings.save({
      realtime_agent_ids: this.selection.pluck('id')
    });
  },
  _selectionInSettings: function () {
    var realtimeAgentIds = this.settings.get('realtime_agent_ids');
    var selectedAgentModels = _.map(realtimeAgentIds, function (id) {
      return agents.get(id);
    });
    return new Agents(selectedAgentModels);
  }
});

export default RealtimeNotificationController;

