import _ from 'underscore'
import AgentTopic from 'models/agentTopic'

function tokenizeQuery(query) {
  var splitByQuote = query.split('"');
  var parts = _.map(splitByQuote, function (s, index) {
    if (index % 2 === 0) {
      return s.split(/\s+/);
    } else {
      return '"' + s + '"';
    }
  });
  return _.compact(_.flatten(parts));
}

function buildTopic(query) {
  var words = tokenizeQuery(query);
  var term = _.first(words);
  var contextLiterals = _.rest(words);
  return new AgentTopic({
    terms: [
      {
        name: term
      }
    ],
    contexts: [
      {
        literals: _.map(contextLiterals, function (literal) {
          return {
            name: literal
          };
        })
      }
    ]
  });
}

export default buildTopic;

