import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/placeholderView.html';

class LayoutView extends Marionette.Layout {
    showView(view) {
      if (this.container) {
        this.container.show(view);
      }
    }
}

LayoutView.prototype.template = template;
LayoutView.prototype.regions = {
  container: '[data-placeholder]'
}

export default LayoutView;
