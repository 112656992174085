import Backbone from 'backbone'
import _ from 'underscore'

var RestorableModel = Backbone.Model.extend({
  store: function () {
    this.stored_attributes = _.clone(this.attributes);
  },
  restore: function () {
    this.set(this.stored_attributes);
  }
});

export default RestorableModel;

