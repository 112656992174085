import * as React from "react";
import { connect } from "react-redux";
import { Dashboard } from "../redux/dashboards";
import { Store } from "../redux/store";
import { User } from "../redux/user";
import { UpdateAction, update } from "../redux/selectedDashboard";
import I18n from "i18n";

interface Props {
  dashboards: Dashboard[];
  user: User;
  update(id: number): UpdateAction;
  reloadDashboard(selectedDashboard: number): void;
}

class Sidebar extends React.PureComponent<
  Props,
  {
    selectedItem: string;
  }
> {
  state = {
    selectedItem: ""
  };

  componentDidMount() {
    const { dashboards } = this.props;
    if (this.state.selectedItem === "" && dashboards.length > 0) {
      this.setState({ selectedItem: dashboards[0].id.toString() });
    }
  }

  render() {
    return (
      <div className="layout-sidebar contrasted-container">
        <ul className="nav-tree">
          <li className="nav-tree__item">
            <h2 className="nav-tree__item-title">
              <a className="nav-tree__link nav-tree__link--nonclickable">
                <span className="nav-tree__text">
                  {I18n.t("webapp.new_dashboard.sidebar.subscribed_dashboards")}
                </span>
              </a>
            </h2>
          </li>
          {this.props.dashboards.map(db => this.renderDashBoardItem(db))}
        </ul>
      </div>
    );
  }

  onSelect = (board: Dashboard) => {
    this.setState({ selectedItem: board.id.toString() });
    this.props.update(board.id);
    this.props.reloadDashboard(board.id);
  };

  renderDashBoardItem(dashboard: Dashboard) {
    return (
      <li
        className={`nav-subtree__item ${
          this.state.selectedItem === dashboard.id.toString() ? "is-active" : ""
        }`}
        key={dashboard.id.toString()}
        onClick={this.onSelect.bind(this, dashboard)}>
        <a className="nav-subtree__link">
          <span className="nav-subtree__text">{dashboard.name}</span>
          <span className="nav-subtree__toggle">
            {dashboard.shared === false ? (<i className="fa fa-lock nav-subtree__private-icon " />) : null}
          </span>
        </a>
      </li>
    );
  }
}

export default connect(
  function({ user }: Store) {
    return { user };
  },
  { update }
)(Sidebar);
