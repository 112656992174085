/* eslint no-invalid-this: "off" */

var monthChooser = function (minYear, minMonth, maxYear, maxMonth) {
  this.month = NaN;
  this.year = NaN;
  this.minYear = parseInt(minYear, 10);
  this.minMonth = parseInt(minMonth, 10);
  this.maxYear = parseInt(maxYear, 10);
  this.maxMonth = parseInt(maxMonth, 10);
};
monthChooser.prototype.minYear = function () {
  var minYear = this.minYear;
  if (this.otherMinDate && !isNaN(this.otherMinDate.year)) {
    minYear = Math.max(minYear, this.otherMinDate.year);
  }
  return minYear;
};
monthChooser.prototype.maxYear = function () {
  var maxYear = this.maxYear;
  if (this.otherMaxDate && !isNaN(this.otherMaxDate.year)) {
    maxYear = Math.min(maxYear, this.otherMaxDate.year);
  }
  return maxYear;
};
monthChooser.prototype.compareMonths = function (month1, year1, month2, year2) {
  if (year1 < year2) { return -1; }
  if (year1 > year2) { return 1; }
  if (month1 < month2) { return -1; }
  if (month1 > month2) { return 1; }
  return 0;
};
monthChooser.prototype.minDate = function () {
  var minMonth = this.minMonth;
  var minYear = this.minYear;
  if (this.otherMinDate) {
    var otherMinDate = this.otherMinDate.minDate();
    if (this.compareMonths(otherMinDate.month, otherMinDate.year, minMonth, minYear) > 0) {
      minMonth = otherMinDate.month;
      minYear = otherMinDate.year;
    }
    if (this.otherMinDate.isComplete()) {
      var month = this.otherMinDate.month;
      var year = this.otherMinDate.year;
      if (this.compareMonths(month, year, minMonth, minYear) > 0) {
        minMonth = month;
        minYear = year;
      }
    }
  }
  return {month: minMonth, year: minYear};
};
monthChooser.prototype.maxDate = function () {
  var maxMonth = this.maxMonth;
  var maxYear = this.maxYear;
  if (this.otherMaxDate) {
    var otherMaxDate = this.otherMaxDate.maxDate();
    if (this.compareMonths(otherMaxDate.month, otherMaxDate.year, maxMonth, maxYear) < 0) {
      maxMonth = otherMaxDate.month;
      maxYear = otherMaxDate.year;
    }
    if (this.otherMaxDate.isComplete()) {
      var month = this.otherMaxDate.month;
      var year = this.otherMaxDate.year;
      if (this.compareMonths(month, year, maxMonth, maxYear) < 0) {
        maxMonth = month;
        maxYear = year;
      }
    }
  }
  return {month: maxMonth, year: maxYear};
};
monthChooser.prototype.checkValidity = function (month, year) {
  if (isNaN(month)) {
    for (var m = 0; m < 12; m++) {
      if (this.checkValidity(m, year)) { return true; }
    }
    if (this.year === year) { this.year = NaN; }
    return false;
  }
  var minDate = this.minDate();
  var maxDate = this.maxDate();
  if (isNaN(year)) {
    for (var y = minDate.year; y <= maxDate.year; y++) {
      if (this.checkValidity(month, y)) { return true; }
    }
    if (this.month === month) { this.month = NaN; }
    return false;
  }
  if (year < minDate.year || year > maxDate.year) { return false; }
  if (year === minDate.year && month < minDate.month) { return false; }
  if (year === maxDate.year && month > maxDate.month) { return false; }
  return true;
};
monthChooser.prototype.possibleYears = function () {
  var years = [];
  for (var year = this.minYear; year <= this.maxYear; year++) {
    if (this.checkValidity(this.month, year)) {
      years.push(year);
    }
  }
  return years;
};
monthChooser.prototype.possibleMonths = function () {
  var months = [];
  for (var month = 0; month < 12; month++) {
    if (this.checkValidity(month, this.year)) {
      months.push(month);
    }
  }
  return months;
};
monthChooser.prototype.after = function (otherMonthChooser) {
  this.otherMinDate = otherMonthChooser;
};
monthChooser.prototype.before = function (otherMonthChooser) {
  this.otherMaxDate = otherMonthChooser;
};
monthChooser.prototype.isComplete = function () {
  return !isNaN(this.month) && !isNaN(this.year);
};
monthChooser.prototype.getFirstDate = function () {
  var date;
  if (this.isComplete()) {
    date = new Date(this.year, this.month, 1);
  }
  return date;
};
monthChooser.prototype.getLastDate = function () {
  var date;
  if (this.isComplete()) {
    date = new Date(this.year, this.month + 1, 0);
  }
  return date;
};
monthChooser.prototype.setDate = function (date) {
  this.setYear(date.getFullYear());
  this.setMonth(date.getMonth());
};
monthChooser.prototype.setMonth = function (month) {
  var newMonth;
  if (this.checkValidity(month, this.year)) { newMonth = month; }
  this.month = parseInt(newMonth, 10);
};
monthChooser.prototype.setYear = function (year) {
  var newYear;
  if (this.checkValidity(this.month, year)) { newYear = year; }
  this.year = parseInt(newYear, 10);
};

export default monthChooser;

