import _ from 'underscore'
import deepMerge from 'libraries/deepMerge'
import Chart from 'views/dashboard/widgetView/helpers/chart'
import 'highcharts_legacy'

function PieChart(container, options) {
  this._initialize(container, options);
}
_.extend(PieChart.prototype, Chart.prototype, {
  _defaultOptions: function () {
    var defaults = Chart.prototype._defaultOptions.call(this);
    deepMerge(defaults, {
      chart: {
        type: 'pie'
      }
    });
    return defaults;
  }
});

export default PieChart;

