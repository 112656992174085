import AgentResultActionsController from
    'controllers/agents/agentResultActionsController';
import ReaderResultActionsView from 'views/newsroom/readerResultActionsView';
import ReaderResultTagController from 'controllers/newsroom/readerResultTagController';
import SelectedAgentResults from 'models/selectedAgentResults';
import ReaderResultMarkImportantController from
    'controllers/newsroom/readerResultMarkImportantController';
import CloseSubObjects from 'libraries/closeSubObjects';

class ReaderResultActionsController extends AgentResultActionsController {
    initialize(options) {
        this.agentResult = options.agentResult;
        this.readerResult = options.readerResult;
        this.view = new ReaderResultActionsView({model: this.agentResult});
        this.listenTo(this.view, 'render', this.showMarkImportant);
        this.listenTo(this.view, "render", this.showTags);
    }
    onClose() {
        CloseSubObjects.closeAll([
            this.markAsImportantController,
            this.tagController
        ]);
    }
    showMarkImportant() {
        var agentResults = new SelectedAgentResults([this.agentResult]);
        var opts = {
            selectedAgentResults: agentResults,
            readerResult: this.readerResult
        };
        this.markAsImportantController =
            new ReaderResultMarkImportantController(opts);
        this.view.showMarkImportant(this.markAsImportantController.view);
    }

    showTags() {
      var agentResults = new SelectedAgentResults([this.agentResult]);
      this.tagController = new ReaderResultTagController(
        agentResults.collection
      );
      this.view.showTags(this.tagController.view);
    }
}

export default ReaderResultActionsController;
