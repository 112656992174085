import Widget from 'models/dashboard/widget'
import _ from 'underscore'

Widget.RssFeedWidget = Widget.extend({
  parse: function (response) {
    var res = response;

    if (response.object) {
      res = response.object;
    }

    _.each(res.articles, function (article) {
      article.date = new Date(article.date * 1000);
    });

    return res;
  }
});

export default Widget.RssFeedWidget;
