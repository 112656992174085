import vent from 'libraries/vent';

const Notificator = {
  showNotification: function (message: string) {
    vent.commands.execute('notifications:show', message);
  },
  showSlowNotification: function (message: string) {
    vent.commands.execute('notifications:showSlow', message);
  },
  showErrorNotification: function (message: string) {
    vent.commands.execute('notifications:showError', message);
  },
  showPermanentNotification: function (message: string, identifier: number) {
    vent.commands.execute('notifications:showPermanent', message, identifier);
  },
  clearPermanentNotification: function (identifier: number) {
    vent.commands.execute('notifications:clearPermanent', identifier);
  },
  showLoadError: function () {
    Notificator.showErrorNotification('Teile der Anwendung konnten nicht geladen werden. Bitte laden Sie blueReport neu.');
  }
};

export default Notificator;
