import BarChart from 'views/dashboard/widgetView/helpers/barChart'
import _ from 'underscore'
import $ from 'jquery'
import AgentTagWidgetView from 'views/dashboard/agentTagWidgetView'

var TopSourcesWidgetView = AgentTagWidgetView.extend({
  prepareWidgetBody: function () {
    var container = this.$('div.chart')[0];

    if (_.isEmpty(this.model.get('sources'))) {
      $(container).html("<p class='top-sources-message'>Es sind leider keine Treffer verfügbar.</p>");
    } else {
      this.sources = this.model.get('sources');
      var _this = this;
      var seriesData = _.map(this.sources, function (source) {
        return {
          y: source.occurances_count,
          color: "#006699"
        };
      }, this);
      var legend = $(container).parent().find('.legend');
      var caption = $(container).parent().find('.top-sources-text');

      this.chart = new BarChart(container, {
        plotOptions: {
          bar: {
            point: {
              events: {
                mouseOver: function () {
                  caption.addClass('hidden');
                  legend.removeClass('hidden');
                  _this._handleHover(this.x);

                },
                mouseOut: function () {
                  legend.addClass('hidden');
                  caption.removeClass('hidden');
                  _this._handleHover();
                },
                click: function () {
                  _this._handleClick(this.x);
                }
              }
            }
          }
        },
        chart: {
          width: 246,
          height: 140
        },
        xAxis: {
          categories: _.map(this.model.get('sources'), (source) => {
            return this._truncateLabel(source.name);
          }, this),
          labels: {
            style: {
              width: 1000
            }
          }
        }
      });

      this.chart.addSeries({
        data: seriesData
      });

      this.chart.render();

      this._registerListeners();
    }
  },

  _registerListeners: function () {
    this._unregisterListeners();
    this._elementsWithListener = [];
    this._registerClickOnTextListeners('svg > g > text');
    this._registerClickOnTextListeners('svg > g > g > text');
    this._registerHoverOnTextListeners('svg > g > text');
    this._registerHoverOnTextListeners('svg > g > g > text');
  },

  _registerClickOnTextListeners: function (selector) {
    var _this = this;
    var elements = this.$(selector);
    elements.each(function (index, element) {
      $(element).on('click', function () {
        _this._handleClick(index);
      });
    });
    this._elementsWithListener.push(elements);
  },

  _registerHoverOnTextListeners: function (selector) {
    var _this = this;
    var elements = this.$(selector);
    elements.each(function (index, element) {
      $(element).hover(function () {
        _this._handleHover(index);
      }, function () {
        _this._handleHover();
      });
    });
    this._elementsWithListener.push(elements);
  },

  _unregisterListeners: function () {
    if (this._elementsWithListener) {
      _.each(this._elementsWithListener, function (elements) {
        elements.off();
      });
      delete this._elementsWithListener;
    }
  },

  _maybeShowAgentWithSource: function (id, name) {
    if (id) {
      this.model.showAgentTag([{
        type: 'source',
        id,
        name
      }])
    }
  },

  _handleClick: function (index) {
    var id = this.sources[index].online_source_id;
    if (id) {
      var name = this.sources[index].name;
      this._maybeShowAgentWithSource(id, name);
    }
  },

  _handleHover: function (index) {
    var pointerCursor = false;
    if (index >= 0) {
      var id = this.sources[index].online_source_id;
      if (id) {
        pointerCursor = true;
      }
    }
    this._toggleCursor(pointerCursor);
  },

  _toggleCursor: function (pointer) {
    var body = this.$('[data-widget-body]');
    if (pointer) {
      body.addClass('is-clickable');
    } else {
      body.removeClass('is-clickable');
    }
  },

  onClose: function () {
    this._unregisterListeners();
  }
});

export default TopSourcesWidgetView;

