import SceneController from 'controllers/sceneController'
import _ from 'underscore'
import notificationSettingsPromise from 'models/settings/notificationSettingsPromise'
import NotificationsListController from 'controllers/settings/notifications/notificationsListController'
import NotificationSummaryController from 'controllers/settings/notifications/notificationSummaryController'
import RealtimeNotificationController from 'controllers/settings/notifications/realtimeNotificationController'

var NotificationsSceneController = SceneController.extend({
  show: function () {
    var self = this;
    notificationSettingsPromise.fetch();
    notificationSettingsPromise.done(function (settings) {
      self.notificationSettings = settings;
      self._createListController();
    });
  },
  _createListController: function () {
    this.listController = new NotificationsListController({
      notification_settings: this.notificationSettings
    });
    this.showInLeftColumn(this.listController.view);
    this._registerListeners();
    this.listController.activateSummary();
    this._showSummary();
  },
  _registerListeners: function () {
    this.listenTo(this.listController, 'summary', this._showSummary);
    this.listenTo(this.listController, 'realtime', this._showRealtime);
  },
  _showSummary: function () {
    var self = this;
    this.notificationSettings.fetch().done(function () {
      self._showRightController(new NotificationSummaryController({
        settings: self.notificationSettings
      }));
    });
  },
  _showRealtime: function () {
    var self = this;
    this.notificationSettings.fetch().done(function () {
      self._showRightController(new RealtimeNotificationController({
        settings: self.notificationSettings
      }));
    });
  },
  _showRightController: function (controller) {
    this._closeRightController();
    this.showInRightColumn(controller.view);
    this.rightController = controller;
  },
  _closeRightController: function () {
    if (!_.isUndefined(this.rightController)) {
      this.rightController.close();
      this.focusRight();
    }
  }
});

export default NotificationsSceneController;

