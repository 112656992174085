import * as React from 'react';
import * as ReactDOM from 'react-dom';
import I18n from 'i18n';

export default class AgentsListLandingPageView extends React.Component<{}> {
  render() {
    return (
      <div className="layout-content greyed-container">
        <div className="pal">
          <div className="notice">
            <p><i className="icon-searchagent notice__marker"></i> {I18n.t("webapp.agents.agents_list.landing_page_placeholder")}</p>
          </div>
        </div>
      </div>
    )
  }
}
