import Marionette from 'backbone.marionette';
import moment from 'moment';
import readerResultListItemTemplate from 'text-loader!templates/newsroom/readerResultListItem.html';

class ReaderResultListItemView extends Marionette.ItemView {
    initialize() {
        this.listenTo(this.model.get('agent_result'), 'change', this.render);
    }

    attributes() {
        return {
            'data-reader-result-id': this.model.get('id'),
            'data-reader-result-position': this.model.get('position')
        };
    }

    onRender() {
        let self = this;
        let data = this.model.agentResultData();

        this.registerClickHandler(function () {
            self.trigger('result:clicked', self.model);
        });
        this.registerCtrlClickHandler(function () {
            self.trigger('result:ctrlClicked', self.model);
        });
        this.registerShiftClickHandler(function () {
            self.trigger('result:shiftClicked', self.model);
        });

        if (data.isImportant) {
            this.$el.addClass('is-highlighted');
        } else {
            this.$el.removeClass('is-highlighted');
        }
    }

    serializeData() {
        let data = this.model.agentResultData();

        return {
            resultId: this.model.get('id'),
            colorCode: data.color,
            primary_source_name: data.primarySourceName,
            additional_source_count: data.sourceCount,
            publish_time: moment.unix(data.publishTime),
            title: data.title,
            mentions: data.mentions.slice(0, 5),
            primarySourceFavicon: data.primarySourceFavicon,
            position: this.model.get('position'),
            youtubeContentRemoved: this.model.get('agent_result').youtubeContentRemoved(),
        };
    }

    registerClickHandler(handler) {
        this.clickHandler = handler;
    }

    registerCtrlClickHandler(handler) {
        this.ctrlClickHandler = handler;
    }

    registerShiftClickHandler(handler) {
        this.shiftClickHandler = handler;
    }

    generalClickHandler(e) {
        if (!e.ctrlKey && !e.shiftKey && !e.metaKey) {
            this.clickHandler();
        } else if (e.ctrlKey || e.metaKey) {
            this.ctrlClickHandler();
        } else if (e.shiftKey) {
            this.shiftClickHandler();
        }
    }

    markAsSelected() {
        var el = this.$el;
        el.addClass('is-active');
        if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
            el.css('visibility', 'hidden');
            window.setTimeout(function () { el.css('visibility', 'visible'); }, 0);
        }
    }

    markAsDeselected() {
        this.$el.removeClass('is-active');
    }

    selectHandler(event) {
        event.preventDefault();
    }
}

ReaderResultListItemView.prototype.template  = readerResultListItemTemplate;
ReaderResultListItemView.prototype.tagName   = "li";
ReaderResultListItemView.prototype.className = "clips-list__item";
ReaderResultListItemView.prototype.events = {
    'click': 'generalClickHandler'
};

export default ReaderResultListItemView;
