import Marionette from 'backbone.marionette';
import SourceCategoryLicensingView from '../../views/settings/sourceCategoryLicensingView';

class sourceCategoryLicensingController extends Marionette.Controller {
  initialize() {
    this.view = new SourceCategoryLicensingView();
    this.registerListeners();
  }

  registerListeners() {
    this.listenTo(this.view, 'render', this.showList);
    this.listenTo(
      this.view,
      'show:setUpLicensing', (item) => {
        this.trigger('setUpLicensing', item)      
      }
    );
    this.listenTo(
      this.view,
      'close:setUpLicensing', () => 
        this.trigger('closeSetUpLicensing')      
    );
  }

  showList() {
    this.view.showSourceCategoryList();
  }
}

export default sourceCategoryLicensingController;
