import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/agents/resultListLayout.html';

var ResultListLayout = Marionette.Layout.extend({
  template: template,
  regions: {
    filters: '[data-container=filters]',
    list: '[data-container=list]'
  }
});

export default ResultListLayout;

