import _ from 'underscore'
import $ from 'jquery'
import template from 'text-loader!templates/reporting/newsletter.html'
import ReportView from 'views/reporting/reportView'
import recipientsPartial from 'text-loader!templates/reporting/newsletter/recipients.html'
import reportHeadPartial from 'text-loader!templates/reporting/report/report_head.html'
import filterSortPartial from 'text-loader!templates/reporting/report/filter_sort.html'
import newsletterActionsPartial from 'text-loader!templates/reporting/newsletter/actions.html'
import 'backbone.marionette'

var View = ReportView.extend({
  template: template,
  initialize: function () {
    this.on('clicked:destroy', this.destroyReport);
    this.on('clicked:edit', this.editReport);
    this.on('clicked:preview', this.previewReport);
    this.on('render', function () {
      $("#main").animate({ scrollTop: 0 });
    });
    this.on('clicked:use-as-template-Medienspiegel', () => {
      this.model.trigger('new-from-template', this.model, 'Medienspiegel');
    });
    this.on('clicked:use-as-template-ExcelReport', () => {
      this.model.trigger('new-from-template', this.model, 'ExcelReport');
    });
    this.on('clicked:use-as-template-Newsletter', () => {
      this.model.trigger('new-from-template', this.model, 'Newsletter');
    });
    this.on('before:render', () => {
      this.registerPartial('report_head', reportHeadPartial);
      this.registerPartial('recipients', recipientsPartial);
      this.registerPartial('filter_sort', filterSortPartial);
      this.registerPartial('newsletter_actions', newsletterActionsPartial);
    });
  },
  serializeData: function () {
    var obj = ReportView.prototype.serializeData.call(this);
    obj.recipients = _.map(this.model.get('recipients'), function (r) { return r.toString(); }).join(', ');

    return obj;
  }
});

export default View;

