/* eslint no-invalid-this: "off" */
import App from 'app'
import vent from 'libraries/vent'
import Notificator from 'libraries/notificator'
import $ from 'jquery'
import Visibility from 'libraries/visibility'
import Widgets from 'collections/dashboard/widgets'
import Layout from 'views/dashboard/layout'
import Toolbar from 'views/dashboard/toolbar'
import WidgetGridView from 'views/dashboard/widgetGridView'
import CreateAWidgetWindowView from 'views/dashboard/createAWidgetWindowView'
import session from 'models/sessionInstance'
import 'highcharts_legacy'

App.module("Dashboard", {
  startWithParent: false,
  define: function () {
    App.Dashboard.name = 'Dashboard';
    App.Dashboard.refreshDisabled = false;

    App.Dashboard.show = function () {
      this.layout.addThemeClass();
      $(this.layout.el).show();
    };

    // Navigate away
    App.Dashboard.hide = function () {
      $(this.layout.el).hide();
      this.layout.removeThemeClass();
      App.Dashboard.exitEditMode();
      // legacy support, new navigation module already does
      // this, which is why .show doesn’t set it.
      $('body').removeClass('gradient-grey-dark');
      $('#container-navigation').removeClass('shadow');
      // end legacy support
    };

    App.Dashboard.on('loaded', function () {
      vent.commands.execute('navigation:loaded', 'dashboard');
    });

    App.Dashboard.on('centerWidgets', function () {
      this.widgetGridView.centerWidgets();
    });

    App.Dashboard.on('unCenterWidgets', function () {
      this.widgetGridView.unCenterWidgets();
    });

    App.Dashboard.addInitializer(function () {
      App.Dashboard.registerInNavigation();

      App.Dashboard.createWidgetsCollection();

      App.Dashboard.createLayout();

      App.Dashboard.addWidgetNavigationHandlers();

      App.Dashboard.loadWidgets(function () {
        App.Dashboard.trigger('loaded');
        App.Dashboard.maybeStartFullScreen();
      });

      App.Dashboard.setRefreshInterval(session.dashboardRefreshInterval());
      App.Dashboard.listenForVisibilityChange();
    });

    App.Dashboard.addFinalizer(function () {
      this.hide();
      this.deregisterInNavigation();
      this.stopListeningForVisibilityChange();
    });

    App.Dashboard.registerInNavigation = function () {
      this.createToolbar();
      vent.commands.execute('navigation:register',
                            App.Dashboard,
                            'dashboard',
                            { dark: true, toolbar: this.toolbar }
                           );
      vent.commands.execute('navigation:loading', 'dashboard');
      vent.commands.setHandler('navigation:show:dashboard', function () { App.Dashboard.show(); });
      vent.commands.setHandler('navigation:hide:dashboard', function () { App.Dashboard.hide(); });
    };

    App.Dashboard.setRefreshInterval = function (refreshInterval) {
      window.setInterval(function () {
        if (App.Dashboard.shouldRefresh()) {
          App.Dashboard.refreshDashboard();
        }
      }, refreshInterval);
    };

    App.Dashboard.shouldRefresh = function () {
      var editMode = App.Dashboard.editMode;
      var refreshDisabled = App.Dashboard.refreshDisabled;
      return !(editMode || refreshDisabled);
    };

    App.Dashboard.listenForVisibilityChange = function () {
      this.listenTo(Visibility, 'user-is-away', function () {
        App.Dashboard.refreshDisabled = true;
      });
      this.listenTo(Visibility, 'user-is-back', function () {
        App.Dashboard.refreshDisabled = false;
        App.Dashboard.refreshDashboard();
      });
    };

    App.Dashboard.stopListeningForVisibilityChange = function () {
      this.stopListeningTo(Visibility);
    };

    App.Dashboard.addWidgetNavigationHandlers = function () {
      this.widgets.on('showFilteredTimeline', (filters) => {
        vent.commands.execute('agents:showFilteredTimeline', filters)
      })
    };

    App.Dashboard.createWidgetsCollection = function () {
      this.widgets = new Widgets();
      this.widgets.on('widget-creation-failed', function () {
        Notificator.showErrorNotification('Das Dashboard-Widget konnte nicht hinzugefügt werden.');
      });
    };

    App.Dashboard.refreshDashboard = function () {
      App.Dashboard.clearGridster();
      App.Dashboard.loadWidgets();
    };

    App.Dashboard.clearGridster = function () {
      App.Dashboard.widgetGridView.clearGridster();
    };

    App.Dashboard.loadWidgets = function (finishedCallback) {
      this.widgets.fetch({complete: finishedCallback, reset: true});
    };

    App.Dashboard.createToolbar = function () {
      this.toolbar = new Toolbar();
      this.toolbar.render();
      this.toolbar.on('fullscreen', App.Dashboard.toggleFullScreen);
      this.toolbar.on('edit-mode', App.Dashboard.toggleEditMode);
      this.toolbar.on('add-widget', App.Dashboard.addWidget);
    };

    App.Dashboard.createLayout = function () {
      this.layout = new Layout();
      this.layout.on('fullscreen-exited', App.Dashboard.fullScreenExited);
      this.layout.on('fullscreen', App.Dashboard.toggleFullScreen);
      this.layout.render();
      this.createWidgetGrid();
      $('#container-content').append(this.layout.el);
    };

    App.Dashboard.toggleFullScreen = function () {
      if (App.Dashboard.fullScreenMode) {
        App.Dashboard.exitFullScreen();
      } else {
        App.Dashboard.startFullScreen();
      }
    };

    App.Dashboard.startFullScreen = function (options) {
      if (!App.Dashboard.fullScreenMode) {
        App.Dashboard.fullScreenMode = true;
        App.Dashboard.exitEditMode();
        App.Dashboard.layout.startFullScreen(options);
        App.Dashboard.trigger('centerWidgets');
      }
    };

    App.Dashboard.exitFullScreen = function () {
      if (App.Dashboard.fullScreenMode) {
        App.Dashboard.fullScreenMode = false;
        App.Dashboard.layout.exitFullScreen();
        App.Dashboard.trigger('unCenterWidgets');
      }
    };

    App.Dashboard.maybeStartFullScreen = function () {
      if (session.get('start_dashboard_in_fullscreen')) {
        App.Dashboard.startFullScreen({forceCompatibility: true});
      }
    };

    App.Dashboard.toggleEditMode = function () {
      if (App.Dashboard.editMode) {
        App.Dashboard.exitEditMode();
      } else {
        App.Dashboard.enterEditMode();
      }
    };

    App.Dashboard.enterEditMode = function () {
      this.editMode = true;
      this.widgetGridView.enterEditMode();
      this.toolbar.enterEditMode();
      this.layout.enterEditMode();
    };

    App.Dashboard.exitEditMode = function () {
      this.editMode = false;
      this.widgetGridView.exitEditMode();
      this.toolbar.exitEditMode();
      this.layout.exitEditMode();
      this.layout.createAWidgetWindowContainer.close();
    };

    App.Dashboard.addWidget = function () {
      App.Dashboard.createAWidgetWindowView = new CreateAWidgetWindowView({widgets: App.Dashboard.widgets});
      App.Dashboard.layout.createAWidgetWindowContainer.show(App.Dashboard.createAWidgetWindowView);
    };

    App.Dashboard.fullScreenExited = function () {
      App.Dashboard.fullScreenMode = false;
      App.Dashboard.layout.exitFullScreen();
    };

    App.Dashboard.createWidgetGrid = function () {
      this.widgetGridView = new WidgetGridView({widgets: this.widgets});
      this.layout.grid.show(this.widgetGridView);
    };

    function setHighchartsDefaults() {
      // handle the case when legacy Highcharts is not available
      if (typeof Highcharts === 'undefined') {
        return
      }
      // end legacy handling
      Highcharts.setOptions({
        global: {
          useUTC: false
        },
        chart:{
          animation: true
        },
        exporting: {
          enabled: false,
          buttons: {
            exportButton: {
              enabled: false
            },
            printButton: {
              enabled: false
            }
          }
        },
        title: {
          text: null
        },
        lang: {
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
          resetZoom: 'gesamter Zeitraum'
        },
        xAxis: {
          title: {
            text: null
          }
        },
        yAxis: {
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        }
      });
    }
    setHighchartsDefaults();
  }
});

export default App.Dashboard
