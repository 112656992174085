import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/pmgAgentSelection.html';
import $ from 'jquery';
import I18n from 'i18n';
import _ from 'underscore';

class PmgAgentSelectionView extends Marionette.ItemView {
    initialize(options) {
        this.response = options.response;
        this.documentId = this.response.document_id;
        this._selects = []
        this.globalSelectReset = true;
    }

    onRender() {
        const importSuccessCallback = () => {
            const data = this.ui.agentSelectionForm.serializeArray();
            this.ui.agentSelectionForm.hide();
            this.ui.agentImportingFailed.hide();
            this.ui.agentImportingSuccessful.show();
            this._enableSubmit();
            this.trigger('pmgZip:imported', data);
        };

        const importFailureCallback = () => {
            this.ui.agentSelectionForm.hide();
            this.ui.agentImportingSuccessful.hide();
            this.ui.agentImportingFailed.show();
            this._enableSubmit();
        };

        this.ui.agentImportingFailed.hide();
        this.ui.agentImportingSuccessful.hide();

        this.ui.agentSelectionForm.submit((e) => {
            const data = this.ui.agentSelectionForm.serializeArray();
            data.push({ name: 'document_id', value: this.documentId });
            e.preventDefault();
            this._disableSubmit();

            $.post(
                '/api/v3/pmg_upload_wizard/import', $.param(data)
            ).done(importSuccessCallback).fail(importFailureCallback);
        });

        this.ui.agentsSelectionTable.append(this._headersHtml());
        this.ui.agentsSelectionTable.append(
          this._generateGlobalAgentSelectionContentHtml()
        );
        _.each(this.response.articles, (article) => {
            let html = this._generateTableContentHtml(
                article, this.response.users
            );
            this.ui.agentsSelectionTable.append(html);
        });

        this._setupGlobalAgentSelection();
    }

    _setupGlobalAgentSelection() {
        this.$('[data-global-agent-selection]').select2(this._select2Options());
        this.$('[data-global-agent-selection]').on('select2-opening', () => {
            this._selects.forEach((s) => { s.select2('close'); });
        });
        $('body').click(() => { this.$('[data-global-agent-selection]').select2('close'); });
    }

    onShow() {
        this._disableSubmitIfInvalidSelects();
        this._addAgentSelectionListener();
        this._addGlobalAgentSelectionListener();
        _.each(this.response.articles, (article) => {
            const select = this.$(`[data-agent-selection-id="${article.id}"]`);
            const globalSelect = this.$('[data-global-agent-selection]');
            this._selects.push(select);
            select.select2(this._select2Options());
            $('body').click(() => { select.select2('close'); });
            select.on('select2-opening', () => {
                this._selects.forEach((s) => {
                    if (s !== select) { s.select2('close'); }
                    if (s !== globalSelect) { globalSelect.select2('close'); }
                });
            });
        });
    }

    _select2Options() {
      return {
        containerCssClass: 'custom-select2',
        width: '100%',
        formatNoMatches: () => {
          return I18n.t('webapp.settings.contacts.groups.no_matches');
        }
      };
    }

    onClose() {
        _.each(this.response.articles, (article) => {
            const select = this.$(`[data-agent-selection-id="${article.id}"]`);
            select.select2('close');
        });
    }

    _headersHtml() {
        const tableHeaderTitleSource =
            I18n.t('webapp.agents.pmg_upload.table_header_title_source');
        const tableHeaderSearchAgent =
            I18n.t('webapp.agents.pmg_upload.table_header_search_agent');

        return `
            <tr class="agent-selection-overview__headline">
              <td class="agent-selection-overview__headline">
                ${tableHeaderSearchAgent}
              </td>
              <td>
                ${tableHeaderTitleSource}
              </td>
            </tr>
        `;
    }

    _generateGlobalAgentSelectionContentHtml() {
        const globalSelectionMessage =
            I18n.t('webapp.agents.pmg_upload.global_agent_selection');
        const defaultMessage =
            I18n.t('webapp.agents.pmg_upload.please_select');
        const userOptions = _.map(this.response.users, (user) => {
          return this._userOptionGroup(user, -1, false);
        }).toString();

        return `
            <tr class="agent-selection-overview__row agent-selection-overview__row--global-selection">
              <td>
                <select data-global-agent-selection>
                  <option data-not-selected value='default'>${defaultMessage}</option>
                  ${userOptions}
                </select>
              </td>
              <td>
                <span class="agent-selection-overview__title">
                  ${globalSelectionMessage}
                </span>
              </td>
            </tr>
        `;
    }

    _generateTableContentHtml(article, users) {
        return `
            <tr class="agent-selection-overview__row">
              <td>
                ${this._createSelectForArticle(article, users)}
              </td>
              <td>
                <span class="agent-selection-overview__title">
                  ${article.title}
                </span>
                <span class="agent-selection-overview__source text--grey">
                  ${article.source}
                </span>
              </td>
            </tr>
        `;
    }

    _createSelectForArticle(article, users) {
        const defaultMessage =
            I18n.t('webapp.agents.pmg_upload.please_select');
        let userOptions = _.map(users, (user) => {
            return this._userOptionGroup(user, article.preselection, true);
        }).toString();

        return `
            <select data-agent-selection
                    name="agent_mapping[${article.id}]"
                    data-agent-selection-id="${article.id}">
              <option data-not-selected>${defaultMessage}</option>
              ${userOptions}
            </select>
        `;
    }

    _userOptionGroup(user, preselection, allowDoNotImport) {
        const doNotImport =
            I18n.t('webapp.agents.pmg_upload.do_not_import');
        let baseAgentOptions = [`<option value=''>${doNotImport}</option>`];
        if (!allowDoNotImport) { baseAgentOptions = []; }
        let agentOptions = _.map(user.agents, (agent) => {
            return this._agentOptions(agent, preselection);
        });

        return `
            <optgroup label='${user.name}'>
              ${(baseAgentOptions.concat(agentOptions)).toString()}
            </optgroup>
        `;
    }

    _agentOptions(agent, preselection) {
        let preselected = '';
        if (agent.id === preselection) {
            preselected = 'selected="selected"';
        }

        return `
            <option value="${agent.id}" ${preselected}>${agent.name}</option>
        `;
    }

    _disableSubmit() {
        this.ui.submitAgentSelections.attr('disabled', true);
        this.ui.submitAgentSelections.addClass('is-disabled');
    }

    _enableSubmit() {
        this.ui.submitAgentSelections.attr('disabled', false);
        this.ui.submitAgentSelections.removeClass('is-disabled');
    }

    _addAgentSelectionListener() {
        this.$('[data-agent-selection]').on('change', () => {
            if (this.globalSelectReset) {
                this.$('select[data-global-agent-selection]').val('default');
                this.$('select[data-global-agent-selection]').trigger('change');
                this._disableSubmitIfInvalidSelects();
            }
        });
    }

    _addGlobalAgentSelectionListener() {
        this.$('[data-global-agent-selection]').on('change', () => {
            const selectedValue =
                this.$('[data-global-agent-selection] option:selected').val();
            if (selectedValue !== 'default') {
                this.globalSelectReset = false;
                this.$('select[data-agent-selection]').val(selectedValue);
                this.$('select[data-agent-selection]').trigger('change');
                this.globalSelectReset = true;
                this._disableSubmitIfInvalidSelects();
            }
        });
    }

    _disableSubmitIfInvalidSelects() {
        const unselectedOptions =
            this.$('[data-agent-selection] [data-not-selected]:selected');

        if (unselectedOptions.length > 0) {
            this._disableSubmit();
        } else {
            this._enableSubmit();
        }
    }
}

PmgAgentSelectionView.prototype.template = template;
PmgAgentSelectionView.prototype.ui = {
    agentsSelectionTable: '[data-agents-selection]',
    agentSelectionForm: '[data-agent-selection-form]',
    submitAgentSelections: '[data-submit-agent-selections]',
    agentImportingSuccessful: '[data-articles-imported-success]',
    agentImportingFailed: '[data-articles-imported-failure]'
}

export default PmgAgentSelectionView;
