import * as React from "react";
import * as _ from "underscore";

interface ResultMetadataItemProps {
  title: string;
  value: string | number;
}

export interface ResultMetadataColumnViewProps {
  title?: string;
  items: Array<ResultMetadataItemProps>;
  cssClassName: string;
}

export default class ResultMetadataColumnView extends React.Component<ResultMetadataColumnViewProps, {}> {
  render() {
    return (
      <div className={this.props.cssClassName}>
        { this.renderTitle() }
        <dl className="meta__body">
          { this.renderMetadata() }
        </dl>
      </div>
    );
  }

  private renderTitle() {
    if (this.props.title) {
      return <h2 className="meta__title">{this.props.title}</h2>;
    }
  }

  private renderMetadata() {
    return _.map(this.props.items, (item: ResultMetadataItemProps, idx) => {
      return (
        <span key={idx}>
          <dt className="meta__label">{item.title}:</dt>
          {" "}
          <dd className="meta__value"
              dangerouslySetInnerHTML={{ __html: item.value.toString() }}></dd>
        </span>
      );
    });
  }
}
