import Marionette from 'backbone.marionette';
import ContactGroupContactsView from 'views/settings/contacts/contactGroupContactsView'
import template from 'text-loader!templates/newsroom/readerAudienceLayout.html';
import 'jquery.fastLiveFilter';
import _ from 'underscore';
import $ from 'jquery';
import I18n from 'i18n';
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import handleTooltips from 'models/handleTooltips';

class ReaderAudienceLayout extends Marionette.Layout {
    initialize(options) {
        _.bindAll(this, '_updateUserCount', '_addressbookSearch');
        this.contactsView = options.contactsView;
        this.addressbookView = options.addressbookView;
        this.contactGroupsCollapsed = {};
        this.editable = options.editable;
        handleTooltips(this)
    }

    showAudienceWithAddressbook(props) {
        const contactsView = <ContactGroupContactsView {...props}/>
        ReactDOM.render(contactsView, this.ui.contactList[0]);
        this.trigger('registerTooltips')
    }

    serializeData() {
        return { editable: this.editable };
    }

    _registerListeners() {
        this.listenTo(this.contactsView.collection, 'add remove reset',
            this._updateUserCount);
        this.listenTo(this.contactsView, 'itemview:contactGroup:render',
            this._handleAudienceContactGroupRendering);
        this.listenTo(this.addressbookView, 'itemview:contactGroup:render',
            this._handleAddressbookContactGroupRendering);
        this.listenTo(this.contactsView, 'itemview:contactGroup:toggle',
            this._handleContactGroupToggle);
        this.listenTo(this.addressbookView, 'itemview:contactGroup:toggle',
            this._handleContactGroupToggle);
    }

    _handleAudienceContactGroupRendering(view) {
        this._handleContactGroupRendering(view, false);
    }

    _handleAddressbookContactGroupRendering(view) {
        this._handleContactGroupRendering(view, true);
    }

    _handleContactGroupRendering(view, addressbook) {
        const id = view.model.id;
        const state = this.contactGroupsCollapsed[id];
        if (_.isUndefined(state) || state.addressbook !== !!addressbook) {
            this.contactGroupsCollapsed[id] = {
                addressbook: !!addressbook,
                collapsed: !!addressbook
            };
        }

        view.collapseOrExpandContactGroup(
            this.contactGroupsCollapsed[id].collapsed);
    }

    _handleContactGroupToggle(view) {
        const id = view.model.id;
        if (_.isUndefined(id)) { return; }

        this.contactGroupsCollapsed[id].collapsed =
            !this.contactGroupsCollapsed[id].collapsed;
        view.collapseOrExpandContactGroup(
            this.contactGroupsCollapsed[id].collapsed);
    }

    _updateUserCount(numShown) {
        let audienceCount;
        if (numShown === undefined) {
            const itemsSelector = '[data-audience-list-container] [data-contact]:visible';
            audienceCount = this.$(itemsSelector).length;
        } else {
            audienceCount = numShown;
        }
        let key = 'webapp.reader_admin.edit.audience.list_headline_audience';
        let label = I18n.t(key, { audience_count: audienceCount });
        this.$(this.ui.userCount).html(label);
    }

    _handleHeaderClick() {
        const opened = !this.addressbookOpened;
        this._updateAddressbookVisibility(opened);
    }

    _updateAddressbookVisibility(visible) {
        this.ui.addressbookIcon.toggleClass('icon-triangle-right', !visible);
        this.ui.addressbookIcon.toggleClass('icon-triangle-down', visible);
        this.ui.addressbook.toggleClass('hidden', !visible);
        this.addressbookOpened = visible;
    }

    _updateAddContactVisibility(visible) {
        this.addressbookView.fixAddContactDisplay()
        if (visible) {
            this.addressbookView.showAndUpdateAddContact(this.ui.searchInput.val())
        } else {
            this.addressbookView.hideAddContact()
        }
    }

    onRender() {
        // this.contactList.show(this.contactsView);
        // this.addressbook.show(this.addressbookView);
        // this._updateAddressbookVisibility(false);
        // this._updateAddContactVisibility(false);
    }

    onShow() {
        // this.initFastLiveFilter();
    }

    initFastLiveFilter() {
        let _this = this;
        const selectorSuffix = '[data-audience-items-container]';
        const contactsSelector = '[data-audience-list-container] ' + selectorSuffix;
        this.$(this.ui.searchInput).fastLiveFilter(contactsSelector, {
            timeout: 200,
            callback: _this._updateUserCount,
            textContentSelector: this._textContentSelector
        });
        const addressbookSelector = '[data-addressbook] ' + selectorSuffix;
        this.$(this.ui.searchInput).fastLiveFilter(addressbookSelector, {
            timeout: 200,
            callback: _this._addressbookSearch,
            textContentSelector: this._textContentSelector
        });
        this.ui.searchInput.change();
    }

    _textContentSelector(el) {
        return $(el).attr('data-filterable-text') || '';
    }

    _addressbookSearch() {
        if (this.ui.searchInput.val()) {
            this._updateAddressbookVisibility(true);
            this._updateAddContactVisibility(true);
        } else {
            this._updateAddContactVisibility(false);
        }
    }
}

ReaderAudienceLayout.prototype.template = template;
ReaderAudienceLayout.prototype.ui = {
    searchInput: '[data-contact-search]',
    userCount: '[data-user-count]',
    addressbookHeader: '[data-addressbook-header]',
    addressbookIcon: '[data-addressbook-icon]',
    addressbook: '[data-addressbook]',
    contactList: '[data-contact-group-list]'
};
ReaderAudienceLayout.prototype.regions = {
    contactList: '[data-audience-list-container]',
    addressbook: '[data-addressbook]',
    orderModal: '[data-order-modal]'
};
ReaderAudienceLayout.prototype.events = {
    'click @ui.addressbookHeader': '_handleHeaderClick'
};

export default ReaderAudienceLayout;
