import Holiday from './holiday';
import I18n from 'i18n';

export default function swissHolidays(): Holiday[] {
  return [
    {
      id: 'ch',
      region: 'ch',
      name: I18n.t(
        'webapp.reader_admin.edit.weekday_schedule.national_switzerland'
      ),
    },
    {
      id: 'ch_ag',
      region: 'ch_ag',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ag'),
    },
    {
      id: 'ch_ai',
      region: 'ch_ai',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ai'),
    },
    {
      id: 'ch_ar',
      region: 'ch_ar',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ar'),
    },
    {
      id: 'ch_be',
      region: 'ch_be',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.be'),
    },
    {
      id: 'ch_bl',
      region: 'ch_bl',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.bl'),
    },
    {
      id: 'ch_bs',
      region: 'ch_bs',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.bs'),
    },
    {
      id: 'ch_fr',
      region: 'ch_fr',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.fr'),
    },
    {
      id: 'ch_ge',
      region: 'ch_ge',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ge'),
    },
    {
      id: 'ch_gl',
      region: 'ch_gl',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.gl'),
    },
    {
      id: 'ch_gr',
      region: 'ch_gr',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.gr'),
    },
    {
      id: 'ch_ju',
      region: 'ch_ju',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ju'),
    },
    {
      id: 'ch_lu',
      region: 'ch_lu',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.lu'),
    },
    {
      id: 'ch_ne',
      region: 'ch_ne',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ne'),
    },
    {
      id: 'ch_nw',
      region: 'ch_nw',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.nw'),
    },
    {
      id: 'ch_ow',
      region: 'ch_ow',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ow'),
    },
    {
      id: 'ch_sg',
      region: 'ch_sg',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.sg'),
    },
    {
      id: 'ch_sh',
      region: 'ch_sh',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.sh'),
    },
    {
      id: 'ch_so',
      region: 'ch_so',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.so'),
    },
    {
      id: 'ch_sz',
      region: 'ch_sz',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.sz'),
    },
    {
      id: 'ch_tg',
      region: 'ch_tg',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.tg'),
    },
    {
      id: 'ch_ti',
      region: 'ch_ti',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ti'),
    },
    {
      id: 'ch_ur',
      region: 'ch_ur',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.ur'),
    },
    {
      id: 'ch_vd',
      region: 'ch_vd',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.vd'),
    },
    {
      id: 'ch_vs',
      region: 'ch_vs',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.vs'),
    },
    {
      id: 'ch_zg',
      region: 'ch_zg',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.zg'),
    },
    {
      id: 'ch_zh',
      region: 'ch_zh',
      name: I18n.t('webapp.reader_admin.edit.weekday_schedule.regions.ch.zh'),
    },
  ];
}
