import Backbone from 'backbone'
import when from 'when'

var RtvPriceOfNextUnit = Backbone.Model.extend({
  url: 'api/v3/rtv/price_of_next_unit',
  load: function () {
    return when(this.fetch());
  }
});

export default RtvPriceOfNextUnit;

