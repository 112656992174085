import * as Backbone from 'backbone'
import 'backbone.model.file-upload'
import pusherWrapper from 'models/pusherWrapper';

interface Attachment {
  id?: number
  caption?: string
  file?: File
}

export interface EditedAgentAttrs {
  id: number
  title: string
  text: string
  rich_text: string
  attachments: Array<Attachment>
}

export default class EditedAgentResult extends Backbone.Model {
  buildUrl() {
    return `/api/v3/agent_results/${this.get('id')}/edit`
  }

  _buildData(): FormData {
    let formData = new FormData()
    formData.append('socket_id', pusherWrapper.socketId)
    formData.append('agent_result[title]', this.get('title'))
    formData.append('agent_result[text]', this.get('text'))
    if (!(typeof this.get("rich_text") === "undefined")) {
      formData.append("agent_result[rich_text]", this.get("rich_text"));
    }
    formData.append('agent_result[sending_attachments]', this.get('sending_attachments'))
    let attachments: Array<Attachment> = this.get('attachments')
    let sources = this.get("sourceData");
    if (sources) {
      const newSource = sources.newSource;
      const oldSource = sources.oldSource;
      formData.append("old_source[id]", oldSource.get("id"));
      formData.append("old_source[name]", oldSource.get("name"));
      formData.append("new_source[id]", newSource.get("id"));
      formData.append("new_source[name]", newSource.get("name"));
    }
    attachments.forEach((attachment, index) => {
      if (attachment.id) {
        formData.append(`agent_result[attachments][${index}][id]`, attachment.id.toString())
      }
      if (attachment.caption) {
        formData.append(`agent_result[attachments][${index}][caption]`, attachment.caption)
      }
      if (attachment.file) {
        formData.append(`agent_result[attachments][${index}][file]`, attachment.file)
      }
    })
    return formData
  }

  sync(method: any, model: any, options: any): JQueryXHR {
    options.formData = true
    options.url = this.buildUrl()
    options.type = 'POST'
    options.processData = false
    options.contentType = false
    let data = this._buildData()
    options.data = data
    return super.sync(method, model, options)
  }
}
