import SceneController from 'controllers/sceneController';
import ReaderTimelineController
    from 'controllers/newsroom/readerTimelineController';
import singleAsyncCall from 'libraries/singleAsyncCall';
import _ from 'underscore';
import vent from 'libraries/vent';
import ReaderResultDetailController
    from 'controllers/newsroom/readerResultDetailController';
import MultiReaderResultDetailController
    from 'controllers/newsroom/multiReaderResultDetailController';
import ResultListLandingPageView from
    'views/agents/resultListLandingPageView';
import ReaderPlaceholderView
    from 'views/newsroom/readerPlaceholderView';

export default class ResultsSceneController extends SceneController {
    show() {
        this.timeline = new ReaderTimelineController({
            readerConfigurationId: this.options.readerConfigurationId,
            readerCategoryId: this.options.readerCategoryId
        });
        this.showInLeftColumn(this.timeline.view);
        this._showPlaceHolder();
        this._registerTimelineListeners();
    }

    _registerTimelineListeners() {
        if (this.timeline) {
            var callback =
                singleAsyncCall(
                'handleReaderResultSelectionChange',
                this.handleReaderResultSelectionChange
            );
            this.listenTo(this.timeline.selection, 'add', callback);
            this.listenTo(this.timeline.selection, 'remove', callback);
            this.listenTo(this.timeline.selection, 'reset', callback);
        }
    }

    close() {
        if (!_.isUndefined(this.detailController)) {
            this.detailController.view.close();
            this.detailController.close();
            delete this.detailController.view;
            delete this.detailController;
        }
    }

    _showLandingPage() {
        var view = new ResultListLandingPageView();
        this.showInRightColumn(view);
        vent.trigger('layout:focus-left');
    }

    _showPlaceHolder() {
        const placeholder = new ReaderPlaceholderView();
        this.showInRightColumn(placeholder);
    }

    handleReaderResultSelectionChange() {
        var selection = this.timeline.selection;
        if (!_.isUndefined(this.rightHandController)) {
            this.rightHandController.close();
        }

        if (selection.isEmpty()) {
            this._showLandingPage();
        } else {
            if (selection.length === 1) {
                var result = selection.first();

                let options = {
                    agentResult: result.get('agent_result'),
                    readerResult: result
                };

                this.detailController =
                    new ReaderResultDetailController(options);
                this.rightHandController = this.detailController;
            } else {
                this.rightHandController =
                    new MultiReaderResultDetailController(selection.models);
            }
            this.showInRightColumn(this.rightHandController.view);
            vent.trigger('layout:focus-right');
        }
    }
}
