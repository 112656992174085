import Marionette from 'backbone.marionette';
import _ from 'underscore';
import MultiReaderResultDetailView from
    'views/newsroom/multiReaderResultDetailView';
import ReaderResultsSelectionCollection from
    'collections/newsroom/readerResultsSelectionCollection';
import $ from 'jquery';
import Config from 'libraries/configuration';
import appState from 'models/appStateInstance';
import ReaderResultTagController from './readerResultTagController';
import SelectedAgentResults from 'models/selectedAgentResults';
import Notificator from 'libraries/notificator';
import OnlineClipOrder from 'libraries/onlineClipOrder';
import I18n from 'i18n';
import pusherWrapper from 'models/pusherWrapper';
import Shortcutter from 'libraries/shortcutter';

class MultiReaderResultDetailController extends Marionette.Controller {
    initialize(readerResults) {
        this.readerResults = readerResults;
        this._bindShortcuts();
        this.agentResults = this.readerResults.map((readerResult) => {
            return readerResult.get("agent_result");
        })
        this.view = new MultiReaderResultDetailView({
            readerResults: this.readerResults,
            selectedReaderResult: _.last(this.readerResults)
        });
        this.listenTo(
            this.view,
            'click:readerResultsDelete',
            () => {
                this._handleResultDeletion({deleteTags: false});
            }
        );
        this.listenTo(
            this.view,
            'click:readerResultsAndTagsDelete',
            () => {
                this._handleResultDeletion({deleteTags: true});
            }
        );
        this.listenTo(this.view, 'render', this.showTags);
        this.listenTo(this.view, 'click:onlineOrder', () => {
            this._displayOnlineClipOrderModal();
        });
        this.listenTo(this.view, 'modal:multiOnlineClipOrderConfirm', this._orderOnlineClip);
        this.agentResults.forEach((agentResult) => {
            this.listenTo(agentResult, 'updatedByOtherUser', this.view.render);
        });
        this.listenTo(pusherWrapper, 'agent_result_changed',
            this._handleAgentResultPusherChange);
    }

    onClose() {
        this._unbindShortcuts();
    }

    _bindShortcuts() {
        const self = this;
        Shortcutter.bindInModule('newsroom', 'l', function () {
          if (self.view._resultsLicensable()) {
            self._displayOnlineClipOrderModal();
          }
        });
    }
    _unbindShortcuts() {
        Shortcutter.unbindInModule('newsroom', 'l');
    }

    _handleAgentResultPusherChange(data) {
        this.agentResults.forEach((agentResult) => {
          if (data.agent_result_id === agentResult.get('id')) {
            var notification =
              I18n.t('webapp.notifications.agent_results.concurrent_update');
            Notificator.showNotification(notification);
          }
        })
    }

    _displayOnlineClipOrderModal() {
        this.view.showOnlineClipOrderModal();
    }

    _orderOnlineClip() {
        let _this = this;
        const selectedResultsIds = this.agentResults.map((agentResult) => {
            return agentResult.get("id");
        })
        OnlineClipOrder.order(selectedResultsIds).then(
          function () {
            let message = I18n.t(
              'webapp.agents.results.order_full_text.multiple_results.order_successful'
            );
            Notificator.showNotification(message);
          },
          function () {
            _this._orderError();
          }
        );
    }
    
    _orderError() {
        let message = I18n.t("webapp.notifications.error.order_failed");
        Notificator.showNotification(message);
    }

    showTags() {
        let agentResults = this.readerResults.map(r => r.get('agent_result'));
        let readerResults = new SelectedAgentResults(agentResults);
        this.tagController = new ReaderResultTagController(
          readerResults.collection
        );
        this.tagController.view && this.view.showTags(this.tagController.view);
    }

    _handleResultDeletion(params) {
        if (!appState.isBusy()) {
            appState.requestStart();

            var onComplete = function() {
                appState.requestCompleted();
            };

            let deletedResults = new ReaderResultsSelectionCollection();
            let resultIds = _.map(this.readerResults, function (readerResult) {
                return readerResult.get("id");
            });
            deletedResults.models = this.readerResults;

            let url = Config.apiUrl;
            if (params.deleteTags === true) {
                url = url + 'reader_results_with_tags/' + resultIds.join(';');
            } else {
                url = url + 'reader_results/' + resultIds.join(';');
            }

            $.ajax({
                url: url,
                type: 'DELETE',
                context: this,
                complete: onComplete
            });

            _.each(deletedResults.toArray(), (result) => {
                result.trigger("destroy", result);
            });
        } else {
            appState.displayWarning();
        }
    }
}

export default MultiReaderResultDetailController;
