import * as Backbone from "backbone";
import * as _ from "underscore";
import I18n from "i18n";

export default class Topic extends Backbone.Model {
  parseName() {
    const names: { [key: string]: string } = {
      "en": this.get("name_en"),
      "de": this.get("name_de"),
      "fr": this.get("name_fr"),
      "it": this.get("name_it"),
    };
    const supportedLocales = _.keys(names);
    const locale = _.contains(supportedLocales, I18n.locale.split('-')[0]) ? I18n.locale.split('-')[0] : "de";
    let name = names[locale];
    if (name) {
      return name
    }

    let identifier = this.get('identifier')
    if (!this.get('parent')) {
      identifier = `${identifier}.__main`
    }
    return I18n.t(identifier)
  }
}
