import WidgetView from 'views/dashboard/widgetView'
import _ from 'underscore'
import template from 'text-loader!templates/dashboard/widget/swissFacebookWallWidget.html'

WidgetView.SwissFacebookWallWidget = WidgetView.extend({
  template: template,
  onInitialize: function () {
    this.enableAutoRefresh();
  },
  prepareSerializedData: function () {
    var attributes = _.clone(this.model.attributes);
    if (attributes.picture) {
      attributes.picture.style = this.pictureStyle();
      attributes.text = this.truncatedText();
    }
    return attributes;
  },
  pictureStyle: function () {
    var picture = this.model.get('picture');
    var maxWidth = 700;
    var maxHeight = 400;
    if (picture.width > maxWidth || picture.height > maxHeight) {
      var max_aspect_ratio = maxWidth / maxHeight;

      var aspect_ratio = picture.width / picture.height;

      if (aspect_ratio > max_aspect_ratio) {
        return "width: " + maxWidth + "px";
      } else {
        return "height: " + maxHeight + 'px';
      }
    } else {
      return "";
    }
  },
  truncatedText: function () {
    var maxLength = 180;
    return this._truncateLabel(this.model.get('text'), maxLength);
  },
  prepareWidgetBody: function () {
    if (!this.model.get('picture')) {
      this.$el.hide();
    }
  }
});

export default WidgetView.SwissFacebookWallWidget;

