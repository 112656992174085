import OptionsProvider, { GlobalSearchOption } from './optionsProvider'
import tagsPromise from 'models/tagsInstancePromise'

export default class TagsOptionsProvider extends OptionsProvider {
  results(term: string) {
    return new Promise<GlobalSearchOption[]>((resolve) => {
      tagsPromise.fetch().then((tags: any) => {
        let options = tags.toGlobalSearchSelect2(term);
        let filtered = options.filter((opt: any) => {
          return opt.text.toUpperCase().indexOf(term.toUpperCase()) >= 0;
        })
        resolve(filtered)
      })
    })
  }
}
