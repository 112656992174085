import Marionette from "backbone.marionette";
import SearchSettingsView from "views/research/searchSettingsView";
import TopicFormController from "controllers/research/topicFormController";
import SourceCategorySelectionFormController from "controllers/agents/sourceCategorySelectionFormController";
import ClipDetailController from "controllers/research/clipDetailController";
import AgentTopic from "models/agentTopic";
import _ from "underscore";
import MultiClipDetailController from 'controllers/research/multiClipDetailController';
import SourceCategoriesInstancePromise from 'models/sourceCategoriesInstancePromise';

class SearchSettingsController extends Marionette.Controller {
  initialize(options) {
    SourceCategoriesInstancePromise.fetch();
    this.agent = options.agent;
    this.view = new SearchSettingsView();
    this.topic = options.topic;
    _.bindAll(this,
              '_showTopicFormRegion',
              '_showSourceCategorySelectionFormRegion');
    this.listenTo(this.view, 'render', () => {
      SourceCategoriesInstancePromise.done((sourceCategories) => {
        this._showTopicFormRegion();
        this._showSourceCategorySelectionFormRegion({preloadedSourceCategories: sourceCategories});
        this.view.switchToTopics();
      });
    });
    this.listenTo(this.view, 'clicked:topicsTab',
                  this._switchToTopics);
    this.listenTo(this.view, 'clicked:sourceCategoriesTab',
                  this._switchToSourceCategories);
    this.listenTo(this.view, 'clicked:reset',
                  this._resetForm);
    this.listenTo(this.view, 'clicked:search',
                  this._saveForm);
  }

  onClose() {
    if (!_.isUndefined(this.clipController)) {
        this.clipController.view.close();
        this.clipController.close();
    }
    if (!_.isUndefined(this.topicFormController)) {
        this.topicFormController.close();
    }
    if (!_.isUndefined(this.sourceCategorySelectionFormController)) {
        this.sourceCategorySelectionFormController.close();
    }
    this.view.close();
  }

  _switchToTopics() {
    this.closeClip();
    this.view.switchToTopics();
  }

  _switchToSourceCategories() {
    this.closeClip();
    this.view.switchToSourceCategories();
  }

  _showTopicFormRegion() {
    this.topic = this.topic || new AgentTopic();
    this.topicFormController = new TopicFormController({
      topic: this.topic
    });
    this.topicFormView = this.topicFormController.view;
    this.view.showTopic(this.topicFormView);
  }

  _showSourceCategorySelectionFormRegion(viewOptions) {
    this.sourceCategorySelectionFormController =
      new SourceCategorySelectionFormController({
        sourceCategoryIds: [],
        excludedCategoryIdentifiers: ['sm', 'rtv'],
        ...viewOptions
      });
    this.sourceCategoryFormView =
      this.sourceCategorySelectionFormController.view;
    this.view.showSourceCategories(this.sourceCategoryFormView);
    this.sourceCategorySelectionFormController.setDefaultCategories();
  }

  _resetForm() {
    this.topicFormController.resetForm();
    this.sourceCategorySelectionFormController.setDefaultCategories();
  }

  _saveForm() {
    if (this._atLeastOneTermGiven() && this.topicFormController.isFormValid()) {
      this.trigger("clicked:search", this.searchOptions());
    }
  }

  _atLeastOneTermGiven() {
    var topicBuildParameters = this.topicFormView.buildParameters();
    return topicBuildParameters.terms.length > 0;
  }

  searchOptions() {
    var topicBuildParameters = this.topicFormView.buildParameters();
    var sourceCategoryIds =
          this.sourceCategorySelectionFormController.selectedCategoryIds();
    return {
      topicBuildParameters: topicBuildParameters,
      sourceCategoryIds: sourceCategoryIds
    };
  }

  showClip(clip) {
    this.view.deactivateTabs();
    this.clipController = new ClipDetailController(clip);
    this.view.showInDetailView(this.clipController.view);
  }

  showClips(esResults) {
    this.view.deactivateTabs();
    this.clipController = new MultiClipDetailController(esResults);
    this.view.showInDetailView(this.clipController.view);
  }

  closeClip() {
    this.view.activateTabs();
    if (!_.isUndefined(this.clipController)) {
      this.clipController.view.close();
      this.clipController.close();
      this.trigger('clip:closed');
    }
  }

  onlyCloseClip() {
    if (!_.isUndefined(this.clipController)) {
      this.clipController.view.close();
      this.clipController.close();
    }
  }
}

export default SearchSettingsController;
