import Backbone from 'backbone'
import template from 'text-loader!templates/reporting/collectionItemView.html'
import 'backbone.marionette'

var View = Backbone.Marionette.ItemView.extend({
  template: template,
  tagName: 'tr',
  triggers: {
    'click .title': 'clicked:title'
  },
  initialize: function () {
    this.on('clicked:title', () => {
      this.model.open();
    });
  },
  serializeData: function () {
    var m = this.model;
    var updater = m.get('updater');
    return {
      updated_at: m.get('updated_at'),
      type:       m.get('type'),
      label:      this.formatLabelFromReportType(),
      title:      m.get('title'),
      topic_description: m.topicDescription(),
      updater: updater ? updater.get('full_name') : ""
    };
  },
  formatLabelFromReportType: function () {
    var report     = this.model;
    var reportType = report.get('type');

    if (reportType === 'Medienspiegel') {
      return "PDF";
    }

    if (reportType === 'Newsletter') {
      return "eMail";
    } else {
      return report.get('type').replace("Report", "");
    }
  }
});

export default View;

