import _ from 'underscore'
import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/components/modal.html'
import 'bpopup'

var ModalView = Marionette.ItemView.extend({
  initialize: function(opt) {
    this.title = opt.title;
    this.headline = opt.headline;
    this.confirmation = opt.confirmation;
    this.warning = opt.warning;
    this.confirmationLabel = opt.confirmationLabel;
    this.cancelLabel = opt.cancelLabel;
    this._confirmed = false;

    if (opt.positiveButton) {
      this.confirmationButtonStyle = 'button--primary';
    } else {
      this.confirmationButtonStyle = 'button--negative';
    }
  },

  template: template,

  onRender: function() {
    if (!this.disabled) {
      this.initializeModal();
    }
  },

  serializeData: function() {
    return {
      title: this.title,
      headline: this.headline,
      confirmation: this.confirmation,
      warning: this.warning,
      confirmationLabel: this.confirmationLabel,
      showHeader:  !_.isEmpty(this.headline),
      cancelLabel: this.cancelLabel,
      confirmationButtonStyle: this.confirmationButtonStyle,
      showButtons: true
    };
  },

  hideConfirmationButton: function() {
    this.ui.modalConfirmButton.hide();
  },

  showConfirmationButton: function() {
    this.ui.modalConfirmButton.show();
  },

  ui: {
    modalElement: '[data-modal]',
    modalConfirmButton: '[data-modal-confirm]'
  },

  modalConfirm: function (modal) {
    modal._confirmed = true;
    modal.close();
    modal.triggerModalConfirmed();
  },

  initializeModal: function () {
    var _this = this;
    this.ui.modalConfirmButton.on('click', function () {
      _this.modalConfirm(_this);
    });
    this.ui.modalConfirmButton.on('keydown', function (e) {
      if (e.key === "Enter") {
        _this.modalConfirm(_this);
      }
    });
  },

  close: function() {
    if (this.modal) {
      this.modal.bPopup().close();
    }
  },

  triggerModalConfirmed: function () {
    this.trigger('modal:confirmed');
  },

  showModal: function() {
    if (window.event) {
      event.preventDefault();
    }
    var self = this;
    this.modal = this.ui.modalElement.bPopup({
      onClose: function () {
        self._onModalClose();
      }
    });
    this.ui.modalConfirmButton.focus();
  },

  _onModalClose: function() {
    if (!this._confirmed) {
      this.trigger('modal:cancelled');
    }
  }
});

export default ModalView;

