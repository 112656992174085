import _ from 'underscore'
import template from 'text-loader!templates/reporting/preview.html'
import Marionette from 'backbone.marionette'
import App from 'app'

var ReportPreviewView = Marionette.ItemView.extend({
  template: template,
  serializeData: function () {
    var newsletter = this.model;
    return  {
      title: newsletter.get('title'),
      sender: newsletter.get('sender'),
      previewUrl: '/api/v3/' + newsletter.get('preview_link'),
      recipients: _.map(newsletter.get('recipients'), function (r) { return r.toString(); }).join(', '),
      editable: newsletter.isEditable()
    };
  },
  triggers: {
    'click #deliver': 'clicked:deliver',
    'click #cancel': 'clicked:cancel',
    'click #edit': 'clicked:edit'
  },
  initialize: function () {
    this.on('clicked:deliver', () => {
      this.$('#deliver').attr('disabled', 'disabled');
      this.deliverReport();
    });
    this.on('clicked:edit', () => {
      this.editReport();
    });
    this.on('clicked:cancel', () => {
      var report = this.model;
      report.open();
    });
  },
  editReport: function () {
    var report = this.model;
    report.edit();
  },
  deliverReport: function () {
    var report = this.model;
    var previewView = this;

    report.deliver(function () {
      previewView.$('#deliver').removeAttr('disabled');
      App.Reporting.showReportsList();
    });
  }
});

export default ReportPreviewView;

