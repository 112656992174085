import Config from 'libraries/configuration'
import _ from 'underscore'
import Tag from 'models/tag'
import tagsInstance from 'collections/tagsInstance'
import BaseObject from 'libraries/baseObject'
import pusherWrapper from 'models/pusherWrapper'
import Notificator from 'libraries/notificator'
import I18n from 'i18n'
import vent from "libraries/vent";

var Tagging = Tag.extend({
  initialize: function (options) {
    this.agentResultIds = options.agentResultIds;
  },
  urlRoot: function () {
    return Config.apiUrl + "agent_results/taggings/";
  },
  isNew: function () { return false; },
  toJSON: function () {
    return {
      agent_result_id: this.agentResultIds,
      tag_name: this.get('name'),
      socket_id: pusherWrapper.socketId
    };
  },
  sync: function (method, model, options) {
    if (method === "delete") {
      options.data = 'socket_id=' + pusherWrapper.socketId +
        '&agent_result_id=' + this.agentResultIds;
    }
    return Tag.prototype.sync.call(this, method, model, options);
  }
});

var TaggingManager = BaseObject.extend({
  initialize: function(agentResults) {
    this.agentResults = agentResults;
  },
  generateTagging: function (tag) {
    return new Tagging(
      {
        agentResultIds: this._agentResultIds(),
        name: tag.name,
        id: tag.name
      }
    );
  },
  addTaggingToAgentResults: function (tag) {
    _.each(this.agentResults.models, function (agentResult) {
      var existingTags = agentResult.get('tags');
      if (!existingTags.findWhere(tag)) {
        existingTags.add(tag);
      }
    });
  },
  removeTaggingFromAgentResults: function (tag) {
    _.each(this.agentResults.models, function (agentResult) {
      var existingTag = agentResult.get('tags').findWhere(tag);
      if (existingTag) {
        agentResult.get('tags').remove(existingTag);
      }
    });
  },
  triggerResultTagChangeEvent: function (agentResultIds) {
    const affectedResults = this.agentResults.filter(r => _.contains(agentResultIds, r.get('id')));
    vent.trigger(
      'changed:resultTagged',
      affectedResults.map(r => [
        r.get('id'),
        r.get('tags').toJSON().reduce((a, b) => a.concat(b), []),
      ]),
    );
  },
  _agentResultIds: function () {
    return _.map(this.agentResults.models, function (agentResult) {
      return agentResult.get('id');
    });
  }
});

var TaggingManagerPublicInterface = BaseObject.extend({
  initialize: function(agentResults) {
    this.taggingManager = new TaggingManager(agentResults);
  },
  createTaggings: function(tag, errorCallback) {
    var tagging = this.taggingManager.generateTagging(tag);
    var self = this;
    tagging.save(null,
                 {
                   type: 'POST',
                   success: function (tag) {
                     var collection = _.first(tag.get("collection"));
                     _.each(collection.tags, function (tag) {
                       tagsInstance.add(tag);
                     });
                     self.taggingManager.triggerResultTagChangeEvent(
                       tagging.get('agentResultIds')
                     );
                   },
                   error: function(tag) {
                     var message = I18n.t("webapp.notifications.error.tag.name_invalid");
                     Notificator.showErrorNotification(message);
                     self.taggingManager.removeTaggingFromAgentResults({name: tag.get('name')});
                     self.taggingManager.triggerResultTagChangeEvent(tagging.get('agentResultIds'));
                     errorCallback()
                   }
                 }
                );
    this.taggingManager.addTaggingToAgentResults(tag);
  },
  destroyTaggings: function(tag) {
    var self = this;
    var tagging = this.taggingManager.generateTagging(tag);
    tagging.destroy({
      success: function() {
        self.taggingManager.removeTaggingFromAgentResults({name: tagging.get('name')});
        self.taggingManager.triggerResultTagChangeEvent(tagging.get('agentResultIds'));
      },
      error: function() {
        self.taggingManager.addTaggingToAgentResults({name: tagging.get('name')});
        self.taggingManager.triggerResultTagChangeEvent(tagging.get('agentResultIds'));
      },
    });
    this.taggingManager.removeTaggingFromAgentResults(tag);
  }
});

export default TaggingManagerPublicInterface;
