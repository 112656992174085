import * as React from "react";

export interface Props {
  label: string
}
export const PlaceholderView = (props: Props) => {
  return (
    <div className="pal">
      <div className="notice">
        <p><i className="icon-cog notice__marker"></i> {props.label}</p>
      </div>
    </div>
  )
}
