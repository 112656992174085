import Backbone from 'backbone'
import SourceCategories from 'collections/sourceCategories'

var SourceCategory = Backbone.Model.extend({
  parse: function (response) {
    this.parseSubCategories(response);
    return response;
  },
  parseSubCategories: function (response) {
    response.sub_categories = new SourceCategories(response.sub_categories, { parse: true });
  }
});

export default SourceCategory;

