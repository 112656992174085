import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/settings/contacts/contactDetails.html';
import _ from 'underscore';
import $ from 'jquery';

const contactGroupSerializer = {
    serializeContactGroups: function (contactGroups, contact) {
        return contactGroups.map(function (contactGroup) {
            return {
                id: contactGroup.get('id'),
                name: contactGroup.get('name'),
                active: _.contains(contact.get('contact_group_ids'),
                                   contactGroup.get('id'))
            };
        });
    }
};

class ContactDetailView extends Marionette.Layout {
    initialize(options) {
        _.bindAll(this,
                  'showDeletionModal',
                  'setContactGroups',
                  'disableDeleteButton',
                  'enableDeleteButton');
        this.contact = options.contact;
        this.contactGroups = new Backbone.Collection();
        this.contactGroups.comparator = 'name';
        this.isContactUser = options.contact.attributes.is_user_contact;
    }

    onRender() {
        this.$(this.ui.deletionModalSeparator).hide();
        if (this.isContactUser) {
            this.$(this.ui.deletionModalButton).hide();
        } else {
            this.$(this.ui.deletionModalButton).show();
        }
    }

    showContactView(view) {
        this.contactItem.show(view);
    }

    showDeletionModal(view) {
        this.deletionModalView = view;
        this.deletionModal.show(view);
        this.$(this.ui.deletionModalSeparator).show();
    }

    setContactGroups(contactGroups) {
        this.contactGroups = contactGroups;
        this.render();
    }

    serializeData() {
        let name = this.contact.get('name');
        let email = this.contact.get('email');
        return {
            contactGroups: contactGroupSerializer
              .serializeContactGroups(this.contactGroups, this.contact),
            name: name,
            email: email,
            iconType: this.contact.isUserContact() ? 'user' : 'envelope',
            title: name || email,
            notUser: !this.isContactUser,
        };
    }

    toggleContactGroup(contactGroup) {
        this.$('[data-id="' + contactGroup.get('id') + '"]')
            .toggleClass('is-active');
    }

    _selectedContactGroupIds() {
        var selected = _.filter(this.ui.contactGroups,
                                function (contactGroup) {
            return $(contactGroup).hasClass('is-active');
        });

        return _.map(selected, function (contactGroup) {
            return $(contactGroup).data('id');
        });
    }

    _handleContactGroupClick(e) {
        var groupNode = $(e.target);
        var group = this.contactGroups.findWhere({
            id: groupNode.data('id')
        });

        if (groupNode.hasClass('is-active')) {
            this.trigger('removeContactFromGroup', group);
        } else {
            this.trigger('addContactToGroup', group);
        }

        this.$(e.target).toggleClass('is-active');
    }

    _handleClick(event) {
        event.preventDefault();
        this.isDisabled = this.$('[data-delete]').hasClass('is-disabled');
        if (!this.isDisabled) {       
            this.trigger('showModal');
        }
    }

    disableDeleteButton() {
        this.$('[data-delete]').addClass('is-disabled');
    }

    enableDeleteButton() {
        this.$('[data-delete]').removeClass('is-disabled');
    }
}

ContactDetailView.prototype.template = template;
ContactDetailView.prototype.regions = {
    contactItem: '[data-contact-item]',
    deletionModal: '[data-delete-modal]',
    orderModal: '[data-order-modal]'
};
ContactDetailView.prototype.events = {
    'click [data-contact-group]': '_handleContactGroupClick',
    'click [data-delete]': '_handleClick'
};
ContactDetailView.prototype.ui = {
    contactGroups: '[data-id]',
    deletionModalButton: '[data-delete]',
    deletionModalSeparator: '[data-deletion-modal-separator]'
};

export default ContactDetailView;
