import _ from 'underscore'
import Marionette from 'backbone.marionette'
import tagListTemplate from 'text-loader!templates/settings/tagList.html'
import TagListItemView from 'views/settings/tagListItemView'

var TagListView = Marionette.CompositeView.extend({
  template: tagListTemplate,
  itemView: TagListItemView,
  itemViewContainer: "#tag-list",
  appendHtml: function (collectionView, itemView, index) {
    var child = collectionView.$(this.itemViewContainer).children()[index];

    if (_.isUndefined(child)) {
      collectionView.$(this.itemViewContainer).append(itemView.el);
    } else {
      collectionView.$(child).before(itemView.el);
    }
  },
  initialize: function () {
    this.collection = this.options.tags;

    this.on("itemview:tag:clicked", (itemView) => {
      if (itemView.$el.hasClass("is-active")) {
        this.trigger('tag:deselected', itemView.model);
        this.deselectAll();
      } else {
        this.trigger('tag:selected', itemView.model);
        this.deselectAll();
        this.selectTag(itemView.model);
      }
    });

    this.listenTo(this.collection, 'sort', this.render);
  },
  deselectAll: function () {
    this.children.each(function (view) {
      view.$el.removeClass('is-active');
    });
  },
  selectTag: function (tag) {
    var itemView = this.children.findByModel(tag);
    itemView.$el.addClass('is-active');
  }
});

export default TagListView;

