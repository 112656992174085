import Config from 'libraries/configuration';
import Backbone from 'backbone';
import Tag from 'models/tag';
import _ from 'underscore';

class Tags extends Backbone.Collection {
    url() {
        return Config.apiUrl + "tags";
    }
    parse(response) {
        return response.collection;
    }
    comparator(tag) {
        if (!_.isUndefined(tag.get('name'))) {
            return tag.get('name').toLowerCase();
        } else {
            return null;
        }
    }
    toSelect2() {
        return _.map(this.models, function (tag) {
            return tag.get('name');
        });
    }
    toGlobalSearchSelect2(term) {
        return Tags.toGlobalSearchSelect2(this.models, term);
    }
}

Tags.toGlobalSearchSelect2 = (tags, term) => {
    return _.map(tags, function (tag) {
        let prio = 5;
        if (term && tag.visibleName().toLowerCase() === term.toLowerCase()) {
            prio = 1;
        }
        const smarttag = !tag.isManual();
        if (smarttag) { prio += 1; }
        return {
            id: tag.get("id"),
            type: "tag",
            text: tag.visibleName(),
            priority: prio,
            smarttag: smarttag
        };
    });
}

Tags.prototype.model = Tag;

export default Tags;
