import * as React from "react";

export const Sidebar = (props: any) => {
  return (
    <ul className="nav-tree" data-component="nav-tree">
      {props.children}
    </ul>
  )
}

export default Sidebar
