import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/settings/createSocialMediaMonitor.html'
import I18n from 'i18n'
import handleTooltips from 'models/handleTooltips'
import _ from 'underscore'

var CreateSocialMediaMonitorView = Marionette.Layout.extend({
  template: template,
  triggers: {
    'click [data-submit-form]': 'submitted:form',
    'click [data-reset-form]': 'canceled:form',
    'click [data-channel=facebook]': 'selected:facebook',
    'click [data-channel=facebook_without_comments]': 'selected:facebook_without_comments',
    'click [data-channel=youtube]': 'selected:youtube',
    'click [data-channel=wikipedia]': 'selected:wikipedia',
    'click [data-social-media-monitor-delete]': 'deleted:social_media_monitor'
  },
  ui: {
    identifier: "[data-identifier]",
    dropdown: "[data-channel-dropdown]",
    dropdownTitle: "[data-channel-dropdown-title]",
    dropdownToggle: "[data-toggle]"
  },
  regions: {
    orderModal: '[data-order-modal]'
  },
  initialize: function () {
    handleTooltips(this);
  },
  toggleButtons: function () {
    this.$el.find('[data-submit-form]').toggle();
    this.$el.find('[data-reset-form]').toggle();
  },
  updateChannel: function (channel) {
    if (!_.isString(this.ui.identifier)) {
      this.ui.identifier.attr('placeholder',
                              I18n.t("webapp.agents.edit.smm.channel." + channel + ".placeholder")
                             );
    }
    if (!_.isString(this.ui.dropdownTitle)) {
      this.ui.dropdownTitle.html(
        I18n.t("webapp.agents.edit.smm.channel." + channel + ".name")
      );
    }
    if (!_.isString(this.ui.dropdown)) {
      this.ui.dropdown.click();
    }
  },
  resetForm: function () {
    this.updateChannel("none");
    if (!_.isString(this.ui.identifier)) {
      this.ui.identifier.val("");
    }
  },
  identifier: function () {
    return this.ui.identifier.val();
  },
  serializeData: function () {
    return {
      isCreateForm: _.isUndefined(this.model)
    };
  },
  onShow: function () {
    if (_.isObject(this.model)) {
      var socialMediaMonitor = this.model;

      this.updateChannel(socialMediaMonitor.get('type'));

      this.$el.find('[data-submit-form]').hide();
      this.$el.find('[data-reset-form]').hide();

      if (!_.isString(this.ui.dropdown)) {
        this.ui.dropdownToggle.addClass('disabled');
        this.ui.dropdownToggle.addClass('is-disabled');
      }

      if (!_.isString(this.ui.identifier)) {
        this.ui.identifier.attr('disabled', 'disabled');
        this.ui.identifier.attr('placeholder', socialMediaMonitor.get('name'));
      }
    }
  }
});

export default CreateSocialMediaMonitorView;
