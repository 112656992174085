import Backbone from 'backbone'

class PmgZipFile extends Backbone.Model {
    parse(response) {
        var data = response.object ? response.object : response;
        return data;
    }
}

PmgZipFile.prototype.urlRoot = '/api/v3/pmg_upload_wizard/validate';

export default PmgZipFile;
