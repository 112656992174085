import Marionette from 'backbone.marionette';
import EditableDetailTitleView from 'views/components/editableDetailTitleView'
import EditableSummaryTitleView
    from 'views/newsroom/readerEditableSummaryTitleView';
import template
    from 'text-loader!templates/newsroom/readerNewsroomSummaryLayout.html';
import RichTextEditorController from 'controllers/components/richTextEditorController'
import readerConfigurationsInstance from 'collections/newsroom/configurationsInstance'
import I18n from 'i18n'
import CustomReaderBrandingStyleTagView from 'views/components/customReaderBrandingStyleTagView.js'
import * as _ from 'underscore'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import MultiLanguageTabs from './MultiLanguageTabs';

class ReaderNewsroomSummaryLayout extends Marionette.Layout {
    initialize(options) {
        this.model = options.model;
        this.selectedLanguage = options.selectedLanguage;
        this.languages = ['de', 'en', 'fr'];
        this.configuration = readerConfigurationsInstance.get(this.model.readerConfigurationId);
        this.richTextEditorController = new RichTextEditorController({
          richText: this.model.getText(this.selectedLanguage),
          placeholderText: I18n.t('webapp.reader_admin.summaries.edit.text_placeholder'),
          isSummaryEdit: true
        });
        this.summaryTextView =
          this.richTextEditorController.view
        this.brandingStyleView = new CustomReaderBrandingStyleTagView({
            configuration: this.configuration
        });
        let title = '';
        if (this.model.isPresent()) { title = this.model.getTitle(this.selectedLanguage); }
        this.summaryTitleView = new EditableSummaryTitleView({
            model: this.model,
            titleFieldName: 'title',
            isAgent: false
        });
        this.titleProps = {
          originalTitle: title,
          title: title,
          displayError: false,
          onEdit: (inputValue) => {
            this._renderEditableTitle({ title: inputValue });
            this._handleSave();
          },
          onEnter: () => {
            this.summaryTextView.focusOnText();
          },
          titleEditIcon: false
        }
    }

    onRender() {
        this._renderEditableTitle({})
        this._renderLanguageTabs()
        this.summaryText.show(this.summaryTextView);
        if (this.configuration.attributes.reader_branding) {
            this.summaryBrandingStyle.show(this.brandingStyleView);
        }

    }

    _focusOnText() {
        this.summaryTextView.focusOnText();
    }

    getSummaryTitle() {
      return this.titleProps.title;
    }

    getSummaryText() {
        return this.summaryTextView.getRichText();
    }

    showDeleteModal(view) {
        this.deleteModal.show(view);
    }

    _handleSave() {
      this.trigger('save');
    }

    _renderEditableTitle(props) {
      const fullProps = _.extend({}, this.titleProps, props);
      this.titleProps = fullProps;
      const editableDetailTitleView = <EditableDetailTitleView {...fullProps}/>;
      ReactDOM.render(editableDetailTitleView, this.ui.summaryTitleReact[0]);
    }

    _renderLanguageTabs() {
        const multiLanguageView = <MultiLanguageTabs languages={this.languages} summary={this.model} handleLanguageChange={this.handleLanguageChange.bind(this)} />;
        ReactDOM.render(multiLanguageView, this.ui.languageTabs[0]);
    }

    
    handleLanguageChange(lang) {
        this.selectedLanguage = lang;
        this.titleProps.title = this.model.getTitle(lang);
        this._renderEditableTitle(this.titleProps);
        this.richTextEditorController.setRichText(this.model.getText(lang));
        this.trigger('languageChanged', lang);
    }
}

ReaderNewsroomSummaryLayout.prototype.template = template;
ReaderNewsroomSummaryLayout.prototype.regions = {
    summaryTitle: '[data-summary-title-area]',
    summaryText: '[data-summary-text-area]',
    summaryBrandingStyle: '[data-summary-branding-style]',
    deleteModal: '[data-delete-modal]',
};
ReaderNewsroomSummaryLayout.prototype.ui = {
    summaryTitle: '[data-summary-title]',
    summaryTitleReact: '[data-summary-title-area]',
    languageTabs: '[data-language-tabs]',
    deleteButton: '[data-delete]',
};
ReaderNewsroomSummaryLayout.prototype.events = {
    'focusout [data-summary-text-area]': '_handleSave',
};
ReaderNewsroomSummaryLayout.prototype.triggers = {
    'click @ui.deleteButton': 'delete'
};
export default ReaderNewsroomSummaryLayout;
