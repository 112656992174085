import ResearchResultListItemView from 'views/research/researchResultListItemView';
import moment from 'moment'

class NewResearchResultListItemView extends ResearchResultListItemView {
  serializeData() {
    let clip = this.model.get("clip");
    let sources = this.model.get("sources");
    let primarySource = this.model.primarySource();
    let sourceCount = sources.length;
    let additionalSourceCount;

    if (sourceCount > 1) {
      additionalSourceCount = sourceCount - 1;
    }
    return {
      primary_source_name: primarySource.meta.name,
      additional_source_count: additionalSourceCount,
      publish_time: moment(clip.publish_time),
      title: clip.title,
      mentions: (clip.meta.mentions || []).slice(0, 5),
      primarySourceFavicon: primarySource.meta.favicon_url
    };
  }

  attributes() {
    const clipId = this.model.get('clip').id;
    return {
      'data-clip-id': clipId
    };
  }
}

export default NewResearchResultListItemView;
