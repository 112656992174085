import Marionette from 'backbone.marionette';
import UserSettingsView from 'views/settings/userSettingsView';
import Notificator from 'libraries/notificator';
import I18n from 'i18n';
import _ from 'underscore';
import Validations from 'libraries/validations';

class UserSettingsViewController extends Marionette.Controller {
    initialize(options) {
        this.user = options.user;
        this.view = new UserSettingsView({
            model: this.user
        });
        this._registerListeners();
        this._registerSaveUserListener();
    }

    _registerListeners() {
        this.listenTo(this.view, 'salutation:changed:male', () => {
            this.user.set('male', true);
            this._maybeEnableSaveButton();
        });
        this.listenTo(this.view, 'salutation:changed:female', () => {
            this.user.set('male', false);
            this._maybeEnableSaveButton();
        });
        this.listenTo(this.view, 'reset:clicked', () => {
            this.trigger('user:cancelled');
        });
        this.listenTo(this.view, 'email:changed', this._handleEmailChanged);
        this.listenTo(this.view, 'firstName:changed', this._handleFirstNameChanged);
        this.listenTo(this.view, 'lastName:changed', this._handleLastNameChanged);
        this.listenTo(this.view, 'password:new', this._handleNewPassword)
        this._registerLocaleChangeListener();
    }

    _registerLocaleChangeListener() {
        this.listenTo(this.view, 'locale:change', this._handleLocaleChange);
    }

    _registerSaveUserListener() {
        let self = this;
        this.listenTo(this.view, 'save', (attributes) => {
            this.user.store();
            if (!this.user.save(attributes, {
                error: function () {
                    self.user.restore();
                    Notificator.showErrorNotification(
                        I18n.t("webapp.notifications.error.not_saved")
                    );
                },
                success: function () {
                    self.trigger('user:saved');
                    self.user.unset('password', { silent: true });
                    self.user.unset('password_confirmation', { silent: true });
                    self._maybeReload();
                }
            })) {
                this.user.restore();
                Notificator.showErrorNotification(
                    I18n.t('webapp.notifications.error.not_saved')
                );
            }
        });
    }

    _handleLocaleChange(locale) {
        this._maybeEnableSaveButton();
        this._updateLocaleReloadWarning(locale);
    }

    _updateLocaleReloadWarning(locale) {
        this._oldLocale = this.user.get('locale');
        if (this._differentLocale(locale)) {
            this.view.showReloadWarning();
        } else {
            this.view.hideReloadWarning();
        }
    }

    _handleNewPassword() {
        var password = this.view.getPassword();
        var passwordConfirmation = this.view.getPasswordConfirmation();
        if (password && this._passwordsMatch()) {
            this._hidePasswordError();
            this._maybeEnableSaveButton();
        } else {
            this._disableSaveButton();
            if (passwordConfirmation) {
                this._showPasswordError();
            } else {
                this._hidePasswordError();
            }
        }
    }

    _differentLocale(locale) {
        if (_.isUndefined(locale)) { return false; }
        if (_.isUndefined(this._oldLocale)) { return false; }
        return this._oldLocale !== locale;
    }

    _maybeReload() {
        if (this._differentLocale(this.user.get('locale'))) {
            window.setTimeout(() => {
                location.reload();
            }, 0);
        }
    }

    _handleEmailChanged() {
        var email = this.view.getEmailValue();
        if (this._isValidEmail(email)) {
            this._hideEmailError();
            this._maybeEnableSaveButton();
        } else {
            this._showEmailError();
            this._disableSaveButton();
        }
    }

    _handleFirstNameChanged() {
        var firstName = this.view.getFirstNameLength();
        if (firstName < 1) {
            this._showFirstNameError();
            this._disableSaveButton();
        } else {
            this._hideFirstNameError();
            this._maybeEnableSaveButton();
        }
    }

    _handleLastNameChanged() {
        var lastNameLength = this.view.getLastNameLength();
        if (lastNameLength < 1) {
            this._showLastNameError();
            this._disableSaveButton();
        } else {
            this._hideLastNameError();
            this._maybeEnableSaveButton();
        }
    }

    _maybeEnableSaveButton() {
        if (this._namesAreFilled() &&
            this._isValidEmail(this.view.getEmailValue()) &&
            this._passwordsMatch()
        ) {
            this._enableSaveButton();
        }
    }

    _passwordsMatch() {
        var password = this.view.getPassword();
        var passwordConfirmation = this.view.getPasswordConfirmation();
        return password === passwordConfirmation;
    }

    _isValidEmail(email) {
        return Validations.isEmailValid(email);
    }

    _namesAreFilled() {
        var firstNameLength = this.view.getFirstNameLength();
        var lastNameLength = this.view.getLastNameLength();
        return firstNameLength > 0 && lastNameLength > 0;
    }

    _showEmailError() {
        var key = "webapp.settings.users.user_details.validations.email_invalid";
        this.view.showEmailError(I18n.t(key));
    }

    _hideEmailError() {
        this.view.hideEmailError();
    }

    _showFirstNameError() {
        var key = "webapp.settings.users.user_details.validations.name_invalid";
        this.view.showFirstNameError(I18n.t(key));
    }

    _hideFirstNameError() {
      this.view.hideFirstNameError();
    }

    _showLastNameError() {
        var key = "webapp.settings.users.user_details.validations.name_invalid";
        this.view.showLastNameError(I18n.t(key));
    }

    _hideLastNameError() {
      this.view.hideLastNameError();
    }

    _enableSaveButton() {
        this.view.enableSaveButton();
    }

    _disableSaveButton() {
        this.view.disableSaveButton();
    }

    _showPasswordError() {
        var key = "webapp.settings.users.user_details.validations.passwords_dont_match";
        this.view.showPasswordError(I18n.t(key));
    }

    _hidePasswordError() {
        this.view.hidePasswordError();
    }
}

export default UserSettingsViewController;
