import _ from 'underscore'
import Backbone from 'backbone'
import Report from 'models/report'
import Logger from 'libraries/logger'
import Notificator from 'libraries/notificator'
import ClipTopicSelection from 'models/reporting/wizard/clipTopicSelection'
import contacts from 'collections/contactsInstance'
import users from 'collections/usersInstance'

var ReportWizard = Backbone.Model.extend({
  initialize: function (opts) {
    if (!opts) { opts = {}; }

    if (opts.reportsCollection) {
      this.reportsCollection = opts.reportsCollection;
    } else {
      Logger.error('Report Wizard initialized without a reports collection');
    }

    if (opts.report) {
      this.report = opts.report;
    } else {
      this.report = this.getReport();
    }

    if (this.report.isNewsletter() && !this.report.get('recipients')) {
      this.setDefaultContacts();
    }

    _.bindAll(this, 'updateCompleteness');
    this.stepModels = this.initializeStepModels();

    if (this.report.isClipTopic()) {
      this.stepModels = _.rest(this.stepModels, 2); // Delete first 2 steps
      var clipTopicSelectionStep = new ClipTopicSelection({
        report: this.report,
        complete: true
      });
      this.stepModels.unshift(clipTopicSelectionStep);
      var clipCount = this.report.get('topic_ids').length;
      this.set({approximateClipCount: clipCount});
    }

    this.on('change:currentStep', this.updateCompleteness);
    this.on('change:approximateClipCount', this.updateCompleteness);

    var currentStep = this.stepModels[0];
    this.set({currentStep: currentStep});
    currentStep.set({active: true});

    if (this.report.isClipTopic()) {
      this.makeStep();
    }

    _.each(this.stepModels, (step) => {
      step.on('change:complete', this.updateCompleteness);
    }, this);

    if (opts.useAsTemplate) {
      while (!this.get('complete') && this.get('currentStepComplete') && !this.get('lastStep')) {
        this.makeStep();
      }
      this.updateApproximateClipCount();
    } else {
      this.updateCompleteness();
      this.addEventsToAttributesThatTriggerCalculationOfApproximateClipCount();
    }
  },
  initializeStepModels: function () {
    return [];
  },

  setDefaultContacts: function () {
    var currentUserEmail = users.currentUser.get('email');
    var contactee = contacts.find(function (contact) {
      return contact.get('email') === currentUserEmail;
    });
    if (!_.isUndefined(contactee)) {
      this.report.set({recipients: [contactee]});
    }
  },

  getReport: function () {
    return new Report({
      title: "Report " + (new Date()).format()
    });
  },

  updateCompleteness: function () {
    var firstIncompleteStep = _.find(this.stepModels, function (step) {
      return !step.get('complete');
    });
    if (!firstIncompleteStep && this.get('currentStep') === _.last(this.stepModels)) {
      if (!this.isLoadingApproximateClipCount() && !this.isOverClipLimit()) {
        this.set({complete: true});
      } else {
        this.set({complete: false});
      }
    } else if (firstIncompleteStep) {
      this.set({complete: false});
      var indexOfFirstIncompleteStep = _.indexOf(this.stepModels, firstIncompleteStep);
      var indexOfLastCompleteStep = indexOfFirstIncompleteStep - 1;
      var indexOfCurrentStep = _.indexOf(this.stepModels, this.get('currentStep'));
      if (indexOfCurrentStep <= indexOfLastCompleteStep) {
        this.set({currentStepComplete: true});
      } else {
        this.set({currentStepComplete: false});
      }
    } else {
      this.set({currentStepComplete: true});
    }
  },
  makeStep: function () {
    if (this.get('currentStepComplete')) {
      var indexOfCurrentStep = _.indexOf(this.stepModels, this.get('currentStep'));
      var indexOfNextStep = indexOfCurrentStep + 1;
      if (indexOfNextStep < this.stepModels.length) {
        var nextStep = this.stepModels[indexOfNextStep];
        if (nextStep === _.last(this.stepModels)) {
          this.set({lastStep: true});
        } else {
          this.set({lastStep: false});
        }
        this.get('currentStep').set({currentStep: false});
        nextStep.set({active: true, currentStep: true});
        this.set({currentStep: nextStep});
      }
    }
  },

  save: function () {
    var wizard = this;
    var report = this.report;
    if (!report.isNewsletter()) {
      report.unset('sender');
      report.unset('recipients');
      report.unset('preview_link');
    }
    report.save([], {
      success: function () {
        report.editing_in_progress = false;
        wizard.addToReportsList(report);
        report.open();
      },
      error: function () {
        Notificator.showErrorNotification('Leider ist beim Speichern Ihres Reports ein Fehler aufgetreten. Versuchen Sie es bitte noch einmal.');
        wizard.trigger('saveError');
      }
    });
  },

  addToReportsList: function (report) {
    this.reportsCollection.unshift(report);
  },

  collectedStepData: function () {
    var activeSteps = _.filter(this.stepModels, function (step) {
      return step.get('active');
    });
    var completeData = {};
    _.each(activeSteps, function (step) {
      _.extend(completeData, step.data());
    });
    return completeData;
  },
  addEventsToAttributesThatTriggerCalculationOfApproximateClipCount: function () {
    var attributesThatChangeClipCount = ['topic_type', 'topic_ids', 'start_date', 'end_date', 'clip_types_to_keep'];
    var wizard = this;
    _.each(attributesThatChangeClipCount, function (attribute) {
      wizard.report.on('change:' + attribute, function () {
        wizard.updateApproximateClipCount();
      });
    });
    _.each(this.stepModels, function (step) {
      step.on('updateClipApproximation', function () {
        wizard.updateApproximateClipCount();
      });
    });
  },
  clipLimit: function () {
    return 4000;
  },
  isOverClipLimit: function () {
    var clipCount = this.get('approximateClipCount');
    var clipLimit = this.clipLimit();
    return clipCount > clipLimit;
  },
  isLoadingApproximateClipCount: function () {
    return _.isUndefined(this.get('approximateClipCount'));
  },
  updateApproximateClipCount: function () {
    var wizard = this;
    this.unset('approximateClipCount');
    this.report.approximateClipCount(this.collectedStepData(), function (clipCount) {
      wizard.set({approximateClipCount: clipCount});
    });
  }
});

export default ReportWizard;

