import Backbone from 'backbone';
import ReaderConfiguration from 'models/newsroom/configuration';

class ReaderConfigurations extends Backbone.Collection {
    initialize() {
        this.model = ReaderConfiguration;
        this.url = 'api/v3/reader_configurations';
        this.comparator = 'title';
    }
}

export default ReaderConfigurations;
