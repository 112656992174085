import Marionette from 'backbone.marionette';
import * as _ from 'underscore';
import session from 'models/sessionInstance';
import ReactWrapper from 'views/marionetteReactWrapper';
import * as React from 'react';
import Layout from 'views/layoutView'
import Button from 'components/button'
import Modal from 'components/modal'
import RssFeedButton from 'components/rssFeedButton'
import users from 'collections/usersInstance'
import I18n from "i18n";

class EditAgentUnsubscribeButtonController extends Marionette.Controller {
  initialize(opts) {
    this.agent = opts.agent
    this.view = new Layout();
    this.listenTo(this.view, 'render', () => this.renderView());
  }

  updateSubscribedUsers() {
    const self = this
    $.get(this.url(), function (data) {
      self.subscribedUserIds = data.user_ids
      self.renderView()
    })
  }

  url() {
    const url =
      '/api/v3/agents/' + this.agent.get('id') + '/subscribed_users'
    return url
  }

  renderView(additionalOpts?: object) {
    const props = this.buildProps()
    const modalProps = this.buildModalProps(additionalOpts)
    const heading =
      I18n.t('webapp.agents.edit.unsubscribe_button.heading')
    const body =
      I18n.t('webapp.agents.edit.unsubscribe_button.modal_body')
    const buttonLabel =
      ' ' + I18n.t('webapp.agents.edit.unsubscribe_button.label')
    let rssButton
    const feedUrl = this.agent.get('feed_url')
    if (session.hasFeature("agent_rss")) {
      rssButton = (
        <RssFeedButton feedUrl={feedUrl} className="button-group__item" />
      )
    } else {
      rssButton = (<div></div>)
    }
    const view = new ReactWrapper({
      getComponent: () => {
        return (
          <div className="mbm">
            <h2 className="heading-xlarge mbm mtm">{heading}</h2>
            <Modal {...modalProps}>
             {body}
            </Modal>
          <div className="button-group button-group--vertical">
            <Button {...props} className="button-group__item">
              <i className="icon-eye"></i>
              {buttonLabel}
            </Button>
            {rssButton}
          </div>
          </div>
        )
      }
    });
    this.view.showView(view);
  };

  handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this._onUnSubscribeClick();
    }
  }

  buildModalProps(additionalProps?: object): object {
    const prefix = 'webapp.agents.edit.unsubscribe_button.'
    const base = {
      title: I18n.t(prefix + 'modal_title'),
      cancelLabel: I18n.t(prefix + 'modal_cancel'),
      confirmationLabel: I18n.t(prefix + 'modal_confirm'),
      onConfirm: () => this._onUnSubscribeClick(),
      modalId: this.modalId(),
      onKeyDown: (e) => this.handleKeyDown(e),
    }

    return _.extend(base, additionalProps)
  }

  modalId() {
    return 'unsubscribe-' + this.agent.get('id')
  }

  buildProps(additionalProps?: object): object {
    const base = {
      onClick: () => this._showModal(),
    }

    return _.extend(base, additionalProps)
  }

  _showModal() {
    this.renderView({open: true})
  }

  _onUnSubscribeClick() {
    if (this.confirmDisabled) { return }
    const currentUser = users.get(users.currentUser.id);

    const url =
      '/api/v3/agents/' + this.agent.get('id') + '/subscribed_users' + '/' + currentUser.get('id')

    const self = this

    this.confirmDisabled = true
    self.renderView({close: true })
    $.ajax({
      url: url,
      type: 'DELETE',
      dataType: 'json',
      success: function (data) {
        this.confirmDisabled = false
        self.agent.set({ subscribed: false })
      }
      }
    )
  }
}

export default EditAgentUnsubscribeButtonController;
