import * as React from "react";
import I18n from "i18n";

interface Props {
  count: number;
  preloading: boolean;
  displayCount: boolean;
}

export default class ArticleCountView extends React.PureComponent<Props> {
  render() {
    return (
      <>
        {this.props.displayCount ? (
          <div className="flaired-container bottom-rounded-container dropped-container phm">
            {this.props.preloading ? (
              <div className="preloader"></div>
            ) : (
              <h3 className="heading-large pvm pls">
                {I18n.t("webapp.research.settings.article_count" , { "count":  this.props.count })}
              </h3>
            )}
          </div>
        ) : null}
      </>
    );
  }
}
