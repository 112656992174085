import Marionette from "backbone.marionette";
import template from "text-loader!templates/agents/sourceCategorySelectionForm.html";
import SourceCategoriesInstancePromise from "models/sourceCategoriesInstancePromise";
import SourceCategories from "collections/sourceCategories";
import _ from "underscore";
import I18n from "i18n";
import handleTooltips from 'models/handleTooltips';

class SourceCategorySelectionFormView extends Marionette.ItemView {
  initialize() {
    this.sourceCategories = this.options.preloadedSourceCategories || new SourceCategories();
    this.excludedCategoryIdentifiers =
      this.options.excludedCategoryIdentifiers;

    this.subSubCategories = [];
    handleTooltips(this);
    SourceCategoriesInstancePromise.done((sourceCategories) => {
      this.sourceCategories = sourceCategories;
    });
  }

  includeCategory(category) {
    return !_.contains(this.excludedCategoryIdentifiers, category);
  }

  serializeData() {
    let categoriesTree = this.sourceCategories.map((country) => {
      let subCategoriesCollection = country.get("sub_categories");
      let name =  I18n.t(country.get("locale_key"));
      let node = {
        id: country.get("id"),
        name: name,
        identifier: country.get("identifier"),
        printCategory: country.get("identifier") === "c2f"
      };

      if (subCategoriesCollection.size() > 0 &&
          this.includeCategory(node.identifier)) {
        node.subCategories = subCategoriesCollection.map((subCategory) => {
          let name =  I18n.t(subCategory.get("locale_key"));
          let subNode = {
            id: subCategory.get("id"),
            name: name
          };

          let languagesCollection = subCategory.get("sub_categories");
          if (languagesCollection.size() > 0) {
            let subSubCategories = [];
            subNode.subCategories = languagesCollection.map((language) => {
              subSubCategories.push(language);
              let name =  I18n.t(language.get("locale_key"));
              return { id: language.get("id"),
                       name: name };
            }, this);
            this.subSubCategories[subCategory.id] = subSubCategories;
          }

          return subNode;
        }, this);

        return node;
      }
      return undefined;
    }, this);

    // the ignored top categories leave undefined values in the array...
    categoriesTree = _.reject(categoriesTree, (c) => {
      return c === undefined;
    });
    return { categories: categoriesTree };
  }

  // this sets the initial checkbox states, which come from the model.
  // initializes the header checkboxes according to the initially selected
  // checkboxes.
  initializeCheckboxes(ids) {
    _.each(ids, (id) => {
      this.$("#source-category-id-" + id).prop("checked", true);
    }, this);

    _.each(this.$(".accordion"), (acc) => {
      this.updateAccordionHeader(this.$(acc));
    }, this);
  }

  checkAll(options = { silent: false }) {
    SourceCategoriesInstancePromise.done((sourceCategories) => {
      let categoryIds = sourceCategories.map((sc) => {
        return sc.get("id");
      });

      let subCategories = _.flatten(sourceCategories.map((sc) => {
        return sc.get("sub_categories").models;
      }));

      let subCategoryIds = _.map(subCategories, (sc) => {
        return sc.get("id");
      });

      this.initializeCheckboxes(categoryIds.concat(subCategoryIds));
      if (!options.silent) {
        this.trigger("changed:sourceCategory");
      }
    });
  }

  // this happens when the user clicks on a language in the dropdown
  handleLanguageChange(e) {
    let subSubCategoryId = this.$(e.currentTarget).data("source-sub-sub-category-id");
    let subCategoryId = this.$(e.currentTarget).data("parent-category-id");
    this.setSubSubCategory(subCategoryId, subSubCategoryId);
    this.trigger("changed:sourceCategory");
  }

  // used by the controller to get the currently selected sub-sub-categories
  getSubSubCategories() {
    let elements = this._selectedSubSubCategoryElements();
    let selectedSubCategories = this.getEnabledCheckboxes();
    return _.map(
      _.filter(elements, (element) => {
        let parentId = this.$(element).data("parent-category-id");
        return _.contains(selectedSubCategories, parentId);
      }),
      (element) => {
        return this.$(element).data("selected-source-sub-sub-category");
      }
    );
  }

  // also used by the controller to set the language from the DB upon initialization
  setSubSubCategory(subCategoryId, id) {
    let subSubCategoryName = this.$("[data-source-sub-sub-category-id=" + id + "]").data("source-sub-sub-category-name");
    let element = this._selectedSubSubCategoryElement(subCategoryId);
    element.html(subSubCategoryName);
    element.data("selected-source-sub-sub-category", id);
  }

  initializeSubSubCategoryDropdowns(ids) {
    _.each(this.subSubCategories, (categories, parentId) => {
      if (categories === undefined) {
        return;
      }
      // set the selected sub-sub category, use the first one as
      // a default value if none is selected
      let selectedSubSubCategoryId = _.first(
        _.intersection(_.pluck(categories, "id"), ids)
      );
      if (_.isUndefined(selectedSubSubCategoryId)) {
        selectedSubSubCategoryId = categories[0].id;
      }
      this.setSubSubCategory(parentId, selectedSubSubCategoryId);
    }, this);
  }

  getEnabledCheckboxes() {
    let ids = _.map(this.$("[data-source-sub-category-id]"), (category) => {
      if (this.$(category).prop("checked")) {
        return this.$(category).data("source-sub-category-id");
      }
    });

    return _.filter(ids, (id) => { return id !== undefined; });
  }

  // open/close the accordion when the accordion toggle button is clicked
  handleDropdownFolding(e) {
    let dropdown = this.$(e.currentTarget).parent();
    dropdown.toggleClass("is-open");
  }

  handleBodyCheckboxChange(e) {
    this.trigger("changed:sourceCategory");
    this.updateAccordionHeader(this.$(e.currentTarget).parents(".accordion"));
  }

  // when the user clicks the header checkbox,
  // check/uncheck all subcategories
  handleHeaderCheckboxChange(e) {
    // we invert the boolean because this method is called after the
    // user has clicked the checkbox, so at this point the checkbox is
    // already unchecked, but we have to handle the previous state...
    let isHeaderCheckboxChecked = !this.$(e.currentTarget).prop("checked");
    let isSemiActive = this.$(e.currentTarget).siblings("label").hasClass("is-semi-active");
    let categories = this.$(e.currentTarget).parents(".accordion").find(".accordion__body input");

    if (isHeaderCheckboxChecked) {
      this.setCheckboxStates(categories, false);
    } else if (isSemiActive) {
      this.setCheckboxStates(categories, true);
    } else {
      this.setCheckboxStates(categories, true);
    }
    this.$(e.currentTarget).siblings("label").removeClass("is-semi-active");
    this.trigger("changed:sourceCategory");
  }

  setCheckboxStates(checkboxes, state) {
    _.each(checkboxes, (checkbox) => {
      this.$(checkbox).prop("checked", state);
    });
  }

  // this happens when the user clicks any of the subcategories
  updateAccordionHeader(accordion) {
    let headerCheckbox = accordion.find(".accordion__head input");
    let headerCheckboxLabel = accordion.find(".accordion__head label");
    let categoryCount = accordion.find(".accordion__body input").length;

    let selectedCategoryCount = _.filter(accordion.find(".accordion__body input"), (element) => {
      return this.$(element).prop("checked") === true;
    }).length;

    if (selectedCategoryCount === 0) {
      headerCheckboxLabel.removeClass("is-semi-active");
      headerCheckbox.prop("checked", false);
    } else if (selectedCategoryCount > 0 && selectedCategoryCount < categoryCount) {
      headerCheckboxLabel.addClass("is-semi-active");
      headerCheckbox.prop("checked", false);
    } else {
      headerCheckboxLabel.removeClass("is-semi-active");
      headerCheckbox.prop("checked", true);
    }
  }

  _selectedSubSubCategoryElements() {
    return this.$("[data-selected-source-sub-sub-category]");
  }

  _selectedSubSubCategoryElement(subCategoryId) {
    return this.$("[data-selected-source-sub-sub-category]" +
                  "[data-parent-category-id=" + subCategoryId + "]");
  }
}
SourceCategorySelectionFormView.prototype.template = template;
SourceCategorySelectionFormView.prototype.ui = {
    "preloader": "[data-preloader]",
    "categories": "[data-source-category-id]",
    "subcategories": "[data-source-sub-category-id]"
};
SourceCategorySelectionFormView.prototype.events = {
    "click .accordion__toggle": "handleDropdownFolding",
    "change [data-source-category-id]": "handleHeaderCheckboxChange",
    "change [data-source-sub-category-id]": "handleBodyCheckboxChange",
    "click [data-source-sub-sub-category-id]": "handleLanguageChange"
};

export default SourceCategorySelectionFormView;
