import { BASE_PATH } from "../DashboardAppConstants";
import { WordCloudItem } from "../components/WordCloud";
import { SearchAgentItem } from "../components/TableWidget";
import { LineChartResponseItem } from "../components/LineChartWidget";
import { NewstickerItem } from "../components/NewstickerWidget";
import moment from "moment";
import DateFormatter from "../../../libraries/dateFormatter";

const LOAD: "app/widgets/load" = "app/widgets/load";
const LOAD_FULFILLED: "app/widgets/load_FULFILLED" =
  "app/widgets/load_FULFILLED";
const LOAD_PENDING: "app/widgets/load_PENDING" = "app/widgets/load_PENDING";
const LOAD_REJECTED: "app/widgets/load_REJECTED" = "app/widgets/load_REJECTED";

export enum VisType {
  Table = "table",
  WordCloud = "word_cloud",
  Static = "static",
  LineChart = "line_chart",
  Newsticker = "news_ticker"
}

export interface Widget {
  id: number;
  dashboardId: number;
  x: number;
  y: number;
  h: number;
  w: number;
  title: string;
  visualizationType: VisType;
  dataType: string;
  dataSeries: DataSeries[];
  configuration: {
    content?: string;
  };
  data: Data;
}

type Data =
  | string
  | WordCloudItem[][]
  | SearchAgentItem[]
  | LineChartResponseItem[][]
  | NewstickerItem[];
type DataResponse = string | object[];

interface WidgetResponse {
  id: number;
  dashboard_id: number;
  x_coordinate: number;
  y_coordinate: number;
  height: number;
  width: number;
  title: string;
  visualization_type: VisType;
  data_type: string;
  data_series: DataSeries[];
  configuration: {
    content: string;
  };
  data: DataResponse;
}
export interface DataSeries {
  id: number;
  agent_ids: number[];
  tag_ids: number[];
}

export interface WidgetStore {
  isNetworkReqOngoing: boolean;
  value: Widget[];
  error: boolean;
}

const initialState: WidgetStore = {
  isNetworkReqOngoing: false,
  value: [],
  error: false
};

type Action =
  | LoadAction
  | { type: typeof LOAD_FULFILLED; payload: Widget[] }
  | { type: typeof LOAD_REJECTED }
  | { type: typeof LOAD_PENDING };

//Reducer
export default function widgets(
  state = initialState,
  action: Action
): WidgetStore {
  switch (action.type) {
    case LOAD_FULFILLED:
      return { isNetworkReqOngoing: false, value: action.payload, error: false };
    case LOAD_PENDING:
      return { ...state, isNetworkReqOngoing: true };
    case LOAD_REJECTED:
      return { ...state, isNetworkReqOngoing: false, error: true };
    default:
      return state;
  }
}

//Action Creators
export interface LoadAction {
  type: typeof LOAD;
  payload: Promise<Widget[]>;
}
export function getWidgets(
  dashboardId: number,
  startTS: number,
  endTS: number
): LoadAction {
  return {
    type: LOAD,
    payload: fetch(
      `${BASE_PATH}/dashboards/${dashboardId}/widgets/?sts=${startTS}&ets=${DateFormatter.formatTimestamp(
        moment()
      )}`,
      {
        credentials: "same-origin"
      }
    )
      .then(res => res.json())
      .then(json => json.collection)
      .then(coll =>
        coll.map(
          ({
            id,
            dashboard_id,
            x_coordinate,
            y_coordinate,
            height,
            width,
            title,
            visualization_type,
            data_type,
            data_series,
            configuration,
            data
          }: WidgetResponse) => ({
            id,
            dashboardId: dashboard_id,
            x: x_coordinate,
            y: y_coordinate,
            h: height,
            w: width,
            title,
            visualizationType: visualization_type,
            dataType: data_type,
            dataSeries: data_series,
            configuration,
            data: mappedData(visualization_type, data)
          })
        )
      )
  };
}

function mappedData(type: VisType, data: DataResponse): Data {
  switch (type) {
    case VisType.Newsticker:
      return (data as any[]).map(
        ({ publish_date, source_name, ...rest }) =>
          ({
            ...rest,
            sourceName: source_name,
            publishDate: publish_date
          } as NewstickerItem)
      );
    default:
      return data as Data;
  }
}
