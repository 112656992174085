import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/settings/favoriteSourceGroupItem.html'
import _ from 'underscore'

var FavoriteSourceGroupItemView = Marionette.ItemView.extend({
  template: template,
  tagName: 'li',
  className: 'accordion__item',
  triggers: {
    'click': 'clicked:sourceGroup',
  },
  serializeData: function () {
    return {
      id: this.model.get('id'),
      name: this.model.get('name')
    };
  },
  isActive: function () {
    if (this.$el.children().first().prop('checked')) {
      return true
    }
  },
  activate: function () {
    this.$el.children().first().prop('checked', true);
  },
  deactivate: function () {
    this.$el.children().first().prop('checked', false);
  },
});

export default FavoriteSourceGroupItemView;

