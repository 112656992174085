import _ from 'underscore'
import MonthChooser from 'models/reporting/wizard/timespanSelection/monthChooser'
import template from 'text-loader!templates/reporting/wizard/timespanSelection.html'
import templateMonth from 'text-loader!templates/reporting/wizard/timespanSelectionMonth.html'
import StepView from 'views/reporting/wizard/stepView'
import session from 'models/sessionInstance'
import datePicker from 'libraries/datePicker'
import $ from 'jquery'
import 'jquery.ui'
import 'jquery.ui.datepicker-de'

var View = StepView.extend({
  getTemplate: function () {
    if (this.selectDay) {
      return template;
    } else {
      return templateMonth;
    }
  },
  className: "wizard-step rounded clearfix",
  triggers: {
    'change input[name=start_date]': 'change:start_date',
    'change input[name=end_date]': 'change:end_date',
    'change select[name=start_month]': 'change:start_month',
    'change select[name=start_year]': 'change:start_year',
    'change select[name=end_month]': 'change:end_month',
    'change select[name=end_year]': 'change:end_year'
  },
  ui: {
    start_date: 'input[name=start_date]',
    end_date: 'input[name=end_date]',
    start_month: 'select[name=start_month]',
    start_year: 'select[name=start_year]',
    end_month: 'select[name=end_month]',
    end_year: 'select[name=end_year]'
  },
  inputNames: [ 'start_month', 'start_year', 'end_month', 'end_year' ],
  initialize: function () {
    var select_day_feature = session.get('features').reports_select_day_range;
    this.selectDay = !!(select_day_feature || this.options.edit_mode);
    if (this.selectDay) {
      _.bindAll(this, 'updateMinMaxDates');
      this.on('change:start_date', () => {
        var value = this.ui.start_date.val();
        var date = this.parseDate(value);
        this.model.set({start_date: date});
      });
      this.on('change:end_date', () => {
        var value = this.ui.end_date.val();
        var date = this.parseDate(value);
        this.model.set({end_date: date});
      });
      this.listenTo(this.model, "change:start_date", this.updateMinMaxDates);
      this.listenTo(this.model, "change:end_date", this.updateMinMaxDates);
    } else {
      this.initMonthChoosers();
    }
    StepView.prototype.initialize.call(this);
  },
  initMonthChoosers: function () {
    _.each(this.inputNames, (inputName) => {
      this.on('change:' + inputName, () => {
        this.updateDate(inputName);
      });
    }, this
          );
    var currentDate = new Date();
    var maxYear = currentDate.getFullYear();
    var maxMonth = currentDate.getMonth();
    this.startMonth = new MonthChooser(2009, 0, maxYear, maxMonth);
    this.startMonth.setMonth(maxMonth);
    this.startMonth.setYear(maxYear);
    this.endMonth = new MonthChooser(2009, 0, maxYear, maxMonth);
    this.endMonth.setMonth(maxMonth);
    this.endMonth.setYear(maxYear);
    this.startMonth.before(this.endMonth);
    this.endMonth.after(this.startMonth);
  },
  onRender: function () {
    if (this.selectDay) {
      // there are no clips before this
      var minDate = new Date(2009, 0, 1);

      datePicker.defaultToSessionLocale();

      this.ui.start_date.datepicker({
        minDate: minDate,
        maxDate: 0 // today
      });
      this.ui.end_date.datepicker({
        maxDate: 0 // today
      });
      this.updateMinMaxDates();
    } else {
      this.updateModelDates();
      this.updateOptions('start_month');
      this.updateOptions('start_year');
      this.updateOptions('end_month');
      this.updateOptions('end_year');
    }
  },
  updateDate: function (inputName) {
    var newValue = this.ui[inputName].val();
    var monthChooser;
    if (inputName.match(/start/)) {
      monthChooser = this.startMonth;
    } else {
      monthChooser = this.endMonth;
    }
    if (inputName.match(/month/)) {
      monthChooser.setMonth(newValue);
    } else {
      monthChooser.setYear(newValue);
    }
    _.each(this.inputNames, (name) => {
      if (name !== inputName) {
        this.updateOptions(name);
      }
    }, this);
    this.updateModelDates();
  },
  updateModelDates: function () {
    var startDate = this.startMonth.getFirstDate();
    var endDate = this.endMonth.getLastDate();
    this.model.set({start_date: startDate, end_date: endDate});
  },
  updateOptions: function (inputName) {
    var selectedValue;
    var allValues;
    var monthChooser;
    if (inputName.match(/start/)) {
      monthChooser = this.startMonth;
    } else {
      monthChooser = this.endMonth;
    }
    var isMonth = !!inputName.match(/month/);
    if (isMonth) {
      selectedValue = monthChooser.month;
      allValues = monthChooser.possibleMonths();
    } else {
      selectedValue = monthChooser.year;
      allValues = monthChooser.possibleYears();
    }
    this.ui[inputName].html(this.optionsHtml(allValues, selectedValue, isMonth));
  },
  optionsHtml: function (allValues, selectedValue, isMonth) {
    var html = '';
    var monthNames = [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ];
    _.each(allValues, function (value) {
      html += '<option value="' + value + '"';
      if (value.toString() === selectedValue.toString()) {
        html += ' selected';
      }
      html += '>';
      html += (isMonth && value !== '') ? monthNames[value] : value;
      html += '</option>';
    });
    return html;
  },
  updateMinMaxDates: function () {
    var start_date = this.model.get('start_date');
    var end_date = this.model.get('end_date');
    if (start_date) {
      this.ui.end_date.datepicker("option", "minDate", start_date);
    }
    if (end_date) {
      this.ui.start_date.datepicker("option", "maxDate", end_date);
    } else {
      this.ui.start_date.datepicker("option", "maxDate", 0); // today
    }
  },
  parseDate: function (date) {
    return $.datepicker.parseDate('dd.mm.yy', date);
  },
  serializeData: function () {
    var report = this.model;
    var options = this.options;

    return {
      start_date: report.get('start_date'),
      end_date: report.get('end_date'),
      disabled: options.disabled ? "disabled" : ""
    };
  }
});

export default View;

