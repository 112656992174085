import * as React from "react";
import I18n from "i18n";
import * as jQuery from "jquery";
import * as _ from "underscore";
import Spinner from './Spinner'
import * as moment from 'moment';
import {
  IKbApiEntity, IKbApiRepType, IKbApiRepresentation, findRepresentation,
  SupportedLocale, localizedName, locale
} from 'models/kbApiEntity'
import { EntityDetailViewContainer as Container } from 'components/entityDetailViewContainer'

export interface Props {
  business?: IKbApiEntity
}
export class BusinessDetailView extends React.Component<any, {}> {  
  render() {
    if (this.props.business) {
      const business = this.props.business
      const name = localizedName(business)
      return (
        <Container>
          {this.updatedAt()}
          <h2 className="heading-xlarge mtm">{name}</h2>
          {this.businessMarkup()}
        </Container>
      )
    } else {
      const label = I18n.t('webapp.components.businessDetailView.loading')
      return (
        <Container> <Spinner label={label} /> </Container>
      )
    }
  }
  private businessMarkup() {
    const supportedLocales: SupportedLocale[] = ['de', 'fr', 'it', 'en']
    return supportedLocales.map((locale)=> {
      const name = findRepresentation(this.props.business, 'name', locale)
      const label = I18n.t('webapp.components.businessDetailView.name_' + locale)
        if (name != undefined) {
          const key = 'business-' + locale
          return (
            <form className="form-item mvm" key={key}>
              <label className="form-item__label">{label}</label>
              <div className="form-item__input">
                <input type="text" className="text-input text-input--block" disabled={true} value={name}/>
              </div>
            </form>
          )
        }

    })
  }

  private updatedAt() {
    const updatedAt = this.props.business.data.attributes.updated_at
    const fmt = moment(updatedAt).format("DD.MM.YYYY HH:MM")
    return (
      <div className="link-bar mbm">
        <span className="link-bar__right-item text--grey"> <i className="fa fa-refresh text--white"></i> {fmt}</span>
      </div>
    )
  }
}
