import Marionette from 'backbone.marionette';
import { LocationDetailView, Props } from 'components/locationDetailView'
import { IEsApiEntity as Entity } from 'models/esResult'
import * as _ from 'underscore'
import * as React from "react";
import ReactWrapper from 'views/marionetteReactWrapper';
import LayoutView from 'views/layoutView'
import { IKbApiEntity } from 'models/kbApiEntity'

class LocationsDetailController extends Marionette.Controller {
  initialize(opts: { location?: IKbApiEntity }) {
    if (opts.location) {
      this.location = opts.location
    }
    this.view = new LayoutView()
    this.listenTo(this.view, 'render', () => this.renderView())
  }

  renderView(additionalOpts?: object) {
    const props = this.buildProps(additionalOpts)
    const sidebarView = new ReactWrapper({
      getComponent: () => (<LocationDetailView {...props} />)
    });
    this.view.showView(sidebarView);
  }

  buildProps(additionalProps?: object): object {
    let props: Props = {}
    if (this.location && this.location.data) {
      props['location'] = this.location
    }
    return _.extend(props, additionalProps)
  }
}

export default LocationsDetailController;