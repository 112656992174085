import * as React from 'react';
import * as ReactDOM from 'react-dom';
import select2CloseOnBlur from 'libraries/select2CloseOnBlur';
import swissHolidays from '../components/swissHolidays';
import germanHolidays from '../components/germanHolidays';
import I18n from 'i18n';
import * as _ from 'underscore';
import * as jQuery from 'jquery';

interface ExcludedHolidayOption {
  id: string
  region: string
  name: string
}

interface IHolidayExclusionViewProps {
  excludedHolidays: ExcludedHolidayOption[]
  onHolidayChange: (selection: string | null) => void
  selectionDisabled?: boolean;
}

class HolidayExclusionView extends React.Component<IHolidayExclusionViewProps> {
    excludedHolidaysSelect: HTMLSelectElement | null;

    constructor(props: IHolidayExclusionViewProps) {
        super(props);
        this.excludedHolidaysSelect = null;
    }

    render() {
        return (
            <select multiple={true}
                    ref={(element) => { this.excludedHolidaysSelect = element; }}
                    defaultValue={(this.props.excludedHolidays as any)}>
                {this.renderOptionGroups()}
            </select>
        );
    }

    renderOptionGroups() {
        return _.map(this.optionGroups(), (optionGroup, idx) => {
            const label = optionGroup.label;
            const options = optionGroup.options;

            return <optgroup label={label} key={idx}>{ this.renderOptions(options) }</optgroup>
        });
    }

    renderOptions(options: ExcludedHolidayOption[]) {
        return _.map(options, (option, idx) => {
            return <option value={option.region} key={idx}>{option.name}</option>;
        });
    }

    optionGroups() {
        const swissHolidayGroupLabel =
            'webapp.reader_admin.edit.weekday_schedule.holidays_switzerland';
        const germanHolidayGroupLabel =
            'webapp.reader_admin.edit.weekday_schedule.holidays_germany';

        return [
            {
                label: I18n.t(swissHolidayGroupLabel),
                options: swissHolidays()
            },
            {
                label: I18n.t(germanHolidayGroupLabel),
                options: germanHolidays()
            }
        ];
    }

    private selectionEnabled(): boolean {
        return this.props.selectionDisabled ? false : true;
    }

    componentDidMount() {
        const selectionEnabled = this.selectionEnabled();
        const holidayExclusionSelect = jQuery(this.excludedHolidaysSelect as any);
        const select2Options = {
            containerCssClass: 'custom-select2',
            width: '100%'
        };
        select2CloseOnBlur(holidayExclusionSelect);
        (holidayExclusionSelect as any).select2(select2Options);
        holidayExclusionSelect.change(() => {
            const selection = (holidayExclusionSelect.val() as string);
            this.props.onHolidayChange(selection);
        });
        (holidayExclusionSelect as any).select2("enable", selectionEnabled);
        if (!selectionEnabled) {
          jQuery(document).ready(function() {
            setTimeout(function() {
              jQuery(".custom-select2 input").css("background-color", "transparent");
            }, 10);
          });
        }
    }
}

export default HolidayExclusionView;
