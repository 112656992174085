import Marionette from 'backbone.marionette';
import { Overview, EntityList, Props as ListProps } from 'components/entityList'
import LayoutView from 'views/layoutView'
import Entity from 'models/entity'
import * as _ from 'underscore'
import * as React from "react";
import ReactWrapper from 'views/marionetteReactWrapper';
import { IPrefixSearchResponse, IPrefixSearchEntityType } from 'models/prefixSearchResponse'
import EntityPrefixSearcher from 'libraries/entityPrefixSearcher'
import I18n from "i18n";
import Notificator from 'libraries/notificator'

interface Opts {
  headerLocaleKey: string
  entityType: IPrefixSearchEntityType
  prefixSearchErrorLocaleKey: string
  knowledgebaseErrorLocaleKey: string
}
class EntityOverviewController extends Marionette.Controller {
  initialize(opts: Opts) {
    this.opts = opts
    this.view = new LayoutView();
    this.listenTo(this.view, 'render', () => this.renderView());
  }

  renderView(additionalOpts?: object) {
    const props = this.buildProps(additionalOpts)
    if (this.reactWrapperView === undefined) {
      this.reactWrapperView = new ReactWrapper({
        getComponent: () => (
          <Overview headerLocale={this.opts.headerLocaleKey}>
            <EntityList {...props} />
          </Overview>
        )
      });
    }
    this.view.showView(this.reactWrapperView);
  }


  buildProps(additionalProps?: object): ListProps {
    if (this.props === undefined) {
      this.props = this.defaultProps()
    }
    this.props = _.extend(this.props, additionalProps)
    return this.props
  }

  defaultProps(): ListProps {
    return {
      entities: [],
      onFilterChange: _.debounce(this.onFilterChange.bind(this), 1000),
      onClick: this.onClick.bind(this),
      onActiveClick: this.onActiveClick.bind(this),
      selection: undefined,
      isLoading: false,
      searchPlaceholder: I18n.t('webapp.components.entityList.searchPlaceholder'),
      filter: '',
      initialLoad: true
    }
  }

  onActiveClick(entity: Entity): void {
    this.renderView({ selection: undefined })
    this.trigger('entity:closed')
  }

  onClick(entity: Entity): void {
    this.renderView({ selection: entity })

    const self = this
    const entityId = entity.get('meta').kb_id
    const host =
      '/api/v3/knowledgebase/entities/'
    const params = '?include=representations'
    const url = host + entityId + params
    self.trigger('entity:clicked')
    $.ajax({
      type: "GET",
      url: url,
      dataType: 'json',
      success: (data)  => {
        self.trigger('entity:clicked', data)
      },
      error: () => {
        const key = this.opts.knowledgebaseErrorLocaleKey
        Notificator.showNotification(I18n.t(key));
        this.renderView({ selection: undefined })
        self.trigger('entity:closed')
      }
    })
  }

  getEntities(response: IPrefixSearchResponse): Entity[] {
    return _.map(response.collection, (entry: any) => entry.entity)
  }

  onFilterChange(input: string): void {
    if (input.length < 1) {
      this.clearResults(input)
    } else {
      this.fetchEntities(input)
    }
  }

  clearResults(input: string) {
    const props = {
      isLoading: false,
      entities: [],
      filter: input,
      initialLoad: true
    }
    this.renderView(props);
  }

  fetchEntities(input: string) {
    this.renderView({ isLoading: true, filter: input })

    const entitiesSearcher = new EntityPrefixSearcher();
    entitiesSearcher.search(input, this.opts.entityType).then(
      () => {
        const props = {
          isLoading: false,
          entities: entitiesSearcher.results(),
          filter: input,
          initialLoad: false
        }
        this.renderView(props)
      },
      () => {
        const key = this.opts.prefixSearchErrorLocaleKey
        Notificator.showNotification(I18n.t(key));
        const props = {
          isLoading: false,
          entities: [],
          filter: input,
          initialLoad: false
        }
        this.renderView(props)
      }
    )
  }
}

export default EntityOverviewController;
