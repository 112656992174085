import * as React from 'react';

interface IEditableReactTitleViewProps {
  titleFieldName: string;
  title: string;
  model: Backbone.Model;
  headerCssClasses?: string[];
}

class EditableReactTitleView extends React.Component<IEditableReactTitleViewProps, any> {
    private titleInput: any;
    private wrapperRef: any;

    constructor(props: any) {
      super(props);
      this.state = this.initialState(props);
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate() {
        if (this.state.headerInEditMode) {
            this.titleInput.focus();
        }
    }

    handleClickOutside(e: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.persistTitleChange();
        }
    }

    initialState(props: any) {
         return {
           headerInEditMode: false,
           title: props.title,
           lastPersistedTitle: props.title
         };
    }

    render() {
        const inputClasses = [
            "text-input",
            "text-input--large",
            "text-input--block",
            "editable-heading__input"
        ];
        const nonInputClasses = [];
        let headerClasses = [
            "editable-heading"
        ];
        if (this.props.headerCssClasses) {
          headerClasses = headerClasses.concat(this.props.headerCssClasses);
        } else {
          headerClasses.push("mbs");
        }

        if (this.state.headerInEditMode) {
            headerClasses.push('is-active');
            nonInputClasses.push('hidden');
        } else {
            inputClasses.push('hidden');
        }

        return (
          <div
            className={headerClasses.join(" ")}
            ref={wrapper => {
              this.wrapperRef = wrapper;
            }}>
            <div
              className={nonInputClasses.join(" ")}
              onClick={this.toggleEditableTitle.bind(this)}>
              <h1 className="heading-xxlarge editable-heading__text">
                {this.state.title}
              </h1>
              <span className="editable-heading__action">
                <i className="editable-heading__action-icon" />
              </span>
            </div>
              <input
                type="text"
                className={inputClasses.join(" ")}
                onKeyUp={this.handleInputKeyPress.bind(this)}
                ref={input => {
                  this.titleInput = input;
                }}
                value={this.state.title}
                onChange={this.onTitleChange.bind(this)}
                onFocus={this.moveCaretAtEnd.bind(this)}
                maxLength={40}
              />
          </div>
        );
    }

    // For some reason the caret is not in the end on initial click
    moveCaretAtEnd(e: any) {
      const tempValue = e.target.value
      e.target.value = ''
      e.target.value = tempValue
    }

    onTitleChange() {
        this.setState((prevState: any) => {
            return {
                headerInEditMode: prevState.headerInEditMode,
                title: this.titleInput.value,
                lastPersistedTitle: prevState.lastPersistedTitle
            }
        });
    }

    toggleEditableTitle() {
        this.setState((prevState: any) => {
            return {
                headerInEditMode: !prevState.headerInEditMode,
                title: prevState.title,
                lastPersistedTitle: prevState.lastPersistedTitle
            };
        });
    }

    handleInputKeyPress(e: any) {
        switch (e.keyCode) {
        case 13: // Enter key pressed
            this.persistTitleChange();
            break;
        case 27: // Esc key pressed
          this.setState((prevState: any) => {
              return {
                  headerInEditMode: false,
                  title: this.state.lastPersistedTitle,
                  lastPersistedTitle: prevState.lastPersistedTitle
              };
          });
          break;
        default:
          return; // continue
        }
    }

    persistTitleChange() {
        if (this.state.headerInEditMode) {
            const titleFieldName = this.props.titleFieldName;
            const title = this.state.title;
            const model = this.props.model;

            if (this.state.title.length > 0) {
                model.set(titleFieldName, title);
                if (model.changedAttributes([titleFieldName])) {
                    model.trigger('changed:' + titleFieldName);
                }
                this.setState((prevState: any) => {
                    return {
                        headerInEditMode: false,
                        title: prevState.title,
                        lastPersistedTitle: title
                    };
                });
            } else {
                this.setState((prevState: any) => {
                    return {
                        headerInEditMode: false,
                        title: this.state.lastPersistedTitle,
                        lastPersistedTitle: prevState.lastPersistedTitle
                    };
                });
            }
        }
    }
}

export default EditableReactTitleView;
