import jQuery from 'jquery'
import _ from 'underscore'

var Deferred = jQuery.Deferred;
Deferred.when = jQuery.when;
Deferred.build = function () { return jQuery.Deferred(); };

Deferred.whenArray = function (deferreds) {
  var deferred = Deferred.build();
  var sliceArguments = function (args) {
    return Array.prototype.slice.call(args);
  };
  Deferred.when(...deferreds).then(
    function (...args) {
      deferred.resolve(sliceArguments(args));
    },
    function (...args) {
      deferred.fail(sliceArguments(args));
    },
    function (...args) {
      deferred.progress(sliceArguments(args));
    });

  return deferred;
};

/*
 * Deferred.async runs a function `work' asynchronously. It
 * returns a promise that will be resolved with the result of
 * `work' or rejected with any errors that occur.
 */
Deferred.async = function (work) {
  if (!_.isFunction(work)) {
    throw "Expected `work' to be a function";
  }
  var deferred = Deferred.build();
  setTimeout(function () {
    try {
      deferred.resolve(work());
    } catch (error) {
      deferred.reject(error);
      throw error;
    }
  }, 0);
  return deferred.promise();
};

export default Deferred;

