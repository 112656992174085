import Backbone from 'backbone';
import AgentResult from 'models/agentResult';

class AgentResults extends Backbone.Collection {
    clipIds () {
        return this.models.map(function (agentResult) {
           return agentResult.get("clip_id");
       });
    }
}

AgentResults.prototype.model = AgentResult;

export default AgentResults;
