import when from 'when'

function fetchOncePromise(object) {
  var deferred = when.defer();
  var promise = deferred.promise;

  var fetched = false;
  promise.fetch = function () {
    if (!fetched) {
      fetched = true;
      object.fetch().done(function () {
        deferred.resolve(object);
      });
    }
    return promise;
  };

  return promise;
}

export default fetchOncePromise;

