import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/multiAgentResultDetailView.html'
import I18n from 'i18n'
import _ from 'underscore'
import session from 'models/sessionInstance'
import highlight from 'libraries/highlight'
import MultipleOnlineClipOrderModalView from 'views/agents/multipleOnlineClipOrderModalView';
import convertNewlinesToParagraphs from 'libraries/convertNewlinesToParagraphs'
import SearchableDropdown from 'components/SearchableDropdown';
import * as React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper';
import AddToReaderClient from 'components/addToReaderClient';
import SendEmailModal from '../../components/SendEmailModal';

class MultiAgentResultDetailView extends Marionette.Layout {
  initialize(options) {
    this.agentResults = options.agentResults;
    this.selectedAgentResult = options.selectedAgentResult;
  }

  onRender() {
    if (session.hasFeature('use_new_reader_management')) {
      const props = {
        onCategorySelect: (confId, catId) => {
          new AddToReaderClient(confId, catId).fromAgentResults(
            _.map(this.agentResults, (ar) => { return ar.get('id'); })
          );
        }
      };
      let component = <SearchableDropdown {...props} />
      let componentWrapper = new ReactWrapper({
        tagName: 'span',
        getComponent: () => component
      })
      this.searchableDropdown.show(componentWrapper);
    }
    if (session.shareSelectedArticlesViaEmail()) {
      this.showArticleViaEmailModal();
    }
  }

  showRatings(view) {
    this.ratings.show(view);
  }

  showMarkImportant(view) {
    this.markImportant.show(view);
  }

  showTags(view) {
    this.tags.show(view);
  }

  uniqueSourcesCount() {
    var count = _.unique(_.flatten(_.map(this.agentResults, function (agentResult) {
      return agentResult.get("sources").pluck("name");
    }))).length;

    return { count: count };
  }

  hasOwnedAgentResults() {
    var count = _.filter(this.agentResults, function (agentResult) {
      return agentResult.isOwnedByCurrentUser();
    }).length;

    return count > 0;
  }

  agentResultCount() {
    return { count: this.agentResults.length };
  }

  showArticleViaEmailModal() {
    let component = (
      <SendEmailModal
        emailAgentResultIds={this.agentResults.map(i => i.get('id'))}
        emailClipIds={[]}
      />
    );
        let componentWrapper = new ReactWrapper({
            getComponent: () => component
        })
    this.articleViaEmail.show(componentWrapper);
}

  serializeData() {
    return {
      title: I18n.t("webapp.agents.results.multiple_results.selected_results", this.agentResultCount()),
      subTitle: I18n.t("webapp.agents.results.multiple_results.from_sources", this.uniqueSourcesCount()),
      hasOwnedAgentResults: this.hasOwnedAgentResults(),
      agentResultTitle: this.selectedAgentResult.get("title"),
      agentResultTeaser: this.teaser(),
      hasPdfExport: session.get("features").reports_pdf_export,
      hasExcelExport: session.get("features").reports_excel_export,
      hasNewsletterReport: session.get("features").reports_newsletter,
      canCreateReport: session.get('features').reports_select_clips_individually,
      canBeAddedToReader: session.hasFeature('use_new_reader_management'),
      canShareArticleViaEmail: session.shareSelectedArticlesViaEmail(),
      showFullTextLicensing: this._resultsLicensable(),
    };
  }

  _resultsLicensable() {
    return this.agentResults.every((agentResult) => {
      return (
        agentResult.isOrderable() &&
        agentResult.isOrderableOnlineClip() &&
        !agentResult.isOrdered()
      );
    });
  }

  teaser() {
    var teaser = this.selectedAgentResult.get("teaser");
    teaser = highlight(teaser);

    return convertNewlinesToParagraphs(teaser);
  }

  _handleOrderClick() {
    this.trigger('click:onlineOrder');
  }

  _handleOrderConfirmation() {
    this.trigger('modal:multiOnlineClipOrderConfirm');
  }

  showOnlineClipOrderModal() {
    this.multipleOnlineClipOrderModalView = new MultipleOnlineClipOrderModalView();
    const self = this;
    this.listenTo(
        this.multipleOnlineClipOrderModalView,
        'modal:confirmed',
        function () { self._handleOrderConfirmation() }
    );
    this.orderModal.show(this.multipleOnlineClipOrderModalView);
    this.multipleOnlineClipOrderModalView.showModal();
  }
}

MultiAgentResultDetailView.prototype.template = template;
MultiAgentResultDetailView.prototype.regions = {
  markImportant: '[data-mark-important-partial]',
  ratings: '[data-ratings-partial]',
  tags: '[data-tags-partial]',
  searchableDropdown: '[data-add-to-reader]',
  articleViaEmail: '[data-article-email]',
  orderButton: '[data-clip-order]',
  orderModal: '[data-order-modal]',
};
MultiAgentResultDetailView.prototype.triggers = {
  'click [data-pdf-export-button]': 'clicked:pdf:report',
  'click [data-excel-export-button]': 'clicked:excel:report',
  'click [data-newsletter-report-button]': 'clicked:newsletter:report',
  'click [data-delete-all-button]': 'clicked:delete:all'
};
MultiAgentResultDetailView.prototype.events = {
  'click [data-clip-order]': '_handleOrderClick',
};

export default MultiAgentResultDetailView;
