import Marionette from 'backbone.marionette'
import AgentResultDetailView from 'views/agents/agentResultDetailView'
import AgentResultTrashBinActionsView from 'views/agents/agentResultTrashBinActionsView'

var AgentResultTrashBinDetailController = Marionette.Controller.extend({
  initialize: function (options) {
    this.agentResult = options.agentResult;
    this.view = new AgentResultDetailView({model: this.agentResult});

    this.listenTo(this.view, 'render', () => {
      this.trashBinActionsView =
        new AgentResultTrashBinActionsView({model: this.agentResult});
      this.view.showActions(this.trashBinActionsView);

      this.listenTo(this.trashBinActionsView,
                    'clicked:restore',
                    this._restoreAgentResult);
    });

  },
  _restoreAgentResult: function () {
    this.agentResult.set('hidden', 'false');
    this.trashBinActionsView.toggleButton();

    var self = this;
    this.agentResult.save().success(function () {
      self.trigger('clicked:restore', self.agentResult);
      self.trashBinActionsView.toggleButton();
    });
  }
});

export default AgentResultTrashBinDetailController;
