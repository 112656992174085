import * as React from "react";
import 'bpopup';

interface IModal {
  title: string;
  cancelLabel: string;
  confirmationLabel: string;
  confirmationButtonBlue?: boolean;
  onConfirm: () => void;
  modalId: string;
  children: any;
  open?: boolean;
  close?: boolean;
  onClose: () => void;
  enableConfirmButton?: boolean;
  onKeyDown?: (e: any) => void;
}

// This is a clone of components/modal that can be used in contexts where
// the react component just updates in place and isn't re-mounted
// (as it needs to do for components/modal to work).
// Ideally we replace components/modal with this one
class Modal extends React.Component<IModal> {
  modal: React.RefObject<any> = React.createRef();
  confirmBtnRef: React.RefObject<any> = React.createRef();
  private popup: any;

  render() {
    const style = { display: 'none' }
    let confirmationButtonLabels = [
      "button", "button--block", "button--small"
    ];
    if (this.props.confirmationButtonBlue) {
      confirmationButtonLabels.push("button--primary") ;
    } else {
      confirmationButtonLabels.push("button--negative") ;
    }
    return (
      <div
        className="modal"
        ref={this.modal}
        id={this.props.modalId}
        style={style}>
        <div className="modal__head">
          <h2 className="modal__head-title">{this.props.title}</h2>
        </div>
        <div className="modal__body">
          <div>{this.props.children}</div>
        </div>
        <div className="modal__footer">
          <div className="action-bar">
            <div className="col1of2">
              <div className="action-bar__action">
                <a className="button button--block button--small b-close">
                  {this.props.cancelLabel}
                </a>
              </div>
            </div>
            <div className="col1of2">
              <div className="action-bar__action">
                <a
                  ref={this.confirmBtnRef}
                  className={`${confirmationButtonLabels.join(' ')} ${!this.props.enableConfirmButton ? "is-disabled" : ''}`}
                  tabIndex={0}>
                  {this.props.confirmationLabel}
                </a>
              </div>
            </div>
          </div>
        </div>
        <span className="modal__close b-close"></span>
      </div>
    );
  }

  onKeyDown(e: any) {
      this.props.onKeyDown && this.props.enableConfirmButton && this.props.onKeyDown(e);
  }

  onConfirm() {
    this.props.enableConfirmButton && this.props.onConfirm();
  }

  componentDidMount(): void {
    if (this.confirmBtnRef.current != null) {
      this.confirmBtnRef.current.onclick = () => {
        this.onConfirm()
      };
    }
  }

  bindHandler = (e: any) => this.onKeyDown(e);

  componentDidUpdate(prevProps: IModal) {
    if (!prevProps.open && this.props.open) {
      this.modal.current.focus();
      document.addEventListener("keydown", this.bindHandler);
      setTimeout(() => {
        const popup = ($(`#${this.props.modalId}`) as any);
        this.popup = popup;
        this.popup.bPopup(this.opts());
      })
    }

    if (!prevProps.close && this.props.close) {
      document.removeEventListener("keydown", this.bindHandler);
      this.closeModal()

    }
  }

  opts() {
    const self = this;
    return {
      onClose: () => {
        self.props.onClose();
        this.removeModalBackground()
      }
    }
  }

  closeModal() {
    setTimeout(() => {
      if (this.popup) {
        this.popup.bPopup(this.opts()).close()
      }
    })
  }

  removeModalBackground() {
    const self = this;
    setTimeout(() => {
      $('.b-modal').remove()
    })
  }
}

export default Modal;
