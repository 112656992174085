import Marionette from 'backbone.marionette'
import TagDetailView from 'views/settings/tagDetailView'
import session from 'models/sessionInstance'
import Notificator from 'libraries/notificator'
import DeletionModalView from 'views/deletionModalView'
import i18n from 'i18n'

var TagDetailController = Marionette.Controller.extend({
  initialize: function (tag) {
    this.tag = tag;
    this.tag.store();
    this.view = new TagDetailView(this.viewOptions());
    this._registerListeners();
    this._completeTagIfNeeded();
  },
  viewOptions: function () {
    var shouldShowRss = !!(session.get('features').tag_rss);
    return {
      model: this.tag,
      shouldShowRss: shouldShowRss
    };
  },
  _registerListeners: function () {
    var self = this;
    this.listenTo(this.view, 'tag:delete', this._deleteTag);
    this.listenTo(this.tag, 'change:name', () => {
      this.tag.save(null, {
        updateOnly: 'name',
        error: function (model, response) {
          var errors = JSON.parse(response.responseText);
          var error_key = errors.name[0];
          Notificator.showErrorNotification(
            i18n.t("webapp.notifications.error.tag.name_" + error_key)
          );
          self.tag.restore();
          self.view.render();
        },
        success: function () {
          self.tag.store();
        }
      });
    });
    this.listenTo(this.view, 'close', this.close);
    this.listenTo(this.view, 'showModal', self._showTagDeletion);
  },
  _showTagDeletion: function () {
    this.deletionView = new DeletionModalView({
      confirmation: i18n.t('webapp.settings.tags.tag_details.remove.confirmation',
                           { tag_title: this.tag.get('name')}),
      warning : i18n.t('webapp.settings.tags.tag_details.remove.warning'),
      deleteLabel : i18n.t('webapp.settings.tags.tag_details.remove.remove'),
      cancelLabel : i18n.t('webapp.settings.tags.tag_details.remove.cancel')
    });
    this.listenTo(this.deletionView, 'modal:delete', this._deleteTag);
    this.view.showDeletion(this.deletionView);
  },
  _deleteTag: function () {
    var self = this;
    this.tag.destroy({
      wait: true,
      dataType: 'text',
      error: function () {
        Notificator.showNotification(
          i18n.t("webapp.notifications.warning.update_failed")
        );
      },
      success: function () {
        self.trigger("tag:deleted", self.tag);
      }
    });
  },
  _completeTagIfNeeded: function () {
    if (this.tag.isIncomplete()) {
      var view = this.view;
      this.tag.fetch().done(function () {
        view.render();
      });
    }
  }
});

export default TagDetailController;

