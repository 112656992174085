import Marionette from 'backbone.marionette'
import _ from 'underscore'
import AgentTopicView from 'views/agents/agentTopicView'
import LivePreviewListController from 'controllers/agents/livePreviewListController'
import moment from 'moment'
import SourceCategoriesInstancePromise from 'models/sourceCategoriesInstancePromise'
import TopicFormController from 'controllers/agents/topicFormController'
import Notificator from 'libraries/notificator'
import Usage from 'models/usage';
import UnitOrderModalView from 'views/settings/unitOrderModalView';
import I18n from 'i18n'

export default class AgentTopicController extends Marionette.Controller {
    initialize(options) {
        this.topic = options.topic;
        this.view = new AgentTopicView({topic: this.topic});
        this.agent = options.agent;
        this._registerListeners();

        this.listenTo(this.view, 'render', () => {
            this.topicFormController = new TopicFormController({
                topic: this.topic
            });
            const view = this.topicFormController.view;
            this.view.showTopicForm(view);
        });
    }

    _registerListeners() {
        this.listenTo(this.view, "submitted:form", this._handleFormSubmitted)

        this.listenTo(this.view, "canceled:form", () => {
            this.view.resetForm();
            this.trigger("topic:cancelled");
        });

        this.listenTo(this.view, "delete:topic", () => {
            this.topic.destroy();
            this.trigger("topic:deleted");
        });

        this.listenTo(this.view,
                      'topicUpdated',
                      this._handleTopicUpdated);
    }

    _handleFormSubmitted() {
        if (!this.topicFormController.isFormValid()) {
            return;
        }

        this.view.toggleButtons();

        if (this.topic.isNew()) {
            let usage = new Usage()
            usage.load().then(() => {
                if (usage.isMinimumUsageReached('search_topics')) {
                    this._showOrderModal(usage)
                } else {
                    this._performSave();
                }
            })
        } else {
            this._performSave();
        }
    }

    _showOrderModal(usage) {
        this.orderModalView = new UnitOrderModalView({
            unitType: 'search_topics',
            usage: usage
        })
        this.listenTo(this.orderModalView, 'modal:confirmed', this._performSave)
        this.listenTo(this.orderModalView, 'modal:cancelled', () => this.view.toggleButtons())
        this.view.orderModal.show(this.orderModalView)
        this.orderModalView.showModal()
    }

    onClose() {
        super.onClose()
        if (!_.isUndefined(this.orderModalView)) {
            this.orderModalView.close();
        }
    }

    _performSave() {
        this.view.topic.save(this.view.buildParameters(), {
            success: (model) => {
                this.agent.get('topics').add(model);
                this.view.toggleButtons();
                this.trigger("topic:saved");
            },
            error: (model, response) => {
                if (response.status === 409) {
                    const text = response.responseText;
                    if (text.indexOf("Topic limit exceeded") >= 0) {
                        this.trigger("topic:limitReached");
                    } else if (text.indexOf("Global topic/smt limit exceeded") >= 0) {
                        this.trigger("topic:globalLimitReached");
                    } else {
                        const message = I18n.t("webapp.notifications.error.not_saved");
                        Notificator.showNotification(message);
                    }
                }
                this.view.toggleButtons();
            }
        });
    }

    livePreviewResultLimit() {
        return 10;
    }

    _topicParamsEmpty(topicParams) {
        if (topicParams.terms.length === 0) {
            return true;
        }
    }

    _handleTopicUpdated(topicParams) {
        if (!this.topicFormController.isFormValid()) {
            return;
        }

        if (this._topicParamsEmpty(topicParams)) {
            return;
        }

        SourceCategoriesInstancePromise.fetch();
        SourceCategoriesInstancePromise.done((sourceCategories) => {
            let options = {
                topicBuildParameters: topicParams,
                limit: this.livePreviewResultLimit(),
                since: moment().subtract('months', 1)
            };

            _.extend(options, {
                sourceCategoryIds: this.agent.get('source_category_ids'),
                sourceGroupIds: this.agent.get('source_group_ids'),
                sourceIdsToExclude: this.agent.get(
                    'source_exclusions').pluck('id')
            });

            this.livePreviewListController =
                new LivePreviewListController(options);

            this.view.showLivePreview(this.livePreviewListController.view);
        });
    }
}
