import Marionette from 'backbone.marionette';
import handleTooltips from 'models/handleTooltips';

class ReaderSchedulingView extends Marionette.ItemView {
    initialize(options) {
        this.currentOption = options.currentOption;
        this.template = options.template;
        this.viewData = options.viewData;
        handleTooltips(this);
    }

    select(option) {
        this._toggleOptionState(this.currentOption, false);
        this._toggleOptionState(option, true);
        this.currentOption = option;
    }

    serializeData() {
        return this.viewData;
    }

    onRender() {
        this._toggleOptionState(this.currentOption, true);
    }

    _optionsClicked(event) {
        const element = event.currentTarget;
        const option = this.$(element).data('option');
        this._handleOptionClick(option);
    }

    _handleOptionClick(option) {
        if (this.currentOption === option) { return; }
        this._toggleOptionState(this.currentOption, false);
        this._toggleOptionState(option, true);
        this.currentOption = option;
        this.trigger('change', option);
    }

    _toggleOptionState(option, selected) {
        const element = this.$(`[data-option="${option}"]`);
        element.toggleClass('is-active', selected);
    }
}

ReaderSchedulingView.prototype.events = {
    'click @ui.options': '_optionsClicked'
};
ReaderSchedulingView.prototype.ui = {
    options: '[data-option]'
};

export default ReaderSchedulingView;
