import TimelineController from 'controllers/timelineController';
import AgentResultListController from 'controllers/agents/agentResultListController';
import AgentResults from 'collections/agentResults';
import AgentResultsApi from 'collections/agentResultApiCollection';
import I18n from 'i18n';
import Logger from 'libraries/logger';
import _ from 'underscore';
import pusherWrapper from 'models/pusherWrapper';
import SelectionObserver from 'models/timeline/selectionObserver';
import moment from 'moment';
import Notificator from 'libraries/notificator';
import TimelineFilter from 'models/agents/timelineFilter';
import when from 'when';
import agents from 'collections/agentsInstance';
import session from 'models/sessionInstance';

class AgentsTimelineController extends TimelineController {
    initialize(options) {
        TimelineController.prototype.initialize.apply(this, [options]);
        this.listenTo(
          pusherWrapper,
          'agent_result_changed',
          this._handleAgentResultChange
        );
        this.listenTo(
          pusherWrapper,
          'agent_result_added',
          this._fetchNewResults
        );
        this.listenTo(
          pusherWrapper,
          'agent_result_destroyed',
          this._handleAgentResultDestruction
        );
        this.selectionObserver = new SelectionObserver(this.selection);
        if (!this.showTimeline2) {
          this.selectionObserver.start();
        }
        this.pollLocked = false;
        this.lastUpdate = moment().unix();
        this.agentResultIdsMarkedForDeletion = [];
        if (!session.sortByPublishTimeEnabled()) {
          this._registerPusherConnectivityListeners();
        }
        this._rendered = when.promise((resolve) => {
            this.listenTo(this.view, 'render', () => {
                resolve();
            });
        });

        this.agents = _.compact(
          options.agentIds.map((id) => agents.get(id))
        );
        this.agents.forEach((agent) => {
            this.listenTo(agent, 'change', () => {
                this._updateInitializingWarning();
            });
        });
        this._updateInitializingWarning();
    }

    getTime(agentResult) {
      if (session.sortByPublishTimeEnabled()) {
        return new Date(agentResult.get('publish_time') * 1000);
      } else {
        return new Date(agentResult.get('created_at'));
      }
    }
    pollNewResults() {
        let self = this;

        this.pollResultsTimerId = setInterval(function () {
            if (!self._isPollingLocked()) {
                self._pollNewResults();
            }
        }, this._pollingInterval);
    }
    onClose() {
        this._clearPollingInterval();
        TimelineController.prototype.onClose.call(this);
        if (!this.showTimeline2) {
          this.selectionObserver.stop();
        } else {
          this.timeline2.destroyReactComponent();
        }
    }

    _initialFetch() {
      TimelineController.prototype._initialFetch.call(this);
    }

    _clearPollingInterval() {
        let intervalId = this.pollResultsTimerId;

        if (!_.isUndefined(intervalId)) {
            this.pollResultsTimerId = undefined;
            clearInterval(intervalId);
        }
    }
    _pollingOptions(agentIds) {
        if (this.newestResult) {
            return _.extend(this.listOptions, {
                sinceId: this.newestResult.get('id'),
                limit: this._newResultLimit,
                agentIds: agentIds
            });
        } else {
            return _.extend(this.listOptions, {
                limit: this._newResultLimit,
                agentIds: agentIds
            });
        }
    }
    _fetchNewResults(data) {
        if (this._isAgentWithIdSelected(data.agent_id)) {
            if (!this._isPollingLocked()) {
                this._pollNewResults();
            }
        }
    }
    _pollResultUpdates() {
        if (!this._isUpdatePollingLocked()) {
            Logger.debug("AgentsTimeline: Polling for updated results");
            this._pollUpdatedResults();
        }
    }
    _pollNewResults() {
        Logger.debug("AgentsTimeline: Polling for new results");
        this._lockPolling();
        let self = this;
        let agentIds = this.options.agentIds;
        let newResults = new this.ListItemApiCollection(
            [], this._pollingOptions(agentIds)
        );

        newResults.appendResults()
            .done(function () {
                if (newResults.length > 0) {
                    self.newestResult = newResults.first();

                    if (newResults.length >= self._newResultLimit &&
                        !newResults.allItemsLoaded()) {
                        self._pollNewResults();
                    }

                    self._rendered.done(() => {
                        self._displayNewResults(newResults.models);
                    });
                }
            })
            .always(function () {
                self._releasePollingLock();
            });
    }
    _updatePollingOptions() {
        if (this.lastUpdatedResult) {
            return _.extend(this.listOptionKeys, {
                updatedSinceId: this.lastUpdatedResult.get('id'),
                limit: this._newResultLimit,
                agentIds: this.options.agentIds
            });
        } else if (this.lastUpdate) {
            return _.extend(this.listOptionKeys, {
                updatedAtAfter: this.lastUpdate,
                limit: this._newResultLimit,
                agentIds: this.options.agentIds
            });
        } else {
            return _.extend(this.listOptionKeys, {
                limit: this._newResultLimit,
                agentIds: this.options.agentIds
            });
        }
    }
    _pollUpdatedResults() {
        this._lockUpdatePolling();
        let self = this;
        let results = new this.ListItemApiCollection(
            [], this._updatePollingOptions()
        );

        results.updateResults()
            .done(function () {
                if (results.length > 0) {
                    self.lastUpdatedResult = results.first();

                    results.each(function (result) {
                        let shownResult = self.shownListItems.get(result.id);
                        if (shownResult) {
                            result.setPolled();
                            self.shownListItems.set(
                                result, { remove: false, add: false }
                            );
                            shownResult.comments.set(result.comments.models);
                        }
                    });

                    if (results.length >= self._newResultLimit &&
                        !results.allItemsLoaded()) {
                        self._pollUpdatedResults();
                    }
                }
            })
            .always(function () {
                self._releaseUpdatePollingLock();
            });
    }
    _displayNewResults(newResults) {
        let time              = this.getTime(this.newestResult);
        let newResultDayId    = this.getDayContaining(time).cid;
        let newResultDay      = this.dayViews[newResultDayId];
        let resultController  = this.listControllers[newResultDayId];

        newResultDay.showDay();

        if (_.isUndefined(resultController)) {
            this.showList(newResultDay);
            resultController  = this.listControllers[newResultDayId];
        } else {
            _.each(newResults, function (newResult) {
                newResult.setPolled();
                resultController.agentResults.add(newResult, {at: 0});
            });
        }

        if (!_.isUndefined(resultController)) {
            resultController.trigger(
                'newItemsLoaded',
                newResultDay,
                resultController
            );
        }
    }
    _startDate(options) {
        if (_.isUndefined(options.startAt)) {
            return new Date();
        } else {
            return options.startAt;
        }
    }
    _handleAgentResultChange(data) {
        if (this._isAgentWithIdSelected(data.agent_id)) {
            let agentResult =
                this.findAgentResultById(data.agent_result_id);
            if (!_.isUndefined(agentResult)) {
                agentResult.setPolled();
                agentResult.fetch().done(function () {
                    agentResult.trigger('updatedByOtherUser');
                });
            }
        }
    }
    findAgentResultById(agentResultId) {
        return this.shownListItems.get(agentResultId);
    }
    _isAgentWithIdSelected(agentId) {
        return _.contains(this.options.agentIds, agentId);
    }
    _lockPolling() {
        this.pollingLock = true;
    }
    _releasePollingLock() {
        this.pollingLock = false;
    }
    _isPollingLocked() {
        return this.pollingLock === true;
    }
    _lockUpdatePolling() {
        this.updatePollingLock = true;
    }
    _releaseUpdatePollingLock() {
        this.updatePollingLock = false;
    }
    _isUpdatePollingLocked() {
        return this.updatePollingLock === true;
    }
    _handleAgentResultDestruction(data) {
        let id = data.agent_result_id;
        if (this._isAgentResultDetailViewDisplayed(id) &&
            this._isAgentWithIdSelected(data.agent_id)) {
            this.agentResultIdsMarkedForDeletion.push(id);
            this._showConcurrentDestructionNotification();
        } else {
            let agentResult = this._findAgentResultById(id);
            this._triggerAgentResultDestroy(agentResult);
        }
    }
    _handleSelectionRemoval (listItem) {
        this._maybeTriggerAgentResultDestroy(listItem);
        this.notifyDeselect(listItem);
    }
    _isAgentResultDetailViewDisplayed(agentResultId) {
        return this.selection.get(agentResultId);
    }
    _showConcurrentDestructionNotification() {
        let key =
            'webapp.notifications.agent_results.concurrent_deletion';
        Notificator.showNotification(I18n.t(key));
    }
    _maybeTriggerAgentResultDestroy(agentResult) {
        let id = agentResult.get('id');
        if (_.contains(this.agentResultIdsMarkedForDeletion, id)) {
            this.agentResultIdsMarkedForDeletion =
                _.without(this.agentResultIdsMarkedForDeletion, id);
            this._triggerAgentResultDestroy(agentResult);
        }
    }
    _triggerAgentResultDestroy(agentResult) {
        if (agentResult) {
            agentResult.trigger('destroy', agentResult, agentResult.collection);
        }
    }
    _registerPusherConnectivityListeners() {
        let self = this;
        this.listenTo(pusherWrapper, 'disconnected', function () {
            self.lastUpdate = moment().unix();
            self.disconnected = true;
        });
        this.listenTo(pusherWrapper, 'connected', () => {
            if (self.disconnected) {
                self._pollResultUpdates();
                if (!this._isPollingLocked()) {
                    self._pollNewResults();
                }
            }
            self.disconnected = false;
        });
    }
    _showNoResults() {
        if (this._isFiltered()) {
            this.view.showNoResults(
                I18n.t('webapp.agents.results.result_list.no_search_results')
            );
        } else {
            this.view.showNoResults(
                I18n.t('webapp.agents.results.result_list.no_results_in_agents')
            );
        }
    }
    _isFiltered() {
        let filter = new TimelineFilter(this.listOptions.filter);
        return filter.isFiltered();
    }
    _updateInitializingWarning() {
        if(!_.all(this.agents, (agent) => agent.get('initialized'))) {
            this._showInitializingWarning();
        } else {
            this._hideInitializingWarning();
        }
    }
    _showInitializingWarning() {
        this.view.showInitializingWarning();
    }
    _hideInitializingWarning() {
        this.view.hideInitializingWarning();
    }
}

AgentsTimelineController.prototype.ListItemCollection = AgentResults;
AgentsTimelineController.prototype.ListItemApiCollection = AgentResultsApi;
AgentsTimelineController.prototype.ListController = AgentResultListController;
AgentsTimelineController.prototype.listOptionKeys = ['agentIds', 'filter', 'query'];
AgentsTimelineController.prototype.moduleName = 'agents';
AgentsTimelineController.prototype._pollingInterval = 60000; // 1 minute in milliseconds
AgentsTimelineController.prototype._newResultLimit = 50;

export default AgentsTimelineController;
