import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'
import { IAddressbookModelProps, IAddressbookGroup }
  from 'models/addressbookModel'
import ContactView, { IContactViewProps } from 'views/contactGroup/contactView'

interface IContactGroupActionsViewProps {
  searchInput: string
  groupAddable: boolean
  groupRemovable: boolean
  onGroupAdd: (group: IAddressbookGroup, query: string) => void
  onGroupRemove: (group: IAddressbookGroup, query: string) => void
  group: IAddressbookGroup
}

interface IContactGroupState {
  isOpen: boolean
}

class ContactGroupActionsView extends React.Component<IContactGroupActionsViewProps, {}> {
  render() {
    let actions: Array<JSX.Element> = []
    if (this.props.groupAddable) {
      actions.push(
        <i onClick={() => { this.props.onGroupAdd(this.props.group, this.props.searchInput) }}
           key={`addContactGroup-%{this.props.group.id}`}
           className="icon-plus"></i>
      )
    }
    if (this.props.groupRemovable) {
      actions.push(
        <i onClick={() => { this.props.onGroupRemove(this.props.group, this.props.searchInput) }}
           key={`removeContactGroup-%{this.props.group.id}`}
           className="icon-delete"></i>
      )
    }
    function spaceArray<T>(array: Array<T>) : Array<T | ' '> {
      return _.flatten(_.map(array, (value: T, index: number) => {
        if (array.length - 1 !== index) {
          return [value, ' ']
        } else {
          return value
        }
      }))
    }
    let spacedActions = spaceArray(actions)

    return (<div className="contact-list__item-actions">{ spacedActions }</div>)
  }
}

export default class ContactGroupView extends React.Component<IContactViewProps, IContactGroupState> {
  constructor(props: IContactViewProps) {
    super(props)
    this.state = this.initialState(props)
  }

  private initialState(props: IContactViewProps) {
    return { isOpen: false }
  }

  render() {
    const group = this.props.contact.data as IAddressbookGroup
    const actionViewProps = {
      searchInput: this.props.searchInput,
      groupAddable: this.props.groupAddable,
      groupRemovable: this.props.groupRemovable,
      onGroupAdd: this.props.onGroupAdd,
      onGroupRemove: this.props.onGroupRemove,
      group: group
    }
    let contactsSize = 0
    if (group.contacts !== undefined) {
      contactsSize = group.contacts.length
    }
    let listClasses = ['contact-list__group-members']
    let classes = ["contact-list__item"]
    if (!this.state.isOpen) {
      listClasses.push('hidden')
    }
    if (!this.props.visible) {
      classes.push('hidden')
    }
    return (
      <li className={classes.join(' ')}>
        <div className="contact-list__item-arrow"
        onClick={() => this.toggleContactVisibility()}>
          <i className={this.arrowClass()} data-collapse=""></i>
        </div>
        <div className="contact-list__item-field">
          <ContactGroupActionsView {...actionViewProps}/>
          <i className="icon-group"></i>
          <span>{group.name}</span>
          <span className="text--grey"> ({contactsSize})</span>
        </div>
        <ul className={listClasses.join(' ')}>
          { this.contactItems() }
        </ul>
      </li>
    )
  }


  private arrowClass(): string {
    if (this.state.isOpen) {
      return 'icon-triangle-down'
    } else {
      return 'icon-triangle-right'
    }
  }

  private toggleContactVisibility() {
    this.setState((prevState, props) => {
      return { isOpen: !prevState.isOpen }
    })
  }

  private contactItems() {
    const group = this.props.contact.data as IAddressbookGroup
    let contactEditable = true;
    if (this.props.groupContactEditable !== undefined) {
      contactEditable = this.props.groupContactEditable;
    }

    return _.map(group.contacts, (contact) => {
      return (
        <ContactView key={contact.id}
                     onAdd={this.props.onAdd}
                     onEdit={this.props.onEdit}
                     onRemove={this.props.onRemove}
                     onDestroy={this.props.onDestroy}
                     onListItemClick={this.props.onListItemClick}
                     onCancel={this.props.onCancel}
                     onUpdate={this.props.onUpdate}
                     onGroupAdd={this.props.onGroupAdd}
                     onGroupRemove={this.props.onGroupRemove}
                     removable={false}
                     destroyable={false}
                     addable={false}
                     editable={contactEditable}
                     editContactId={this.props.editContactId}
                     highlightContactId={this.props.highlightContactId}
                     visible={this.props.visible}
                     searchInput={this.props.searchInput}
                     useButtonStyle={false}
                     groupAddable={false}
                     groupRemovable={false}
                     contact={contact}
                     groupMember={true}/>
      )
    })
  }
}
