import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/settings/agentTopic.html'
import AgentTopic from 'models/agentTopic'

class AgentTopicView extends Marionette.Layout {
  initialize() {
    this.topic = this.options.topic || new AgentTopic();

    this.on('addTerm', () => { this.trigger('addTerm'); }, this);
    this.on('addContext', () => { this.trigger('addContext'); }, this);
    this.on('addExclusion', () => { this.trigger('addExclusion'); }, this);
  }

  _retriggerTopicUpdated(topicParams) {
    this.trigger('topicUpdated', topicParams);
  }

  showTopicForm(view) {
    this.topicFormView = view;
    this.listenTo(this.topicFormView, 'topicUpdated', this._retriggerTopicUpdated);
    this.listenTo(this.topicFormView, 'enter-pressed', () => {
      this.trigger('submitted:form');
    });
    this.topicForm.show(view);
  }

  showLivePreview(view) {
    this.livePreview.show(view);
  }

  serializeData() {
    return { isCreateForm: this.topic.isNew() };
  }

  resetForm() {
    return this.topicFormView.resetForm();
  }

  buildParameters() {
    return this.topicFormView.buildParameters();
  }

  toggleButtons() {
    this.$el.find('#topic_save').toggle();
    this.$el.find('#topic_cancel').toggle();
  }
}
AgentTopicView.prototype.template = template;
AgentTopicView.prototype.regions = {
  topicForm: '[data-topic-form]',
  livePreview: '[data-live-preview]',
  orderModal: '[data-order-modal]'
};
AgentTopicView.prototype.triggers = {
  'click #topic_save':   'submitted:form',
  'click #topic_cancel': 'canceled:form',
  'click #topic_delete': 'delete:topic'
};

export default AgentTopicView;
