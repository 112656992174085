import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/settings/sourceCategoryLicensing.html';
import _ from 'underscore';
import SourceCategoryLicensingList from '../../components/SourceCategoryLicensingList';
import ReactWrapper from 'views/marionetteReactWrapper';
import React from 'react';
import * as $ from 'jquery'

class SourceCategoryLicensingView extends Marionette.Layout {
  initialize() {
    _.bindAll(this, 'handleClickSourceCategory', 'showSourceCategoryList');
  }

  handleClickSourceCategory(item) {
    if (this.$(`#${item.id}`).hasClass('is-active')) {
      this.$(`#${item.id}`).removeClass('is-active');
      this.trigger('close:setUpLicensing');
    } else {
      this.$(`#${item.id}`).addClass('is-active');
      this.trigger('show:setUpLicensing', item);
    }
  }

  showSourceCategoryList() {
    const props = {
      handleClickSourceCategory: this.handleClickSourceCategory,
    }
    const sourceCategoryListView = new ReactWrapper({
      getComponent: () => {
        return <SourceCategoryLicensingList {...props} />;
      },
    });
    this.sourceCategoryList.show(sourceCategoryListView);
  }
}

SourceCategoryLicensingView.prototype.template = template;
SourceCategoryLicensingView.prototype.regions = {
  sourceCategoryList: '[data-source-category-list]',
};
export default SourceCategoryLicensingView;
