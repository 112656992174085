import Marionette from 'backbone.marionette';
import * as template from 'text-loader!templates/newsroom/editReaderCategory.html';
import session from 'models/sessionInstance';

class EditReaderCategoryView extends Marionette.Layout {
    // TODO: Marionette types
    showResultSelection(view: any) {
        this.resultSelection.show(view);
    }

    showLanguageSelection(view: any) {
        this.languageSelection.show(view);
    }

    showMediaSelection(view: any) {
        this.mediaSelection.show(view);
    }

    showEditableTitle(view: any) {
        this.editableTitle.show(view);
    }

    showTopics(view: any) {
        this.topics.show(view);
    }

    showCategoryDeletion(view: any) {
        if (this.deletion) {
            this.deletion.show(view);
        }
    }
    serializeData() {
        return { new_search_agent_management_enabled: session.hasFeature('new_search_agent_management') };
    }
    handleDelCategoryClick(event: any) {
        event.preventDefault();
        this.trigger('showModal');
    }
}

EditReaderCategoryView.prototype.template = template;
EditReaderCategoryView.prototype.regions = {
    editableTitle: '[data-category-title]',
    topics: '[data-category-topics]',
    resultSelection: '[data-category-result-selection]',
    languageSelection: '[data-language-selection]',
    mediaSelection: '[data-media-selection]',
    deletion: '[data-category-deletion]'
};
EditReaderCategoryView.prototype.events = {
    'click [data-delete]': 'handleDelCategoryClick'
};
EditReaderCategoryView.prototype.ui = {
    deleteCategoryBtn: '[data-delete]',
};

export default EditReaderCategoryView;
