/* eslint no-invalid-this: "off" */
import $ from 'jquery'
import LayoutContentResizer from 'modules/layoutContentResizer'

var setupNavigationModule = function (module, ModuleController) {
  module.addInitializer(function () {
    this._createController();
  });

  module.addFinalizer(function () {
    this._closeController();
  });

  module.show = function () {
    this.controller.show();
  };

  module.hide = function () {
    this.controller.hide();
  };

  module._createController = function () {
    this.controller = new ModuleController();
    this.listenTo(this.controller, 'loaded', module._controllerLoaded);
  };

  module._addViewToDOM = function () {
    $('#container-content').append(this.controller.view.el);
    LayoutContentResizer.resizeContent();
    this.hide();
  };

  module._closeController = function () {
    this.controller.close();
    delete this.controller;
  };

  module._controllerLoaded = function () {
    module._addViewToDOM();
    module.trigger('loaded');
  };
};

export default setupNavigationModule;

