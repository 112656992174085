import * as React from "react";

interface SidebarNavItemTitleProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  clickable?: boolean
  children?: any
  isActive?: boolean
  clip_download_enabled? : false
  onMouseOver?: () => void
  onMouseOut?: () => void
}

const SidebarNavItemTitle = (props: SidebarNavItemTitleProps) => {
  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onClick) {
      props.onClick(e)
    }
  }
  const isNonClickable = (!props.clip_download_enabled) && (props.clickable != undefined) && (!props.clickable)
  const clickableClass = isNonClickable ? "nav-tree__link--nonclickable" : ""
  const activeClass    = props.isActive ? "is-active" : ""
  const fullClass      =
    ["nav-tree__link", activeClass, clickableClass].join(" ")
  let attrs: any = {}
  if (props.onMouseOver) {
    attrs['onMouseOver'] = props.onMouseOver
  }
  if (props.onMouseOut) {
    attrs['onMouseOut'] = props.onMouseOut
  }

  return (
    <h2 className="nav-tree__item-title" {...attrs}>
      <a className={fullClass} onClick={(e: React.MouseEvent<HTMLElement>) => onClickHandler(e)}>
        {props.children}
      </a>
    </h2>
  )
}

export default SidebarNavItemTitle
