import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/globalSearch.html'
import $ from 'jquery'
import _ from 'underscore'
import handleTooltips from 'models/handleTooltips'
import KeypressCallbacks from 'libraries/keypressCallbacks'
import I18n from 'i18n'
import select2CloseOnBlur from 'libraries/select2CloseOnBlur'

var GlobalSearchView = Marionette.ItemView.extend({
  template: template,
  ui: {
    globalSearchBar: "input[data-global-search]"
  },
  events: {
    'change input[data-global-search]': '_selectionEvent'
  },
  initialize: function () {
    _.bindAll(this, '_selectionEvent');
    this.queryCallback = this.options.queryCallback;
    this.setPlaceholderText();
    handleTooltips(this);
  },
  onDomRefresh: function () {
    this._createSelect2();
  },
  focus: function () {
    this.ui.globalSearchBar.select2('open');
  },
  _createSelect2: function () {
    var globalSearchSelect = this.ui.globalSearchBar;
    globalSearchSelect.select2({
      multiple: true,
      containerCssClass: "custom-select2 custom-select2--search",
      formatResult: this._formatResult,
      formatSelection: this._formatSelection,
      formatNoMatches: function () {
        return I18n.t('webapp.global_search.no_matches');
      },
      formatLoadMore   : I18n.t('webapp.general.loading_more'),
      placeholder: this.placeholderText,
      query: this.queryCallback
    });
    select2CloseOnBlur(globalSearchSelect);
    globalSearchSelect.prevObject.keyup(KeypressCallbacks.blurOnEscape);
  },
  hide: function () {
    this.$el.hide();
  },
  show: function () {
    this.$el.show();
    this.render();
  },
  clearSelection: function () {
    this.ui.globalSearchBar.select2('val', "").select2('data', null);
  },
  _selectionEvent: function (event) {
    var dataType;

    if (event.added) {
      dataType = event.added.type;

      if (dataType === 'keyword') {
        this.trigger('keyword:selected', event.added);
      } else if (dataType === 'search') {
        this.trigger('search:selected', event.added);
      } else if (dataType === 'tag') {
        this.trigger('tag:selected', event.added);
      } else if (dataType === 'source') {
        this.trigger('source:selected', event.added);
      } else {
        this.trigger('option:selected', event.added);
      }
    }
    if (event.removed) {
      dataType = event.removed.type;

      if (dataType === 'keyword') {
        this.trigger('keyword:deselected', event.removed);
      } else if (dataType === 'tag') {
        this.trigger('tag:deselected', event.removed);
      } else if (dataType === 'source') {
        this.trigger('source:deselected', event.removed);
      } else {
        this.trigger('option:deselected', event.removed);
      }
    }
  },
  _formatResult: function (result, container) {
    if (result.type === "tag") {
      var tagType = result.smarttag ? 'smarttag' : 'tag';
      return "<span class='select2-result-searchquery'>" +
        "<i class='icon-" + tagType + " mrx'></i>" +
        result.text +
        "</span>" +
        "<span class='select2-result-type'>" +
        I18n.t("webapp.global_search." + tagType) +
        "</span>" ;
    }
    else if (result.type === "source") {
      return "<span class='select2-result-searchquery'>" +
        "<i class='icon-globe mrx'></i>" +
        result.text +
        "</span>" +
        "<span class='select2-result-type'>" +
        I18n.t("webapp.global_search.source") +
        "</span>";
    }
    else if (result.type === 'topic') {
      return "<span class='select2-result-searchquery'>" +
        result.text +
        "</span>" +
        "<span class='select2-result-type'>" +
        I18n.t("webapp.global_search.topic") +
        "</span>";
    }
    else if (result.type === "keyword") {
      return "<span class='select2-result-searchquery'>" + result.text + "</span><span class='select2-result-type'>" + I18n.t("webapp.global_search.agent_results_containing") + ": " + result.text + "</span>";
    }
    else if (result.type === "search") {
      $(container).addClass('select2-result-action');
      var icon = '<i class="icon-lens mrx"></i>';
      return "<span class='select2-result-searchquery'>" + icon + I18n.t("webapp.global_search.research_for") + ": " + result.text + "</span>";
    }
    else {
      return result.text;
    }
  },
  _formatSelection: function (result, container) {
    if (result.type === "tag") {
      var tagType = result.smarttag ? 'smarttag' : 'tag';
      return "<span class='select2-result-searchquery'>" +
        "<i class='icon-" + tagType + " mrx'></i>" +
        result.text +
        "</span>"
    }
    else if (result.type === "source") {
      return "<span class='select2-result-searchquery'>" +
        "<i class='icon-globe mrx'></i>" +
        result.text +
        "</span>"
    }
    else if (result.type === 'topic') {
      return "<span class='select2-result-searchquery'>" +
        I18n.t('webapp.global_search.topic_selection_prefix') + ' ' +
        result.text +
        "</span>"
    }
    else if (result.type === "keyword") {
      return result.text;
    }
    else if (result.type === "search") {
      $(container).css('background', 'red');
      return "<span class='select2-result-action'>" + result.text + "</span>";
    }
    else {
      return result.text;
    }
  },
  setPlaceholderText: function (text) {
    this.placeholderText = text || this.defaultPlaceholder();
    this._updatePlaceholderText();
  },
  _updatePlaceholderText: function () {
    if (!_.isString(this.ui.globalSearchBar)) {
      this.ui.globalSearchBar.select2('destroy');
      this._createSelect2();
    }
  },
  defaultPlaceholder: function () {
    return I18n.t('webapp.global_search.general_placeholder');
  },
  setSelection: function (options, triggerChange) {
    this.ui.globalSearchBar.select2('data', options, triggerChange);
  }
});


export default GlobalSearchView;

