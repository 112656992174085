import * as React from "react";
import Sidebar from "./Sidebar";
import WidgetSpace from "./WidgetSpace";
import session from "models/sessionInstance";
import { UpdateAction, update, User } from "../redux/user";
import {
  UpdateAction as UpdateDashboardAction,
  update as updateDash
} from "../redux/selectedDashboard";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import Loading from "./Loading";
import { Store } from "../redux/store";
import {
  getWidgets,
  LoadAction as LoadWidgets,
  WidgetStore
} from "../redux/widgets";
import {
  LoadAction,
  getDashboard,
  DashboardStore
} from "../redux/dashboards";
import { dateRanges, dateRangeIdx } from "../redux/selectedDateRange";
import DashboardErrorMsg from "./DashboardErrorMsg";
import { reset } from "../redux/searchAgents";
import UnexpectedErrorMessage from "./UnexpectedErrorMessage";
import { BugsnagErrorBoundary } from "../../../libraries/bugsnag";

interface Props {
  update(payload: number | undefined): UpdateAction;
  updateDash(payload: number): UpdateDashboardAction;
  dashboards: DashboardStore;
  widgets: WidgetStore;
  getDashboard(): LoadAction;
  getWidgets(dashboardId: number, startTS: number, endTS: number): LoadWidgets;
  selectedDashboard: number;
  selectedDateRange: number;
  reset(): void;
  showDashboard: boolean;
}

class Dashboard extends React.PureComponent<
  Props,
  { showNotification: boolean }
> {
  state = {
    showNotification: false
  };

  componentDidMount() {
    this.props.update(session.get("user").id);
    this.props.getDashboard();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.dashboards !== this.props.dashboards &&
      this.props.dashboards.value.length > 0
    ) {
      this.props.updateDash(this.props.dashboards.value[0].id);
    }

    if (
      prevProps.selectedDashboard !== this.props.selectedDashboard ||
      prevProps.selectedDateRange !== this.props.selectedDateRange
    ) {
      this.props.getWidgets(
        this.props.selectedDashboard,
        dateRanges[this.props.selectedDateRange][dateRangeIdx.startTimeStamp],
        dateRanges[this.props.selectedDateRange][dateRangeIdx.endTimeStamp]
      );
    }
  }

  reloadDashboard = (selectedDashboard: number) => {
    if (selectedDashboard === this.props.selectedDashboard) {
      this.props.getWidgets(
        this.props.selectedDashboard,
        dateRanges[this.props.selectedDateRange][dateRangeIdx.startTimeStamp],
        dateRanges[this.props.selectedDateRange][dateRangeIdx.endTimeStamp]
      );
      this.props.reset();
    }
  }

  render() {
    return (
      <div className="layout">
        <div className="layout-wrapper">
        <BugsnagErrorBoundary FallbackComponent={UnexpectedErrorMessage}>
          {this.props.dashboards.value.length > 0 ? (
            <Sidebar dashboards={this.props.dashboards.value} reloadDashboard={this.reloadDashboard}/>
          ) : (
            !this.props.dashboards.isNetworkRequestOngoing && (
              <>
              <Sidebar dashboards={this.props.dashboards.value} reloadDashboard={this.reloadDashboard}/>
              <DashboardErrorMsg />
              </>
            )
          )}
          {!this.props.widgets.isNetworkReqOngoing && this.props.showDashboard ? (
              <WidgetSpace dashboards={this.props.dashboards.value} />
          ) : (
                <Loading cssClass="layout-content greyed-container"/>
              )}
            </BugsnagErrorBoundary>
        </div>  
      </div>
    );
  }
}

export const filterDashboards = createSelector(
  [(store: Store) => store.dashboards, (store: Store) => store.user],
  function(dashboards: DashboardStore, user: User): DashboardStore {
    return {
      ...dashboards,
      value: dashboards.value.filter(i => i.subscribedUserIds.includes(user.id))
    };
  }
);

export default connect(
  (store: Store) => ({
    widgets: store.widgets,
    dashboards: filterDashboards(store),
    selectedDashboard: store.selectedDashboard,
    selectedDateRange: store.selectedDateRange,
    showDashboard: store.showDashboard
  }),
  { update, updateDash, getDashboard, getWidgets, reset }
)(Dashboard);
