/* jshint module: true */

import _ from 'underscore';
import Backbone from 'backbone';
import Logger from 'libraries/logger';
import Visibility from 'libraries/visibility';
import Pusher from 'pusher-js';
import session from 'models/sessionInstance'

let pusherWrapper = _.extend({}, Backbone.Events, {
    setChannel(channelName) {
        this._initPusher();
        this.channelName = channelName;
    },
    // Used by NCL
    getChannel() {
        return this.channel;
    },
    // Used by NCL
    getConnectionState() {
        return this.pusher.connection.state;
    },
    // Used by NCL
    getSocketId() {
       if (this.pusher.connection.state === 'connected') {
         return this.pusher.connection.socket_id;
       }
    },
    // Used by NCL
    getSessionId() {
      return this.channel.members.me.id;
    },
    startListening() {
        this.channel = this.pusher.subscribe(this.channelName);
        this.channel.bind_global((eventName, data) => {
            this.trigger(eventName, data);
        });
        this._connect();
        this.listenTo(Visibility, 'user-is-away', this._disconnect);
        this.listenTo(Visibility, 'user-is-back', this._connect);
        this.pusher.connection.bind('connected', () => {
            this.trigger('connected');
        });
        this.pusher.connection.bind('disconnected', () => {
            this.trigger('disconnected');
        });
        this.pusher.connection.bind('unavailable', () => {
            this.trigger('disconnected');
        });
    },
    stopListening() {
        this.stopListeningTo(Visibility);
        this.channel.unbind_global();
    },
    triggerClientEvent(name, data){
        if (this.pusher.connection.state === 'connected') {
            this.channel.trigger(name, data);
        }
    },
    _initPusher() {
         let opts = {
             authEndpoint: 'api/v3/pusher',
             encrypted: true,
             cluster: 'eu'
        };
        this.pusher = new Pusher(session.get('br_pusher_key'), opts);
        this._getSocketId();
    },
    _getSocketId() {
        this.pusher.connection.bind('connected', () => {
            this.socketId = this.pusher.connection.socket_id;
        });
    },
    _disconnect() {
        this.pusher.disconnect();
    },
    _connect() {
        this.pusher.connect();
    },
});

Pusher.log = message => {
    Logger.debug(message);
};

export default pusherWrapper;
