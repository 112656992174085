import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/socialMediaMonitorList.html'
import SocialMediaMonitorListItemView from 'views/agents/socialMediaMonitorListItemView'

var SocialMediaMonitorListView = Marionette.CompositeView.extend({
  template: template,
  itemView: SocialMediaMonitorListItemView,
  appendHtml: function(collectionView, itemView) {
    var addSmmButton = collectionView.$("#add-smm-button");

    if (addSmmButton.length > 0) {
      addSmmButton.before(itemView.el);
    } else {
      collectionView.
        $("#social-media-monitor-list").append(itemView.el);
    }
  },
  initialize: function () {
    this.on("itemview:social_media_monitor:clicked", (itemView) => {
      this.trigger('clicked:social_media_monitor', itemView.model);
      this.deselectAll();
      itemView.activate();
    });
  },
  triggers: {
    "click #add-smm-button": "add:smm"
  },
  removeAddSocialMediaMonitorButton: function () {
    if (this.$("#add-smm-button").length > 0) {
      this.$("#add-smm-button").remove();
    }
  },
  addAddSocialMediaMonitorButton: function () {
    if (this.$("#add-smm-button").length === 0) {
      this.$("#social-media-monitor-list").append(
        this.$("#add-smm-button-template > a").clone()
      );
      this.$("#social-media-monitor-list > a:last").attr(
        "id", "add-smm-button"
      );
    }
  },
  deselectAll: function () {
    this.children.each(function (view) {
      view.deactivate();
    });
  }
});

export default SocialMediaMonitorListView;

