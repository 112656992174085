import WidgetView from 'views/dashboard/widgetView'
import MultiChartWidgetView from 'views/dashboard/widgetView/multiChartWidgetView'
import template from 'text-loader!templates/dashboard/widget/multiAgentChartWidget.html'

WidgetView.MultiAgentChartWidget = MultiChartWidgetView.extend({
  template: template,
  getColor: function (index) { return this.model.get('agents')[index].color; },
  getItems: function () { return this.model.get('agents'); }
});

export default WidgetView.MultiAgentChartWidget;

