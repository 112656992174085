import Marionette from "backbone.marionette";
import { AgentTopicManagementView } from "views/components/agentTopicManagementView";
import ReactWrapper from "views/marionetteReactWrapper";
import * as React from "react";
import _ from "underscore";
import ProviderFinder from "libraries/researchFilter/providerFinder";
import Notificator from "libraries/notificator";
import {buildFilters} from "controllers/research/newResearchController/buildFilters";
import I18n from "i18n";
import Usage from "models/usage";
import * as Backbone from "backbone";
import Entity from "models/entity";
import Topic from "models/topic";

// TODO: move the file to .tsx
export default class NewAgentTopicController extends Marionette.Controller {
  initialize() {
    this.openModal = false;
    this.closeModal = false;
    this.disableButtons = false;
    let json = this.options.topic.get('json')
    // Load from backend
    if (_.isString(json) && json !== "{}") {
      this.filters = JSON.parse(json);
    // after order form
    } else if (_.isArray(json)) {
      this.filters = json;
    // new topic
    } else {
      this.filters = buildFilters(null)
    }
    this.topic = this.options.topic;
    let props = _.extend(
      {}, this._newTopicProps(this.options.topic), this.options.props
    );
    this.reactView = <AgentTopicManagementView {...props} />;
    this.view = new ReactWrapper({ getComponent: () => this.reactView });
    this.agent = this.options.agent;
    this.listenTo(this.topic, "changed:topic_name", () => {
      if (!this.topic.isNew()) { this.topic.save(); }
    });
  }

  formValid(noErrorMessages) {
    return _.every(_.flatten(this.filters), (filter) => {
      let isValid = ProviderFinder[filter.type].isValid(filter.selection);
      if (!isValid && !noErrorMessages) { filter.showErrors = true; }
      return isValid;
    });
  }

  _newTopicProps() {
    const doneCallback = () => {
      let props = _.extend(
        {}, this._newTopicProps(this.topic), { disableButtons: false }
      );
      this.trigger('topic:render', this.topic, props);
    };
    const entities = this.options.topic.get('references') && this.options.topic
      .get('references')
      .filter(el => {
        return !el.meta.identifier;
      })
      .map(i => new Entity(i));

    const topics = this.options.topic.get('references') && this.options.topic
      .get('references')
      .filter(el => {
        return el.meta.identifier;
      })
      .map(i => new Topic(i));

    const entityNameCache = new Backbone.Collection(entities);
    const topicNameCache = new Backbone.Collection(topics);

    return {
      topicNameCache: topicNameCache,
      entityNameCache: entityNameCache,
      filters: this.filters,
      openModal: this.openModal,
      closeModal: this.closeModal,
      titleFieldName: "topic_name",
      title: this.topic.get("topic_name") || this.topic.get("name"),
      model: this.topic,
      onCancel: () => {
        this.trigger("topic:cancelled");
      },
      onDelete: () => {
        if (this.topic) { this.topic.destroy(); }
        this.trigger("topic:deleted");
      },
      onFiltersChange: (filters) => {
        let noErrorMessages = false;
        let filterCount = this.filters.length;
        this.filters = filters;
        // Newly added filter
        if (filterCount < filters.length) { noErrorMessages = true; }
        if (this.formValid(noErrorMessages)) { this.topic.set({ json: this.filters }) }
      },
      onFormSubmit: () => {
        if (this.topic.isNew()) {
          if (this.formValid()) {
            let usage = new Usage();
            usage.load().then(() => {
              if (usage.isMinimumUsageReached('search_topics')) {
                let props = _.extend(
                  {}, this._newTopicProps(this.options.topic), {
                  openModal: true,
                  modalUnitCount: usage.currentUnitUsage('search_topics'),
                  modalUnitPrice: usage.unitPrice('search_topics')
                });
                this.trigger('topic:render', this.topic, props);
              } else {
                if (this.formValid()) {
                  this._performSave(doneCallback);
                } else {
                  doneCallback();
                }
              }
            });
          } else {
            doneCallback();
          }
        } else {
          if (this.formValid()) {
            this._performSave(doneCallback);
          } else {
            doneCallback();
          }
        }
      },
      onSave: () => {
        let props = _.extend(
          {}, this._newTopicProps(this.options.topic), {
            openModal: false,
            disableButtons: true
          }
        );
        this.trigger('topic:render', this.topic, props)
        if (this.formValid()) {
          this._performSave(doneCallback);
        } else {
          doneCallback();
        }
      }
    };
  }

  _performSave(doneCallback) {
    this.topic.save().done((response) => {
      this.agent.get('topics').add(response.object);
      this.trigger("topic:saved");
    }).fail((model, response) => {
      if (response.status === 409) {
        const text = response.responseText;
        if (text.indexOf("Topic limit exceeded") >= 0) {
          this.trigger("topic:limitReached");
        } else if (text.indexOf("Global topic/smt limit exceeded") >= 0) {
          this.trigger("topic:globalLimitReached");
        } else {
          const message = I18n.t("webapp.notifications.error.not_saved");
          Notificator.showNotification(message);
        }
      }
      doneCallback();
    });
  }
}
