import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/dashboard/editModeOverlay.html'

var EditModeOverlayView = Marionette.ItemView.extend({
  template: template,
  className: 'widget-edit-mode-overlay',
  triggers: {
    'click .delete-widget': 'clicked:delete-widget',
    'click .decrease-size': 'clicked:decrease-size',
    'click .increase-size': 'clicked:increase-size'
  },
  ui: {
    decreaseSize: '.decrease-size',
    increaseSize: '.increase-size'
  },
  initialize: function () {
    this.widget = this.options.widget;
    this.on('clicked:delete-widget', () => {
      this.widget.destroy({wait: true});
    });
    this.on('clicked:decrease-size', () => {
      this.widget.decreaseSize();
    });
    this.on('clicked:increase-size', () => {
      this.widget.increaseSize();
    });
  },
  onRender: function () {
    if (!this.widget.sizeDecreasable()) {
      this.ui.decreaseSize.hide();
    }
    if (!this.widget.sizeIncreasable()) {
      this.ui.increaseSize.hide();
    }
  }
});

export default EditModeOverlayView;

