import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/timeline/timelineMonth.html'
import TimelineDayView from 'views/timeline/timelineDayView'
import Moment from 'moment'
import $ from 'jquery'

var TimelineMonthView = Marionette.CompositeView.extend({
  template: template,
  itemView: TimelineDayView,
  itemViewOptions: function () {
    return {customOptions: this.options.customOptions};
  },
  itemViewEventPrefix: "days",
  ui: {
    daysContainer: '[data-container=days]'
  },
  triggers: {
    'click [data-month]': 'month:clicked'
  },
  initialize: function () {
    this.collection = this.model.days;
  },
  serializeData: function () {
    var date = new Moment(this.model.get('date'));
    var monthName = date.format('MMMM');
    return { monthName: monthName };
  },
  appendHtml: function (collectionView, itemView, index) {
    var container = collectionView.ui.daysContainer;
    var followingElement = container.children()[index];
    if (followingElement) {
      collectionView.$(followingElement).before(itemView.el);
    } else {
      container.append(itemView.el);
    }
  },
  onRender: function () {
    if (this.model.isCurrent()) {
      this.$('[data-month]').hide();
    }
  },
  getDistanceFromTopAndBottom: function () {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elementTop = this.$el.offset().top;
    var elementBottom = elementTop + this.$el.height();
    return {
      top: elementTop - docViewTop,
      bottom: docViewBottom - elementBottom
    };
  }
});

export default TimelineMonthView;

