import WidgetView from 'views/dashboard/widgetView'
import SourceDistributionPieWidgetView from 'views/dashboard/widgetView/sourceDistributionPieWidgetView'
import template from 'text-loader!templates/dashboard/widget/tagSourceDistributionPieWidget.html'

WidgetView.TagSourceDistributionPieWidget = SourceDistributionPieWidgetView.extend({
  template: template
});

export default WidgetView.TagSourceDistributionPieWidget;

