import Marionette from 'backbone.marionette'
import _ from 'underscore'
import $ from 'jquery'
import template from 'text-loader!templates/dashboard/widgetGridView.html'
import WidgetView from 'views/dashboard/widgetView'
import EditModeOverlayView from 'views/dashboard/editModeOverlayView'
import 'gridster'
import 'views/dashboard/widgetView/agentChartWidgetView'
import 'views/dashboard/widgetView/multiAgentChartWidgetView'
import 'views/dashboard/widgetView/socialMediaTrackerChartWidgetView'
import 'views/dashboard/widgetView/agentTopSourcesWidgetView'
import 'views/dashboard/widgetView/tagTopSourcesWidgetView'
import 'views/dashboard/widgetView/agentAdValueWidgetView'
import 'views/dashboard/widgetView/tagAdValueWidgetView'
import 'views/dashboard/widgetView/agentSourceDistributionPieWidgetView'
import 'views/dashboard/widgetView/trendingTopicsWidgetView'
import 'views/dashboard/widgetView/swissLogoWidgetView'
import 'views/dashboard/widgetView/swissFacebookWallWidgetView'
import 'views/dashboard/widgetView/tickerWidgetView'
import 'views/dashboard/widgetView/timeWidgetView'
import 'views/dashboard/widgetView/geochartSwitzerlandWidgetView'
import 'views/dashboard/widgetView/welcomeWidgetView'
import 'views/dashboard/widgetView/agentRatingWidgetView'
import 'views/dashboard/widgetView/tagRatingWidgetView'
import 'views/dashboard/widgetView/rssFeedWidgetView'
import 'views/dashboard/widgetView/tagCloudWidgetView'
import 'views/dashboard/widgetView/keySourcesWidgetView'
import 'views/dashboard/widgetView/keyCommentSourcesWidgetView'
import 'views/dashboard/widgetView/trendingTopicsOnAgentClipsWidgetView'
import 'views/dashboard/widgetView/tagChartWidgetView'
import 'views/dashboard/widgetView/multiTagChartWidgetView'
import 'views/dashboard/widgetView/tagSourceDistributionPieWidgetView'

var View = Marionette.ItemView.extend({
  config: {
    widgetMargins: [10, 10],
    widgetBaseDimensions: [133, 110]
  },
  template: template,
  ui: {
    gridsterContainer: ".gridster"
  },
  initialize: function () {
    _.bindAll(this,
              'addWidget',
              'widgetAdded',
              'addAllWidgets',
              'centerWidgets',
              'removeWidget',
              'savePositions');
    this.listenTo(this.options.widgets, "add", this.widgetAdded);
    this.listenTo(this.options.widgets, "reset", this.addAllWidgets);
    this.listenTo(this.options.widgets, "remove", this.removeWidget);
    this.on('centerWidgets', this.centerWidgets);
    // allow tests to substitute the overlay dependency:
    this.EditModeOverlayView = EditModeOverlayView;
    this.bindings = {};
  },
  savePositions: function () {
    var positions = this.gridster.serialize();
    this.options.widgets.savePositions(positions);
  },
  onRender: function () {
    // initialize gridster here
    this.expandWidth();
    this.expandHeight();
    var self = this;
    this.$(".gridster ul").gridster({
      max_size_x: 12,
      max_size_y: 12,
      widget_margins: this.config.widgetMargins,
      widget_base_dimensions: this.config.widgetBaseDimensions,
      extra_cols: 0,
      min_rows: 30,
      draggable: {
        stop: function () {
          self.savePositions();
        }
      },
      serialize_params: function (widgetElement, widget) {
        return {
          id: widgetElement.data('widget-id'),
          position_y: widget.row,
          position_x: widget.col,
          size_x: widget.size_x,
          size_y: widget.size_y
        };
      }
    });
    this.gridster = this.$(".gridster ul").gridster().data('gridster');
    this.gridster.options.autogenerate_stylesheet = false;
    this.restoreWidth();
    this.restoreHeight();

    this.addAllWidgets();
    this.gridster.disable();
  },
  centerWidgets: function () {
    var config   = this.config;
    var gridView = this;

    var leftMostColumnPosition = _.min(this.$('.gridster li').map(function (index, element) {
      return gridView.$(element).offset().left;
    }));

    var rightMostColumnPosition = _.max(this.$('.gridster li').map(function (index, element) {
      var sizex   = gridView.$(element).data('sizex');
      var offset  = gridView.$(element).offset().left;
      var margins = config.widgetMargins[0] * (sizex - 1);

      return offset + sizex * config.widgetBaseDimensions[0] + margins;
    }));

    var availableWidth     = this.ui.gridsterContainer.width();
    var spaceAvailableP    = rightMostColumnPosition < availableWidth;
    var needsLeftPaddingP  = leftMostColumnPosition < (availableWidth - rightMostColumnPosition);

    if (spaceAvailableP && needsLeftPaddingP) {
      var diff = (availableWidth - rightMostColumnPosition) - leftMostColumnPosition;
      this.ui.gridsterContainer.css('padding-left', diff / 2);
    }
  },
  unCenterWidgets: function () {
    this.ui.gridsterContainer.css('padding-left', 0);
  },
  expandWidth: function () {
    var width = (144 + 10) * 30;
    this.ui.gridsterContainer.css('width', width + 'px');
  },

  restoreWidth: function () {
    this.ui.gridsterContainer.css('width', '');
  },

  expandHeight: function () {
    var height = (144 + 10) * 30;
    this.ui.gridsterContainer.css('height', height + 'px');
  },

  restoreHeight: function () {
    this.ui.gridsterContainer.css('height', '');
  },
  removeWidget: function (widget) {
    var view = this.widgetViews[widget.id];
    this._deregisterEventListeners(view);
    this.gridster.remove_widget(view.el, false, this.savePositions);
    delete this.widgetViews[widget.id];
    view.remove();
    view.close();
  },
  widgetAdded: function (widget) {
    this.addWidget(widget);
    this.savePositions();
  },
  addWidget: function (widget) {
    var position_x = widget.get('position_x');
    var position_y = widget.get('position_y');
    var size_x     = widget.get('size_x');
    var size_y     = widget.get('size_y');
    var view = this.createWidgetView(widget);
    view.refresh({ visualFeedback: true });
    this._registerEventListeners(view);
    this.widgetViews[widget.id] = view;
    var widgetElement = this.gridster.add_widget(view.el, size_x, size_y, position_x, position_y);
    widgetElement.data('widget-id', widget.get('id'));
    if (this.editMode && view.enterEditMode) {
      view.enterEditMode();
    }
  },
  _registerEventListeners: function (view) {
    var self = this;

    var hoverHandler = function () { self.handleWidgetHover(view); };
    this.listenTo(view, 'hover', hoverHandler);

    var hoverStopHandler = function () {self.handleWidgetHoverStop(view); };
    this.listenTo(view, 'hover:stop', hoverStopHandler);

    var resizeHandler = function () { self.handleWidgetResize(view); };
    this.listenTo(view, 'resize', resizeHandler);
  },
  _deregisterEventListeners: function (view) {
    this.stopListening(view);
  },
  addAllWidgets: function () {
    this.clearGridster();

    this.options.widgets.each(this.addWidget);
  },
  clearGridster: function () {
    var self = this;
    _.each(this.widgetViews, function (view) {
      self._deregisterEventListeners(view);
    });
    this.widgetViews = {};
    this.gridster.remove_all_widgets();
  },
  createWidgetView: function (widget) {
    var type = widget.get('type');
    var ViewKlass = WidgetView[type];
    if (_.isUndefined(ViewKlass)) {
      ViewKlass = WidgetView;
    }
    var view = new ViewKlass({model: widget});
    view.render();
    return view;
  },
  enterEditMode: function () {
    this.expandWidth();
    this.expandHeight();
    this.gridster.enable();
    _.each(this.widgetViews, function (view) {
      if (view.enterEditMode) { view.enterEditMode(); }
    });
    this.unCenterWidgets();
    this.editMode = true;
  },
  exitEditMode: function () {
    this.editMode = false;
    this.restoreWidth();
    this.restoreHeight();
    this.gridster.disable();
    this.removeAllEditModeOverlays();
    _.each(this.widgetViews, function (view) {
      if (view.exitEditMode) { view.exitEditMode(); }
    });
  },
  removeAllEditModeOverlays: function () {
    _.each(this.widgetViews, function (view) {
      view.removeOverlay();
    });
  },
  addEditModeOverlay: function (widgetView) {
    if (!widgetView.hasOverlay()) {
      this.removeAllEditModeOverlays();
      var overlay = new this.EditModeOverlayView({widget: widgetView.model});
      overlay.render();
      widgetView.showOverlay(overlay);
    }
  },
  handleWidgetHover: function (widgetView) {
    if (this.editMode) {
      this.addEditModeOverlay(widgetView);
    }
  },
  handleWidgetHoverStop: function (widgetView) {
    widgetView.removeOverlay();
  },
  handleWidgetResize: function (widgetView) {
    var widget = widgetView.model,
        size_x = widget.get('size_x'),
        size_y = widget.get('size_y');
    this.gridster.resize_widget($(widgetView.el), size_x, size_y);
    this.savePositions();
  }
});

export default View;

