import _ from 'underscore'
import compactObject from 'libraries/compactObject'

function conditionalSetToMetadata(metadata, key, obj) {
  obj = compactObject(obj);
  if (!_.isEmpty(obj)) { metadata[key] = obj; }
}

export default conditionalSetToMetadata;

