import * as Backbone from "backbone";
import Topic from "../models/topic";


const topicCache = new Backbone.Collection<Topic>();

export const get = (id: number): Topic => {
    return topicCache.get(id);
};
export const set = (entities: Topic[]) => {
    topicCache.set(entities, {remove: false})
};

export const all = (): Topic[] => {
    return topicCache.models
}