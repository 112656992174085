import EditableTitleView from 'views/components/editableTitleView';
import template from 'text-loader!templates/newsroom/readerEditableSummaryTitleView.html';
import I18n from 'i18n';
import _ from 'underscore';

class ReaderEditableSummaryTitleView extends EditableTitleView {
    initialize(options) {
        EditableTitleView.prototype.initialize.apply(this, [options]);
        this.originalTitle = this.model.get('title');
    }

    getSummaryTitle() {
        let titleFromForm = this.$(this.ui.header).find('input').val();
        let existingTitle = this.model.get('title');
        return titleFromForm || existingTitle;
    }

    cancelOrContinue(e) {
        switch (e.keyCode) {
        case 13: // Enter key pressed
            this.assignNewTitle();
            this.trigger('focusOnText');
            break;
        case 27: // Esc key pressed
            this.abortEdit(); // cancel
            this.$(this.ui.header).find('input').text(this.originalTitle);
            break;
        default:
            return; // continue
        }
    }

    assignNewTitle() {
        if (this.isHeaderInEditMode()) {
            if (!_.isEmpty(this._newTitle())) {
                this.model.set(this.titleFieldName, this._newTitle());
            } else {
                this.$(this.ui.header).find('input').text(this.originalTitle);
            }

            this.disabledInplaceEdit();
        }
    }

    _title() {
        let title = this.model.get('title');

        if (this.model.isPresent()) {
            return title;
        } else {
            return I18n.t('webapp.reader_admin.summaries.edit.title_placeholder');
        }
    }
}
ReaderEditableSummaryTitleView.prototype.template = template;
ReaderEditableSummaryTitleView.prototype.events = {
    'click [data-editable-title]': 'enableInplaceEditForAgentHeading',
    'keyup [data-editable-title] input': 'cancelOrContinue',
    'blur [data-editable-title] input': 'assignNewTitle'
};

export default ReaderEditableSummaryTitleView;
