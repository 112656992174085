import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/settings/notifications/notificationSummary.html'

var NotificationSummaryView = Marionette.Layout.extend({
  template: template,
  regions: {
    weekdaySelector: '[data-weekday-selector]',
    timeSelector: '[data-time-selector]',
    agentSelector: '[data-agent-selector]',
    orderSelector: '[data-order-selector]'
  },
  events: { 'click [data-order]' : '_handleClick' },
  updateOrderSelector: function (order) {
    var self = this;
    this.$('[data-order]').each(function (index, button) {
      var jqueryButton = self.$(button);
      if (jqueryButton.attr('data-order') === order) {
        jqueryButton.addClass('is-active');
      } else {
        jqueryButton.removeClass('is-active');
      }
    });
  },
  _handleClick: function (event) {
    var value = this.$(event.target).attr('data-order');
    this.trigger('change', 'agents_order', value);
    this.updateOrderSelector(value);
  }
});

export default NotificationSummaryView;

