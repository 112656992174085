import * as React from "react";
import HighchartsReact from "./highchartsReact";
import I18n from "i18n";
import vent from "libraries/vent";
import { DataSeries } from "../redux/widgets";

interface Props {
  data: WordCloudItem[][];
  dataSeries: DataSeries[];
  className?: string;
  header: string;
}
export interface WordCloudItem {
  topic: {
    id: number;
    name_de: string;
    name_fr: string;
    name_it: string;
    name_en: string;
  };
  weight: number;
}

const options = {
  plotOptions: {
    series: {
      cursor: "pointer"
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        symbol: 
          "url(../styleguide/assets/images/icons/icon-download.png)",
        symbolX: 19,
        symbolY: 19,
        menuItems: ["downloadPNG", "downloadSVG"]
      }
    }
  },
};

const chart = {
  height: 350
}
function getWordCloudData(data: WordCloudItem[][]) {
  return data
    .reduce((i, val) => i.concat(val), [])
    .map(item => ({
      ...item,
      name: getLocalText(item)
    }));
}

function getLocalText(item: WordCloudItem): string {
  switch (I18n.locale) {
    case "en":
      return item.topic.name_en;
    case "fr":
      return item.topic.name_fr;
    case "it":
      return item.topic.name_it;
    default:
      return item.topic.name_de;
  }
}

function filter(ids: number[], type: string) {
  return ids.map(id => ({ type, id }));
}

function drillDown(dataSeries: DataSeries[], event: any) {
  if (dataSeries[0].agent_ids.length > 0 && dataSeries[0].tag_ids.length > 0) {
    throw new Error("data series contains both tags and agents");
  } else if (dataSeries[0].agent_ids.length > 0) {
    vent.commands.execute('agents:showFilteredTimeline', [...filter(dataSeries[0].agent_ids, "agent"), { id: event.point.topic.id, type: "topic", name: event.point.options.name }]);
  } else if (dataSeries[0].tag_ids.length > 0) {
    vent.commands.execute('agents:showFilteredTimeline', [...filter(dataSeries[0].tag_ids, "tag"), { id: event.point.topic.id, type: "topic", name: event.point.options.name  }]);
  }
}

export default function WordCloud({
  className,
  data,
  dataSeries,
  header,
  ...itemStyle
}: Props) {
  const series = [
    {
      type: "wordcloud",
      colors: [
        "rgba(3,56,64,1)",
        "rgba(3,56,64,.8)",
        "rgba(3,56,64,.6)",
        "rgba(3,56,64,.4)",
        "rgba(3,56,64,.3)",
      ],
      allowPointSelect: true,
      point: {
        events: {
          click: drillDown.bind(null, dataSeries)
        }
      },
      rotation: {
        to: 0,
        orientations: 5
      },
      minFontSize: 8,
      data: getWordCloudData(data)
    }
  ];

  const title = {
    text: `${header}`,
    align: "center",
    style: {
      fontWeight: '300',
      color: '#999',
    }
  }

  const tooltip = {
    pointFormatter: function(): string {
      return `<b>${(this as any).name}</b>: ${I18n.toNumber(
        (this as any).weight,
        { precision: 0 }
      )}`;
    },
    headerFormat: ""
  };
  

  return (
    <div
      className={`dashboard-widget__container`}
      {...itemStyle}
    >
      <HighchartsReact options={{ ...options, chart, series, title, tooltip }} />
    </div>
  );
}
