import $ from 'jquery';
import 'froala';
import 'froala.lists';
import 'froala.link';
import 'froala.paragraph_format';
import 'froala.languages.en';
import 'froala.languages.de';
import 'froala.languages.fr';
import froalaButtons from "libraries/froalaButtons";
import addFroalaHighlightButton from 'libraries/addFroalaHighlightButton'
import addFroalaHorizontalRowButton from 'libraries/addFroalaHorizontalRowButton'

$.FroalaEditor.DefineIcon('headingButton', {NAME: 'header'});
$.FroalaEditor.RegisterCommand('heading', {
    title: 'Heading',
    icon: 'headingButton',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function() { this.paragraphFormat.apply('H2'); },
});

$.FroalaEditor.DefineIcon('normalButton', {NAME: 'paragraph'});
$.FroalaEditor.RegisterCommand('normal', {
    title: 'Normal',
    icon: 'normalButton',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function() { this.paragraphFormat.apply('Normal'); },
});

function froalaLinkStyle() {
    return ['linkOpen', 'linkEdit', 'linkRemove'] 
}

addFroalaHighlightButton();
addFroalaHorizontalRowButton();
$.FroalaEditor.DEFAULTS.toolbarButtons = froalaButtons();
$.FroalaEditor.DEFAULTS.toolbarButtonsMD = froalaButtons();
$.FroalaEditor.DEFAULTS.toolbarButtonsSM = froalaButtons();
$.FroalaEditor.DEFAULTS.toolbarButtonsXS = froalaButtons();
$.FroalaEditor.DEFAULTS.linkEditButtons = froalaLinkStyle();