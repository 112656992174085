import Marionette from 'backbone.marionette';
import LayoutView from 'views/layoutView'
import * as React from "react";
import * as _ from 'underscore'
import ReactWrapper from 'views/marionetteReactWrapper';
import { PlaceholderView as Placeholder, Props } from 'components/placeholderView'
import I18n from "i18n";

// abstracts away some of the boilerplate involved in rendering react
// components
abstract class ReactController<T> extends Marionette.Controller {
  initialize(opts: object) {
    this.view = new LayoutView();
    this.listenTo(this.view, 'render', () => this.renderView());
  }

  abstract getComponent(props: T): JSX.Element

  abstract defaultProps(): T

  renderView(additionalOpts?: object) {
    const props = this.buildProps(additionalOpts)
    if (this.reactWrapperView == undefined) {
      this.reactWrapperView = new ReactWrapper({
        getComponent: () => this.getComponent(props)
      });
    }
    this.view.showView(this.reactWrapperView);
  }

  rerender() {
    this.reactWrapperView = undefined;
    this.renderView()
  }

  buildProps(additionalProps?: object): T {
    if (this.props == undefined) {
      this.props = this.defaultProps()
    }
    this.props = _.extend(this.props, additionalProps)
    return this.props
  }
}

export default ReactController
