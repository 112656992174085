import Marionette from 'backbone.marionette'
import $ from 'jquery'
import _ from 'underscore'
import agentResultListItemTemplate from 'text-loader!templates/agentResultListItem.html'
import session from 'models/sessionInstance'

var AgentResultListItemView = Marionette.ItemView.extend({
  template: agentResultListItemTemplate,
  tagName: "li",
  className: "clips-list__item",
  events: {
    'click': 'generalClickHandler',
    'selectstart': 'selectHandler'
  },
  initialize: function () {
    this.listenTo(this.model, 'change', this.render);
  },
  registerClickHandler: function (handler) {
    this.clickHandler = handler;
  },
  registerCtrlClickHandler: function (handler) {
    this.ctrlClickHandler = handler;
  },
  registerShiftClickHandler: function (handler) {
    this.shiftClickHandler = handler;
  },
  selectHandler: function (event) {
    event.preventDefault();
  },
  generalClickHandler: function (e) {
    if (!e.ctrlKey && !e.shiftKey && !e.metaKey) {
      this.clickHandler();
    } else if (e.ctrlKey || e.metaKey) {
      this.ctrlClickHandler();
    } else if (e.shiftKey) {
      this.shiftClickHandler();
    }
  },
  attributes: function () {
    var resultId = this.toListViewData().resultId;
    var clipId   = this.model.get('clip_id');

    return {
      'data-agent-result-id': resultId,
      'data-clip-id': clipId
    };
  },
  onRender: function () {
    var self = this;
    this.registerClickHandler(function () {
      self.trigger('result:clicked', self.model);
    });
    this.registerCtrlClickHandler(function () {
      self.trigger('result:ctrlClicked', self.model);
    });
    this.registerShiftClickHandler(function () {
      self.trigger('result:shiftClicked', self.model);
    });
    if (this.toListViewData().isRead && this.showAgentResultsReadStatus()) {
      this.$el.addClass('is-read');
    } else {
      this.$el.removeClass('is-read');
    }
    if (this.toListViewData().isImportant) {
      this.$el.addClass('is-highlighted');
    } else {
      this.$el.removeClass('is-highlighted');
    }
    if (self.model.isPolled()) {
      self.handleViewUpdate();
      self.model.unsetPolled();
    }
  },
  handleViewUpdate: function () {
    var self = this;
    this.$el.addClass('is-new');
    self.$el.addClass('is-not-new');
    setTimeout(function () {
      self.$el.removeClass('is-new');
    }, 3000);
    setTimeout(function () {
      self.$el.removeClass('is-not-new');
    }, 3100);
  },
  serializeData: function () {
    var data = this.toListViewData();
    var colorCode           = data.color;
    var ratingCss           = '';
    var additionalSourceCount;

    if (data.rating > 0) {
      ratingCss = '--positive';
    } else if (data.rating < 0) {
      ratingCss = '--negative';
    } else if (data.rating === 0) {
      ratingCss = '--neutral';
    }

    if (data.additional_source_count > 0) {
      additionalSourceCount = data.additional_source_count;
    }

    return {
      colorCode: colorCode,
      primary_source_name: data.primary_source_name,
      additional_source_count: additionalSourceCount,
      publish_time: data.publish_time,
      title: data.title,
      mentions: data.mentions.slice(0, 5),
      ratingCss: ratingCss,
      hasComments: (data.commentCount > 0),
      hasTags: (data.tagCount > 0),
      primarySourceFavicon: data.primarySourceFavicon,
      youtubeContentRemoved: data.youtubeContentRemoved,
    };
  },

  showAgentResultsReadStatus: function () {
    return session.hasFeature("show_agent_results_read_status")
  },

  toListViewData: function () {
    var agentResult = this.model;

    var data =  {
      primary_source_name: agentResult.get('primary_source').name,
      source_count: agentResult.get('sources').length,
      additional_source_count: agentResult.additionalSources().length,
      publish_time: agentResult.timelineTime(),
      title: agentResult.get('title'),
      mentions: agentResult.get('mentions'),
      isRead: agentResult.get('read'),
      rating: agentResult.get('rating_value'),
      commentCount: agentResult.comments.length,
      tagCount: agentResult.get('tags').length,
      primarySourceFavicon: agentResult.get('primary_source').favicon_url,
      resultId: agentResult.get('id'),
      isImportant: agentResult.get('important'),
      youtubeContentRemoved: agentResult.youtubeContentRemoved(),
    };

    if (!_.isUndefined(agentResult.agent)) {
      data.color = agentResult.agent.get('colors')['default'];
    }

    return data;
  },
  markAsSelected: function () {
    var el = this.$el;
    el.addClass('is-active');
    if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
      el.css('visibility', 'hidden');
      window.setTimeout(function () { el.css('visibility', 'visible'); }, 0);
    }
  },
  markAsDeselected: function () {
    this.$el.removeClass('is-active');
  },
  isSelected: function () {
    return this.$el.hasClass('is-active');
  },
  getDistanceFromTopAndBottom: function () {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elementTop = this.$el.offset().top;
    var elementBottom = elementTop + this.$el.height();
    return {
      top: elementTop - docViewTop,
      bottom: docViewBottom - elementBottom
    };
  }
});

export default AgentResultListItemView;
