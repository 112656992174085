import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/settings/tagDetail.html'
import _ from 'underscore'

var TagDetailView = Marionette.Layout.extend({
  template: template,
  regions: {
    deletion: '[data-edit-tag-deletion]'
  },
  ui: {
    feedButton: '[data-feed-button]',
    editTagName: '[data-edit-tag-name]',
    editTagNameInput: '[data-edit-tag-name-input]',
    editTagNameElement: '[data-edit-tag-name-element]',
    editTagNameElementHeader: '[data-edit-tag-name-element-header]',
    removeTagBtn: '[data-delete]'
  },
  events: {
    'click @ui.feedButton': '_openFeed',
    'click @ui.editTagName': "enableInplaceEditForTagName",
    'click [data-delete]': 'handleClick',
    'keyup @ui.editTagNameInput': 'saveOrCancelOrContinue',
    'blur  @ui.editTagNameInput': 'assignNewTagName'
  },
  enableInplaceEditForTagName: function () {
    if (!this.isHeaderInEditMode()) {
      this.ui.editTagName.addClass("is-active");
      this.ui.editTagNameInput.val(this.model.get("name"));
      this.ui.editTagNameElement.hide();
      this.ui.editTagNameInput.show();
      this.ui.editTagNameInput.focus();
    }
  },
  isHeaderInEditMode: function () {
    return this.ui.editTagName.hasClass("is-active");
  },
  saveOrCancelOrContinue: function (e) {
    switch (e.keyCode) {
    case 13: // Enter key pressed
      this.assignNewTagName(); // save
      break;
    case 27: // Esc key pressed
      this.abortNewTagName(); // cancel
      break;
    default:
      return; // continue
    }
  },
  assignNewTagName: function () {
    if (this.isHeaderInEditMode()) {
      if (!_.isEmpty(this._newTagName())) {
        this.model.set("name", this._newTagName());
      } else {
        // Restore old if blank
        this.ui.editTagNameInput.text(this.model.get("name"));
      }
      this.disabledInplaceEditForTagHeadint();
    }
  },
  abortNewTagName: function () {
    if (this.isHeaderInEditMode()) {
      this.ui.editTagNameElementHeader.text(this.model.get("name"));
      this.ui.editTagName.removeClass("is-active");
      this.ui.editTagNameElement.show();
      this.ui.editTagNameInput.hide();
    }
  },
  disabledInplaceEditForTagHeadint: function () {
    if (this.isHeaderInEditMode()) {
      this.abortNewTagName();
    }
  },
  _newTagName: function () {
    return this.ui.editTagNameInput.val();
  },
  serializeData: function () {
    return {
      tagName: this.model.get('name'),
      numberOfResults: this.model.get('clip_count'),
      shouldShowRss: this.options.shouldShowRss
    };
  },
  showDeletion: function (view) {
    this.deletion.show(view);
  },
  _openFeed: function () {
    window.open(this.model.get('feed_url'), '_blank');
  },
  handleClick(event) {
    event.preventDefault();
    this.trigger('showModal');
  }
});

export default TagDetailView;

