import Marionette from 'backbone.marionette'
import AgentResultFilterView from 'views/agents/agentResultFilterView'
import _ from 'underscore'
import * as React from 'react'
import TimelineYears from 'collections/timeline/timelineYears'
import moment from 'moment'
import agents from 'collections/agentsInstance'
import AgentCollection from 'collections/agents'
import vent from "libraries/vent";
import session from 'models/sessionInstance';

var AgentResultFilterController = Marionette.Controller.extend({
  // to set the initial states of checkboxes, pass in an object with the
  // desired states
  initialize: function (options) {
    if (!session.timeline2Enabled()) {
      this.view = new AgentResultFilterView();
      this.listenTo(this.view, 'render', () => {
        this.view.setCheckboxStates(this.states);
        this.handleFiltersHighlighting(this.states);
        this.view.initDatePicker(new Date(this.firstResultAt()));
      });
      this.listenTo(this.view, 'changed:date', (date) => {
        this.handleDatePicked(date);
      });
      this.registerEventListeners();
    } 
    this.agents = options.agents;
    this.states = this.parseFilter(options.filter);
  },
  handleDatePicked: function (date) {
    this.view.closeDatePicker();
    this.trigger("changed:date", date);
    vent.trigger("changed:date", date);
  },
  firstResultAt: function () {
    return new AgentCollection(this.agents).firstResultAt();
  },
  datePickerDates: function () {
    const firstResultAt = this.firstResultAt();

    if (firstResultAt) {
      var years = new TimelineYears([], {
        startAt: firstResultAt
      });
      years.fill();

      const dates = years.map(function (year) {
        year.fillMonths();
        const num = parseInt(year.get('date').format('Y'))
        return year.months.map(function (month) {
          return moment(month.get('date'))
        })
      })
      return _.flatten(dates);
    } else {
      return [moment()]
    }
  },
  registerEventListeners: function () {
    this.listenTo(this.view, 'changed:filter:online', this.handleFilterChange);
    this.listenTo(this.view, 'changed:filter:sm', this.handleFilterChange);
    this.listenTo(this.view, 'changed:filter:print', this.handleFilterChange);
    this.listenTo(this.view, 'changed:filter:radiotv', this.handleFilterChange);

    this.listenTo(this.view, 'changed:filter:positive', this.handleFilterChange);
    this.listenTo(this.view, 'changed:filter:neutral', this.handleFilterChange);
    this.listenTo(this.view, 'changed:filter:negative', this.handleFilterChange);
    this.listenTo(this.view, 'changed:filter:unrated', this.handleFilterChange);

    this.listenTo(this.view, 'changed:filter:important',
                  this.handleFilterChange);
    this.listenTo(this.view, 'changed:filter:normal',
                  this.handleFilterChange);
  },
  handleFilterChange: function () {
    this.states  = this.view.getCheckboxStates();
    this.trigger("changed:filterParameters", this.filterParameters());

    // source dropdown
    this.handleCheckboxDisabling(['online', 'socialMedia', 'print', 'radioTv']);

    // rating dropdown
    this.handleCheckboxDisabling(['positive', 'neutral', 'negative', 'unrated']);

    this.handleFiltersHighlighting(this.states);
  },

  handleFiltersHighlighting: function(states) {
    this.handleFilterHighlighting(this.isSourceFilterInDefaultState(states),
                                  'source');
    this.handleFilterHighlighting(this.isRatingFilterInDefaultState(states),
                                  'rating');
  },

  handleFilterHighlighting: function(isFilterInDefaultState,
                                     filterName) {
    if (!isFilterInDefaultState) {
      this.view.highlightCheckbox(filterName);
    } else {
      this.view.unHighlightCheckbox(filterName);
    }
  },
  isSourceFilterInDefaultState: function(checkboxStates) {
    return checkboxStates.online &&
      checkboxStates.socialMedia &&
      checkboxStates.print &&
      checkboxStates.radioTv;
  },
  isRatingFilterInDefaultState: function(checkboxStates) {
    return checkboxStates.positive &&
      checkboxStates.neutral &&
      checkboxStates.negative &&
      checkboxStates.unrated &&
      checkboxStates.normal;
  },
  handleCheckboxDisabling: function (checkboxes) {
    var checkedCheckboxes = _.filter(checkboxes, (checkbox) => {
      return this.states[checkbox];
    }, this);

    if (checkedCheckboxes.length === 1) {
      this.view.disableCheckbox(_.first(checkedCheckboxes));
    } else {
      this.view.enableCheckboxes(checkboxes);
    }
  },
  parseFilter: function (filter) {
    var states = {};

    states.online = _.contains(filter.mediaTypes, "online");
    states.socialMedia = _.contains(filter.mediaTypes, "social_media");
    states.print = _.contains(filter.mediaTypes, "print");
    states.radioTv = _.contains(filter.mediaTypes, "radio_tv");

    states.positive = _.contains(filter.ratings, 10);
    states.neutral = _.contains(filter.ratings, 0);
    states.negative = _.contains(filter.ratings, -10);
    states.unrated = _.contains(filter.ratings, null);

    states.important = _.contains(filter.resultImportance, "important");
    states.normal = _.contains(filter.resultImportance, "normal");

    return states;
  },
  filterParameters: function () {
    var mediaTypes = [];
    var ratings = [];
    var resultImportance = [];

    if (this.states.online) { mediaTypes.push('online'); }
    if (this.states.socialMedia) { mediaTypes.push('social_media'); }
    if (this.states.print) { mediaTypes.push('print'); }
    if (this.states.radioTv) { mediaTypes.push('radio_tv'); }

    if (this.states.positive) { ratings.push(10); }
    if (this.states.neutral) { ratings.push(0); }
    if (this.states.negative) { ratings.push(-10); }
    if (this.states.unrated) { ratings.push(null); }

    if (this.states.important) {
      resultImportance.push('important');
    } else {
      resultImportance.push('important');
      resultImportance.push('normal');
    }

    return { ratings: ratings,
             mediaTypes: mediaTypes,
             resultImportance: resultImportance };
  }
});

export default AgentResultFilterController;
