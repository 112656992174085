export interface GlobalSearchOption {
  id: number
  type: string
  text: string
  priority: number
}

export default abstract class OptionsProvider {
  name: string

  constructor(name: string) {
    this.name = name;
  }

  abstract results(term: string): Promise<GlobalSearchOption[]>
}

