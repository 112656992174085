import Backbone from 'backbone'

var ClipTopicSelection = Backbone.Model.extend({
  identifier: 'clipTopicSelection',
  checkForCompleteness: function () {
    this.set({complete: true});
  },
  data: function () {
    var report = this.get('report');

    return {
      topic_ids: report.get('topic_ids'),
      topic_type: report.get('topic_type'),
      group_by: report.get('group_by')
    };
  }
});

export default ClipTopicSelection;

