import * as React from 'react';
import I18n from 'i18n';
import * as _ from 'underscore';
import Validations from 'libraries/validations';
import session from 'models/sessionInstance';

const SenderDefaultValues = {
  email: function () {
      if (session.hasFeature('make_soprano_default_reader_notification_sender')) {
        return 'doc@parl.admin.ch'
      } else {
        return I18n.t('webapp.reader_admin.edit.notification_configuration.sender.default_email')
      }
  },
  name: function () {
      if (session.hasFeature('make_soprano_default_reader_notification_sender')) {
        return I18n.t('webapp.reader_admin.edit.notification_configuration.sender.soprano')
      } else {
        return I18n.t('webapp.reader_admin.edit.notification_configuration.sender.default_name')
      }
  },
  warningContent: function () {
    if (session.hasFeature('make_soprano_default_reader_notification_sender')) {
      return I18n.t('webapp.reader_admin.edit.notification_configuration.sender.soprano_warning_content')
    } else {
      return I18n.t('webapp.reader_admin.edit.notification_configuration.sender.warning_content')
    }
  }
};

interface IProps {
  name: string
  email: string
  hidden: boolean
  onInputBlurKeyup: (email: string, senderName: string) => void
}

interface IState {
  emailValid: boolean
  senderValid: boolean
}

class ReaderNotificationConfigurationSenderFormView
extends React.Component<IProps, IState> {
    private nameInput?: HTMLInputElement
    private emailInput?: HTMLInputElement

    constructor(props: IProps) {
      super(props);
      this.state = this.initialState(props);
      this.onSelection = _.throttle(this.onSelection, 1000);
      this.nameInput = undefined
      this.emailInput = undefined
    }

    initialState(props: IProps) {
         return {
           emailValid: Validations.isEmailValid(this.props.email),
           senderValid: Validations.isPresent(this.props.name)
         };
    }

    render() {
        const warningHeader = I18n.t(
            'webapp.reader_admin.edit.notification_configuration.sender.warning_header'
        );
        const emailLabel = I18n.t(
            'webapp.reader_admin.edit.notification_configuration.sender.email_label'
        );
        const senderNameLabel = I18n.t(
            'webapp.reader_admin.edit.notification_configuration.sender.sender_name_label'
        );
        const emailError = I18n.t(
            "webapp.settings.users.user_details.validations.email_invalid"
        );
        const senderError = I18n.t(
            "webapp.settings.users.user_details.validations.name_invalid"
        );
        let senderFormClasses = [];
        let emailErrorClasses = ["input-group__feedback", "is-erroneous"];
        let senderErrorClasses = ["input-group__feedback", "is-erroneous"];
        if (this.props.hidden) { senderFormClasses.push('hidden'); }
        if (this.state.emailValid) { emailErrorClasses.push('hidden'); }
        if (this.state.senderValid) { senderErrorClasses.push('hidden'); }

        return (
          <div
            id="notification-sender-options"
            className={senderFormClasses.join(" ")}>
            <div className="form-item mbm">
              <p className="text--grey">
                <strong>{warningHeader}</strong>{" "}
                {SenderDefaultValues.warningContent()}
              </p>
            </div>
            <form>
              <div className="form-item">
                <label
                  htmlFor="absenderemail"
                  className="form-item__label">
                  {emailLabel}
                </label>
                <div className="form-item__input">
                  <input
                    type="email"
                    ref={input => {
                      this.emailInput = input || undefined;
                    }}
                    className="text-input text-input--block"
                    onBlur={this.onSelection.bind(this)}
                    onKeyUp={this.onSelection.bind(this)}
                    defaultValue={this.props.email}
                  />
                </div>
              </div>
              <div className={emailErrorClasses.join(" ")}>
                <i className="icon-info-2" />
                <span>{emailError}</span>
              </div>
              <div className="form-item mbm mtm">
                <label
                  htmlFor="absendername"
                  className="form-item__label">
                  {senderNameLabel}
                </label>
                <div className="form-item__input">
                  <input
                    type="text"
                    ref={input => {
                      this.nameInput = input || undefined;
                    }}
                    className="text-input text-input--block"
                    onBlur={this.onSelection.bind(this)}
                    onKeyUp={this.onSelection.bind(this)}
                    defaultValue={this.props.name}
                  />
                </div>
              </div>
              <div className={senderErrorClasses.join(" ")}>
                <i className="icon-info-2" />
                <span>{senderError}</span>
              </div>
            </form>
          </div>
        );
    }

    email(): string {
      if (this.emailInput) {
        const value = this.emailInput.value
        return value
      } else {
        throw "email is undefined"
      }
    }

    senderName(): string {
      if (this.nameInput) {
        const value = this.nameInput.value
        return value
      } else {
        throw "senderName is undefined"
      }
    }

    onSelection() {
      this.setState((prevState) => {
        return {
          emailValid: Validations.isEmailValid(this.email()),
          senderValid: Validations.isPresent(this.senderName())
        }
      });
      this.props.onInputBlurKeyup(
        this.email(), this.senderName()
      );
    }
}

export { SenderDefaultValues, ReaderNotificationConfigurationSenderFormView };
