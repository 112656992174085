import _ from 'underscore'
import BaseObject from 'libraries/baseObject'
import session from 'models/sessionInstance'

var UserAgentResultPresenter = BaseObject.extend({
  initialize: function (agentResult) {
    this.agentResult = agentResult;
  },
  needTranslation: function () {
    return this. _typeSupportsTranslation() &&
      this._langDetected() &&
      this._notInTheUserLanguage();
  },
  canDownloadResult: function () {
    return this._downloadableWithoutFeature() ||
      this._downloadableWithFeature();
  },
  googleTranslateUrl: function (){
    return "https://translate.google.com/translate?sl=auto&tl="+
      session.get("language")+"&u="+
      this.agentResult.get("primary_source").clip_url;
  },
  cuttingUrl: function (){
      return this.agentResult.cuttingUrl();
  },
  _typeSupportsTranslation: function () {
    return _.contains(
      ['TextClip', 'SMTTwitterClip', 'SMTWikipediaClip'],
      this.agentResult.get("kind")
    );
  },
  _notInTheUserLanguage: function() {
    return !_.contains(
      this.agentResult.get("languages"),
      session.get("language")
    );
  },
  _langDetected: function (){
    return !_.isEmpty(this.agentResult.get("languages"));
  },
  _downloadableWithFeature: function () {
    return this.agentResult.downloadable() &&
      session.hasClipDownloadFeature();
  },
  _downloadableWithoutFeature: function () {
    return this.agentResult.downloadable() &&
      this.agentResult.downloadableWithoutDownloadFeature();
  }
});

export default UserAgentResultPresenter;

