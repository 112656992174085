import * as Backbone from "backbone";
import Entity from "../models/entity";


const entityCache = new Backbone.Collection<Entity>();

export const get = (id: number): Entity => {
    return entityCache.get(id);
};
export const set = (entities: Entity[]) => {
    entityCache.set(entities, {remove: false})
};

export const all = (): Entity[] => {
    return entityCache.models
}