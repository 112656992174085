import Marionette from 'backbone.marionette'
import _ from 'underscore'
import $ from 'jquery'

var ModuleController = Marionette.Controller.extend({
  show: function () {
    this._addStyleguideBodyClass();
    this._enableGlobalSearchproviders();
    this.view.$el.show();
    this.onShow();
  },
  hide: function () {
    this._removeStyleguideBodyClass();
    this._disableGlobalSearchProviders();
    this.view.$el.hide();
    this.onHide();
  },
  _addStyleguideBodyClass: function () {
    $('body').addClass('styleguide-module');
  },
  _removeStyleguideBodyClass: function () {
    $('body').removeClass('styleguide-module');
  },
  _enableGlobalSearchproviders: function () {},
  _disableGlobalSearchProviders: function () {},
  onShow: function () {},
  onHide: function () {},
  _closeScene: function () {
    if (!_.isUndefined(this.sceneController)) {
      this.sceneController.close();
    }
  },
  _showScene: function (ASceneController, sceneOptions) {
    this._closeScene();
    var self = this;
    var options = {
      showInLeftColumn: function(view) {
        self._showInLeftColumn(view);
      },

      showInRightColumn: function(view) {
        self._showInRightColumn(view);
      }
    };
    if (_.isObject(sceneOptions)) {
      options = _.extend(sceneOptions, options);
    }

    this.sceneController = new ASceneController(options);
  },
  _showInLeftColumn: function(view) {
    this.view.showLeftContainer(view);
  },
  _showInRightColumn: function(view) {
    this.view.showRightContainer(view);
  }
});

export default ModuleController;
