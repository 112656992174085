import WidgetView from 'views/dashboard/widgetView'
import template from 'text-loader!templates/dashboard/widget/ratingWidget.html'
import AgentTagWidgetView from 'views/dashboard/agentTagWidgetView'

WidgetView.AgentRatingWidget = AgentTagWidgetView.extend({
  template: template
});

export default WidgetView.AgentRatingWidget;


