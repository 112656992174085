import Marionette from 'backbone.marionette';
import ReaderEditView from 'views/newsroom/readerEditView';
import readerConfigurationsInstance from 'collections/newsroom/configurationsInstance';
import I18n from 'i18n';
import ModalView from 'views/components/modalView';
import Notificator from 'libraries/notificator';

class ReaderEditController extends Marionette.Controller {
    initialize(options) {
        this.configuration = readerConfigurationsInstance.get(options.configurationId);
        this.configuration.fetch();
        this.view = new ReaderEditView({ model: this.configuration });

        this.listenTo(this.view, 'sendTokenModal', this._showSendTokenModal);
        this.listenTo(this.view, 'showPresentationOptions',
                      this.showPresentationOptions);
    }

    _showSendTokenModal() {
        this.modalView = new ModalView({
            confirmation: I18n.t('webapp.reader_admin.edit.send_token_modal.title'),
            warning: I18n.t('webapp.reader_admin.edit.send_token_modal.body'),
            confirmationLabel: I18n.t('webapp.reader_admin.edit.send_token_modal.confirmation'),
            cancelLabel: I18n.t('webapp.reader_admin.edit.send_token_modal.cancel')
        });
        this.listenTo(
            this.modalView,
            'modal:confirmed',
            this.sendToken
        );
        this.view.showRefreshTokenModal(this.modalView);
        this.modalView.showModal();
    }

    sendToken() {
        this.configuration.sendToken({
            success: function() {
                Notificator.showNotification(
                    I18n.t('webapp.notifications.reader.token_email_successful')
                );
            },

            error: function() {
                Notificator.showErrorNotification(
                    I18n.t('webapp.notifications.error.not_saved')
                );
            }
        });
    }
}

export default ReaderEditController;
