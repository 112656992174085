import Marionette from 'backbone.marionette'
import _ from 'underscore'
import NavigationView from 'views/navigationView'
import vent from 'libraries/vent'

var NavigationController = Marionette.Controller.extend({
  modules: [
    'dashboard',
    'agents',
    'newsroom',
    'reports',
    'settings'
  ],
  initialize: function () {
    this._createView();
    this._registerListeners();
  },
  _createView: function () {
    this.view = new NavigationView();
    this.listenTo(this.view, 'render', () => {
      _.each(this.modules, this.view.hideModule, this.view);
      _.each(this.modules, this.view.disableModule, this.view);
    });
    this.listenTo(vent, 'navigation:registered', this.view.showModule);
    this.listenTo(vent, 'navigation:deregistered', this.view.hideModule);
    this.listenTo(vent, 'navigation:loaded', this.view.enableModule);
    this.listenTo(vent, 'navigation:activated', this.view.activateModule);
    this.listenTo(vent, 'navigation:deactivated', this.view.deactivateModule);
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'module:clicked', function (module) {
      vent.commands.execute('navigation:activate', module);
    });
  }
});

export default NavigationController;
