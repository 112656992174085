import App from 'app'
import EventLogger from 'libraries/eventLogger'
import configureNavigationModule from 'modules/configureNavigationModule'
import setupNavigationModule from 'modules/setupNavigationModule'
import ResearchController from 'controllers/research/researchController'
import I18n from 'i18n'

App.module('Research', {
  startWithParent: false,
  define: function (Research) {
    EventLogger.logEventsOn(this, 'Research');
    configureNavigationModule(this, 'research');
    setupNavigationModule(this, ResearchController);

    // Set the title only when module is started
    // The locales might not be loaded before
    var self = this;
    Research.addInitializer(function () {
      self.name = I18n.t("webapp.research.title");
    });
  }
});

export default App.Research;

