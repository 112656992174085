import Marionette from 'backbone.marionette';
import PmgAgentSelectionView from 'views/pmgAgentSelectionView';
import _ from 'underscore';

class PmgAgentSelectionController extends Marionette.Controller {
    initialize(options) {
        const successCallback = (selections) => {
            let agentIds = _.map(selections, (selection) => {
                return selection.value;
            });

            agentIds = _.compact(agentIds);
            this.trigger('agentSelectionFinished', agentIds);
        };

        this.view = new PmgAgentSelectionView(options);
        this.listenTo(this.view, 'pmgZip:imported', successCallback);
    }
}

export default PmgAgentSelectionController;
