import UserSettingsView from 'views/settings/userSettingsView';
import template from 'text-loader!templates/settings/otherUserSettings.html';

class OtherUserSettingsView extends UserSettingsView {
    // all the user deletion functionality is in the base class, because js
    // doesn't have proper inheritance. but only this views template has the
    // delete button in it.
}
OtherUserSettingsView.prototype.template = template;

export default OtherUserSettingsView;
