import Marionette from 'backbone.marionette'
import CommentListCollectionView from 'views/agents/commentListView'
import _ from 'underscore'
import Shortcutter from 'libraries/shortcutter'
import usersInstance from 'collections/usersInstance'
import vent from "libraries/vent"

var AgentResultCommentController = Marionette.Controller.extend({
  initialize: function (agentResult) {
    this.agentResult = agentResult;
    this.view = new CommentListCollectionView({
      collection: this.agentResult.comments
    });
    this._bindShortcuts();
    this._registerListeners();
  },
  onClose: function () {
    this._unbindShortcuts();
  },
  _updateComment: function (comment, text) {
    comment.store();
    comment.save({text: text}, {
      error: function () {
        comment.restore();
      }
    });
  },
  _deleteComment: function (comment) {
    const self = this;
    var agentResult = this.agentResult;
    comment.destroy({
      success: function () {
        self._triggerCommentChange();
      },
      error: function () {
        agentResult.comments.add(comment);
        self._triggerCommentChange();
      }
    });
  },
  _createComment: function (text) {
    const self = this;
    var agentResult = this.agentResult;
    var comment = agentResult.comments.create({
      creator_id: usersInstance.currentUser.id,
      author: usersInstance.currentUser.get('full_name'),
      text: text
    }, {
      success: function () {
        self._triggerCommentChange();
      },
      error: function () {
        agentResult.comments.remove(comment);
        self._triggerCommentChange();
      }
    });
  },
  _triggerCommentChange: function () {
    vent.trigger(
      'changed:resultCommented',
      [this.agentResult.get('id')],
      this.agentResult.comments.toJSON({ uneditedJSON: true }),
    );
  },
  _registerListeners: function () {
    _.bindAll(this, '_createComment', '_deleteComment', '_updateComment');
    this.listenTo(this.view, 'create:comment', this._createComment);
    this.listenTo(this.view, 'delete:comment', this._deleteComment);
    this.listenTo(this.view, 'update:comment', this._updateComment);
  },
  _bindShortcuts: function ()  {
    var self = this;
    Shortcutter.bindInModule('agents', 'c', function () {
      self.view.focusOnCommentInput();
      return false;
    });
  },
  _unbindShortcuts: function () {
    Shortcutter.unbindInModule('agents', 'c');
  }
});

export default AgentResultCommentController;
