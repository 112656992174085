import ClipAccessLog from 'models/clipAccessLog'

var ClipLogger = {
  createFromAgentResult: function (agentResult) {
    var log = new ClipAccessLog({clip_id: agentResult.get("clip_id")});
    log.save();
  }
};

export default ClipLogger;
