import AgentsTopicFormController from 'controllers/agents/topicFormController'

var TopicFormController = AgentsTopicFormController.extend({
  initialize: function (options) {
    AgentsTopicFormController.prototype.initialize.apply(this, [options]);
  }
});

export default TopicFormController;

