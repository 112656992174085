import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/editReaderCategoryResultSelection.html';

class EditReaderCategoryResultSelectionView extends Marionette.ItemView {
    initialize() {
        this.listenTo(this.model, 'change', this.render);
    }

    onRender() {
        let category = this.model;

        if (category.get('id')) {
            this._activateResultSelection();
            this._enableButtons();
        } else {
            this._deactivateResultSelection();
            this._disableButtons();
        }
    }

    activateAllResultsSelection() {
        this.ui.allCategoryResults.addClass('is-active');
        this.ui.onlyImportantResults.removeClass('is-active');
    }

    activateOnlyImportantResultsSelection() {
        this.ui.onlyImportantResults.addClass('is-active');
        this.ui.allCategoryResults.removeClass('is-active');
    }

    _activateResultSelection() {
        if (this.model.get('only_important_results')) {
            this.activateOnlyImportantResultsSelection();
        } else {
            this.activateAllResultsSelection();
        }
    }

    _deactivateResultSelection() {
        this.ui.onlyImportantResults.removeClass('is-active');
        this.ui.allCategoryResults.removeClass('is-active');
    }

    _enableButtons() {
        this.ui.allCategoryResults.prop('disabled', false);
        this.ui.onlyImportantResults.prop('disabled', false);
    }

    _disableButtons() {
        this.ui.allCategoryResults.prop('disabled', true);
        this.ui.onlyImportantResults.prop('disabled', true);
    }
}

EditReaderCategoryResultSelectionView.prototype.template = template;
EditReaderCategoryResultSelectionView.prototype.ui = {
    allCategoryResults: '[data-all-category-results]',
    onlyImportantResults: '[data-only-important-category-results]'
};
EditReaderCategoryResultSelectionView.prototype.triggers = {
    'click @ui.allCategoryResults': 'select:all-category-results',
    'click @ui.onlyImportantResults': 'select:only-important-results'
};

export default EditReaderCategoryResultSelectionView;
