import * as Backbone from "backbone";
import I18n from "i18n";
import * as _ from "underscore";
import {
  IPrefixSearchResponse,
  IPrefixSearchResult,
  IPrefixSearchEntityType
} from "../../models/prefixSearchResponse";
import {
  Select2Configuration,
  Select2Option,
  Select2CallbackOptions,
  Select2Results
} from "../../components/researchFilter/select2Component";
import {Select2Id} from '../../components/researchFilter/select2Component'
import * as topicCache from "../../services/topicCache";
import Topic from "models/topic";
import EntityPrefixSearcher from '../../libraries/entityPrefixSearcher';

export interface IEntityProviderOptions {
  entityType: IPrefixSearchEntityType,
  dropdownLocale: string
}
export class TopicFilterProvider {
  seenTopics: Backbone.Collection<Topic>;

  constructor(private opts: IEntityProviderOptions) {
    this.opts = opts;
    this.seenTopics = new Backbone.Collection<Topic>();
  }

  summaryOperator(exclude: boolean) {
    if (exclude) {
      return I18n.t("webapp.agents.edit.filters.filter_operator.isnot");
    } else {
      return I18n.t("webapp.agents.edit.filters.filter_operator.is");
    }
  }

  operatorOptions() {
    return [
      {
        text: I18n.t("webapp.agents.edit.filters.filter_operator.is"),
        value: false
      },
      {
        text: I18n.t("webapp.agents.edit.filters.filter_operator.isnot"),
        value: true
      }
    ];
  }

  dropDownOption() {
    return {
      text: I18n.t(this.opts.dropdownLocale),
      value: "topic"
    };
  }

  select2Options(): Select2Option[] {
    return [];
  }

  select2CustomConfiguration(opts: any): Select2Configuration {
    return {
      fetchOptionsCallback: (options: Select2CallbackOptions) => {
        const entitiesSearcher = new EntityPrefixSearcher();
        return entitiesSearcher.search(options.term, "topic");
      },
      fetchOptionsToSelect2: (apiResults: IPrefixSearchResponse) => {
        let results = _.map(
          apiResults.collection,
          (response: IPrefixSearchResult) => {
            let topic
            let parentTopic;
            topic = new Topic(response.topic);
            parentTopic = new Topic(response.parent_topic);
            this.seenTopics.add(topic);
            const select2Results: Select2Results = {
              id: topic.id,
              text: topic.parseName()
            }
            if (parentTopic != undefined) {
              select2Results.parentTopicId = parentTopic.id
              select2Results.parentTopicText = parentTopic.parseName()
            }
            return select2Results
          });

        return _.sortBy(results, (opt) => opt["text"]);
      },
      knownFetchOptions: () => {
        let allTopics = this.seenTopics.models.concat(topicCache.all());
        if ((opts != undefined) &&
            (opts.topicNameCache != undefined)) {
          const topicNameCache = opts.topicNameCache
          allTopics =
            allTopics.concat(topicNameCache.models);
        }
        return _.map(
          allTopics,
          (topic: Topic) => {
            return {
              id: topic.id,
              text: topic.parseName()
            };
          });
      },
      minimumInputLength: 3
    };
  }

  allowArbitraryInput() {
    return false;
  }

  showSelection(topicNameCache: Backbone.Collection<Topic>,
                selection: Select2Id[]) {
    let or = ` ${I18n.t("webapp.agents.edit.filters.multiple_selection_or")} `;
    let topics = _.map(selection, (id: number) => {
      const topicName = topicNameCache && topicNameCache.get(id);
      const cachedTopic = topicCache.get(id);
      let model = this.seenTopics.get(id) || topicName || cachedTopic;
      return model.parseName();
    });
    if (topics.length > 2) {
      return topics.slice(0, -1).join(", ") + or + topics.slice(-1);
    } else {
      return topics.join(or);
    }
  }

  validate(selection: string[]) {
    let errors = [];
    if (!this.validateLength(selection)) {
      errors.push(I18n.t("webapp.agents.edit.filters.selection_empty_error"));
    }
    return errors;
  }

  isValid(selection: string[]) {
    return this.validate(selection).length === 0;
  }

  validateLength(selection: string[]) {
    return selection.length > 0;
  }
}

export default TopicFilterProvider;
