import * as React from "react";
import * as _ from "underscore";
import * as jQuery from "jquery";
import I18n from "i18n";
import { FilterInfo, FilterBuilderView } from
  "views/components/advancedFilterBuilderView";
import Modal from "components/newModal";
import AgentTopic from "models/agentTopic";
import EditableTitle from "views/components/editableReactTitleView";
import { IEsApiEntity as Entity, IEsApiTopic as Topic } from 'models/esResult'

export interface IAgentTopicManagementProps {
  entityNameCache?: Entity[]
  topicNameCache?: Topic[]
  onCancel: () => void;
  onFormSubmit: () => void;
  onSave: () => void;
  onDelete: () => void;
  onFiltersChange: (filters: FilterInfo[][], valid: boolean) => void;
  filters: FilterInfo[][];
  modalUnitCount: number | undefined;
  modalUnitPrice: number | undefined;
  openModal: boolean;
  disableButtons: boolean;
  model: AgentTopic;
  titleFieldName: string;
  title: string;
}

interface IAgentTopicManagementState {
  openModal: boolean;
  closeModal: boolean;
}

export class AgentTopicManagementView extends
  React.Component<IAgentTopicManagementProps, IAgentTopicManagementState> {
  cancelButton?: HTMLAnchorElement | null;
  saveButton?: HTMLAnchorElement | null;

  constructor(props: IAgentTopicManagementProps) {
    super(props);
    this.state = { openModal: false, closeModal: false };
  }

  render() {
    const deleteButton = I18n.t("webapp.agents.edit.topic.delete");
    const cancelButton = I18n.t("webapp.agents.edit.topic.cancel");
    const saveButton = I18n.t("webapp.agents.edit.topic.save");
    const filterTypes =
      [
        "fulltext",
        "language",
        "topic",
        "person_entity",
        "organization_entity",
        "location_entity",
        "business_entity",
        "law_entity"
      ]
    return (
      <div className="lightened-container lifted-container pvs phm">
        <div className="link-bar mbm">
          <a
            className="link-bar__right-item"
            onClick={() => this.onDelete()}>
            <i className="icon-trash" /> {deleteButton}
          </a>
        </div>
        <form>
          <div>
            <EditableTitle
              model={this.props.model}
              title={this.props.title}
              headerCssClasses={["mbm"]}
              titleFieldName={this.props.titleFieldName}
            />
          </div>
          <FilterBuilderView
            filters={this.props.filters}
            onFiltersChange={this.onFiltersChange.bind(this)}
            allowedTypes={filterTypes}
            readonly={false}
            entityNameCache={this.props.entityNameCache}
            topicNameCache={this.props.topicNameCache}
          />
          <div className="row ptm">
            <div className="col1of2 prm">
              <a
                ref={e => {
                  this.cancelButton = e;
                }}
                className="button button--block"
                onClick={() => this.onCancel()}>
                {cancelButton}
              </a>
            </div>
            <div className="col1of2">
              <a
                ref={e => {
                  this.saveButton = e;
                }}
                className="button--primary button button--block"
                onClick={() => this.onFormSubmit()}>
                {saveButton}
              </a>
            </div>
          </div>
        </form>
        {this.orderModal()}
      </div>
    );
  }

  componentDidMount() {
    if (this.props.openModal) {
      this.disableButtons();
      this.showModal();
    }
    if (this.props.disableButtons) {
      this.disableButtons();
    }
  }

  private showModal() {
    this.setState({openModal: true, closeModal: false});
  }

  private hideModal() {
    this.setState({openModal: false, closeModal: true});
  }

  private orderModal() {
    const modalBody = I18n.t(
      "webapp.general.modals.confirm_order.body", {
        unit_name: I18n.t("webapp.general.products.search_topics"),
        unit_count: this.props.modalUnitCount,
        unit_price: this.props.modalUnitPrice
      }
    );
    const props = {
      title: I18n.t(
        "webapp.general.modals.confirm_order.title", {
          unit_name: I18n.t("webapp.general.products.search_topics")
        }
      ),
      cancelLabel: I18n.t("webapp.general.modals.confirm_order.button_cancel"),
      confirmationLabel: I18n.t("webapp.general.modals.confirm_order.button_confirm"),
      onConfirm: this.onSave.bind(this),
      modalId: "order_search_topics",
      open: this.state.openModal,
      close: this.state.closeModal,
      onClose: this.onModalClose.bind(this),
      confirmationButtonBlue: true
    };
    return (
      <Modal {...props}>
        <span dangerouslySetInnerHTML={{ __html: modalBody}}></span>
      </Modal>
    );
  }

  private onDelete() {
    if (this.cancelButton && !jQuery(this.cancelButton).hasClass("is-disabled")) {
      this.props.onDelete();
    }
  }

  private onCancel() {
    if (this.cancelButton && !jQuery(this.cancelButton).hasClass("is-disabled")) {
      this.props.onCancel();
    }
  }

  private onFormSubmit() {
    if (this.cancelButton && !jQuery(this.cancelButton).hasClass("is-disabled")) {
      this.toggleButtons();
      this.props.onFormSubmit();
    }
  }

  private onSave() {
    this.hideModal();
    this.props.onSave();
  }

  private onModalClose() {
    this.toggleButtons();
  }

  private toggleButtons() {
    if (this.cancelButton) { jQuery(this.cancelButton).toggleClass("is-disabled"); }
    if (this.saveButton) { jQuery(this.saveButton).toggleClass("is-disabled"); }
  }

  private disableButtons() {
    if (this.cancelButton) { jQuery(this.cancelButton).addClass("is-disabled"); }
    if (this.saveButton) { jQuery(this.saveButton).addClass("is-disabled"); }
  }

  private onFiltersChange(filters: FilterInfo[][], valid: boolean) {
    this.props.onFiltersChange(filters, valid);
  }
}
