import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/comment.html'

var CommentView = Marionette.ItemView.extend({
  template: template,
  className: function () {
    if (this.editing) {
      return "comment active";
    } else {
      return "comment";
    }
  },
  ui: {
    commentInput: '[data-comment-text]'
  },
  id: function () {
    return "comment-" + this.model.get('id');
  },
  events: {
    'keypress @ui.commentInput': '_handleCommentInput',
    'blur @ui.commentInput': '_updateComment'
  },
  triggers: {
    'click [data-delete-comment]': 'delete:comment',
    'click [data-update-comment]': 'update:comment',
    'click [data-edit-comment]':   'edit:comment'
  },
  initialize: function () {
    this.listenTo(this.model, 'change:text', this.render);
    this.listenTo(this.model, 'change:text', this._releaseLock);
  },
  serializeData: function () {
    var comment = this.model;

    return {
      id: comment.get('id'),
      text: comment.get('text'),
      createdAt: comment.get('created_at'),
      authorName: comment.get('author'),
      editing: this.editing,
      isOwnComment: comment.ownedByCurrentUser()
    };
  },
  enterEditMode: function () {
    this.editing = true;
    this.render();
    this.ui.commentInput.select();
  },
  leaveEditMode: function () {
    delete this.editing;
    this.render();
  },
  _handleCommentInput: function (event) {
    if (event.which === 13) {
      event.preventDefault();
      this._updateComment();
    }
  },
  _updateComment: function () {
    if (!this._isLocked()) {
      var value = this.$(this.ui.commentInput).val();
      if (value && value !== this.model.get('text')) {
        this._lock();
        this.trigger('update:comment');
      }
    }
    // Stop propagation in case the view is locked
    return false;
  },
  _isLocked: function () {
    return this.updateLock === true;
  },
  _lock: function () {
    this.updateLock = true;
  },
  _releaseLock: function () {
    this.updateLock = false;
  }
});

export default CommentView;

