import _ from 'underscore';
import Agents from 'collections/agents';
import users from 'collections/usersInstance';
import pusher from 'models/pusherWrapper';

let agents = new Agents();

// Automatically update agents when we get an update vent via Pusher
function handlePusherAgentUpdate(data) {
    if (!_.isUndefined(agents.get(data['agent_id']))) {
        agents.get(data['agent_id']).fetch();
    }
}

function handlePusherAgentCreated() {
    agents.fetch();
}

function handlePusherAgentDeleted(data) {
    if (!_.isUndefined(agents.get(data['agent_id']))) {
        agents.remove(agents.get(data['agent_id']));
    }
}

agents.listenTo(pusher,
                'agent_updated',
                handlePusherAgentUpdate)
agents.listenTo(pusher,
                'agent_created',
                handlePusherAgentCreated)
agents.listenTo(pusher,
                'agent_deleted',
                handlePusherAgentDeleted)

// Add agents to their user’s agents collection when they
// get added here. This is not thoroughly tested and the
// collections might get out of sync, as I do not fully
// understand how Backbone handles models in multiple
// collections, yet.
agents.on('add', function (agent) {
    "use strict";
    let user = users.get(agent.get('user_id'));
    if (!_.isUndefined(user)) {
        user.agents.add(agent);
    }
});

// When an agent gets removed via Pusher event, it isn’t removed from
// the user’s agents collection automatically, so to be safe we do it
// here. In the case that an agent is removed in the current session,
// everything already works as expected.
agents.on('remove', function (agent) {
    "use strict";
    let user = users.get(agent.get('user_id'));
    if (!_.isUndefined(user)) {
        user.agents.remove(agent);
    }
})

export default agents;
