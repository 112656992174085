import * as Backbone from "backbone";
import I18n from "i18n";
import * as _ from "underscore";
import Entity from "models/entity";
import {
  IPrefixSearchResponse,
  IPrefixSearchResult,
  IPrefixSearchEntityType
} from "models/prefixSearchResponse";
import {
  Select2Configuration,
  Select2Option,
  Select2CallbackOptions,
  Select2Keyword,
  Select2Results
} from "components/researchFilter/select2Component";
import {Select2Id} from 'components/researchFilter/select2Component'
import EntityPrefixSearcher from 'libraries/entityPrefixSearcher'
import * as entityCache from "../../services/entityCache";
export interface IEntityProviderOptions {
  entityType: IPrefixSearchEntityType,
  dropdownLocale: string
}
export class EntityFilterProvider {
  seenEntities: Backbone.Collection<Entity>;

  constructor(private opts: IEntityProviderOptions) {
    this.opts = opts;
    this.seenEntities = new Backbone.Collection<Entity>();
  }

  summaryOperator(exclude: boolean) {
    if (exclude) {
      return I18n.t("webapp.agents.edit.filters.filter_operator.isnot");
    } else {
      return I18n.t("webapp.agents.edit.filters.filter_operator.is");
    }
  }

  operatorOptions() {
    return [
      {
        text: I18n.t("webapp.agents.edit.filters.filter_operator.is"),
        value: false
      },
      {
        text: I18n.t("webapp.agents.edit.filters.filter_operator.isnot"),
        value: true
      }
    ];
  }

  dropDownOption() {
    return {
      text: I18n.t(this.opts.dropdownLocale),
      value: this.opts.entityType
    };
  }

  select2Options(): Select2Option[] {
    return [];
  }

  select2CustomConfiguration(opts: any): Select2Configuration {
    return {
      fetchOptionsCallback: (options: Select2CallbackOptions) => {
        const entitiesSearcher = new EntityPrefixSearcher();
        return entitiesSearcher.search(options.term, this.opts.entityType);
      },
      fetchOptionsToSelect2: (apiResults: IPrefixSearchResponse) => {
        let results = _.map(
          apiResults.collection,
          (response: IPrefixSearchResult) => {
            let entity = new Entity(response.entity);
            this.seenEntities.add(entity);
            const select2Results: Select2Results = {
              id: entity.id,
              text: entity.parseName()
            }
            return select2Results
          });

        return _.sortBy(results, (opt) => opt["text"]);
      },
      knownFetchOptions: () => {
        let allEntities = this.seenEntities.models.concat(entityCache.all());
        if ((opts != undefined) &&
            (opts.entityNameCache != undefined)) {
          const entityNameCache = opts.entityNameCache
          allEntities = allEntities.concat(entityNameCache.models);
        }
        return _.map(
          allEntities,
          (entity: Entity) => {
            return {
              id: entity.id,
              text: entity.parseName()
            };
          });
      },
      minimumInputLength: 3
    };
  }

  allowArbitraryInput() {
    return false;
  }

  showSelection(entityNameCache: Backbone.Collection<Entity>,
                selection: Select2Id[]) {
    let or = ` ${I18n.t("webapp.agents.edit.filters.multiple_selection_or")} `;
    let entities = _.map(selection, (id: number) => {
      const entityName = entityNameCache && entityNameCache.get(id);
      const cachedEntity = entityCache.get(id);
      let model = this.seenEntities.get(id) || entityName || cachedEntity;
      return model.parseName();
    });
    if (entities.length > 2) {
      return entities.slice(0, -1).join(", ") + or + entities.slice(-1);
    } else {
      return entities.join(or);
    }
  }

  validate(selection: string[]) {
    let errors = [];
    if (!this.validateLength(selection)) {
      errors.push(I18n.t("webapp.agents.edit.filters.selection_empty_error"));
    }
    return errors;
  }

  isValid(selection: string[]) {
    return this.validate(selection).length === 0;
  }

  validateLength(selection: string[]) {
    return selection.length > 0;
  }
}

export default EntityFilterProvider;
