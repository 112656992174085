import { BASE_PATH } from "../DashboardAppConstants";

const LOAD: "app/dashboards/load" = "app/dashboards/load";
const LOAD_FULFILLED: "app/dashboards/load_FULFILLED" =
  "app/dashboards/load_FULFILLED";
  const LOAD_PENDING: "app/dashboards/load_PENDING" =
  "app/dashboards/load_PENDING";
  const LOAD_ERROR: "app/dashboards/load_ERROR" =
  "app/dashboards/load_ERROR";
export interface DashboardStore {
  isNetworkRequestOngoing: boolean;
  value: Dashboard[]
}

export interface Dashboard {
  id: number;
  name: string;
  shared: boolean;
  subscribedUserIds: number[];
}

interface DashboardResponse {
  id: number;
  name: string;
  shared: boolean;
  subscribed_user_ids: number[];
}

const initialState: DashboardStore = {
  isNetworkRequestOngoing: true,
  value: []
}

type Action = LoadAction | { type: typeof LOAD_FULFILLED; payload: Dashboard[] }
  | { type: typeof LOAD_PENDING } | { type: typeof LOAD_ERROR };

//Reducer
export default function dashboards(
  state = initialState,
  action: Action
): DashboardStore {
  switch (action.type) {
    case LOAD_FULFILLED:
      return {isNetworkRequestOngoing: false, value: action.payload};
    case LOAD_PENDING: 
      return {...state, isNetworkRequestOngoing: true};
    case LOAD_ERROR:
      return {...state, isNetworkRequestOngoing: false}
    default:
      return state;
  }
}

//Action Creators
export interface LoadAction {
  type: typeof LOAD;
  payload: Promise<Dashboard[]>;
}
export function getDashboard(): LoadAction {
  return {
    type: LOAD,
    payload: fetch(`${BASE_PATH}/dashboards/`, {
      credentials: 'same-origin'
    })
      .then(res => res.json())
      .then(json => json.collection)
      .then(c =>
        c.map(({ id, name, shared, subscribed_user_ids }: DashboardResponse) => ({
          id,
          name,
          shared,
          subscribedUserIds: subscribed_user_ids
        }))
      )
  };
}
