import Backbone from 'backbone';
import ReaderResult from 'models/newsroom/readerResult';

class ReaderResultsSelectionCollection extends Backbone.Collection {
    /* For multiselection in newsroom timeline to work, it needs this
     * exact sorting logic.
     *
     * ArchiveDate with highest id must be first in the collection.
     * Within archive date the highest position comes first and 0 is the last.
     */
    comparator(m1, m2) {
        let ad1 = m1.get('archiveDateId');
        let ad2 = m2.get('archiveDateId');
        let p1 = m1.get('position');
        let p2 = m2.get('position');

        if (ad1 < ad2) {
            return 1;
        } else if (ad1 > ad2) {
            return -1;
        } else {
            if (p1 < p2) {
                return 1;
            } else if (p1 > p2) {
                return -1;
            } else {
                return 0;
            }
        }
    }
}

ReaderResultsSelectionCollection.prototype.model = ReaderResult;

export default ReaderResultsSelectionCollection;
