import Marionette from 'backbone.marionette'
import * as _ from 'underscore'
import History from 'libraries/history'
import Logger from 'libraries/logger'
import vent from 'libraries/vent'
import EventLogger from 'libraries/eventLogger'
import ReactWrapper from 'views/marionetteReactWrapper'

export interface SandboxContent {
  setup(refresh: () => void): void
  component?: any
  controller?: any
  view?: any
  promise?: any
  postRender?: () => void
}

interface ImportedSandboxContent {
  default: SandboxContent
}

declare function require(moduleNames: string[], onLoad: (...args: any[]) => void): void;

var SandboxController = Marionette.Controller.extend({
  initialize: function () {
    this.historyReady = History.waitForMe();
    this._createRouter();
  },
  ready: function () {
    this.historyReady.ready();
  },
  showSandbox: function (name: string) {
    Logger.level = Logger.LEVELS.DEBUG;

    if (!_.isString(name)) {
      name = 'default';
    }
    Logger.debug('Showing Sandbox "' + name + '"');
    this.setup(name);
  },
  _createRouter: function () {
    var SandboxRouter = Marionette.AppRouter.extend({
      controller: this,
      appRoutes: {
        "sandbox(/:named_sandbox)": "showSandbox"
      }
    });

    this.router = new SandboxRouter();
  },
  setup: function (name: string) {
    var controller = this;
    require(['controllers/sandboxes/' + name], function (c: ImportedSandboxContent) {
      let content = c.default
      let handleViewUpdate = () => {};
      content.setup(() => handleViewUpdate());
      controller.content = content;
      var show = function () {
        vent.trigger('sandbox:show', name);
        vent.commands.execute('navigation:deactivateAll');
        if (_.isObject(content.view)) {
          EventLogger.logEventsOn(content.view, 'Sandbox view');
          vent.commands.execute('setContent', content.view);

          if (content.postRender) {
            content.postRender();
          }
        } else if (_.isObject(content.component)) {
          let view = new ReactWrapper({getComponent: () => content.component})
          handleViewUpdate = () => view.render()
          vent.commands.execute('setContent', view)
        }

        if (_.isObject(content.controller)) {
          EventLogger.logEventsOn(content.controller, 'Sandbox controller');
        }
      };
      if (content.promise) {
        content.promise.done(show);
      } else {
        show();
      }
    });
  }
});

export default SandboxController;
