import I18n from 'i18n'
import * as _ from 'underscore'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

interface IEditableDetailTitleViewProps {
  originalTitle: string
  title: string
  displayError: boolean
  onEdit: (inputValue: string) => void
  onEnter: () => void
  titleEditIcon?: boolean
}

export default class EditableDetailTitleView extends
    React.Component<IEditableDetailTitleViewProps, {}> {

  render() {
    const error = I18n.t('webapp.notifications.error.missing_information')
    let validationClasses = ["form-item__feedback"]
    let titleContainerClasses = ["form-item", "mbs"]
    if (!this.props.displayError) {
      validationClasses.push('hidden')
    } else {
      titleContainerClasses.push('is-erroneous')
    }

    return (
      <div data-title-container className={titleContainerClasses.join(' ')}>
        {this.titleForm()}
        <div data-title-validation-container
             className={validationClasses.join(' ')}>
          <i className="icon-spam"></i> {error}
        </div>
      </div>
    )
  }

  getTitle(): string {
    return this.props.title
  }

  private titleForm() {
    return this.richTextForm();
  }

  private defaultForm() {
    const label = I18n.t('webapp.agents.results.edit_result.title')
    const placeholder = I18n.t('webapp.agents.results.edit_result.title')
    const onTitleEdit = (e: any) => {
      const inputValue = $(e.target).val()
      if (inputValue) {
        this.props.onEdit(inputValue.toString())
      }
    }

    return (
      <span data-old-title-form-item>
        <form>
          <label htmlFor="title" className="form-item__label">
            {label}
          </label>
          <div className="form-item__input">
            <input
              data-agent-result-title
              type="text"
              onKeyDown={this.disableEnter.bind(this)}
              onKeyUp={this.handleAbort.bind(this)}
              onBlur={onTitleEdit}
              placeholder={placeholder}
              className="text-input text-input--block"
              defaultValue={this.props.title}
            />
          </div>
        </form>
      </span>
    );
  }

  private richTextForm() {
    const placeholder = I18n.t("webapp.agents.add_result.title")
    const onTitleEdit = (e: any) => {
      const inputValue = $(e.target).text()
      this.props.onEdit(inputValue)
    }
    const attrs = { placeholder: placeholder };

    return (
      <span data-new-title-form-item>
        <div className="wysiwyg-clip-heading">
          <h1 data-editable-title
              onKeyDown={this.disableEnter.bind(this)}
              onKeyUp={this.handleAbort.bind(this)}
              onBlur={onTitleEdit}
              className="wysiwyg-clip-heading__text"
              contentEditable={true}
              suppressContentEditableWarning
              {...attrs}>
            {this.props.title}
          </h1>
          {this.props.titleEditIcon &&
            <span className="wysiwyg-clip-heading__action">
              <i className="wysiwyg-clip-heading__action-icon"></i>
            </span>
          }
        </div>
      </span>
    )
  }

  private disableEnter(e: any) {
    switch (e.keyCode) {
      case 13:
        this.props.onEnter()
        e.preventDefault()
        break
      default:
        return
    }
  }

  private handleAbort(e: any) {
    switch (e.keyCode) {
      case 27:
        $('[data-editable-title]').text(this.props.originalTitle)
        break
      default:
        return
    }
  }
}
