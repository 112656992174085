/* jshint module: true */
import App from 'app';
import EventLogger from 'libraries/eventLogger';
import configureNavigationModule from 'modules/configureNavigationModule';
import setupNavigationModule from 'modules/setupNavigationModule';
import NewsroomController
    from 'controllers/newsroom/controller';
import I18n from 'i18n';
import contactGroupsInstancePromise
    from 'models/contactGroupsInstancePromise';
import contactsInstancePromise from 'models/contactsInstancePromise';

export default App.module('Newsroom', {
    startWithParent: false,
    define: (Newsroom) => {
        EventLogger.logEventsOn(Newsroom, 'Newsroom');
        configureNavigationModule(Newsroom, 'newsroom');
        setupNavigationModule(Newsroom, NewsroomController);

        Newsroom.addInitializer(() => {
            Newsroom.name = I18n.t('webapp.reader_admin.title');
            contactGroupsInstancePromise.fetch();
            contactsInstancePromise.fetch();
        });

    }
});
