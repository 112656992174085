import _ from 'underscore'
import Marionette from 'backbone.marionette'
import agentResultMarkImportant from 'text-loader!templates/agents/agentResultMarkImportant.html'

var AgentResultMarkImportant = Marionette.ItemView.extend({
  template: agentResultMarkImportant,
  ui: {
    button: '[data-mark-important]'
  },
  triggers: {
    'click [data-mark-important]': 'clicked.markImportant'
  },
  initialize: function () {
    _.bindAll(this, 'markImportant');
  },
  markImportant: function () {
    this.$(this.ui.button).addClass('is-active');
  },
  unMark: function () {
    this.$(this.ui.button).removeClass('is-active');
  }
});

export default AgentResultMarkImportant;

