/* eslint no-invalid-this: "off" */
import App from 'app'
import vent from 'libraries/vent'
import MousetrapCheese from 'libraries/mousetrapCheese'

App.module('Shortcuts', {
  startWithParent: false,
  define: function () {
    var mousetrapWithCheese = new MousetrapCheese();

    function bindInModule(namespace, keys, callback) {
      mousetrapWithCheese.bind(namespace, keys, callback);
    }

    function unbindInModule(namespace, keys) {
      mousetrapWithCheese.unbind(namespace, keys);
    }

    function updateNamespace(namespace) {
      mousetrapWithCheese.updateCurrentNamespace(namespace);
    }

    this.addInitializer(function () {
      this.listenTo(
        vent, 'navigation:activated',
        mousetrapWithCheese.updateCurrentNamespace
      );
      this.listenTo(vent, 'shortcuts:bindInModule', bindInModule);
      this.listenTo(vent, 'shortcuts:unbindInModule', unbindInModule);
      this.listenTo(vent, 'shortcuts:updateNamespace', updateNamespace);
      vent.trigger('shortcuts:ready');
    });

    this.addFinalizer(function () {
      this.stopListening(vent, 'shortcuts:bindInModule');
      this.stopListening(vent, 'shortcuts:unbindInModule');
      this.stopListening(vent, 'shortcuts:updateNamespace');
    });

    this.listBoundKeys = function () {
      return mousetrapWithCheese.bindings();
    };
  }
});

export default App.Shortcuts;

