import * as React from "react";
import I18n from "i18n";

interface Props {
  onCategorySelect: (configurationId: number, categoryId: number) => void;
  models: Model[];
}
interface State {
  isDropdownOpen: boolean;
  searchQuery: string;
}
interface Model {
  get(s: string): string;
  id: number;
  categories: {
    models: Category[];
  };
}

interface Category {
  get(s: string): string;
  name: string;
  id: number;
}

export default class ArticleToReaderDropdown extends React.PureComponent<
  Props,
  State
> {
  node: React.RefObject<HTMLFormElement> = React.createRef();

  componentDidUpdate(_: Props, prevState: State) {
    if (
      this.state.isDropdownOpen !== prevState.isDropdownOpen &&
      this.state.isDropdownOpen === false
    ) {
      this.setState({ searchQuery: "" });
    }
  }

  componentWillMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  state = {
    isDropdownOpen: false,
    searchQuery: ""
  };

  handleClick = () =>
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));

  handleSelect = (configurationId: number, categoryId: number) => {
    this.setState({ isDropdownOpen: false });
    this.props.onCategorySelect(configurationId, categoryId);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: event.target.value
    });
  };

  outsideClick = () => {
    this.setState({ isDropdownOpen: false });
  };

  handleOutsideClick = (event: MouseEvent) => {
    if (
      this.node.current &&
      event.target instanceof Element &&
      this.node.current.contains(event.target)
    ) {
      return;
    }
    this.outsideClick();
  };

  render() {
    let list: Model[] = this.getItems();

    return (
      <form
        className="dropdown dropdown-search link-bar__left-item"
        ref={this.node}>
        <a
          className="dropdown__toggle dropdown-search__toggle"
          data-toggle="dropdown"
          onClick={this.handleClick}>
          {I18n.t(
            "webapp.result_lists.result_details.add_to_reader.add_result"
          )}
          <i className="icon-triangle-down" />
        </a>
        {this.state.isDropdownOpen && (
          <ul className="dropdown__menu dropdown-search__menu" role="menu">
            <div className="dropdown-search__padding">
              <input
                type="text"
                placeholder="Search..."
                className="text-input text-input--block dropdown-search__input dropdown-search__input--focus"
                value={this.state.searchQuery}
                onChange={this.handleChange}
                autoFocus={true}
              />
              {list.length === 0 ? (
                <li className="dropdown__item" role="menuitem">
                  <span className="dropdown__item--disabled">
                    {I18n.t(
                      "webapp.result_lists.result_details.add_to_reader.no_match_found"
                    )}
                  </span>
                </li>
              ) : (
                list.map(i => (
                  <div key={i.id.toString()}>
                    <li className="dropdown__item" role="menuitem">
                      <span className="dropdown__title">
                        {i.get("title")}
                      </span>
                    </li>
                    <ul className="dropdown__submenu">
                      {i.categories.models.map(c => (
                        <li
                          key={i.id.toString() + "-" + c.id.toString()}
                          className="dropdown__item"
                          onClick={() => {
                            this.handleSelect(
                              parseInt(i.get("id")),
                              parseInt(c.get("id"))
                            );
                          }}>
                          <a className="dropdown__link">{c.get("name")}</a>
                        </li>
                      ))}
                    </ul>
                    <li
                      className="dropdown__separator"
                      role="presentation"
                    />
                  </div>
                ))
              )}
            </div>
          </ul>
        )}
      </form>
    );
  }

  getItems() {
    const queries = this.state.searchQuery.split(" ").filter(s => s);
    let list: Model[] = [];

    if (this.state.searchQuery.length > 0) {
      for (let m of this.props.models) {
        const lcModels = m.categories.models.map(c =>
          c.get("name").toLowerCase()
        );

        if (
          queries.every(
            q =>
              m
                .get("title")
                .toLowerCase()
                .indexOf(q.toLowerCase()) !== -1 ||
              lcModels.filter(lcm => lcm.indexOf(q.toLowerCase()) !== -1)
                .length > 0
          )
        ) {
          list.push(m);
        }
      }
    } else {
      list = this.props.models;
    }
    return list;
  }
}
