import Marionette from "backbone.marionette";
import EditAgentTopicsView from "views/agents/settings/editAgentTopicsView";
import TopicListView from "views/agents/topicListView";
import Topic from "models/agentTopic";

class EditAgentTopicsController extends Marionette.Controller {
  initialize() {
    this.agent = this.options.agent;
    this.view = new EditAgentTopicsView({model: this.agent});
    this.topics = this.agent.get("topics");

    this.listenTo(this.view, "render", this.showTopicList);
    this.listenTo(this.topics, "add", () => {
      this.trigger("topics:changed");
    });
    this.listenTo(this.topics, "remove", () => {
      this.trigger("topics:changed");
    });
  }

  removeAddButton() {
    this.topicListView.removeAddTopicButton();
  }

  addAddButton() {
    this.topicListView.addAddTopicButton();
  }

  private showTopicList() {
    this.topicListView = new TopicListView({
      collection: this.topics
    });

    this.view.showTopicList(this.topicListView);

    this.listenTo(this.topicListView,
                  "clicked:topic",
                  this.triggerTopicClicked);

    this.listenTo(this.topicListView,
                  "add:topic",
                  this.triggerTopicAdded);
  }

  private triggerTopicClicked(topic: Topic) {
    this.trigger("clicked:topic", topic);
  }

  private triggerTopicAdded(topic: Topic) {
    this.trigger("add:topic", topic);
  }

  deselectAll() {
    this.topicListView.deselectAll();
  }
}

export default EditAgentTopicsController;
