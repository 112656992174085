import Backbone from 'backbone'

var SocialMediaMonitor = Backbone.Model.extend({
  toJSON: function () {
    var fullJson = Backbone.Model.prototype.toJSON.call(this);

    return {
      monitor: {
        author_name: fullJson.name
      },
      agent_id: fullJson.agent_id,
      id:   fullJson.id,
      type: fullJson.type
    };
  },
  parse: function (response) {
    if (response.object) {
      return response.object;
    } else {
      return response;
    }
  }
});

export default SocialMediaMonitor;

