import _ from 'underscore'
import Backbone from 'backbone'
import contacts from 'collections/contactsInstance'
import contactGroups from 'collections/contactGroupsInstance'

var NewsletterRecipientSelection = Backbone.Model.extend({
  identifier: 'newsletterRecipientSelection',
  initialize: function () {
    this.on('change:selection', this.updateOptions);
    this.on('change:selection', this.updateReport);
    this.on('change:selection', this.checkForCompleteness);

    this.initializeSelectionFromReport();
    this.updateOptions();
  },
  initializeSelectionFromReport: function () {
    var recipients = this.get('report').get('recipients');
    if (recipients) {
      var selection = _.map(recipients, function (recipient) {
        return recipient.type + '-' + recipient.get('id');
      });
      this.set({selection: selection});
    }
  },
  checkForCompleteness: function () {
    var complete = !_.isEmpty(this.get('selection'));
    this.set({complete: complete});
  },
  updateReport: function () {
    var selection = this.get('selection');
    var report = this.get('report');
    var recipients = this.selectionToRecipients(selection);
    report.set({recipients: recipients});
  },
  selectionToRecipients: function (selection) {
    var classMap = {
      contact: contacts,
      contact_group: contactGroups
    };
    return _.map(selection, function (s) {
      var type, id;
      type = s.split('-')[0];
      id = parseInt(s.split('-')[1], 10);
      var recipient = classMap[type].get(id);
      return recipient;
    });
  },
  updateOptions: function () {
    this.set({options: this.availableOptions()});
  },
  availableOptions:  function () {
    var options = [];
    options.push(this.contactGroupOptGroup());
    options.push(this.contactOptGroup());
    return options;
  },
  contactGroupOptGroup: function () {
    return {
      label: "Empfängergruppen",
      options: this.markSelectedOptions(this.contactGroups())
    };
  },
  contactOptGroup: function () {
    return {
      label: 'Einzelpersonen',
      options: this.markSelectedOptions(this.contacts())
    };
  },
  contactGroups: function () {
    if (_.isUndefined(this.cachedContactGroupOptions)) {
      this.cachedContactGroupOptions = contactGroups.map(function (contactGroup) {
        return {
          id: 'contact_group-' + contactGroup.get('id'),
          name: contactGroup.toString()
        };
      });
    }
    return this.cachedContactGroupOptions;
  },
  contacts: function () {
    if (_.isUndefined(this.cachedContactOptions)) {
      this.cachedContactOptions = contacts.map(function (contact) {
        return {
          id: 'contact-' + contact.get('id'),
          name: contact.toString()
        };
      });
    }
    return this.cachedContactOptions;
  },
  markSelectedOptions: function (array) {
    return _.map(array, (option) => {
      var selected = _.include(this.get('selection'), option.id);
      return {
        id: option.id,
        name: option.name,
        selected: selected
      };
    }, this);
  },
  // The data method is used for clip count approximation in the
  // wizard. This step does not affect the number of clips, so
  // just returning an empty object.
  data: function () {
    return {};
  }
});


export default NewsletterRecipientSelection;

