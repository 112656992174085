import Backbone from 'backbone'
import moment from 'moment'
import TimelineDay from 'models/timeline/timelineDay'
import TimelineDays from 'collections/timeline/timelineDays'

var timelineMonth = Backbone.Model.extend({
  initialize: function (date) {
    this.set('date', date);
    this.days = new TimelineDays();
  },
  fillDays: function () {
    var date = this.get('date');
    var year = date.getFullYear();
    var month = date.getMonth();
    var now = new Date();
    for (var day = date.getDate(); day <= 31; day++) {
      var dayStart = new Date(year, month, day);
      if (dayStart.getMonth() !== month || dayStart > now) { break; }
      this.days.add(new TimelineDay(dayStart));
    }
  },
  isCurrent: function () {
    var today = new Date();
    var date = this.get('date');
    return today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth();
  },
  includes: function (day) {
    var d = moment(day.get('date'));
    var monthStart = moment(this.get('date'));
    var monthEnd = moment(monthStart).add(1, 'month');
    return (d.isSame(monthStart) || d.isAfter(monthStart)) && d.isBefore(monthEnd);
  },
  isBefore: function (day) {
    var d = moment(this.get('date'));
    return d.isBefore(day.get('date'), 'month');
  },
  isAfter: function (day) {
    var d = moment(this.get('date'));
    return d.isAfter(day.get('date'), 'month');
  }
});

export default timelineMonth;

