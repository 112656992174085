import SceneController from 'controllers/sceneController';
import OrganizationsDetailController
from 'controllers/settings/organizations/organizationsDetailController';
import OrganizationsPlaceholderController from 'controllers/settings/organizations/organizationsPlaceholderController';
import * as _ from 'underscore'
import OverviewController from 'controllers/settings/entityOverviewController';

class OrganizationsSceneController extends SceneController {
  show() {
    this.controller = new OverviewController({
      headerLocaleKey: 'webapp.settings.organizations.header',
      entityType: 'organization_entity',
      prefixSearchErrorLocaleKey: 'webapp.settings.organizations.searchErrorNotification',
      knowledgebaseErrorLocaleKey: 'webapp.settings.organizations.organizationLoadErrorNotification'
    });
    this.showInLeftColumn(this.controller.view);
    this._showPlaceholder();
    this._registerListeners();
  }

  _showPlaceholder() {
    this._closeDetailView();
    this._showDetailController (OrganizationsPlaceholderController, {});
  }

  _showDetailController(Controller, param) {
    this.detailController = new Controller(param);
    this.detailView = this.detailController.view;
    this.showInRightColumn(this.detailView);
  }

  _closeDetailView() {
    if (!_.isUndefined(this.detailView)) {
      this.detailView.close();
      delete this.detailView;
      this.detailController.close();
      delete this.detailController;
      this.focusRight();
    }
  }

  _registerListeners() {
    this.listenTo(this.controller, 'entity:clicked', this._showOrganizationDetails);
    this.listenTo(this.controller, 'entity:closed', this._closeDetailView);
  }

  _showOrganizationDetails(data) {
    this._showDetailController(
      OrganizationsDetailController,
      { organization: data }
    );

  }
}

export default OrganizationsSceneController;
