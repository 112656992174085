import ModalView from 'views/components/modalView';
import I18n from 'i18n';
import agents from '../../collections/agentsInstance';

export default class DeleteUserModalView extends ModalView {
    initialize(options) {
        this.user = options.user;
        this.new_search_agent_management = options.new_search_agent_management;
    }

    serializeData() {
        return {
            headline: this._modalHeadline(),
            confirmation: this._modalTitle(),
            warning: this._modalBody(),
            confirmationLabel: this._confirmationLabel(),
            showHeader: true,
            cancelLabel: this._cancelLabel(),
            confirmationButtonStyle: 'button--negative',
            showButtons: true
        };
    }

    _modalTitle() {
        return this._modalHeadline();
    }

    _modalHeadline() {
        return I18n.t(
            'webapp.settings.users.user_details.delete.confirmation', { username: this._userName() }
        );
    }

    _userName() {
        return this.user.get('login');
    }

    _modalBody() {
        if (this.new_search_agent_management) {
            return I18n.t(
              'webapp.settings.users.user_details.delete.warning_new_agent_mgmt').concat(this._modalUserAgents());
        } else {
            return I18n.t(
                'webapp.settings.users.user_details.delete.warning').concat(this._modalUserAgents());
        }
    }

    _modalUserAgents() {
        let agentNamesList = [];
        const agentNameWarning = I18n.t(
            'webapp.settings.users.user_details.delete.agent_name_list')
        agents.forEach(agent => {
            if (agent.get("user_id") === this.user.id) {
                agentNamesList.push(`<br/>-${agent.get("name")}`);
            }
        })
        if (agentNamesList.length > 0) {     
            return `${agentNameWarning}${agentNamesList.join("")}`;
        } else {
            return "";
        }
    }

    _confirmationLabel() {
        return I18n.t(
            'webapp.settings.users.user_details.delete.delete'
        );
    }

    _cancelLabel() {
        return I18n.t(
            'webapp.settings.users.user_details.delete.cancel'
        );
    }
}
