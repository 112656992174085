import * as React from "react";

interface SidebarActionsProps {
  label: string
  children?: any
  onClick?: () => void
}

const SidebarActions = (props: SidebarActionsProps) => {
  const attrs: any = {}
  if (props.onClick) {
    attrs['onClick'] = props.onClick
  }
  return (
    <li className="layout-sidebar-actions nav-tree__actions" data-sidebar-actions>
      <div className="dropup dropup--block">
        <a className="nav-tree__action dropdown__toggle" data-toggle="dropdown" {...attrs}>
          <i className="icon-plus"></i> {props.label}
        </a>
        <ul className="dropdown__menu dropdown__menu--block" role="menu">
          {props.children}
        </ul>
      </div>
    </li>
  )
}

export default SidebarActions