import _ from 'underscore'
import Marionette from 'backbone.marionette'
import EditAgentSocialMediaMonitorView from 'views/agents/settings/editAgentSocialMediaMonitorView'
import SocialMediaMonitorListView from 'views/agents/socialMediaMonitorListView'

var EditAgentSocialMediaMonitorController = Marionette.Controller.extend({
  initialize: function (options) {
    this.agent = options.agent;
    this.view = new EditAgentSocialMediaMonitorView(this.agent);
    this.socialMediaMonitors = this.agent.get('social_media_monitors');

    this.listenTo(this.view, 'render', this._showSocialMediaMonitorList);
    this.listenTo(this.socialMediaMonitors, 'add', () => {
      this.trigger("social_media_monitors:changed");
    });
    this.listenTo(this.socialMediaMonitors, 'remove', () => {
      this.trigger("social_media_monitors:changed");
    });
  },
  removeAddButton: function () {
    this.smmListView.removeAddSocialMediaMonitorButton();
  },
  addAddButton: function () {
    this.smmListView.addAddSocialMediaMonitorButton();
  },
  _showSocialMediaMonitorList: function () {
    this.smmListView = new SocialMediaMonitorListView({
      collection: this.socialMediaMonitors
    });
    this.view.showSocialMediaMonitorList(this.smmListView);

    this.listenTo(this.smmListView,
                  'clicked:social_media_monitor',
                  this._retriggerSmmClicked);

    this.listenTo(this.smmListView,
                  'add:smm',
                  this._triggerSmmAdded);
  },
  _retriggerSmmClicked: function (smm) {
    this.trigger('clicked:social_media_monitor', smm);
  },
  _triggerSmmAdded: function (smm) {
    this.trigger('add:smm', smm);
  },
  deselectAll: function () {
    if (!_.isUndefined(this.smmListView)) {
      this.smmListView.deselectAll();
    }
  }
});

export default EditAgentSocialMediaMonitorController;

