import Backbone from 'backbone';
import ReaderNotificationTriggers
    from 'collections/newsroom/readerNotificationTriggers';
import ReaderNotificationSchedules
    from 'collections/newsroom/readerNotificationSchedules';
import _ from 'underscore';
import weekdaySort from 'libraries/weekdaySort';

class NotificationConfiguration extends Backbone.Model {
    initialize() {
        this.triggers = new ReaderNotificationTriggers(
            this.get('notification_triggers'),
            { notificationConfiguration: this }
        );
        _.each(this.get('notification_schedules'), (schedule) => {
            schedule.weekdays = schedule.weekdays.sort(weekdaySort);
        });
        this.schedules = new ReaderNotificationSchedules(
            this.get('notification_schedules'),
            { notificationConfiguration: this }
        );
    }
}

NotificationConfiguration.prototype.urlRoot = 'api/v3/notification_configurations';

export default NotificationConfiguration;
