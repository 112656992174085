import * as React from "react";
import HighchartsReact from "./highchartsReact";
import I18n from "i18n";
import DateFormatter, { AnyDate } from "../../../libraries/dateFormatter";
import NoDataAvailableError from "./NoDataAvailableError";
import vent from "libraries/vent";
import { DataSeries } from "../redux/widgets";

interface Props {
  data: LineChartResponseItem[][];
  dataSeries: DataSeries[];
  className?: string;
  header: string;
}

const chart = {
    height: '330'
  }

enum DateType {
  Day = "day",
  Hour = "hour",
  Week = "week",
  Month = "month"
}
export interface LineChartResponseItem {
  series: string;
  count: number[][];
  interval: DateType;
}

interface LineChartItem {
  name: string;
  data: number[][];
  interval: DateType;
}

function filter(ids: number[], type: string) {
  return ids.map(id => ({ type, id }));
}

function drillDown(dataSeries: DataSeries[], event: any) {
  const idx = event.point.series.index;
  if (dataSeries[idx].agent_ids.length > 0 && dataSeries[idx].tag_ids.length > 0) {
    throw new Error("data series contains both tags and agents");
  } else if (dataSeries[idx].agent_ids.length > 0) {
    vent.commands.execute('agents:showFilteredTimeline', filter(dataSeries[idx].agent_ids, "agent"), new Date(event.point.options.x));
  } else if (dataSeries[idx].tag_ids.length > 0) {
    vent.commands.execute('agents:showFilteredTimeline', filter(dataSeries[idx].tag_ids, "tag"), new Date(event.point.options.x));
  }
}

function getLineChartData(data: LineChartResponseItem[][]): LineChartItem[] {
  return data
    .reduce((i, val) => i.concat(val), [])
    .map(({ series, count, interval }) => ({ name: series, data: count, interval }));
}

function intervalType(interval: DateType): (date: AnyDate) => string {
  switch (interval) {
    case DateType.Day:
      return DateFormatter.formatDate
    case DateType.Hour:
      return DateFormatter.formatTime
    case DateType.Week:
      return DateFormatter.formatWeekNumber
    case DateType.Month:
      return DateFormatter.formatMonth
  }
}

export default class LineChart extends React.PureComponent<Props> {

  render() {
    const { className,
      data,
      header,
      dataSeries,
      ...itemStyle
    } = this.props;

    const options = {
      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: drillDown.bind(null, dataSeries)
            }
          },
        },
        line: {
          softThreshold: false
        }
      },
      allowPointSelect: true,
      exporting: {
        buttons: {
          contextButton: {
            symbol:
              "url(../styleguide/assets/images/icons/icon-download.png)",
            symbolX: 19,
            symbolY: 19,
            menuItems: ["downloadPNG", "downloadSVG"]
          }
        }
      }
    };


    const series = getLineChartData(data);

    const title = {
      text: `${header}`,
      align: "center",
      style: {
        fontWeight: "300",
        color: "#999"
      }
    };
    const dateFormatter = intervalType(series[0].interval)

    const xAxis = {

      labels: {
        formatter: function () {
          return dateFormatter((this as any).value);
        }
      },
      type: 'datetime',
    }

    const tooltip = {
      formatter: function (): string {
        return (this as any).points.reduce(function (s: string, point: any): string {
          return (s + "<br/>" + point.series.name + ": " + I18n.toNumber(point.y, { precision: 0 }));
        },
          "<b>" + dateFormatter((this as any).x) + "</b>");
      }, shared: true
    };

    const yAxis = {
      allowDecimals: false,
      title: {
        text: `${I18n.t("webapp.new_dashboard.line_chart.articles")}`
      },
      minRange: 10,
      min: 0,
    };

    return (
      <div
        className={`dashboard-widget__container`}
        {...itemStyle}>
        {this.props.data.length > 0 ?
          <HighchartsReact options={{ ...options, chart, series, title, yAxis, xAxis, tooltip }} /> : <NoDataAvailableError />}
      </div>
    );
  }
}
