import RestorableModel from 'libraries/restorableModel';
import _ from 'underscore';
import $ from 'jquery';
import when from 'when';

class Contact extends RestorableModel {
    getName() {
        let name = this.get('name');
        let email = this.get('email');

        return _.isEmpty(name) ? email : name;
    }

    updateIncurCost(params) {
        return when($.ajax({
            type: 'POST',
            url: `/api/v3/contacts/${this.get('id')}/update_incur_cost`,
            data: { contact: params }
        }));
    }

    isUserContact() {
        return this.get('is_user_contact');
    }

    toString() {
        let name = this.get('name');
        let email = this.get('email');

        if (name) {
            return `${name} <${email}>`;
        } else {
            return email;
        }
    }
    parse(response) {
        return response.object || response;
    }
    toJSON() {
        return {
            "contact": {
                "name": this.get('name'),
                "email": this.get('email'),
                "user_group_id": this.get('user_group_id'),
                "contact_group_ids": this.get('contact_group_ids'),
                "reader_language": this.get('reader_language')
            }
        };
    }
}

Contact.prototype.urlRoot = '/api/v3/contacts/';
Contact.prototype.type = 'contact';
Contact.prototype.defaults = {
    'is_user_contact': false
};

export default Contact;
