import * as React from "react";
import {
  IEsApiResultContent as EsResult,
  IEsApiSource
} from "models/esResult";
import I18n from "i18n";
import * as _ from "underscore";
import session from "models/sessionInstance";
import SearchableDropdown from "components/SearchableDropdown";
import AddToReaderClient from "components/addToReaderClient";
import ShowEmailModal from "./SendEmailModal";

export interface Props {
  results: EsResult[];
}

export class MultiClipDetailView extends React.PureComponent<Props, {}> {
  render() {
    const last = _.last(this.props.results);
    const result =
      last["attributes"]["clip"] ? last["attributes"]["clip"] : last["attributes"];

    let text;
    if (result.meta &&
        result.meta.rich_text &&
        result.meta.rich_text.length > 0) {
      text = result.meta.rich_text;
    } else if (result.full_text) {
      text = result.full_text;
    } else if (result.meta && result.meta.text) {
      text = result.meta.text;
    } else if (result.teaser) {
      text = result.teaser;
    }
    return (
      <div className="stacked-container ptm phl pbm">
        {this.renderAddToReaderDropdown()}
        {this.resultCounts()}
        <hr className="separator mvl" />
        <h2 className="heading-large mbs">{ I18n.t("webapp.agents.results.multiple_results.latest_selected_result") }</h2>
        <h3 className="heading-xlarge mbs">{ result.title }</h3>
        <div className="clip-detail__body">
          <p dangerouslySetInnerHTML={{__html: text}} className="readmore"></p>
        </div>
      </div>
    );
  }

  articleViaEmailModal() {
    return (
        <ShowEmailModal emailAgentResultIds={[]} emailClipIds={this.props.results.map(i => i.id)} />
    );
  }

  private resultCounts() {
    const title =
      I18n.t(
        "webapp.agents.results.multiple_results.selected_results",
        this.resultCount()
      )
    const subTitle =
      I18n.t(
        "webapp.agents.results.multiple_results.from_sources",
        this.uniqueSourcesCount()
      )
    return (
      <div className="clip-detail">
        <div className="clip-detail__head">
          <h1 className="clip-detail__title">{title}</h1>
          <p className="clip-detail__subtitle"> {subTitle}</p>
        </div>
      </div>
    )
  }

  private resultCount() {
    return { count: this.props.results.length };
  }

  private uniqueSourcesCount() {
    var count =
      _.chain(this.props.results)
       .map((result: EsResult) => result.get("sources"))
       .flatten()
       .map((source: IEsApiSource) => source.meta.name)
       .unique()
       .value()
       .length

    return { count: count };
  }

  private renderAddToReaderDropdown() {
    if (session.hasFeature("use_new_reader_management")) {
      const props = {
        onCategorySelect: (confId: number, catId: number) => {
          new AddToReaderClient(confId, catId).fromClips(
            _.map((this.props.results), (result) => { return result.id; })
          );
        }
      };

      return (
        <div className="link-bar mbl">
          <SearchableDropdown {...props} />
          {session.shareSelectedArticlesViaEmail() && this.articleViaEmailModal()}
        </div>
      );
    }
  }
}

export default MultiClipDetailView;
