import ArchiveDates from 'collections/archiveDates';
import moment from 'moment';
import _ from 'underscore';

class ArchiveDateApiCollection extends ArchiveDates {
    initialize(collection, options) {
        this.options = options;
        this.readerConfigurationId = options.readerConfigurationId;
    }

    url() {
        return '/api/v3/reader_configurations/' +
                this.readerConfigurationId +
                '/archive_dates?include_unpublished=1';
    }

    parse(response) {
        return response;
    }

    latestArchiveDateId() {
        if (this.size() === 0) {
            return null;
        }

        const timestamp = moment().valueOf();
        let lst = this.filter(function(archiveDate) {
            let date = archiveDate.get('delivery_time') * 1000;
            return date < timestamp;
        });

        if (lst.length === 0) {
            return null;
        }

        let sorted = _.sortBy(lst, function(archiveDate) {
            return -archiveDate.get('delivery_time');
        });

        return sorted[0].get('id');
    }

}

export default ArchiveDateApiCollection;
