import Marionette from "backbone.marionette";
import template from "text-loader!templates/agents/metadata/sopranoAdminChConsultationView.html";
import I18n from "i18n";
import localizedLanguages from "libraries/localizedLanguages";

class SopranoAdminChConsultationView extends Marionette.ItemView {
  initialize() {
    this.agentResult = this.options.agentResult;
  }

  serializeData() {
    const languageCodes = this.agentResult.get('languages');
    const clipMetadata = this.agentResult.get('clip_metadata');
    let status = "";
    switch(clipMetadata.status) {
      case 'Im Rat noch nicht behandelt':
        status = I18n.t("webapp.agents.results.result_details.metadata.curia_state_not_yet_handled_in_council");
        break;
      case 'draft':
        status = I18n.t("webapp.agents.results.result_details.metadata.verbalix_state_draft");
        break;
      case 'preview':
        status = I18n.t("webapp.agents.results.result_details.metadata.consultation_state_planned");
        break;
      case 'in_progress':
        status = I18n.t("webapp.agents.results.result_details.metadata.consultation_state_in_progress");
        break;
      default:
        status = I18n.t("webapp.agents.results.result_details.metadata.consultation_state_closed");
    }
    const lastUpdate = clipMetadata.last_update;

    return {
      languages: localizedLanguages(languageCodes),
      status: status,
      lastUpdate: lastUpdate,
    };
  }
}

SopranoAdminChConsultationView.prototype.template = template;

export default SopranoAdminChConsultationView;
