import Marionette from "backbone.marionette";
import ModuleController from "controllers/moduleController";
import _ from "underscore";
import vent from "libraries/vent";
import ApogeeLayout from "views/apogeeLayout";
import ResearchOptionsProvider from "models/globalSearch/researchOptionsProvider";
import SearchSettingsController from "controllers/research/searchSettingsController";
import ResearchTimelineController from "controllers/researchTimelineController";
import buildTopic from "controllers/research/researchController/buildTopic";
import Deferred from "libraries/deferred";
import ResearchLandingView from "views/research/researchLandingView";
import session from 'models/sessionInstance';

class ResearchController extends ModuleController {
  initialize() {
    const self = this;
    Deferred.async(() => {
      self._setupViews();
    });
  }

  _setupViews() {
    this.view = new ApogeeLayout();
    this.view.render();

    this._setupSidebar();
    this._showLanding();
    this._registerGlobalSearchProvider();
    this._registerListeners();
    this.trigger('loaded');
  }

  onShow() {
    vent.trigger('layout:focus-left');
  }

  _showLanding() {
    const view = new ResearchLandingView();
    this.view.showLeftContainer(view);
  }

  _setupSidebar() {
    const Sidebar = Marionette.ItemView.extend({template: '<div class="contrasted-container"></div>'});
    this.view.showSidebar(new Sidebar());
  }

  _registerListeners() {
    _.bindAll(this, 'handleQuery');
    vent.commands.setHandler('research:query', this.handleQuery);
    this.listenTo(vent, 'layout:focus-left', this._handleDetailClosed);
  }

  handleQuery(query) {
    if (this.settingsController) {
      this.settingsController.close();
    }
    const _this = this;
    Deferred.async(() => {
      vent.commands.execute('navigation:activate', 'research');
      _this._createSettings(query.replace(/:/, ''));
      _this.handleSearch(_this.settingsController.searchOptions());
    });
  }

  handleSearch(options) {
    if (!_.isUndefined(this.timelineController)) {
      this.timelineController.close();
      this._showLanding();
    }
    this._createTimeline(options);
  }

  handleClosedClip() {
    if (!_.isUndefined(this.timelineController)) {
      this.timelineController.selection.set();
    }
  }

  _createSettings(query) {
    const topic = buildTopic(query);
    this.settingsController = new SearchSettingsController({
      topic: topic
    });
    this.view.showRightContainer(this.settingsController.view);

    this.listenTo(this.settingsController, 'clicked:search', this.handleSearch);
    this.listenTo(this.settingsController, 'clip:closed', this.handleClosedClip);
  }

  onHide() {
    if (!_.isUndefined(this.settingsController)) {
        this.settingsController.close();
        this.timelineController.close();
        this.close();
    }
  }

  _createTimeline(options) {
    this.timelineController = new ResearchTimelineController(options);
    this.view.showLeftContainer(this.timelineController.view);
    this.listenTo(this.timelineController.selection, 'add', this._handleSelectionChange);
    this.listenTo(this.timelineController.selection, 'remove', this._handleSelectionChange);
  }

  _handleSelectionChange() {
    this.settingsController.onlyCloseClip()
    var selection = this.timelineController.selection;
    if (!selection.isEmpty()) {
      if ((selection.length > 1) &&
          session.hasFeature('access_to_new_research')) {
        this.settingsController.showClips(selection.models);
      } else {
        var result = selection.first();
        this.settingsController.showClip(result);
      }
    } else {
      this.settingsController.closeClip();
    }
    vent.trigger('layout:focus-right');
  }

  _handleDetailClosed() {
    if (!_.isUndefined(this.timelineController)) {
      this.timelineController.clearSelection();
    }
  }

  _registerGlobalSearchProvider() {
    const provider = new ResearchOptionsProvider('research');
    vent.commands.execute('globalSearch:registerOptionsProvider', provider, true);
  }
}

export default ResearchController;
