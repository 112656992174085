import Marionette from 'backbone.marionette';
import ReaderEmailSettingsView from 'views/newsroom/readerEmailSettingsView';
import RecipientSelectionModel
    from 'models/components/recipientSelection';
import RecipientSelectionView
    from 'views/components/recipientSelectionView';
import _ from 'underscore';
import I18n from 'i18n';
import ModalView from 'views/components/modalView';
import Notificator from 'libraries/notificator';

class ReaderEmailSettingsController extends Marionette.Controller {
    initialize(options) {
        this.configuration = options.configuration;
        this.view = new ReaderEmailSettingsView();
        this._registerListeners();
        _.bindAll(this, '_handleRecipientSelectionUpdate');
    }

    _registerListeners() {
        this.listenTo(this.view,
                      'clicked:sendEmailAutomatically',
                      this._handleSendEmailButtonClick);
        this.listenTo(this.view,
                      'change:sender',
                      this._handleSenderChange);
        this.listenTo(this.view,
                      'render',
                      this._onViewRender);
        this.listenTo(this.view,
                      'refreshToken',
                      this._showRefreshTokenModal);
    }

    _onViewRender() {
        let value = this.configuration.get('send_changed_token');
        this.view.setSendEmailButton(value);

        this._showRecipientsInput();

        this._initSender();
    }

    _initSender() {
        this.view.setSenderName(this.configuration.get('sender_name'));
        this.view.setSenderEmail(this.configuration.get('sender_email'));
    }

    _handleSenderChange() {
        let data = this.view.data();
        let attrs = {
            sender_name: data.senderName,
            sender_email: data.senderEmail
        };
        this.configuration.set(attrs);
        this.configuration.save(attrs, this._saveParams());
    }

    _showRecipientsInput() {
        let recipients = this.configuration.get('recipients');
        let options = { updateCallback: this._handleRecipientSelectionUpdate };
        this.recipientSelectionModel =
            new RecipientSelectionModel({ recipients: recipients }, options);
        this.recipientSelectionView =
            new RecipientSelectionView({ model: this.recipientSelectionModel });
        this.view.showRecipientSelection(this.recipientSelectionView);
    }

    _handleRecipientSelectionUpdate(recipients) {
        let attrs = { recipients: recipients };
        this.configuration.set(attrs);
        this.configuration.save(attrs, this._saveParams());
    }

    _handleSendEmailButtonClick() {
        this.view.toggleSendEmailAutomatically();
        let sendTokenEmails = this.view.data().sendEmailAutomatically;
        let attrs = { send_changed_token: sendTokenEmails };
        this.configuration.set(attrs);
        this.configuration.save(attrs, this._saveParams());
    }

    _saveParams() {
        return {
            error: function() {
                Notificator.showErrorNotification(
                    I18n.t('webapp.notifications.error.not_saved')
                );
            }
        };
    }

    _showRefreshTokenModal() {
        this.modalView = new ModalView({
            title: I18n.t('webapp.reader_admin.edit.update_token_modal.title'),
            confirmation: I18n.t('webapp.reader_admin.edit.update_token_modal.title'),
            warning: this._refreshTokenModalWarningText(),
            confirmationLabel: I18n.t('webapp.reader_admin.edit.update_token_modal.confirmation'),
            cancelLabel: I18n.t('webapp.reader_admin.edit.update_token_modal.cancel')
        });
        this.listenTo(
            this.modalView,
            'modal:confirmed',
            this._updateToken
        );
        this.view.showRefreshTokenModal(this.modalView);
        this.modalView.showModal();
    }

    _refreshTokenModalWarningText() {
        let warning1 =
            I18n.t('webapp.reader_admin.edit.update_token_modal.body_with_email');
        let warning2 =
            I18n.t('webapp.reader_admin.edit.update_token_modal.body_without_email');

        if (this.configuration.get('send_changed_token')) {
            return warning1;
        } else {
            return warning2;
        }
    }

    _updateToken() {
        let sendChangedToken = this.configuration.get('send_changed_token');
        let _this = this;

        this.configuration.updateToken({
            success: function(response) {
                let message;

                _this.configuration.set(
                    'newest_reader_link', response.token_url
                );

                if (sendChangedToken) {
                    message = I18n.t('webapp.notifications.reader.token_update_and_email_successful');
                } else {
                    message = I18n.t('webapp.notifications.reader.token_update_successful');
                }

                Notificator.showNotification(message);
            },

            error: function() {
                Notificator.showErrorNotification(
                    I18n.t('webapp.notifications.error.not_saved')
                );
            }
        });
    }
}

export default ReaderEmailSettingsController;

