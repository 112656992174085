import Backbone from 'backbone'

var SourceGroup = Backbone.Model.extend({
  parse: function (response) {
    return response;
  }
});

export default SourceGroup;

