import Marionette from 'backbone.marionette'
import _ from 'underscore'
import vent from 'libraries/vent'
import deepMerge from 'libraries/deepMerge'
import TrashBinController from 'controllers/agents/trashBinController'
import ResultListLandingPageView from 'views/agents/resultListLandingPageView'

var TrashBinLayoutController = Marionette.Controller.extend({
  initialize: function (options) {
    this.showLeftHandContainer  = options.showLeftHandContainer;
    this.showRightHandContainer = options.showRightHandContainer;
    this.filter                 = options.filter;
    this._createAndShowController();
  },
  onClose: function () {
    if (!_.isUndefined(this.controller)) {
      this.controller.close();
    }
  },
  _createAndShowController: function () {
    this.controller = new TrashBinController({
      filter: this._cloneFilter()
    });
    this._showLandingPage();
    this.showLeftHandContainer(this.controller.view);
    this._registerListeners();
  },
  _showLandingPage: function () {
    var view = new ResultListLandingPageView();
    this.showRightHandContainer(view);
    vent.trigger('layout:focus-left');
  },
  _registerListeners: function () {
    this.listenTo(this.controller,
                  'agentResult:deselected',
                  this._showLandingPage);
    this.listenTo(this.controller,
                  'agentResult:selected',
                  this._showSelectedResult);
    this.listenTo(this.controller,
                  'agentResult:restored',
                  this._showLandingPage);
  },
  _showSelectedResult: function (resultView) {
    this.showRightHandContainer(resultView);
    vent.trigger('layout:focus-right');
  },
  _cloneFilter: function () {
    return deepMerge({}, this.filter);
  },
  handleRightColumnClosed: function () {
    this.controller.deselectAll();
  }
});

export default TrashBinLayoutController;

