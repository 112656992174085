/* jshint module: true */
import App from 'app';
import EventLogger from 'libraries/eventLogger';
import configureNavigationModule from 'modules/configureNavigationModule';
import setupNavigationModule from 'modules/setupNavigationModule';
import SettingsController from 'controllers/settings/settingsController';
import I18n from 'i18n';

export default App.module('Settings', {
    startWithParent: false,
    define: (Settings) => {
        EventLogger.logEventsOn(Settings, 'Settings');
        configureNavigationModule(Settings, 'settings');
        setupNavigationModule(Settings, SettingsController);

        Settings.addInitializer(function () {
            Settings.name = I18n.t('webapp.settings.title');
        });
    }
});
