import Marionette from "backbone.marionette";
import AgentResultTagController from "controllers/agents/agentResultTagController";
import Shortcutter from "libraries/shortcutter";
import TaggingManager from "models/taggingManager";
import AgentResult from "models/agentResult";
import vent from "libraries/vent";

class ReaderResultTagController extends AgentResultTagController {
  initialize(agentResults: Backbone.Collection<AgentResult>) {
    super.initialize(agentResults);
  }

  private taggingCallback() {
    this.agentResults.each((agentResult: AgentResult) => {
      vent.trigger("updatedByNewsroom", agentResult.get("id"));
    });
  }

  private bindShortcuts()  {
    Shortcutter.bindInModule("newsroom", "t", () => {
      this.view.tagsInputFocusOpen();
      return false;
    });
  }

  private unbindShortcuts() {
    Shortcutter.unbindInModule("newsroom", "t");
  }
}

export default ReaderResultTagController;
