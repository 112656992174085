import AgentTagWidgetView from 'views/dashboard/agentTagWidgetView'
import LineChart from 'views/dashboard/widgetView/helpers/lineChart'
import $ from 'jquery'

var ChartWidget = AgentTagWidgetView.extend({
  prepareWidgetBody: function () {
    var container = $(this.el).find('div.chart')[0];
    var backgroundColor = this.chartBackgroundColor($(container).css('background-color'));

    this.chart = new LineChart(container, {
      chart: {
        backgroundColor: backgroundColor,
        width: 246,
        height: 140
      }
    });

    this.chart.addSeries({
      color: this.getColor(),
      data: this.getData()
    });

    this.chart.render();
  },
  getColor: function () { return this.model.get('color'); },
  getData: function () {
    return this._convertTimestamps(this.model.get('chart_data'));
  },
  truncatedLabel: function () {
    return this._truncateLabel(this.model.get('name'));
  },
  prepareSerializedData: function () {
    var values = {
      truncatedLabel: this.truncatedLabel(),
      clip_count: this.model.clipCount(),
      color: this.getColor()
    };
    return values;
  }
});

export default ChartWidget;

