import * as React from "react";
import * as ReactDOM from "react-dom";
import * as jQuery from "jquery";
import * as _ from "underscore";
import I18n from "i18n";
import Select2Component from "components/select2Component";

export interface ISourceSearchProps {
  queryCallback: (query: any) => void;
  selectionCallback: (i: number) => void;
  multiselect: boolean;
  minimumInputLength?: number | undefined;
  select2Id: string;
  preselectedItems?: Array<any>;
}

export default class SourceSearch extends React.Component<ISourceSearchProps, {}> {
  render() {
    let select2Props = {
      placeholder: I18n.t("webapp.agents.edit.source_categories.source_name"),
      formatSearching: () => {
        return I18n.t("webapp.agents.edit.source_categories.searching");
      },
      formatNoMatches: () => {
        return I18n.t("webapp.agents.edit.source_categories.no_matches");
      },
      formatInputTooShort: (input: string, min: number) => {
        return I18n.t("webapp.agents.edit.source_categories.more_characters");
      },
      query: this.props.queryCallback,
      selectionCallback: this.props.selectionCallback,
      multiple: this.props.multiselect,
      select2Id: this.props.select2Id,
      sortResults: this.sortResults
    };

    if (this.props.preselectedItems) {
      select2Props = _.extend(
        {},
        select2Props,
        { preselectedItems: this.props.preselectedItems }
      );
    }
    if (this.props.minimumInputLength) {
      select2Props = _.extend(
        {},
        select2Props,
        { minimumInputLength: this.props.minimumInputLength }
      );
    }

    return (
      <div>
        <div>
          <Select2Component {...select2Props} />
        </div>
        <div className="input-group__item-actions">
          <span className="input-group__item-action input-group__item-action--add"></span>
        </div>
      </div>
    );
  }

  sortResults(
    data: {text: string}[],
    container: any,
    query: {term: string}
  ) {
    return data.sort(function (a, b) {
      const ta = a.text;
      const tb = b.text;
      const isAEqual = ta == query.term
      const isBEqual = tb == query.term
      if (isBEqual) return 1;
      if (isAEqual) return -1;
      if (ta > tb) {
        return 1;
      } else if (ta < tb) {
        return -1;
      }
      return 0;
    });
  }
}
