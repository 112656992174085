import Marionette from 'backbone.marionette';
import template
    from 'text-loader!templates/newsroom/editReaderCategoryTitle.html';

class EditReaderCategoryTitleView extends Marionette.ItemView {
    initialize() {
        this.listenTo(this.model, 'change:id', this.render);
    }

    onRender() {
        let category = this.model;

        if (category.get('id')) {
            this.ui.titleInput.prop('disabled', false);
        } else {
            this.ui.titleInput.prop('disabled', true);
        }
    }

    _handleTitleKeyUpEvent(e) {
        switch (e.keyCode) {
        case 13: // Enter key pressed
            this._maybeTriggerNewTitleAssignment();
            break;
        default:
            return; // continue
        }
    }

    _handleTitleBlurEvent() {
        this._maybeTriggerNewTitleAssignment();
    }

    _maybeTriggerNewTitleAssignment() {
        let newTitle = this.ui.titleInput.val();

        if (newTitle !== this.model.get('name')) {
            this.trigger('title:updated', newTitle);
        }
    }
}

EditReaderCategoryTitleView.prototype.template = template;
EditReaderCategoryTitleView.prototype.ui = {
    titleInput: '[data-category-title]'
};
EditReaderCategoryTitleView.prototype.events = {
    'blur @ui.titleInput': '_handleTitleBlurEvent',
    'keyup @ui.titleInput': '_handleTitleKeyUpEvent'
};

export default EditReaderCategoryTitleView;
