import Config from 'libraries/configuration';
import Backbone from 'backbone';

class WhitelistDomain extends Backbone.Model {
    urlRoot() {
        const groupId = this.get('contact_group_id');
        return `${Config.apiUrl}contact_groups/${groupId}/whitelist_domains`;
    }

    parse(response) {
        if (response.object) {
            return response.object;
        } else {
            return response;
        }
    }

    toJSON() {
        return {
            whitelist_domain: {
                id: this.get('id'),
                name: this.get('name'),
            },
            contact_group_id: this.get('contact_group_id')
        };
    }
}

export default WhitelistDomain;
