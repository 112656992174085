import Marionette from 'backbone.marionette';
import _ from 'underscore';
import DomainListItemView from
    'views/settings/contacts/contactGroupDomainListItemView';
import template from
    'text-loader!templates/settings/contacts/contactGroupDomainListCollection.html';

class ContactGroupDomainListCollectionView extends Marionette.CompositeView {
    appendHtml(collectionView, itemView, index) {
        var child = collectionView.$el.children()[index];
        if (_.isUndefined(child)) {
            collectionView.$el.append(itemView.el);
        } else {
            collectionView.$(child).before(itemView.el);
        }
    }

    _handleKeyboardInput(e) {
        const enter = 13;
        const domainName = this.ui.name.val().trim();
        if (e.keyCode === enter) { this._handleDomainInput(); }
        if (domainName.length > 0 && domainName.charAt(0) !== '@') {
            this.ui.name.val(`@${this.ui.name.val()}`);
        }
    }

    _handleDomainInput() {
        const domainName = this.ui.name.val().trim();
        this.ui.name.val(domainName);
        const validLookingDomain = new RegExp(/^@[\w-]+\.\w+/);
        const emptyInput = domainName.length < 1;
        if (!emptyInput) {
            // @a.de
            if (domainName.length > 5 && validLookingDomain.test(domainName)) {
                this.setDomainName('');
                this.trigger('domainNameAdded', domainName);
                this.ui.name.focus();
            } else {
                this.trigger('domainError', domainName);
            }
        } else {
            this.trigger('hideAllDomainErrors');
        }
    }

    setDomainName(domainName) {
        this.ui.name.val(domainName);
    }

    _handleDomainAddClick() {
        if (this.ui.name.val().trim().length < 1) {
            this.ui.name.focus();
        } else {
            this._handleDomainInput();
        }
    }
}

ContactGroupDomainListCollectionView.prototype.template = template;
ContactGroupDomainListCollectionView.prototype.tagName = "ul";
ContactGroupDomainListCollectionView.prototype.className = "input-group input-group--compact";
ContactGroupDomainListCollectionView.prototype.itemView = DomainListItemView;
ContactGroupDomainListCollectionView.prototype.events = {
    'blur [data-domain-name]': '_handleDomainInput',
    'click [data-domain-add]': '_handleDomainAddClick',
    'keyup [data-domain-name]': '_handleKeyboardInput'
};
ContactGroupDomainListCollectionView.prototype.ui = {
    name: '[data-domain-name]'
};
export default ContactGroupDomainListCollectionView;
