import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/agents/settings/editAgentTopics.html'
import handleTooltips from 'models/handleTooltips'


class EditAgentTopicsView extends Marionette.Layout {
  initialize() {
    handleTooltips(this);
  }

  showTopicList(view) {
    this.topicList.show(view);
  }
}
EditAgentTopicsView.prototype.template = template;
EditAgentTopicsView.prototype.regions = {
  topicList: '[data-topic-list]'
};

export default EditAgentTopicsView;
