import moment from "moment";
import DateFormatter from "../../../libraries/dateFormatter";

// SelectedDateRange is a "Tuple" 
// Index [0] holds the selected no. of date
// Index [1] holds the translated displayed string of the dates
// Index [2] holds the start timestamp
// Index [3] holds the end timestamp 

export type SelectedDateRange = [number, string, number, number];

export enum dateRangeIdx {
  dateRangeName = 1,
  startTimeStamp = 2,
  endTimeStamp = 3,
};


// Actions
const UPDATE: "app/dateRange/UPDATE" = "app/dateRange/UPDATE";
const now = moment();

export const dateRanges: SelectedDateRange[] = [
  [
    0,
    "webapp.new_dashboard.date_range.options.today",
    DateFormatter.formatTimestamp(moment(now).startOf("day")),
    DateFormatter.formatTimestamp(moment(now))
  ],
  [
    7,
    "webapp.new_dashboard.date_range.options.last_seven_days",
    // todo - need to adjust start of the day for 7 and other days
    DateFormatter.formatTimestamp(moment(now).subtract(7, "days")),
    DateFormatter.formatTimestamp(moment(now))
  ],
  [
    30,
   "webapp.new_dashboard.date_range.options.last_thirty_days",
    DateFormatter.formatTimestamp(moment(now).subtract(30, "days")),
    DateFormatter.formatTimestamp(moment(now))
  ],
  [
    90,
   "webapp.new_dashboard.date_range.options.last_ninety_days",
    DateFormatter.formatTimestamp(moment(now).subtract(90, "days")),
    DateFormatter.formatTimestamp(moment(now))
  ]
];

const initialState = 1;


type Action = UpdateAction;

// Reducer
export default function(state = initialState, action: Action) {
  switch (action.type) {
    case UPDATE:
      return action.payload;
    default:
      return state;
  }
}

// Action Creators
export interface UpdateAction {
  type: typeof UPDATE;
  payload: number;
}
export function update(payload: number): UpdateAction {
  return { type: UPDATE, payload };
}
