import Backbone from 'backbone';
import Category from 'models/newsroom/category';

class ReaderCategories extends Backbone.Collection {
    initialize(models, options) {
        this.readerConfig = options.readerConfig;
        this.comparator = 'position';
    }
    url() {
        const configId = this.readerConfig.id;
        return `api/v3/reader_configurations/${configId}/categories`;
    }
}
ReaderCategories.prototype.model = Category;

export default ReaderCategories;
