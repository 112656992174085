import Marionette from 'backbone.marionette'
import template from 'text-loader!templates/components/customReaderBrandingStyleTag.html'

var View = Marionette.ItemView.extend({
  initialize: function(options) {
    this.branding = options.configuration.attributes.reader_branding;
  },

  template: template,

  serializeData: function () {
    return {
      styling: this._styling()
    };
  },

  _primaryColor: function () {
    if (this.branding) {
      return this.branding.primary_color
    }
  },

  _primaryColorFaded: function () {
    var primaryColor = this._primaryColor()
    if (primaryColor) {
      return primaryColor.replace('rgb', 'rgba').replace(')', ',0.1)')
    }
  },

  _styling: function () {
    var title = '.with-reader-branding .editable-heading__text {color: ' + this._primaryColor() + ' !important} '
    var title2 = '.with-reader-branding .wysiwyg-clip-heading__text {color: ' + this._primaryColor() + ' !important} '
    var subtitle = '.with-reader-branding .wysiwyg-summary-content h2 {color: ' + this._primaryColor() + ' !important}'
    var highlighting = '.with-reader-branding .fr-box .clip-detail__highlight {background: ' + this._primaryColorFaded() + ' !important}'
    return title + title2 + subtitle + highlighting
  }
});

export default View;
