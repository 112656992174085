import RestorableModel from 'libraries/restorableModel';
import ReaderCategories from 'collections/newsroom/categories';
import _ from 'underscore';
import when from 'when';
import $ from 'jquery';
import NotificationConfigurations from 'collections/newsroom/notificationConfigurations';

class ReaderConfiguration extends RestorableModel {
    initialize() {
        this.urlRoot = 'api/v3/reader_configurations';
        this.categories = new ReaderCategories([], { readerConfig: this });
        this.initializeNotificationConfigurations();
        this.listenTo(this.categories, 'change', () =>  this.trigger('change'));
        this.listenTo(this.categories, 'add', () =>  this.trigger('change'));
        this.listenTo(this.categories, 'remove', () =>  this.trigger('change'));
    }

    initializeNotificationConfigurations() {
        this.notification_configurations =
            new NotificationConfigurations(
                this.get('notification_configurations')
            );
    }

    updateIncurCost() {
        return when($.ajax({
            type: 'POST',
            url: `${this.urlRoot}/${this.get('id')}/update_incur_cost`,
            contentType: 'application/json',
            data: JSON.stringify(this.attributes)
        }));
    }

    updateToken(opts) {
        let updateUrl = this.urlRoot + '/' + this.get('id') + '/generate_token';
        let options = {
            url: updateUrl,
            type: 'POST'
        };
        _.extend(options, opts);

        return this.sync.call(this, null, this, options);
    }

    sendToken(opts) {
        let sendTokenUrl = this.urlRoot + '/' + this.get('id') + '/send_token_email';
        let options = {
            url: sendTokenUrl,
            type: 'POST'
        };
        _.extend(options, opts);

        return this.sync.call(this, null, this, options);
    }

    fetchCategories() {
        return this.categories.fetch();
    }

    editorialReader() {
        return !this.get("starts_as_delivery_time");
    }

    toJSON() {
        let fullJson = super.toJSON(this);
        let recipients = _.compact(fullJson.recipients);
        let recipients_attributes =
            _.map(recipients, (recipient) => {
                return {
                    type: recipient.type,
                    id: recipient.id
                };
            });

        _.extend(fullJson, {
            recipients: recipients_attributes
        });

        return fullJson;
    }
}

export default ReaderConfiguration;
