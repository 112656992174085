import * as React from "react";
import I18n from "i18n";
import * as jQuery from "jquery";
import * as _ from "underscore";
import Spinner from './Spinner'
import * as moment from 'moment';
import {
  IKbApiEntity, IKbApiRepType, IKbApiRepresentation, findRepresentation,
  SupportedLocale, localizedName, locale
} from 'models/kbApiEntity'
import { EntityDetailViewContainer as Container } from 'components/entityDetailViewContainer'

export interface Props {
  person?: IKbApiEntity
}
export class PersonDetailView extends React.Component<any, {}> {
  render() {
    if (this.props.person) {
      const person = this.props.person
      const name = localizedName(person)
      return (
        <Container>
          {this.updatedAt()}
          <h2 className="heading-xlarge mtm">{name}</h2>
          {this.firstNameMarkup()}
          {this.lastNameMarkup()}
        </Container>
      )
    } else {
      const label = I18n.t('webapp.components.personDetailView.loading')
      return (
        <Container> <Spinner label={label} /> </Container>
      )
    }
  }

  firstNameMarkup() {
    const name = findRepresentation(
      this.props.person,
      'first_name',
      locale()
    )
    const label = I18n.t('webapp.components.personDetailView.firstName')
    if (name != undefined) {
      return (
        <form className="form-item mvm">
          <label className="form-item__label">{label}</label>
          <div className="form-item__input">
            <input type="text" className="text-input text-input--block" disabled={true} value={name} />
          </div>
        </form>
      )
    }
  }

  lastNameMarkup() {
    const name = findRepresentation(
      this.props.person,
      'last_name',
      locale()
    )
    const label = I18n.t('webapp.components.personDetailView.lastName')
    if (name != undefined) {
      return (
        <form className="form-item mvm">
          <label className="form-item__label">{label}</label>
          <div className="form-item__input">
            <input type="text" className="text-input text-input--block" disabled={true} value={name} />
          </div>
        </form>
      )
    }
  }

  updatedAt() {
    const updatedAt = this.props.person.data.attributes.updated_at
    const fmt = moment(updatedAt).format("DD.MM.YYYY HH:MM")
    return (
      <div className="link-bar mbm">
        <span className="link-bar__right-item text--grey"> <i className="fa fa-refresh text--white"></i> {fmt}</span>
      </div>
    )
  }

}
