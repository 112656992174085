import * as React from 'react';
import I18n from 'i18n';
import session from 'models/sessionInstance';
import {
  SenderDefaultValues
} from 'views/newsroom/readerNotificationConfigurationSenderFormView';

const AvailableButtons = {
    bluereport: 0,
    custom: 1
};

class ReaderNotificationConfigurationSenderButtonsView extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = this.initialState(props);
    }

    initialState(props: any) {
         return {
           activeButton: props.activeButton || AvailableButtons.bluereport
         };
    }

    render() {
        const senderFrom = I18n.t(
            'webapp.reader_admin.edit.notification_configuration.sender.from'
        );
        const senderCustom = I18n.t(
            'webapp.reader_admin.edit.notification_configuration.sender.custom'
        );
        const buttonDivClasses = [
            "button-group",
            "button-group--radio",
            "button-group--horizontal",
            "button-group--small",
            "toggle-notification-sender-options"
        ];
        let buttonBluereportClasses = ["button-group__item", "phs"];
        let buttonCustomClasses = ["button-group__item", "phs"];

        if (this.state.activeButton === AvailableButtons.bluereport) {
             buttonBluereportClasses.push('is-active');
        } else {
             buttonCustomClasses.push('is-active');
        }

        return (
            <div className="form-item--inline mbs">
              <label htmlFor="absenderemail"
                     className="form-item__label">{senderFrom}</label>
              <div className="form-item__input">
                <div className={buttonDivClasses.join(" ")}>
                  <button type="button"
                          onClick={this.onSelection.bind(this)}
                          className={buttonBluereportClasses.join(" ")}
                          data-buttonIdentifier={AvailableButtons.bluereport}>
                    {SenderDefaultValues.name()}
                  </button>
                  <button type="button"
                          onClick={this.onSelection.bind(this)}
                          className={buttonCustomClasses.join(" ")}
                          data-buttonIdentifier={AvailableButtons.custom}>
                    {senderCustom}
                  </button>
                </div>
              </div>
            </div>
        );
    }

    onSelection(e: any) {
        const buttonIdentifier = Number(e.target.dataset.buttonidentifier);
        if (buttonIdentifier === AvailableButtons.bluereport) {
            this.setState((prevState: any) => {
              return {
                activeButton: AvailableButtons.bluereport
              }
            });
            this.props.onButtonClick(AvailableButtons.bluereport);
        } else {
            this.setState((prevState: any) => {
              return {
                activeButton: AvailableButtons.custom
              }
            });
            this.props.onButtonClick(AvailableButtons.custom);
        }
    }
}

export {ReaderNotificationConfigurationSenderButtonsView, AvailableButtons};
