import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as _ from 'underscore'
import * as jQuery from 'jquery'
import I18n from 'i18n'
import {Filter, FilterType} from 'components/researchFilter'
import {Select2Id} from 'components/researchFilter/select2Component'
import ProviderFinder from 'libraries/researchFilter/providerFinder'
import { IEsApiEntity as Entity, IEsApiTopic as Topic } from 'models/esResult'
import * as Backbone from "backbone";

export interface FilterInfo {
  type: FilterType
  exclude: boolean
  id: number
  selection: Select2Id[]
  active: boolean
  showErrors: boolean
}

export interface IFilterBuilderProps {
  entityNameCache?: Backbone.Collection<Entity>
  topicNameCache?: Backbone.Collection<Topic>
  filters: FilterInfo[][]
  allowedTypes: FilterType[]
  onFiltersChange: (newFilters: FilterInfo[][], valid: boolean) => void
  readonly: boolean
}

interface FilterBuilderState {
  filters: FilterInfo[][]
}

export class FilterBuilderView extends React.Component<IFilterBuilderProps, FilterBuilderState> {
  constructor(props: IFilterBuilderProps) {
    props.filters.forEach((filterBlock) => {
      filterBlock.forEach((filter) => {
        if (filter.selection === null) {
          filter.selection = []
        }
      })
    })
    super(props)
    this.validateProps(props)
    this.state = this.initialState(props)
  }

  private initialState(props: IFilterBuilderProps) {
    return {
      filters: this.props.filters.slice()
    }
  }

  componentWillReceiveProps(nextProps: IFilterBuilderProps) {
    this.validateProps(nextProps)
  }

  componentDidUpdate() {
    $(".separator--drop").removeClass("notDroppable");
    $(".separator--drop").hide();
  }

  onFiltersChange(filters: FilterInfo[][]) {
    let valid = _.every(_.flatten(filters), (filter: FilterInfo) => {
      return ProviderFinder[filter.type].isValid(filter.selection)
    })
    this.props.onFiltersChange(filters, valid)
  }

  private validateProps(props: IFilterBuilderProps) {
    _.flatten(props.filters).forEach(filter => {
      if(!_.contains(props.allowedTypes, filter.type)) {
        throw `Filter type ${filter.type} is not in allowedTypes`
      }
    })
  }

  render() {
    return (
      <div className="advancedsearch">
        <div className="advancedsearch--and-block">{this.filters()}</div>
        {this.addFilter()}
      </div>
    );
  }

  addFilter() {
    if (this.props.readonly) {
      return (<div></div>)
    } else {
        return (
          <div onClick={() => this.onAddClick()}
              className="advancedsearch--add-filter">
            <i className="icon-plus"></i>{' '}
            {I18n.t('webapp.agents.edit.filters.add_filter')}
          </div>
        )
    }
  }

  private filters(): JSX.Element[] {
    let filterBlocks = this.state.filters.map((block: any, index: number) => {
      return (
        <div className="advancedsearch--or-block" style={{display:'block'}}>
          {this.filterBlock(block, index)}
        </div>
      )
    })
    let ands = _.range(filterBlocks.length - 1).map(() => {
      return <div className="separator--and">
        {I18n.t('webapp.agents.edit.filters.separator_and')}
      </div>
    })
    let andDropzone = [
      <div className="separator--drop"
        onDragOver={(e) => {this.dragOverHandler(e)}}
        onDragEnter={(e) => {this.dragOverHandler(e)}}
        onDragLeave={(e) => {this.dragLeaveHandler(e)}}
        onDrop={(e) => {this.andDropHandler(e)}}>
        {I18n.t('webapp.agents.edit.filters.separator_and')}
      </div>
    ]
    return _.flatten(_.zip(filterBlocks, ands).concat(andDropzone))
  }

  private filterBlock(filters: FilterInfo[], index: number): JSX.Element[] {
    const draggable = _.flatten(this.state.filters).length > 1;
    const singleFilter = filters.length === 1;
    let deletable = _.flatten(this.state.filters).length > 1;
    let filterEls = filters.map((filterInfo: FilterInfo, index: number) => {
      return (
        <Filter
          entityNameCache={this.props.entityNameCache}
          topicNameCache={this.props.topicNameCache}
          key={filterInfo.id}
          onFilterChange={(newFilterInfo) => this.onFilterChange(newFilterInfo)}
          onDeleteClick={() => this.onDeleteClick(filterInfo.id)}
          deletable={deletable}
          allowedTypes={this.props.allowedTypes}
          readonly={this.props.readonly}
          draggable={draggable}
          singleFilter={singleFilter}
          active={filterInfo.active}
          showErrors={filterInfo.showErrors}
          {...filterInfo}
        />
      )
    })
    let ors = _.range(filterEls.length - 1).map(() => {
      return <div className="separator--or">
        {I18n.t('webapp.agents.edit.filters.separator_or')}
      </div>
    })
    let orDropzone = [
      <div className="separator--drop"
        onDragOver={(e) => {this.dragOverHandler(e)}}
        onDragEnter={(e) => {this.dragOverHandler(e)}}
        onDragLeave={(e) => {this.dragLeaveHandler(e)}}
        onDrop={(e) => {this.orDropHandler(e, index)}}>
        {I18n.t('webapp.agents.edit.filters.separator_or')}
      </div>
    ]
    return _.flatten(_.zip(filterEls, ors).concat(orDropzone))
  }

  private dragOverHandler(event: any) {
     event.currentTarget.classList.add("drag-over");
     event.preventDefault();
  }

  private dragLeaveHandler(event: any) {
     event.currentTarget.classList.remove("drag-over");
     event.preventDefault();
  }

  private andDropHandler(event: any) {
    event.preventDefault();
    let id = Number(event.dataTransfer.getData("Text"));
    this.setState((prevState: FilterBuilderState) => {
      let movedFilter = _.find(_.flatten(prevState.filters), filterInfo => filterInfo.id === id)
      let remainingFilters: FilterInfo[][] = prevState.filters.map((block: FilterInfo[]) => {
        return _.filter(block, (filterInfo) => {return filterInfo.id !== id})
      })
      let cleanedUpFilters = _.filter(remainingFilters, (block) => {return block.length > 0})
      let newFilters = cleanedUpFilters.concat([[movedFilter]])
      this.onFiltersChange(newFilters)
      return {
        filters: newFilters
      }
    })
  }

  private orDropHandler(event: any, index: number) {
    event.preventDefault();
    let id = Number(event.dataTransfer.getData("Text"));
    this.setState((prevState: FilterBuilderState) => {
      let movedFilter = _.find(_.flatten(prevState.filters), filterInfo => filterInfo.id === id)
      let remainingFilters: FilterInfo[][] = prevState.filters.map((block: FilterInfo[]) => {
        return _.filter(block, (filterInfo) => {return filterInfo.id !== id})
      })
      let newFilters = remainingFilters.map((block: FilterInfo[], blockIndex: number) =>{
        if (blockIndex === index) {
          return block.concat([movedFilter])
        } else {
          return block
        }
      })
      let cleanedUpFilters = _.filter(newFilters, (block) => {return block.length > 0})
      this.onFiltersChange(cleanedUpFilters)
      return {
        filters: cleanedUpFilters
      }
    })
  }

  private onAddClick() {
    this.setState((prevState: FilterBuilderState) => {
      let filters = prevState.filters
      let newId = _.max(_.flatten(filters), filter => filter.id).id + 1
      let newFilter: FilterInfo = {
        type: 'fulltext',
        exclude: false,
        id: newId,
        selection: [],
        active: true,
        showErrors: false
      }
      let newFilters = filters.concat([[newFilter]])
      this.onFiltersChange(newFilters)
      return {
        filters: newFilters
      }
    })
  }

  private onFilterChange(newFilterInfo: FilterInfo) {
    this.setState((prevState: FilterBuilderState) => {
      let filters = prevState.filters.map((block: FilterInfo[]) => {
        return block.map((filterInfo: FilterInfo) => {
          if (filterInfo.id === newFilterInfo.id) {
            return newFilterInfo
          } else {
            return filterInfo
          }
        })
      })
      this.onFiltersChange(filters)
      return {
        filters: filters
      }
    })
  }

  private onDeleteClick(id: number) {
    this.setState((prevState: FilterBuilderState) => {
      let newFilters: FilterInfo[][] = prevState.filters.map((block: FilterInfo[]) => {
        return _.filter(block, (filterInfo) => {return filterInfo.id !== id})
      })
      let cleanedUpFilters = _.filter(newFilters, (block) => {return block.length > 0})
      this.onFiltersChange(cleanedUpFilters)
      return {
        filters: cleanedUpFilters
      }
    })
  }
}
