import $ from 'jquery'
import Marionette from 'backbone.marionette'
import session from 'models/sessionInstance'
import LayoutController from 'controllers/layoutController'
import users from 'collections/usersInstance'
import vent from 'libraries/vent'

var ApplicationController = Marionette.Controller.extend({
  initialize: function (options) {
    this.options = options;
    this._createApplicationRegion();
    this._registerEventListeners();
    this._registerCommandHandlers();
  },
  _createApplicationRegion: function () {
    this.applicationRegion = new Marionette.Region({
      el: '#layout'
    });
  },
  _createLayoutController: function () {
    this.layoutController = new LayoutController();
  },
  _registerEventListeners: function () {
    this.listenTo(users, 'changed:currentUser', this.refreshHeader);
  },
  _registerCommandHandlers: function () {
    vent.commands.setHandler('logout', this.logout);
    vent.commands.setHandler('unswitch', this.unswitchUser);
  },
  brandLayout: function () {
    $('body').addClass(session.get('brand_theme'));
  },
  renderLayout: function () {
    this._createLayoutController();
    this.layoutController.show(this.applicationRegion);
  },
  logout: function () {
    window.location.href = '/logout';
  },
  unswitchUser: function () {
    window.location.href = '/unswitch_user';
  },
  refreshHeader: function () {
    this.layoutController.refreshHeader()
  }
});

export default ApplicationController;
