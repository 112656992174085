import Marionette from 'backbone.marionette'
import _ from 'underscore'
import template from 'text-loader!templates/agents/agentResultTrashBinActions.html'

var AgentResultTrashBinActionsView = Marionette.ItemView.extend({
  template: template,
  triggers: {
    'click [data-button-restore]': 'clicked:restore'
  },
  ui: {
    restoreButton: '[data-button-restore]'
  },
  toggleButton: function () {
    if (!_.isString(this.ui.restoreButton)) {
      this.ui.restoreButton.toggle();
    }
  },
  serializeData: function () {
    return {
      isOwnedAgentResult: this.model.isOwnedByCurrentUser()
    };
  }
});

export default AgentResultTrashBinActionsView;

