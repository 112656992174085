import { BASE_PATH } from "../DashboardAppConstants";
import agentsInstancePromise from "models/agentsInstancePromise";

// Actions
const UPDATE: "app/searchAgents/UPDATE" = "app/searchAgents/UPDATE";
const UPDATE_FULFILLED: "app/searchAgents/UPDATE_FULFILLED" =
  "app/searchAgents/UPDATE_FULFILLED";
const UPDATE_PENDING: "app/searchAgents/UPDATE_PENDING" =
  "app/searchAgents/UPDATE_PENDING";
const UPDATE_REJECTED: "app/searchAgents/UPDATE_REJECTED" =
  "app/searchAgents/UPDATE_REJECTED";
const RESET: "app/searchAgents/RESET" = "app/searchAgents/RESET";

export interface UserAgentItem {
  userIds: number[];
  agentId: number;
}

const initialState: UserAgentItem[] = [];

type Action =
  | UpdateAction
  | {
    type: typeof UPDATE_FULFILLED;
    payload: number[];
    meta: { agentId: number; };
  }
  | { type: typeof UPDATE_PENDING; meta: { agentId: number, userId: number } }
  | { type: typeof UPDATE_REJECTED; meta: { agentId: number } }
  | { type: typeof RESET };

// Reducer
export default function(state = initialState, action: Action): UserAgentItem[] {
  switch (action.type) {
    case UPDATE_FULFILLED:
      return [
        ...state.filter(i => i.agentId !== action.meta.agentId),
        { userIds: action.payload, agentId: action.meta.agentId }
      ];
    case UPDATE_PENDING:
      return [...state, { userIds: [action.meta.userId], agentId: action.meta.agentId }];
    case UPDATE_REJECTED:
      return [...state.filter(i => i.agentId !== action.meta.agentId)];
    case RESET:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
interface UpdateAction {
  type: typeof UPDATE;
  payload: Promise<any>;
  meta: { agentId: number; userId: number; };
}
export function updateSubscription(
  userId: number,
  agentId: number
): UpdateAction {
  let formData = new FormData();
  formData.append("id", `${userId}`);

  return {
    type: UPDATE,
    payload: fetch(`${BASE_PATH}/agents/${agentId}/subscribed_users`, {
      method: "POST",
      body: formData,
      credentials: "same-origin"
    })
      .then(res => res.json())
      .then(json => {
        agentsInstancePromise.then((agents: any) => agents.fetch());
        return json.user_ids;
      }),
    meta: { agentId, userId }
  };
}

export function reset() {
  return {
    type: RESET
  };
}
