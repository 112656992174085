import Marionette from 'backbone.marionette'
import agentResultFilterTemplate from 'text-loader!templates/agentResultFilter.html'
import _ from 'underscore'
import moment from 'moment'
import I18n from 'i18n'
import jQuery from 'jquery';
import session from 'models/sessionInstance';

var AgentResultFilterView = Marionette.ItemView.extend({
  template: agentResultFilterTemplate,
  ui: {
    onlineCheckbox: "#agent-result-filter-source-online",
    socialMediaCheckbox: "#agent-result-filter-source-social-media",
    printCheckbox: "#agent-result-filter-source-print",
    radioTvCheckbox: "#agent-result-filter-source-radio-tv",

    positiveCheckbox: "#agent-result-filter-rating-positive",
    neutralCheckbox: "#agent-result-filter-rating-neutral",
    negativeCheckbox: "#agent-result-filter-rating-negative",
    unratedCheckbox: "#agent-result-filter-rating-unrated",

    importantCheckbox: "#agent-result-filter-result-important",
    normalCheckbox: "#agent-result-filter-result-normal",

    allCheckboxes: "input[type=checkbox]",

    sourceDropdown: '[data-source-dropdown]',
    ratingDropdown: '[data-rating-dropdown]',

    datePickerDate: '#datePickerDate'
  },
  triggers: {
    'change #agent-result-filter-source-online': 'changed:filter:online',
    'change #agent-result-filter-source-social-media': 'changed:filter:sm',
    'change #agent-result-filter-source-print': 'changed:filter:print',
    'change #agent-result-filter-source-radio-tv': 'changed:filter:radiotv',

    'change #agent-result-filter-rating-positive': 'changed:filter:positive',
    'change #agent-result-filter-rating-neutral': 'changed:filter:neutral',
    'change #agent-result-filter-rating-negative': 'changed:filter:negative',
    'change #agent-result-filter-rating-unrated': 'changed:filter:unrated',

    'change #agent-result-filter-result-important': 'changed:filter:important',
    'change #agent-result-filter-result-normal': 'changed:filter:normal'
  },
  initDatePicker(minDate) {
    const self = this;
    const triggerElement = self.$('.date-picker-dropdown__trigger');
    var opts = {
      startDate: minDate,
      endDate: new Date(),
      format: "yyyy-mm-dd",
      language: I18n.locale,
      autoclose: true,
      orientation: 'auto',
    }
    this.picker = self.pickerElement().bootstrapDatepicker(opts)
    this.picker.on('changeDate', function (data) {
      const d = moment(data.date);
      self.trigger('changed:date', d);
    })

    triggerElement.on('click', function () {
      if (self.isDatePickerOpen()) {
        self.closeDatePicker();
      } else {
        self.openDatePicker();
      }
    });

    // close date picker if we click somewhere outside it
    jQuery(window).click(function (ev) {
      const outSideCheck1 =
            !jQuery.contains(self.container()[0],
                             jQuery(ev.target)[0]);
      const outSideCheck2 =
            !jQuery(ev.target).hasClass('month');
      const outSideCheck3 =
            !jQuery(ev.target).hasClass('year');

      // contains by itself doesn't work so well in this case, hence the other two checks
      if (outSideCheck1 &&
          outSideCheck2 &&
          outSideCheck3 &&
          self.isDatePickerOpen()
         )  {
        self.closeDatePicker();
      }
    });

    // close datepicker if we click on other filters
    self.$('[data-rating-dropdown], [data-source-dropdown]').click(function() {
      if (self.isDatePickerOpen()) {
        self.closeDatePicker();
      }
    });
  },
  isDatePickerOpen() {
    return this.container().hasClass('is-open');
  },
  openDatePicker() {
    this.container().removeClass('is-closed');
    this.container().addClass('is-open');

    this.pickerElement().show();
  },
  closeDatePicker() {
    this.container().removeClass('is-open');
    this.container().addClass('is-closed');

    this.pickerElement().hide();
  },
  container() {
    return this.$('[data-date-dropdown]');
  },
  pickerElement() {
    return this.$('.date-picker-dropdown__datepicker');
  },
  setCheckboxStates: function (states) {
    this.ui.onlineCheckbox.prop("checked", states.online);
    this.ui.socialMediaCheckbox.prop("checked", states.socialMedia);
    this.ui.printCheckbox.prop("checked", states.print);
    this.ui.radioTvCheckbox.prop("checked", states.radioTv);

    this.ui.positiveCheckbox.prop("checked", states.positive);
    this.ui.neutralCheckbox.prop("checked", states.neutral);
    this.ui.negativeCheckbox.prop("checked", states.negative);
    this.ui.unratedCheckbox.prop("checked", states.unrated);

    this.ui.importantCheckbox.prop("checked",
                                   states.important &&
                                   !states.normal);
  },
  disableCheckbox: function(checkbox) {
    this.ui[checkbox + 'Checkbox'].prop('disabled', true);
  },
  highlightCheckbox: function(dropdown) {
    this.ui[dropdown + 'Dropdown']
      .addClass('multiselect--highlighted');
  },
  unHighlightCheckbox: function(dropdown) {
    this.ui[dropdown + 'Dropdown']
      .removeClass('multiselect--highlighted');
  },
  hidePrintOption: function () {
    this.ui.printCheckbox.parents("li").hide();
  },
  enableCheckboxes: function (checkboxes) {
    _.each(checkboxes, (checkbox) => {
      this.ui[checkbox + "Checkbox"].prop("disabled", false);
    }, this);
  },
  getCheckboxStates: function () {
    return { online: this.ui.onlineCheckbox.prop("checked"),
             socialMedia: this.ui.socialMediaCheckbox.prop("checked"),
             print: this.ui.printCheckbox.prop("checked"),
             radioTv: this.ui.radioTvCheckbox.prop("checked"),

             positive: this.ui.positiveCheckbox.prop("checked"),
             neutral: this.ui.neutralCheckbox.prop("checked"),
             negative: this.ui.negativeCheckbox.prop("checked"),
             unrated: this.ui.unratedCheckbox.prop("checked"),

             important: this.ui.importantCheckbox.prop("checked"),
             normal:  !this.ui.importantCheckbox.prop("checked") };
  },

  serializeData: function () {
    return {
      hiddenFilterDropdownClass: session.hasFeature('show_all_timeline_filters') ? '' : 'hidden',
    }
  },
});

export default AgentResultFilterView;

