import ContactReactAdapter from 'models/contactReactAdapter'
import AddressbookModel, { AddressbookType } from 'models/addressbookModel'
import contactsInstance from 'collections/contactsInstance'
import contactGroupsInstance from 'collections/contactGroupsInstance'
import * as _ from 'underscore'

export default class ReaderAudienceReactAdapter {
  constructor(configuration) {
    this.configuration = configuration
    this.audience = this._audienceContacts().concat(this._audienceGroups())
    let addressbookContacts =
        this._addressbookGroups().concat(this._addressbookContacts())
    this.addressbook = _.without(addressbookContacts, ...this.audience)
  }

  audienceToReactProps() {
    return new ContactReactAdapter(this.audience).toReactProps()
  }

  addressbookToReactProps() {
    return new ContactReactAdapter(this.addressbook).toReactProps()
  }

  _audienceContacts() {
    const audience = this.configuration.get('audience')
    const contactIds = _.chain(audience).filter((a) => {
        return a.type === 'contact'
    }).pluck('id').value()
    const existingContactIds = _.filter(contactIds, (cId) => {
      return !_.isUndefined(contactsInstance.get(cId))
    });
    return _.map(existingContactIds, (contactId) => {
      return contactsInstance.get(contactId)
    })
  }

  _audienceGroups() {
    const audience = this.configuration.get('audience')
    const contactGroupIds = _.chain(audience).filter((a) => {
        return a.type === 'contact_group'
    }).pluck('id').value()
    const existingGroupIds = _.filter(contactGroupIds, (cgId) => {
      return !_.isUndefined(contactGroupsInstance.get(cgId))
    });
    return _.map(existingGroupIds, (contactGroupId) => {
      let contactGroup = contactGroupsInstance.get(contactGroupId)
      let contacts = this._filterAndConvertContactsToReact(contactGroupId)
      contactGroup.set({ contacts: contacts })
      return contactGroup
    })
  }

  _addressbookContacts() {
    return contactsInstance.models
  }

  _addressbookGroups() {
    return _.map(contactGroupsInstance.models, (contactGroup) => {
      let contacts = this._filterAndConvertContactsToReact(contactGroup.get('id'))
      contactGroup.set({ contacts: contacts })
      return contactGroup
    })
  }

  _filterAndConvertContactsToReact(groupId) {
      let contacts = contactsInstance.filter((contact) => {
        return _.contains(contact.get('contact_group_ids'), groupId)
      });
      return _.map(contacts, (contact) => {
        return new AddressbookModel(
            `c${contact.get('id')}`, AddressbookType.Contact, contact
        ).toReactProps()
      })
  }
}
