/* jshint module: true */
import $ from 'jquery';
import Backbone from 'backbone';
import Config from 'libraries/configuration';
import Notificator from 'libraries/notificator';
import I18n from 'i18n';

let AgentResultDownload = Backbone.Model.extend({
    urlRoot() {
        const agentResult = this.get('agentResult');
        return `${Config.apiUrl}clips/${agentResult.get('clip_id')}.pdf`;
    },

    initialize(options) {
        this.set('agentResult', options.agentResult);
    },

    downloadPDF() {
        const agentResultDownload = this;
        const pdf_path = agentResultDownload.urlRoot();

        if (agentResultDownload.downloadSupported()) {
            if (agentResultDownload.downloadPending === false) {
                    Notificator.showNotification(I18n.t('webapp.notifications.agent_results.pdf_download_request_sent'));
                    agentResultDownload.downloadGlobalTimeout = window.setTimeout(function () {
                        agentResultDownload.clipAbortDownloadPDF();
                    }, 45000);
            }

            agentResultDownload.downloadPending = $.ajax({
                url: pdf_path,
                type: 'GET',
                cache: true,
                context: agentResultDownload,
                timeout: 20000,
                success: (data, textStatus, XMLHttpRequest) => {
                    switch (XMLHttpRequest.status) {
                    case 202:
                        Notificator.showPermanentNotification(
                          I18n.t('webapp.notifications.agent_results.pdf_download_request_pending'),
                          this.get("agentResult").get("clip_id")
                        );
                        agentResultDownload.downloadTimeout = window.setTimeout(() => {
                            agentResultDownload.downloadPDF();
                        }, 10000);
                        break;

                    case 204:
                        Notificator.showNotification(I18n.t('webapp.notifications.agent_results.pdf_download_contact_support_to_order'));

                        agentResultDownload.downloadPending = false;
                        agentResultDownload.downloadTimeout = false;
                        agentResultDownload.downloadGlobalTimeout = false;
                        agentResultDownload.removePermaNotice();
                        break;
                    case 201:
                        Notificator.showNotification(I18n.t('webapp.notifications.agent_results.pdf_download_ready'));

                        clearTimeout(agentResultDownload.downloadGlobalTimeout);
                        $('#downloadClipContent').attr('src', XMLHttpRequest.getResponseHeader('Location'));

                        agentResultDownload.downloadPending = false;
                        agentResultDownload.downloadTimeout = false;
                        agentResultDownload.downloadGlobalTimeout = false;
                        agentResultDownload.removePermaNotice();
                        break;
                    }
                },
                error: (XMLHttpRequest, textStatus) => {
                    if (XMLHttpRequest.status === 410) {
                        clearTimeout(agentResultDownload.downloadGlobalTimeout);
                        agentResultDownload.clipPDFGone();
                    } else if (XMLHttpRequest.status === 401) {
                        clearTimeout(agentResultDownload.downloadGlobalTimeout);
                        agentResultDownload.clipPDFUnauthorized();
                    } else if (textStatus === 'timeout') {
                        clearTimeout(agentResultDownload.downloadGlobalTimeout);
                        agentResultDownload.clipAbortDownloadPDF();
                    }
                }
            });
        } else {
            $('#downloadClipContent').attr('src', pdf_path);
            window.open(agentResultDownload.downloadUrl(pdf_path));
        }
    },

    downloadSupported() {
        return !navigator.userAgent.match(/(iPod|iPhone|iPad)/);
    },

    downloadUrl(url) {
        return url.replace(Config.apiUrl, '');
    },

    removePermaNotice() {
        Notificator.clearPermanentNotification(this.get("agentResult").get("clip_id"));
    },

    clipAbortDownloadPDF() {
        this.clipAbortDownloadPDFWithMessage(I18n.t('webapp.notifications.agent_results.pdf_download_generation_failed'));
    },

    clipPDFUnauthorized() {
        this.clipAbortDownloadPDFWithMessage(I18n.t('webapp.notifications.agent_results.pdf_download_generation_failed'));
    },

    clipPDFGone() {
        this.clipAbortDownloadPDFWithMessage(I18n.t('webapp.notifications.agent_results.pdf_download_article_gone'));
    },

    clipAbortDownloadPDFWithMessage(message) {
        Notificator.showNotification(message);
        this.removePermaNotice();

        this.downloadPending.abort();
        clearTimeout(this.downloadTimeout);

        this.downloadPending = false;
        this.downloadTimeout = false;
        this.downloadGlobalTimeout = false;
    }
});

export default AgentResultDownload;
