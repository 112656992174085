/* eslint no-invalid-this: "off" */
import App from 'app'
import $ from 'jquery'
import moment from 'moment'
import DateFormatter from 'libraries/dateFormatter'

App.module('RelativeTimes', {
  startWithParent: true,
  define: function () {
    this.addInitializer(function () {
      this.interval = setInterval(this.updateElements, 10000);
    });

    this.addFinalizer(function () {
      if (this.interval) {
        clearInterval(this.interval);
      }
    });

    this.updateElements = function () {
      $('[data-date-auto-update]').each(function (index, element) {
        App.RelativeTimes.formatElement(element);
      });
    };

    this.formatElement = function (element) {
      element = $(element);
      var format = element.attr('data-date-format');
      var formatter = {
        date: DateFormatter.formatRelativeDate,
        dateTime: DateFormatter.formatRelativeDateTime,
        listDateTime: DateFormatter.formatRelativeListDateTime
      }[format];
      var date = moment.unix(element.attr('data-timestamp'));
      element.html(formatter(date));
    };
  }
});

export default App.RelativeTimes;

