import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import interactPartial from 'text-loader!templates/reporting/wizard/interact.html'
import 'backbone.marionette'

var View = Backbone.Marionette.ItemView.extend({
  initialize: function () {
    if (!this.options.showAllSteps) {
      _.bindAll(this, 'updateVisibility');
      this.model.on('change:active', this.updateVisibility);
      this.updateVisibility();
    }

    this.on('before:render', () => {
      this.registerPartial('interact', interactPartial);
    });
  },
  updateVisibility: function () {
    if (this.model.get('active')) {
      $(this.el).show();
      if (!this.options.scrollOff) {
        $("#main").animate({ scrollTop: ($("#main").height() * 2) });
      }
    } else {
      $(this.el).hide();
    }
  }
});

export default View;

