import Marionette from 'backbone.marionette';
import GlobalSearchView from 'views/globalSearchView';
import OptionsManager from 'models/globalSearch/optionsManager';
import Shortcutter from 'libraries/shortcutter';
import _ from 'underscore';
import vent from 'libraries/vent';
import session from 'models/sessionInstance';
import Deferred from 'libraries/deferred';
import { buildFilters } from './research/newResearchController/buildFilters';

class GlobalSearchController extends Marionette.Controller {
  initialize() {
    _.bindAll(this,
              '_addToQuery',
              '_removeFromQuery',
              '_researchQuery',
              '_queryCallback');
    this.view = new GlobalSearchView({
      queryCallback: this._queryCallback
    });
    this.query = [];
    this.optionsManager = new OptionsManager();
    this._registerListeners();
    this._bindShortcuts();
    this._registerCommandHandlers();
  }

  hide() {
    this.view.hide();
  }

  show() {
    this.view.show();
  }

  onClose() {
    this._unbindShortcuts();
  }

  _registerListeners() {
    this.listenTo(this.view, 'tag:selected',     this._addToQuery);
    this.listenTo(this.view, 'keyword:selected', this._addToQuery);
    this.listenTo(this.view, 'source:selected',     this._addToQuery);
    this.listenTo(this.view, 'option:selected',     this._addToQuery);
    this.listenTo(this.view, 'search:selected', this._researchQuery);

    this.listenTo(this.view, 'tag:deselected',     this._removeFromQuery);
    this.listenTo(this.view, 'keyword:deselected', this._removeFromQuery);
    this.listenTo(this.view, 'source:deselected', this._removeFromQuery);
    this.listenTo(this.view, 'option:deselected', this._removeFromQuery);
  }

  _registerCommandHandlers() {
    _.bindAll(this,
              '_registerOptionsProvider',
              '_enableOptionsProvider',
              '_disableOptionsProvider',
              '_setPlaceholder',
              '_setSelection');
    vent.commands.setHandler('globalSearch:registerOptionsProvider',
                             this._registerOptionsProvider);
    vent.commands.setHandler('globalSearch:enableOptionsProvider',
                             this._enableOptionsProvider);
    vent.commands.setHandler('globalSearch:disableOptionsProvider',
                             this._disableOptionsProvider);
    vent.commands.setHandler('globalSearch:setPlaceholder',
                             this._setPlaceholder);
    vent.commands.setHandler('globalSearch:setSelection',
                             this._setSelection);
  }

  _bindShortcuts() {
    var self = this;
    Shortcutter.whenReady.then(() => {
      Shortcutter.bind('/', () => {
        self.view.focus();
        return false;
      });
    });
  }

  _unbindShortcuts() {
    Shortcutter.unbind('/');
  }

  _addToQuery(added) {
    this.query.push(added);
    this._notifyQueryChanged();
  }

  _removeFromQuery(removed) {
    this.query = _.reject(this.query, (item) => {
      return item.id === removed.id;
    });
    this._notifyQueryChanged();
  }

  _researchQuery(researchQuery) {
    this.view.clearSelection();
    this._clearQuery();
    if (session.hasFeature('access_to_new_research')) {
      const text = researchQuery.text.replace(/^["'](.+)["']$/, '$1');
      Deferred.async(() => {
        vent.commands.execute("new-research:query", buildFilters(text));
      })
    } else {
      vent.commands.execute("research:query", researchQuery.text);
    }
  }

  _clearQuery(triggerChange) {
    if (!_.isEmpty(this.query)) {
      this.view.clearSelection();
      this.query = [];
      if (triggerChange !== false) {
        this._notifyQueryChanged();
      }
    }
  }

  _notifyQueryChanged() {
    const currentQuery = {
      tagIds: this._tagIdsFromQuery(),
      sourceIds: this._sourceIdsFromQuery(),
      topicIds: this._topicIdsFromQuery(),
      query: this._keywordsFromQuery().join(' ')
    };
    vent.trigger("globalSearch:query:changed", currentQuery);
  }

  _tagIdsFromQuery() {
    const tags = _.where(this.query, {type: "tag"});
    return _.map(tags, (tag) => { return tag.id; });
  }

  _sourceIdsFromQuery() {
    const sources = _.where(this.query, {type: "source"});
    return _.map(sources, (source) => { return source.id; });
  }

  _topicIdsFromQuery() {
    const topics = _.where(this.query, {type: "topic"});
    return _.map(topics, (topic) => { return topic.id; });
  }

  _keywordsFromQuery() {
    const keywords = _.where(this.query, {type: "keyword"});
    return _.map(keywords, (keyword) => { return keyword.text; });
  }

  _registerOptionsProvider(provider, enabled) {
    this._clearQuery();
    this.optionsManager.registerProvider(provider, enabled);
    this._maybeShowSearch();
  }

  _enableOptionsProvider(name) {
    this._clearQuery();
    this.optionsManager.enableProvider(name);
    this._maybeShowSearch();
  }

  _disableOptionsProvider(name) {
    this._clearQuery();
    this.optionsManager.disableProvider(name);
    this._maybeHideSearch();
  }

  _maybeShowSearch() {
    if (!_.isEmpty(this.optionsManager.enabledProviders())) {
      this.show();
    }
  }

  _maybeHideSearch() {
    if (_.isEmpty(this.optionsManager.enabledProviders())) {
      this.hide();
    }
  }

  _setPlaceholder(text) {
    this.view.setPlaceholderText(text);
  }

  _queryCallback(options) {
    this.optionsManager.dataForTerm(options);
  }

  _setSelection(newSelection, triggerChange) {
    this._clearQuery(triggerChange);
    this.query = newSelection.slice(0);
    this.view.setSelection(newSelection, triggerChange);
  }
}

export default GlobalSearchController;
