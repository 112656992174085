import * as React from 'react';
import I18n from 'i18n';

export default function UnexpectedErrorMessage () {
    return (
        <div className="dashboard-widget__no-data">
        <p>{I18n.t("webapp.new_dashboard.dashboard_error_message.unexpected_error_occurred")}</p>
      </div>
  );
}
