import FulltextFilterProvider from 'libraries/researchFilter/fulltextFilterProvider'
import LanguageFilterProvider from 'libraries/researchFilter/languageFilterProvider'
import DateFilterProvider from 'libraries/researchFilter/dateFilterProvider'
import { EntityType } from 'models/entity'
import {
  EntityFilterProvider,
  IEntityProviderOptions
} from "libraries/researchFilter/entityFilterProvider"
import {
  IPrefixSearchEntityType,
  entityTypeToPrefixSearchEntityType
} from "models/prefixSearchResponse";
import { TopicFilterProvider } from './topicFilterProvider';

const entityProviderForType = (entityType: EntityType, localeOverride?: string) => {
  const prefix = 'webapp.agents.edit.filters.filter_type.'
  const opts: IEntityProviderOptions = {
    entityType: entityTypeToPrefixSearchEntityType(entityType),
    dropdownLocale: prefix + entityType + "_entity"
  }
  if (localeOverride) {
    opts.dropdownLocale = prefix + localeOverride
  }
  return new EntityFilterProvider(opts)
}
const topicFilterProvider = () => {
  const opts: IEntityProviderOptions = {
    entityType: 'topic',
    dropdownLocale: 'webapp.agents.edit.filters.filter_type.topic'
  }
  return new TopicFilterProvider(opts)
}
const personOrganizationFilterProvider = () => {
  const opts: IEntityProviderOptions = {
    entityType: 'person_organization_entity',
    dropdownLocale: ''
  }
  return new EntityFilterProvider(opts)
}

let ProviderFinder: { [key: string]: any } = {
  'fulltext': FulltextFilterProvider,
  'language': LanguageFilterProvider,
  'date': DateFilterProvider,
  'law_entity': entityProviderForType('law'),
  "topic": topicFilterProvider(),
  'location_entity': entityProviderForType('location'),
  'person_entity': entityProviderForType('person'),
  'organization_entity': entityProviderForType('organization'),
  'business_entity': entityProviderForType('business'),
  'person_organization_entity': personOrganizationFilterProvider()

}

export default ProviderFinder
