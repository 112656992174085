import Marionette from 'backbone.marionette'
import AgentResultMarkImportantView from 'views/agents/agentResultMarkImportantView'
import _ from 'underscore'
import Shortcutter from 'libraries/shortcutter'
import vent from "libraries/vent";

var AgentResultMarkImportantController = Marionette.Controller.extend({
  initialize: function (options) {
    this.options = options;
    var selectedAgentResults = options.selectedAgentResults;
    _.bindAll(this, 'setImportantValue', 'saveImportantValue');
    this.selectedAgentResults = selectedAgentResults;
    this.agentResults = selectedAgentResults.collection;

    this.view = new AgentResultMarkImportantView();

    this._registerListeners();
    this._bindShortcuts();
  },
  onClose: function () {
    this._unbindShortcuts();
  },
  _registerListeners: function () {
    this.listenTo(this.view, 'clicked.markImportant',
                  this.markImportantButtonHandler);
    this.listenTo(this.view, 'render', this.updateViewButtons);
  },
  markImportantButtonHandler: function () {
    // Block button until we receive a response
    this.stopListening(this.view, 'clicked.markImportant');
    var important = this.setImportantValue();
    this.saveImportantValue(important);
  },
  setImportantValue: function () {
    // Store previous model values in case update fails
    this.agentResults.invoke('store');

    var newImportantValue;
    newImportantValue = !this.isImportant();

    this.agentResults.each(function (agentResult) {
      agentResult.set('important', newImportantValue);
    });
    this.selectedAgentResults.set('important', newImportantValue);

    this.updateViewButtons();

    vent.trigger(
      "changed:resultImportance",
      this.agentResults.map((r) => r.get('id')),
      newImportantValue
    );

    return newImportantValue;
  },
  saveImportantValue: function (important) {
    // Restore stored model values on update failure
    var self = this;
    this.selectedAgentResults.save({
      agent_result: {
        important: important
      }
    }, {patch: true}).fail(function () {
      self.agentResults.each(function (agentResult) {
        agentResult.restore();
      });
      self.updateViewButtons();
      self.listenTo(self.view, 'clicked.markImportant',
                    self.markImportantButtonHandler);
      self._runAfterSaveCallback();
    }).done(function () {
      self.listenTo(self.view, 'clicked.markImportant',
                    self.markImportantButtonHandler);
      self._runAfterSaveCallback();
    });
  },
  _runAfterSaveCallback: function() {
  },
  updateViewButtons: function () {
    if (this.isImportant()) {
      this.view.markImportant();
    } else {
      this.view.unMark();
    }
  },
  isImportant: function () {
    return this.agentResults.every(function (r) {
      return r.get('important');
    });
  },
  _bindShortcuts: function () {
    var self = this;
    Shortcutter.bindInModule(this._shortcutModuleName(),
                             ['!', '0'], function () {
                               self.markImportantButtonHandler();
                             });
  },
  _unbindShortcuts: function () {
    Shortcutter.unbindInModule(this._shortcutModuleName(), ['!', '0']);
  },
  _shortcutModuleName: function () {
    return 'agents';
  }
});

export default AgentResultMarkImportantController;
