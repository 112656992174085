import * as _ from 'underscore'

var paramString = window.location.search.substring(1);
export default _.reduce(
  _.map(
    paramString.split('&'),
    function (o) {
      var oo = o.split('=');
      var r: any = {};
      r[oo[0]] = oo[1];
      return r;
    }
  ),
  function (m: any, v: any) {
    return _.extend(m,v);
  },
  {}
);
