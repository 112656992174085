import Config from 'libraries/configuration';
import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';
import Agent from 'models/agent';
import session from 'models/sessionInstance';

class Agents extends Backbone.Collection {
    url() {
        return Config.apiUrl + "agents";
    }
    subscribed() {
        return this.filter((agent) => agent.get('subscribed'));
    }
    parse(response) {
        return response.collection;
    }
    firstResultAt() {
        const fieldName = session.sortByPublishTimeEnabled() ?
              'first_result_at_publish_time' : 'first_result_at';
        let firstAgentResultDates = this.map(function (agent) {
            let firstResult = agent.get(fieldName);
            if (_.isNumber(firstResult) && moment.unix(firstResult).isValid()) {
                return moment.unix(firstResult);
            }
            return undefined
        });

        if (_.every(firstAgentResultDates, _.isUndefined)) {
            return undefined;
        } else {
            return _.min(firstAgentResultDates);
        }
    }
}

Agents.prototype.model = Agent;

export default Agents;
