import Widget from 'models/dashboard/widget'

Widget.TagAdValueWidget = Widget.extend({
  tagIds: function () {
    return this.get('tag_ids');
  }
});

export default Widget.TagAdValueWidget;

