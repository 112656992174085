import Marionette from 'backbone.marionette'
import userListItemTemplate from 'text-loader!templates/settings/userListItem.html'

var UserListItemView = Marionette.ItemView.extend({
  template: userListItemTemplate,
  tagName: "a",
  className: "button-group__item button--pointing",
  triggers: {
    'click': 'user:clicked'
  },
  initialize: function () {
    this.listenTo(this.model, 'change', this.render);
  },
  serializeData: function () {
    return this.model.toJSON().user;
  }
});

export default UserListItemView;

